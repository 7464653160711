<nav class="nav">
    @if (isUtilityNavBarEnable) {
    <navigation data-test-id="utilityNavigationBar"></navigation>
    } @else {
    <div class="container u-flex u-alignCenter">
        <a class="nav__logo" tabindex="0" [routerLink]="config?.logoRoute"> </a>

        <div class="EnrollmentSubHeader" *ngIf="showSubNav">
            @if(showAppID && appID){
            <p class="t-data t-bold EnrollmentSubHeader__Text" [innerHTML]="'Application #' + appID"></p>
            } @else if (enrollmentSubheader && enrollmentSubheader.enable){
            <p class="t-data t-bold EnrollmentSubHeader" [innerHTML]="enrollmentSubheader?.content"></p>
            }
        </div>

        <div class="u-fillRemaining u-flex u-alignCenter u-justifyEnd u-maxY">
            <a class="enrollment__exit" (click)="exitEnrollment()">
                <icon [config]="exitIconConfig" name="exit"></icon>
                <p class="t-data t-bold" [innerHTML]="config?.exitEnrollmentText"></p>
            </a>
        </div>
    </div>
    }
</nav>
