import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { autopayConfig, saveMethodConfig } from './autopay.constants';

@Component({
    selector: 'autopay',
    templateUrl: './autopay.component.html',
    styleUrls: ['./autopay.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutopayComponent),
            multi: true,
        },
    ],
})
export class AutopayComponent implements OnInit {
    @Input() form;
    @Input() config;

    saveMethodConfig = saveMethodConfig;
    autopayConfig = autopayConfig;
    onlyAutopay: boolean;

    constructor(private formControlService: FormControlService) {}

    ngOnInit() {
        if (this.config.saveMethodConfig) {
            this.saveMethodConfig = this.config.saveMethodConfig;
        } else if (this.config.saveMethodConfig === null) {
            this.onlyAutopay = true;
        }

        if (this.config.autopayConfig) {
            this.autopayConfig = this.config.autopayConfig;
        }

        if (!this.onlyAutopay) {
            this.formControlService.addControlToFormGroup(this.form, this.saveMethodConfig);
        }

        this.formControlService.addControlToFormGroup(this.form, this.autopayConfig);
    }

    public get saveMethodValue(): boolean {
        const saveMethod = this.form.get(this.saveMethodConfig.prop);
        return saveMethod && saveMethod.value;
    }
}
