<article class="toolbar">
    <div class="container">
        <div class="u-flex u-alignCenter u-justifyBetween">
            <a
                (click)="logoClicked.emit()"
                class="nav__logo"
                tabindex="0"
                [attr.aria-label]="navigationService.config.alt"
                *ngIf="!!navigationService.config.showLogoInToolbar"
            >
            </a>

            <img
                class="nav__image"
                *ngIf="navigationService.config.showImage"
                [src]="navigationService.config.imageLink"
                alt="Navigation"
            />

            <language-selector
                *ngIf="!!navigationService.config.toolbar?.languageSelector?.languages"
                class="toolbar__lang"
                [config]="navigationService.config.toolbar?.languageSelector?.languages"
                mode="tab"
            ></language-selector>

            <div *ngIf="!!navigationService.config.toolbar.routes && isLoggedIn" class="toolbar__dropdown">
                <ng-container *ngIf="navigationService.config.toolbar.routes.length > 1">
                    <div class="toolbar__dropdown__toggle" [ngClass]="{ 'is-open': !!dropdownOpen }" (click)="dropdownOpen = !dropdownOpen">
                        <p class="t-data t-bold" *ngIf="navigationService.activeRoute">{{ navigationService.activeRoute.label }}</p>
                        <i class="icon icon--medium">carat_down</i>
                    </div>

                    <div class="toolbar__dropdown__menu" *ngIf="!!dropdownOpen">
                        <header class="toolbar__dropdown__menu__header" *ngIf="navigationService.config.toolbar.label">
                            <p class="t-caption t-alt">{{ navigationService.config.toolbar.label }}</p>
                        </header>

                        <div
                            class="toolbar__dropdown__menu__item"
                            [ngClass]="{ 'is-active': i === navigationService.activeRouteIdx }"
                            *ngFor="let route of navigationService.config.toolbar.routes; let i = index"
                            (click)="navigationService.navigateSelected(route, i); dropdownOpen = false"
                        >
                            <i class="icon icon--medium" *ngIf="route.icon">
                                {{ i === navigationService.activeRouteIdx ? route.icon + '_active' : route.icon }}
                            </i>
                            <p class="t-data t-alt">{{ route.label }}</p>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div
                class="toolbar__tools"
                [ngClass]="{
                    'u-justifyEnd u-fillRemaining': !navigationService.config.toolbar.routes || navigationService.config.showImage
                }"
                *ngIf="icons && icons.length > 0"
            >
                <a class="toolbar__tool" (click)="handleIconClick(tool)" *ngFor="let tool of icons">
                    <i class="icon icon--medium" *ngIf="!!tool.icon">{{ tool.icon }}</i>
                    <span class="toolbar__tool-label t-data t-bold" *ngIf="!tool.icon">{{ tool.label }}</span>
                    <ng-container [ngSwitch]="tool.count">
                        <ng-container *ngSwitchCase="'notifications'">
                            <span class="toolbar__tool-count t-caption u-flex u-alignCenter u-justifyCenter" *ngIf="notifications > 0">{{
                                notifications
                            }}</span>
                        </ng-container>
                    </ng-container>
                </a>
            </div>
        </div>
    </div>
</article>
