export class passwordsConfig {
    type: 'passwords';
    prop: string;
}

export const passwordConfig = {
    prop: 'password',
    type: 'password',
    label: 'Create a password',
    focusDisplay: {
        description: '',
        label: 'Your Password Must:',
        rules: [
            {
                label: 'be at least 8 characters in length',
                value: false,
            },
            {
                label: 'be at least 8 characters in length',
                value: true,
            },
        ],
    },
    errors: {
        customErrMessage: 'Password must follow all security rules',
    },
    focus: 'complex_password',
    validators: [
        'required',
        {
            name: 'minCount',
            dependency: 8,
        },
        {
            name: 'not_match',
            dependency: 'username',
        },
    ],
};

export const confirmPasswordConfig = {
    prop: 'confirm_password',
    type: 'password',
    label: 'Confirm Password',
    validators: ['required'],
    groupValidators: [
        {
            name: 'groupMatch',
            prop1: 'password',
            prop2: 'confirm_password',
        },
    ],
};

export const enabledRules = [
    {
        name: 'lowercase',
        dependency: 1,
        label: 'include at least 1 lowercase character (a-z)',
    },
    {
        name: 'uppercase',
        dependency: 1,
        label: 'include at least 1 uppercase character (A - Z)',
    },
    {
        name: 'minCount',
        dependency: 8,
        label: 'be at least 8 characters in length',
    },
    {
        name: 'specialChar',
        dependency: ['/', '\\', '[', ']', ':', ';', '!', '=', '*', '?', '<', '>', '$'],
        label: 'include at least 1 special character (\\, /, [, ], :, ;, !, =, *, ?, <, >, $)',
    },
    {
        name: 'number',
        dependency: 1,
        label: 'include at least 1 number (0-9)',
    },
];
