import { AddMemberRequest } from './add member/workflow-ede--add-member-request.constants';
import { AddMemberResponse } from './add member/workflow-ede--add-member-response.constants';
import { CreateApplicationRequest } from './create application/workflow-ede--create-application-request.constants';
import { CreateApplicationResponse } from './create application/workflow-ede--create-application-response.constants';
import { SubmitApplicationRequest } from './submit application/workflow-ede--submit-application-request.constants';
import { SubmitApplicationResponse } from './submit application/workflow-ede--submit-application-response.constants';
import { UpdateApplicationRequest } from './update application/workflow-ede--update-application-request.constants';
import { UpdateApplicationResponse } from './update application/workflow-ede--update-application-response.constants';

export class WorkflowEDEApiCalls {
    UpdateApplicationRequest: UpdateApplicationRequest = new UpdateApplicationRequest();
    UpdateApplicationResponse: UpdateApplicationResponse = new UpdateApplicationResponse();

    CreateApplicationRequest: CreateApplicationRequest = new CreateApplicationRequest();
    CreateApplicationResponse: CreateApplicationResponse = new CreateApplicationResponse();

    SubmitApplicationRequest: SubmitApplicationRequest = new SubmitApplicationRequest();
    SubmitApplicationResponse: SubmitApplicationResponse = new SubmitApplicationResponse();

    AddMemberRequest: AddMemberRequest = new AddMemberRequest();
    AddMemberResponse: AddMemberResponse = new AddMemberResponse();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export const exampleStateReferenceData = {
    refdata: {
        AL: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        AK: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        AZ: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        AR: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        DE: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        FL: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        GA: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        HI: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        IL: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        IN: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        IA: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        KS: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        KY: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        LA: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        ME: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        MI: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        MO: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        MS: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        MT: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NE: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NV: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NH: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NJ: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NM: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        NC: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        ND: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        OH: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        OK: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        OR: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        PA: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        SC: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        SD: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        TN: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        TX: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        UT: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        VA: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        WV: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        WI: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
        WY: {
            FosterCareAgeThreshold: '21',
            InStateFosterCareRequired: 'Y',
            AcceptMedicaidEligibilityIndicator: 'Y',
            DeprivationRequirementRetained: 'Y',
            OptionRequireWorkQuarters: 'Y',
            Under21AndFTSOption: 'Y',
            OptionStudentResidency: 'Y',
            ChipForStateHealthBenefits: '01',
            StateCHIPWaitingPeriod: '0',
            PercentFPLAdultGroup: '0',
            OptionCareTakerRelationship: '01',
            StateOptionForCountingCashSupport: 'Y',
            StateOptionForPredictableIncome: 'Y',
            EffectiveDateOfCoverage: '01',
            StateRunsMedicaidGapFiling: 'Y',
        },
    },
};

export enum YES_NO {
    Y = 'Y',
    N = 'N',
    YES = 'YES',
    NO = 'NO',
}

export class StateReferenceResponse {
    refdata: {
        [state: string]: StateReference;
    };
}

export class StateReference {
    FosterCareAgeThreshold?: string;
    InStateFosterCareRequired?: YES_NO;
    AcceptMedicaidEligibilityIndicator?: YES_NO;
    DeprivationRequirementRetained?: YES_NO;
    OptionRequireWorkQuarters?: YES_NO;
    Under21AndFTSOption?: YES_NO;
    OptionStudentResidency?: YES_NO;
    ChipForStateHealthBenefits?: string;
    stateChipWaitingPeriod?: string;
    PercentFPLAdultGroup?: string;
    OptionCareTakerRelationship?: string;
    StateOptionForCountingCashSupport?: YES_NO;
    StateOptionForPredictableIncome?: YES_NO;
    EffectiveDateOfCoverage?: string;
    StateRunsMedicaidGapFiling?: YES_NO;
    acceptChipEligibilityIndicator?: YES_NO;
    acceptMedicaidEligibilityIndicator?: YES_NO;
    optionForDependentStudent?: YES_NO;
    optionDependentStudent?: YES_NO;
    medicaidProgramName?: string;
    chipProgramName?: string;
    medicaidAgencyName?: string;
    chipAgencyName?: string;
    medicaidWebsite?: string;
    chipWebsite?: string;
    medicaidPhone?: string;
    medicaidTty?: string;
    chipPhone?: string;
    chipTty?: string;
    medicaidAddress?: string;
    chipAddress?: string;
}
