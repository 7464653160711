import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    transform(value, sanitizerType) {
        const sanitizer = this.sanitized[sanitizerType] || this.sanitized.bypassSecurityTrustHtml;
        return sanitizer(value);
    }
}
