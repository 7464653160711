export const ButtonConfigs = {
    footer: {
        backButton: {
            level: 'medium',
            content: 'Back',
        },
        saveAndExit: {
            level: 'medium',
            content: 'Save and Exit',
        },
        nextButton: {
            level: 'high',
            content: 'Next',
        },
    },
};
