import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    OnDestroy,
    HostBinding,
    HostListener,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormattingService, BREAKPOINTS } from '@zipari/design-system';
import { ConfigService, PlanUtilService } from '@zipari/shared-sbp-services';
import { ExtraPlanBenefit, HighlightedInCompare, SinglePlanSelectionDetail } from './compare-plans.model';
import { comparePlansConstantText } from './compare-plans.constants';
import { Plan } from '../../../../../modules/src/lib/comparison-card/comparison-card.constant';
import { NavigationService } from '../../../../../modules/src/lib/navigation/navigation.service';

@Component({
    selector: 'compare-plans',
    templateUrl: './compare-plans.component.html',
    styleUrls: ['./compare-plans.component.scss'],
})
export class ComparePlansComponent implements OnInit, OnDestroy {
    @ViewChild('header') header: ElementRef;
    @ViewChild('content') content: ElementRef;
    @ViewChild('footer') footer: ElementRef;

    @Input() highlightedInCompare: HighlightedInCompare[];
    @Input() subsidyAmount: number = 0;
    allBenefits;
    config: any = {};
    comparePlansConfig;
    @Output() complete = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    navigationConfig = {
        search: true,
    };
    footerConfig;
    topOffset: string = '0px';
    topOffsetSub: Subscription;
    isScrolled: boolean = false;
    singlePlanSelectionDetail: SinglePlanSelectionDetail;
    comparePlansConstantText = comparePlansConstantText;

    constructor(
        public formattingService: FormattingService,
        public plansService: PlanUtilService,
        private navigationService: NavigationService,
        private configService: ConfigService
    ) {}

    private _plans: Array<Plan> = [];

    public get plans(): any {
        return this._plans;
    }

    @Input('plans')
    public set plans(plans: any) {
        this._plans = plans.map((plan) => this.plansService.formatBenefitsOnPlan(plan));
    }

    private _benefits: any;

    public get benefits(): Array<{
        value: string;
        label: string;
        type?: string;
    }> {
        return this._benefits;
    }

    @Input('benefits')
    public set benefits(
        benefits: Array<{
            value: string;
            label: string;
            type?: string;
        }>
    ) {
        const accBenefits = [];
        this.plansService.formatBenefits(benefits, accBenefits);
        this._benefits = accBenefits;
    }

    private _primaryBenefits: any;

    public get primaryBenefits(): Array<{
        value: string;
        label: string;
        type?: string;
    }> {
        return this._primaryBenefits;
    }

    @Input('primaryBenefits')
    public set primaryBenefits(
        primaryBenefits: Array<{
            value: string;
            label: string;
            type?: string;
        }>
    ) {
        const accPrimaryBenefits = [];

        this.plansService.formatBenefits(primaryBenefits, accPrimaryBenefits);
        this._primaryBenefits = accPrimaryBenefits;
    }

    private _planSelectionConfig: any;

    @Input('planSelectionConfig')
    public set planSelectionConfig(value: any) {
        this._planSelectionConfig =
            value || this.configService.getPageConfig('plan-selection') || this.configService.getPageConfig('plan_selection');
    }

    public get planSelectionConfig(): any {
        return this._planSelectionConfig;
    }

    get showFooter(): boolean {
        const configShow = this.comparePlansConfig.showDocumentsFooter;
        const linksExist = this.plans.some((plan) => !!plan.documents);

        return configShow && linksExist;
    }

    @HostBinding('style.marginTop') get marginTop() {
        return window.innerWidth < BREAKPOINTS.SMALL ? 0 : this.topOffset;
    }

    @HostListener('window:resize') onResize() {
        if (window.innerWidth > BREAKPOINTS.MEDIUM) {
            this.isScrolled = false;
        }
    }

    @HostBinding('class.topOffset') get setTopHeight() {
        return this.comparePlansConfig?.topOffSet || false;
    }

    ngOnInit() {
        this.footerConfig = this.configService.getPageConfig('footer');
        if (this.planSelectionConfig) {
            this.singlePlanSelectionDetail = this.planSelectionConfig.singlePlanSelection.plan_details;

            this.comparePlansConfig = this.planSelectionConfig.compare_plans;

            const accBenefits = [];
            const accPrimaryBenefits = [];
            this.plansService.formatBenefits(this.benefits, accBenefits);
            this.plansService.formatBenefits(this.primaryBenefits, accPrimaryBenefits);

            this.allBenefits = [...accPrimaryBenefits, ...accBenefits].sort((benefit1, benefit2) => {
                const specialLabels = {
                    'Plan Overview': 1,
                    'Plan Type': 2,
                    'Plan Name': 3,
                };

                if (specialLabels[benefit1.label] && specialLabels[benefit2.label]) {
                    return specialLabels[benefit1.label] > specialLabels[benefit2.label] ? -1 : 1;
                } else if (!specialLabels[benefit1.label] && specialLabels[benefit2.label]) {
                    return 1;
                } else if (specialLabels[benefit1.label] && !specialLabels[benefit2.label]) {
                    return -1;
                } else {
                    return 0;
                }
            });

            const planKeysToShowForBenefits: ExtraPlanBenefit[] = this.comparePlansConfig.planKeysToShowForBenefits || [];
            const newBenefits = [];
            planKeysToShowForBenefits.forEach((planBenefitConfig: ExtraPlanBenefit) => {
                const extraBenefitValue = this.plans[0][planBenefitConfig.planKey];

                if (extraBenefitValue || extraBenefitValue === 0) {
                    newBenefits.push(planBenefitConfig);
                }
            });

            this.allBenefits = [].concat(newBenefits, this.allBenefits);

            this.plans.forEach((plan) => {
                plan.allBenefitsLink = this.getAllBenefitsLink(plan);
            });
        }
        document.querySelector('body').style.overflow = 'hidden';

        this.topOffsetSub = this.navigationService.height$.subscribe((navHeight: number) => {
            this.topOffset = `${navHeight}px`;
        });
    }

    ngOnDestroy() {
        document.querySelector('body').style.overflow = 'auto';

        if (this.topOffsetSub) {
            this.topOffsetSub.unsubscribe();
        }
    }

    filterBenefits(query) {
        this.benefits = this.allBenefits.filter((benefit) => {
            return benefit.label.toLowerCase().includes(query.toLowerCase());
        });
    }

    getAllBenefitsLink(plan) {
        const { allBenefitsFile: defaultLink = '' } = this.comparePlansConfig || {};
        const allBenefitsLabels = [
            this.config.allBenefitLookup || '',
            'Benefit Summary',
            'Summary of Benefits and Coverage',
            'Benefit Summary URL Link',
        ];
        const allBenefits = plan.benefits.find(({ label }) => allBenefitsLabels.indexOf(label) > -1);
        const { value: link = defaultLink } = allBenefits || {};

        return link;
    }

    closeCompare() {
        this.close.emit({});
        document.querySelector('body').style.overflow = 'auto';
    }

    documentList(plan): Array<any> {
        if (this.comparePlansConfig.displayDocuments) {
            const documentList = [];
            this.comparePlansConfig.displayDocuments.forEach((item) => {
                if (plan.documents && plan.documents[item.type]) {
                    documentList.push({
                        label: item.label,
                        document_id: plan.documents[item.type].document_id,
                    });
                }
            });

            return documentList;
        } else {
            return plan.documents ? Object.keys(plan.documents).map((i) => plan.documents[i]) : [];
        }
    }

    formatDocumentName(name: string) {
        return name
            .split('_')
            .join(' ')
            .replace(/\b\w/g, function (l) {
                return l.toUpperCase();
            });
    }

    scrollMobile() {
        this.isScrolled = !this.isScrolled;
    }
}
