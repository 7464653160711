<ul class="lang" [ngClass]="'lang--' + mode" *ngIf="config">
    <li *ngFor="let lang of config" class="lang__item" [ngClass]="getClasses('lang__item', lang.iso)">
        <button
            class="lang__btn"
            [ngClass]="getClasses('lang__btn', lang.iso)"
            [attr.aria-label]="lang.label"
            [ngSwitch]="mode"
            (click)="setLanguage(lang)"
        >
            <span *ngSwitchCase="'tab'" class="t-bold">{{ lang.abbv }}</span>
            <ng-container *ngSwitchDefault>{{ lang.label }}</ng-container>
        </button>
    </li>
</ul>
