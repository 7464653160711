import { ActionStates, GenericErrorConfig } from '../generic-error/generic-error.constants';

export const errorPageConfig: GenericErrorConfig = {
    img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
    header: {
        title: 'We were unable to connect you',
        subtitle:
            'Something went wrong on our end and we were unable to connect. Please try again. If you continue to see this message, please contact Member Services using the number on the back of your ID card.',
    },
};

export const defaultTokenExpired: GenericErrorConfig = {
    img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
    header: {
        title: 'Token Link Expired',
        subtitle: 'Your token link has expired, please contact your broker or sales representative for a new link.',
    },
};

export const defaultEnrollError: GenericErrorConfig = {
    img: 'https://zipari-uploads-dev.s3.amazonaws.com/files/77bacafc-d4c5-4a5d-9cdd-7e7fdbbb0746.svg',
    header: {
        title: '',
    },
    footer: {
        buttons: [
            {
                content: 'Return to Quotes',
                level: 'high',
                prop: {
                    action: ActionStates.ROUTE,
                    route: 'broker-portal/medicare/quotes',
                },
            },
        ],
    },
};

export enum TOKEN_EXPIRE_TYPE {
    QUOTE_ERROR = 'quote-error',
    APPLICATION_ERROR = 'application-error',
    TOKEN_ERROR = 'token-error',
}

export const CHAR_LIMIT = {
    DEFAULT_CHAR_LIMIT: 300,
};
