<section
    [style.margin-top]="config?.offset"
    [ngClass]="{ 'slide-out__full': config?.isFull }"
    class="modal custom--modal plan-review__panel modal--panel"
    @slideRight
>
    <header>
        <ng-content select="[header]"></ng-content>
    </header>

    <main>
        <ng-content select="[body]"></ng-content>
    </main>

    <footer>
        <ng-content select="[footer]"></ng-content>
    </footer>
</section>
