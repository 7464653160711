import RatingRegion from './RatingRegion.model';

export default class Rate {
    age?: number;
    internal_name?: string;
    plan: number;
    product_rate?: number;
    rate_effective_date?: string;
    rate_expiration_date?: string;
    rate_tier?: string;
    rating_region: RatingRegion;
    tobacco_surcharge?: number;
}
