<modal class="Modal" [config]="popUpModalConfig" [condition]="true" (cancel)="cancelDragAndDrop()">
    <div body>
        <h6 *ngIf="errorResponse" class="Modal__Error" [innerHTML]="errorMessage"></h6>
        <p [innerHTML]="dragAndDropModalConfig.body"></p>
        <div cdkDropList class="DragContainer" (cdkDropListDropped)="resortColumn($event)">
            <div class="DragColumn" *ngFor="let column of dragAndDropColumns; let columnIndex = index" cdkDrag>
                <div class="DragColumn__Icon">
                    <icon
                        *ngIf="column.isHidden"
                        class="DragColumn__Icon--Disabled"
                        [attr.aria-label]="'disabled drag icon button'"
                        role="button"
                        name="drag"
                        label="Drag & Drop Disabled"
                        [config]="{ fill: 'gray--shadow' }"
                    >
                    </icon>
                    <icon cdkDragHandle [attr.aria-label]="'drag icon button'" label="Drag & Drop" role="button" name="drag"></icon>
                </div>
                <p [ngClass]="column.isHidden ? 'DragColumn__Content--Disabled' : 'DragColumn__Content'" [innerHTML]="column.name"></p>
                <div *ngIf="hidableField(column.prop)" class="DragColumn__Action">
                    <icon
                        role="button"
                        tabindex="0"
                        [attr.aria-label]="getIconAriaLabel(column)"
                        [name]="getIconName(column)"
                        [label]="getIconLabel(column)"
                        [config]="{ type: 'link' }"
                        (click)="toggleShowHide(columnIndex)"
                    >
                    </icon>
                    <p class="DragColumn__ActionContent" [innerHTML]="getActionContent(column)"></p>
                </div>
            </div>
        </div>
    </div>

    <div footer class="u-flex u-alignCenter u-justifyBetween u-maxX">
        <zip-button [config]="dragAndDropModalConfig.cancelButton" (zipButtonClicked)="cancelDragAndDrop()"> </zip-button>
        <zip-button [config]="dragAndDropModalConfig.submitButton" (zipButtonClicked)="saveGridPreference()"> </zip-button>
    </div>
</modal>
