// enum holds the names of content/schema types in squidex
// this enum is used so we have uniform names across clients for each content/schema type
export enum SquidexContentNames {
    attestations = 'attestations',
    contactUs = 'contact-us',
    footer = 'footer',
    termsOfService = 'terms-of-service',
}

// names of squidex feature keys related to appV3 features
// this enum is used so we have uniform featureKeys across clients for content associated to features in squidex
export enum SquidexFeatureKeys {
    attestationsIndividual = 'individual',
    attestationsMedicareSupplemental = 'medicare-supplemental',
    contactUsIndividual = 'individual',
    globalFooter = 'globalFooter',
    termsOfServiceIndividual = 'individual',
}

// response types for squidex api response
export enum SquidexContentResponseTypes {
    array = 'array',
    object = 'object',
}

// different statuses squidex content may have
// NOTE - some tenants may have different environment names, the env names below should cover most tenants
export enum SquidexEnvironment {
    Draft = 'Draft',
    DEV = 'DEV',
    SIT = 'SIT',
    PIT = 'PIT',
    UAT = 'UAT',
    Published = 'Published',
    Archived = 'Archived',
}
