<label class="form-control__label">
    <p class="t-data t-bold">{{ config.label }}</p>
</label>

<div class="basic-address__row">
    <form-control
        class="basic-address__control"
        *ngFor="let control of addressLine1"
        [control]="group.controls[config.prop].controls[control.prop]"
        [config]="control"
        direction="grouping"
    >
    </form-control>
</div>

<div class="basic-address__row basic-address__row--2">
    <form-control
        class="basic-address__control"
        *ngFor="let control of addressLine2"
        [control]="group.controls[config.prop].controls[control.prop]"
        [config]="control"
        direction="grouping"
    >
    </form-control>
</div>
