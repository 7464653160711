<div class="layout--desktop cardContainer">
    <ng-template *ngTemplateOutlet="desktopTemplate"></ng-template>
</div>

<div class="layout--tablet" [ngClass]="{ 'has-match': discontinued === false }">
    <ng-container *ngIf="!discontinued">
        <ng-template *ngTemplateOutlet="mobileTemplate"></ng-template>
    </ng-container>
    <ng-container *ngIf="discontinued">
        <ng-template *ngTemplateOutlet="desktopTemplate"></ng-template>
    </ng-container>
</div>

<div class="layout--mobile" [ngClass]="{ 'has-match': discontinued === false }">
    <ng-container *ngIf="!discontinued">
        <ng-template *ngTemplateOutlet="mobileTemplate"></ng-template>
    </ng-container>
    <ng-container *ngIf="discontinued">
        <ng-template *ngTemplateOutlet="desktopTemplate"></ng-template>
    </ng-container>
</div>
<ng-template #desktopTemplate>
    <div class="comparison-card__container__header">
        <p *ngIf="configs.displayPlansTypeLabel && comparePlansObj?.plansTypeLabel?.length" class="t-bold">
            {{ comparePlansObj.plansTypeLabel | titlecase }}
        </p>

        <form-control
            [group]="selectOrChoosePlanFormGroup"
            [config]="configs.selectOrChoosePlanToggleControl"
            [control]="selectOrChoosePlanFormGroup.get(configs.selectOrChoosePlanToggleControl.prop)"
        >
        </form-control>
    </div>
    <div class="comparison-card__container" [ngStyle]="{ width: width }" [ngClass]="{ 'no-match': discontinued === true }">
        <ng-template *ngTemplateOutlet="summaryDesktopLayout"></ng-template>
        <ng-template *ngTemplateOutlet="showDetailsTemplate"></ng-template>
        <ng-template *ngTemplateOutlet="detailsDesktopLayout"></ng-template>
    </div>
</ng-template>

<ng-template #mobileTemplate>
    <div class="comparison-card__container" [ngStyle]="{ width: width }">
        <ng-template *ngTemplateOutlet="summaryMobileLayout"></ng-template>
        <ng-template *ngTemplateOutlet="showDetailsTemplate"></ng-template>
        <ng-template *ngTemplateOutlet="detailsMobileLayout"></ng-template>
    </div>
</ng-template>

<ng-template #showDetailsTemplate>
    <div class="comparision-card__show_details_toggle" [ngClass]="{ 'details-open': showDetails, 'details-closed': !showDetails }">
        <div (click)="showDetails = !showDetails">
            <i class="icon icon--medium">chevron_{{ showDetails ? 'up' : 'down' }}</i>
            <p class="t-bold">{{ showDetails ? 'Hide' : 'View' }} Details</p>
        </div>

        <div *ngIf="ctas && ctas.length > 0" class="comparison-card__ctas">
            <ng-container *ngFor="let action of ctas">
                <i class="icon icon--medium" (click)="initCta($event, action)">{{ action }}</i>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #planRow let-label="label" let-plan1Value="plan1Value" let-plan2Value="plan2Value">
    <div class="comparison-card__wrapper">
        <div class="comparison-card__labels">
            <p class="t-bold">{{ label }}</p>
        </div>
        <div class="comparison-card__values values-col-1">
            <p class="t-bold">{{ plan1Value }}</p>
        </div>
        <div *ngIf="!discontinued" class="comparison-card__values">
            <p class="t-bold">{{ plan2Value }}</p>
        </div>
    </div>
</ng-template>

<ng-template #planRowMobile let-label="label" let-plan1Value="plan1Value" let-plan2Value="plan2Value">
    <div class="comparison-card__wrapper">
        <div class="comparison-card__values values-col-1" [ngClass]="{ 'plan-discontinued': discontinued }">
            <p class="t-bold">{{ label }}</p>
            <p [ngClass]="{ 't-bold': i < 4 && discontinued }">{{ plan1Value }}</p>
        </div>
        <div *ngIf="!discontinued" class="comparison-card__values">
            <p class="spacer"></p>
            <p>{{ plan2Value }}</p>
        </div>
    </div>
</ng-template>

<ng-template #summaryDesktopLayout>
    <div class="comparison-card__headers u-flex">
        <ng-container *ngIf="useBenefitsIndex; else defaultDesktopLabel">
            <div class="comparison-card__headers-wrapper u-flex">
                <div class="label-col" *ngIf="discontinued != true"></div>
                <div *ngIf="!discontinued" class="plan-col plan-1-col">
                    <h6 class="t-bold">{{ comparePlansObj.crossWalkPlan?.year }}</h6>
                </div>
                <div class="plan-col plan-2-col">
                    <h6 class="t-bold">{{ comparePlansObj.previousPlan?.year }}</h6>
                </div>
                <div *ngIf="discontinued && !ineligible" class="plan-discontinued">
                    <p class="t-bold"><i class="icon icon--medium">error</i>This plan has been discontinued</p>
                </div>
            </div>
        </ng-container>
        <ng-template #defaultDesktopLabel>
            <div class="comparison-card__headers-wrapper u-flex">
                <div class="label-col" *ngIf="discontinued != true"></div>
                <div class="plan-col plan-1-col">
                    <h6 class="t-bold">{{ comparePlansObj.previousPlan?.year }}</h6>
                </div>
                <div *ngIf="!discontinued" class="plan-col plan-2-col">
                    <h6 class="t-bold">{{ comparePlansObj.crossWalkPlan?.year }}</h6>
                </div>
                <div *ngIf="discontinued && !ineligible" class="plan-discontinued">
                    <p class="t-bold"><i class="icon icon--medium">error</i>This plan has been discontinued</p>
                </div>
            </div>
        </ng-template>
    </div>

    <!--TODO: make plan1Value and plan2Value into getters to reduce duplication in the template -hlopez -->
    <div class="comparison-card__content comparison-card__summary">
        <ng-container *ngFor="let config of configs.additionalPlanDetails">
            <ng-container *ngIf="useBenefitsIndex; else defaultDesktopSummary">
                <ng-container
                    *ngTemplateOutlet="
                        planRow;
                        context: {
                            label: config.label,
                            plan2Value: comparePlansObj.previousPlan && format((comparePlansObj?.previousPlan)[config.prop], config),
                            plan1Value: comparePlansObj.crossWalkPlan && format((comparePlansObj?.crossWalkPlan)[config.prop], config)
                        }
                    "
                >
                </ng-container>
            </ng-container>
            <ng-template #defaultDesktopSummary>
                <ng-container
                    *ngTemplateOutlet="
                        planRow;
                        context: {
                            label: config.label,
                            plan1Value: comparePlansObj.previousPlan && format((comparePlansObj?.previousPlan)[config.prop], config),
                            plan2Value: comparePlansObj.crossWalkPlan && format((comparePlansObj?.crossWalkPlan)[config.prop], config)
                        }
                    "
                >
                </ng-container>
            </ng-template>
        </ng-container>

        <div
            class="comparison-card__wrapper"
            *ngFor="let plan of comparePlansObj.summary | keyvalue; index as i"
            [ngClass]="{ 'border-btm': i === 3 }"
        >
            <div class="comparison-card__labels">
                <p class="t-bold">{{ plan.key }}</p>
            </div>
            <div class="comparison-card__values values-col-1" [ngClass]="{ 'plan-discontinued': discontinued === true }">
                <p [ngClass]="{ 't-bold': i < 4 }">{{ plan.value[0] }}</p>
            </div>
            <div class="comparison-card__values" *ngIf="!discontinued">
                <p [ngClass]="{ 't-bold': i < 4 }">{{ plan.value[1] }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #summaryMobileLayout>
    <div class="comparison-card__headers u-flex">
        <div class="comparison-card__headers-wrapper u-flex">
            <div class="plan-col plan-1-col">
                <h6 class="t-bold">{{ comparePlansObj.previousPlan?.year }}</h6>
            </div>
            <div *ngIf="!discontinued" class="plan-col plan-2-col">
                <h6 class="t-bold">{{ comparePlansObj.previousPlan?.name }}</h6>
            </div>
            <div *ngIf="discontinued && !ineligible" class="plan-discontinued">
                <p class="t-bold"><i class="icon icon--medium">error</i>This plan has been discontinued</p>
            </div>
        </div>
    </div>
    <div class="comparison-card__content comparison-card__summary">
        <ng-container *ngFor="let config of configs.additionalPlanDetails">
            <ng-container *ngIf="useBenefitsIndex; else defaultMobileSummary">
                <ng-container
                    *ngTemplateOutlet="
                        planRowMobile;
                        context: {
                            label: config.label,
                            plan2Value: comparePlansObj.previousPlan && format((comparePlansObj?.previousPlan)[config.prop], config),
                            plan1Value: comparePlansObj.crossWalkPlan && format((comparePlansObj?.crossWalkPlan)[config.prop], config)
                        }
                    "
                >
                </ng-container>
            </ng-container>
            <ng-template #defaultMobileSummary>
                <ng-container
                    *ngTemplateOutlet="
                        planRowMobile;
                        context: {
                            label: config.label,
                            plan1Value: comparePlansObj.previousPlan && format((comparePlansObj?.previousPlan)[config.prop], config),
                            plan2Value: comparePlansObj.crossWalkPlan && format((comparePlansObj?.crossWalkPlan)[config.prop], config)
                        }
                    "
                >
                </ng-container>
            </ng-template>
        </ng-container>
        <div class="comparison-card__wrapper" *ngFor="let plan of comparePlansObj.summary | keyvalue; index as i">
            <div class="comparison-card__values values-col-1" [ngClass]="{ 'plan-discontinued': discontinued }">
                <p class="t-bold">{{ plan.key }}</p>
                <p [ngClass]="{ 't-bold': i < 4 && discontinued }">{{ plan.value[0] }}</p>
            </div>
            <div class="comparison-card__values" *ngIf="!discontinued">
                <p class="spacer"></p>
                <p>{{ plan.value[1] }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #detailsDesktopLayout>
    <div *ngIf="showDetails">
        <div *ngIf="useBenefitsIndex; else defaultDesktop" class="comparison-card__content comparison-card__details">
            <div *ngFor="let plan of comparePlansObj.details">
                <div *ngIf="isArray(plan.value)" class="comparison-card__wrapper">
                    <div class="plan-row" *ngFor="let benefits of plan.formattedBenefits; index as idx2"></div>
                    <div class="comparison-card__labels">
                        <p class="t-bold">{{ plan.label }}</p>
                    </div>
                    <div class="comparison-card__values values-col-1">
                        <p *ngIf="plan.type !== 'link'" [innerHTML]="plan.value[0]"></p>
                        <a *ngIf="plan.type === 'link'" [href]="plan.value[0]" class="t-link" target="_blank">{{ plan.label }}</a>
                    </div>
                    <div *ngIf="!discontinued" class="comparison-card__values">
                        <p *ngIf="plan.type !== 'link'" [innerHTML]="plan.value[1]"></p>
                        <a *ngIf="plan.type === 'link' && !!plan.value[1]" [href]="plan.value[1]" class="t-link" target="_blank">{{
                            plan.label
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- need to preserve backwards compatiblity for clients that don't have benefit_display_index -->
        <ng-template #defaultDesktop>
            <div class="comparison-card__content comparison-card__details">
                <div class="comparison-card__wrapper" *ngFor="let plan of comparePlansObj.detailsMap | keyvalue : originalOrder">
                    <div class="plan-row" *ngFor="let benefits of plan.formattedBenefits; index as idx2"></div>
                    <div class="comparison-card__labels">
                        <p class="t-bold">{{ plan.key }}</p>
                    </div>
                    <div class="comparison-card__values values-col-1">
                        <p *ngIf="plan.value[0]" [innerHTML]="plan.value[0]"></p>
                    </div>
                    <div class="comparison-card__values" *ngIf="!discontinued">
                        <p [innerHTML]="plan.value[1]"></p>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #detailsMobileLayout>
    <div *ngIf="showDetails">
        <div *ngIf="useBenefitsIndex; else defaultMobile" class="comparison-card__content comparison-card__details">
            <div *ngFor="let plan of comparePlansObj.details">
                <div *ngIf="isArray(plan.value)" class="comparison-card__wrapper">
                    <div class="plan-row" *ngFor="let benefits of plan.formattedBenefits; index as idx2"></div>
                    <div class="comparison-card__values values-col-1">
                        <p class="t-bold">{{ plan.label }}</p>
                        <p *ngIf="plan.type !== 'link'" [innerHTML]="plan.value[0]"></p>
                        <a *ngIf="plan.type === 'link'" [href]="plan.value[0]" class="t-link" target="_blank">{{ plan.label }}</a>
                    </div>
                    <div *ngIf="!discontinued" class="comparison-card__values">
                        <p class="spacer"></p>
                        <p *ngIf="plan.type !== 'link'" [innerHTML]="plan.value[1]"></p>
                        <a *ngIf="plan.type === 'link' && !!plan.value[1]" [href]="plan.value[1]" class="t-link" target="_blank">{{
                            plan.label
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- need to preserve backwards compatiblity for clients that don't have benefit_display_index -->
        <ng-template #defaultMobile>
            <div class="comparison-card__content comparison-card__details">
                <div class="comparison-card__wrapper" *ngFor="let plan of comparePlansObj.detailsMap | keyvalue">
                    <div class="plan-row" *ngFor="let benefits of plan.formattedBenefits; index as idx2"></div>
                    <div class="comparison-card__values values-col-1">
                        <p class="t-bold">{{ plan.key }}</p>
                        <p [innerHTML]="plan.value[0]"></p>
                    </div>
                    <div class="comparison-card__values" *ngIf="!discontinued">
                        <p class="spacer"></p>
                        <p [innerHTML]="plan.value[1]"></p>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
