<div class="modal custom--modal modal--small" @fade *ngIf="!!toolbarMenuOpen">
    <header class="modal__header">
        <p class="t-data t-bold">{{ navigationService.config.toolbar.label }}</p>
    </header>

    <main class="modal__body">
        <div
            class="mobile-menu__option"
            [ngClass]="{ 'is-active': i === navigationService.activeRouteIdx }"
            *ngFor="let route of navigationService.config.toolbar.routes; let i = index"
            (click)="navigationService.navigateSelected(route, i); toolbarMenuOpen = false"
        >
            <i class="icon icon--medium">
                {{ i === navigationService.activeRouteIdx ? route.icon + '_active' : route.icon }}
            </i>
            <p class="t-data">{{ route.label }}</p>

            <div class="u-fillRemaining u-flex u-justifyEnd">
                <i class="icon icon--medium icon--pointer">{{ i === navigationService.activeRouteIdx ? 'radio_active' : 'radio' }}</i>
            </div>
        </div>
    </main>
</div>

<section class="modal__mask" @fade *ngIf="!!navigationService.mobileMenuOpen"></section>

<menu
    class="mobile-menu"
    [ngClass]="{
        'mobile-menu--alt': !!navigationService.config.navAlt || !!navigationService.config.toolbar,
        'is-toolbar-open': !!toolbarMenuOpen
    }"
    @slideLeft
    *ngIf="!!navigationService.mobileMenuOpen"
>
    <header class="mobile-menu__header">
        <zip-button
            [config]="{
                level: 'text',
                icon: 'close',
                content: 'Menu'
            }"
            (zipButtonClicked)="navigationService.toggleMobileMenu()"
        >
        </zip-button>
    </header>

    <main class="mobile-menu__body">
        <section class="mobile-menu__toolbar" *ngIf="isMobileToolbarDisplay()">
            <div class="mobile-menu__item" data-test-id="mobileMenuItem">
                <p
                    data-test-id="activeRouteLabel"
                    *ngIf="navigationService.activeRoute?.label"
                    class="t-data t-bold"
                    [innerHTML]="navigationService.activeRoute.label"
                ></p>
                <div class="u-fillRemaining u-flex u-justifyEnd">
                    <icon name="edit" [config]="{ type: 'pointer' }" (click)="toolbarMenuOpen = true"></icon>
                </div>
            </div>
        </section>

        <ng-container *ngIf="mobileMenuSections">
            <section class="mobile-menu__section" *ngFor="let section of mobileMenuSections">
                <ng-container *ngFor="let link of section">
                    <div
                        class="mobile-menu__item"
                        [ngClass]="{
                            'is-open': !!link.items && !!link.open,
                            'is-active': navigationService.isActiveRoute(link.route)
                        }"
                        *ngIf="isLoginOrRegisterMenuVisibile(link)"
                        (click)="linkClick($event, link)"
                    >
                        <ng-container *ngIf="!!link.route || !!link.onClick">
                            <p class="t-data" [ngClass]="!!link.icon && 'u-flex u-justifyBetween u-alignCenter u-maxX'">
                                {{ link.label }}
                                <span *ngIf="link.icon || link.type === 'languageSelector'" class="u-flex u-justifyBetween u-alignCenter">
                                    <span *ngIf="link.type === 'languageSelector' && !!language" [innerHTML]="language.label"></span>
                                    <icon [name]="link.icon" [config]="{ type: 'pointer' }"></icon>
                                </span>
                            </p>
                        </ng-container>

                        <ng-container *ngIf="!!link.link">
                            <a class="t-data" [href]="link.link" target="_blank" [innerHTML]="link.label"></a>
                        </ng-container>

                        <div class="u-flex u-fillRemaining" *ngIf="!!link.items">
                            <div class="u-fillRemaining">
                                <p class="t-data" [innerHTML]="link.label"></p>
                            </div>
                            <icon [ngClass]="{ 'icon--rotate180': !!link.open }" name="chevron_down" [config]="{ type: 'pointer' }"></icon>
                        </div>

                        <div *ngIf="!!link.items && !!link.open" @accordion class="mobile-menu__subitems">
                            <div class="mobile-menu__subitem" *ngFor="let item of link.items">
                                <a class="t-data" [routerLink]="item.route" (click)="itemClick(item)" [innerHTML]="item.label"></a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>

            <footer class="mobile-menu__footer" *ngIf="!!navigationService.config.menu.footer">
                <div class="mobile-menu__item">
                    <div class="mobile-menu__item__icon">
                        <i class="icon icon--medium">{{ navigationService.config.menu.footer.icon }}</i>
                    </div>

                    <p class="t-data">{{ navigationService.config.menu.footer.label }}</p>
                </div>
            </footer>
        </ng-container>
    </main>
</menu>

<modal
    *ngIf="!!navigationService.config.toolbar?.languageSelector?.modalConfig"
    class="language-selector-modal"
    [config]="navigationService.config.toolbar?.languageSelector?.modalConfig"
    [condition]="showLangSelector"
    (cancel)="callback('toggleLang')"
>
    <div body>
        <language-selector
            class="mobile-menu__lang"
            [config]="navigationService.config.toolbar?.languageSelector?.languages"
            mode="radio"
        ></language-selector>
    </div>
</modal>
