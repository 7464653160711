import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { deepCompare, isEmptyObj } from '@zipari/web-utils';
import { BrowserSupportConfig, BrowserSupportService } from '@zipari/shared-sbp-services';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthCardOptions } from '../authentication.constants';

@Component({
    selector: 'auth-card',
    templateUrl: './auth-card.component.html',
    styleUrls: ['./auth-card.component.scss'],
})
export class AuthCardComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() config: AuthCardOptions;
    @Input() errorMessage: Array<string>;
    @Input() success: boolean;
    @Input() disableButton: boolean;
    @Input() browserSupportConfig: BrowserSupportConfig;
    @Input() date_of_birth: any = {};

    @Output() linkClicked = new EventEmitter<any>();
    @Output() buttonClicked = new EventEmitter<any>();
    public isBrowserSupported = true;

    sub: Subscription;

    constructor(public route: ActivatedRoute, private browserSupportService: BrowserSupportService) {
        this.isBrowserSupported = this.browserSupportService.isBrowserSupported();
    }

    ngOnInit() {
        // this is to check the emails to make sure that they match after changing either one
        // the validator triggers the first time through, but if you update the main email after validation
        // the confirm email doesn't trigger the validator again
        this.sub = this.formGroup.valueChanges.pipe(distinctUntilChanged((a, b) => deepCompare(a, b))).subscribe((value) => {
            const emailValue: string = value.email;
            const emailConfirmValue: string = value.email_confirm;
            const isMatch: boolean = emailValue === emailConfirmValue;
            const hasError: boolean = emailConfirmValue ? this.formGroup.controls['email_confirm'].hasError('match') : false;

            if (!!emailValue && !!emailConfirmValue && !isMatch) {
                this.formGroup.controls['email_confirm'].updateValueAndValidity();
            } else if (!!emailValue && !!emailConfirmValue && isMatch && hasError) {
                this.formGroup.controls['email_confirm'].updateValueAndValidity();
            }
        });
    }

    ngOnDestroy() {
        if (!!this.sub) {
            this.sub.unsubscribe();
        }
    }

    patchDOB() {
        if (!isEmptyObj(this.date_of_birth) && this.config.patchDOB) {
            this.formGroup.patchValue(this.date_of_birth);
        }
    }
}
