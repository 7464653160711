import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { HighlightedInCompare, ComparePlansConfig, HighlightedConfigItems, HighlightsData } from '../compare-plans.model';
import { Plan } from '../../../../../../modules/src/lib/comparison-card/comparison-card.constant';

@Component({
    selector: 'compare-plan-header',
    templateUrl: './compare-plan-header.component.html',
    styleUrls: ['./compare-plan-header.component.scss'],
})
export class ComparePlanHeaderComponent {
    @Input() plans: Plan[];
    @Input() comparePlansConfig: ComparePlansConfig;
    @Input() isScrolled: boolean;
    @Input() highlightedPlans: HighlightedInCompare[] = [];
    @Input() comparePlansConstantText;

    @Output() complete = new EventEmitter<Plan>();
    @Output() close = new EventEmitter<void>();

    constructor(public formattingService: FormattingService) {}

    getHighlights(plan: Plan): HighlightsData[] {
        const planType: string = plan.plan_type;
        const configPath: string = `planConfig.attributeHighlights.${planType}`;
        const highlightConfigItems: HighlightedConfigItems[] = getValue(this.comparePlansConfig, configPath);
        const highlights: HighlightsData[] = [];

        if (this.comparePlansConfig.showLevel1Benefits) {
            for (const highlightConfigItem of highlightConfigItems) {
                const value: string = plan[highlightConfigItem.prop];
                const formattedValue: string = this.formattingService.restructureValueBasedOnFormat(value, highlightConfigItem);
                highlights.push({
                    label: highlightConfigItem.label,
                    value: formattedValue,
                });
            }
        }
        return highlights;
    }

    getHighlightedPlanHeader(plan: Plan): string {
        if (this.highlightedPlans) {
            const highlightedPlan: HighlightedInCompare = this.highlightedPlans.find(
                (highlightedPlan: HighlightedInCompare) => highlightedPlan.external_id === plan.external_id
            );
            return highlightedPlan ? highlightedPlan.header : '';
        }
        return '';
    }

    enroll(plan): void {
        this.complete.emit(plan);
        document.querySelector('body').style.overflow = 'unset';
    }

    goBackToAllPlans(): void {
        this.close.emit();
    }
}
