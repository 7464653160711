export class MemberPolicy {
    id: number;
    contacts: Array<any>;
    product_coverages: Array<any>;
    created_at: string;
    updated_at: string;
    effective_date: string;
    termination_date: string;
    policy_number: string;
    policy_name: string;
    policy_premium: string;
    policy_status: string;
    policy_status_reason: string;
    crm_id: string;
    group: number;
}
