import Diagnosis from './Diagnosis.model';
import Procedure from './Procedure.model';
import Provider from './Provider.model';

export default class Referral {
    readonly id?: number;
    readonly updated_at?: string;
    created_at?: string;
    referral_number: string;
    servicing_provider?: Provider;
    limit?: string;
    referral_status?: string;
    referral_status_description?: string;
    approval_date: string;
    denial_date: string;
    procedure: Array<Procedure>;
    diagnosis: Array<Diagnosis>;
}
