import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule, ModalModule, ModelAttributeModule, IconModule, ZipBusyModule } from '@zipari/design-system';

import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NavigationComponent } from './navigation.component';
import { TabletToolbarComponent } from './tablet-toolbar/tablet-toolbar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SharedModule } from '../shared.module';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ButtonModule,
        SharedModule,
        ModelAttributeModule,
        ModalModule,
        ZipBusyModule,
        IconModule,
    ],
    declarations: [NavigationComponent, ToolbarComponent, MobileMenuComponent, TabletToolbarComponent, LanguageSelectorComponent],
    exports: [NavigationComponent],
})
export class NavigationModule {}
