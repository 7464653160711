import { Component, Input, OnInit } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { formatTypes } from '@zipari/shared-ds-util-format';

@Component({
    selector: 'member-card',
    templateUrl: './member-card.component.html',
    styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
    @Input() member;
    @Input() attributes;

    constructor(public formattingService: FormattingService) {}

    get memberName(): string {
        if (this.member.name && typeof this.member.name === 'string') {
            return this.member.name;
        }
        if (this.member.name && this.member.name.constructor === Object) {
            return this.formatName(this.member.name);
        } else {
            return this.formatName(this.member);
        }
    }

    formatName(nameObj) {
        return this.formattingService.restructureValueBasedOnFormat(nameObj, {
            format: formatTypes.FULL_NAME,
        });
    }

    // if were watching scroll and updating menu item
    ngOnInit() {}
}
