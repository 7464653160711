import {
    Application__ContactInformation,
    ApplicationAssistorType,
    ApplicationSignatureType,
    IncarcertationType,
    IncomeFrequencies,
    IncomeSourceJob,
    InsuranceMarketType,
    MaritalStatus,
    OfferedEmployeeCoverage,
    ReconcilePTCIndicatorType,
    Sexes,
    Suffixes,
    TaxReturnFilingStateType,
} from '../sharedModels';

export class UpdateApplicationRequest__Member__Demographic_HomeAddress {
    streetName1: string = null;
    cityName: string = null;
    // todo: maybe put the state codes in an enum
    stateCode: string = null;
    zipCode: string = null;
    countyName: string = null;
    countyFipsCode: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Demographic_Name {
    /** helper variables used internally */
    first_name: string = null;
    last_name: string = null;
    /** helper variables used internally */

    firstName: string = null;
    middleName: string = null;
    lastName: string = null;
    suffix: Suffixes = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Demographic {
    birthDate: string = null;
    maritalStatus: MaritalStatus = null;
    name: UpdateApplicationRequest__Member__Demographic_Name = null;
    noHomeAddressIndicator: boolean = null;
    ssn: string = null;
    americanIndianAlaskanNativeIndicator: boolean = null;
    gender: Sexes = null;
    homeAddress: UpdateApplicationRequest__Member__Demographic_HomeAddress =
        new UpdateApplicationRequest__Member__Demographic_HomeAddress();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Income__AnnualIncome {
    incomeAmount: number = null;
    unknownIncomeIndicator: boolean = null;
    variableIncomeIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Income__CurrentIncome__Structure__JobIncome {
    employerName: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Income__CurrentIncome__Structure {
    incomeAmount: number = null;
    incomeSourceType: IncomeSourceJob = null;
    incomeFrequencyType: IncomeFrequencies = null;
    jobIncome: UpdateApplicationRequest__Member__Income__CurrentIncome__Structure__JobIncome =
        new UpdateApplicationRequest__Member__Income__CurrentIncome__Structure__JobIncome();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Income__CurrentIncome {
    [currentIncomeIndex: string]: UpdateApplicationRequest__Member__Income__CurrentIncome__Structure;
}

export class UpdateApplicationRequest__Member__Income {
    annualTaxIncome: UpdateApplicationRequest__Income__AnnualIncome = new UpdateApplicationRequest__Income__AnnualIncome();
    currentIncome: UpdateApplicationRequest__Member__Income__CurrentIncome = new UpdateApplicationRequest__Member__Income__CurrentIncome();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Family {
    claimsDependentIndicator: boolean = null;
    parentCaretakerIndicator: boolean = null;
    absentParentIndicator: boolean = null;
    taxDependentIndicator: boolean = null;
    taxFilerIndicator: boolean = null;
    taxReturnFilingStatusType: TaxReturnFilingStateType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__LawfulPresence {
    citizenshipIndicator: boolean = null;
    naturalizedCitizenIndicator: boolean = null;
    noAlienNumberIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Medicaid {
    medicaidDeniedIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__InsuranceCoverage__Employer__Contact {
    firstName: string = null;
    middleName: string = null;
    lastName: string = null;
    suffix: Suffixes = null;
    email: string = null;
    phoneNumber: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__InsuranceCoverage__Employer {
    name: string = null;
    streetName1: string = null;
    streetName2: string = null;
    cityName: string = null;
    stateCode: string = null;
    zipCode: string = null;
    // todo: what the hell is a plus 4 code.... example they gave was 1111
    plus4Code: string = null;
    countryCode: string = null;
    countyName: string = null;
    countyFipsCode: string = null;
    employerPhoneNumber: string = null;
    contact: UpdateApplicationRequest__Member__InsuranceCoverage__Employer__Contact =
        new UpdateApplicationRequest__Member__InsuranceCoverage__Employer__Contact();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Other__Native {
    personRecognizedTribeIndicator: false = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Other {
    americanIndianAlaskanNative: UpdateApplicationRequest__Member__Other__Native = new UpdateApplicationRequest__Member__Other__Native();
    reconcilePtcIndicatorType: ReconcilePTCIndicatorType = null;
    incarcerationType: IncarcertationType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__Chip {
    coverageEndedIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest__Member__InsuranceCoverage {
    enrolledCoverages: Array<{ insuranceMarketType: InsuranceMarketType }> = null;
    employerSponsoredCoverageOffers: {
        [offerKey: string]: {
            employer: UpdateApplicationRequest__Member__InsuranceCoverage__Employer;
        };
    };
    offeredEmployeeCoverage: OfferedEmployeeCoverage = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest_Member__Structure {
    requestingCoverageIndicator?: boolean = null;
    demographic: UpdateApplicationRequest__Member__Demographic = new UpdateApplicationRequest__Member__Demographic();
    income: UpdateApplicationRequest__Member__Income = new UpdateApplicationRequest__Member__Income();
    family: UpdateApplicationRequest__Member__Family = new UpdateApplicationRequest__Member__Family();
    lawfulPresence: UpdateApplicationRequest__Member__LawfulPresence = new UpdateApplicationRequest__Member__LawfulPresence();
    insuranceCoverage: UpdateApplicationRequest__Member__InsuranceCoverage = new UpdateApplicationRequest__Member__InsuranceCoverage();
    medicaid: UpdateApplicationRequest__Member__Medicaid = new UpdateApplicationRequest__Member__Medicaid();
    chip: UpdateApplicationRequest__Member__Chip = new UpdateApplicationRequest__Member__Chip();
    other: UpdateApplicationRequest__Member__Other = new UpdateApplicationRequest__Member__Other();

    /** internal use of the member key to easily pass the members around in js */
    memberKey?: string;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest_Member {
    [memberKey: string]: UpdateApplicationRequest_Member__Structure;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest_Application__LegalAttestations {
    changeInformationAgreementIndicator?: boolean = null;
    penaltyOfPerjuryAgreementIndicator?: boolean = null;
    nonIncarcerationAgreementIndicator: boolean = null;
    renewEligibilityYearQuantity?: number = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest_Application__ApplicationAssistor {
    applicationAssistorType: ApplicationAssistorType = null;
    assistorOrganizationId: string = null;
    assistorOrganizationName: string = null;
    assistorFirstName: string = null;
    assistorLastName: string = null;
    assistorMiddleName: string = null;
    assistorSuffix: Suffixes = null;
    assistorNationalProducerNumber: number = null;
    assistorSystemUserName: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest_Application {
    accountHolderIdentityProofedIndicator?: boolean;
    applicationAssistors?: Array<UpdateApplicationRequest_Application__ApplicationAssistor> = null;
    applicationSignatures?: Array<ApplicationSignatures> = null;
    comments?: string = null;
    contactInformation?: Application__ContactInformation = new Application__ContactInformation();
    contactMemberIdentifier?: string = null;
    contactMethod?: Array<string> = null;
    coverageState?: string = null;
    insuranceApplicationSecurityQuestionAnswer?: string = null;
    insuranceApplicationSecurityQuestionType?: string = null;
    legalAttestations?: UpdateApplicationRequest_Application__LegalAttestations =
        new UpdateApplicationRequest_Application__LegalAttestations();
    requestingFinancialAssistanceIndicator?: boolean = null;
    spokenLanguageType?: string = null;
    writtenLanguageType?: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class ApplicationSignatures {
    applicationSignatureDate: string = null;
    applicationSignatureText: string = null;
    applicationSignatureType: ApplicationSignatureType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

/* 
 familyRelationships: [
 [
 '1254107644534669912',
 'SELF',
 '1254107644534669912'
 ],
 [
 '1254107644534669912',
 'PARENT',
 '1254107644534669914',
 {
 'resideTogetherIndicator': true
 }
 ]
 ];
 taxRelationships: [
 [
 '1254107644534669912',
 'TAX_FILER',
 '1254107644534669912'
 ],
 [
 '1254107644534669912',
 'TAX_FILER',
 '1254107644534669914'
 ]
 ];
 */
export class UpdateApplicationRequest__Household {
    familyRelationships: any = null;
    legalRelationships: any = null;
    taxRelationships: any = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationRequest {
    application: UpdateApplicationRequest_Application = new UpdateApplicationRequest_Application();
    members: UpdateApplicationRequest_Member = new UpdateApplicationRequest_Member();
    household: UpdateApplicationRequest__Household = new UpdateApplicationRequest__Household();

    constructor(options = null) {
        Object.assign(this, options);
    }
}
