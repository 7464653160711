<div class="cart-container">
    <ng-container>
        <div class="chip" *ngFor="let plan of plans">
            <div class="count">{{ plan.count }}</div>
            <div class="label">{{ plan.label }}</div>
        </div>
    </ng-container>

    <span *ngIf="!hidePrice && plans.length > 0">
        {{ totalPremiumLabel }}<span class="price">{{ price }}/month</span>
    </span>
</div>
