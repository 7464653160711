import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormattingService, FormControlService } from '@zipari/design-system';
import { controlTypes, RadioConfiguration } from '@zipari/shared-ds-util-form';
import { ApiListResponse, Plan } from '@zipari/shared-sbp-models';
import { AuthService, CoverageEffectiveDateService, validEnrollmentTypes } from '@zipari/shared-sbp-services';
import {
    CoverageEffectiveConfig,
    CoverageEffectiveDateRange,
    effectiveDateDropdownConfig,
    whichEnrollment,
} from '@zipari/shared-sbp-templates';
import { cloneObject, deepCompare, getValue, stringBuilder } from '@zipari/web-utils';
import { compact, flatten, isEmpty } from 'lodash';
import { concat, of, Subscription } from 'rxjs';

import { QLEService } from '../qle/qle.service';
import { PlansAPIService } from '../../../../../modules/src/lib/plans/plans-api.service';

@Component({
    selector: 'coverage-effective',
    templateUrl: './coverage-effective-date.component.html',
    styleUrls: ['./coverage-effective-date.component.scss'],
})
export class CoverageEffectiveDateComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
    @Output() enrollmentPeriodChosen = new EventEmitter();
    @Output() effectiveYearFound = new EventEmitter();

    @Input() prefilledValue: string;
    @Input() initialEnrollmentPeriod: string;
    @Input() form: FormGroup;
    @Input() productType: string;
    @Input() coverageTypeControl: FormControl;
    @Input() openEnrollmentRange: CoverageEffectiveDateRange;
    @Input() windowShoppingRange: CoverageEffectiveDateRange;
    @Input() isQLEenabled: boolean;
    @Input() applicationType: string;
    @Input() showCoverageEffectiveDateError: boolean;
    coverageFormErrorSubscription: Subscription;
    userChoiceSub: Subscription;
    coverageEffectiveSub: Subscription;
    productTypeSub: Subscription;
    coverageTypeSub: Subscription;
    _coverageType: string[];
    isPlanExist: boolean;
    _showCoverageDateSelector: boolean = false;
    whichEnrollment: RadioConfiguration = cloneObject(whichEnrollment);
    formattedText = {
        title: '',
        subtitle: '',
    };
    effectiveDateDropdownConfig = effectiveDateDropdownConfig;
    storedEffectiveDate = [];

    constructor(
        public cdr: ChangeDetectorRef,
        public formattingService: FormattingService,
        public formControlService: FormControlService,
        public coverageEffectiveDateService: CoverageEffectiveDateService,
        public http: HttpClient,
        public authService: AuthService,
        private qleService: QLEService,
        private plansAPIService: PlansAPIService
    ) {}

    @Input() _config: CoverageEffectiveConfig;

    public get config() {
        return this._config || {};
    }

    _textTemplates = {
        title: {
            OE: 'You are eligible for coverage starting on ${date}',
            SEP: 'You may be eligible for coverage starting on ${date}',
        },
        subtitle: {
            OE: '',
            SEP: 'You will be asked to submit optional documents to verify your QLE',
        },
    };

    _QLEtextTemplates = {
        title: {
            OE: 'You are eligible for coverage starting on ${date}',
            SEP: 'You are eligible for coverage starting on ${date}',
        },
        subtitle: {
            OE: '',
            SEP: '',
        },
    };

    public get textTemplates() {
        return this.config['textTemplates'] || this._textTemplates;
    }

    public get QLEtextTemplates() {
        return this.config['QLEtextTemplates'] || this._QLEtextTemplates;
    }

    get shouldDisableSEP() {
        const disabledInConfig = getValue(this.config, 'disableSEP');
        // Don't show the sep checkboxes if medical coverage type isn't selected
        // or when we're out of the OpenEnrollmentPeriod && window shopping period.
        const disableBasedOnCoverageType: boolean =
            (!this.isOpenEnrollmentPeriod && !this.isWindowShoppingPeriod) || !this.isMedicalSelected;
        return disabledInConfig || disableBasedOnCoverageType;
    }

    public get enrollmentChosen() {
        return this.whichEnrollmentControl ? this.whichEnrollmentControl.value : '';
    }

    get coverageType() {
        const productType: Array<string> = typeof this.productType === 'string' ? [this.productType] : this.productType;

        return this._coverageType && this._coverageType.length ? this._coverageType : productType;
    }

    get isOpenEnrollmentPeriod(): boolean {
        return this.coverageEffectiveDateService.isOpenEnrollmentPeriod(this.openEnrollmentRange);
    }

    get isWindowShoppingPeriod(): boolean {
        return this.coverageEffectiveDateService.isWindowShoppingPeriod(this.windowShoppingRange);
    }

    get isMedicalSelected(): boolean {
        return this.coverageType?.includes('medical') || this.coverageType?.includes('health');
    }

    get showCoverageDateSelector(): boolean {
        if (this.config?.multipleEffectiveDates && this._showCoverageDateSelector && !!this.enrollmentChosen) {
            return !(this.isMedicalSelected && this.enrollmentChosen === 'SEP');
        } else {
            return false;
        }
    }

    get dropdownControl(): FormControl {
        return this.form?.get(this.effectiveDateDropdownConfig.prop) as FormControl;
    }

    get whichEnrollmentControl(): FormControl {
        return this.form?.get(this.whichEnrollment.prop) as FormControl;
    }

    get parentEligibilityForm(): FormGroup {
        return this.coverageTypeControl?.parent?.parent as FormGroup;
    }

    get getStartedForm(): FormGroup {
        return this.parentEligibilityForm?.controls['getStarted'] as FormGroup;
    }

    get showCoverageEffectiveText(): boolean {
        if ((this.isOpenEnrollmentPeriod || this.isWindowShoppingPeriod) && this.isMedicalSelected) {
            return !!this.isQLEenabled && this.enrollmentChosen === 'OE' && !!this.form?.valid;
        } else {
            return !!this.isQLEenabled && !this.isMedicalSelected && !!this.form?.valid;
        }
    }

    ngOnInit() {
        // sets the open enrollment period to a range, then defaults to SEP when out of range
        // (if no range is provided, method is ignored)
        this.setOpenEnrollmentPeriod();

        this.handleFormCreated();
        this.onChangeCoverageType();
    }

    ngOnChanges(changes) {
        if ('_config' in changes && this.config) {
            if (this.config.disableSEP) {
                this.whichEnrollment.options = this.whichEnrollment.options.filter((option) => {
                    return option.value !== validEnrollmentTypes.SEP;
                });
            }
        }

        if ('prefilledValue' in changes && changes.prefilledValue.currentValue) {
            this.storedEffectiveDate.push(changes.prefilledValue.currentValue);
            this.prefillEffectiveDate(this.prefilledValue);
        }

        if ('coverageTypeControl' in changes && changes.coverageTypeControl.currentValue) {
            this.onChangeCoverageType();
        }

        if ('initialEnrollmentPeriod' in changes && changes.initialEnrollmentPeriod.currentValue) {
            this.prefillEnrollmentPeriod(this.initialEnrollmentPeriod);
        }

        if ('productType' in changes && changes.productType.currentValue && this.enrollmentChosen) {
            if (!deepCompare(changes.productType.currentValue, changes.productType.previousValue)) {
                this.handleNewEnrollmentPeriod(this.enrollmentChosen);
            }
        }
    }

    ngDoCheck() {
        if (
            this.storedEffectiveDate.length > 0 &&
            this.storedEffectiveDate[0] &&
            this.form &&
            (this.dropdownControl || this.enrollmentChosen)
        ) {
            while (this.storedEffectiveDate.length > 0) {
                const effectiveDate = this.storedEffectiveDate.pop();
                this.coverageEffectiveDateService.emitDate(effectiveDate);
                this.checkPlansExistForCoverageEffectiveDate(effectiveDate);
                if (this.dropdownControl) {
                    this.prefillEffectiveDate(effectiveDate);
                }
                if (!!this.enrollmentChosen) {
                    this.handleEffectiveDatePrefillingText(effectiveDate);
                }
            }

            this.cdr.detectChanges();
        }

        if (this.config.isDisabled !== this.effectiveDateDropdownConfig.isDisabled) {
            this.effectiveDateDropdownConfig.isDisabled = this.config.isDisabled;
        }

        // Rename effectiveDate dropdown label if a custom label is in config, else use default 'Coverage Effective Date'
        if (this.config.label && this.config.label !== this.effectiveDateDropdownConfig.label) {
            this.effectiveDateDropdownConfig.label = this.config.label;
        }
    }

    ngOnDestroy() {
        const subs: Array<Subscription> = [
            this.coverageEffectiveSub,
            this.userChoiceSub,
            this.productTypeSub,
            this.coverageTypeSub,
            this?.coverageFormErrorSubscription,
        ];

        subs.forEach((sub) => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    setOpenEnrollmentPeriod(): void {
        // sets the open enrollment period to a range, then defaults to SEP when out of range (if no range is provided,
        // method is ignored)

        if (!this.isOpenEnrollmentPeriod && !this.isWindowShoppingPeriod && this.isMedicalSelected) {
            this.whichEnrollment = {
                prop: 'whichEnrollment',
                label: '',
                isDisabled: true,
                type: controlTypes.radio,
                options: [
                    {
                        label: 'See if I qualify for Special Enrollment.',
                        value: 'SEP',
                    },
                ],
                validators: ['required'],
            };
        } else {
            // if we get here, then we're in Open Enrollment period
            this.whichEnrollment = this.config.whichEnrollment ? this.config.whichEnrollment : cloneObject(whichEnrollment);
        }

        this.formControlService.addControlToFormGroup(this.form, this.whichEnrollment);
    }

    prefillEnrollmentPeriod(enrollmentPeriod) {
        if (this.whichEnrollment && this.whichEnrollmentControl) {
            this.whichEnrollmentControl.patchValue(enrollmentPeriod);
            this.handleNewEnrollmentPeriod(enrollmentPeriod);
        }
    }

    handleFormCreated() {
        if (this.initialEnrollmentPeriod) {
            this.prefillEnrollmentPeriod(this.initialEnrollmentPeriod);
        }

        if (this.whichEnrollment.options.length === 1) {
            this.prefillEnrollmentPeriod(this.whichEnrollment.options[0].value);
        }

        this.userChoiceSub = this.whichEnrollmentControl.valueChanges.subscribe((enrollmentPeriodChosen: validEnrollmentTypes) => {
            this.handleNewEnrollmentPeriod(enrollmentPeriodChosen);
            this.showCoverageEffectiveDateError = false;
            // Clear the effective date when switching from SEP -> OE and the current value is no longer an option on the dropdown
            if (enrollmentPeriodChosen === validEnrollmentTypes.OE && this.dropdownControl && this.dropdownControl.value) {
                this.dropdownControl.reset();
            }
        });
    }

    handleNewEnrollmentPeriod(enrollmentPeriodChosen: validEnrollmentTypes) {
        this.formattedText = {
            title: '',
            subtitle: '',
        };

        this.enrollmentPeriodChosen.emit(enrollmentPeriodChosen);

        // when we interact with the getStarted form, we shouldn't prefill the QLE values
        const setGetStartedPristine: boolean = !!this.getStartedForm ? this.getStartedForm.pristine : true;
        this.qleService.updateGetStartedPristine(setGetStartedPristine);
        // if whichEnrollment has changed by user input, we should reset the coverageForms
        this.resetCoverageEffectiveForms(!this.whichEnrollmentControl?.pristine);

        if (Array.isArray(this.coverageType) && this.coverageType.length) {
            const isDentalSelected: boolean = this.coverageType.some((type: string) => type === 'dental');
            const isVisionSelected: boolean = this.coverageType.some((type: string) => type === 'vision');
            const isDentalVision: boolean = this.coverageType.some((type: string) => type === 'dental/vision');
            this.coverageFormErrorSubscription = this.coverageEffectiveDateService
                .retrieveCoverageEffectiveDate(enrollmentPeriodChosen, this.coverageType, this.applicationType)
                .subscribe(
                    (result) => {
                        let planIndex: number;
                        let effectiveDates: Array<string>;

                        if (this.isMedicalSelected) {
                            planIndex = result.results.findIndex(
                                (plan) => (plan.plan_type === 'medical' || plan.plan_type === 'health') && plan.effective_dates.length !== 0
                            );
                            effectiveDates = getValue(result, `results.${planIndex}.effective_dates`);
                        } else if (isDentalSelected || isVisionSelected) {
                            planIndex = result.results.findIndex((plan) => plan.plan_type === 'vision' || plan.plan_type === 'dental');
                            effectiveDates = getValue(result, `results.${planIndex}.effective_dates`);
                        } else if (isDentalVision) {
                            planIndex = result.results.findIndex((plan) => plan.plan_type === 'dental/vision');
                            effectiveDates = getValue(result, `results.${planIndex}.effective_dates`);
                        }

                        if (effectiveDates && effectiveDates.length) {
                            if (this.config.multipleEffectiveDates) {
                                this.handleEffectiveDateOptions(effectiveDates);
                            } else {
                                // Only update effective date if there isn't one already available in the workflow
                                if (this.storedEffectiveDate.length === 0) {
                                    this.form.updateValueAndValidity();
                                    this.handleEffectiveDatePrefillingText(effectiveDates[0]);
                                }
                            }
                        } else {
                            this.handleErrorRetrievingEffectiveDate();
                        }
                    },
                    (err) => {
                        this.handleErrorRetrievingEffectiveDate();
                    }
                );
        }
    }

    handleEffectiveDateOptions(dates: Array<string>) {
        const context: { coverage_effective_date?: string } = {};

        if (this.coverageEffectiveSub) this.coverageEffectiveSub.unsubscribe();

        if (this.prefilledValue && this.enrollmentChosen) {
            context.coverage_effective_date = this.prefilledValue;
        }

        this.effectiveDateDropdownConfig = {
            ...this.effectiveDateDropdownConfig,
            options: [
                { label: 'Select One', value: null },
                ...dates.map((dateString) => {
                    return {
                        label: this.formattingService.restructureValueBasedOnFormat(dateString, { format: 'DATE' }),
                        value: dateString,
                    };
                }),
            ],
        };

        if (!this.dropdownControl) this.formControlService.addControlToFormGroup(this.form, this.effectiveDateDropdownConfig, context);

        this.coverageEffectiveSub = this.dropdownControl.valueChanges.subscribe((effectiveDate) => {
            if (effectiveDate) {
                this.form.updateValueAndValidity();
                this.checkPlansExistForCoverageEffectiveDate(effectiveDate);
            }
        });

        if (this.dropdownControl && this.prefilledValue && this.enrollmentChosen) {
            this.dropdownControl.patchValue(this.prefilledValue);
        }

        this._showCoverageDateSelector = true;

        // options change potentially in the middle of lifecycle hooks
        // this fixes ExpressionChangedAfterItHasBeenCheckedError
        if (this.cdr) {
            this.cdr.detectChanges();
        }
    }

    prefillEffectiveDate(value) {
        if (this.dropdownControl && value) {
            this.dropdownControl.patchValue(value);
        }
    }

    handleEffectiveDatePrefillingText(effectiveDate: string) {
        this.checkPlansExistForCoverageEffectiveDate(effectiveDate);
    }

    handleErrorRetrievingEffectiveDate() {
        this.formattedText = {
            title: '',
            subtitle: '',
        };
        this.effectiveYearFound.emit(null);
    }

    formatTextBasedOnChoice(date) {
        if (!!this.enrollmentChosen) {
            if (!!this.isQLEenabled) {
                // Setting a config to allow Ancillary OE text to be displayed even when we're out of the OpenEnrollmentPeriod
                if (this.config.useSepForAncillary) {
                    this.formattedText.title = stringBuilder(this.QLEtextTemplates.title['OE'], {
                        date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
                    });
                } else {
                    this.formattedText.title = stringBuilder(this.QLEtextTemplates.title[this.enrollmentChosen], {
                        date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
                    });
                }

                this.formattedText.subtitle = stringBuilder(this.QLEtextTemplates.subtitle[this.enrollmentChosen], {
                    date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
                });

                this.coverageEffectiveDateService.setQLECoverageEffectiveText(this.formattedText);
            } else {
                const isOpenEnrollment: boolean = this.coverageEffectiveDateService.isOpenEnrollmentPeriod(this.openEnrollmentRange);

                const titleConfig = !!this.config && !!this.config.title ? this.config : this.textTemplates;
                const titleText: string =
                    isOpenEnrollment && this.enrollmentChosen === 'OE' && titleConfig.title['OEPeriod']
                        ? titleConfig.title['OEPeriod']
                        : titleConfig.title[this.enrollmentChosen];

                this.formattedText.title = stringBuilder(titleText, {
                    date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
                });
                const subtitleConfig = !!this.config && !!this.config.subtitle ? this.config : this.textTemplates;
                const subtitleText: string =
                    isOpenEnrollment && this.enrollmentChosen === 'OE' && titleConfig.subtitle['OEPeriod']
                        ? subtitleConfig.subtitle['OEPeriod']
                        : subtitleConfig.subtitle[this.enrollmentChosen];
                this.formattedText.subtitle = stringBuilder(subtitleText, {
                    date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
                });
            }
        }
    }

    onChangeCoverageType() {
        // FormControl to subscribe to, either coverage_type on the current form
        // or a FormControl passed in to coverageTypeControl
        const cTypeControl = this.form.get('coverage_type') || this.form.get('coverage_types') || this.coverageTypeControl;

        this.shouldDisableSEP;

        if (cTypeControl) {
            const coverageTypeSubWithCurrValue = concat(of(cTypeControl.value), cTypeControl.valueChanges);
            this.coverageTypeSub = coverageTypeSubWithCurrValue.subscribe((val) => {
                if (val) {
                    let productInitialEnrollmentPeriod: string;
                    let valueArray: Array<string>;

                    // due to the change from coverage_type to coverage_types, we need to ensure this is always returning an array
                    !Array.isArray(val) && typeof val === 'string' ? (valueArray = [val]) : (valueArray = val);
                    this._coverageType = valueArray;

                    const medicalExists = valueArray.find((coverageType) => coverageType === 'medical' || coverageType === 'health');
                    const dentalVisionExists = valueArray.find(
                        (coverageType) => coverageType === 'dental' || coverageType === 'vision' || coverageType === 'dental/vision'
                    );

                    valueArray?.length && this.isOpenEnrollmentPeriod
                        ? (productInitialEnrollmentPeriod = validEnrollmentTypes.OE)
                        : (productInitialEnrollmentPeriod = validEnrollmentTypes.SEP);

                    if (this.isOpenEnrollmentPeriod && (valueArray.includes('medical') || valueArray.includes('health'))) {
                        if (!this.prefilledValue && !this.config.isDefaultOEP) {
                            this.whichEnrollmentControl.reset();
                        } else if (!cTypeControl.pristine && !this.config.isDefaultOEP) {
                            this.whichEnrollmentControl.reset();
                        }
                    }

                    // When we have a dropdown, we're not resetting the forms the way we'd expect
                    const setGetStartedPristine: boolean = !!this.getStartedForm ? !this.getStartedForm.pristine : false;
                    this.resetCoverageEffectiveForms(setGetStartedPristine);

                    // We do not want the enrollment period prefilled if the array is filled with nulls, so this will filter that out
                    const isFlattened: Array<string> = flatten(valueArray);
                    const isCompacted: Array<string> = compact(isFlattened);
                    const isEmptyArray: boolean = isEmpty(isCompacted);

                    // Prefill the enrollment period for this coverage type only when 'medical'/'health' isn't set
                    // or when out of OE
                    if (productInitialEnrollmentPeriod && !isEmptyArray && (!medicalExists || !this.isOpenEnrollmentPeriod)) {
                        // during window shopping do not prefill the enrollment period and ensures it resets
                        // unless medical isn't selected to allow for SEP/OE dental or vision flows
                        if (!this.isWindowShoppingPeriod || (dentalVisionExists && !medicalExists)) {
                            this.prefillEnrollmentPeriod(productInitialEnrollmentPeriod);
                        } else if (!cTypeControl.pristine) {
                            this.whichEnrollmentControl.reset();
                        }
                    }
                }
            });
        }
    }

    resetCoverageEffectiveForms(resetForms: boolean) {
        // Entire form that's tracked in eligibilty-stepper
        const localCoverageEffectiveControl: FormControl = this.form.controls['coverage_effective_date'] as FormControl;

        // when there are changes by user input, reset the coverage effective controls
        if (resetForms) {
            this.prefilledValue = null;
            // if whichEnrollment was updated by the user, regardless of getStarted updates
            // force the user to re-enter the QLE form data
            this.qleService.updateGetStartedPristine(this.whichEnrollmentControl?.pristine);
            const coverageEffectiveControl: FormControl = this.parentEligibilityForm?.controls['coverage_effective_date'] as FormControl;
            const effectiveDateGroup: FormGroup = this.parentEligibilityForm?.controls['effective_date'] as FormGroup;
            const effectiveDateControl: FormControl = effectiveDateGroup?.controls['coverage_effective_date'] as FormControl;

            if (!!coverageEffectiveControl) {
                coverageEffectiveControl.reset();
            }

            if (!!localCoverageEffectiveControl) {
                localCoverageEffectiveControl.reset();
            }

            if (!!effectiveDateControl) {
                effectiveDateControl.reset();
            }
        }
    }

    get checkForCoverageEffectiveErrorMessage(): boolean {
        return (
            this.showCoverageEffectiveDateError && !this.showQLEs && !this.form.controls.hasOwnProperty(effectiveDateDropdownConfig.prop)
        );
    }

    get showQLEs(): boolean {
        return this.isMedicalSelected && !!this.isQLEenabled && this.enrollmentChosen === 'SEP';
    }

    private checkPlansExistForCoverageEffectiveDate(effectiveDate: string): void {
        this.showCoverageEffectiveDateError = false;
        if (this.getStartedForm?.valid || this.form?.valid) {
            this.updateErrorMessageIfPlansExist(effectiveDate);
        }
    }

    private updateErrorMessageIfPlansExist(effectiveDate: string): void {
        this.coverageFormErrorSubscription = this.plansAPIService
            .getShoppingPlans(effectiveDate, this.getStartedForm?.getRawValue() || this.form?.getRawValue())
            .subscribe(
                (response: ApiListResponse<Plan>) => {
                    if (response.count !== 0) {
                        this.isPlanExist = true;
                    }

                    const error = !this.isPlanExist ? { customErrMessage: true } : null;

                    this.setControlErrors(error, effectiveDate);
                },
                (error) => {
                    this.setControlErrors({ customErrMessage: true });
                }
            );
    }

    private setControlErrors(error: Object, effectiveDate?: string) {
        if (this.form?.controls.hasOwnProperty(effectiveDateDropdownConfig.prop)) {
            this.form.controls[effectiveDateDropdownConfig.prop].setErrors(error);
        }
        this.showCoverageEffectiveDateError = !!error;
        if (!error) {
            this.effectiveYearFound.emit(effectiveDate);
            this.formatTextBasedOnChoice(effectiveDate);
        } else {
            this.handleErrorRetrievingEffectiveDate();
        }
    }
}
