import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadEnd, Router, RouterEvent } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { applyIconsFromConfig, getValue } from '@zipari/web-utils';
import { AnalyticsService, AuthService, BrowserSupportService, ConfigService, IdleService } from '@zipari/shared-sbp-services';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GlobalConfig, KeepaliveConfig, TimeoutConfig } from './app.constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public isAppLoading = true;
    public lastPing: Date;
    public typeface;

    constructor(
        public authService: AuthService,
        private keepalive: Keepalive,
        public idleService: IdleService,
        public configService: ConfigService,
        public title: Title,
        private router: Router,
        private http: HttpClient,
        private analyticsService: AnalyticsService,
        private browserSupportService: BrowserSupportService
    ) {
        const globalConfig: GlobalConfig = this.configService.getPageConfig<GlobalConfig>('global');

        this.setupTimeouts(globalConfig.timeout);
        this.setTitle(globalConfig.title);
        this.setupRouterCheckForAuth(globalConfig);

        if (globalConfig?.analytics?.captureGlobalClickEvents) {
            this.analyticsService.initGlobalClickEvents();
        }

        if (globalConfig.browserSupport?.showModal) {
            this.browserSupportService.openBrowserSupportModal();
        }
    }

    ngOnInit() {
        this.changeThemeFromNewConfig();
    }

    setTitle(titleConfig: string): void {
        if (titleConfig) {
            this.title.setTitle(titleConfig);
        }
    }

    setupTimeouts(timeoutConfig: TimeoutConfig): void {
        const config: TimeoutConfig = new TimeoutConfig(timeoutConfig);

        this.idleService.setupIdle(config.idle);
        this.setupKeepalive(config.keepalive);
    }

    /** documentation can be found @ https://hackedbychinese.github.io/ng2-idle/ */
    setupKeepalive(keepaliveConfig: KeepaliveConfig): void {
        if (keepaliveConfig.enabled) {
            this.keepalive.onPing.subscribe(() => {
                this.lastPing = new Date();

                this.http.get(keepaliveConfig.endpoint).subscribe(
                    () => {},
                    (err: Error) => {
                        if (keepaliveConfig.error.link) {
                            window.location.assign(keepaliveConfig.error.link);
                        } else if (keepaliveConfig.error.route) {
                            this.router.navigate([keepaliveConfig.error.route]);
                        }
                    }
                );
            });

            // Sets the ping keepaliveInterval to 15 seconds
            this.keepalive.interval(keepaliveConfig.interval);
        }
    }

    setupRouterCheckForAuth(globalConfig): void {
        // Subscribe to router events to show loading indicator when app modules
        // (e.g. broker, shopping portals) are compiling
        // navigate to app route when url redirect fails
        this.router.events
            .pipe(
                tap((event) => {
                    // check for a default route
                    let defaultRoute = getValue(globalConfig, 'defaultRoute') || getValue(environment, 'configs.defaultRoute');

                    // if no route is specified and config has an alternate default route, then route there
                    if (defaultRoute && event['url'] === '/') {
                        this.router.navigate([defaultRoute]);
                    }

                    defaultRoute = defaultRoute ?? 'applications';

                    if (event['urlAfterRedirects'] === '/shopping/applications') {
                        if (defaultRoute !== 'applications') {
                            this.router.navigate([this.configService.appRoute, defaultRoute], {
                                queryParamsHandling: 'merge',
                            });
                        }
                    }

                    if (event instanceof NavigationEnd && this.checkForNonAppUrl(event)) {
                        this.router.navigate([this.configService.appRoute], {
                            queryParamsHandling: 'merge',
                        });
                    }
                    if (
                        this.authService.loggedInUser &&
                        this.authService.loggedInUser.app_user_data &&
                        this.idleService.enabled &&
                        !this.idleService.isIdling
                    ) {
                        this.idleService.resetTimeout();
                    } else if (this.idleService.isIdling) {
                        this.idleService.stopTimeout();
                    }
                }),
                filter((event) => event instanceof RouteConfigLoadEnd || event instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.isAppLoading = false;
            });
    }

    applyTheme(themeConfig) {
        const root = document.documentElement;

        Object.keys(themeConfig).forEach((key) => {
            if (typeof themeConfig[key] === 'string') {
                if (key === '--typeface-1-url') {
                    this.typeface = themeConfig[key];
                }

                root.style.setProperty(key, themeConfig[key]);
            } else {
                this.applyTheme(themeConfig[key]);
            }
        });
    }

    /** Tracks config changes and updates theme based on new theme in configs.
     * Also sets the app config in the auth service as well.
     * */
    private changeThemeFromNewConfig() {
        this.configService.configUpdated.subscribe((config) => {
            const theme = config.theme;

            this.authService.setAppConfig(config);

            if (theme) {
                if (theme) {
                    this.applyTheme(theme);
                }
            }

            const icons = config.icon_library;
            if (!!icons) {
                applyIconsFromConfig(icons);
            }
        });
    }

    private checkForNonAppUrl(event: NavigationEnd): boolean {
        return event.urlAfterRedirects.split('?')[0] === '/';
    }
}
