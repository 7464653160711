export const mockAllPlansByPlanType = [
    {
        id: 9390,
        hsa: false,
        year: 2024,
        price: '19.40',
        county: {
            county_name: 'Essex',
            fips_county_code: '34013',
        },
    },
    {
        id: 9390,
        hsa: false,
        year: 2024,
        price: '19.40',
        county: {
            county_name: 'Essex',
            fips_county_code: '34013',
        },
    },
];

export const mockFilteredAllPlansByPlanType = [
    {
        id: 9390,
        hsa: false,
        year: 2024,
        price: '19.40',
        county: {
            county_name: 'Essex',
            fips_county_code: '34013',
        },
    },
];
