import { Injectable } from '@angular/core';
import { cloneObject, groupBy } from '@zipari/web-utils';
import { PlansAPIService } from '@zipari/shared-sbp-modules';
import { ApiListResponse, Plan } from '@zipari/shared-sbp-models';
import { PlanUtilService } from '@zipari/shared-sbp-services';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

@Injectable()
export class PlansStoreService {
    busy: Subscription;
    endpoint: string;
    planType: string;
    private _plansComparingLocked = new BehaviorSubject<Array<Plan>>([]);
    plansComparingLocked$: Observable<Array<Plan>> = this._plansComparingLocked.asObservable();

    constructor(public planService: PlanUtilService, private plansApiService: PlansAPIService) {}

    public get plansSelectedLength(): number {
        return this._plansSelected.value.length;
    }

    public get plans$(): Observable<Array<Plan>> {
        return this._plans.asObservable();
    }

    public get plansSelected$(): Observable<Array<Plan>> {
        return this._plansSelected.asObservable();
    }

    public get plansComparing$(): Observable<Array<Plan>> {
        return this._plansComparing.asObservable();
    }

    public get plansFilteredAndSorted$(): Observable<Array<Plan>> {
        return this._plansFilteredAndSorted.asObservable();
    }

    public get plansSelectedByPlanType$(): Observable<{ [plan_type: string]: Array<Plan> }> {
        return this.plansSelected$.pipe(map((plans) => groupBy(plans, 'plan_type')));
    }

    private _plans = new BehaviorSubject<Array<Plan>>([]);

    private get plans(): Array<Plan> {
        return this._plans.getValue();
    }

    private _plansFilteredAndSorted = new BehaviorSubject<Array<Plan>>([]);

    private get plansFilteredAndSorted(): Array<Plan> {
        return this._plansFilteredAndSorted.getValue();
    }

    private _plansSelected = new BehaviorSubject<Array<Plan>>([]);

    private get plansSelected(): Array<Plan> {
        return this._plansSelected.getValue();
    }

    private _plansComparing = new BehaviorSubject<Array<Plan>>([]);

    private get plansComparing(): Array<Plan> {
        return this._plansComparing.getValue();
    }

    lockPlanForCompare(plan: Plan) {
        const alreadyLocked: boolean = !!this._plansComparingLocked.value.find((planComparing: Plan) => {
            planComparing.external_id === plan.external_id;
        });

        if (!alreadyLocked) this._plansComparingLocked.next([...this._plansComparingLocked.value, plan]);
    }

    public getPlans(queryParams = { plan_type: null }, endpoint?, keepPlans = false): Observable<ApiListResponse<Plan>> {
        this.endpoint = endpoint;
        this.planType = queryParams.plan_type;

        this._plansFilteredAndSorted.next([]);

        // medical / dental / health... all have plan variations that should be set
        // vision plans do not and there are potentially other plan types that do not and they need to have their plan
        // variation removed from the plans call
        const validOnExchangePlanTypes = {
            medical: true,
            health: true,
            dental: true,
        };
        const params = cloneObject(queryParams);
        if (params['plan_variation'] && !validOnExchangePlanTypes[params.plan_type]) {
            delete params['plan_variation'];
        }

        return this.fetchPlans(params, endpoint).pipe(
            tap((response) => {
                // Preserve selected plans from API response, yeah yeah O(n*m)
                let plans = this.mergeSelectedAndComparingPlans(response.results);

                // todo: move this logic into BE bug make work for now
                if (queryParams['plan_variation']) {
                    const plan_variation = queryParams['plan_variation'];

                    if (plan_variation) {
                        const csrVariations = ['02', '03', '04', '05', '06'];

                        let isCSR = false;
                        if (Array.isArray(plan_variation)) {
                            isCSR = plan_variation.every((variation) => csrVariations.includes(variation));
                        } else {
                            isCSR = csrVariations.includes(plan_variation);
                        }

                        if (isCSR) {
                            plans = plans.filter(
                                (plan: Plan) => !(plan.metal_tier.toLowerCase() === 'silver' && plan.plan_variation === '01')
                            );
                        }
                    }
                }
                // todo: move this logic into BE bug make work for now
                if (!keepPlans) {
                    this._plans.next(plans);
                    this._plansFilteredAndSorted.next(plans);
                }
            })
        );
    }

    public clearPlans(): void {
        this._plans.next([]);
        this._plansFilteredAndSorted.next([]);
    }

    public filterPlans(filters: any) {
        return this.fetchPlans(filters, this.endpoint).subscribe((response) => {
            // Preserve selected plans from API response, yeah yeah O(n*m)
            const plans = this.mergeSelectedAndComparingPlans(response.results);
            this._plans.next(plans);
            this._plansFilteredAndSorted.next(plans);
        });
    }

    public togglePlanToCompare(planToToggle: Plan, maxComparable: number) {
        if (this.plansComparing.length < maxComparable || planToToggle.comparing) {
            this.setPlanProperty(planToToggle, 'comparing', !planToToggle.comparing);
        }

        if (!planToToggle.comparing && this.plansComparing.length < maxComparable) {
            this._plansComparing.next([...this.plansComparing, { ...planToToggle, comparing: true }]);
        } else {
            this._plansComparing.next(
                this.plansComparing.filter((plan) => {
                    return plan.id !== planToToggle.id;
                })
            );
        }
    }

    public removePlanToCompare(planToRemove: Plan) {
        this.setPlanProperty(planToRemove, 'comparing', false);
        this._plansComparing.next(
            this.plansComparing.filter((plan) => {
                return plan.id !== planToRemove.id;
            })
        );
    }

    public updateSelectedPlans(plans: Array<Plan>) {
        this._plansSelected.next(plans);
    }

    /**
     * @param deselectOtherPlans flag to allow only one plan selected at a time
     */
    public togglePlanSelected(planToToggle: Plan, deselectOtherPlans: boolean = false) {
        if (deselectOtherPlans) {
            this.clearSelectedPlans();
        }

        this.setPlanProperty(planToToggle, 'selected', !planToToggle.selected);

        if (!planToToggle.selected) {
            const plansSelected = deselectOtherPlans ? [] : this.plansSelected;
            this._plansSelected.next([...plansSelected, { ...planToToggle, selected: true }]);
        } else {
            this._plansSelected.next(
                this.plansSelected.filter((plan) => {
                    return plan.id !== planToToggle.id;
                })
            );
        }
    }

    public clearSelectedPlans() {
        this._plansFilteredAndSorted.next([
            ...this.plansFilteredAndSorted.map((plan) => {
                return {
                    ...plan,
                    selected: false,
                };
            }),
        ]);
    }

    public removePlanSelected(planToRemove: Plan) {
        this.setPlanProperty(planToRemove, 'selected', false);
        this._plansSelected.next(
            this.plansSelected.filter((plan) => {
                return plan.id !== planToRemove.id;
            })
        );
    }

    public fetchPlans(queryParams = {}, endpoint?): Observable<ApiListResponse<Plan>> {
        return this.plansApiService.fetchPlans(queryParams, endpoint).pipe(
            map((planResponse) => {
                planResponse.results = planResponse.results.map((plan) => this.planService.formatBenefitsOnPlan(plan));

                return planResponse;
            })
        );
    }

    private setPlanProperty(planToSet: Plan, property, value) {
        this._plansFilteredAndSorted.next(
            this.plansFilteredAndSorted.map((plan) => {
                if (plan.id === planToSet.id) {
                    return { ...plan, [property]: value };
                }
                return plan;
            })
        );
    }

    // Preserve selected/comparing plans (aka user's "cart") when resetting plans
    private mergeSelectedAndComparingPlans(plans: Array<Plan>): Array<Plan> {
        return plans.map((plan) => {
            const selected: boolean = !!this.plansSelected.find((selectedPlan) => selectedPlan.id === plan.id);
            const comparing: boolean = !!this.plansComparing.find((comparingPlan) => comparingPlan.id === plan.id);

            return {
                ...plan,
                selected,
                comparing,
            };
        });
    }
}
