import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface PlanFilter {
    prop: string;
    type: string;
    label: string;
    default?: any;
    options?: Array<any>;
}

@Component({
    selector: 'plan-filters',
    templateUrl: './plan-filters.component.html',
    styleUrls: ['./plan-filters.component.scss'],
})
export class PlanFiltersComponent {
    @Input() filters: Array<PlanFilter>;
    @Input() currentFilters: any;
    @Input() sortHeader: string = 'Sort';
    @Input() filterHeader: string = 'Filters';
    @Input() filterResetButton: string = 'Reset';
    @Input() filterApplyButton: string = 'Apply';
    @Input() hideFilterFooter: boolean = false;
    @Input() openAccordion: boolean;

    @Output() filtersOut = new EventEmitter();
    @Output() closeFilters = new EventEmitter();
    filterForm: FormGroup = new FormGroup({});

    get hideFilters(): boolean {
        return this.filters && !this.filters.length;
    }

    get filterForms() {
        return this.filters.filter((filter) => filter.prop !== 'ordering');
    }

    get sortForms() {
        return this.filters.filter((filter) => filter.prop === 'ordering');
    }

    handleFormGroupCreated() {
        this.filterForm.patchValue(this.currentFilters || {});
    }

    applyFilters() {
        this.filterForm.updateValueAndValidity();

        let filterFormVal = { ...this.filterForm.value };
        Object.keys(filterFormVal).forEach((key) => {
            if (filterFormVal[key] === null) {
                delete filterFormVal[key];
            }

            // some values are objects bc they require multilple query params
            if (typeof filterFormVal[key] === 'object') {
                filterFormVal = { ...filterFormVal, ...filterFormVal[key] };
                delete filterFormVal[key];
            }
        });

        this.filtersOut.emit(filterFormVal);
    }

    clearFilters() {
        this.filtersOut.emit(null);
        const defaultValues = this.filters.map((filter) => ({ [filter.prop]: filter.default })).reduce((_, filter) => ({ ...filter }), {});
        this.filterForm.reset(defaultValues);
    }

    close() {
        this.closeFilters.emit();
    }

    handleSortFormCreated() {
        const sortControl: FormControl = this.filterForm.get(this.sortForms[0].prop) as FormControl;

        sortControl.valueChanges.subscribe(() => {
            this.applyFilters();
        });
    }
}
