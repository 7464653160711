<div class="container">
    <div [ngClass]="{ 'u-justifyEnd': justifyEnd }" class="u-flex u-alignCenter u-justifyBetween">
        <zip-button *ngIf="showBackButton" [config]="backButtonConfig" [disabled]="isBackDisabled" (zipButtonClicked)="back.emit()">
        </zip-button>

        <div class="u-flex u-alignCenter button_group">
            <!-- 
                * commenting out as this was pulled from plan selection.
                * not sure if we'll need this in the future, but don't want to loose it
                TODO: Determine if we need this functionality - hlopez
            -->

            <!-- 
            <ng-container *ngIf="!showShoppingCart">
                <ng-container *ngIf="selectedPlan && !configs?.showPlanChips">
                    <ng-template #singlePlanTemplate>
                        <p *ngIf="selectedPlan.display_name" class="t-data">
                            <span class="t-link" (click)="onSelectedPlanClick()">{{ selectedPlan.display_name }}</span>
                        </p>
                    </ng-template>

                    <p class="t-data" *ngIf="selectedPlan.length > 1; else singlePlanTemplate">{{ selectedMultiPlan }}</p>
                </ng-container>

                <ng-container *ngIf="selectedPlan && configs?.showPlanChips">
                    <div *ngIf="(plansStore.plansSelected$ | async).length" class="u-flex u-alignCenter">
                        <p class="t-data">{{ selectedSinglePlan }}</p>
                        <ng-container *ngFor="let planType of planTypes">
                            <div class="plan__selection-count" *ngIf="plansSelectedByPlanType[planType.prop]">
                                <p class="plan__selection-count__indicator">{{ plansSelectedByPlanType[planType.prop].length }}</p>
                                <p class="t-bold">{{ planType.label }}</p>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            -->

            <ng-container *ngIf="showShoppingCart">
                <shopping-cart [hidePrice]="configs?.quoting" [workflow]="workflowObject"> </shopping-cart>
            </ng-container>

            <div class="u-flex" style="padding-left: 0.25rem">
                <zip-button *ngIf="isAuthenticated" [config]="saveAndExitConfig" (zipButtonClicked)="saveAndExit.emit()"></zip-button>
                <ng-container *ngIf="!isSubmitted; else goToDashboard">
                    <zip-button [config]="nextButtonConfig" [disabled]="isNextDisabled" (zipButtonClicked)="next.emit()"> </zip-button>
                </ng-container>
                <ng-template #goToDashboard>
                    <zip-button level="high" content="Go to My Dashboard"></zip-button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
