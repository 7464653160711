export class MemberTotalInput {
    subscriber?: MemberTypeValue;
    spouse?: MemberTypeValue;
    dependents?: MemberTypeValue[];
    stepParents?: MemberTypeValue[];
}

export class MemberTypeValue {
    address?: AddressData;
    home_phone?: string;
    medicare_enrolled?: string;
    primary_phone?: string;
    sex?: string;
    coverage_type?: string[];
    date_of_birth: string;
    gender?: string;
    mailing_address?: MailingAddressData;
    memberType: string;
    memberTypeUnique: string;
    name: string;
    tobacco_use: boolean;
    tobacco_user: boolean;
    uuid: string;
    child_apartment_number?: string;
    child_city?: string;
    child_state?: string;
    child_street_address?: string;
    child_zipcode?: string;
    county?: string;
    county_display?: string;
    county_name?: string;
    state_name?: string;
    different_mailing_address?: boolean;
}

export class AddressData {
    county: string;
    county_display: string;
    county_name: string;
    state: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
    zipcode: string;
}

export class MailingAddressData {
    county: string;
    county_display: string;
    county_name: string;
    state: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
    zipcode: string;
}

export class EligiblityStepperValue {
    coverage_effective_date: string;
    demographics: any;
    permanent_address: {
        county: string;
        zipcode: string;
    };
    members: any;
    total_members: number;
}

export type Workflow = any;

export interface EligibilityConfig {
    /** Backwards compatible */
    [zipari: string]: any;
    /** Get coverage type from API on state selection */
    coverageByState: boolean;
}
