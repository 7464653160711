import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ButtonModule } from '@zipari/design-system';

import { ContentSkipComponent } from './content-skip.component';

@NgModule({
    declarations: [ContentSkipComponent],
    imports: [CommonModule, ButtonModule],
    exports: [ContentSkipComponent],
})
export class ContentSkipModule {}
