import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { PlansAPIService, StepperPrefillVal } from '@zipari/shared-sbp-modules';
import { ApplicationTypes } from '@zipari/shared-sbp-constants';
import { effectiveDateDropdownConfig } from '@zipari/shared-sbp-templates';
import { Subscription } from 'rxjs';
import { QLEService } from '../../../../../templates/src/lib/enrollment/qle/qle.service';
import { BaseCVAComponent } from './../base-cva.component';

@Component({
    selector: 'coverage-effective-control',
    templateUrl: './coverage-effective-control.component.html',
    styleUrls: ['./coverage-effective-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoverageEffectiveControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CoverageEffectiveControlComponent),
            multi: true,
        },
    ],
})
export class CoverageEffectiveControlComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() group;
    @Input() config;
    @Input() prefillVal: StepperPrefillVal = {};

    @Output() formCreated = new EventEmitter();

    enrollmentPeriod;
    coverageGroup = new FormGroup({});
    coverageTypeControl;
    applicationType: ApplicationTypes;
    effectiveDateDropdownConfig = effectiveDateDropdownConfig;
    planSubscription: Subscription;
    showCoverageEffectiveDateError: boolean;

    constructor(private formControlService: FormControlService, private qleService: QLEService, private plansAPIService: PlansAPIService) {
        super();
    }

    get getStartedForm(): FormGroup {
        return this.group.root.get('getStarted') as FormGroup;
    }

    ngOnInit() {
        this.formControlService.addControlToFormGroup(this.group, {
            type: 'text',
            prop: 'coverage_effective_date',
        });

        this.formControlService.addControlToFormGroup(this.group, {
            type: 'text',
            prop: 'enrollment_period',
        });

        if (this.config.valueOnRoot) {
            this.formControlService.addControlToFormGroup(this.group.root, {
                type: 'text',
                prop: 'coverage_effective_date',
            });
        }

        this.applicationType = this.prefillVal.applicationType;

        this.formCreated.emit();
        this.coverageTypeControl =
            this.group.root.get(this.config.coverageTypePath) || this.group.root.get('getStarted').get('coverage_types');
    }

    ngOnDestroy() {
        this.planSubscription?.unsubscribe();
        super.ngOnDestroy();
    }

    handleNewEnrollmentPeriod(enrollmentPeriod) {
        this.enrollmentPeriod = enrollmentPeriod;

        this.qleService.updateGetStartedPristine(this.getStartedForm.pristine);

        this.group.get('enrollment_period').patchValue(this.enrollmentPeriod);
    }

    handleNewEffectiveDate(effectiveDate) {
        this.group.get(effectiveDateDropdownConfig.prop).patchValue(effectiveDate);

        if (this.config.valueOnRoot) {
            this.group.root.get(effectiveDateDropdownConfig.prop).patchValue(effectiveDate);
        }
    }
}
