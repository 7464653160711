import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResourceCenterComponent } from './resource-center.component';
import { FooterModule } from '../../../../modules/src/lib/footer/footer.module';
import { SharedModule } from '../../../../modules/src/lib/shared.module';
import { TableModule } from '../../../../modules/src/lib/table/table.module';

@NgModule({
    declarations: [ResourceCenterComponent],
    imports: [CommonModule, SharedModule, TableModule, FooterModule],
    exports: [ResourceCenterComponent],
})
export class ResourceCenterModule {}
