import { RadioConfiguration } from '@zipari/shared-ds-util-form';
import { defaultErrorMessages } from '@zipari/shared-sbp-constants';
import { validEnrollmentTypes } from '../../../../../services/src/lib/coverage-effective-date.service';

export const whichEnrollment = {
    prop: 'whichEnrollment',
    label: '',
    type: 'radio',
    options: [
        {
            label: 'See if I qualify for Special Enrollment.',
            value: 'SEP',
        },
        {
            label: 'Continue with standard Open Enrollment.',
            value: 'OE',
        },
    ],
    validators: ['required'],
};

export enum coverageEffectiveDisplayTypes {
    form = 'form',
}

export class CoverageEffectiveConfig {
    multipleEffectiveDates?: boolean;
    disableSEP?: boolean;
    disableOE?: boolean;
    displayType?: coverageEffectiveDisplayTypes;
    isDisabled?: boolean;
    title?: { SEP: string; OE: string };
    subtitle?: { SEP: string; OE: string };
    label?: string;
    whichEnrollment?: RadioConfiguration;
    isDefaultOEP?: boolean;
    useSepForAncillary?: boolean;
}

export const effectiveDateDropdownConfig = {
    isDisabled: false,
    placeholder: 'Select one',
    label: 'Coverage Effective Date',
    prop: 'coverage_effective_date',
    type: 'dropdown',
    errors: {
        customErrMessage: defaultErrorMessages.noPlansForEffectiveDate,
    },
    options: [],
    validators: ['required'],
};

export const intitialEnrollmentPeriods = {
    'dental/vision': validEnrollmentTypes.OE,
    vision: validEnrollmentTypes.OE,
    dental: validEnrollmentTypes.OE,
};

export const disableSEPForCoverageTypes = {
    'dental/vision': true,
    vision: true,
    dental: true,
};

export interface CoverageEffectiveDateRange {
    startDate: string;
    endDate: string;
}

export enum coverageType {
    medical = 'medical',
    health = 'health',
    dental = 'dental',
    vision = 'vision',
    dentalVision = 'dental/vision',
    dentalVisionWithUnderscore = 'dental_vision',
}

export const pagination = {
    page: 1,
    page_size: 1,
};
