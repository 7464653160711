import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@zipari/shared-sbp-services';
import { NavigationService } from '../../../../modules/src/lib/navigation/navigation.service';

@Component({
    selector: 'market-selection',
    templateUrl: './market-selection.component.html',
    styleUrls: ['./market-selection.component.scss'],
})
export class MarketSelectionComponent implements OnInit, OnDestroy {
    config: any;
    isFullScreen = false;

    constructor(public configService: ConfigService, public router: Router, public navigationService: NavigationService) {}

    ngOnInit(): void {
        this.navigationService.initConfig();
        this.config = this.configService.getPageConfig('market-selection') || this.configService.configs['market-selection'];

        if (this.config && !!this.config.fullScreen) {
            this.navigationService.setNavHidden(true);
            this.isFullScreen = !!this.config.fullScreen;
        }
    }

    ngOnDestroy() {
        this.navigationService.setNavHidden(false);
    }

    onClick(card) {
        if (card.externalLink) {
            window.open(card.externalLink, '_self');
        } else {
            this.router.navigate([card.route]);
        }
    }
}
