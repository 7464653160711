import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

export const mockUserErrorConfig = {
    error: {
        errors: [
            {
                user_error: 'User with that email address already exists',
            },
        ],
    },
};

export const mockErrorConfig = {
    error: [
        {
            sp_account_already_exists: 'User with that email address already exists',
        },
    ],
};

export const mockCurrentDetailCache = {
    'policy-detail': {},
};

export const mockEndpoint = 'api/enrollment/policies/1631302632/';
export const mockErrorMessage = 'Error fetching data';
export const mockPageName = 'policy-detail';
export const mockDefaultErrorMessage = 'default error message';

export const mockActivatedRoute = {
    title: undefined,
    url: undefined,
    params: undefined,
    queryParams: undefined,
    fragment: undefined,
    data: undefined,
    outlet: '',
    component: null,
    snapshot: new ActivatedRouteSnapshot(),
    routeConfig: undefined,
    root: new ActivatedRoute(),
    parent: new ActivatedRoute(),
    firstChild: new ActivatedRoute(),
    children: [],
    pathFromRoot: [],
    paramMap: undefined,
    queryParamMap: undefined,
};

export const mockFakeResponse = {
    id: 1631302632,
    renewal_quote_id: null,
    number_of_subscribers: 0,
};
