export const ErrorMessageKey = {
    accountAlreadyExist: 'sp_account_already_exists',
};

export class ZipBackendError {
    user_error: string | unknown;
}

export class ZipBackendErrorResponse {
    status?: number | string;
    error: {
        errors: ZipBackendError[];
    };
}
