import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConfirmationModalConfig, ConfirmationModalWrapperComponent } from '@zipari/shared-sbp-components';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private overlay: Overlay) {}

    cancelSubscription: Subscription;
    confirmSubscription: Subscription;

    public presentModal(config: ConfirmationModalConfig, context, confirmCallback?: Function, cancelCallback?: Function) {
        const backdropClass = !!config.altBackDropClass ? config.altBackDropClass : 'modal__mask';
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: [backdropClass],
        });
        const modalComponent = new ComponentPortal(ConfirmationModalWrapperComponent);
        const containerRef = overlayRef.attach(modalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.config = config;
        containerRef.instance.context = context;

        this.confirmSubscription = containerRef.instance.confirm.subscribe(() => {
            if (confirmCallback) {
                confirmCallback();
            }
            overlayRef.detach();
            this.confirmSubscription.unsubscribe();
        });

        this.cancelSubscription = containerRef.instance.cancel.subscribe(() => {
            if (cancelCallback) {
                cancelCallback();
            }
            overlayRef.detach();
            this.cancelSubscription.unsubscribe();
        });
    }
}
