import Agency from './Agency.model';
import License from './License.model';

export default class Broker {
    id: number;
    broker_id: string;
    agency_id: string;
    has_user: boolean;
    role: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    date_of_birth: string;
    status: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    agent_effective_date: string;
    npn: string;
    phone_number: string;
    email_address: string;
    tin: string;
    agency: Agency;
    licenses: Array<License>;
}

export interface BrokerShoppingEndpointURL {
    brokerPortalEndpoint: string;
    shoppingEndpoint: string;
}
