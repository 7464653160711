export enum ApplicationTypes {
    enrollment = 'enrollment',
    upsell = 'upsell',
    renewal = 'renewal',
}

export const defaultMemberOptionsConfig = {
    subscriber: {
        value: 'subscriber',
        cards: ['subscriber'],
    },
    spouse: {
        value: 'spouse',
        cards: ['subscriber', 'spouse'],
    },
    family: {
        value: 'family',
        cards: ['subscriber', 'spouse', 'children'],
    },
    parent: {
        value: 'parent',
        cards: ['subscriber', 'children'],
    },
    children: {
        value: 'children',
        cards: ['child_only'],
    },
    subscriber_spouse_children_parent: {
        value: 'subscriber_spouse_children_parent',
        cards: ['subscriber', 'spouse', 'children', 'stepParents'],
    },
    subscriber_spouse_parent: {
        value: 'subscriber_spouse_parent',
        cards: ['subscriber', 'spouse', 'stepParents'],
    },
    subscriber_children_parent: {
        value: 'subscriber_children_parent',
        cards: ['subscriber', 'children', 'stepParents'],
    },
    subscriber_parent: {
        value: 'subscriber_parent',
        cards: ['subscriber', 'stepParents'],
    },
};
