export const coverSpouseConfig = {
    label: 'Does the subscriber want to cover a spouse / partner',
    prop: 'spouse_covered',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
};

export const coverDepConfig = {
    label: 'Does the subscriber want to cover any dependents',
    prop: 'deps_covered',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
};

export const subscriberConfig = {
    text: {
        subscriber: 'Subscriber',
    },
    controls: [
        {
            type: 'date',
            label: 'Date of Birth',
            max: '9999-01-01',
            prop: 'date_of_birth',
            placeholder: 'YYYY-MM-DD',
            validators: [
                'required',
                'reasonableDate',
                'dateInPast',
                {
                    name: 'minAge',
                    dependency: {
                        age: 18,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
            ],
        },
    ],
};

export const spouseConfig = {
    text: {
        spouse: 'Spouse',
    },
    controls: [
        {
            type: 'date',
            label: 'Date of Birth',
            max: '9999-01-01',
            prop: 'date_of_birth',
            placeholder: 'YYYY-MM-DD',
            validators: [
                'required',
                'reasonableDate',
                'dateInPast',
                {
                    name: 'minAge',
                    dependency: {
                        age: 18,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
            ],
        },
    ],
};

export const parentDependentConfig = {
    text: {
        parent: 'Parent(s)/Step-Parent(s)',
    },
    controls: [
        {
            prop: 'date_of_birth',
            type: 'date',
            label: 'Date of Birth',
            errors: {
                maxAge: 'Parent(s)/Step-Parent(s) must be 114 or younger',
                minAge: 'Parent(s)/Step-Parent(s) must be 27 or older',
                required: 'Please enter a valid date of birth',
                dateInPast: 'Please enter a valid date of birth',
                reasonableDate: 'Parent(s)/Step-Parent(s) must be 114 or younger',
            },
            validators: [
                'required',
                'reasonableDate',
                {
                    name: 'minAge',
                    dependency: {
                        age: 27,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
                {
                    name: 'maxAge',
                    dependency: {
                        age: 114,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
                'dateInPast',
            ],
            placeholder: 'YYYY-MM-DD',
        },
        {
            prop: 'tobacco_user',
            type: 'toggle',
            label: 'Are you a tobacco user?',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false,
                },
            ],
            validators: ['required'],
            description:
                'Tobacco user is defined as using tobacco products (for example cigarettes, cigars, smokeless tobacco, e-cigarettes) four or more times a week on average (other than for religious or ceremonial purposes) within the last six months. This only applies to individuals age 21 and over.',
        },
    ],
};

export const dependentConfig = {
    text: {
        child: 'Child',
    },
    controls: [
        {
            label: 'Date of Birth',
            placeholder: 'YYYY-MM-DD',
            prop: 'date_of_birth',
            validators: [
                'required',
                'dateInPast',
                'reasonableDate',
                {
                    name: 'maxAgeAndPropCheck',
                    dependency: {
                        dateProp: 'parent.parent.parent.parent.controls.getStarted.controls.coverage_effective_date',
                        otherPropValue: true,
                        age: 26,
                        otherProp: 'controls.is_disabled',
                    },
                },
            ],
            type: 'date',
            errors: {
                maxAgeAndPropCheck: 'Child dependent must be under the age 26 with the exception of having a disability.',
                required: 'Please enter a valid date of birth',
                dateInPast: 'Please enter a valid date of birth',
                reasonableDate: 'Child dependent must be under the age 26',
            },
        },
    ],
};

export const childOnlyConfig = {
    text: {
        child: 'Child Only',
    },
    controls: [
        {
            type: 'date',
            prop: 'date_of_birth',
            label: 'Date of Birth',
            placeholder: 'YYYY-MM-DD',
            errors: {
                reasonableDate: 'Please enter a valid date of birth',
                required: 'Please enter a valid date of birth',
                maxAge: 'Child must be less than 18 years old for child only coverage.',
                dateInPast: 'Please enter a valid date of birth',
            },
            validators: [
                'required',
                'dateInPast',
                'reasonableDate',
                {
                    name: 'maxAge',
                    dependency: {
                        dateProp: 'root.value.coverage_effective_date',
                        age: 18,
                    },
                },
            ],
        },
    ],
    validators: ['required'],
};

export const individualsCoveredConfig = {
    prop: 'whose_covered',
    type: 'dropdown',
    label: 'Please add all individuals who will be covered under this policy',
    options: [],
    validators: ['required'],
};

export const parentButtonConfig = {
    icon: 'add',
    level: 'text',
    content: 'Add Another Parent(s)/Step-Parent(s)',
};

export const dependentButtonConfig = {
    icon: 'add',
    level: 'text',
    content: 'Add Another Dependent',
};

export interface IParentDependent {
    date_of_birth: string;
    tobacco_user: boolean;
}

export const whoseCoveredOptions = {
    subscriber: {
        label: 'Subscriber Only',
        value: 'subscriberDependent',
        icon: 'add_yourself',
    },
    family: {
        label: 'Family',
        value: 'familyDependent',
        icon: 'add_family',
    },
    children: {
        label: 'Child Only',
        value: 'chilOnlyDependent',
        icon: 'add_child_only',
    },
};

export const CoveredDependentMembers = {
    spouse: 'spouse',
    parent: 'parent',
    family: 'family',
    subscriberSpouse: 'subscriber_spouse',
    subscriberChildren: 'subscriber_children',
    subscriberSpouseChildren: 'subscriber_spouse_children',
};
