import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthBannerLayoutComponent } from './auth-banner-layout.component';
import { ButtonModule, IconModule } from '@zipari/design-system';

@NgModule({
    declarations: [AuthBannerLayoutComponent],
    imports: [CommonModule, ButtonModule, IconModule],
    exports: [AuthBannerLayoutComponent],
})
export class AuthBannerLayoutModule {}
