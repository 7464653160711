export const environment = {
    app: 'broker-portal-medicare',
    configs: {
        indexRoute: 'broker-portal/medicare',
        defaultRoute: 'medicare',
        useLocalConfig: useLocalConfig,
    },
    production: false,
    squidex: {
        baseUrl: 'https://squidex-production.i1.zipari.cloud',
    },
};
