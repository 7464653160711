import { ButtonConfig } from '@zipari/shared-ds-util-button';

export class SessionExpiredConfig {
    title?: string = 'You have been logged out';
    body?: string = 'Your session expired after ${timeout} minutes of inactivity.<br />Please login again to continue.';
    image?: string = 'https://zipari-uploads-dev.s3.amazonaws.com/files/77bacafc-d4c5-4a5d-9cdd-7e7fdbbb0746.svg';
    loginButton?: ButtonConfig = {
        content: 'Go To Login',
    };
    loginButtonUrl?: string;

    constructor(options: SessionExpiredConfig = {}) {
        Object.assign(this, options || {});
    }
}
