import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IndividualSimpleSubnavItem, SimpleSubnavConfig } from './simple-subnav.constants';

@Component({
    selector: 'simple-subnav',
    templateUrl: './simple-subnav.component.html',
    styleUrls: ['./simple-subnav.component.scss'],
})
export class SimpleSubnavComponent {
    @Input() config: SimpleSubnavConfig;
    @Input() activeNavItem: string;

    @Output() handleSubnavItemClicked: EventEmitter<IndividualSimpleSubnavItem> = new EventEmitter<IndividualSimpleSubnavItem>();
}
