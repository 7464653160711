import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationModule } from '@zipari/design-system';

import { NotificationListComponent } from './notification-list.component';
import { FooterModule } from '../../../../modules/src/lib/footer/footer.module';

@NgModule({
    declarations: [NotificationListComponent],
    imports: [CommonModule, NotificationModule, FooterModule],
    exports: [NotificationListComponent],
})
export class NotificationListModule {}
