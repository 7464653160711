import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Broker } from '@zipari/shared-sbp-models';

export enum BrokerFields {
    agency_id = 'agency_id',
    broker_id = 'broker_id',
    has_user = 'has_user',
    role = 'role',
    tin = 'tin',
    npn = 'npn',
}

@Injectable({
    providedIn: 'root',
})
export class BrokerService {
    private brokerEndpoint = 'api/enrollment/brokers/';
    private _brokerCache = new Map<string, Observable<Broker>>();
    constructor(private http: HttpClient) {}

    getBroker(brokerId) {
        const getBrokerCall = <Observable<Broker>>this.http.get(`${this.brokerEndpoint}${brokerId}/`).pipe(shareReplay());
        if (!this._brokerCache.get(brokerId)) {
            this._brokerCache.set(brokerId, getBrokerCall);
        }
        return this._brokerCache.get(brokerId);
    }
}
