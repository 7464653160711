import { formatTypes } from '@zipari/shared-ds-util-format';

export const modelAttributeConfigs = [
    {
        label: 'Phone Number',
        prop: 'phone',
        format: formatTypes.PHONE,
    },
    {
        label: 'Address',
        prop: 'location',
        format: formatTypes.ADDRESS,
    },
];
