import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZipBusyModule } from '@zipari/design-system';
import { AuthCardModule } from './auth-card/auth-card.module';
import { AuthenticationComponent } from './authentication.component';
import { AuthBannerLayoutModule } from './layouts/auth-banner-layout/auth-banner-layout.module';
import { AuthStepLayoutModule } from './layouts/auth-step-layout/auth-step-layout.module';

@NgModule({
    declarations: [AuthenticationComponent],
    imports: [CommonModule, AuthCardModule, AuthBannerLayoutModule, AuthStepLayoutModule, ZipBusyModule],
    exports: [AuthenticationComponent],
})
export class AuthenticationModule {}
