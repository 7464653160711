import { Injectable } from '@angular/core';
import { cloneObject, isEmptyObj } from '@zipari/web-utils';
import { validGAEvents } from '@zipari/shared-sbp-constants';
import { Plan } from '@zipari/shared-sbp-models';
import { LoggerService } from '@zipari/shared-sbp-services';

export enum GAEcommerceKeys {
    productClick = 'productClick',
    addToCart = 'addToCart',
    removeFromCart = 'removeFromCart',
    productImpressions = 'productImpressions',
    checkout = 'checkout',
}

// model pulled from
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
export class productFieldObject {
    id: string;
    name: string;
    brand?: string;
    category?: string;
    variant?: string;
    price?: number;
    quantity?: number = 1;
    coupon?: string;
    position?: number;
    list?: string;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

// model pulled from
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
// and https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
export class productObj {
    id: string;
    name: string;
    list?: string;
    brand?: string;
    category?: string;
    variant?: string;
    position?: number;
    price?: number;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export class GAEventOptions {
    eventCategory: string;
    eventAction: string;
    eventLabel: string = null;
    eventValue: any = null;
    hitType = 'event';

    constructor(options, private loggerService: LoggerService) {
        if (options) {
            const newOptions = cloneObject(options);
            if (newOptions.eventLabel) {
                this.eventLabel = newOptions.eventLabel;
            } else {
                this.loggerService.warn('No key provided for GA');
            }

            if (newOptions.dictionary_attributes.eventCategory) {
                this.eventCategory = newOptions.dictionary_attributes.eventCategory;
                delete newOptions.dictionary_attributes.eventCategory;
            } else {
                this.eventCategory = this.eventLabel;
            }

            if (newOptions.dictionary_attributes.eventAction) {
                this.eventAction = newOptions.dictionary_attributes.eventAction;
                delete newOptions.dictionary_attributes.eventAction;
            } else {
                this.eventAction = this.eventLabel;
            }

            this.eventValue = 0;
        } else {
            this.loggerService.warn('No options provided for GA call');
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    shoppingCart: Array<productFieldObject> = [];

    constructor(private loggerService: LoggerService) {}

    public emitGAEvent(key: validGAEvents, dictionary_attributes: any = {}) {
        if (window['dataLayer']) {
            let clonedDictionaryAttributes = cloneObject(dictionary_attributes);

            if (
                key === validGAEvents.virtualPageView &&
                clonedDictionaryAttributes.plans &&
                !isEmptyObj(clonedDictionaryAttributes.plans)
            ) {
                clonedDictionaryAttributes['products'] = this.formatProductObj(clonedDictionaryAttributes.plans);
            }

            if (GAEcommerceKeys[key]) {
                let ecommerce = this.formatEcommerceEvent(key, dictionary_attributes);

                // we have to make a deep copy of the ecommerce object because having it as a JS object doesn't come up
                // correctly in GA
                ecommerce = cloneObject(ecommerce);

                dictionary_attributes = { context: dictionary_attributes.context };
                Object.assign(dictionary_attributes, ecommerce);

                Object.assign(clonedDictionaryAttributes, { context: dictionary_attributes.context });
                Object.assign(clonedDictionaryAttributes, ecommerce);

                const zipariBusinessObjectsToRemoveFromDictionaryAttributes: Array<string> = ['plans'];

                zipariBusinessObjectsToRemoveFromDictionaryAttributes.forEach((key: string) => {
                    if (clonedDictionaryAttributes[key]) {
                        delete clonedDictionaryAttributes[key];
                    }
                });
            }

            const finalDatalayerData = {
                event: key,
                ...clonedDictionaryAttributes,
            };

            /* Do not push the GA event if there is a duplicate in the recent events */
            if (!this.isDuplicateGAEvent(finalDatalayerData)) {
                window['dataLayer'].push(finalDatalayerData);
            }
        }

        if (typeof gtag !== 'undefined') {
            gtag('event', key, dictionary_attributes);
        }

        if (typeof ga !== 'undefined') {
            const formattedOptions = new GAEventOptions(
                {
                    eventLabel: key,
                    dictionary_attributes: dictionary_attributes,
                },
                this.loggerService
            );

            ga('send', formattedOptions);
        }
    }

    /* Checks if event has a duplicate in the recent Google Analytics events */
    isDuplicateGAEvent(eventData) {
        if (eventData && window['dataLayer'] && window['dataLayer'].length) {
            /* control duplication/similarity of the event data using the control key of the event object */
            const eventDataSimilarityControlMap = {
                virtualPageView: 'eventLabel',
                checkout: 'ecommerce',
            };
            /* set a distance for recent events, in this case last 4 events is our recent domain */
            const recentEventDistance = 4;
            /* get recent Google Analytics events */
            const recentEvents = window['dataLayer'].slice(Math.max(window['dataLayer'].length - recentEventDistance, 0));
            /* check if there is an event with same key in recent evens */
            const eventWithSameKeyInRecentEvents = recentEvents
                .slice()
                .reverse()
                .find((e) => e.event === eventData.event);
            if (eventWithSameKeyInRecentEvents) {
                /* Check if the event has same data */
                const controlKey = eventDataSimilarityControlMap[eventData.event];
                if (controlKey && eventWithSameKeyInRecentEvents[controlKey]) {
                    /* Event has same data on the control key */
                    return JSON.stringify(eventWithSameKeyInRecentEvents[controlKey]) === JSON.stringify(eventData[controlKey]);
                } else {
                    /* event has same data */
                    return JSON.stringify(eventWithSameKeyInRecentEvents) === JSON.stringify(eventData);
                }
            }
        }
        return false;
    }

    public formatEcommerceEvent(eventKey: string, context: any) {
        const ecommerceStructure = {
            ecommerce: {},
        };

        switch (eventKey) {
            case GAEcommerceKeys.removeFromCart:
                ecommerceStructure.ecommerce['remove'] = this.formatAddRemoveEvent(context);
                break;
            case GAEcommerceKeys.addToCart:
                ecommerceStructure.ecommerce['add'] = this.formatAddRemoveEvent(context);
                break;
            case GAEcommerceKeys.productClick:
                ecommerceStructure.ecommerce['click'] = {};
                ecommerceStructure.ecommerce['click'].products = this.formatProductObj([context]);
                break;
            case GAEcommerceKeys.productImpressions:
                ecommerceStructure.ecommerce['impressions'] = this.formatProductObj(context.plans);
                break;
            case GAEcommerceKeys.checkout:
                ecommerceStructure.ecommerce['checkout'] = {};
                ecommerceStructure.ecommerce['checkout'].products = this.formatProductObj(context.plans);
                break;
        }

        return ecommerceStructure;
    }

    public formatProductObj(plans: any): Array<productObj> {
        if (Array.isArray(plans)) {
            return plans.map((currentPlan: Plan) => {
                return new productObj(this.productObjFromPlan(currentPlan));
            });
        } else {
            return Object.keys(plans).map((planType: string) => {
                const currentPlan: Plan = plans[planType];

                return this.productObjFromPlan(currentPlan);
            });
        }
    }

    formatAddRemoveEvent(plan: Plan) {
        return {
            actionField: this.retrieveActionField(plan.plan_type),
            products: this.formatProductObj([plan]),
        };
    }

    retrieveActionField(plan_type) {
        return {
            list: this.determinePlanType(plan_type),
        };
    }

    determinePlanType(planType: string): string {
        switch (planType) {
            case 'bundled':
                return 'Bundled';
            case 'medical':
                return 'Medical Plans';
            case 'dental':
                return 'Dental Plans';
            case 'vision':
                return 'Vision Plans';
        }
    }

    private productObjFromPlan(plan: Plan): productObj {
        return cloneObject(
            new productObj({
                id: plan?.external_id,
                name: plan?.display_name,
                category: plan?.hmo ? 'HMO' : 'PPO',
                list: this.determinePlanType(plan?.plan_type),
                price: plan?.price,
                position: plan?.index,
            })
        );
    }
}
