import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneObject } from '@zipari/web-utils';
import { ConfigService } from '@zipari/shared-sbp-services';
import { fade } from '@zipari/design-system';

export class AddressSelectConfig {
    property: string;
}

@Component({
    selector: 'zip-address-select-modal',
    templateUrl: './address-select-modal.component.html',
    styleUrls: ['./address-select-modal.component.scss'],
    animations: [fade],
})
export class AddressSelectModalComponent implements OnInit {
    @Input() current;
    @Input() verified;
    @Input() canCancel = true;
    @Output() resolve = new EventEmitter();
    @Output() cancel = new EventEmitter();

    @ViewChild('addressSelect') addressSelect: NgForm;

    isVerifiedSelection: boolean;
    config;

    constructor(private configService: ConfigService) {}

    ngOnInit() {
        this.setConfig();
    }

    setConfig() {
        const errorMessageModalConfig: any = this.configService.getPageConfig('enrollment') || this.configService.configs.enrollment;

        if (errorMessageModalConfig && errorMessageModalConfig.addressSelectionModalMessage) {
            this.config = cloneObject(errorMessageModalConfig.addressSelectionModalMessage);
        } else {
            this.config = {};
        }
    }

    get showAddressSelection(): boolean {
        return typeof this.config.address_selection === 'boolean' ? this.config.address_selection : true;
    }

    public get selectedAddressErrorMessage() {
        return this.config.errorModalNonUSPSValidated;
    }

    cancelAddress() {
        this.cancel.emit('zipari');
    }

    onSelectChange(value) {
        this.isVerifiedSelection = this.addressSelect.form.get('select').value.verified;
    }

    resolveAddress() {
        this.resolve.emit(this.addressSelect.form.get('select').value);
    }
}
