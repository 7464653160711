<div
    class="form-control__wrapper"
    [ngClass]="{
        'form-control__wrapper--inline': direction === 'row',
        'form-control__wrapper--filter': direction === 'filter'
    }"
>
    <label class="form-control__label">
        <p class="t-data t-bold">{{ config.label }}</p>
    </label>

    <div [formGroup]="formGroup" [ngClass]="{ 'address-group__controls': !!config.grid }">
        <form-control
            direction="grouping"
            [libGridCell]="address1Config.grid"
            [config]="address1Config"
            [control]="formGroup.get(address1Config.prop)"
        >
        </form-control>

        <form-control
            *ngIf="address2Config.prop"
            direction="grouping"
            [libGridCell]="address2Config.grid"
            [config]="address2Config"
            [control]="formGroup.get(address2Config.prop)"
        >
        </form-control>

        <form-control direction="grouping" [libGridCell]="cityConfig.grid" [config]="cityConfig" [control]="formGroup.get(cityConfig.prop)">
        </form-control>

        <form-control
            direction="grouping"
            [libGridCell]="zipCodeConfig.grid"
            [config]="zipCodeConfig"
            [control]="formGroup.get(zipCodeConfig.prop)"
        >
        </form-control>

        <form-control
            [stateAutoComplete]="formGroup"
            direction="grouping"
            [libGridCell]="stateConfig.grid"
            [config]="stateConfig"
            [control]="formGroup.get(stateConfig.prop)"
        >
        </form-control>
    </div>
</div>
