<article class="main__profile--container" [zipBusy]="busy">
    <main class="main__profile">
        <header class="main__profile__header">
            <div class="main__profile__breadcrumb" (click)="onViewAllResultsClick()">
                <i class="icon icon--medium icon--link">chevron_left</i>
                <p class="t-data t-bold t-link">Back to All Search Results</p>
            </div>

            <div class="main__profile__navigation">
                <p
                    class="t-data"
                    [ngClass]="{
                        't-alt': selectedResultIdx === 0,
                        't-bold t-link': selectedResultIdx !== 0
                    }"
                    (click)="selectedResultIdx !== 0 && onPreviousNextClick(selectedResultIdx - 1)"
                >
                    Previous
                </p>

                <p
                    class="t-data"
                    [ngClass]="{
                        't-alt': selectedResultIdx === backendResults.length - 1,
                        't-bold t-link': selectedResultIdx !== backendResults.length - 1
                    }"
                    (click)="selectedResultIdx !== backendResults.length - 1 && onPreviousNextClick(selectedResultIdx + 1)"
                >
                    Next
                </p>
            </div>
        </header>

        <div class="u-flex" *ngIf="!!showDetails">
            <section class="main__profile__info">
                <section id="pcp__profile__id" class="main__profile__info__section">
                    <div class="main__profile__id" *ngIf="!removePCPavatar">
                        <div class="avatar main__profile__avatar">
                            <img src="{{ selectedResult.picture }}" alt="Selected result" />
                        </div>

                        <p class="t-data">
                            {{ selectedResult.gender === 'M' ? 'Male' : 'Female' }}
                        </p>
                    </div>

                    <div class="search__result__section__content">
                        <h5 class="t-bold">{{ selectedResult | fullName : true }}, MD</h5>

                        <p class="t-data t-bold">
                            {{ selectedResult.primary_specialty }}
                        </p>

                        <div class="main__profile__attributes">
                            <div class="main__profile__attribute" *ngIf="!!selectedResult.accepting_new_patients">
                                <i class="icon">check</i>
                                <div>
                                    <span>Accepting New Patients</span>
                                    <p class="t-data" *ngIf="removePCPavatar">
                                        {{ selectedResult.gender === 'M' ? 'Male' : 'Female' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="main__profile__info__section">
                    <h5 class="t-bold">Contact</h5>

                    <div class="model-attributes">
                        <model-attribute
                            *ngFor="let contactConfig of contactConfigs"
                            [context]="contactContext"
                            [config]="contactConfig.config"
                            (linkClicked)="onDirectionsLinkClick($event)"
                            direction="column"
                        >
                        </model-attribute>
                    </div>
                </section>

                <section class="main__profile__info__section">
                    <h5 class="t-bold">Profile</h5>

                    <div class="model-attributes">
                        <ng-container *ngFor="let profileConfig of profileConfigs">
                            <model-attribute
                                *ngIf="!!shouldShowModelAttribute(profileConfig.config.prop)"
                                [context]="profileContext"
                                [config]="profileConfig.config"
                                direction="column"
                            >
                            </model-attribute>
                        </ng-container>
                    </div>
                </section>

                <section class="main__profile__info__section">
                    <h5 class="t-bold">Education and Training</h5>

                    <div class="model-attributes">
                        <ng-container *ngFor="let eduAndTrainConfig of educationAndTrainingConfigs">
                            <model-attribute
                                *ngIf="!!shouldShowModelAttribute(eduAndTrainConfig.config.prop)"
                                [context]="educationAndTrainingContext"
                                [config]="eduAndTrainConfig.config"
                                direction="column"
                            >
                            </model-attribute>
                        </ng-container>
                    </div>
                </section>
            </section>

            <aside class="main__profile__aside">
                <zip-button
                    *ngIf="!selectedResult.selected"
                    size="full"
                    level="high"
                    content="Add Provider"
                    [disabled]="resultIsSelected"
                    (zipButtonClicked)="onAddProviderClick()"
                >
                </zip-button>
                <zip-button
                    *ngIf="selectedResult.selected"
                    size="full"
                    level="high"
                    content="Selected"
                    icon="check"
                    (zipButtonClicked)="onRemoveProviderClick()"
                >
                </zip-button>

                <div class="main__profile__actions">
                    <a class="main__profile__action" [href]="'tel: ' + selectedResult.phone">
                        <i class="icon icon--medium icon--link">phone</i>
                        <p class="t-data">Call Provider</p>
                    </a>

                    <a class="main__profile__action" target="_blank" [href]="selectedResultDirectionsUrl">
                        <i class="icon icon--medium icon--link">directions</i>
                        <p class="t-data">Get Directions</p>
                    </a>
                </div>
            </aside>
        </div>
    </main>

    <aside class="search__map">
        <div class="search__map__controls">
            <div class="search__map__control search__map__control--zoom">
                <i class="icon icon--medium icon--pointer" (click)="zoom = zoom + 1"> add </i>

                <i class="icon icon--medium icon--pointer" (click)="zoom = zoom - 1"> subtract </i>
            </div>
        </div>

        <map [centerLat]="centerLat" [centerLng]="centerLng" [mapZoom]="zoom" [pins]="pins" (pin-selected)="onPinSelect($event)"> </map>
    </aside>
</article>
