import { Component, EventEmitter, Input, Output } from '@angular/core';

import { fade } from '@zipari/design-system';
import { BehaviorSubject } from 'rxjs';

interface CustomModal {
    imgLink?: string;
    header?: string;
    subHeader?: string;
    btn?: {
        content: string;
        level: string;
        link: string;
        icon?: string;
    };
    footerDescription?: string;
    footerOptions?: Array<{ label: string; link: string; icon?: any }>;
}
export interface ConfirmationModalConfig {
    header?: string;
    body?: string;
    HTMLBody?: string;
    cancel?: string;
    confirm?: string;
    altBackDropClass?: string;
    customModal?: CustomModal;
}

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    animations: [fade],
})
export class ConfirmationModalComponent {
    public open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    @Input() config: ConfirmationModalConfig = <ConfirmationModalConfig>{};
    @Input() context = <any>{};
    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();
}
