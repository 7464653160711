import { YesNoResponse } from '../sharedModels';

export class GetApplicationSummaryLinks {
    rel: string;
    href: string;
}

export class GetApplicationSummaryMember {
    personTrackingNumber?: string;
    aptcStatus?: YesNoResponse;
    qhpStatus?: YesNoResponse;
    qhpStatusReason: string;
    csrStatus?: YesNoResponse;
    medicaidStatus?: YesNoResponse;
    chipStatus?: YesNoResponse;
    fiveYearBarStatus?: YesNoResponse;
    emergencyMedicaidStatus?: YesNoResponse;
    medicaidNonMagiReferralStatus?: YesNoResponse;
    unbornChildChipStatus?: YesNoResponse;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    requestingCoverageIndicator?: boolean;
}

export class GetApplicationSummary {
    insuranceApplicationIdentifier?: string;
    applicationVersionNumber?: number;
    applicationStatus?: string;
    applicationCreationOriginType?: string;
    applicationSubmissionOriginType?: string;
    dmiIndicator?: boolean;
    pdmIndicator?: boolean;
    bestSepStatus?: YesNoResponse;
    sepStartDate?: string;
    sepEndDate?: string;
    sepType?: string;
    coverageYear?: number;
    creatingDePartner?: string;
    submittingDePartner?: string;
    lastModifiedDateTime?: string;
    linkedSystemUserIdentifier?: string;
    members?: {
        [memberKey: string]: GetApplicationSummaryMember;
    };
}

export class GetApplicationSummaryModel {
    resultType?: string;
    links?: GetApplicationSummaryLinks;
    summaries?: Array<GetApplicationSummary>;
}
