import { BannerTypes, MessageBannerConfig } from '@zipari/shared-ds-util-messages';

export interface UserError {
    error?: string;
}

export interface EnrollError {
    error?: {
        correlation_id: string;
        errors: {
            error_level: string;
            reason?: string;
            user_error: UserError;
        };
    };
    message: string;
    name: string;
    ok: boolean;
    status: number;
    statusText: string;
    url: string;
}

export interface ErrorMessageBanner extends Omit<MessageBannerConfig, 'type'> {
    title: string;
    type?: BannerTypes;
}
