export class BadgeConfig {
    label: string;
    value: string;
    link?: string;
    type: BadgeTypes;
    size?: BadgeSizes;
}

export enum BadgeTypes {
    flat = 'flat',
    priority = 'priority',
}

export enum BadgeSizes {
    small = 'small',
    large = 'large',
}
