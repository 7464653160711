import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'details-accordion',
    templateUrl: './details-accordion.component.html',
    styleUrls: ['./details-accordion.component.scss'],
})
export class DetailsAccordionComponent {
    @Input() config: any;
    @Input() formGroup: FormGroup;
    @Output() isEditingChange = new EventEmitter();
    _isEditing = false;

    @Input()
    set isEditing(val) {
        this._isEditing = val;
    }

    get isEditing() {
        return this._isEditing;
    }

    public toggleEdit() {
        this.isEditing = !this.isEditing;
        this.isEditingChange.emit(this.isEditing);
    }
}
