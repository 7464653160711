export const coverageEffectiveDateConfig = {
    subtitle: {
        SEP: '',
        OE: '',
    },
    title: {
        SEP: 'Please select the QLE that applies to you or someone in your household in the past 60 days.',
        OE: 'You are eligible for coverage starting on ${date}',
    },
    textTemplates: {
        subtitle: {
            SEP: ' ',
            OE: '',
        },
        title: {
            SEP: 'Please select the QLE that applies to you or someone in your household in the past 60 days.',
            OE: 'You are eligible for coverage starting on ${date}',
        },
    },
};

export const coverageEffectiveDate = '2023-11-01';

export const errorConfig = {
    customErrMessage: true,
};
