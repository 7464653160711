import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormattingService, FormControlService } from '@zipari/design-system';
import { stringBuilder } from '@zipari/web-utils';
import { yoyWfStepChooseDiffPlansProp } from '@zipari/shared-sbp-constants';
import {
    AdditionalPlanDetailConfig,
    CtaActionsEnum,
    Plan,
    PRIMARY_BENEFIT_KEYS,
    Benefit,
    defaultPrevPlanTitleTemplate,
    defaultCurrPlanTitleTemplate,
} from './comparison-card.constant';

@Component({
    selector: 'comparison-card',
    templateUrl: './comparison-card.component.html',
    styleUrls: ['./comparison-card.component.scss'],
})
export class ComparisonCardComponent implements OnInit, OnDestroy {
    @Input() plans: Array<any>;
    @Input() discontinued: boolean;
    @Input() ineligible: boolean;
    @Input() configs;
    @Input() workflowValues;

    @Output() onPlanFormGroupChanges = new EventEmitter<any>();

    public showDetails = false;
    public width;
    public canCompare: boolean;
    public selectOrChoosePlanFormGroup: FormGroup;
    private destroy$: Subject<void> = new Subject<void>();

    public comparePlansObj = {
        summary: new Map<string, [string]>(),
        details: [],
        detailsMap: new Map(),
        plansTypeLabel: <string>null,
        previousPlan: <Plan>null,
        crossWalkPlan: <Plan>null,
    };

    // Montly premium value should always be coming from top level plan object as "price" and not primary_benefits or benefits []
    // In case price does come from those []'s, we remove them.
    keysToRemove = ['Monthly Premium', 'Monthly Premiums'];

    // Provide a comparator function to keyvalue angular pipe to remove auto-sort items by key
    // https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
    public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => 0;

    get ctas() {
        return this.configs?.ctas;
    }

    get showDetailsAccordion(): boolean {
        return typeof this.configs?.showDetailsAccordion === 'boolean' ? this.configs.showDetailsAccordion : true;
    }

    get useBenefitsIndex(): boolean {
        return !!this.configs.useBenefitsIndex && this.plans.length > 1;
    }

    get crosswalkPlanIndex(): number {
        // Returns the crosswalk plan's index in this.plans if it exists, else returns null when no crosswalk plan exists.
        // In the case that we don't have crosswalk plan and we're using the new benefit_display_index assign prev and crosswalk correctly
        if (!this.configs?.useBenefitsIndex && this.plans?.length !== 1) {
            return this.useBenefitsIndex ? 0 : 1;
        }
        return null;
    }

    get previousPlanIndex(): number {
        // Returns the previous plan's index in this.plans
        // In the case that we don't have crosswalk plan and we're using the new benefit_display_index assign prev and crosswalk correctly
        if (!this.configs?.useBenefitsIndex && this.plans?.length !== 1) {
            return this.useBenefitsIndex ? 1 : 0;
        }
        return 0;
    }

    constructor(private formattingService: FormattingService, private formControlService: FormControlService) {}

    ngOnInit() {
        // format and set plan data
        this.formatPlanDisplayObj();
        this.setPlanData();
        this.removeFromComparePlans(this.keysToRemove);
        this.plans.length > 1 && this.cleanupPrimaryBenefits();

        // set configs for form groups (only a toggle control rn)
        this.initCardToggleControl();
    }

    ngOnDestroy() {
        this.destroy$.unsubscribe();
    }

    private formatPlanDisplayObj(): void {
        if (this.useBenefitsIndex) {
            for (const plan of this.plans.reverse()) {
                const primaryBenefits = plan.primary_benefits || this.getPrimaryBenefits(plan);
                const benefits = plan.benefits || [];
                for (const pb of primaryBenefits) {
                    const label = pb.label.toLowerCase();
                    if (!this.comparePlansObj.summary.has(label)) {
                        this.comparePlansObj.summary.set(label, [pb.value]);
                    } else {
                        this.comparePlansObj.summary.get(label).push(pb.value);
                    }
                }

                if (!this.comparePlansObj.details.length) {
                    this.comparePlansObj.details = benefits;

                    if (plan.links?.length) {
                        plan.links.forEach((link) => {
                            this.comparePlansObj.details.push({
                                label: link.label,
                                value: [link.url],
                                type: 'link',
                            });
                        });
                    }

                    if (plan.documents) {
                        this.comparePlansObj.details = [
                            ...this.comparePlansObj.details,
                            ...Object.entries(plan.documents).map(([documentKey, document]: [string, Benefit]) => ({
                                label: documentKey,
                                value: document.document_id,
                                type: 'link',
                            })),
                        ];
                    }
                } else {
                    this.comparePlansObj.details.forEach((fb) => {
                        // compare new benefits array to details array
                        benefits.forEach((cb) => {
                            if (parseInt(fb.benefit_display_index, 10) === parseInt(cb.benefit_display_index, 10)) {
                                fb.value = [fb.value, cb.value];
                            }
                        });

                        if (plan.links?.length) {
                            plan.links.forEach((link) => {
                                if (link.label === fb.label) {
                                    fb.value.push(link.url);
                                }
                            });
                        }
                    });
                }
            }
        } else {
            // need to preserve backwards compatiblity for clients that don't have benefit_display_index
            this.plans.forEach((plan, index) => {
                const primaryBenefits = plan.primary_benefits || this.getPrimaryBenefits(plan);
                const benefits = plan.benefits || [];
                for (const pb of primaryBenefits) {
                    const label = pb.label.toLowerCase();
                    if (!this.comparePlansObj.summary.has(label)) {
                        this.comparePlansObj.summary.set(label, [pb.value]);
                    } else {
                        this.comparePlansObj.summary.get(label).push(pb.value);
                    }
                }

                for (const fb of benefits) {
                    if (!this.comparePlansObj.summary.has(fb.label)) {
                        if (!this.comparePlansObj.detailsMap.has(fb.label)) {
                            index === 0
                                ? this.comparePlansObj.detailsMap.set(fb.label, [this.formatValue(fb)])
                                : this.comparePlansObj.detailsMap.set(fb.label, [null, this.formatValue(fb)]);
                        } else {
                            this.comparePlansObj.detailsMap.get(fb.label).push(this.formatValue(fb));
                        }
                    }
                }

                if (plan.links?.length) {
                    plan.links.forEach((link) => {
                        link.type = 'link';
                        link.value = link.url;
                        if (!this.comparePlansObj.detailsMap.has(link.label)) {
                            this.comparePlansObj.detailsMap.set(link.label, [this.formatValue(link)]);
                        } else {
                            this.comparePlansObj.detailsMap.get(link.label).push(this.formatValue(link));
                        }
                    });
                }

                if (plan.documents) {
                    Object.entries(plan.documents).forEach(([documentKey, document]: [string, Benefit]) => {
                        document.type = 'link';
                        document.value = document.document_id;
                        const detailsMap = this.comparePlansObj.detailsMap;

                        if (!detailsMap.has(documentKey)) {
                            detailsMap.set(documentKey, index === 0 ? [this.formatValue(document)] : [null, this.formatValue(document)]);
                        } else {
                            detailsMap.get(documentKey).push(this.formatValue(document));
                        }
                    });
                }
            });
        }

        // set the plan type label for this comparison card's plan type
        if (this.configs.displayPlansTypeLabel && this.plans.length) {
            // Yeah using index 0 kinda sucks, but all plans in this.plans will have the same plan_type
            this.comparePlansObj.plansTypeLabel = this.plans[0].plan_type || '';
        }
    }

    formatValue(benefit: Benefit) {
        if (benefit.type && benefit.type === 'link') {
            return `<a class="t-link" href="${benefit.value}" target="_blank">Click Here</a>`;
        } else {
            return benefit.value;
        }
    }

    /*
     *   set the name, price, and year of plans to comparePlans obj
     */
    setPlanData() {
        // TODO: Refactor to dynamically accept multiple rows
        const PREVIOUS_PLAN = this.previousPlanIndex;
        const CROSSWALK_PLAN = this.crosswalkPlanIndex;

        this.comparePlansObj.previousPlan = { ...this.plans[PREVIOUS_PLAN] };

        const prevYearPlanTitleTemplate = this.configs.prevYearPlanTitleTemplate || defaultPrevPlanTitleTemplate;
        const prevYear = this.plans[PREVIOUS_PLAN].year || this.plans[PREVIOUS_PLAN].plan_year;
        this.comparePlansObj.previousPlan.year = stringBuilder(prevYearPlanTitleTemplate, { prevYear });

        this.comparePlansObj.previousPlan.name = this.plans[PREVIOUS_PLAN].name || this.plans[PREVIOUS_PLAN].display_name;

        if (this.plans[CROSSWALK_PLAN]) {
            this.comparePlansObj.crossWalkPlan = { ...this.plans[CROSSWALK_PLAN] };

            const currYearPlanTitleTemplate = this.configs.currYearPlanTitleTemplate || defaultCurrPlanTitleTemplate;
            const currYear = this.plans[CROSSWALK_PLAN].year || this.plans[CROSSWALK_PLAN].plan_year;
            this.comparePlansObj.crossWalkPlan.year = stringBuilder(currYearPlanTitleTemplate, { currYear });

            this.comparePlansObj.crossWalkPlan.name = this.plans[CROSSWALK_PLAN].name || this.plans[CROSSWALK_PLAN].display_name;
        }
    }

    /**
     * initialize this card's toggle control for selecting the current plan or choosing a different plan
     */
    private initCardToggleControl(): void {
        const formGroup = new FormGroup({});
        const toggleControlConfig = this.configs.selectOrChoosePlanToggleControl;

        // get prev vals from yoy wf step stored in wf
        const prevYoyComparisonCardVals: Array<string> = this.workflowValues[yoyWfStepChooseDiffPlansProp];
        const context = {
            [toggleControlConfig.prop]:
                prevYoyComparisonCardVals?.some((planType) => planType === this.plans[0].plan_type) || this.crosswalkPlanIndex == null
                    ? true
                    : false,
        };

        if (this.crosswalkPlanIndex == null) {
            Object.assign(toggleControlConfig, { isDisabled: true });
        }

        this.formControlService.addControlToFormGroup(formGroup, toggleControlConfig, { context });
        this.selectOrChoosePlanFormGroup = formGroup;

        // emit initial val of the form group & listen to form group changes & emit changed values
        this.onPlanFormGroupChanges.emit(context);
        this.selectOrChoosePlanFormGroup.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((changes) => this.onPlanFormGroupChanges.emit(changes));
    }

    initCta(event, action: CtaActionsEnum) {
        switch (action) {
            case CtaActionsEnum.email:
                this.email(event);
                break;
            case CtaActionsEnum.download:
                this.download(event);
                break;
            case CtaActionsEnum.print:
                this.print(event);
                break;
        }
    }

    print(event) {
        event.stopPropagation();
        console.log('print');
    }

    email(event) {
        event.stopPropagation();
        console.log('email');
    }

    download(event) {
        event.stopPropagation();
        console.log('download');
    }

    getPrimaryBenefits(plan) {
        const primaryCareObj = [];
        if (plan.formattedBenefits) {
            for (const benefits of plan.formattedBenefits) {
                const label = benefits.label.toLowerCase();
                if (PRIMARY_BENEFIT_KEYS.includes(label)) {
                    const benefitsObj = { label: label, value: benefits.value };
                    primaryCareObj.push(benefitsObj);
                }
            }
        }
        return primaryCareObj;
    }

    cleanupPrimaryBenefits() {
        // remove from summary if a primary benefit only has 1 value of less;
        this.comparePlansObj.summary.forEach((value, key) => {
            if (value.length < 2) {
                this.comparePlansObj.summary.delete(key);
            }
        });
    }

    removeFromComparePlans(keys: Array<string>) {
        for (const key of keys) {
            if (this.comparePlansObj.summary.has(key)) {
                this.comparePlansObj.summary.delete(key);
            }

            if (this.comparePlansObj.detailsMap.has(key)) {
                this.comparePlansObj.detailsMap.delete(key);
            }
        }
    }

    format(value: any, config: AdditionalPlanDetailConfig) {
        return this.formattingService.restructureValueBasedOnFormat(value, config);
    }

    isArray(value): boolean {
        return Array.isArray(value);
    }
}
