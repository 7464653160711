<div class="inner-card">
    <p class="inner-card__header">
        <ng-content select="[title]"></ng-content>
        <ng-content select="[header-action]"></ng-content>
    </p>

    <div class="inner-card__wrapper">
        <ng-content select="[body]"></ng-content>
    </div>

    <div class="inner-card__group-wrapper">
        <ng-content select="[body-group]"></ng-content>
    </div>
</div>
