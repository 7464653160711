export const errorDuration = 3000;

export class FileRestrictionMessages {
    fileTypes: string = 'Provided file does not meet file type requirements.';
    fileSizeLimit: string = 'Provided file does not meet file size requirements.';

    constructor(options?) {
        Object.assign(this, options);
    }
}

export class FileRestrictions {
    fileTypes?: string;

    // in mb
    fileSizeLimit?: number;

    // Number of files
    filesLimit?: number;

    messages?: FileRestrictionMessages;

    constructor(options: FileRestrictions = {}) {
        Object.assign(this, options);
        this.messages = new FileRestrictionMessages(options && options.messages ? options.messages : {});
    }
}
