import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SquidexContentNames, SquidexFeatureKeys, validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import { SquidexGetContentParams } from '@zipari/shared-sbp-models';
import { AnalyticsService, ConfigDispenserService, ConfigDispenserGetConfigOptions } from '@zipari/shared-sbp-services';
import { first } from 'rxjs/operators';

import { formatPortalFooterSquidexResponse } from './footerHelper';
import {
    default__contact1,
    default__contact2,
    default__copyrightConfig,
    default__quickLinks1,
    default__socialMedia,
} from './footer.constants';

@Component({
    selector: 'zip-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() config: any;
    @Input() enrollmentSubfooter: any;
    @Input() showStateHeaderFooter: boolean;

    layout: string = 'rows';
    contact1 = default__contact1;
    contact2 = default__contact2;
    contactTitle: string = 'Contact Us';
    useSmallerTitle: boolean = true;
    contactText: string[];
    logo: string;
    quickLinks1 = default__quickLinks1;
    quickLinks2: any;
    languages;
    copyright = default__copyrightConfig;
    socialMedia = default__socialMedia;
    showImg;
    quickLinksHeaderLabel = 'Quick Links';

    constructor(
        private analyticsService: AnalyticsService,
        private configDispenserService: ConfigDispenserService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        // use config from this.config or get from configDispenser
        const configPageName = this.config ? null : 'footer';
        const squidexContentParams: SquidexGetContentParams = this.config
            ? null
            : { contentName: SquidexContentNames.footer, featureKey: SquidexFeatureKeys.globalFooter };
        const configDispenserOptions: ConfigDispenserGetConfigOptions = {
            fallbackConfig: this.config,
            squidexFormatterFunction: formatPortalFooterSquidexResponse,
        };

        this.configDispenserService
            .dispenseConfig(configPageName, squidexContentParams, configDispenserOptions)
            .pipe(first())
            .subscribe((footerConfig) => this.setFooterConfig(footerConfig));
    }

    setFooterConfig(footerConfig: any): void {
        if (footerConfig && !footerConfig.usesDesignSystem) {
            if (footerConfig.hasOwnProperty('layout')) {
                this.layout = footerConfig.layout;
            }

            if (footerConfig.hasOwnProperty('logo')) {
                this.logo = footerConfig.logo;
            }

            if (footerConfig.hasOwnProperty('contact1')) {
                this.contact1 = footerConfig.contact1;
            }

            if (footerConfig.hasOwnProperty('contact2')) {
                this.contact2 = footerConfig.contact2;
            }

            if (footerConfig.hasOwnProperty('contactTitle')) {
                this.contactTitle = footerConfig.contactTitle;
            }

            if (footerConfig.hasOwnProperty('useSmallerTitle')) {
                this.useSmallerTitle = footerConfig.useSmallerTitle;
            }

            if (footerConfig.hasOwnProperty('contactText')) {
                this.contactText = footerConfig.contactText;
            }

            if (footerConfig.hasOwnProperty('quickLinks1')) {
                this.quickLinks1 = footerConfig.quickLinks1;
            }

            if (footerConfig.hasOwnProperty('quickLinks2')) {
                this.quickLinks2 = footerConfig.quickLinks2;
            }

            if (footerConfig.hasOwnProperty('quickLinksHeaderLabel')) {
                this.quickLinksHeaderLabel = footerConfig.quickLinksHeaderLabel;
            }

            if (footerConfig.hasOwnProperty('languages')) {
                this.languages = footerConfig.languages;
            }

            if (footerConfig.hasOwnProperty('copyright')) {
                this.copyright = footerConfig.copyright;
            }

            if (footerConfig.hasOwnProperty('socialMedia')) {
                this.socialMedia = footerConfig.socialMedia;
            }

            if (footerConfig.hasOwnProperty('showImg')) {
                this.showImg = footerConfig.showImg;
            }
        }
    }

    sendDataAnalytics(details): void {
        this.analyticsService.dispatchAnalytics(
            {
                GAKey: validGAEvents['footer_clicked'],
                CXKey: validCXEvents['footer_clicked'],
                PosthogKey: validPosthogEvents['footer_clicked'],
            },
            { ...details }
        );
    }

    getGridAreaStyle(col: number, row: number): SafeStyle {
        return this.sanitizer['bypassSecurityTrustStyle'](`--grid-area: ${row + 1} / ${col + 1} / auto / auto;`);
    }
}
