import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonConfig, ButtonLevels, ButtonSizes } from '@zipari/shared-ds-util-button';
import { validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import { Plan } from '@zipari/shared-sbp-models';
import { AnalyticsService } from '@zipari/shared-sbp-services';

@Component({
    selector: 'plan-compare',
    templateUrl: './plan-compare.component.html',
    styleUrls: ['./plan-compare.component.scss'],
})
export class PlanCompareComponent implements OnInit {
    @Input() comparedPlans: Array<any>;
    @Input() maxComparable: number;
    @Input() plansComparingLocked: Array<Plan> = [];
    @Input() compareTitle = 'Compare';
    @Input() compareMessage: string;
    @Input() comparePlaceholder = 'add plan to compare';
    @Input() compareButtonConfig = new ButtonConfig({
        size: ButtonSizes.fullWidth,
        content: 'Compare Plans',
        level: ButtonLevels.medium,
    });
    @Output() removePlan = new EventEmitter<any>();
    @Output() comparePlans = new EventEmitter();

    placeholders = [];

    constructor(public analyticsService: AnalyticsService) {
        this.compareMessage = 'Select up to ' + this.maxComparable + ' plans to compare';
    }

    ngOnInit() {
        this.placeholders = new Array(this.maxComparable);
    }

    removePlanFromComparison(plan) {
        this.removePlan.emit(plan);
    }

    showCompareTable() {
        this.comparePlans.emit();
        this.analyticsService.dispatchAnalytics(
            {
                GAKey: validGAEvents['plans_compared'],
                CXKey: validCXEvents['plans_compared'],
                PosthogKey: validPosthogEvents['plans_compared'],
            },
            this.createCXContext()
        );
    }

    isPlanLocked(plan: Plan): boolean {
        return !!this.plansComparingLocked.find((lockedPlan: Plan) => lockedPlan.external_id === plan.external_id);
    }

    createCXContext() {
        const planIds = [];
        const planNames = [];

        for (const plan of this.comparedPlans) {
            planIds.push(plan.external_id);
            planNames.push(plan.display_name);
        }

        const cxContext = {
            plans_ids_compared: planIds,
            plan_names_compared: planNames,
        };

        return cxContext;
    }
}
