<navigation *ngIf="comparePlansConfig?.showNavigation" (query)="filterBenefits($event)"></navigation>

<article class="ComparePlans">
    <section
        *ngIf="plans.length > 2"
        class="ComparePlans__Scroll"
        [ngClass]="{ 'ComparePlans__Scroll--Scrolled': isScrolled }"
        (click)="scrollMobile()"
    >
        <icon [name]="'chevron_right'" [config]="{ type: 'link', size: 'medium' }"></icon>
        <icon [name]="'chevron_left'" [config]="{ type: 'link', size: 'medium' }"></icon>
    </section>

    <compare-plan-header
        (complete)="complete.emit($event)"
        [plans]="plans"
        [comparePlansConfig]="comparePlansConfig"
        [highlightedPlans]="highlightedInCompare"
        [isScrolled]="isScrolled"
        [comparePlansConstantText]="comparePlansConstantText"
        (close)="closeCompare()"
    >
    </compare-plan-header>

    <article #content class="ComparePlans__Content">
        <section class="ComparePlans__Row" *ngFor="let benefit of allBenefits; let benefitIndex = index">
            <div class="container">
                <div class="ComparePlans__Cells" [ngClass]="{ 'ComparePlans__Cells--Scrolled': isScrolled }">
                    <div class="ComparePlans__Cell">
                        <div class="u-flex u-alignCenter">
                            <p class="t-data t-bold" [innerHTML]="benefit.label"></p>
                        </div>
                    </div>

                    <div class="ComparePlans__Cell" *ngFor="let plan of plans">
                        <p
                            *ngIf="benefit.type !== 'link'"
                            class="t-data"
                            [innerHTML]="
                                plansService.getBenefitFromLabel(
                                    plan.benefits,
                                    plan.primary_benefits,
                                    allBenefits[benefitIndex],
                                    plan,
                                    subsidyAmount
                                ).value
                            "
                        ></p>
                        <p class="t-data" *ngIf="benefit.type === 'link'">
                            <a
                                *ngIf="
                                    plansService.getBenefitFromLabel(
                                        plan.benefits,
                                        plan.primary_benefits,
                                        allBenefits[benefitIndex],
                                        plan,
                                        subsidyAmount
                                    ).value
                                "
                                class="t-link"
                                [href]="
                                    plansService.getBenefitFromLabel(
                                        plan.benefits,
                                        plan.primary_benefits,
                                        allBenefits[benefitIndex],
                                        plan,
                                        subsidyAmount
                                    ).value
                                "
                                target="_blank"
                            >
                                {{ comparePlansConfig?.label?.linkLabel || comparePlansConstantText.ComparelabelLinkText }}
                            </a>
                            <span
                                *ngIf="
                                    !plansService.getBenefitFromLabel(
                                        plan.benefits,
                                        plan.primary_benefits,
                                        allBenefits[benefitIndex],
                                        plan,
                                        subsidyAmount
                                    ).value
                                "
                                >{{ comparePlansConfig?.label?.noValueLabel || 'N/A' }}</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="ComparePlans__Row" *ngIf="singlePlanSelectionDetail?.showLinks">
            <div class="container">
                <div class="ComparePlans__Cells u-flex" [ngClass]="{ 'ComparePlans__Cells--Scrolled': isScrolled }">
                    <div class="ComparePlans__Cell">
                        <div class="u-flex u-alignCenter">
                            <p class="t-data t-bold">
                                {{ singlePlanSelectionDetail?.compareLabel || comparePlansConstantText.planLinkLabel }}
                            </p>
                        </div>
                    </div>

                    <div class="ComparePlans__Cell" *ngFor="let plan of plans">
                        <ng-container *ngFor="let link of plan.links">
                            <div class="u-flex t-link ComparePlans__CellLinks">
                                <icon [name]="link.icon || 'pdf'"></icon>
                                <a class="t-data" target="_blank" [attr.href]="link.url" [innerHTML]="link.label"> </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
    </article>

    <footer #footer class="ComparePlans__Footer" *ngIf="showFooter || comparePlansConfig?.showFooter">
        <section class="ComparePlans__DocumentsRow">
            <div class="container">
                <div class="ComparePlans__Cells" [ngClass]="{ 'ComparePlans__Cells--Scrolled': isScrolled }">
                    <div class="ComparePlans__Cell"></div>
                    <div class="ComparePlans__Cell" *ngFor="let plan of plans">
                        <div *ngIf="comparePlansConfig?.displayAllDocuments || comparePlansConfig?.displayDocuments; else showSinglePdf">
                            <div *ngFor="let document of documentList(plan)">
                                <a class="ComparePlans__DocumentLink u-flex" [href]="document.document_id" target="_blank">
                                    <icon name="pdf" class="ComparePlans__Document--Icon" [config]="{ fill: 'primary' }"></icon>
                                    <span class="t-data t-bold">{{ document.label || formatDocumentName(document.type) }}</span>
                                </a>
                            </div>
                        </div>
                        <ng-template #showSinglePdf>
                            <div class="ComparePlans__AllBenefits" *ngIf="plan.documents">
                                <a class="ComparePlans__DocumentLink u-flex" target="_blank" [href]="plan.documents">
                                    <icon name="pdf" class="ComparePlans__Document--Icon" [config]="{ fill: 'primary' }"></icon>
                                    <span class="t-data t-bold"
                                        >All {{ plan.display_name }} {{ comparePlansConstantText.benefitText }}</span
                                    >
                                </a>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</article>
