import { ProviderResult } from '@zipari/shared-sbp-modules';
import Address from './Address.model';
import Contact from './Contact.model';
import Household from './Household.model';
import Provider from './Provider.model';

export default class Member {
    readonly id?: number;
    addresses?: Address[];
    name?: string;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    crm_id?: string;
    coverage_type?: string[];
    member_number?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    suffix_name?: string;
    prefix_name?: string;
    gender?: string;
    marital_status?: string;
    medicare_enrolled?: string;
    birth_date?: string;
    email?: string;
    phone?: string;
    home_phone?: string;
    primary_phone?: string;
    cell_phone?: string;
    office_phone?: string;
    ssn?: string;
    tribal_id?: string;
    dhs_number?: string;
    ffe_assigned_member_id?: string;
    issuer_assigned_member_id?: string;
    tobacco_use?: boolean;
    tobacco_user?: boolean;
    last_date_of_tobacco_use?: string;
    primary_language?: string;
    secondary_language?: string;
    disabled?: boolean;
    ethnicity?: string;
    height?: string;
    weight?: string;
    employer_name?: string;
    info_method?: string;
    terminated_date?: string;
    email_address?: string;
    employee?: number;
    member_value?: number;
    loyalty?: number;
    sentiment?: number;
    individual_risk_score?: number;
    household_risk_score?: number;
    primary_care_provider?: Provider;
    household?: Household;
    emergency_contact?: Contact;
    authorized_rep?: Contact;
    concierge_tier?: number;
    nps_score?: number;
    memberType?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    dateOfBirth?: string;
    date_of_birth?: string;
    dependent_index?: number;
    providerName?: string;
    isPCPSelected?: boolean;
    isMemberSelected?: boolean;
    type?: string;
    selectedProvider?: ProviderResult;
    sex?: string;
    uuid?: string;
    billing_address?: Address;
    mailing_address?: Address;
    permanent_address?: Address;
}
