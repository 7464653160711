/**
 * Change event object that is emitted when the user selects a
 * different page size or navigates to another page.
 */
export class ZipPageEvent {
    /** The current page index. */
    pageIndex: number;

    /** The current page size */
    pageSize: number;

    /** The current total number of items being paged */
    length: number;
}

export class TableGridPage {
    page_size = 10;
    totalElements = 0;
    totalPages = 0;
    page_number = 0;
    offset = 0;
    next?: string;
    previous?: string;

    constructor(page: any = {}) {
        Object.assign(this, page);
    }
}

export class TablePagedData {
    data = new Array();
    page = new TableGridPage();
}
