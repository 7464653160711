import { Member } from '@zipari/shared-sbp-enrollment';
import { Plan, Broker } from '@zipari/shared-sbp-models';

export enum applicationSources {
    zshop = 'zshop',
    zshopMedicare = 'zshop-medicare',
    'broker-portal' = 'broker-portal',
}

export class OnExchangeAppDetails {
    enrollmentPlans?: any;
    submittedVersion?: number;
    applicationCreationDateTime?: string;
    eligibilityResultsViewed?: boolean;
    id?: string;
    permissionsRevoked?: boolean;
    postIntegrationWorkflowId?: number;
    status?: string;
    year?: string;
    state?: string;
    submitted?: boolean;

    constructor(options) {
        Object.assign(this, options);
    }
}

export default class ApplicationModel {
    broker?: Broker;
    effective_date?: string;
    coverage_effective_date?: string;
    status_internal?: string;
    is_effectuated?: boolean;
    status?: string;
    application_source?: applicationSources;
    client?: number;
    id?: number | string;
    enrollment_json?: any;
    webuser_id?: string;
    agency_id?: string;
    tenant?: string;
    broker_id?: string;
    application_type?: string;
    created_at?: string;
    workflow_id?: number;
    confirmation_number?: string;
    termination_date?: string;
    submitted_date?: string;
    line_of_business?: string;
    updated_at?: string;
    spouse?: Member;
    subscriber?: Member;
    dependents?: Array<Member>;
    permanent_address?: {
        state?: string;
    };
    plan?: Plan;
    plans?: Record<string, Plan>;
    planArr?: Array<Plan>;
    // will get returned during application call with full workflow for associated workflow_id
    workflow?: any;

    // added by FE as helper to display the status as a label
    status_display?: string;
    title?: string;
    deleted?: boolean;
    de?: OnExchangeAppDetails;
    ede?: OnExchangeAppDetails;
    year?: string | number;
    identifier?: string | number;
    edeAppIdentifier?: string | number;

    constructor(options) {
        Object.assign(this, options);
    }
}
