import Zipcode from './Zipcode.model';

export default class County {
    county_display?: string;
    county_name: string;
    county_code: string;
    created_at?: string;
    updated_at?: string;
    state_name: string;
    state_code: string;
    zipcode?: string;
    zipcodes?: Zipcode[];
    has_plans?: boolean;
    plan_type_name?: string[];
    plan_year?: number[];
    file_date?: string;
    fips_county_code?: string;
    id?: number;
    lat?: number;
    lng?: number;
}
