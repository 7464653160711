<header class="shopping__header">
    <div class="container">
        <div class="u-flex u-alignCenter u-justifyBetween">
            <div class="shopping__header__steps">
                <a class="nav__logo" (click)="exitClicked.emit()" tabindex="0"> </a>

                <p class="t-data" [innerHTML]="label"></p>
            </div>

            <div class="shopping__header__exit" (click)="exitClicked.emit()">
                <i class="icon icon--medium" innerHTML="exit"></i>
                <p class="t-data t-bold">Exit To Dashboard</p>
            </div>
        </div>
    </div>
</header>
