import { Form } from '@angular/forms';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { ConfirmationModalConfig } from '@zipari/shared-sbp-components';

export class AttributeGridConfig {
    sections: Array<AttributeGridSection>;
    discardConfirmModal: ConfirmationModalConfig;
}

export class AttributeGridSection {
    label: string;
    key: string;
    attributes: Array<ModelAttributeConfig>;
    edit: AttributeGridEditorForm;
}

export class AttributeGridEditorForm {
    label: string;
    controls: Array<Form>;
}
