import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoverageEffectiveDateService } from '@zipari/shared-sbp-services';

//TODO remove after temp move has been finished
const qleEndpoint: string = `/api/product_changes/qle/`;

@Injectable({
    providedIn: 'root',
})
export class QLEService {
    constructor(private http: HttpClient, public coverageEffectiveDateService: CoverageEffectiveDateService) {}

    isGetStartedPristine: boolean = true;

    public updateGetStartedPristine(pristineVal: boolean): void {
        this.isGetStartedPristine = pristineVal;
    }

    public fetchEffectiveDate(qle_id: string, qle_date: string, enrollment_period?: string): Observable<any> {
        let endpoint: string = `/api/product_changes/qle/${qle_id}/effective_date/`;

        const params = { qle_date, enrollment_period };
        return this.http.get(endpoint, { params });
    }

    fetchQLEList(enrollment_period: string, state: string = '') {
        const params = { enrollment_period, state };

        return this.http.get(qleEndpoint, { params });
    }
}
