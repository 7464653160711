import { Component, EventEmitter, Input, Output } from '@angular/core';
import { accordionX, fade, slideRight } from '@zipari/design-system';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { Member } from '@zipari/shared-sbp-models';
import { AsideHeaderConfig } from '../provider-result.model';
import { providerSearchTypes } from '../provider-search.constant';
import { FormGroup } from '@angular/forms';
import { SearchService } from '../../search.service';

@Component({
    selector: 'provider-search-modals',
    templateUrl: './provider-search-modals.component.html',
    styleUrls: ['./provider-search-modals.component.scss'],
    animations: [accordionX, fade, slideRight],
})
export class ProviderSearchModalsComponent {
    @Input() showPCPModal: boolean;
    @Input() showFiltersModal: boolean;
    @Input() pcpModalConfig: ModalConfig;
    @Input() filtersModalConfig: ModalConfig;
    @Input() asideHeaderConfig: AsideHeaderConfig;
    @Input() members: Member[];
    @Input() whichWorkflow: string;
    @Input() filterGroupConfig;
    @Input() filtersForm: FormGroup;

    @Output() togglePCPModal = new EventEmitter<void>();
    @Output() toggleFiltersModal = new EventEmitter<void>();
    @Output() removeMemberPCP = new EventEmitter<Member>();
    @Output() select = new EventEmitter();
    @Output() filter = new EventEmitter<void>();
    @Output() reset = new EventEmitter<void>();

    constructor(private searchService: SearchService) {}

    getMemberName(member: Member): string {
        return member.name || `${member.first_name} ${member.last_name}`;
    }

    getProviderDetail(member: Member): string {
        return this.searchService.getProviderDetail(member, this.whichWorkflow);
    }

    selectedProviderName(member: Member): string {
        const selectedProviderFullName = `${member.selectedProvider.first_name ?? ''} ${member.selectedProvider.last_name ?? ''}`;

        return this.whichWorkflow === providerSearchTypes.doctor ? `${selectedProviderFullName}, MD` : selectedProviderFullName;
    }

    removePCP(member: Member): void {
        this.removeMemberPCP.emit(member);
    }

    selectConsumer(members: Member[], index: number): void {
        this.select.emit({ members, index });
    }
}
