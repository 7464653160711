import { Component, DoCheck, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlSerializer, UrlTree } from '@angular/router';
import { AllControlsConfiguration, controlTypes } from '@zipari/shared-ds-util-form';
import { GlobalConfig, validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import {
    AnalyticsService,
    AuthService,
    AppUserData,
    BrowserSupportConfig,
    ConfigService,
    LoggerService,
    ZipEndpointService,
    ZipBackendErrorResponse,
} from '@zipari/shared-sbp-services';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import {
    AuthCardOptions,
    AuthenticationConfig,
    AuthenticationLayouts,
    AuthenticationPages,
    authenticationRoutes,
    BannerOptions,
    defaultErrorMessages,
    pathType,
    RouteAfterSuccessConfig,
    StepOptions,
    validConfigs,
} from './authentication.constants';

@Component({
    selector: 'authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements DoCheck, OnInit {
    @Input() workflowValues;
    @Output() registerCompleted = new EventEmitter();
    @Output() completeStep = new EventEmitter();
    AuthenticationLayouts = AuthenticationLayouts;
    config: AuthenticationConfig = {};
    formGroup: UntypedFormGroup = new FormGroup({});
    busy: Promise<any> | Subscription;
    errorMessage: Array<string>;
    workflowId: string | number;
    success: boolean;
    token: string;
    previousPage: string;
    browserSupportConfig: BrowserSupportConfig;

    constructor(
        public loggerService: LoggerService,
        private urlSerializer: UrlSerializer,
        public zipEndpointService: ZipEndpointService,
        public authService: AuthService,
        public configService: ConfigService,
        public route: ActivatedRoute,
        public router: Router,
        public analyticsService: AnalyticsService
    ) {}

    @Input()
    set layout(layout: AuthenticationLayouts) {
        this.config.layout = layout;
    }

    @Input()
    set page(page: AuthenticationPages) {
        this.config.page = page;
    }

    @Input()
    set layoutOptions(layoutOptions: StepOptions | BannerOptions) {
        this.config.layoutOptions = layoutOptions;
    }

    @Input()
    set authCardOptions(authCardOptions: AuthCardOptions) {
        this.config.authCardOptions = authCardOptions;
    }

    _routeAfterSuccessConfig;

    get routeAfterSuccessConfig() {
        return this._routeAfterSuccessConfig || this.config.routeAfterSuccessConfig;
    }

    @Input()
    set routeAfterSuccessConfig(routeAfterSuccessConfig: RouteAfterSuccessConfig) {
        this.config.routeAfterSuccessConfig = routeAfterSuccessConfig;
        this._routeAfterSuccessConfig = routeAfterSuccessConfig;
    }

    public get disableButton() {
        return getValue(this.config, 'routeAfterSuccessConfig.disableAfterSuccess') && this.success;
    }

    get externalUrl(): string {
        return this.authService.externalLoginUrl || this.authService.externalRegisterUrl;
    }

    get subscriberDOB() {
        if (!!this.workflowValues) {
            if (this.workflowValues.child_only) {
                return { date_of_birth: this.workflowValues.dependents[0].date_of_birth };
            }
            return { date_of_birth: this.workflowValues.subscriber.date_of_birth };
        }
        return {};
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        // when enter is clicked submit the form as a filter
        if (event.which === 13 && this.formGroup.valid) {
            this.handleButtonClicked();
        }
    }

    ngOnInit() {
        if (this.authService.loggedInUser) {
            this.router.navigate([this.configService.appRoute]);
        } else {
            // tries to retrieve data from a route if added as a route
            this.retrieveDataForPage();

            // initializes page if needed
            this.initializePage();
        }

        const globalConfig = this.configService.getPageConfig<GlobalConfig>('global');
        this.browserSupportConfig = globalConfig && globalConfig.browserSupport;
    }

    /** core functionality */
    initializePage() {
        const replayPath = this.route.snapshot.queryParams.replayPath;

        this.token = this.route.snapshot.queryParams.token;
        this.workflowId = this.route.snapshot.queryParams.workflowId;

        if (this.token) {
            this.navigateToAuthenticationRoute('reset-password');
        }

        if (replayPath) {
            this.authService.replayPath = replayPath;
        }

        switch (this.config.page) {
            case AuthenticationPages['reset-password']:
                // handle reset password specific intialization logic
                break;
            case AuthenticationPages['forgot-password']:
                // handle forgot password specific intialization logic
                break;
            case AuthenticationPages.login:
                // handle login specific intialization logic

                if (!this.authService.loggedInUser) {
                    this.authService.handleExternalLogin();
                }
                break;
            case AuthenticationPages.register:
                // handle register specific intialization logic
                if (this.route.snapshot.queryParams.fromEmail) {
                    this.router.navigate([this.configService.appRoute, 'login']);
                }

                if (!this.authService.loggedInUser && !!this.externalUrl) {
                    this.authService.handleExternalRegister();
                }
                break;
        }
    }

    ngDoCheck() {
        if (this.config.page !== this.previousPage) {
            this.previousPage = this.config.page;

            const dictionary_attributes = {
                eventLabel: 'auth-page-viewed',
                pageName: `${this.config.page.charAt(0).toUpperCase() + this.config.page.slice(1)} Page`,
            };

            this.analyticsService.dispatchAnalytics(
                {
                    CXKey: validCXEvents.virtualPageView,
                    GAKey: validGAEvents.virtualPageView,
                    PosthogKey: validPosthogEvents.virtualPageView,
                },
                { ...dictionary_attributes }
            );
        }
    }

    handleLinkClicked(event) {
        // handle specific link clicking scenarios
        // if you need to handle a new page having a link clicked just create a new case in the switch statement
        switch (this.config.page) {
            case AuthenticationPages.login:
                this.handleLoginLinkClicked(event);
                break;
            case AuthenticationPages.register:
                this.handleRegisterLinkClicked(event);
                break;
        }
    }

    handleButtonClicked() {
        // handle specific button clicking scenarios per page
        switch (this.config.page) {
            case AuthenticationPages['reset-password']:
                this.handleResetPasswordButtonClicked();
                break;
            case AuthenticationPages['forgot-password']:
                this.handleForgotPasswordButtonClicked();
                break;
            case AuthenticationPages.register:
                this.handleRegisterButtonClicked();
                break;
            case AuthenticationPages.login:
                this.handleLoginButtonClicked();
                break;
        }
    }

    /** core functionality */

    /** page specific logic */
    handleResetPasswordButtonClicked() {
        const payload = { ...this.formGroup.value, token: this.token };
        this.busy = this.authService.resetPassword(payload).subscribe(
            (response) => {
                this.success = true;
                this.handleAuthSuccess(response);
            },
            (error: ZipBackendErrorResponse) => {
                if (error.status === 200) {
                    this.success = true;
                    this.handleAuthSuccess({});
                } else {
                    this.success = false;
                    this.errorMessage = this.zipEndpointService.handleErrorMessages(
                        error,
                        'Failed to reset password! Please try again later'
                    );
                    this.loggerService.error(error);
                }
            }
        );
    }

    handleForgotPasswordButtonClicked() {
        const payload = {
            ...this.formGroup.value,
            user_name: this.formGroup.value.username,
            question_text: this.formGroup.value.questionId,
            answer_text: this.formGroup.value.answerText,
            endpoint: this.config.endpoint ? this.config.endpoint : null,
        };

        const next = this.router.url.replace('forgot-password', 'reset-password');

        this.busy = this.authService.sendForgotPasswordEmail({ ...payload, next }).subscribe(
            (response) => {
                this.success = true;
                this.handleAuthSuccess(response);
            },
            (error) => {
                window.scroll(0, 0);
                this.errorMessage = this.zipEndpointService.handleErrorMessages(error, 'Something went wrong, please try again.');
                this.loggerService.error(error);
            }
        );
    }

    handleRegisterButtonClicked() {
        // This correctly handles adding fromEmail queryParam
        const nextUrlTree: UrlTree = this.router.parseUrl(this.router.url);
        nextUrlTree.queryParams.fromEmail = 'true';
        let nextUrl = this.urlSerializer.serialize(nextUrlTree);

        // Preserve the replay path if originally came looking for a particular path
        if (this.authService.replayPath) {
            nextUrl = `${nextUrl}&replayPath=${this.authService.replayPath}`;
        }

        const registerPayload = {
            ...this.formGroup.value,
            next: nextUrl,
        };

        //preserve the plan ids if the unauthenticated user selects the plan
        if (this.authService?.selectedPlanIds?.length) {
            registerPayload.selected_plan_ids = this.authService.selectedPlanIds;
        }

        this.busy = this.authService.register(registerPayload).subscribe(
            (response) => {
                window.scroll(0, 0);

                const dictionary_attributes = {
                    eventLabel: 'Registration',
                    pageName: `${this.config.page.charAt(0).toUpperCase() + this.config.page.slice(1)} Page`,
                };

                this.analyticsService.dispatchAnalytics(
                    {
                        CXKey: validCXEvents.registration_submit,
                        GAKey: validGAEvents.registration_submit,
                        PosthogKey: validPosthogEvents.registration_submit,
                    },
                    { ...dictionary_attributes }
                );

                this.registerCompleted.emit(this.formGroup.value.email);

                this.success = true;

                this.handleAuthSuccess(response);
            },
            (error) => {
                window.scroll(0, 0);
                this.errorMessage = this.zipEndpointService.handleErrorMessages(
                    error,
                    defaultErrorMessages[this.config.page],
                    this.config.accountAlreadyExistsErrorMessage
                );
                this.loggerService.error(error);
            }
        );
    }

    handleLoginButtonClicked() {
        this.busy = this.authService.login(this.formGroup.value).subscribe(
            (response) => {
                this.errorMessage = null;
                // remove fromemail query param after login if exists
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        fromEmail: null,
                    },
                    queryParamsHandling: 'merge',
                });

                this.busy = new Promise<void>((resolve, reject) =>
                    this.authService
                        .getUser({})
                        .then((app_user) => {
                            return this.configService
                                .initializeDBConfigWithLocalOverride(this.configService.app, {
                                    setConfig: true,
                                    makeAPICall: true,
                                })
                                .then(() => {
                                    this.retrieveDataForPage(true);
                                    this.authService.setLoggedInUser(app_user);
                                    this.sendCXData();
                                    this.completeStep.emit();
                                    this.success = true;
                                    this.handleAuthSuccess(response, app_user);
                                    resolve(null);
                                });
                        })
                        .catch((error: ZipBackendErrorResponse) => {
                            this.errorMessage = this.zipEndpointService.handleErrorMessages(
                                error,
                                defaultErrorMessages[this.config.page],
                                this.config.accountAlreadyExistsErrorMessage
                            );
                            this.loggerService.error(error);
                            reject(error);
                        })
                );
            },
            (error: ZipBackendErrorResponse) => {
                this.errorMessage = this.zipEndpointService.handleErrorMessages(error, defaultErrorMessages[this.config.page]);
                this.loggerService.error(error);
            }
        );
    }

    sendCXData() {
        const dictionary_attributes = {
            eventLabel: 'user-login',
            pageName: 'Successful Authentication',
        };

        this.analyticsService.dispatchAnalytics(
            {
                CXKey: validCXEvents.user_login,
                GAKey: validGAEvents.user_login,
                PosthogKey: validPosthogEvents.user_login,
            },
            { ...dictionary_attributes }
        );
    }

    handleRegisterLinkClicked(event) {
        switch (event.prop) {
            case AuthenticationPages.login:
                this.navigateToAuthenticationRoute(AuthenticationPages.login);
                break;
        }
    }

    handleLoginLinkClicked(event) {
        switch (event.prop) {
            case 'register':
                this.navigateToAuthenticationRoute(AuthenticationPages.register);
                break;
            case 'password':
                this.navigateToAuthenticationRoute(AuthenticationPages['forgot-password']);
                break;
        }
    }

    /** page specific logic */

    /** utility functions */
    navigateToAuthenticationRoute(page) {
        let route = this.formatAuthenticationRoute(page);

        this.router.navigate([route], { queryParamsHandling: 'merge' });
    }

    retrieveDataForPage(force = false) {
        this.retrieveDataFromRoute(this.route);
        this.retrieveDataFromConfig(force);
    }

    retrieveDataFromConfig(force: boolean) {
        const routeFromConfig = this.configService.getPageConfig(this.config.page);

        if (routeFromConfig) {
            const keys = Object.keys(routeFromConfig);
            keys.forEach((key) => {
                if (routeFromConfig[key] && (!this.config[key] || force)) {
                    this.config[key] = routeFromConfig[key];
                }
            });
        }
    }

    retrieveDataFromRoute(route: ActivatedRoute) {
        const data = route.snapshot.data;

        validConfigs.forEach((key) => {
            if (data[key] && !this.config[key]) {
                this.config[key] = data[key];
            }
        });
    }

    routeAfterSuccess(id = null) {
        const routes = [this.routeAfterSuccessConfig.route];
        const params = this.routeAfterSuccessConfig.params;

        if (id) {
            routes.push(String(id));
        }

        this.router.navigate(routes, {
            queryParams: params,
        });
    }

    formatAuthenticationRoute(page: string) {
        return stringBuilder(authenticationRoutes[page], {
            app: this.configService.appRoute,
        });
    }

    handleRedirect(url) {
        if (url.includes('http')) {
            window.location.assign(url);
        } else {
            this.router.navigate([url]);
        }
    }

    checkRedirectPathForHost(redirectPath: string) {
        if (!redirectPath) {
            return null;
        }

        const hostName: string = window.location.host;
        const cleanedRedirectPath: string =
            redirectPath.indexOf('http://') >= 0 ? redirectPath.replace('http://', '') : redirectPath.replace('https://', '');

        return hostName !== cleanedRedirectPath;
    }

    handleAuthSuccess(response, user?: AppUserData): void {
        const url: URL = new URL(user?.data?.next, window.location.origin);
        const replayPath: string = url.searchParams.get(pathType.replayPath);

        if (this.authService.replayPath && this.authService.replayPath !== '/' && this.config.page === AuthenticationPages.login) {
            this.router.navigate([this.authService.replayPath]);

            this.authService.replayPath = null;
        } else if (user?.data?.selected_plan_ids?.length && replayPath) {
            this.router.navigate([replayPath]);
        } else if (
            response['redirect_path'] &&
            response['redirect_path'] !== '/' &&
            this.checkRedirectPathForHost(response['redirect_path'])
        ) {
            this.handleRedirect(response['redirect_path']);
        } else if (this.routeAfterSuccessConfig) {
            if (this.routeAfterSuccessConfig.completeStep) {
                this.completeStep.emit();
            }

            if (this.routeAfterSuccessConfig.route) {
                this.routeAfterSuccess();
            }

            // this code is ran when register is completed on shopping
            if (this.routeAfterSuccessConfig.disableAfterSuccess) {
                // add the email to the success title
                this.config.authCardOptions.successTitle = stringBuilder(this.config.authCardOptions.successTitle, {
                    email: this.formGroup.value.email,
                });

                this.config.authCardOptions.form.controls = this.config.authCardOptions.form.controls.map(
                    (formConfig: AllControlsConfiguration) => {
                        formConfig.type = controlTypes.password;
                        formConfig['canToggle'] = false;
                        formConfig.isDisabled = true;

                        return formConfig;
                    }
                );
            }
        }
    }

    /** utility functions */
}
