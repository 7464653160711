<article class="notifications">
    <div class="notifications__container container">
        <header class="notifications__header">
            <h4 class="t-bold">Recent Notifications</h4>
        </header>
        <div class="notifications__list" *ngIf="notifications.length > 0; else empty">
            <notification
                *ngFor="let notification of notifications"
                class="notifications__row"
                [notification]="notification"
                (readNotification)="setAsRead(notification)"
                (deleteNotification)="deleteNotification(notification)"
            >
            </notification>
        </div>
        <ng-template #empty>
            <p *ngIf="config.noNotifications && !loading">{{ noNotifications }}</p>
        </ng-template>
    </div>
</article>

<zip-footer *ngIf="config?.showFooter && !footerConfig?.usesDesignSystem"></zip-footer>
