import { Injectable, Provider } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { stringBuilder } from '@zipari/web-utils';
import { SquidexApiService } from '@zipari/shared-sbp-services';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { SquidexTokenResponse } from '@zipari/shared-sbp-models';

const squidexTokenName = 'zpr_squidex_token';
const getTokenBodyStrTemplate = 'grant_type=client_credentials&scope=squidex-api&client_id=${clientId}&client_secret=${clientSecret}';

function getBearerToken(): string {
    return localStorage.getItem(squidexTokenName);
}

function setBearerToken(token: string): void {
    localStorage.setItem(squidexTokenName, token);
}

function clearBearerToken(): void {
    localStorage.removeItem(squidexTokenName);
}

@Injectable()
export class SquidexAuthInterceptor implements HttpInterceptor {
    constructor(private squidex: SquidexApiService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return req.url.includes('squidex') ? this.invokeInternal(req, next, true) : next.handle(req);
    }

    private invokeInternal(req: HttpRequest<any>, next: HttpHandler, retry: boolean): Observable<HttpEvent<any>> {
        return this.getToken(next).pipe(
            switchMap((token) => {
                // add squidex token to header
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                return next.handle(req).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if ((error.status === 403 || error.status === 401) && retry) {
                            // clear the current stored token and make one more attempt to get a new token
                            clearBearerToken();
                            return this.invokeInternal(req, next, false);
                        }

                        // already attempted to get a new token, throw error
                        return throwError(error);
                    })
                );
            })
        );
    }

    /**
     * Returns a squidex token. Gets token from local storage or by requesting our squidex instance for a token.
     */
    private getToken(next: HttpHandler): Observable<string> {
        // return cached token if it exists
        const cachedToken = getBearerToken();
        if (cachedToken) {
            return of(cachedToken);
        }

        const { clientId, clientSecret } = this.squidex;
        const body = stringBuilder(getTokenBodyStrTemplate, { clientId, clientSecret });
        const tokenRequest = new HttpRequest('POST', `${this.squidex.baseUrl}/identity-server/connect/token`, body, {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });

        // get new token and cache it
        return next.handle(tokenRequest).pipe(
            filter((x) => x instanceof HttpResponse),
            map((response: HttpResponse<SquidexTokenResponse>) => {
                const token = response.body.access_token;

                setBearerToken(token);

                return token;
            })
        );
    }
}

export const SQUIDEX_AUTH_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: SquidexAuthInterceptor,
    multi: true,
};
