<div class="WhoseCovered">
    <label [innerHTML]="individualsCoveredConfig?.label"></label>
    <section class="WhoseCovered__coverageSection">
        <div
            class="WhoseCovered__coverageOption"
            *ngFor="let whoseCoveredOption of whoseCoveredOptions | keyvalue : keepOrder"
            [attr.aria-label]="whoseCoveredOptions[whoseCoveredOption.key].label"
            [ngClass]="{ 'is-active': whoseCoveredOptions[whoseCoveredOption.key]?.value === whoseCoveredSelectedValue }"
            (click)="buildMemberForm(whoseCoveredOptions[whoseCoveredOption.key].value)"
        >
            <div class="WhoseCovered__coverageOptionAvatar">
                <!-- <i *ngIf="whoseCoveredOptions[whoseCoveredOption.key].icon.indexOf(':') == -1" class="icon icon--medium">
                    {{ whoseCoveredOptions[whoseCoveredOption.key]?.icon }}
                </i> -->
                <icon
                    *ngIf="whoseCoveredOptions[whoseCoveredOption.key].icon.indexOf(':') == -1"
                    [config]="{ size: 'large' }"
                    [name]="whoseCoveredOptions[whoseCoveredOption.key].icon"
                    class="icon"
                ></icon>
            </div>
            <p class="t-data t-bold t-alt" [innerHTML]="whoseCoveredOptions[whoseCoveredOption.key].label"></p>
        </div>
    </section>
</div>

<ng-container *ngIf="showSubscriber">
    <whose-covered-form
        [whoseCoveredGroup]="whoseCoveredGroup"
        [config]="config"
        [subSpouseConfig]="subscriberConfig"
        [isOverSixtyFive]="isSubscriberOverSixtyFive"
        [isSubscriberOnly]="isSubscriberOnly"
        [maxAgeLimit]="maxAgeForSubscriber"
        [showSubscriber]="showSubscriber"
        [whoseCoveredValue]="whoseCoveredValue"
        [hasParentDependents]="hasParentDependents"
        (addDependent)="addDependent($event)"
    >
    </whose-covered-form>
</ng-container>
<ng-container *ngIf="showSpouse">
    <whose-covered-form
        [whoseCoveredGroup]="whoseCoveredGroup"
        [config]="config"
        [subSpouseConfig]="spouseConfig"
        [isOverSixtyFive]="isSpouseOverSixtyFive"
        [maxAgeLimit]="maxAgeForSpouse"
        [showSpouse]="showSpouse"
        (removeDependent)="removeDependent($event)"
    >
    </whose-covered-form>
</ng-container>
<ng-container *ngIf="showChild || showChildOnly">
    <whose-covered-form
        [whoseCoveredGroup]="whoseCoveredGroup"
        [config]="config"
        [childOnlyConfig]="childOnlyConfig"
        [dependentVal]="dependentVal"
        (removeDependent)="removeDependent($event)"
        [dependentConfig]="dependentConfig"
        [depLimit]="childLimit"
        [dependentButtonConfig]="dependentButtonConfig"
        [showChildOnly]="showChildOnly"
        [showChild]="showChild"
        [whoseCoveredValue]="whoseCoveredValue"
        (addDependent)="addDependent($event)"
    >
    </whose-covered-form>
</ng-container>
<ng-container *ngIf="showParent">
    <whose-covered-form
        [whoseCoveredGroup]="whoseCoveredGroup"
        [config]="config"
        [showChildOnly]="showChildOnly"
        [dependentVal]="parentDependentVal"
        (removeDependent)="removeDependent($event)"
        [dependentConfig]="parentDependentConfig"
        [depLimit]="config?.parentLimit"
        [dependentButtonConfig]="parentButtonConfig"
        [showParent]="showParent"
        [whoseCoveredValue]="whoseCoveredValue"
        (addDependent)="addDependent($event)"
    >
    </whose-covered-form>
</ng-container>
