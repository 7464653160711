import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule, FormControlModule } from '@zipari/design-system';
import { FormGroupModule } from '../form-group/form-group.module';
import { SharedModule } from '../shared.module';

import { DetailsAccordionComponent } from './details-accordion.component';

@NgModule({
    declarations: [DetailsAccordionComponent],
    imports: [CommonModule, AccordionModule, FormControlModule, FormGroupModule, SharedModule],
    providers: [],
    exports: [DetailsAccordionComponent],
})
export class DetailsAccordionModule {}
