import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PageButtonsComponent } from './page-buttons/page-buttons.component';
import { PageCountComponent } from './page-count/page-count.component';
import { PaginatorComponent } from './paginator.component';

@NgModule({
    declarations: [PaginatorComponent, PageButtonsComponent, PageCountComponent],
    imports: [CommonModule],
    exports: [PaginatorComponent],
})
export class PaginatorModule {}
