import { Params } from '@angular/router';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';

export enum AuthenticationLayouts {
    step = 'step',
    banner = 'banner',
}

export enum AuthenticationPages {
    login = 'login',
    register = 'register',
    'forgot-password' = 'forgot-password',
    'reset-password' = 'reset-password',
}

export class StepOptions {}

export class BannerOptions {
    heroImg: string;
    header?: any;
    sections: Array<any>;
}

export class AuthCardFooterLinkOptions {
    label: string;
    prop: string;
}

export class AuthCardFooterOptions {
    link: AuthCardFooterLinkOptions;
    button: ButtonConfig;
}

export class AuthCardOptions {
    title: string;
    subtitle: string;
    form: {
        controls: Array<AllControlsConfiguration>;
    };
    patchDOB?: boolean;
    footer: AuthCardFooterOptions;
    successTitle?: string;
    successSubtitle?: string;
}

export class RouteAfterSuccessConfig {
    completeStep?: boolean;
    route?: string;
    id?: any;
    params?: Params;
    checkApplication?: boolean;
    disableAfterSuccess?: boolean;
}

export class AuthenticationConfig {
    routeAfterSuccessConfig?: RouteAfterSuccessConfig;
    layout?: AuthenticationLayouts;
    page?: AuthenticationPages;
    layoutOptions?: StepOptions | BannerOptions;
    authCardOptions?: AuthCardOptions;
    endpoint?: string;
    accountAlreadyExistsErrorMessage?: string;
}

export const validConfigs = ['page', 'layout', 'layoutOptions', 'authCardOptions', 'routeAfterSuccessConfig'];

export const authenticationRoutes = {
    login: '${app}/login',
    register: '${app}/register',
    'forgot-password': '${app}/forgot-password',
    'reset-password': '${app}/reset-password',
};

export const defaultErrorMessages = {
    login: 'Failed to login, please try again',
    register: 'Failed to register, please try again.',
};

export const pathType = {
    replayPath: 'replayPath',
};
