import { WorkflowStep } from '@zipari/shared-sbp-models';

export enum validStepperTypes {
    iconDisplay = 'iconDisplay',
    check = 'check',
    default = 'default',
}

export enum overflowDirections {
    left = 'left',
    right = 'right',
}

export class StepEmitter {
    displayIndex?: number;
    step?: WorkflowStep;
}

export interface StepperDisplaySchema {
    label?: string;
    stepIndex?: number;
    uniqueId?: string;
}
