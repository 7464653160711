import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PlanTypes } from '@zipari/shared-sbp-models';

export const mockLoggedInUser = {
    app_user_data: {
        broker_id: 181167,
        first_name: 'John',
        last_name: 'Deo',
    },
};

export const coverageFormConfig = {
    zipcode: {
        grid: {
            col: '1 / 2',
            row: '2',
        },
        mask: 'number',
        prop: 'zipcode',
        type: 'text',
        label: 'ZIP Code',
        maxLength: 5,
        enterAnother: 'Please enter a valid ZIP Code',
        validators: ['required', 'zipcode'],
        isDisabled: false,
        errors: {
            zipcode: 'Please enter a valid ZIP Code',
            customErrMessage: 'There are no plans offered in this ZIP Code',
        },
        value: '12008',
    },
    county: {
        grid: {
            col: '2 / 4',
            row: '2',
        },
        prop: 'county',
        type: 'dropdown',
        label: 'County',
        options: [],
        validators: ['required'],
        isDisabled: false,
        errors: {
            customErrMessage: 'There are no plans offered in this county',
        },
        value: '36093',
    },
    coverage_type: {
        grid: {
            col: '4 / 6',
            row: '2',
        },
        prop: 'coverage_type',
        type: 'checkbox',
        label: 'Coverage Type',
        options: [
            {
                label: 'Medical',
                value: 'health',
            },
            {
                label: 'Dental',
                value: 'dental',
            },
            {
                label: 'Vision',
                value: 'vision',
            },
        ],
        validators: ['required'],
        isDisabled: false,
        conditional: {},
        default: '',
        value: 'health',
    },
};

export const countyLocationData = {
    county_code: '36093',
    county_name: 'Schenectady',
    file_date: '2023-06-22T15:21:20.817030',
    fips_county_code: '36093',
    has_plans: true,
    id: 3849,
    lat: 42.85367,
    lng: -73.9063,
    plan_type_name: ['dental'],
    plan_year: [2023, 2024],
    state_code: 'NY',
    state_name: 'New York',
    zipcode: '12008',
    zipcodes: [],
};

export const endpoint = 'api/shopping/plans/';
export const pageName = 'enrollment';

export const coverageFormValue = {
    zipcode: '12008',
    county: '36093',
    coverage_type: ['health'],
};

export const getPlansParameters = {
    effective_date: '07/11/2021',
    getStartedFormValue: {
        zipcode: '12027',
        county: '92023',
    },

    endpoint: endpoint,
};

export const CoverageFormFormGroupMock = new UntypedFormGroup({
    zipcode: new UntypedFormControl(coverageFormConfig.zipcode),
    county: new UntypedFormControl(coverageFormConfig.county),
    coverage_type: new UntypedFormControl(coverageFormConfig.coverage_type),
});

export const getStartedFormValue = {
    county: '243242',
    zipcode: '12003',
    coverage_type: ['medical'],
};

export const coverageEffectiveDate = '2023-11-01';

export const CoverageParam = {
    county_code: getStartedFormValue.county,
    zipcode: getStartedFormValue.zipcode,
    coverage_effective_date: coverageEffectiveDate,
    year: '2023',
    market_segment: 'individual',
    plan_type: 'medical',
    page_size: 1,
    page: 1,
};

export const plansResponse = {
    county: '243242',
    zipcode: '12003',
    coverage_type: ['medical'],
};

export const expectedCoverageFormConfigErrorMessage = {
    zipcode: {
        ...coverageFormConfig.zipcode,
        errors: {
            zipcode: 'Please enter a valid ZIP Code',
            customErrMessage: 'There are no plans offered in this ZIP Code',
        },
    },
    county: {
        ...coverageFormConfig.county,
        errors: {
            customErrMessage: 'There are no plans offered in this county',
        },
    },
    coverage_type: {
        ...coverageFormConfig.coverage_type,
    },
};

export const plansApiResponse = {
    count: 1,
    next: 1,
    previous: 0,
    results: [
        {
            id: 102271,
            hios_ids: [
                {
                    hios_id: '70285NY804000100',
                    rating_region: 1,
                },
            ],
            year: 2024,
            percent_of_premium: '',
            deductible: null,
            deductible_family: null,
            max_out_of_pocket: null,
            max_out_of_pocket_family: null,
            price: '600',
            static_price: 600,
            premium: null,
            star_rating: null,
            external_id: 'BH2024-001',
            plan_type: PlanTypes.health,
            metal_tier: 'Gold',
            market_segment: 'individual',
            display_name: 'BrandHealthZ Medical Gold Plan',
            plan_variation: '00',
            exchange_type: null,
            hsa: true,
            county: {
                county_name: 'Schenectady',
                fips_county_code: '36093',
            },
            plan_codes: [],
            documents: {},
            member_prices: {},
            benefits: [
                {
                    label: 'Deductible',
                    value: '800',
                    type: null,
                    benefit_display_index: null,
                },
                {
                    label: 'In Network Out Of Pocket Maximum',
                    value: '3900',
                    type: null,
                    benefit_display_index: null,
                },
                {
                    label: 'Inpatient Physician and Surgical Services',
                    value: 'Copay - $0.00 after deductible',
                    type: null,
                    benefit_display_index: null,
                },
            ],
            primary_benefits: [
                {
                    label: 'Office Visits',
                    value: '25',
                    type: null,
                    benefit_display_index: null,
                },
                {
                    label: 'Specialist Visits',
                    value: '35',
                    type: null,
                    benefit_display_index: null,
                },
                {
                    label: 'Emergency Coverage',
                    value: 'Copay - $50.00 with deductible',
                    type: null,
                    benefit_display_index: null,
                },
                {
                    label: 'Generic Drugs',
                    value: 'Copay - $25.00|Coinsurance - Not Applicable',
                    type: null,
                    benefit_display_index: null,
                },
            ],
            add_ons: [],
            links: [],
            hmo: null,
            internal_name: null,
            is_active: true,
            is_benchmark: false,
            is_catastrophic: false,
            is_auto_bundle: false,
            network_codes: ['H00000000001'],
            networks: [
                {
                    network_code: 'H00000000001',
                    name: 'H00000000001',
                    year: 2024,
                },
            ],
            rating_region: [
                {
                    region_name: 1,
                    plan_year: 2024,
                },
            ],
            primary_care: '',
            generic_rx: '',
            plan_marketing_description: 'Premium Plan',
        },
    ],
};

export const mockMultiPlanTypes = [
    {
        label: 'Bundled',
        prop: 'bundled',
        conditional: {
            health: {
                label: 'Bundled',
                prop: 'bundled',
            },
        },
    },
    {
        label: 'Dental',
        prop: 'dental',
        conditional: {
            dental: {
                label: 'Dental',
                prop: 'dental',
            },
        },
    },
    {
        label: 'Vision',
        prop: 'vision',
        conditional: {
            vision: {
                label: 'Vision',
                prop: 'vision',
            },
        },
    },
];
