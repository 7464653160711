import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EdeStepperComponent } from './ede-stepper.component';
import { EdeStepComponent } from './ede-step/ede-step.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EdeStepperComponent, EdeStepComponent],
    exports: [EdeStepperComponent, EdeStepComponent],
})
export class EdeStepperModule {}
