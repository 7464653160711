import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControlModule, FormGroupModule, MessageBannerModule, ZipBusyModule } from '@zipari/design-system';
import { DirectivesModule } from '@zipari/web-utils';
import { CoverageFormComponent } from '@zipari/shared-sbp-enrollment';

@NgModule({
    declarations: [CoverageFormComponent],
    providers: [],
    imports: [CommonModule, DirectivesModule, FormControlModule, FormGroupModule, MessageBannerModule, ZipBusyModule],
    exports: [CoverageFormComponent],
})
export class CoverageFormModule {}
