import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Notification } from '@zipari/design-system';
import { cloneObject, getValue, stringBuilder } from '@zipari/web-utils';
import { AuthService, ConfigService } from '@zipari/shared-sbp-services';

import { NOTIFICATIONS } from './notification-list.constants';

@Component({
    selector: 'notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
    loading: boolean = true;
    busy: Subscription;
    pageName: string;
    config;
    notifications: Notification[] = [];
    footerConfig;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public authService: AuthService,
        public configService: ConfigService,
        public httpClient: HttpClient
    ) {}

    ngOnInit() {
        this.pageName = this.route.snapshot.data['pageName'];
        this.config = this.configService.getPageConfig(this.pageName);
        this.footerConfig = this.configService.getPageConfig('footer');
        this.getNotifications();
    }

    formatNotification = (apiNotification) => {
        const notification = cloneObject(apiNotification);
        const { meta_tags, template: body } = notification;
        const is_deletable = meta_tags.indexOf('is_not_deletable') < 0;
        const subtypes = ['benefits', 'document', 'enrollment', 'general', 'payment'];
        const subtype = subtypes.filter((type) => meta_tags.indexOf(type) > -1)[0] || 'general';

        Object.assign(notification, {
            body,
            is_deletable,
            subtype,
        });

        return new Notification(notification);
    };

    getNotifications() {
        const user = getValue(this.authService, 'loggedInUser.app_user_data');
        const configApi = getValue(this.config, 'api.list');
        const api = stringBuilder(configApi, user);

        this.busy = this.httpClient.get(api).subscribe(
            (res: any) => {
                this.notifications = res.results.map(this.formatNotification);
                this.loading = false;
                this.setUnreadCount();
            },
            (error) => {
                console.error(error);
                this.loading = false;
            }
        );
    }

    setAsRead(notification) {
        const configApi = getValue(this.config, 'api.edit');
        const api = stringBuilder(configApi, notification);
        notification.is_read = true;

        this.busy = this.httpClient.put(api, notification).subscribe(
            (res: any) => {
                this.loading = false;
            },
            (error) => {
                console.error(error);
                this.loading = false;
            }
        );

        this.setUnreadCount();
    }

    deleteNotification(notification) {
        const configApi = getValue(this.config, 'api.edit');
        const api = stringBuilder(configApi, notification);

        if (notification.is_deletable) {
            notification.is_deleted = true;
            this.notifications = this.notifications.filter(({ id }) => id !== notification.id);
            this.busy = this.httpClient.put(api, notification).subscribe(
                (res: any) => {
                    this.notifications = res.results.map(this.formatNotification);
                    this.loading = false;
                    this.setUnreadCount();
                },
                (error) => {
                    console.error(error);
                    this.loading = false;
                }
            );
        }
    }

    setUnreadCount() {
        const unread = this.notifications.filter((obj) => !obj['is_read']).length.toString();
        window.sessionStorage.setItem('zipNotificationsUnread', unread);
    }
}
