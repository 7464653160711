import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService, SquidexApiService } from '@zipari/shared-sbp-services';

@NgModule({
    imports: [CommonModule],
})
export class SBPServicesModule {
    public static forRoot(environment: any): ModuleWithProviders<any> {
        return {
            ngModule: SBPServicesModule,
            providers: [
                ConfigService,
                SquidexApiService,
                {
                    provide: 'environment',
                    useValue: environment,
                },
            ],
        };
    }
}
