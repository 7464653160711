import { WorkflowPlanStructure } from '@zipari/shared-sbp-models';

class Address {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    county?: string;
    zipcode: string;
    zip_code: string;
    county_display?: string;
    verified: boolean;
    street_name_1?: string;
    street_name_2?: string;
}

export class Member {
    memberType?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    suffix?: string;
    ssn?: number;
    date_of_birth?: Date;
    gender?: string;
    previous_coverage?: string;
    primary_phone?: number;
    secondary_phone?: number;
    email_address?: string;
    mailing_address?: Address;
    address?: Address;
    tobacco_user?:
        | boolean
        | {
              tobacco_user: boolean;
          };
}

export class Contact {
    email: string;
    home_phone: string | number;
    primary_phone: string | number;
    secondary_phone: string | number;
    phone: string | number;
    email_address: string;
    secondary_phone_number: string | number;
}

export class CurrentBroker {
    source: string;
    last_name: string;
    phone: string | number;
    email_address: string;
    broker_id: string | number;
    first_name: string;
    agency_id: string | number;
}

export class Questions {
    current_vision_coverage: boolean;
    current_medical_coverage: boolean;
    medicare_enrolled: boolean;
    state_of_residence_cal: boolean;
    previous_coverage: boolean;
    medicare_eligible: boolean;
    language_preference: string;
    current_dental_coverage: boolean;
    marital_status: string;
    current_coverage: boolean;
}

export class MemberCoverageOption {
    icon?: string;
    value?: string;
    cards?: Array<string>;
    label?: string;
}

export interface EdeTobaccoMapping {
    [memberId: string]: {
        tobacco_user: boolean;
        lastTobaccoUsedDate?: string;
    };
}

export class IndividualEnrollment {
    max_subsidy_amount?: number;
    subsidy_amount?: number;
    enrollment_period?: 'SEP' | 'OE';
    members?: Array<Member>;
    memberCoverageOption?: MemberCoverageOption;
    county?: any;
    child_only?: boolean;
    is_spouse?: boolean;
    is_dependents?: boolean;
    plans?: WorkflowPlanStructure;
    plan_variation?: Array<any>;
    dependents?: Array<Member>;
    spouse?: Member;
    subscriber?: Member;
    mailing_address_different_from_permanent?: boolean;
    contact_info?: Contact;
    contact?: Contact;
    enrollmentPeriod?: string;
    coverage_effective_date?: string;
    mailing_address?: Address;
    permanent_address?: Address;
    questions?: Questions;
    coverage_type?: string;
    coverage_types?: Array<string>;
    special_enrollment?: any;
    current_broker?: CurrentBroker;
    demographics?: {
        child_only: boolean;
        subscriber?: Member;
        spouse?: Member;
        dependents?: Array<Member>;
        zipcode: number;
        total_members: number;
        county_code: string;
        state: string;
        coverage_type: string;
        coverage_types?: Array<string>;
        displayCoverageType?: string;
        is_sep: boolean;
        county: any;
        // coverage_effective_date: Date;
    };
    personalize?: {
        message: string;
        id: number;
        answers: Array<number>;
    };
    personal_info?: {
        first_name: string;
        last_name: string;
        suffix?: string;
        ssn?: number;
        date_of_birth: Date;
        marital_status: string;
        gender: string;
        previous_coverage: string;
        primary_phone: number;
        secondary_phone?: number;
        email_address: string;
        permanent_address: {
            address1: string;
            address2?: string;
            city: string;
            state: string;
            zipcode: string;
        };
        mailing_address: {
            address1: string;
            address2?: string;
            city: string;
            state: string;
            zipcode: string;
        };
    };
    sep?: {
        qle: any;
    };
    previous_plan?: {};
    quoteId?: any;

    /** used only for EDE */
    edeTobaccoMapping?: EdeTobaccoMapping;
}

export enum IndividualEnrollmentStepTypes {
    demographics = 'demographics',
    decision_support = 'decision_support',
    recommended_plans = 'recommended_plans',
    eligibility = 'eligibility',
    plan_selection = 'plan_selection',
    dental_vision = 'dental_vision',
    autobundle = 'autobundle',
    personal_info = 'personal_info',
    qle_document_upload = 'qle_document_upload',
    pcp_selection = 'pcp_selection',
    special_enrollment_period = 'special_enrollment_period',
    review = 'review',
    enrollment_confirm = 'enrollment_confirm',
    billing = 'billing',
    billing_confirmation = 'billing_confirmation',
    confirmation = 'confirmation',
    complete = 'complete',
}

export const enrollmentConfirmationSteps = [
    IndividualEnrollmentStepTypes.billing_confirmation,
    IndividualEnrollmentStepTypes.enrollment_confirm,
    IndividualEnrollmentStepTypes.confirmation,
    IndividualEnrollmentStepTypes.complete,
];
