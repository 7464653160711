<footer class="form__section__footer" [ngClass]="{ readonly: disabled }">
    <div class="help__text" *ngIf="helpText">
        <a class="t-link" (click)="linkClicked.emit(helpText)">{{ helpText.text }}</a>
    </div>

    <div class="button__group">
        <ng-template #saveAndContinue>
            <zip-button *ngIf="!disabled" [disabled]="!valid" (zipButtonClicked)="buttonClicked.emit()" [content]="content" level="high">
            </zip-button>
        </ng-template>
        <zip-button *ngIf="disabled; else saveAndContinue" icon="edit" (zipButtonClicked)="edit.emit()" [content]="'edit'" level="medium">
        </zip-button>
    </div>
</footer>
