import { Component, Renderer2, Input, Output, EventEmitter } from '@angular/core';

import { EdeStepComponent } from './ede-step/ede-step.component';

export interface ProgressEventData {
    current: number;
    total: number;
    isComplete: boolean;
    id: string;
    goToStep: Function;
    nextStep: Function;
    sections: {
        id: string;
        active: boolean;
        isVisible: boolean;
        isComplete: boolean;
        label: string;
    }[];
}

@Component({
    selector: 'ede-stepper',
    templateUrl: './ede-stepper.component.html',
    styleUrls: ['./ede-stepper.component.scss'],
})
export class EdeStepperComponent {
    @Input() showPreviousSteps = true;
    @Output() stepEvent = new EventEmitter<EdeStepComponent>();
    steps: EdeStepComponent[] = [];
    isComplete = false;

    constructor(private readonly renderer: Renderer2) {}

    goToStep(id: string): void {
        const idx = this.enabledSteps.findIndex((step) => step.id === id);
        this.steps[idx].active = true;
        this.isComplete = false;
        this.stepEvent.emit(this.steps[idx]);
    }

    nextStep(): void {
        if (!this.isLastStep) {
            this.enabledSteps[this.currentStepIndex + 1].active = true;
        } else {
            this.isComplete = true;
        }
        this.stepEvent.emit(this.steps[this.currentStepIndex]);
    }

    previousStep(): void {
        if (!this.isFirstStep) {
            this.enabledSteps[this.currentStepIndex - 1].active = true;
            this.isComplete = false;
            this.stepEvent.emit(this.steps[this.currentStepIndex]);
        }
    }

    progressEventData(sectionId: string): ProgressEventData {
        return {
            current: this.currentStepIndex + 1,
            total: this.enabledSteps.length,
            isComplete: this.isComplete,
            id: sectionId,
            goToStep: (id: string) => this.goToStep(id),
            nextStep: () => this.nextStep(),
            sections: this.enabledSteps.map((step) => {
                const active = !!step.active ? step.active.valueOf() : false;
                const isVisible = step.isVisible.valueOf();
                return {
                    id: step.id,
                    active,
                    isVisible,
                    isComplete: !active && isVisible,
                    label: step.label,
                };
            }),
        };
    }

    get enabledSteps(): EdeStepComponent[] {
        return this.steps.filter((s) => !s.disabled);
    }

    get activeSteps(): EdeStepComponent[] {
        return this.steps.filter((s) => !s.disabled && s.active);
    }

    get currentStep(): EdeStepComponent {
        return this.enabledSteps.find((s) => s.active);
    }

    get currentStepIndex(): number {
        return this.enabledSteps.findIndex((s) => s.active);
    }

    get isFirstStep(): boolean {
        return this.currentStepIndex === 0;
    }

    get isLastStep(): boolean {
        return this.currentStepIndex + 1 === this.enabledSteps.length;
    }

    getStepById(id: string): EdeStepComponent {
        return this.steps.find((s) => s.id === id);
    }

    addStep(step: EdeStepComponent): void {
        const parent = this.renderer.parentNode(step.elementRef.nativeElement);
        if (!!parent) {
            const children = Array.from(parent.children);
            const idx = children.findIndex((current) => current === step.elementRef.nativeElement);
            this.steps.splice(idx, 0, step);
        } else {
            this.steps.push(step);
        }
    }

    removeStep(step: EdeStepComponent): void {
        const index = this.steps.indexOf(step);
        if (index === -1) {
            return;
        }
        this.steps.splice(index, 1);
        if (step.elementRef.nativeElement.parentNode) {
            this.renderer.removeChild(step.elementRef.nativeElement.parentNode, step.elementRef.nativeElement);
        }
    }
}
