<section class="card workflow-step__card">
    <header class="workflow-step__card__header" *ngIf="!hideFormHeader">
        <div class="u-fillRemaining">
            <h6 class="t-bold">Attestations</h6>
        </div>
    </header>

    <form class="workflow-step__card__body" #attestationForm="ngForm">
        <section class="attestation" *ngFor="let attestation of attestations; let i = index">
            <header class="attestation__header">
                <h6 class="t-bold">{{ attestation.header }}</h6>
            </header>

            <section class="attestation__body">
                <div *ngFor="let section of attestation.sections">
                    <p class="t-data t-bold" [innerHTML]="section.header"></p>
                    <ng-container *ngFor="let paragraph of section.paragraphs">
                        <p class="details" [innerHTML]="paragraph"></p>
                    </ng-container>
                </div>
            </section>

            <footer class="attestation__footer" *ngIf="!!attestation.confirmationText">
                <label class="form-control--checkbox__wrapper">
                    <input
                        type="checkbox"
                        class="form-control form-control--checkbox"
                        required
                        ngModel
                        [name]="'attestation' + i"
                        #checkBox
                        (change)="updateValidity(attestationForm)"
                        *ngIf="!checkBox?.checked; else checkboxIcon"
                    />

                    <ng-template #checkboxIcon>
                        <i class="icon icon--medium icon--primary">checkbox_active</i>
                    </ng-template>

                    <p class="t-data t-bold" [innerHTML]="attestation.confirmationText"></p>
                </label>
            </footer>
        </section>
    </form>
</section>
