import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { Plan } from '@zipari/shared-sbp-models';
import { ViewDetailConfig } from '../plan-detail-config.model';
import { planCompareActivatingMessage } from '../plan-card/plan-card.constants';

@Component({
    selector: 'plan-card-footer',
    templateUrl: './plan-card-footer.component.html',
    styleUrls: ['./plan-card-footer.component.scss'],
    animations: [],
})
export class PlanCardFooterComponent {
    @Input() showActions: boolean = true;
    @Input() showDetails: boolean = false;
    @Input() hideSelection: boolean = false;
    @Input() canCompare: boolean = false;
    @Input() isCompareLocked: boolean = false;
    @Input() isCompareDisabled: boolean = false;
    @Input() isSelectDisabled: boolean = false;
    @Input() compareTitle: string = '';
    @Input() viewDetailConfig: ViewDetailConfig;
    @Input() plan: Plan;
    @Input() customButton: ButtonConfig;
    @Input() selectButtonConfig: ButtonConfig;
    @Input() selectedButtonConfig: ButtonConfig;

    @Output() toggleDetails = new EventEmitter();
    @Output() toggleComparison = new EventEmitter();
    @Output() customButtonClicked = new EventEmitter();
    @Output() toggleSelection = new EventEmitter();

    constructor() {}

    public getToggleDetailButtonContent(): string {
        return this.showDetails ? this.viewDetailConfig.hideText : this.viewDetailConfig.viewText;
    }

    public isCompareDisable(): boolean {
        return this.isCompareLocked || (this.isCompareDisabled && !this.plan.comparing);
    }

    public isSelectDisable(): boolean {
        return !this.plan.selected ? this.isSelectDisabled : false;
    }

    public getSelectButtonConfig(): ButtonConfig {
        return this.plan.selected ? this.selectedButtonConfig : this.selectButtonConfig;
    }

    public getCompareActivatingMessage(): string | null {
        return this.plan.comparing ? planCompareActivatingMessage : null;
    }
}
