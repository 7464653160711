import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { getNewUrlWithMergedQueryParams, getValue } from '@zipari/web-utils';
import { ConfigService } from '@zipari/shared-sbp-services';
import { BehaviorSubject, Subscription } from 'rxjs';

export class BrokerAssistanceConfig {
    retrieveDetails: boolean = true;

    constructor(options = {}) {
        Object.assign(this, options || {});
    }
}

@Injectable({
    providedIn: 'root',
})
export class BrokerAssistanceService {
    public config: BrokerAssistanceConfig;
    private broker;
    public assistingBroker$ = new BehaviorSubject(null);

    public brokerSearchSub: Subscription;

    public set assistingBroker(broker) {
        this.assistingBroker$.next(broker);
        this.broker = broker;
    }

    public get assistingBroker() {
        return this.broker;
    }

    constructor(public router: Router, public urlSerializer: UrlSerializer, private http: HttpClient, public configService: ConfigService) {
        const globalConfig: any = this.configService.getPageConfig('global');
        this.config = new BrokerAssistanceConfig(globalConfig?.brokerAssistance);
    }

    checkForBrokerAssistance(route: ActivatedRoute, workflowValues) {
        let brokerId = getValue(route, 'snapshot.queryParams.broker_id');
        if (brokerId) {
            this.retrieveBrokerByBrokerId(brokerId, 'query');
        }

        if (!brokerId && workflowValues.current_broker) {
            if (getValue(workflowValues, 'current_broker.broker_internal_id')) {
                this.retrieveBrokerByBrokerId(getValue(workflowValues, 'current_broker.broker_internal_id'), 'query');
            } else if (getValue(workflowValues, 'current_broker.broker_id')) {
                this.retrieveBrokerByBrokerId(getValue(workflowValues, 'current_broker.broker_id'), 'user');
            }
        }
    }

    retrieveBrokerByBrokerId(brokerId: string, source: string) {
        if (this.config.retrieveDetails) {
            const endpoint = `api/enrollment/brokers/search/?search=${encodeURI(brokerId)}`;

            this.brokerSearchSub = this.http.get(endpoint).subscribe((response: any) => {
                if (response && response.results.length >= 1) {
                    const broker: any = response.results[0];
                    broker['source'] = source;

                    // set up to handle funky BSC broker id
                    // we should be doing this anyway to preserve the query param
                    if (broker.broker_id) {
                        broker.broker_id = encodeURI(broker.broker_id);
                    }

                    this.assistingBroker = broker;
                }
            });
        } else this.assistingBroker = { broker_id: brokerId };
    }

    formatBrokerUrl(shoppingUrl: string, broker_id: string, extraQueryParams?: any): string {
        const queryParams = {
            broker_id,
        };

        if (extraQueryParams) {
            Object.keys(extraQueryParams).forEach((key) => {
                queryParams[key] = extraQueryParams[key];
            });
        }

        return getNewUrlWithMergedQueryParams(this.urlSerializer, shoppingUrl, queryParams);
    }
}
