<div class="card pcp__card">
    <header class="pcp__card__header">
        <div>
            <h6 class="t-bold">{{ pcp | fullName : true }}</h6>

            <p class="t-caption" *ngIf="pcp.name">PCP for {{ pcp.name }}</p>
        </div>
    </header>

    <section class="pcp__card__prices">
        <model-attribute *ngFor="let config of attributeConfigs" [config]="config" [format]="true" [context]="pcp"> </model-attribute>
    </section>
</div>
