// Used to override the default configs passed to the child comparison-card component

import { MessageBannerConfig, BannerTypes } from '@zipari/shared-ds-util-messages';

// This obj model will be found within the Array<PlanTypeCardOverrideConfig> configs.planTypeCardsOverrides
export interface PlanTypeCardOverrideConfig {
    planType: string;
    [prop: string]: any;
}

export interface PlanTypePlans {
    [planType: string]: PlanTypePlanContext;
}

export class PlanTypePlanContext {
    chooseDiffPlan?: boolean;
    crosswalk_type?: string;
    isDiscontinued: boolean;
    isIneligible: boolean;
    plans: Array<any>;

    constructor() {
        this.isDiscontinued = false;
        this.isIneligible = false;
        this.plans = [];
    }
}

export enum YoyHeaderTextStatuses {
    addressChanged = 'address-changed',
    editedMembers = 'edited-members',
    exact = 'exact',
    ineligible = 'ineligible',
    noMatch = 'no-match',
    similar = 'similar',
}

export const infoBannerConfig: MessageBannerConfig = {
    type: BannerTypes.information,
    icon: 'info',
    message:
        'Your new premium, deductible, and out of pocket maximum may seem significantly different. This is due to the change in your household size.',
};

export const yoyMedicalPlanType = 'medical';

export const yoyWfStepChooseDiffPlansProp = 'chooseDiffPlanPlanTypes';
