import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@zipari/design-system';
import { SessionExpiredComponent } from './session-expired.component';

@NgModule({
    declarations: [SessionExpiredComponent],
    imports: [CommonModule, ButtonModule],
    exports: [SessionExpiredComponent],
})
export class SessionExpiredModule {}
