import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule } from '@zipari/design-system';

import { FooterComponent } from './footer.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, SharedModule, ModelAttributeModule],
    exports: [FooterComponent],
})
export class FooterModule {}
