import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    AccordionModule,
    ButtonModule,
    ErrorEmptyModule,
    FormControlModule,
    IconModule,
    ModelAttributeModule,
    PaginatorModule,
    ModalModule,
    ZipBusyModule,
} from '@zipari/design-system';
import { FooterModule, FormGroupModule, MapModule } from '@zipari/shared-sbp-modules';

import { ProviderCardComponent } from './provider-search/provider-card/provider-card.component';
import { ProviderProfileComponent } from './provider-search/provider-profile/provider-profile.component';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ProviderSearchModalsComponent } from './provider-search/provider-search-modals/provider-search-modals.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [
        ProviderCardComponent,
        ProviderProfileComponent,
        ProviderSearchComponent,
        SearchBarComponent,
        ProviderSearchModalsComponent,
    ],
    exports: [ProviderSearchComponent, SearchBarComponent],
    imports: [
        AccordionModule,
        ButtonModule,
        CommonModule,
        FormControlModule,
        FormGroupModule,
        IconModule,
        ModelAttributeModule,
        MapModule,
        SharedModule,
        PaginatorModule,
        ZipBusyModule,
        FooterModule,
        ErrorEmptyModule,
        ModalModule,
    ],
    providers: [],
})
export class SearchModule {}
