<aside class="layout--stepper__aside">
    <section class="layout--stepper__group__wrapper" *ngFor="let group of menu; let i = index">
        <div class="layout--stepper__group" (click)="scrollToSection(group)" [ngClass]="{ 'is-active': group.prop === activeItem }">
            <div class="layout--stepper__group__indicator">
                <p class="t-data t-bold">{{ i + 1 }}</p>
            </div>

            <p class="t-data t-caps">{{ group.header }}</p>
        </div>
    </section>
</aside>
