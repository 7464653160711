import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { FormControlService, FormControlValidatorsService } from '@zipari/design-system';
import { cloneObject } from '@zipari/web-utils';

@Component({
    selector: 'form-array',
    templateUrl: './form-array.component.html',
    styleUrls: ['./form-array.component.scss'],
})
export class FormArrayComponent implements OnInit {
    @Input() group;
    @Input() formArrayConfig;
    @Input() direction;

    formArrayConfigs = [];
    relevantValidators;

    constructor(public formControlService: FormControlService, public validatorService: FormControlValidatorsService) {}

    ngOnInit() {
        this.relevantValidators = this.validatorService.getFormControlValidators(this.formArrayConfig.config);
        this.group.addControl(
            this.formArrayConfig.prop,
            new FormArray([new FormControl(null, { validators: this.relevantValidators || {} })])
        );
        this.addItem();
    }

    addItem(index = null) {
        const clonedConfig = cloneObject(this.formArrayConfig.config);
        const reactiveControl = new FormControl(null, { validators: this.relevantValidators || {} });

        if (index) {
            this.group.controls[this.formArrayConfig.prop].insert(index, reactiveControl);
            this.formArrayConfigs.splice(index, 0, clonedConfig);
        } else {
            this.group.controls[this.formArrayConfig.prop].push(reactiveControl);
            this.formArrayConfigs.push(clonedConfig);
        }
    }

    removeItem(ind) {
        this.group.controls[this.formArrayConfig.prop].removeAt(ind);
        this.formArrayConfigs.splice(ind, 1);
    }
}
