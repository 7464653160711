import { getValue, isEmptyObj } from '@zipari/web-utils';
import { applicationsURLS } from '@zipari/shared-sbp-constants';
import { StateExchangeConfig } from '@zipari/shared-sbp-enrollment';
import { Plan } from '@zipari/shared-sbp-models';

export const checkForOnExchange = (workflow, config, authService, activeStepType) => {
    if (workflow) {
        let stepToCheckForOnExchange = config && config.onExchangeCheckStep ? config.onExchangeCheckStep : 'personal_info';

        if (stepToCheckForOnExchange === 'login_register' && authService.loggedInUser && authService.loggedInUser.app_user_data) {
            let activeStepIndex = workflow.steps.findIndex((step) => step.schema.type === 'login_register');
            while (workflow.steps[activeStepIndex].state === 'skipped') {
                activeStepIndex++;
            }
            stepToCheckForOnExchange = workflow.steps[activeStepIndex].schema.type;
        }

        const values = workflow.values || {};

        let medicalPlan: Plan;

        if (values && values.plans) {
            medicalPlan = values.plans.medical;
        } else if (values && values.quoted) {
            medicalPlan = values.quoted.find((plan) => plan.plan_type === 'medical' && plan.plan_variation !== '00');
        }

        return activeStepType === stepToCheckForOnExchange && medicalPlan && parseInt(medicalPlan.plan_variation) > 0;
    }

    return null;
};

/*
 * this method checks if there are any state exchanges we should redirect the user to
 * we have defined NJ and CO in the code, but you can also pass a config at the root level of `individual.enrollment`
 * you'll need to add a `stateExchangeConfig` object and structure it similarly to the structure below.
 * this will ultimately endup being the ModalConfig for the state exchange redirect Modal
 */
export const checkStatesWithStateExchanges = (workflow, config?) => {
    const stateExchangeConfig: any = !isEmptyObj(config) && !!config.stateExchangeConfig ? config.stateExchangeConfig : {};
    let stateExchanges: { [stateCode: string]: StateExchangeConfig } = {
        NJ: {
            url: 'https://enroll.getcovered.nj.gov/hix/account/user/login',
            body: 'You are being redirected to the New Jersey State Exchange to complete this application.',
            header: 'Complete application on New Jersey State Exchange',
        },
        CO: {
            url: 'https://prd.connectforhealthco.com/individual',
            body: 'You are now leaving our site. You will be redirected to Connect for Health Colorado to complete your application.',
        },
    };

    if (!isEmptyObj(stateExchangeConfig)) {
        stateExchanges = stateExchangeConfig;
    }

    const state: string | null = getValue(workflow, 'values.permanent_address.state');
    if (!state) {
        return null;
    }

    return stateExchanges[state] ? stateExchanges[state] : null;
};

/*
 * when an on-exchange plan has been selected, we'll do one of three things
 * 1. show the redirect modal and send them to the state exchange (this assmumes you've configured the stateExchangeConfig)
 * 2. handle Direct Enrollment if it's enabled
 * 3. start the EDE flow (that's what `handleEDERedirect()` will do)
 */
export const handleOnExchange = (
    workflow,
    router,
    config,
    appRoute,
    edeSes,
    callback,
    isDe: boolean,
    authService,
    activeStepType,
    http
) => {
    const onExchangeCheck: boolean = checkForOnExchange(workflow, config, authService, activeStepType);

    if (onExchangeCheck) {
        const stateExchange: StateExchangeConfig | null = checkStatesWithStateExchanges(workflow, config);

        if (stateExchange) {
            callback({ stateExchange });
        } else if (edeSes) {
            if (isDe) {
                callback({ de: isDe });
            } else {
                handleEDERedirect(workflow, router, appRoute, () => edeSes.resetEDESesVals(), http, config);
            }
        }
    }
};

export const handleEDERedirect = (workflow, router, app, callback, http, config): void => {
    router
        .navigate([app, 'ede', 'application'], {
            queryParams: {
                application_id: workflow.data.application_id,
            },
        })
        .then(() => {
            const endpoint = config.getEndpoint(applicationsURLS);
            callback();
            http.put(`${endpoint}${workflow.data.application_id}/`, { ede: true }).subscribe();
        });
};
