export interface IDProofingPayload {
    Person: Person;
    CurrentAddress: CurrentAddress;
    ContactInformation: ContactInformation;
    LevelOfProofingCode: string;
}
interface Person {
    PersonBirthDate: PersonBirthDate;
    PersonName: PersonName;
    PersonSSNIdentification: string;
    PersonAugmentation: PersonAugmentation;
}
interface PersonBirthDate {
    Date: string;
}
interface PersonName {
    PersonGivenName: string;
    PersonMiddleName: string;
    PersonSurName: string;
    PersonNameSuffixText: string;
}
interface CurrentAddress {
    LocationAddress: LocationAddress;
}
interface LocationAddress {
    StructuredAddress: StructuredAddress;
}
interface StructuredAddress {
    LocationStreet: LocationStreet;
    LocationCityName: string;
    LocationStateUSPostalServiceCode: string;
    LocationPostalCode: string;
    LocationPostalExtensionCode: string;
}
interface LocationStreet {
    StreetName: string;
}
interface ContactInformation {
    ContactTelephoneNumber: ContactTelephoneNumber;
}
interface ContactTelephoneNumber {
    FullTelephoneNumber: string;
}

interface PersonAugmentation {
    PersonPreferredLanguage: PersonPreferredLanguage;
}

interface PersonPreferredLanguage {
    LanguageCode: string;
}
