import { Component, OnInit } from '@angular/core';
import { cloneObject, getValue } from '@zipari/web-utils';
import { AuthService, ConfigService, PrintService } from '@zipari/shared-sbp-services';
import { tableConfig, documentEndpoint, ResourceCenterDocumentsTableCellClickContext } from './resource-center.constant';

@Component({
    selector: 'resource-center',
    templateUrl: './resource-center.component.html',
    styleUrls: ['./resource-center.component.scss'],
})
export class ResourceCenterComponent implements OnInit {
    config;
    documentsConfig;
    documentEndpoint;
    webuserId;
    showFolders: boolean = true;
    footerConfig;

    constructor(private configService: ConfigService, private authService: AuthService, private printService: PrintService) {}

    ngOnInit() {
        this.config = this.configService.getPageConfig('resource-center')
            ? this.configService.getPageConfig('resource-center')
            : tableConfig;
        this.documentsConfig = cloneObject(this.config.documents);
        this.documentEndpoint = this.config.documents.endpoint ? this.config.documents.endpoint.replace(/\/$/, '') : documentEndpoint;
        this.webuserId =
            this.authService.loggedInUser &&
            (this.authService.loggedInUser.app_user_data.broker_id ||
                this.authService.loggedInUser.app_user_data.member_id ||
                this.authService.loggedInUser.app_user_data.id ||
                null);
        this.footerConfig = this.configService.getPageConfig('footer');
    }

    displayFolderContents(data): void {
        if (this.authService.loggedInUser) {
            // if the user is logged in, display the documents for this user
            const contents = cloneObject(getValue(this.config.documents, data.row.folder));
            if (contents) {
                this.documentsConfig = contents;
            } else {
                this.documentsConfig = cloneObject(this.config.documents);
                this.documentsConfig.title = data.row.folder;
                if (data.row.endpoint) {
                    // use the endpoint that is given in the configs
                    this.documentsConfig.endpoint = data.row.endpoint;
                } else {
                    this.documentsConfig.endpoint = `${this.documentEndpoint}/?webuser_id=${this.webuserId}&folder=${data.row.folder}`;
                }
            }
        } else {
            // if the user is not logged in, don't display anything
            this.config.documents.endpoint = null;
        }
        this.toggleDisplay();
    }

    toggleDisplay(): void {
        this.showFolders = !this.showFolders;
    }

    /**
     * Download a file for the user or open a file in a new tab for display for the user
     * This function is called when the user clicks an action in a documents list table
     */
    getDocument(data: any): void {
        const context: ResourceCenterDocumentsTableCellClickContext = data.context || data;
        const url: string = this.getUrl(context);

        switch (data.action) {
            case 'download':
                this.printService.download_file(url, context.row.file_name);
                break;
            default:
                window.open(url, '_blank');
        }
    }

    /**
     * returns the URL needed to complete the action required for the user's click action for the function this.getDocument()
     */
    private getUrl(context: ResourceCenterDocumentsTableCellClickContext): string {
        if (context.row) {
            const documentsEndpoint = `${window.location.origin}/${this.documentEndpoint}/${context.row.id}`;
            return context.col?.useDocumentsEndpoint ? documentsEndpoint : context.row.link || context.row.url || documentsEndpoint;
        }
    }
}
