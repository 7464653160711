<header
    class="tablet-toolbar"
    *ngIf="
        navigationService?.config?.icons && navigationService?.config?.icons?.tablet && navigationService?.config?.icons?.tablet?.length > 0
    "
>
    <div class="container">
        <div class="u-flex u-alignCenter u-justifyBetween">
            <div class="tablet-toolbar__contact">
                <p class="t-data">Contact Support</p>
            </div>

            <section class="nav__icons">
                <a
                    class="nav__link"
                    [ngClass]="{ 'is-active': navigationService.isActiveRoute(tool.route) }"
                    [routerLink]="tool.route"
                    (click)="navigationService.selectNavLink($event, tool)"
                    *ngFor="let tool of navigationService.config.icons.tablet"
                >
                    <i class="icon icon--medium">{{ tool.icon }}</i>
                </a>
            </section>
        </div>
    </div>
</header>
