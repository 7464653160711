<div *ngIf="case !== enrollmentInfoCases.preEnrollment">
    <card *ngIf="case === enrollmentInfoCases.noEnrollment" [config]="noHeaderCardConfig">
        <div body>
            <div class="container">
                <h6 class="center--text" [innerHTML]="cardText.noEnrollment.heading"></h6>
            </div>
        </div>
        <div footer>
            <div class="container button--container">
                <zip-button [config]="shopForPlansButtonConfig" (zipButtonClicked)="shopForPlans()"> </zip-button>
            </div>
        </div>
    </card>
    <card *ngIf="case === enrollmentInfoCases.postEnrollmentNoSVI" [config]="noHeaderCardConfig">
        <div body>
            <div class="text">
                <p class="t-data" [innerHTML]="cardText.shared.submitted"></p>
            </div>
            <br />
            <h6 class="t-bold" [innerHTML]="cardText.shared.whatsNext"></h6>
            <br />
            <ol>
                <ng-container *ngFor="let step of cardText.postEnrollmentNoSVI.nextSteps">
                    <li class="t-data" [innerHTML]="step"></li>
                    <br />
                </ng-container>
            </ol>
            <div *ngIf="extended">
                <zip-button [config]="goToHealthCareDotGovButton" (zipButtonClicked)="goToHealthCareDotGov()"> </zip-button>
                <br />
                <p class="t-data" [innerHTML]="cardText.shared.reminder"></p>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.payPremium"></h6>
                <br />
                <ol>
                    <ng-container *ngFor="let step of cardText.shared.payPremiumSteps">
                        <li class="t-data" [innerHTML]="step"></li>
                        <br />
                    </ng-container>
                </ol>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.hearFromHealthCare"></h6>
                <br />
                <ul>
                    <ng-container *ngFor="let step of cardText.shared.hearFromHealthCareSteps">
                        <li class="t-data" [innerHTML]="step"></li>
                        <br />
                    </ng-container>
                </ul>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.updateMyInfo"></h6>
                <br />
                <p class="t-data" [innerHTML]="cardText.shared.loginToHealthCare"></p>
            </div>
        </div>
        <div footer *ngIf="!extended" class="u-flex">
            <zip-button class="button--space" [config]="goToHealthCareDotGovButton" (zipButtonClicked)="goToHealthCareDotGov()">
            </zip-button>
            <zip-button [config]="learnMoreButton" (zipButtonClicked)="learnMore()"> </zip-button>
        </div>
    </card>
    <card *ngIf="case === enrollmentInfoCases.postEnrollmentSVI" [config]="noHeaderCardConfig">
        <div body>
            <div class="text">
                <p class="t-data" [innerHTML]="cardText.shared.submitted"></p>
            </div>
            <br />
            <h6 class="t-bold" [innerHTML]="cardText.shared.whatsNext"></h6>
            <br />
            <ol>
                <ng-container *ngFor="let step of cardText.postEnrollmentSVI.nextSteps">
                    <li class="t-data" [innerHTML]="step"></li>
                    <br />
                </ng-container>
            </ol>
            <div *ngIf="extended">
                <zip-button [config]="goToHealthCareDotGovButton" (zipButtonClicked)="goToHealthCareDotGov()"> </zip-button>
                <br />
                <p class="t-data" [innerHTML]="cardText.shared.reminder"></p>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.payPremium"></h6>
                <br />
                <ol>
                    <ng-container *ngFor="let step of cardText.shared.payPremiumSteps">
                        <li class="t-data" [innerHTML]="step"></li>
                        <br />
                    </ng-container>
                </ol>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.hearFromHealthCare"></h6>
                <br />
                <ul>
                    <ng-container *ngFor="let step of cardText.shared.hearFromHealthCareSteps">
                        <li class="t-data" [innerHTML]="step"></li>
                        <br />
                    </ng-container>
                </ul>
                <br />
                <h6 class="t-bold" [innerHTML]="cardText.shared.updateMyInfo"></h6>
                <br />
                <p class="t-data" [innerHTML]="cardText.shared.loginToHealthCare"></p>
            </div>
        </div>
        <div footer *ngIf="!extended" class="u-flex">
            <zip-button class="button--space" [config]="goToHealthCareDotGovButton" (zipButtonClicked)="goToHealthCareDotGov()">
            </zip-button>
            <zip-button [config]="learnMoreButton" (zipButtonClicked)="learnMore()"> </zip-button>
        </div>
    </card>
    <card *ngIf="case === enrollmentInfoCases.confirmedEnrollment" [config]="noHeaderCardConfig">
        <div body>
            <div class="text">
                <p class="t-data" [innerHTML]="cardText.shared.submitted"></p>
            </div>
            <br />
            <h6 class="t-bold" [innerHTML]="cardText.shared.whatsNext"></h6>
            <br />
            <ul>
                <li class="t-data" [innerHTML]="cardText.confirmedEnrollment.active"></li>
                <br />
            </ul>
            <div *ngIf="extended">
                <h6 class="t-bold" [innerHTML]="cardText.shared.updateMyInfo"></h6>
                <br />
                <p class="t-data" [innerHTML]="cardText.shared.loginToHealthCare"></p>
                <br />
            </div>
        </div>
        <div footer class="u-flex">
            <zip-button *ngIf="!extended" [config]="learnMoreButton" (zipButtonClicked)="learnMore()"> </zip-button>
            <zip-button *ngIf="extended" [config]="goToHealthCareDotGovButton" (zipButtonClicked)="goToHealthCareDotGov()"> </zip-button>
        </div>
    </card>
    <br />
    <br />
</div>
