import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule, ButtonModule, FormattingService, FormControlModule, ModelAttributeModule } from '@zipari/design-system';
import { SharedModule } from '../shared.module';

import { AttributeGridComponent } from './attribute-grid.component';

@NgModule({
    declarations: [AttributeGridComponent],
    imports: [CommonModule, SharedModule, ButtonModule, FormControlModule, ModelAttributeModule, AccordionModule],
    providers: [FormattingService],
    exports: [AttributeGridComponent],
})
export class AttributeGridModule {}
