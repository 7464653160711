import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, ModelAttributeModule } from '@zipari/design-system';

import { PcpCardComponent } from './pcp-card/pcp-card.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [PcpCardComponent],
    imports: [CommonModule, FormGroupModule, ButtonModule, SharedModule, ModelAttributeModule],
    providers: [],
    exports: [PcpCardComponent],
})
export class PcpModule {}
