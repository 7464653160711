import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageBannerConfig, BannerTypes } from '@zipari/shared-ds-util-messages';
import { ModalConfig, ModalHeader, ModalTypes } from '@zipari/shared-ds-util-modal';

@Component({
    selector: 'calculate-premiums-card',
    templateUrl: './calculate-premiums-card.component.html',
    styleUrls: ['./calculate-premiums-card.component.scss'],
})
export class CalculatePremiumsCardComponent implements OnInit {
    @Input() config: any;
    @Input() state: any;
    @Input() formGroup: FormGroup;
    @Output() onSubmit = new EventEmitter();

    messageBannerConfig: MessageBannerConfig = {
        message: "You've successfully updated your Medicare Supplement Information",
        icon: 'check',
        type: BannerTypes.success,
        actionIcon: 'close',
    };

    learnMoreModalConfig = new ModalConfig({
        type: ModalTypes.popup,
        backdrop: true,
        clickOutside: false,
        header: new ModalHeader({
            title: 'What are Medicare Supplemental Plans?',
            showClose: false,
        }),
    });

    showModal: boolean = false;

    constructor() {}

    ngOnInit(): void {
        this.messageBannerConfig = this.config.messageBannerConfig || this.messageBannerConfig;
        this.learnMoreModalConfig.header.title = this.config.learnMoreConfig.modalHeader;
    }

    public get displayMessageBanner(): boolean {
        return this.config.showMessageBanner && this.state.calculated && this.state.showBanner;
    }

    public toggleModal() {
        this.showModal = !this.showModal;
    }
}
