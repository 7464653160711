import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroupModule } from '@zipari/design-system';

import { SignatureCardComponent } from './signature-card.component';

@NgModule({
    declarations: [SignatureCardComponent],
    exports: [SignatureCardComponent],
    imports: [CommonModule, ReactiveFormsModule, FormGroupModule],
})
export class SignatureCardModule {}
