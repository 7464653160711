import { CurrentBroker } from '@zipari/shared-sbp-enrollment';
import { WorkflowStep } from '@zipari/shared-sbp-models';

export enum WorkflowStates {
    COMPLETE = 'complete',
    IN_PROGRESS = 'in_progress',
    NOT_STARTED = 'not_started',
    SKIPPED = 'skipped',
}

export enum WorkflowType {
    bp_individual_enrollment = 'bp_individual_enrollment',
    individual = 'individual',
    shp_individual_quote = 'shp_individual_quote',
    bp_individual_quote = 'bp_individual_quote',
}

export class Workflow<T> {
    children: Array<any>;
    current_step: WorkflowStep;
    current_step_number: number;
    data: any;
    id: number;
    parent: number;
    relations: any;
    schema: any;
    state: WorkflowStates;
    steps: Array<WorkflowStep>;
    type: string;
    values: T;
    web_session_id: string;
    webuser_id: number;
    created_at?: string;
    helpers: {
        checkForValueInStepAndPatch: Function;
        checkIfStepTypeExists: Function;
        getStepNumberByStepType: Function;
    };
    current_broker?: CurrentBroker;
    client?: any;

    constructor(init?) {
        Object.assign(this, init);
    }
}
