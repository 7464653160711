import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { AddressService } from '@zipari/shared-sbp-services';
import { map, takeUntil } from 'rxjs/operators';

import { BaseAddressDropdownComponent } from '../base-address-dropdown.component';

@Component({
    selector: 'cities',
    templateUrl: './cities.component.html',
    styleUrls: ['./cities.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CitiesComponent),
            multi: true,
        },
    ],
})
export class CitiesComponent extends BaseAddressDropdownComponent implements OnInit, OnDestroy {
    @Input('group') _addressFormGroup;
    @Input('config') _config;

    constructor(formControlService: FormControlService, addressService: AddressService) {
        super(addressService, formControlService);
    }

    ngOnInit() {
        this.initCityOption();
        this.baseInit(this._addressFormGroup, this._config, 'city');
    }

    /*
        If persisted value - create dropdown option for display
    */
    initCityOption() {
        const value = getValue(this._addressFormGroup.value, 'city');
        if (value) {
            this._config.options = [
                {
                    label: value,
                    value,
                },
            ];
        }
    }

    writeValue(value) {
        if (value) {
            const cityCtrl = this.formGroup.get(this.config.prop);
            cityCtrl && cityCtrl.setValue(value);
        }
    }

    /*
        value object to string for form
    */
    registerOnChange(fn: any) {
        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.destroy),
                map(({ city }) => city)
            )
            .subscribe(fn);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
