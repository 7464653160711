export class RolePermissions {
    prospectDetailsEdit?: boolean;
    prospectDetailsSave?: boolean;
    prospectDetailsViewEnrollment?: boolean;
    prospectDetailsNewEnrollment?: boolean;
    groupInfoEdit?: boolean;
    planSelectionAttestationEdit?: boolean;
    planSelectionReviewEdit?: boolean;
    planSelectionReviewDelete?: boolean;
    planSelectionSelectPlan?: boolean;
    censusDownloadTemplate?: boolean;
    censusUploadCensus?: boolean;
    censusAddEmployee?: boolean;
    censusEditEmployee?: boolean;
    censusDeleteEmployee?: boolean;
    documentsUpload?: boolean;
    documentsDelete?: boolean;
    paymentEdit?: boolean;
    reviewEdit?: boolean;
    reviewApprove?: boolean;
}
