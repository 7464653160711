import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { PlanUtilService, ShoppingCart, validShoppingCartEvents, PlanTypeDisplay } from '@zipari/shared-sbp-services';
import { isArray, mapValues } from 'lodash';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
    @Input() workflow: any;
    @Input() hidePrice: boolean;
    @Input() totalPremiumLabel = 'Total Premium: ';

    shoppingCart: ShoppingCart;
    shoppingCartSubscription: Subscription;

    constructor(private plansUtilService: PlanUtilService, private formattingService: FormattingService) {}

    get plans(): Array<PlanTypeDisplay> {
        return this.shoppingCart ? this.shoppingCart.planTypeDisplayArr : [];
    }

    get price(): string {
        return this.shoppingCart.displayTotalPrice;
    }

    ngOnDestroy() {
        if (this.shoppingCartSubscription) {
            this.plansUtilService.clearSelectedPlans();
            this.shoppingCartSubscription.unsubscribe();
        }
    }

    removePlanFromShoppingCart(plan_type, workflow_type: string = 'enrollment'): void {
        const plans = mapValues(this.shoppingCart.plansByType, (plan) => {
            if (isArray(plan) && workflow_type !== 'quotes') {
                return plan[0];
            }
            return plan;
        });

        const format = formatTypes.CURRENCY;
        const subsidy = null;
        const formatPriceFn = (price: number): string => this.formattingService.restructureValueBasedOnFormat(price.toString(), { format });

        this.shoppingCart = this.plansUtilService.retrieveShoppingCart(plans, { formatPriceFn }, subsidy);
    }

    removeAllPlans(): void {
        this.shoppingCart = null;
    }

    ngOnInit() {
        this.shoppingCart = this.plansUtilService.shoppingCartWithDependencies(this.workflow, this.formattingService);

        this.shoppingCartSubscription = this.plansUtilService.shoppingCartEvent$.pipe(skip(1)).subscribe((event) => {
            const shoppingCartEvent = event?.event;
            switch (shoppingCartEvent) {
                case validShoppingCartEvents.removePlan:
                    event.payload && this.removePlanFromShoppingCart(event.payload, event.type);
                    break;
                case validShoppingCartEvents.removeAllPlans:
                    this.removeAllPlans();
                    break;
                case validShoppingCartEvents.addPlan:
                    this.shoppingCart = this.plansUtilService.shoppingCartWithDependencies(this.workflow, this.formattingService);
                    break;
            }
        });
    }
}
