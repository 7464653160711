<div class="generic--error container">
    <img [src]="config?.img" alt="Error" *ngIf="config?.img" />
    <h4>{{ config?.header?.title }}</h4>
    <p>{{ config?.header?.subtitle }}</p>

    <div class="generic--error__buttons u-flex u-alignCenter u-justifyBetween" *ngIf="config?.buttons">
        <zip-button
            *ngFor="let button of config?.buttons"
            [level]="button.level"
            (zipButtonClicked)="onAction.emit(button.prop)"
            [content]="button.content"
        >
        </zip-button>
    </div>
</div>

<div class="generic--error__footer" *ngIf="config?.footer">
    <div class="container">
        <div class="u-flex u-justifyEnd">
            <zip-button
                *ngFor="let button of config?.footer.buttons"
                [level]="button.level"
                (zipButtonClicked)="onAction.emit(button.prop)"
                [content]="button.content"
            >
            </zip-button>
        </div>
    </div>
</div>
