import { ButtonModule } from '@zipari/design-system';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page.component';
import { GenericErrorModule } from '../generic-error/generic-error.module';

@NgModule({
    declarations: [ErrorPageComponent],
    imports: [CommonModule, ButtonModule, GenericErrorModule],
    exports: [ErrorPageComponent],
})
export class ErrorPageModule {}
