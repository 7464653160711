import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/** Parseable as JavaScript */
export type JavaScript = string;

/** A string of valid JavaScript within HTML script tags. */
export type RawHtmlScriptString = string;

/** Generally we want to separate concerns. You'll typically create a script element using this service and then render it with the
 * render script function. We should keep code specific to certain scripts out of this service, agnostic. */
@Injectable({ providedIn: 'root' })
export class ScriptRenderService {
    renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, public rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    /** Used to multiplex the actual script inject functions. Because not all scripts are built the same way we need to determine which
     * function to use here with the the script type. */
    renderScript(htmlScriptElement: HTMLScriptElement): void {
        if (this.document?.head) this.renderer.removeChild(this.document.head, htmlScriptElement);
        this.renderer.appendChild(this.document.head, htmlScriptElement);
    }

    /** Creates an HTML script element. Does not itself inject the element into the document.
     * @param innerHtml The JavaScript script that we add as the inner HTML so that the browser will execute it. */
    scriptElement(innerHtml?: JavaScript): HTMLScriptElement {
        const scriptElement = this.renderer.createElement('script');
        scriptElement.type = 'application/javascript';
        if (innerHtml) scriptElement.innerHTML = innerHtml;

        return scriptElement;
    }
}
