import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ede-header',
    templateUrl: './ede-header.component.html',
    styleUrls: ['./ede-header.component.scss'],
})
export class EdeHeaderComponent {
    @Output() exitClicked: EventEmitter<null> = new EventEmitter();
    @Output() logoClicked: EventEmitter<null> = new EventEmitter();

    @Input() label: string;
}
