import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LeadService {
    constructor(private http: HttpClient) {}

    public getLeadDetails(endpoint: string): Observable<any> {
        return this.http.get(endpoint, {});
    }

    public qualifyLead(endpoint: string, payload: any): Observable<any> {
        return this.http.post(endpoint, payload);
    }

    public patchOpportunity(endpoint: string, payload: any): Observable<any> {
        return this.http.patch(endpoint, payload);
    }
}
