import { YES_NO } from '../allEdeApiModels';
import { FetchEligibilityInsuranceApplicant, FetchEligibilityPerson } from '../fetchEligibility/fetch-eligibility-response.constants';
import {
    UpdateApplicationRequest,
    UpdateApplicationRequest_Member__Structure,
} from '../update application/workflow-ede--update-application-request.constants';

export class GetApplicationLinks {
    rel?: string;
    href?: string;
}

export class GetApplicationMedicaidChipStandard {
    medicaidStandardPercent?: number;
    medicaidStandardBasisType?: string;
    chipStandardPercent?: number;
    chipStandardBasisType?: string;
    parentCaretakerChildList?: Array<string>;
    adultGroupCategoryStatus?: string;
    childCaretakerDeprivedStatus?: string;
    parentCaretakerCategoryStatus?: string;
}

export class GetApplicationEligibleSEP {
    status?: YES_NO;
    statusReason?: string;
    startDate?: string;
    endDate?: string;
    type?: string;
    verificationSourceType?: string;
}

export class GetApplicationResidencyAddress {
    residencyAddressSourceType?: string;
    streetName1: string;
    streetName2: string;
    cityName?: string;
    stateCode?: string;
    plus4Code?: string;
    zipCode?: string;
    countyName?: string;
    countyFipsCode?: string;
}

export class GetApplicationMedicaidHousehold {
    medicaidHouseHoldStatus?: YES_NO;
    medicaidHouseHoldStatusReason?: string;
    medicaidTaxRoleType?: string;
    medicaidHouseholdSize?: number;
    medicaidHouseholdMemberIdentifiers?: Array<string>;
}

export class GetApplicationQHPResidency {
    qhpResidencyStatus?: YES_NO;
    qhpResidencyStatusReason?: string;
    taxHouseholdResidencyAppliesIndicator?: boolean;
}

export class GetApplicationEmployer {
    employer?: string;
    jobIncomeExplanationRequiredIndicator?: boolean;
    jobIncomeExplanationAcceptance?: YES_NO;
}

export class GetApplicationIncome {
    irsDataStatus?: string;
    irsDataStatusReason?: string;
    medicaidIncomeStatus?: YES_NO;
    chipIncomeStatus?: YES_NO;
    medicaidChipIncomeStatusReason?: string;
    medicaidGapFillingIndicator?: boolean;
    annualIncomeExplanationRequired?: boolean;
    attestedMonthlyIndividualIncomeAmt?: number;
    attestedAnnualizedAptcIndividualIncomeAmt?: number;
    employer?: Array<GetApplicationEmployer>;
}

export class GetApplicationMember {
    /** Helper variables used internally */
    formattedCSRVariant?: any;
    eligibilityMessages?: Array<string>;
    demographic?: any;
    maxAPTCAmount?: string;
    maxAPTCAmountSimplified?: string;
    aptcEligible?: boolean;
    fetchEligibilityMember?: {
        person: FetchEligibilityPerson;
        insuranceApplicant: FetchEligibilityInsuranceApplicant;
    };
    memberKey?: string;
    memberInfo?: UpdateApplicationRequest_Member__Structure;
    name?: string;
    docs?: any;
    /** Helper variables used internally */

    personTrackingNumber?: string;
    indianStatus?: YES_NO;
    indianStatusReason?: string;
    nonEscMecStatus?: YES_NO;
    nonEscMecStatusReason?: string;
    ssnStatus?: YES_NO;
    ssnStatusReason?: string;
    citizenshipStatus?: YES_NO;
    citizenshipStatusReason?: string;
    citizenshipDataEventStatus?: string;
    citizenshipDataFoundIndicator?: boolean;
    qhpLawfulPresenceStatus?: YES_NO;
    qhpLawfulPresenceStatusReason?: string;
    fiveYearBarStatus?: YES_NO;
    fiveYearBarStatusReason?: string;
    medicaidLawfulPresenceStatus?: YES_NO;
    medicaidLawfulPresenceStatusReason?: string;
    under100FplWithLawfulPresenceDmiIndicator?: boolean;
    medicaidChipStandard?: GetApplicationMedicaidChipStandard;
    medicaidNonMagiReferralStatus?: YES_NO;
    medicaidNonMagiReferralStatusReason?: string;
    preliminaryMedicaidStatus?: YES_NO;
    preliminaryMedicaidStatusReason?: string;
    preliminaryChipStatus?: YES_NO;
    preliminaryChipStatusReason?: string;
    preliminaryAptcStatus?: string;
    preliminaryAptcStatusReason?: string;
    preliminaryEmergencyMedicaidStatus?: string;
    preliminaryEmergencyMedicaidStatusReason?: string;
    dependentChildCoveredStatus?: string;
    dependentChildCoveredStatusReason?: string;
    chipStateHealthBenefitStatus?: YES_NO;
    chipStateHealthBenefitStatusReason?: string;
    chipWaitingPeriodStatus?: string;
    chipWaitingPeriodStatusReason?: string;
    escMecStatus?: YES_NO;
    escMecStatusReason?: string;
    incarcerationStatus?: YES_NO;
    incarcerationStatusReason?: string;
    medicaidHouseholdComposition?: GetApplicationMedicaidHousehold;
    income?: GetApplicationIncome;
    qhpResidency: GetApplicationQHPResidency;
    medicaidResidencyStatus?: YES_NO;
    medicaidResidencyStatusReason?: string;
    emergencyMedicaidStatus?: string;
    emergencyMedicaidStatusReason?: string;
    residencyAddress?: GetApplicationResidencyAddress;
    qhpStatus?: YES_NO;
    qhpStatusReason?: string;
    aptcStatus?: YES_NO;
    aptcStatusReason?: string;
    secretaryHardshipExemptionIndicator?: boolean;
    csrStatus?: YES_NO;
    csrStatusReason?: string;
    csrVariant?: string;
    medicaidStatus?: YES_NO;
    medicaidStatusReason?: string;
    chipStatus?: YES_NO;
    chipStatusReason?: string;
    unbornChildChipStatus?: YES_NO;
    unbornChildChipStatusReason?: string;
    transferApplicantToStateStatus?: YES_NO;
    transferApplicantToStateStatusReason?: string;
    eligibleSEP: Array<GetApplicationEligibleSEP>;
}

export class GetApplicationBestSEP {
    status?: YES_NO;
    statusReason?: string;
    startDate?: string;
    endDate?: string;
    sepType?: string;
    associatedMemberIdentifiers?: Array<string>;
}

export class GetApplicationEnrollee {
    personTrackingNumber?: string;
    subscriberIndicator?: boolean;
}

export class GetApplicationEnrollmentGroupPlans {
    allocatedAptcAmount?: number;
    insurancePlanIdentifier?: string;
    enrollmentGroupPlanType?: string;
    insurancePlanName?: string;
    monthlyPolicyPremiumAmount?: number;
}

export class GetApplicationEnrollmentGroups {
    enrollmentGroupIdentifier?: string;
    enrollmentGroupName?: string;
    defaultEnrollmentGroupReasonType?: string;
    insuranceProductDivisionType?: string;
    enrollees?: {
        [enrolleeKey: string]: GetApplicationEnrollee;
    };
    enrollmentGroupPlans?: Array<GetApplicationEnrollmentGroupPlans>;
}

export class GetApplicationApplicationKey {
    bestSEP: GetApplicationBestSEP;
    finalQhpEffectiveStartDate?: string;
    finalQhpEffectiveEndDate?: string;
    enrollmentGroups?: Array<GetApplicationEnrollmentGroups>;
}

export class GetApplicationMemberVariableDeterminations {
    catastrophicEligibilityIndicator?: boolean;
    catastrophicEligibilityReasonType?: string;
    planMetalLevelRestrictionRuleType?: string;
    currentPlanOnlyIndicator?: boolean;
    planMetalLevelRestrictionRuleReasonType?: string;
    allowedPlanMetalLevelType?: Array<string>;
    lpaIndicator?: boolean;
}

export class GetApplicationVariableDeterminations {
    creationDateTime?: string;
    samePlanAvailableForCicIndicator?: boolean;
    memberVariableDeterminations?: {
        [memberKey: string]: GetApplicationMemberVariableDeterminations;
    };
    coverageState?: string;
}

export class GetApplicationTaxHouseholdComposition {
    taxHouseHoldStatus?: YES_NO;
    taxHouseHoldStatusReason?: string;
    taxHouseholdMemberIdentifiers?: Array<string>;
}

export class GetApplicationAnnualIncome {
    incomeExplanationRequiredReasonType?: any;
    attestedAnnualTaxHouseholdIncomeAmount?: number;
    attestedAnnualTaxHouseholdIncomePercent?: number;
    annualIncomeStatus?: YES_NO;
    annualIncomeStatusReason?: string;
    incomeExplanationRequiredIndicator?: boolean;
    usedDocumentAnnualTaxIncomeAmount?: number;
}

export class GetApplicationMaxAPTC {
    ptcFplPercentage?: number;
    taxHouseholdAnnualIncomeAmount?: number;
    maxAPTCAmount?: number;
    slcspPremiumAmount?: number;
    slcspIdentifier?: string;
}

export class GetApplicationTaxHouseholds {
    taxHouseHoldComposition?: GetApplicationTaxHouseholdComposition;
    annualIncome?: GetApplicationAnnualIncome;
    maxAPTC: GetApplicationMaxAPTC;
    annualIncomeStatus?: YES_NO;
}

export class GetApplicationComputed {
    members?: {
        [memberKey: string]: GetApplicationMember;
    };
    taxHouseholds?: {
        [taxHouseholdKey: string]: GetApplicationTaxHouseholds;
    };
    application?: GetApplicationApplicationKey;
    variableDeterminations?: Array<GetApplicationVariableDeterminations>;
}

export class GetApplicationResult {
    coverageYear?: number;
    creatingDePartner?: string;
    submittingDePartner?: string;
    insuranceApplicationIdentifier?: number;
    applicationVersionNumber?: number;
    versionDateTime?: string;
    applicationCreationDateTime?: string;
    useCase?: string;
    creatingSystemId?: string;
    creatingSystemRole?: string;
    creatingUserId?: string;
    deletedIndicator?: boolean;
    currentVersionIndicator?: boolean;
    comments?: string;
    lastModifiedDateTime?: string;
    lastModifiedUserId?: string;
    applicationStatus?: string;
    creationOriginType?: string;
    applicationSubmissionDateTime?: string;
    submissionOriginType?: string;
    lastConsumerSubmissionDateTime?: string;
    copiedFromInsuranceApplicationIdentifier?: string;
    copiedFromInsuranceApplicationVersionNumber?: string;
    insuranceApplicationType?: string;
    computed?: GetApplicationComputed;
    linkedSystemUserIdentifier?: string;
    attestations?: UpdateApplicationRequest;
    submission?: GetApplicationSubmission;
}

export class GetApplicationSubmission {
    requestMedicaidDeterminationMembers?: Array<string>;
}

export class GetApplicationModel {
    resultType?: string;
    links?: GetApplicationLinks;
    result?: GetApplicationResult;
}
