import { Injectable } from '@angular/core';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import { GlobalConfig } from '@zipari/shared-sbp-constants';
import { ConfirmationModalConfig } from '@zipari/shared-sbp-components';
import { ConfigService, ModalService } from '@zipari/shared-sbp-services';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface BrowserSupportConfig {
    showBanner: boolean;
    showModal: boolean;
    banner: MessageBannerConfig;
    supportedBrowsers: Array<string>;
    modal: ConfirmationModalConfig;
}

// Map config-friendly browser names to User-Agent browser string names from ngx-device-detector
export const browserNameMapping = {
    safari: 'Safari',
    firefox: 'Firefox',
    edge: 'MS-Edge',
    chrome: 'Chrome',
    ie: 'IE',
};

@Injectable({
    providedIn: 'root',
})
export class BrowserSupportService {
    constructor(private configService: ConfigService, private deviceService: DeviceDetectorService, private modalService: ModalService) {}

    public getBrowserSupportConfig(): BrowserSupportConfig {
        const globalConfig = this.configService.getPageConfig<GlobalConfig>('global');
        return globalConfig ? globalConfig.browserSupport : null;
    }

    /**
     * Checks if user's browser is in configured list of supported browsers
     */
    public isBrowserSupported(): boolean {
        const browserSupportConfig = this.getBrowserSupportConfig();

        // Without config, assume browser supported
        if (!browserSupportConfig || !browserSupportConfig.supportedBrowsers) {
            return true;
        }

        const currentBrowser = this.deviceService.browser;
        const isBrowserInSupportedList = !!browserSupportConfig.supportedBrowsers.find((browser: string) => {
            return (browserNameMapping[browser] || browser) === currentBrowser;
        });
        return isBrowserInSupportedList;
    }

    public openBrowserSupportModal(): void {
        const browserSupportConfig = this.getBrowserSupportConfig();
        if (!this.isBrowserSupported() && browserSupportConfig?.modal) {
            this.modalService.presentModal(browserSupportConfig.modal, null);
        }
    }
}
