import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ZipBusyModule } from '@zipari/design-system';

import { EdeHeaderComponent } from './ede-header.component';

@NgModule({
    declarations: [EdeHeaderComponent],
    imports: [CommonModule, RouterModule, ZipBusyModule],
    exports: [EdeHeaderComponent],
})
export class EdeHeaderModule {}
