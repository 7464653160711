<ng-container *ngFor="let config of configs">
    <div class="form--controls" [libGridCell]="config.grid" *ngIf="!config.customFormElement; else customFormElement">
        <form-control
            (linkClick)="linkClicked.emit({ config: config, event: $event })"
            (iconClick)="iconClicked.emit({ config: config, event: $event })"
            (selected)="selected.emit({ config: config, event: $event })"
            (inputChanged)="inputChanged.emit({ config: config, event: $event })"
            [direction]="direction"
            [config]="config"
            [group]="form"
            [control]="form.controls[config.prop]"
        >
        </form-control>
    </div>

    <ng-template #customFormElement>
        <div [libGridCell]="config.grid" [formGroup]="form">
            <ng-container [ngSwitch]="config.type">
                <form-group-array *ngSwitchCase="'formGroupArray'" [formControlName]="config.prop" [config]="config"> </form-group-array>

                <optional-date *ngSwitchCase="'optionalDate'" [direction]="direction" [config]="config" [group]="form"> </optional-date>

                <conditional-toggle
                    *ngSwitchCase="'conditionalToggle'"
                    [formControlName]="config.prop"
                    [parentForm]="form"
                    [config]="config"
                >
                </conditional-toggle>

                <custom-form-element
                    *ngSwitchDefault
                    (formCreated)="handleFormCreated($event, config)"
                    [direction]="direction"
                    [config]="config"
                    [form]="form"
                    [prefillVal]="prefillVal"
                >
                </custom-form-element>
            </ng-container>
        </div>
    </ng-template>
</ng-container>
