import { Injectable } from '@angular/core';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { cloneObject, deepCompare, getValue, isEmptyObj, isObj } from '@zipari/web-utils';
import { FormattingService } from '@zipari/design-system';
import { MemberTotalInput, MemberTypeValue } from '@zipari/shared-sbp-constants';
import {
    addDependentAddresses,
    determineTobaccoUse,
    generateMemberOptions,
    getCoverageTypes,
    IsTobaccoUse,
} from '@zipari/shared-sbp-enrollment';
import { Address } from '@zipari/shared-sbp-models';
import { validEnrollmentTypes } from '@zipari/shared-sbp-services';
import { MemberType } from '@zipari/shared-sbp-templates';

export class FirstStepFormat {
    enrollmentPeriod?: any;
    permanent_address?: any;
    contact?: any;
    members?: any;
    county?: any;
    coverage_effective_date?: string;
    memberCoverageOption?: any;
    coverage_types?: Array<string>;
    effective_date?: any;
    special_enrollment?: any;
}

export interface EnrollmentFormattingMemberExtraFields {
    /**
     * flag to determine if we bypass (ignore the val of) any conditional checks on any member's Personal Identifiable Info.
     *
     * We prevent checking these conditionals when formatting data that doesn't contain a member's PII fields so we can
     * get member data if a member obj has no PII data. Currently this will occur when calling formatMembersFromData() from
     * the quote-detail-unauthenticated.component
     */
    bypassPiiFieldConditionals?: boolean;
    uuid?: string;
    [memberField: string]: any;
}

enum OptionType {
    primary_address = 'primary_address',
    billing_address = 'billing_address',
    mailing_address = 'mailing_address',
    other_residence = 'other_residence',
    other = 'other',
}

@Injectable({
    providedIn: 'root',
})
export class EnrollmentFormattingService {
    constructor(public formattingService: FormattingService) {}

    public formatMembersFromData(values: MemberTotalInput | any, extra?: EnrollmentFormattingMemberExtraFields): MemberTypeValue[] {
        // todo legacy code ported from V2... CLEANUP
        let members = [];
        const bypassPiiFieldConditionals: boolean = extra?.bypassPiiFieldConditionals || false;
        const isChildOnly: boolean = this.isChildOnly(values);

        if (!values.subscriber && !values.dependents && !values.spouse) return [...members, { ...values }];

        const subscriberPermanentAddress = values.permanent_address || values.subscriber?.address || null;
        const subscriberMailingAddress = values.mailing_address || values.subscriber?.mailing_address || null;

        if (
            values.subscriber &&
            this.bypassConditionalExpression(bypassPiiFieldConditionals, values.subscriber.hasOwnProperty('date_of_birth'))
        ) {
            const subscriberAddress = values.subscriber.address || {};
            if (isEmptyObj(subscriberAddress) && subscriberPermanentAddress) {
                values.subscriber.address = cloneObject(subscriberPermanentAddress);
            } else if (!isEmptyObj(subscriberAddress) && !deepCompare(subscriberAddress, subscriberPermanentAddress)) {
                // update when permanent address changes
                values.subscriber.address = cloneObject(subscriberPermanentAddress);
            }

            if (!values.subscriber.mailing_address && subscriberMailingAddress) {
                values.subscriber.mailing_address = cloneObject(subscriberMailingAddress);
            }

            // if user has a uuid, reuse it, otherwise generate it.
            const subscriberMember = {
                ...values.subscriber,
                ...generateMemberOptions(MemberType.subcriber, extra ? extra : { uuid: values.subscriber.uuid }),
            };

            const tobaccoValues: boolean = determineTobaccoUse(subscriberMember);

            subscriberMember.tobacco_user = tobaccoValues;
            subscriberMember.tobacco_use = tobaccoValues;

            // set up the full name of the applicant
            subscriberMember.name = this.formattingService.restructureValueBasedOnFormat(subscriberMember, {
                format: formatTypes.FULL_NAME,
            });
            values.subscriber.name = subscriberMember.name;

            // unique identifier for the member
            values.subscriber.uuid = subscriberMember.uuid;
            values.subscriber.memberType = subscriberMember.memberType;

            members.push(subscriberMember);
        }

        if (values.spouse && this.bypassConditionalExpression(bypassPiiFieldConditionals, values.spouse.hasOwnProperty('date_of_birth'))) {
            // values.spouse.address implies that a different mailing address has been selected given how
            // PersonalInfoMember stores mailing address.
            const spouseMailingAddress = cloneObject(values?.spouse?.address || {});
            /* As of 05/18/2021 spouse.address should equal subscriber.permanent_address*/
            values.spouse.address = cloneObject(subscriberPermanentAddress);
            // Horizon has custom spouse address props, so we need to map those.
            // if values.spouse.spouse_street_address is truthy, then its safe to assume spouse has a
            // different address, so override address values.
            const isDifferentSpouseAddress: boolean = !!values.spouse.spouse_street_address;

            if (isDifferentSpouseAddress) {
                const spouseAddress: Address = {
                    address_1: values.spouse.spouse_street_address,
                    address_2: values.spouse.spouse_apartment_number,
                    street_name_1: values.spouse.spouse_street_address,
                    street_name_2: values.spouse.spouse_apartment_number,
                    city: values.spouse.spouse_city,
                    state: values.spouse.spouse_state,
                    zip_code: values.spouse.spouse_zipcode,
                    zipcode: values.spouse.spouse_zipcode,

                    // Form values missing for Horizon, we need to clear these to prevent using the subscriber's primary
                    // address values for these fields when we patch to the wf's values on personal info step completion.
                    county: values.spouse?.county || null,
                    county_display: values.spouse?.county_display || null,
                    county_name: values.spouse?.county_name || null,
                    state_name: values.spouse?.state_name || null,
                };
                values.spouse.mailing_address = cloneObject(spouseAddress);
            } else if (!isDifferentSpouseAddress && (isEmptyObj(spouseMailingAddress) || !values.spouse.different_mailing_address)) {
                // When there's no different mailing address, assign subscriber permanent address to this member.
                values.spouse.address = values.spouse.mailing_address = cloneObject(subscriberPermanentAddress);
            } else if (values.spouse.different_mailing_address && !isEmptyObj(spouseMailingAddress)) {
                values.spouse.mailing_address = {
                    ...spouseMailingAddress,
                    street_name_1: spouseMailingAddress.address_1 || spouseMailingAddress.street_name_1,
                    street_name_2: spouseMailingAddress.address_2 || spouseMailingAddress.street_name_2,
                    zip_code: spouseMailingAddress.zipcode || spouseMailingAddress.zip_code,
                };
            }

            const maritalStatus: string = getValue(values, 'questions.marital_status');
            const spouseMember = {
                ...values.spouse,
                ...generateMemberOptions(
                    maritalStatus === 'domestic_partnership' ? MemberType.domestic_partner : MemberType.spouse,
                    extra ? extra : { uuid: values.spouse.uuid }
                ),
            };

            // set up the full name of the applicant
            spouseMember.name = this.formattingService.restructureValueBasedOnFormat(spouseMember, {
                format: formatTypes.FULL_NAME,
            });
            values.spouse.name = spouseMember.name;

            // unique identifier for the member
            values.spouse.uuid = spouseMember.uuid;

            const tobaccoValues: boolean = determineTobaccoUse(spouseMember);

            spouseMember.tobacco_user = tobaccoValues;
            spouseMember.tobacco_use = tobaccoValues;
            values.spouse.memberType = spouseMember.memberType;

            members.push(spouseMember);
        }

        if (
            values.dependents?.length &&
            this.bypassConditionalExpression(bypassPiiFieldConditionals, values.dependents[0]['date_of_birth'])
        ) {
            const dependentAddress = addDependentAddresses(
                subscriberPermanentAddress,
                extra,
                values.dependents,
                MemberType.child_dependent,
                subscriberMailingAddress,
                isChildOnly
            );

            members = [...members, ...dependentAddress];
        }

        // Parent Dependent Member Data

        if (
            values.stepParents?.length &&
            this.bypassConditionalExpression(bypassPiiFieldConditionals, values.stepParents[0]['date_of_birth'])
        ) {
            const stepParentAddress = addDependentAddresses(
                subscriberPermanentAddress,
                extra,
                values.stepParents,
                MemberType.step_parent_dependent
            );
            members = [...members, ...stepParentAddress];
        }

        // not sure why we're overwritting tobacco_use/tobacco_user
        // commenting out incase we need this
        // TODO: delete this code block/comment if not needed -hlopez
        /* return members.map(member => {
            member.tobacco_use = member.tobacco_use ? 'Yes' : 'No';
            member.tobacco_user = member.tobacco_user ? 'Yes' : 'No';

            return member;
        }); */
        return members;
    }

    public formatFirstStep(previousValues: any, currentStepValues: FirstStepFormat) {
        const contactValues = { ...currentStepValues.contact };
        const memberValues = currentStepValues.members;
        const memberCoverageOption = currentStepValues.memberCoverageOption;
        const countyLocation = currentStepValues.county;
        const coverageEffectiveDate = currentStepValues.coverage_effective_date;
        const enrollmentPeriod = currentStepValues.enrollmentPeriod || currentStepValues['enrollment_period'];
        const countyCode = countyLocation.county || countyLocation.county_code;
        const specialEnrollment = currentStepValues.special_enrollment;
        const total_members = 0;

        const coverage_types: Array<string> = getCoverageTypes(currentStepValues.contact);
        if (contactValues.coverage_types) contactValues.coverage_types = coverage_types;

        /* We used to add previousValues.contactInfo in newContactInfo. I don't know why we
        did this because it can potentiall include values not originally submitted in the
        first step.  Removed to fix CE-12670 leaving this comment incase it breaks something
        else. trying to prevent going in circles. */
        const newContactInfo = {
            ...contactValues,
            county_display: countyLocation.county_name,
            county_name: countyLocation.county_name,
        };

        const zipcode = getValue(currentStepValues, 'permanent_address.zipcode') || contactValues.zipcode;
        const enrollment: any = {
            /*
             * we stoped storing values for `personal-info` on that step. it was causing issues with the quote edit panel,
             * these values are patched by the quote edit panel to the demographics/eligilbity step. However, given how BE
             * creates WFvalues it wouldn't update correctly. Now that we're no longer saving those values on the `personal-info` step
             * we'll need to map all the values to step 1 to allow for prefilling forms on `presonal-info` when returning from editing
             */
            ...previousValues,
            memberCoverageOption: memberCoverageOption,
            contact_info: newContactInfo,
            contact: newContactInfo,
            permanent_address: {
                zip_code: contactValues.zipcode,
                zipcode: contactValues.zipcode,
                county_display: countyLocation.county_name,
                county_name: countyLocation.county_name,
                county: countyCode,
                state_name: countyLocation.state_name || countyLocation.state,
                state: countyLocation.state_code || countyLocation.state,
                ...currentStepValues.permanent_address,
            },
            coverage: currentStepValues['coverage'],
            getStarted: {
                ...currentStepValues['getStarted'],
                coverage_effective_date: coverageEffectiveDate,
            },
            coverage_effective_date: coverageEffectiveDate,
            coverage_types,
            coverage_type: contactValues.coverage_type || currentStepValues.coverage_types,
            county: countyLocation,
            county_location: countyLocation,
            enrollment_period: enrollmentPeriod,
            special_enrollment: specialEnrollment,
            demographics: {
                ...currentStepValues['getStarted'],
                ...currentStepValues['demographics'],
                is_sep: enrollmentPeriod === validEnrollmentTypes.SEP,
                coverage_types,
                coverage_type:
                    contactValues.coverage_type ||
                    (currentStepValues['getStarted'] ? currentStepValues['getStarted']['coverage_type'] : null),
                total_members: total_members,
                contact: contactValues,
                child_only: this.isChildOnly(memberValues),
                county: countyCode,
                zipcode: zipcode,
            },
            child_only: this.isChildOnly(memberValues),
            whose_covered: currentStepValues['whose_covered'],
        };

        if (!isEmptyObj(currentStepValues.effective_date || {})) {
            enrollment['effective_date'] = currentStepValues.effective_date;
        }

        // mapping all the values from `personal-info` if county and zipcode hasn't changed
        // as those are the values collected in demographics/eligibilty for all clients
        if (
            enrollment.permanent_address.county === previousValues?.permanent_address?.county &&
            enrollment.permanent_address.zipcode === previousValues?.permanent_address?.zipcode
        ) {
            enrollment.permanent_address = { ...previousValues.permanent_address };
        }

        /*
         * we are still storing plan data to their respective steps,
         * removing them from the first step submission.
         */

        delete enrollment.offExchange;
        delete enrollment.onExchange;
        delete enrollment.plans;
        delete enrollment.quoted;

        if (!!memberValues.subscriber) {
            if (!memberValues.subscriber.sex && memberValues.subscriber.gender) {
                memberValues.subscriber.sex = memberValues.subscriber.gender;
            } else if (memberValues.subscriber.sex && !memberValues.subscriber.gender) {
                memberValues.subscriber.gender = memberValues.subscriber.sex;
            }

            enrollment.subscriber = enrollment.demographics.subscriber = memberValues.subscriber;
            if (previousValues && previousValues.subscriber) {
                enrollment.subscriber['uuid'] = previousValues.subscriber.uuid;
                // mapping all the values from `personal-info` if DOB and gender hasn't changed
                // as those are the values collected in demographics/eligibilty for all clients
                if (
                    enrollment.subscriber.date_of_birth === previousValues?.subscriber?.date_of_birth &&
                    enrollment.subscriber.gender === previousValues?.subscriber?.gender &&
                    enrollment.subscriber.tobacco_user === previousValues?.subscriber?.tobacco_user
                ) {
                    enrollment.subscriber = { ...previousValues.subscriber };
                }
            }

            enrollment.demographics.total_members += 1;
        } else {
            delete enrollment.subscriber;
        }

        if (!!memberValues.spouse) {
            if (!memberValues.spouse.sex && memberValues.spouse.gender) {
                memberValues.spouse.sex = memberValues.spouse.gender;
            } else if (memberValues.spouse.sex && !memberValues.spouse.gender) {
                memberValues.spouse.gender = memberValues.spouse.sex;
            }

            enrollment.spouse = enrollment.demographics.spouse = memberValues.spouse;
            if (previousValues && previousValues.spouse) {
                enrollment.spouse['uuid'] = previousValues.spouse.uuid;
                // mapping all the values from `personal-info` if DOB and gender hasn't changed
                // as those are the values collected in demographics/eligibilty for all clients
                if (
                    enrollment.spouse.date_of_birth === previousValues?.spouse?.date_of_birth &&
                    enrollment.spouse.gender === previousValues?.spouse?.gender &&
                    enrollment.spouse.tobacco_user === previousValues?.spouse?.tobacco_user
                ) {
                    enrollment.spouse = { ...previousValues.spouse };
                }
            }

            enrollment.demographics.total_members += 1;
        } else {
            delete enrollment.spouse;
        }

        if ((!!memberValues.children && memberValues.children.length) || (!!memberValues.dependents && memberValues.dependents.length)) {
            const deps = (memberValues.children || memberValues.dependents).map((dep, i) => {
                if (!dep.sex && dep.gender) dep.sex = dep.gender;
                else if (dep.sex && !dep.gender) dep.gender = dep.sex;

                if (previousValues?.dependents?.[i]?.uuid) {
                    dep['uuid'] = previousValues.dependents[i].uuid;
                    // mapping all the values from `personal-info` if DOB and gender hasn't changed
                    // as those are the values collected in demographics/eligibilty for all clients
                    if (
                        dep.date_of_birth === previousValues?.dependents[i]?.date_of_birth &&
                        dep.gender === previousValues?.dependents[i]?.gender &&
                        dep.tobacco_user === previousValues?.dependents[i]?.tobacco_user
                    ) {
                        dep = { ...previousValues.dependents[i] };
                    }
                }

                return dep;
            });

            enrollment.dependents = enrollment.demographics.dependents = deps;
            enrollment.demographics.total_members += (memberValues.children || memberValues.dependents)?.length;
        } else {
            delete enrollment.dependents;
        }

        if (enrollment.demographics.stepParents) {
            enrollment.stepParents = enrollment.demographics.stepParents;
            enrollment.demographics.total_members += enrollment.demographics.stepParents.length;
        }

        if (memberValues?.stepParents?.length) {
            const parentDependents = previousValues?.dependents?.filter(
                (dependent) => dependent.memberType === MemberType.step_parent_dependent
            );
            const parentDeps = memberValues.stepParents.map((stepParent, i) => {
                if (parentDependents?.[i]?.uuid) {
                    stepParent.uuid = parentDependents[i].uuid;
                    // mapping all the values from `personal-info` if DOB and gender hasn't changed
                    // as those are the values collected in demographics/eligibilty for all clients
                    if (
                        stepParent.date_of_birth === parentDependents[i].date_of_birth &&
                        stepParent.tobacco_user === parentDependents[i].tobacco_user
                    ) {
                        stepParent = { ...parentDependents[i] };
                    }
                }

                return stepParent;
            });

            enrollment.stepParents = parentDeps;
            enrollment.demographics.dependents = [...(enrollment.demographics.dependents || []), ...(parentDeps || [])];
            enrollment.demographics.total_members += memberValues.stepParents?.length;
        }

        enrollment.is_dependents = this.isDependents(enrollment);
        enrollment.is_spouse = this.isSpouse(enrollment);
        enrollment.members = this.formatMembersFromData(enrollment);
        enrollment.total_members = enrollment.demographics.total_members;

        // we have to Only send Parents/Step-Parents data under dependents & members Object
        // but we don't have to send stepParents object in Payload
        if (enrollment.stepParents) {
            enrollment.dependents = [...(enrollment.dependents || []), ...(enrollment.stepParents || [])];
            delete enrollment.stepParents;
        }

        return enrollment;
    }

    public isSpouse(memberValues) {
        return !!memberValues?.spouse;
    }

    public isDependents(memberValues) {
        return (
            (!!memberValues?.dependents || !!memberValues?.stepParents) &&
            (memberValues.dependents?.length > 0 || memberValues.stepParents?.length > 0)
        );
    }

    public isChildOnly(memberValues) {
        return memberValues.child_only === true || (!memberValues.subscriber && !memberValues.spouse);
    }

    public isFamily(memberValues) {
        return (this.isDependents(memberValues) || this.isSpouse(memberValues)) && !this.isChildOnly(memberValues);
    }

    /** this is what we call tech debt
     * todo: after AEP need to figure this out with BE
     * */
    handleBackwardsCompatibility(workflowValues, currentStepValues) {
        const values = structuredClone(currentStepValues);

        // renewals slide has this set to children instead of dependents in BP
        if (values.hasOwnProperty('children') && values.children) {
            values.dependents = values.children ? cloneObject(values.children) : null;
        }

        const currentStepKeys = Object.keys(currentStepValues);

        // handle backwards compatibility with BE enrollment
        // todo: extract this out somehow?
        let finalFormat: any = {
            ...values,
        };

        currentStepKeys.forEach((key) => {
            if (workflowValues[key]) {
                Object.assign(finalFormat, { [key]: workflowValues[key] }, { [key]: currentStepValues[key] });
            }
        });

        finalFormat = this.backwardsCompatibleAddress(finalFormat);

        if (finalFormat.responsible_party) {
            if (!values.contact_info) {
                finalFormat.contact_info = {};
            }

            if (finalFormat.responsible_party.email || finalFormat.responsible_party.email_address) {
                finalFormat.contact_info.email_address = finalFormat.responsible_party.email || finalFormat.responsible_party.email_address;
            }

            if (finalFormat.responsible_party.home_phone || finalFormat.responsible_party.phone_number) {
                finalFormat.contact_info.home_phone =
                    finalFormat.responsible_party.home_phone || finalFormat.responsible_party.phone_number;
            }
        }

        // handle medicare and medicaid and addres under subscriber
        if (finalFormat.subscriber) {
            finalFormat.subscriber = this.backwardsCompatibleMedicareAndMedicaid(finalFormat.subscriber);
            finalFormat.subscriber.address = finalFormat.permanent_address;

            if (!values.contact_info) {
                finalFormat.contact_info = {};
            }

            if (finalFormat.subscriber.email || finalFormat.subscriber.email_address) {
                finalFormat.contact_info.email_address = finalFormat.subscriber.email || finalFormat.subscriber.email_address;
            }

            if (finalFormat.subscriber.home_phone || finalFormat.subscriber.phone_number) {
                finalFormat.contact_info.home_phone = finalFormat.subscriber.home_phone || finalFormat.subscriber.phone_number;
            }

            if (workflowValues.subscriber) {
                if (workflowValues.subscriber.uuid) {
                    finalFormat.subscriber.uuid = workflowValues.subscriber.uuid;
                }

                if (workflowValues.subscriber.member_number) {
                    finalFormat.subscriber.member_number = workflowValues.subscriber.member_number;
                }

                // Avoiding overwriting of old workflow value
                // Updated with current step value
                const memberObj = IsTobaccoUse(finalFormat.subscriber) ? finalFormat.subscriber : workflowValues.subscriber;
                const tobaccoValues: boolean = determineTobaccoUse(memberObj);

                finalFormat.subscriber.tobacco_user = tobaccoValues;
                finalFormat.subscriber.tobacco_user = tobaccoValues;
            }
        }

        if (finalFormat.spouse) {
            finalFormat.spouse = this.backwardsCompatibleMedicareAndMedicaid(finalFormat.spouse);

            if (workflowValues.spouse) {
                if (workflowValues.spouse.uuid) {
                    finalFormat.spouse.uuid = workflowValues.spouse.uuid;
                }

                if (workflowValues.spouse.member_number) {
                    finalFormat.spouse.member_number = workflowValues.spouse.member_number;
                }

                // Avoiding overwriting of old workflow value
                // Updated with current step value
                const memberObj = IsTobaccoUse(finalFormat.spouse) ? finalFormat.spouse : workflowValues.spouse;
                const tobaccoValues: boolean = determineTobaccoUse(memberObj);

                finalFormat.spouse.tobacco_user = tobaccoValues;
                finalFormat.spouse.tobacco_user = tobaccoValues;
            }
        }

        if (finalFormat.dependents) {
            const formattedDep = finalFormat.dependents.map((dependent, i) => {
                this.backwardsCompatibleMedicareAndMedicaid(dependent);

                if (workflowValues.dependents && workflowValues.dependents[i]) {
                    if (workflowValues.dependents[i].uuid) {
                        dependent.uuid = workflowValues.dependents[i].uuid;
                    }

                    // added this condition outside as it overwritting for those dependent memberType value which have memberType with workflow values
                    // result of which updated dependent gets updated with old workflow values
                    // Now it will assign the memberType only when dont have memberType with the dependent

                    if (!dependent.memberType) {
                        dependent.memberType = workflowValues.dependents[i].memberType;
                        dependent.memberTypeUnique = workflowValues.dependents[i].memberTypeUnique;
                    }

                    if (workflowValues.dependents[i].member_number) {
                        dependent.member_number = workflowValues.dependents[i].member_number;
                    }

                    // Avoiding overwriting of old workflow value
                    // Updated with current step value
                    const memberObj = IsTobaccoUse(finalFormat.dependents[i]) ? finalFormat.dependents[i] : workflowValues.dependents[i];

                    const tobaccoValues: boolean = determineTobaccoUse(memberObj);

                    dependent.tobacco_user = tobaccoValues;
                    dependent.tobacco_user = tobaccoValues;
                }

                return dependent;
            });

            /**
             * NOTE: fields not entered in third argument object will be overridden if user returns to edit (for reasons unknown)
             */
            finalFormat.dependents = this.handleDeepMergeArrayBug(workflowValues.dependents || [], formattedDep || [], {
                date_of_birth: true,
                is_disabled: true,
                tobacco_user: true,
                first_name: true,
                last_name: true,
                ssn: true,
                gender: true,
                middle_name: true,
                suffix: true,
                CC6: true, // horizon stuff
                CC6_1: true, // horizon stuff
                address: true,
                mailing_address: true,
                different_mailing_address: true,
                child_apartment_number: true, // horizon stuff
                child_city: true, // horizon stuff
                child_state: true, // horizon stuff
                child_street_address: true, // horizon stuff
                child_zipcode: true, // horizon stuff
            });
        }

        // make sure contact info is correct
        if (finalFormat.contact || finalFormat.contact_info) {
            // Primary is subscriber or child-only
            const primarySubscriber = finalFormat.subscriber ?? finalFormat.dependents?.[0];

            finalFormat.contact = {
                ...(finalFormat.contact || finalFormat.contact_info),
            };

            const primary = this.safeCheckMultipleLevelOfObjectsAndProps(
                finalFormat,
                ['contact_info', 'contact'],
                ['home_phone', 'primary_phone', 'phone']
            );

            const secondary = this.safeCheckMultipleLevelOfObjectsAndProps(
                finalFormat,
                ['contact_info', 'contact'],
                ['secondary_phone_number', 'alternate_phone', 'secondary_phone']
            );

            const email = this.safeCheckMultipleLevelOfObjectsAndProps(
                finalFormat,
                ['contact_info', 'contact'],
                ['email_address', 'email']
            );

            // required for enrollment submission for friday
            finalFormat.contact['email_address'] = email;
            finalFormat.contact['email'] = email;

            finalFormat.contact['primary_phone'] = primary;
            finalFormat.contact['secondary_phone_number'] = secondary;
            finalFormat.contact['phone'] = primary;

            finalFormat.contact['secondary_phone'] = secondary;
            finalFormat.contact['home_phone'] = primary;

            // added check to Populate primary detail on subscriber

            if (primarySubscriber && finalFormat?.subscriber) {
                // Populate primary phone fields on subscriber
                primarySubscriber['home_phone'] = primary;
                primarySubscriber['phone'] = primary;
                primarySubscriber['primary_phone'] = primary;

                // Populate email fields on subscriber
                primarySubscriber['email'] = email;
                primarySubscriber['email_address'] = email;
            }

            finalFormat.contact_info = finalFormat.contact;
        }

        delete finalFormat.plan;
        delete finalFormat.plans;
        delete finalFormat.quoted;
        delete finalFormat.display_coverage_effective_date;
        if (finalFormat['enrollment_period'] === 'OE') {
            delete finalFormat.special_enrollment;
            delete finalFormat.sep_documents;
        }

        /* If there is a child_only value in the workflow, use it; otherwise calculate it */
        finalFormat.child_only = workflowValues.child_only == null ? this.isChildOnly(finalFormat) : workflowValues.child_only;

        finalFormat.is_dependents = this.isDependents(finalFormat);
        finalFormat.is_spouse = this.isSpouse(finalFormat);
        finalFormat.members = this.formatMembersFromData(finalFormat);

        // we have to Only send Parents/Step-Parents data under dependents & members Object
        // but we don't have to send stepParents object in Payload
        if (finalFormat.stepParents) delete finalFormat.stepParents;

        return finalFormat;
    }

    safeCheckMultipleLevelOfObjectsAndProps(values: any, topLevelProps: Array<string>, lowerLevelProps: Array<string>) {
        for (let i = 0; i < topLevelProps.length; i++) {
            const currentTopLevelProp = topLevelProps[i];

            for (let j = 0; j < lowerLevelProps.length; j++) {
                const currentPossibleLowerProp = lowerLevelProps[j];

                if (values[currentTopLevelProp] && values[currentTopLevelProp][currentPossibleLowerProp]) {
                    return values[currentTopLevelProp][currentPossibleLowerProp];
                }
            }
        }

        return null;
    }

    backwardsCompatibleAddress(value) {
        if (value.hasOwnProperty('mailing_address_different_from_permanent') || value.hasOwnProperty('different_mailing_address')) {
            value['mailing_address_different_from_permanent'] = value.hasOwnProperty('mailing_address_different_from_permanent')
                ? value['mailing_address_different_from_permanent']
                : !!getValue(value, 'different_mailing_address.different_mailing_address');
        }

        // handle mailing address
        if (
            value.hasOwnProperty('mailing_address_different_from_permanent') &&
            value.mailing_address_different_from_permanent === false &&
            !getValue(value, 'mailing_address.other_mailing_address')
        ) {
            value.mailing_address = cloneObject(value.permanent_address);
        }

        /* 
          if we select Other Residence (mailing_address) as primary_address and
          billing_address as mailing_address then we need to update mailing_address first
        */
        this.updateAddressonOptionValues(value, 'mailing_address');
        this.updateAddressonOptionValues(value, 'billing_address');

        const addresses: Array<string> = ['permanent_address', 'mailing_address', 'billing_address'];

        addresses.forEach((addressKey: string) => {
            if (value[addressKey]) {
                value[addressKey].zip_code = this.safeCheckMultipleLevelOfObjectsAndProps(value, [addressKey], ['zipcode', 'zip_code']);
                value[addressKey].street_name_1 = this.safeCheckMultipleLevelOfObjectsAndProps(
                    value,
                    [addressKey],
                    ['address1', 'street_name_1', 'address_1']
                );
                value[addressKey].street_name_2 = this.safeCheckMultipleLevelOfObjectsAndProps(
                    value,
                    [addressKey],
                    ['address2', 'street_name_2', 'address_2']
                );
            }
        });

        return value;
    }

    updateAddressonOptionValues(value, addressType: 'billing_address' | 'mailing_address') {
        const addressExists = getValue(value, addressType);
        if (addressExists) {
            const otherKey = addressType === 'billing_address' ? 'other_billing_address' : 'other_mailing_address';
            const optionType = value[addressType][otherKey];

            if (optionType === OptionType.primary_address) {
                value[addressType] = {
                    ...value[addressType],
                    ...value.permanent_address,
                };
            } else if (addressType === OptionType.mailing_address && optionType === OptionType.billing_address) {
                value[addressType] = {
                    ...value[addressType],
                    ...value.billing_address,
                };
            } else if (addressType === OptionType.billing_address && optionType === OptionType.other_residence) {
                value.billing_address = {
                    ...value.billing_address,
                    ...value.mailing_address,
                };
            } else if (addressType === OptionType.billing_address && optionType === OptionType.mailing_address) {
                /* 
             if we select billing_address as Other Residence (mailing_address) then value of other_billing_address key
             was mailing_address and it was not handled in above mentioned condition
            */
                value.billing_address = {
                    ...value.billing_address,
                    ...value.mailing_address,
                };
            }
        }
    }

    backwardsCompatibleMedicareAndMedicaid(member: any) {
        if (member.hasOwnProperty('middle_initial')) {
            member.middle_name = member.middle_initial;
        }

        if (member.hasOwnProperty('medicare_eligible')) {
            member.is_subscriber_on_medicare = member.medicare_eligible;
        }

        if (member.hasOwnProperty('medicaid_eligible')) {
            member.is_subscriber_on_medicaid = member.medicaid_eligible;
        }

        if (member.hasOwnProperty('is_disabled')) {
            member.is_subscriber_disabled = member.is_disabled;
        }

        return member;
    }

    handleDeepMergeArrayBug(previousValue: any, currentValue: any, uniqueIdentifiers: any) {
        const uniquePrevArrayValue = {};
        // get unique key from previous value (storing their original index)
        if (previousValue && previousValue.length > 0) {
            previousValue.forEach((prevVal) => {
                const uniqueKey = this.createUniqueKey(uniqueIdentifiers, prevVal);

                uniquePrevArrayValue[uniqueKey] = prevVal;
            });
        }

        // map current values
        // get individual unique key of the current value
        // merge ONLY IF unique key exists in the previous
        return currentValue.map((currVal) => {
            const uniqueKey = this.createUniqueKey(uniqueIdentifiers, currVal);

            const objExistsInPreviousValue = uniquePrevArrayValue[uniqueKey];

            // if this object is not undefined...
            // we know that this is the same version of this current value from the previous value
            // so do a special merge
            if (objExistsInPreviousValue) {
                Object.assign(currVal, objExistsInPreviousValue);
            }

            return currVal;
        });
    }

    createUniqueKey(uniqueIdentifiers: any, obj: any) {
        let uniqueKey = '';

        // convert the unique keys into an array and make sure to always sort by alphabetical order
        const objKeys = Object.keys(obj).sort();

        // if current key is one of the unique identifiers provided
        objKeys.forEach((key) => {
            if (uniqueIdentifiers[key]) {
                uniqueKey += '--';
                uniqueKey += obj[key];
            }
        });

        return uniqueKey;
    }

    /** this is what we call tech debt
     * todo: after AEP need to figure this out with BE
     * */

    retrieveMemberFromValues(values, memberKey, ind = null) {
        const relevantMember = getValue(values, memberKey);

        return ind !== null ? relevantMember[ind] : relevantMember;
    }

    formatPCPS(values) {
        if (values.pcp) {
            const pcps = [];

            // dependents will be an array, otherwise for subscriber or spouse we'll look for an object
            const membersWithPCP = Object.keys(values.pcp).filter(
                (memberKey) => isObj(values.pcp[memberKey]) || Array.isArray(values.pcp[memberKey])
            );

            membersWithPCP.forEach((memberKey) => {
                let newPcpObject;
                let fullMember;

                if (memberKey === 'dependents') {
                    values.pcp[memberKey].forEach((dep, depInd) => {
                        newPcpObject = {
                            ...values.pcp[memberKey][depInd],
                        };

                        fullMember = this.retrieveMemberFromValues(values, memberKey, depInd);

                        pcps.push({ ...newPcpObject, name: `${fullMember.first_name} ${fullMember.last_name}` });
                    });
                } else if (!memberKey.startsWith('dependent_')) {
                    newPcpObject = {
                        ...values.pcp[memberKey],
                    };

                    fullMember = this.retrieveMemberFromValues(values, memberKey);

                    pcps.push({ ...newPcpObject, name: `${fullMember.first_name} ${fullMember.last_name}` });
                }
            });

            return pcps;
        } else {
            return null;
        }
    }

    formatPCCS(values) {
        if (values.pcc) {
            const pccs = [];
            const membersWithPCC = Object.keys(values.pcc);

            membersWithPCC.forEach((memberKey) => {
                let newPccObject;
                let fullMember;

                if (memberKey === 'dependents') {
                    values.pcc[memberKey].forEach((dep, depInd) => {
                        newPccObject = {
                            ...values.pcc[memberKey][depInd],
                        };

                        fullMember = this.retrieveMemberFromValues(values, memberKey, depInd);

                        pccs.push({ ...newPccObject, name: `${fullMember.first_name} ${fullMember.last_name}` });
                    });
                } else if (!memberKey.startsWith('dependent_')) {
                    newPccObject = {
                        ...values.pcc[memberKey],
                    };

                    fullMember = this.retrieveMemberFromValues(values, memberKey);

                    pccs.push({ ...newPccObject, name: `${fullMember.first_name} ${fullMember.last_name}` });
                }
            });

            return pccs;
        } else {
            return null;
        }
    }

    /**
     * if we want to prevent checking a condition, return true to bypass the condition, else return the boolean value of the condition
     *
     * @param bypassExpression flag to determine if we bypass (ignore) the value of the condition. if true, bypass condition, else return condition
     * @param condition the boolean value of a conditional expression
     */
    private bypassConditionalExpression(bypassExpression: boolean, condition: boolean): boolean {
        return bypassExpression || condition;
    }

    // Necessary to change question keys such as 'PD1_0' back to props such as 'new_to_medicare'
    // for scenarios where the props are alphanumeric rather than descriptive.
    // Also generates an ordered array of the section that can be used in the jinja template
    // eg: {% for item in workflow.past_coverage_ordered|sort(attribute='order') %}
    public remapApplicationQuestions(workflow, mapObj): any {
        const sections = Object.keys(mapObj).filter((key) => workflow[key]);

        sections.map((section) => {
            const wfKeys = Object.keys(workflow[section]);
            let reorderedArray = [];

            wfKeys.map((wfKey) => {
                const mapItem = mapObj[section].find((obj) => obj.key === wfKey);
                if (mapItem) {
                    reorderedArray.push({
                        key: mapItem.prop,
                        value: workflow[section][wfKey],
                        order: mapItem.key,
                    });
                } else {
                    reorderedArray.push({
                        key: wfKey,
                        value: workflow[section][wfKey],
                        order: 'Z', // jinja can't handle null sort prop
                    });
                }
            });

            const remappedObj = {};
            reorderedArray
                .sort((a, b) => a.order.localeCompare(b.order))
                .map((item) => {
                    remappedObj[item.key] = item.value;
                });

            workflow[section] = remappedObj;
            workflow[`${section}_ordered`] = reorderedArray;
        });

        return workflow;
    }
}
