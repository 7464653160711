export class DocumentMetadataResponse {
    results?: Array<DocumentMetadataModel>;
}

export class DocumentMetadataModel {
    dsrsIdentifier?: string;
    documentCategoryCode?: string;
    sourceSystemCode?: string;
    fileFormat?: string;
    fileSize?: number;
    documentFileName?: string;
    documentSubcategoryCode?: string;
    documentCreationDateTime?: string;
    insuranceApplicationIdentifier?: number;
    personTrackingNumbers?: Array<string>;
    marketplaceGroupPolicyIdentifier?: number;
    exemptionApplicationIdentifier?: number;
    coverageYear?: number;
    applicationVersionNumber?: number;
    noticeDeliveryTypeCode?: string;
    noticeLanguageCode?: string;
    noticePageQuantity: number;
    verificationIssueIdentifiers?: Array<string>;
    submissionMethod?: string;
    applicationMemberIdentifier?: number;
    consumerAttestedDocumentType?: string;
    eswDeterminedDocumentType?: string;
    issueType?: string;
    consumerAttestedVerificationIssueIdentifier?: string;
    restrictedResultIndicator?: boolean;

    constructor(options) {
        Object.assign(this, options);
    }
}
