export default class PrincipalDiagnosis {
    readonly id?: number;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    tenant?: string;
    diagnosis_code: string;
    diagnosis_description?: string;
}
