export default class Subscriber {
    id: number;
    created_at: string;
    updated_at: string;
    data: {};
    effective_date: string;
    termination_date: string;
    date_of_birth: string;
    tobacco_use: true;
}
