import Contact from './Contact.model';

/**
 * [todo] Need to resolve model source differences.
 */
export default class Provider {
    // from Swagger 'Requesting provider' model, nested under 'TransferOfCare' model: http://brapi1.dev.zipari.net/swagger/
    readonly id?: number;
    contacts: Array<Contact>;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    name: string;
    first_name?: string;
    last_name?: string;
    provider_locations?: Array<any>;

    // from Member 360 Provider/PCP definition
    ipa_medical_group_association?: string;
    member_encounters?: number;
    npi?: string;
    provider_id?: string;
    network_name?: string;
    specialty_type?: string;
    location_id?: string;
    tin?: string;
    wheelchair_access?: boolean;
    language?: string;

    // from /src/app/provider-search/models/entities/provider.ts
    carrier_specific_id?: string;
    subheader?: string;
    accepting_new_patients?: boolean;
    specialty_group?: Array<string>;
    gender?: string;
    degree?: string;
    board_certification?: Array<string>;
    medical_group?: string;
    hospital_affiliation?: string;
    restrictions?: Array<string>;
    patient_centered_medical_home?: string;
    is_pcp?: boolean;
    education?: string;
    secondary_locations?: Array<string>;
}
