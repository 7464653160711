import { IncomeFrequencies, IncomeSourceJob } from '../../workflow-ede/apiModels/sharedModels';
import { UpdateApplicationRequest__Member__Income__CurrentIncome__Structure__JobIncome } from '../../workflow-ede/apiModels/update application/workflow-ede--update-application-request.constants';

export interface MemberIdMapping {
    firstName: string;
    lastName: string;
    birthDate: string;
}

export interface Form__Member__Income__CurrentIncome__Structure {
    incomeAmount: number;
    incomeSourceType: IncomeSourceJob;
    incomeFrequencyType: IncomeFrequencies;
    jobIncome: UpdateApplicationRequest__Member__Income__CurrentIncome__Structure__JobIncome;
    index: number;
}

export const applicationKeys = [
    'accountHolderIdentityProofedIndicator',
    'applicationAssistors',
    'applicationSignatures',
    'comments',
    'contactInformation',
    'contactMethod',
    'coverageState',
    'insuranceApplicationSecurityQuestionAnswer',
    'insuranceApplicationSecurityQuestionType',
    'legalAttestations',
    'requestingFinancialAssistanceIndicator',
    'spokenLanguageType',
    'writtenLanguageType',
];

export const memberKeys = [
    'chip',
    'family',
    'income',
    'insuranceCoverage',
    'lawfulPresence',
    'medicaid',
    'nonMagi',
    'other',
    'requestingCoverageIndicator',
];

// members.<memberId>.demographic
export const demographicKeys = [
    'americanIndianAlaskanNativeIndicator',
    'birthDate',
    'ethnicity',
    'hispanicOriginIndicator',
    'homeAddress',
    'liveOutsideStateTemporarilyIndicator',
    'mailingAddress',
    'maritalStatus',
    'name',
    'noHomeAddressIndicator',
    'otherEthnicityText',
    'otherRaceText',
    'race',
    'sex',
    'ssn',
    'ssnAlternateName',
    'transientAddress',
];

export interface FetchEligibilityRequest {
    state?: string;
    app_id?: string;
    user_name?: string;
    first_name?: string;
    last_name?: string;
    birth_date?: string;
}

export enum MetalLevelType {
    Bronze = 'Bronze',
    Gold = 'Gold',
    Silver = 'Silver',
    Platinum = 'Platinum',
    Catastrophic = 'Catastrophic',
}

export enum TrueFalseString {
    true = 'true',
    false = 'false',
}
