<card>
    <div title>
        <h6 class="t-bold" [innerHTML]="memberName"></h6>
    </div>
    <div body>
        <ng-container *ngFor="let attribute of attributes">
            <model-attribute [config]="attribute" [context]="member"> </model-attribute>
        </ng-container>
    </div>
</card>
