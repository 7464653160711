import {
    PlanCardBadge,
    PlanCardBadgeConfig,
    planCardBadgeTypesDefaults,
    planCardBadgeMetalTiers,
    PlanCardBadgeTypes,
} from './plan-card-badge.constants';
import { ZipRules } from '@zipari/design-system';
import { Plan } from '@zipari/shared-sbp-models';

export const getBadgesForPlan = (plan: Plan, config: PlanCardBadgeConfig): Array<PlanCardBadge> => {
    const badges: Array<PlanCardBadge> = [];
    const planTitleLower: string = plan.display_name && plan.display_name.toLowerCase();
    const typeConfigs = formatBadgeTypeConfig(config?.types);
    const rules = new ZipRules(plan);

    Object.values(typeConfigs).forEach((typeConfig: PlanCardBadge) => {
        const { type, conditions } = typeConfig;
        if (conditions) {
            const conditionalResult = rules.getRuleResult(conditions);
            if (conditionalResult) badges.push(typeConfigs[type]);
        } else if (planCardBadgeMetalTiers.includes(type) && planTitleLower && planTitleLower.includes(type)) {
            // Determine metal tier based on the plan display name
            badges.push(typeConfigs[type]);
        }
    });

    return badges;
};

/**
 * Add type key to the badge type config
 */
const formatBadgeTypeConfig = (rawConfigTypes: PlanCardBadgeTypes): PlanCardBadgeTypes => {
    const configTypes: PlanCardBadgeTypes = rawConfigTypes ? rawConfigTypes : {};
    const types: PlanCardBadgeTypes = Object.assign({}, planCardBadgeTypesDefaults, configTypes);
    const formattedTypes = Object.entries(types).reduce((acc, [type, value]) => {
        acc[type] = {
            ...value,
            type,
        };
        return acc;
    }, {});
    return formattedTypes;
};
