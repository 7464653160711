export const mockEnrollmentValues = {
    child_only: true,
    dependents: [
        {
            coverage_type: ['dental/vision'],
            date_of_birth: '2010-03-08',
            gender: 'female',
            home_phone: '5556667777',
            memberType: 'Dependent',
            uuid: 'e81942de-3ff7-448f-a705-946fc8beb88d',
            name: '',
            tobacco_user: undefined,
            tobacco_use: undefined,
        },
    ],
    different_mailing_address: { different_mailing_address: false },
    is_dependents: true,
    permanent_address: {
        state: 'CA',
        street_name_1: '1 primary',
        street_name_2: null,
        zip_code: '90210',
        zipcode: '90210',
        county: '12008',
        county_display: '',
        county_name: '',
    },
    mailing_address: {
        state: 'CA',
        street_name_1: '1 secondary',
        street_name_2: null,
        zip_code: '90210',
        zipcode: '90210',
        county: '12008',
        county_display: '',
        county_name: '',
    },
};

export const mockformattedMembers = [
    {
        address: { ...mockEnrollmentValues.permanent_address },
        ...mockEnrollmentValues.dependents[0],
        memberTypeUnique: 'Dependent0',
        mailing_address: { ...mockEnrollmentValues.mailing_address },
    },
];
