import Client from './Client.model';
import County from './County.model';
import Plan from './Plan.model';
import Subscriber from './Subscriber.model';

export default class IndividualQuote {
    id: number;
    client: Client;
    plans: Array<Plan>;
    subscriber: Subscriber;
    dependents: Array<Subscriber>;
    createdAt: string;
    updatedAt: string;
    data: string;
    effectiveDate: string;
    terminationDate: string;
    notes: string;
    county: County;
}
