import ClaimLine from './ClaimLine.model';
import Procedure from './Procedure.model';

export default class ClaimDetail {
    readonly id?: number;
    procedures: Array<Procedure>;
    claim_lines: Array<ClaimLine>;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    tenant?: string;
    total_allowed_amount?: number;
    total_amount_not_covered?: number;
    total_paid_amount?: number;
    total_billed_amount?: number;
    total_copay_amount?: number;
    total_coinsurance_amount?: number;
    total_deductible_amount?: number;
    total_patient_responsibility_amount?: number;
    service_from_date?: string;
    service_to_date?: string;
    statement_from_date?: string;
    statement_to_date?: string;
}
