import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthService } from '@zipari/shared-sbp-services';

import { ButtonConfigs } from './save-exit-footer.constant';

@Component({
    selector: 'save-exit-footer',
    templateUrl: './save-exit-footer.component.html',
    styleUrls: ['./save-exit-footer.component.scss'],
})
export class SaveExitFooterComponent {
    @Input()
    set configs(val) {
        this._configs = val;
    }
    @Input()
    set workflowObject(val) {
        this._workflowObject = val;
    }
    @Input()
    set showBackButton(val) {
        this._showBackButton = typeof val === 'boolean' ? val : true;
    }
    @Input()
    set isBackDisabled(val) {
        this._isBackDisabled = typeof val === 'boolean' ? val : false;
    }
    @Input()
    set isNextDisabled(val) {
        this._isNextDisabled = val;
    }
    @Input()
    set showShoppingCart(val) {
        this._showShoppingCart = val;
    }
    @Input()
    set isSubmitted(val) {
        this._isSubmitted = val;
    }

    @Output() back = new EventEmitter<any>();
    @Output() saveAndExit = new EventEmitter<any>();
    @Output() next = new EventEmitter<any>();

    _configs;
    _workflowObject;
    _showBackButton: boolean;
    _isBackDisabled: boolean;
    _isNextDisabled: boolean;
    _showShoppingCart: boolean;
    _isSubmitted: boolean;

    constructor(private authService: AuthService) {}

    get configs() {
        return this._configs;
    }

    get workflowObject() {
        return this._workflowObject;
    }

    get showBackButton(): boolean {
        return !!this._showBackButton;
    }

    get isBackDisabled(): boolean {
        return !!this._isBackDisabled;
    }

    get isNextDisabled(): boolean {
        return !!this._isNextDisabled;
    }

    get showShoppingCart(): boolean {
        return !!this._showShoppingCart;
    }

    get isSubmitted(): boolean {
        return !!this._isSubmitted;
    }

    get backButtonConfig() {
        return this.configs?.footer?.backButton ? this.configs.footer.backButton : ButtonConfigs.footer.backButton;
    }

    get saveAndExitConfig() {
        return this.configs?.footer?.saveAndExit ? this.configs.footer.saveAndExit : ButtonConfigs.footer.saveAndExit;
    }

    get nextButtonConfig() {
        return this.configs?.footer?.nextButton ? this.configs.footer.nextButton : ButtonConfigs.footer.nextButton;
    }

    get isAuthenticated() {
        return !!this.authService.loggedInUser;
    }

    get justifyEnd(): boolean {
        return this.configs.hideBackButton || !this.showBackButton;
    }
}
