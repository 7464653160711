import Address from './Address.model';
import Contact from './Contact.model';
import Plan from './Plan.model';

export interface Pcp {
    pcpSearchDistancep?: number;
    pcpSearchZipcode?: string;
    subscriber?: PcpData;
    spouse?: PcpData;
    dependents?: PcpData[];
}

export interface PcpData {
    accepting_new_patients?: boolean;
    address?: Address;
    contacts?: Contact;
    distance?: string;
    first_name: string;
    gender: string;
    id: number;
    is_pcp: boolean;
    last_name: string;
    medical_group?: string;
    network_code?: string;
    network_specific_id?: string;
    npi?: string;
    pcpName?: { last: string; first: string };
    phone?: string;
    picture?: string;
    plans?: Plan[];
    primary_specialty?: string;
    provider_id?: number;
    provider_service_restrictions: string[];
    selected?: true;
    tax_id?: string;
    tenant?: string;
}
