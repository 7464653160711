import { ChangeDetectorRef, Component, DoCheck, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneObject } from '@zipari/web-utils';
import { FormControlService } from '@zipari/design-system';
import { BaseCVAComponent } from './../base-cva.component';
import { confirmPasswordConfig, enabledRules, passwordConfig } from './passwords.constants';

@Component({
    selector: 'passwords',
    templateUrl: './passwords.component.html',
    styleUrls: ['./passwords.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordsComponent),
            multi: true,
        },
    ],
})
export class PasswordsComponent extends BaseCVAComponent implements OnInit, OnDestroy, DoCheck {
    @Input() group;
    @Input() config;

    passwordConfig = passwordConfig;
    confirmPasswordConfig = confirmPasswordConfig;
    enabledRules = enabledRules;

    disabled;

    constructor(private cdr: ChangeDetectorRef, private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.overrideDefaultConfigs();
        this.formControlService.addControlToFormGroup(this.group, this.passwordConfig);
        this.formControlService.addControlToFormGroup(this.group, this.confirmPasswordConfig);
        this.updateRules();

        this.group.controls[this.passwordConfig.prop].valueChanges.subscribe((val) => {
            this.updateRules(val);
            this.manageCustomError(!!this.passwordConfig.focusDisplay.rules.filter((rule) => !rule.value).length);
        });

        this.handlePasswordsGettingDisabled();
    }

    ngDoCheck() {
        if (this.disabled !== this.config.isDisabled) {
            this.handlePasswordsGettingDisabled();
        }
    }

    handlePasswordsGettingDisabled() {
        this.disabled = this.config.isDisabled;

        this.passwordConfig['isDisabled'] = this.disabled;
        this.confirmPasswordConfig['isDisabled'] = this.disabled;
    }

    updateRules(val = '') {
        const formattedRules = cloneObject(this.enabledRules);

        this.passwordConfig.focusDisplay.rules = formattedRules.map((rule) => {
            switch (rule.name) {
                case 'minCount':
                    if (val) {
                        rule.value = val.length >= rule.dependency;
                    }
                    break;
                case 'lowercase':
                    const lowercaseReg = `[a-z]{1,${rule.dependency}}`;
                    const regLower = new RegExp(lowercaseReg, 'g');
                    rule.value = regLower.test(val);
                    break;
                case 'uppercase':
                    const uppercaseReg = `[A-Z]{1,${rule.dependency}}`;
                    const regUpper = new RegExp(uppercaseReg);
                    rule.value = regUpper.test(val);
                    break;
                case 'specialChar':
                    const symbols = rule.dependency.map((symbol) => `\\${symbol}`).join(',');
                    const specialCharReg = `[${symbols}]{1,1}`;
                    const specialReg = new RegExp(specialCharReg, 'g');
                    rule.value = specialReg.test(val);
                    break;
                case 'number':
                    const numberReg = `[0-9]{1,${rule.dependency}}`;
                    const regNumber = new RegExp(numberReg, 'g');
                    rule.value = regNumber.test(val);
                    break;
            }

            return rule;
        });
    }

    overrideDefaultConfigs() {
        if (this.config.rules) {
            this.enabledRules = cloneObject(this.config.rules);
        }

        if (this.config.description) {
            this.passwordConfig.focusDisplay.description = this.config.description;
        }

        if (this.config.passwordConfig) {
            this.passwordConfig = cloneObject(this.config.passwordConfig);
        }

        if (this.config.confirmPasswordConfig) {
            this.confirmPasswordConfig = cloneObject(this.config.confirmPasswordConfig);
        }
    }

    manageCustomError(val) {
        if (val) {
            this.passwordControl.setErrors({ customErrMessage: true });
        } else {
            this.passwordControl.setErrors(null);
        }

        // intentional cdr because we are directly changing the error messages
        if (this.cdr) {
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public get passwordControl() {
        return this.group.controls[this.passwordConfig.prop];
    }
}
