export enum CoveredMemberKey {
    childonly = 'child_only',
    stepParents = 'stepParents',
    children = 'children',
    subscriber = 'subscriber',
    spouse = 'spouse',
    family = 'family',
    parent = 'parent',
    dependents = 'dependents',
}

export class MemberCoverageOption {
    icon?: string;
    value?: string;
    cards?: Array<string>;
    label?: string;
}

export interface CoverageFormConfig {
    zipcode?: ZipCodeFieldConfig;
    county?: CountyFieldConfig;
    coverage_type?: CoverageTypeConfig;
    coverage_types?: CoverageTypeConfig;
}

interface ZipCodeFieldConfig {
    mask: string;
    errors: Object;
    prop: string;
    type: string;
    validators: Array<string>;
    enterAnother: string;
    label: string;
    maxLength: number;
}

interface CountyFieldConfig {
    options: Array<any>;
    prop: string;
    type: string;
    label: string;
    errors: Object;
    validators: string[];
}

interface CoverageTypeConfig {
    options: Array<any>;
    prop: string;
    type: string;
    conditional: Object;
    validators: Array<string>;
    default: string;
    label: string;
}

export interface StateNotLicensedErrorConfig {
    enabled: boolean;
    message: string;
}

export const defaultErrorMessages = {
    invalidZipcode: 'Please enter a valid ZIP Code',
    noCountyForSelectedZipcode: 'There are no plans offered in this ZIP Code',
    noPlansForSelectedCounty: 'There are no plans offered in this county',
    noPlansForEffectiveDate: 'There are no plans offered for the selected effective date.',
};

export const coverageFormControlFieldConfig = {
    county: 'county',
    zipcode: 'zipcode',
    getStarted: 'getStarted',
    coverageEffectiveDate: 'coverage_effective_date',
    coverageType: 'coverage_types',
};
