import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from '@zipari/shared-sbp-services';
import { NotificationModel, notificationSubtypeToIconMapping } from './notifications.constant';
import { NotificationsService } from './notifications.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    @Input() showHeader = true;
    @Input() allowPaging = false;
    @Input() latestNotifications: Array<NotificationModel>;

    notifications: Array<NotificationModel> = [];
    notificationSubtypeToIconMapping = notificationSubtypeToIconMapping;

    constructor(
        public router: Router,
        public notificationsService: NotificationsService,
        public http: HttpClient,
        public configService: ConfigService
    ) {}

    ngOnInit() {
        const notificationsConfig: any = this.configService.getPageConfig('notifications');

        // add new mappings for overriding default icons
        if (notificationsConfig && notificationsConfig['mapping']) {
            Object.assign(this.notificationSubtypeToIconMapping, notificationsConfig['mapping']);
        }

        if (this.latestNotifications) {
            this.notifications = this.latestNotifications;
        } else {
            this.notificationsService.newNotifications.subscribe((notifications: any) => {
                this.notifications = notifications;
            });

            this.notificationsService.retrieveNotifications();
        }
    }
}
