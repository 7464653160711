import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Attestation } from './attestations.constants';

@Component({
    selector: 'attestation-card',
    templateUrl: './attestation-card.component.html',
    styleUrls: ['./attestation-card.component.scss'],
})
export class AttestationCardComponent {
    @Input() attestations: Array<Attestation>;
    @Input() hideFormHeader: boolean = false;
    @Output() attestedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _attested = false;

    @Input()
    public set attested(val: boolean) {
        this._attested = val;
    }

    public get attested() {
        return this._attested;
    }

    public updateValidity(attestationForm: NgForm): void {
        const hasCheckedAttestations = attestationForm.valid;
        this.attested = hasCheckedAttestations;
        this.attestedChange.emit(hasCheckedAttestations);
    }
}
