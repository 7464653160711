<section class="plan-compare">
    <header class="plan-compare__header">
        <h6 class="t-bold">{{ compareTitle }}</h6>
    </header>

    <div class="plan-compare__plan" *ngIf="comparedPlans.length === 0; else compareChips">
        <p class="t-data">{{ compareMessage }}</p>
    </div>

    <ng-template #compareChips>
        <div class="plan-compare__plan plan-compare__plan--chip" *ngFor="let plan of comparedPlans">
            <p class="t-data t-bold">{{ plan.display_name }}</p>
            <zip-button
                *ngIf="!isPlanLocked(plan)"
                [config]="{
                    level: 'text',
                    icon: 'close'
                }"
                (zipButtonClicked)="removePlanFromComparison(plan)"
            >
            </zip-button>
        </div>

        <div class="plan-compare__plan plan-compare__plan--empty" *ngFor="let placeholder of placeholders | slice : comparedPlans.length">
            <p class="t-data">{{ comparePlaceholder }}</p>
        </div>
    </ng-template>

    <ng-container *ngIf="comparedPlans.length > 1">
        <zip-button [config]="compareButtonConfig" (zipButtonClicked)="showCompareTable()"></zip-button>
    </ng-container>
</section>
