import { ButtonConfig } from '@zipari/shared-ds-util-button';

export class PlanAddOn {
    type: string;
    label?: string;
    static_price: number;
}

export class Benefit {
    label?: string;
    value?: string;
    priority_weighting?: number;

    /** A special type that can be used to handle custom benefit handling
     * EX. benefit type "link" converts that benefit into a link. Generally these have values of urls
     * */
    type?: string;
}

export class WorkflowPlanStructure {
    [planType: string]: Plan;
}

export class HiosId {
    hios_id?: string;
    rating_region?: number;
}

export class RatingRegionObj {
    plan_year?: number;
    region_name: number;
}

export enum PlanTypes {
    health = 'health',
    medical = 'medical',
    dental = 'dental',
    vision = 'vision',
    bundled = 'bundled',
    medicare_supplemental = 'medicare_supplemental',
    mapd = 'mapd',
    medicare_part_d = 'medicare_part_d',
    medicare_advantage = 'medicare_advantage',
    d_snp = 'd_snp',
}

export default class Plan {
    percent_of_premium?: any;
    networks?: Array<any>;
    rating_region?: Array<RatingRegionObj>;
    plan_benefits?: any;
    hios_ids?: Array<HiosId>;
    primary_benefits?: Array<Benefit>;
    add_ons?: Array<PlanAddOn>;
    benefits?: Array<Benefit>;
    formattedBenefits?: Array<Benefit>;
    comparing?: boolean;
    data?: any;
    deductible?: number;
    display_name?: string;
    readonly documents?: any;
    external_id?: string;
    hsa?: boolean;
    hmo?: boolean;
    readonly id?: number;
    is_active?: boolean;
    is_benchmark?: boolean;
    market_segment?: string;
    max_out_of_pocket?: number;
    max_out_of_pocket_family?: number;
    metal_tier?: string;
    plan_type?: PlanTypes;
    plan_variation?: string;
    price?: string;
    selected?: boolean;
    star_rating?: string;
    static_price?: number;
    waiting_period?: number;
    year?: number;
    readonly zipcodes?: Array<any>;
    index?: number;
    isOnExchange?: boolean;
    weight?: any;
    weight_description?: string;
    plan_marketing_description?: string;
    internal_name?: any;
    links?: Array<{
        icon: string;
        label: string;
        type: string;
        url: string;
    }>;

    /** internal value setup within FE */
    discountedPrice?: string;
    /** internal value setup within FE */

    constructor(options) {
        Object.assign(this, options);
    }
}

export interface PlanModalContent {
    paragraphs?: string[];
    buttons: {
        close?: ButtonConfig;
        open?: ButtonConfig;
    };
    link?: string;
}

//Key type case written is same as which is comming from response.
//The interface key name are based on the response
export interface PlanCxContext {
    deductible: number;
    display_name: string;
    external_id: string;
    index: number;
    isHMO: boolean;
    max_out_of_pocket: number;
    max_out_of_pocket_family: number;
    metal_tier: string;
    numOfApplicants: number;
    plan_type: string;
    price: string;
}

export interface PlanIconConfig {
    type?: string;
    ariaLabel?: string;
}

export interface PlanOriginalPrice {
    fallback?: string[];
    format?: string;
    label?: string;
    prop?: string;
    value?: string;
    discountedPrice?: string;
    originalPrice?: string;
}

export interface GetStartedFormValue {
    zipcode: string;
    county: string;
    coverage_type?: string[];
    whichEnrollment?: string;
    coverageEffectiveDate?: string;
}

export interface MultiPlanTypes {
    label?: string;
    conditional?: Object;
    prop?: string;
}
