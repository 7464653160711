<section class="modal__mask">
    <section class="modal custom--modal modal--small" @fade>
        <div class="modal__header">
            <h6 class="t-data t-bold">Verify Address</h6>
        </div>

        <div class="modal__body">
            <p class="address-modal__description t-caption">
                The U.S. Postal Service (USPS) returned different information on your address. Select the correct address below.
            </p>
            <form #addressSelect="ngForm">
                <div *ngIf="showAddressSelection; else noAddressSelection">
                    <ng-container *ngTemplateOutlet="radio; context: { address: verified, isVerified: true }"></ng-container>
                    <ng-container *ngTemplateOutlet="radio; context: { address: current, isVerified: false }"></ng-container>

                    <ng-template #radio let-address="address" let-verified="isVerified">
                        <div class="address-select__wrapper">
                            <p class="t-bold">{{ verified ? 'USPS Record:' : 'Your Selection:' }}</p>
                            <label class="form-control--radio__wrapper">
                                <input
                                    class="form-control form-control--radio"
                                    name="select"
                                    type="radio"
                                    ngModel
                                    [value]="{ address: address, verified: verified }"
                                    (change)="onSelectChange($event)"
                                    required
                                />

                                <div class="address-select__detail-wrapper">
                                    <p>{{ address.address1 || address.address_1 || address.street_name_1 || address.streetName1 }}</p>
                                    <p>{{ address.address2 || address.address_2 || address.street_name_2 || address.streetName2 }}</p>
                                    <p>
                                        {{ address.city || address.cityName || address.city_name }},
                                        {{ address.state || address.stateCode }}
                                        {{ address.zip_code || address.zipcode || address.zipCode }}
                                    </p>
                                </div>
                            </label>
                        </div>
                    </ng-template>
                </div>

                <ng-template #noAddressSelection>
                    <div>
                        <ng-container *ngTemplateOutlet="radio; context: { address: verified, isVerified: true }"></ng-container>

                        <ng-template #radio let-address="address" let-verified="isVerified">
                            <div class="address-select__wrapper">
                                <p class="t-bold">{{ verified ? 'USPS Record:' : 'Your Selection:' }}</p>
                                <label class="form-control--radio__wrapper">
                                    <input
                                        class="form-control form-control--radio"
                                        name="select"
                                        type="radio"
                                        ngModel
                                        [value]="{ address: address, verified: verified }"
                                        (change)="onSelectChange($event)"
                                        required
                                    />

                                    <div class="address-select__detail-wrapper">
                                        <p>{{ address.address1 || address.address_1 || address.street_name_1 || address.streetName1 }}</p>
                                        <p>{{ address.address2 || address.address_2 || address.street_name_2 || address.streetName2 }}</p>
                                        <p>
                                            {{ address.city || address.cityName }}, {{ address.state || address.stateCode }}
                                            {{ address.zip_code || address.zipcode || address.zipCode }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </ng-template>
                    </div>
                </ng-template>
            </form>
            <ng-container *ngTemplateOutlet="selectionMessage"></ng-container>
        </div>

        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </section>
</section>

<ng-template #selectionMessage>
    <div *ngIf="addressSelect.valid && !isVerifiedSelection && selectedAddressErrorMessage?.body">
        <p class="address-select__selection-message t-caption t-error" [innerHTML]="selectedAddressErrorMessage?.body"></p>
    </div>
</ng-template>

<ng-template #footer>
    <section class="modal__footer u-fillRemaining">
        <div class="u-flex" *ngIf="canCancel">
            <zip-button (zipButtonClicked)="resolveAddress(false)" level="high" content="Cancel"> </zip-button>
        </div>
        <div class="u-flex">
            <zip-button (zipButtonClicked)="resolveAddress(true)" [disabled]="!addressSelect.valid" level="high" content="Save & Continue">
            </zip-button>
        </div>
    </section>
</ng-template>
