import { WorkflowStates } from '@zipari/shared-sbp-models';

export const mockWorkflow = {
    values: {
        demographics: {
            county: '06037',
            is_sep: false,
            zipcode: 90210,
            child_only: false,
            dependents: [],
            county_code: '67',
            state: 'los',
            coverage_type: 'dental/vision',
            total_members: 1,
            whose_covered: 'subscriber',
            coverage_effective_date: '2024-01-01',
        },
        coverage_type: 'dental/vision',
        is_dependents: false,
        total_members: 1,
        whose_covered: 'subscriber',
        coverage_types: ['dental/vision'],
        coverage_effective_date: '2024-01-01',
    },
    steps: [
        {
            id: 2836160,
            locked: false,
            values: {
                demographics: {
                    county: '06037',
                    is_sep: false,
                    zipcode: '90210',
                    coverage_type: 'dental/vision',
                    total_members: 1,
                    whose_covered: 'subscriber',
                    displayCoverageType: 'Dental and/or Vision',
                    coverage_effective_date: '2024-01-01',
                },
                coverage_type: 'dental/vision',
                is_dependents: false,
                total_members: 1,
                whose_covered: 'subscriber',
                coverage_types: ['dental/vision'],
            },
            schema: {
                id: 694,
                workflow: 'bp_individual_enrollment',
                step_number: 1,
                type: 'eligibility',
                label: 'Demographics',
                key: 'eligibility__information',
                description:
                    'Before we can enroll the subscriber, we need to know when they will need their coverage to start, and what their relationship is to your client.',
            },
            step_number: 1,
            state: WorkflowStates.COMPLETE,
            workflow: 2,
        },
        {
            id: 2836160,
            locked: false,
            values: {
                demographics: {
                    county: '06037',
                    is_sep: false,
                    zipcode: '90210',
                    coverage_type: 'dental/vision',
                    total_members: 1,
                    whose_covered: 'subscriber',
                    displayCoverageType: 'Dental and/or Vision',
                    coverage_effective_date: '2024-01-01',
                },
                coverage_type: 'dental/vision',
                is_dependents: false,
                total_members: 1,
                whose_covered: 'subscriber',
                coverage_types: ['dental/vision'],
            },
            schema: {
                id: 694,
                workflow: 'bp_individual_enrollment',
                step_number: 1,
                type: 'eligibility',
                label: 'Choose Plan',
                key: 'eligibility__information',
                description:
                    'Before we can enroll the subscriber, we need to know when they will need their coverage to start, and what their relationship is to your client.',
            },
            step_number: 1,
            state: WorkflowStates.SKIPPED,
            workflow: 2,
        },
        {
            id: 2836162,
            state: WorkflowStates.COMPLETE,
            workflow: 2,
            locked: false,
            values: {
                onExchange: false,
                offExchange: true,
            },
            schema: {
                id: 719,
                workflow: 'bp_individual_enrollment',
                step_number: 3,
                type: 'dental_vision',
                label: 'Specialty Plan',
                key: 'dental_vision',
                description: '',
            },
            step_number: 3,
        },
    ],
};

export const mockMedicalWorkflow = {
    ...mockWorkflow,
    values: {
        ...mockWorkflow.values,
        demographics: { ...mockWorkflow.values.demographics, coverage_type: 'medical' },
    },
};
