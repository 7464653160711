import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormControlService, FormControlValidators } from '@zipari/design-system';
import { cloneObject, deepCompare, deepMerge, getValue, isEmptyObj } from '@zipari/web-utils';
import { coveredCards, hideMemberOptionsMap } from '@zipari/shared-sbp-constants';
import { defaultOptionsConfig, CoveredMemberKey } from '@zipari/shared-sbp-enrollment';
import { ConfigService } from '@zipari/shared-sbp-services';
import {
    childOnlyConfig,
    dependentConfig,
    spouseConfig,
    parentDependentConfig,
    QuoteEditPanelSectionTypes,
    subscriberConfig,
} from '@zipari/shared-sbp-modules';
import { MemberType } from '@zipari/shared-sbp-templates';
import { concat, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'covered-members',
    templateUrl: './covered-members.component.html',
    styleUrls: ['./covered-members.component.scss'],
})
export class CoveredMembersComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean;
    @Input() formGroup: FormGroup;
    @Input() allowAddOrRemoveDependents: boolean;
    @Input() allowAddOrRemoveSpouse: boolean;
    @Input() removeMemberIcon: string = 'delete';
    @Input() workflowValues;
    @Input() coveredMembersFormConfig;
    @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() hasChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() afterFormInit: EventEmitter<null> = new EventEmitter<null>();

    hasParentDependents: boolean;
    cardsConfig;
    demographicsConfig;
    optionsConfig;
    coverageConfig;
    formHasChangesSub: Subscription;
    formValidSub: Subscription;
    originalValues;
    memberCoverageKeys: Array<string>;
    memberCoverageOption;
    subscriberDateOfBirth;
    subscriberMinAgeMsg;
    spouseDateOfBirth;
    childLimit = 8;
    parentLimit: number = 8;
    addAnotherChildLabel: string = 'Add Child';
    addAnotherParentLabel: string = 'Add Parent(s)/Step-Parent(s))';

    constructor(private configService: ConfigService, private formControlService: FormControlService, private cdr: ChangeDetectorRef) {}

    public get valid(): boolean {
        this.cdr.detectChanges();
        return this.formGroup.valid;
    }

    get memberChildrenControls(): any[] {
        return this.formGroup.get(CoveredMemberKey.children) ? this.formGroup.get(CoveredMemberKey.children)['controls'] : [];
    }

    get memberParentStepParentControls(): any[] {
        return this.formGroup.get(CoveredMemberKey.stepParents) ? this.formGroup.get(CoveredMemberKey.stepParents)['controls'] : [];
    }

    public get maxAgeForSubscriber() {
        return (
            !!this.subscriberDateOfBirth &&
            !!this.subscriberDateOfBirth.errors &&
            !!this.subscriberDateOfBirth.errors.maxAge &&
            (this.subscriberDateOfBirth.touched || this.subscriberDateOfBirth.dirty)
        );
    }

    public get isSubscriberOverSixtyFive(): boolean {
        const isMaxAgeAllowed: boolean =
            !!getValue(this.cardsConfig, 'subscriber.maxAgeAllowed') || !!getValue(this.coverageConfig, 'subscriber.maxAgeAllowed');
        if (this.subscriberDateOfBirth && this.subscriberDateOfBirth.valid) {
            return isMaxAgeAllowed ? this.getAge(this.subscriberDateOfBirth.value) >= 65 : false;
        }
        return false;
    }

    public get maxAgeForSpouse() {
        return !!this.spouseDateOfBirth && !!this.spouseDateOfBirth.errors && !!this.spouseDateOfBirth.errors.maxAge;
    }

    public get isSpouseOverSixtyFive(): boolean {
        const isMaxAgeAllowed: boolean =
            !!getValue(this.coverageConfig, 'spouse.maxAgeAllowed') || !!getValue(this.coverageConfig, 'spouse.maxAgeAllowed');
        if (this.spouseDateOfBirth.valid) {
            return isMaxAgeAllowed ? this.getAge(this.spouseDateOfBirth.value) >= 65 : false;
        }
    }

    ngOnInit() {
        this.retrieveConfig();
        this.handlePrefillingComponentValues();
        this.setupFormValidSub();
        this.setupFormHasChangesSub();
    }

    ngOnDestroy() {
        const subscriptions = [this.formValidSub, this.formHasChangesSub];
        subscriptions.forEach((sub: Subscription) => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    setupFormValidSub() {
        this.formValidSub = concat(of(this.formGroup.status), this.formGroup.statusChanges)
            .pipe(
                map((status) => this.valid),
                distinctUntilChanged()
            )
            .subscribe((valid) => {
                return this.formValid.emit(valid);
            });
    }

    /**
     * Emit true if any value is different than its original, pre-populated value
     */
    setupFormHasChangesSub() {
        this.originalValues = this.formGroup.value;

        this.formHasChangesSub = concat(of(this.formGroup.value), this.formGroup.valueChanges)
            .pipe(
                map((value) => !deepCompare(this.originalValues, value)),
                distinctUntilChanged()
            )
            .subscribe((hasChanges) => this.hasChanges.emit(hasChanges));
        this.afterFormInit.emit(null);
    }

    setupValidations() {
        // handle subscriber and spouse validations
        this.subscriberDateOfBirth = getValue(this.formGroup, 'controls.subscriber.controls.date_of_birth');

        if (this.subscriberDateOfBirth && this.memberCoverageOption && this.memberCoverageOption.value === CoveredMemberKey.subscriber) {
            this.subscriberDateOfBirth.valueChanges.subscribe((val) => {
                const ADULT_AGE = 18;
                const date = new Date();
                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear() - ADULT_AGE;
                const minDate = new Date(year, month, day);

                this.subscriberMinAgeMsg =
                    val && !getValue(this.subscriberDateOfBirth, 'errors.reasonableDate')
                        ? !!!FormControlValidators.minDate(minDate, 'minAge')(this.subscriberDateOfBirth)
                        : null;
            });
        } else {
            this.subscriberMinAgeMsg = null;
        }

        this.spouseDateOfBirth = getValue(this.formGroup, 'controls.spouse.controls.date_of_birth');
    }

    handlePrefillingComponentValues() {
        if (this.memberCoverageOption && !isEmptyObj(this.memberCoverageOption)) {
            this.buildCoveredMembersForm(this.memberCoverageOption, this.formGroup.value);
        } else {
            this.buildCoveredMembersForm(this.determineCoverageOption());
        }

        // set member coverage keys
        this.memberCoverageKeys = this.determineMemberOptions(this.memberCoverageOption.value, false, this.optionsConfig);
        if (this.workflowValues && this.workflowValues.demographics) {
            if (this.workflowValues.subscriber && this.formGroup.controls.subscriber) {
                this.formGroup.controls.subscriber.patchValue(this.workflowValues.subscriber);
                this.subscriberDateOfBirth.markAsTouched();
                this.subscriberDateOfBirth.updateValueAndValidity();
            }

            if (this.workflowValues.dependents) {
                this.formGroup.removeControl(CoveredMemberKey.children);
                this.formGroup.addControl(CoveredMemberKey.children, new FormArray([]));

                this.formGroup.removeControl(CoveredMemberKey.stepParents);
                this.formGroup.addControl(CoveredMemberKey.stepParents, new FormArray([]));

                const selectedCard = this.memberCoverageOption.cards.find(
                    (card) => card === CoveredMemberKey.childonly || card === CoveredMemberKey.children
                );

                // used to check for child and child only depend exist on the member ooptions and retirning the card value

                this.workflowValues?.dependents.forEach((dependent, ind) => {
                    if (dependent.memberType === MemberType.step_parent_dependent) {
                        const parentFormGroup = this.addDependent(CoveredMemberKey.stepParents, dependent);
                        parentFormGroup && parentFormGroup.markAllAsTouched();
                    } else {
                        const childFormGroup = this.addDependent(selectedCard, dependent);
                        childFormGroup && childFormGroup.markAllAsTouched();
                    }
                    // Mark child form group as touched to display errors when init with bad data
                });
            }

            if (this.workflowValues.spouse && !isEmptyObj(this.workflowValues.spouse)) {
                this.addSpouseControls();
                this.formGroup.controls.spouse.patchValue(this.workflowValues.spouse);
                this.spouseDateOfBirth.markAsTouched();
                this.spouseDateOfBirth.updateValueAndValidity();
            }
        }
        this.updateFormErrors();
        this.handlePrefillingValidation();
    }

    getAge(dob: string): number {
        const today: Date = new Date();
        const birthDate: Date = new Date(dob);
        let age: number = today.getFullYear() - birthDate.getFullYear();
        const month: number = today.getMonth() - birthDate.getMonth();

        // due to how we format date values we need to compare UTC date to normal date. birthDate comes from FormControls
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getUTCDate())) {
            age -= 1;
        }

        return age;
    }

    public addCardToForm(card) {
        if (card === CoveredMemberKey.children || card === CoveredMemberKey.childonly) {
            this.formGroup.addControl(CoveredMemberKey.children, new FormArray([]));
            this.addDependent(card);
        } else if (card === CoveredMemberKey.parent) {
            this.formGroup.addControl(CoveredMemberKey.stepParents, new FormArray([]));
            this.addDependent(CoveredMemberKey.stepParents);
        } else {
            this.formGroup.addControl(card, new FormGroup({}));

            this.cardsConfig[card].controls.forEach((control) => {
                this.formControlService.addControlToFormGroup(this.formGroup.get(card), control);
            });
        }
    }

    public addDependent(whichCard, context = {}): FormGroup {
        const coveredDependentKey = whichCard === CoveredMemberKey.childonly ? CoveredMemberKey.children : whichCard;
        const dependentAddLimit = coveredDependentKey === CoveredMemberKey.children ? this.childLimit : this.parentLimit;

        if (!this.formGroup.get(coveredDependentKey) || this.formGroup.get(coveredDependentKey).value.length < dependentAddLimit) {
            const dependent = new FormGroup({});

            this.cardsConfig[whichCard].controls.forEach((control) => {
                this.formControlService.addControlToFormGroup(dependent, control, context);
            });

            // Dependents have uuid as a hidden field, to correlate them with dependent data in the workflow values
            this.formControlService.addControlToFormGroup(dependent, { prop: 'uuid' }, context);

            if (!this.formGroup.get(coveredDependentKey)) {
                this.formGroup.addControl(coveredDependentKey, new FormArray([]));
            }
            const dependentFormArray: FormArray = <FormArray>this.formGroup.get(coveredDependentKey);
            dependentFormArray.push(dependent);
            return dependent;
        }
        return null;
    }

    public removeDependent(dependent: FormArray, index: number) {
        dependent.removeAt(index);
    }

    public removeSpouse() {
        this.formGroup.removeControl(CoveredMemberKey.spouse);
    }

    public addSpouseControls() {
        if (!this.formGroup.get(CoveredMemberKey.spouse)) {
            this.addCardToForm(CoveredMemberKey.spouse);
            this.spouseDateOfBirth = getValue(this.formGroup, 'controls.spouse.controls.date_of_birth');
        }
    }

    private updateFormErrors() {
        if (
            !this.formGroup.controls.hasOwnProperty(CoveredMemberKey.subscriber) ||
            !this.formGroup.controls.hasOwnProperty(CoveredMemberKey.children) ||
            !this.formGroup.controls.hasOwnProperty(CoveredMemberKey.stepParents)
        ) {
            this.formGroup.setErrors({ no_member_selected: true });
        }

        if (
            !this.cardsConfig.subscriber ||
            this.formGroup.controls.hasOwnProperty(CoveredMemberKey.subscriber) ||
            this.formGroup.controls.hasOwnProperty(CoveredMemberKey.children)
        ) {
            this.formGroup.setErrors(null);
        }
    }

    handlePrefillingValidation() {
        Object.keys(this.formGroup.controls).forEach((control) => {
            if (!!this.optionsConfig[control]) {
                let prop = this.formGroup.controls[control];
                if (!!prop) {
                    Object.keys(prop.value).forEach((key) => {
                        if (!prop.get(key).value) {
                            prop.get(key).markAsTouched();
                            prop.get(key).updateValueAndValidity();
                        }
                    });
                }
            }
        });
    }

    private determineMemberOptions(currentMemberOption, disableMemberCoverageOptionsThatChangeSubscriber: boolean, options): Array<string> {
        const memberKeys = Object.keys(options);

        // I really would like to think of a better way to do this
        // business case is that we need to disable changes to member coverage options that will change who the
        // subscriber is EX. child only adding a subscriber AS well as the dependent.
        if (currentMemberOption && disableMemberCoverageOptionsThatChangeSubscriber) {
            return memberKeys.filter((memberKey) => !hideMemberOptionsMap[currentMemberOption][memberKey]);
        } else {
            return memberKeys;
        }
    }

    private buildCoveredMembersForm(option, initialValue?) {
        this.memberCoverageOption = option;

        this.memberCoverageOption.cards.forEach((card) => {
            switch (this.memberCoverageOption.value) {
                case this.optionsConfig.spouse.value:
                case this.optionsConfig.family.value:
                    this.formGroup.removeControl(CoveredMemberKey.children);
                    break;
                case this.optionsConfig.subscriber.value:
                case this.optionsConfig.parent.value:
                    this.formGroup.removeControl(CoveredMemberKey.children);
                    this.formGroup.removeControl(CoveredMemberKey.spouse);
                    break;
                case this.optionsConfig.children.value:
                    this.formGroup.removeControl(CoveredMemberKey.children);
                    this.formGroup.removeControl(CoveredMemberKey.subscriber);
                    this.formGroup.removeControl(CoveredMemberKey.spouse);
                    break;
            }

            // setting card for stepParent

            card === CoveredMemberKey.parent ? CoveredMemberKey.stepParents : card;

            /* If the card exists in the cardsConfig, then add the form controls.
            card can be one of "subscriber", "spouse", "children" or "child_only" */

            if (this.cardsConfig[card]) {
                this.addCardToForm(card);
            }
        });

        // coverage effective date is used for minimum age validations
        this.formGroup.addControl('coverage_effective_date', new FormControl(this.workflowValues.coverage_effective_date));

        if (!!initialValue) {
            this.formGroup.patchValue(initialValue);
        }

        this.setupValidations();

        if (this.subscriberDateOfBirth) {
            this.subscriberDateOfBirth.updateValueAndValidity();
        }

        if (this.spouseDateOfBirth) {
            this.spouseDateOfBirth.updateValueAndValidity();
        }
    }

    private retrieveConfig(): void {
        const isBrokerPortal = this.configService.appRoute === 'broker-portal';
        const enrollmentConfig = this.configService.getPageConfig('enrollment');
        this.demographicsConfig = getValue(enrollmentConfig, 'demographics');

        if (isBrokerPortal) {
            this.setupBrokerPortalCoverageFormConfig(enrollmentConfig);
        } else {
            this.setupShoppingFormConfig(this.demographicsConfig);
        }
        // Subscriber can be set to null to not display, filter it out to prevent errors
        this.cardsConfig = Object.entries(this.cardsConfig).reduce((acc, [memberKey, member]) => {
            if (member) acc[memberKey] = member;
            return acc;
        }, {});

        this.cardsConfig = this.setDisabledConfig(this.cardsConfig);
    }

    private setupShoppingFormConfig(demographicsConfig) {
        this.memberCoverageOption = this.workflowValues.memberCoverageOption;
        this.optionsConfig = getValue(demographicsConfig, 'members.options');
        this.childLimit = demographicsConfig.childLimit || this.childLimit;

        this.parentLimit = demographicsConfig.parentLimit || this.parentLimit;

        this.cardsConfig = this.coveredMembersFormConfig || getValue(demographicsConfig, 'members.cards');

        this.coverageConfig = getValue(demographicsConfig, 'coverage');
        this.hasParentDependents = this.configService.getParentDependentEnabled();
    }

    private setupBrokerPortalCoverageFormConfig(enrollmentConfig) {
        this.optionsConfig = defaultOptionsConfig;
        const whoseCovered: string = this.workflowValues.whose_covered || this.workflowValues.coverage.whoseCovered.whose_covered;
        this.memberCoverageOption = defaultOptionsConfig[whoseCovered];
        const eligibilitySteps = getValue(enrollmentConfig, 'eligibility.steps');
        const eligibilityStepsWhoseCoveredForm =
            this.coveredMembersFormConfig || eligibilitySteps?.find((step) => step.id === QuoteEditPanelSectionTypes.coverage)?.form?.[0];

        this.childLimit = eligibilityStepsWhoseCoveredForm.childLimit || this.childLimit;
        this.parentLimit = eligibilityStepsWhoseCoveredForm.parentLimit || this.parentLimit;

        this.cardsConfig = {
            subscriber: deepMerge(subscriberConfig, eligibilityStepsWhoseCoveredForm.subscriberOptions),
            spouse: deepMerge(spouseConfig, eligibilityStepsWhoseCoveredForm.spouseOptions),
            child_only: deepMerge(childOnlyConfig, eligibilityStepsWhoseCoveredForm.childOnlyOptions),
            children: deepMerge(dependentConfig, eligibilityStepsWhoseCoveredForm.depOptions),
            stepParents: deepMerge(parentDependentConfig, eligibilityStepsWhoseCoveredForm.parentDepOptions),
        };

        if (eligibilityStepsWhoseCoveredForm.subscriber || eligibilityStepsWhoseCoveredForm.subscriberOptions) {
            this.cardsConfig.subscriber = deepMerge(subscriberConfig, eligibilityStepsWhoseCoveredForm.subscriberOptions);
        }

        this.addExtraConfigs(eligibilityStepsWhoseCoveredForm.extraConfigs);

        this.addAnotherChildLabel = this.demographicsConfig?.text?.add_another_child || this.addAnotherChildLabel;
        this.addAnotherParentLabel = this.demographicsConfig?.text?.add_another_parent || this.addAnotherParentLabel;

        this.coverageConfig = getValue(eligibilityStepsWhoseCoveredForm, 'coverage');
        this.hasParentDependents = this.configService.getParentDependentEnabled();
    }

    private addExtraConfigs(extraConfigs) {
        if (!extraConfigs) return;

        if (extraConfigs.all) {
            extraConfigs.all.forEach((config) => {
                if (this.cardsConfig.subscriber?.controls) this.cardsConfig.subscriber.controls.push(cloneObject(config));
                this.cardsConfig.spouse.controls.push(cloneObject(config));
                this.cardsConfig.children.controls.push(cloneObject(config));
                this.cardsConfig.child_only.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.subscriber && this.cardsConfig.subscriber?.controls) {
            extraConfigs.subscriber.forEach((config) => {
                this.cardsConfig.subscriber.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.spouse) {
            extraConfigs.spouse.forEach((config) => {
                this.cardsConfig.spouse.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.dependents) {
            extraConfigs.dependents.forEach((config) => {
                this.cardsConfig.children.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.child_only) {
            extraConfigs.child_only.forEach((config) => {
                this.cardsConfig.child_only.controls.push(cloneObject(config));
            });
        }
    }

    /**
     *  If the coverage option is not set (renewals), derive it from the members
     *  available on the workflow
     */
    private determineCoverageOption() {
        const hasSpouse = !!this.workflowValues.spouse && !isEmptyObj(this.workflowValues.spouse);
        const hasDependents = this.workflowValues.dependents && this.workflowValues.dependents.length > 0;

        if (this.workflowValues.child_only) return this.optionsConfig[CoveredMemberKey.children];

        if (hasDependents && hasSpouse) return this.optionsConfig[CoveredMemberKey.family];

        if (hasSpouse) return this.optionsConfig[CoveredMemberKey.spouse];

        if (hasDependents) return this.optionsConfig[CoveredMemberKey.parent];

        return this.optionsConfig[CoveredMemberKey.subscriber];
    }

    private setDisabledConfig(config) {
        const configCopy = cloneObject(config);
        Object.keys(config).forEach((key: string) => {
            configCopy[key].isDisabled = !!this.disabled;
            configCopy[key].controls = configCopy[key].controls.map((control) => ({
                ...control,
                isDisabled: !!this.disabled,
            }));
        });
        return configCopy;
    }

    public isAddNewDependent(): boolean {
        const { allowAddOrRemoveDependents, cardsConfig, optionsConfig, formGroup, memberCoverageOption } = this;
        const childConfig = cardsConfig[coveredCards.childOnly];
        const { isDisabled } = childConfig;
        const { one_per_policy } = optionsConfig.children;
        const childLimit = cardsConfig.child_only?.childLimit;
        const childValueLength = formGroup.get(coveredCards.child).value.length;
        const isCoverageOptionDifferent = memberCoverageOption.value !== optionsConfig.children.value;

        return (
            allowAddOrRemoveDependents && ((!isDisabled && !one_per_policy && childValueLength < childLimit) || isCoverageOptionDifferent)
        );
    }
}
