import {
    applicationStatus,
    catastrophicEligibilityReasonType,
    Computed__Applications,
    Computed__Members,
    Computed__TaxHouseholds,
    CreatingSystemId,
    CreatingSystemRole,
    creationOriginType,
    InsuranceApplicationType,
    ResponseLinks,
    ResultType,
    submissionOriginType,
    UseCaseTypes,
} from '../sharedModels';

export class SubmitApplicationResponse__Result__Computed {
    members: Computed__Members = null;
    application: Computed__Applications = null;
    taxHouseholds: Computed__TaxHouseholds = null;
    variableDeterminations: Array<Computed__VariableDeterminations> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class SubmitApplicationResponse__Result {
    coverageYear: string = null;
    insuranceApplicationIdentifier: number = null;
    applicationVersionNumber: number = null;
    lastModifiedDateTime: string = null;
    lastModifiedUserId: string = null;
    deletedIndicator: boolean = null;
    currentVersionIndicator: boolean = null;
    versionDateTime: string = null;
    applicationStatus: applicationStatus = null;
    comments: string = null;
    creatingSystemId: CreatingSystemId = null;
    creatingSystemRole: CreatingSystemRole = null;
    creatingUserId: string = null;
    applicationSubmissionDateTime: string = null;
    creationOriginType: creationOriginType = null;
    applicationCreationDateTime: string = null;
    useCase: UseCaseTypes = null;
    submissionOriginType: submissionOriginType = null;
    lastConsumerSubmissionDateTime: string = null;
    insuranceApplicationType: InsuranceApplicationType = null;
    computed: SubmitApplicationResponse__Result__Computed = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__VariableDeterminations__MemberVariableDeterminations__Structure {
    catastrophicEligibilityIndicator: boolean = null;
    lpaIndicator: boolean = null;
    catastrophicEligibilityReasonType: catastrophicEligibilityReasonType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__VariableDeterminations__MemberVariableDeterminations {
    [memberDeterminations: string]: Computed__VariableDeterminations__MemberVariableDeterminations__Structure;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__VariableDeterminations {
    creationDateTime: string = null;
    memberVariableDeterminations: Array<Computed__VariableDeterminations__MemberVariableDeterminations> = null;
    coverageState: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class SubmitApplicationResponse {
    resultType: ResultType = null;
    links: ResponseLinks = null;
    result: SubmitApplicationResponse__Result = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
