import { Injectable } from '@angular/core';
import { stringBuilder } from '@zipari/web-utils';
import { TableCellClickEvent, TableAction, TableContext } from '../list/list.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class DetailService {
    constructor() {}

    cellClicked(action: TableAction, context: TableContext, router: Router, actionData: TableCellClickEvent) {
        context.actionData = actionData.context;

        if (action.route) {
            router.navigate([stringBuilder(action.route, context)]);
        }
    }
}
