import MemberCoverage from './MemberCoverage.model';
import PlanInformation from './PlanInformation.model';

export default class ProductCoverage {
    readonly id?: number;
    plan_information?: PlanInformation;
    member_coverages?: Array<MemberCoverage>;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    premium_information?: string;
    coverage_level?: string;
    statemnt_preference?: boolean;
    crm_id?: string;
    policy: number;
    plan: number;
    product_coverage_type?: number;
}
