import { Component, Input, HostBinding, ElementRef, OnInit } from '@angular/core';

import { EdeStepperComponent } from '../ede-stepper.component';

@Component({
    exportAs: 'edeStep',
    selector: 'ede-step',
    templateUrl: './ede-step.component.html',
    styleUrls: ['./ede-step.component.scss'],
})
export class EdeStepComponent implements OnInit {
    @Input() disabled = false;
    @Input() label: string;
    @HostBinding('attr.id') @Input() id: string;
    @HostBinding('class.step-visible') get isVisible(): boolean {
        return this.active || (this.stepper.showPreviousSteps && this.stepIndex < this.stepper.currentStepIndex);
    }
    @HostBinding('class.step-active') @Input() get active(): boolean {
        return this._active;
    }
    set active(active: boolean) {
        if (this._active === active) {
            return;
        }

        if (!active) {
            if (this._active && !active) {
                this._active = active;
            }

            return;
        }

        this._active = active;
        this.edeStepper.steps.forEach((step: EdeStepComponent) => {
            if (step !== this) {
                step.active = false;
            }
        });
    }
    protected _active = false;

    stepper: EdeStepperComponent;

    constructor(public elementRef: ElementRef, readonly edeStepper: EdeStepperComponent) {
        this.stepper = edeStepper;
    }

    ngOnInit(): void {
        this.edeStepper.addStep(this);
    }

    get stepIndex(): number {
        return this.stepper.steps.findIndex((s) => s === this);
    }

    ngOnDestroy(): void {
        this.edeStepper.removeStep(this);
    }
}
