import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@zipari/web-utils';
import { FormControlModule, FormGroupModule, IconModule } from '@zipari/design-system';

import { CoveredMembersComponent } from './covered-members.component';

@NgModule({
    declarations: [CoveredMembersComponent],
    providers: [],
    imports: [CommonModule, DirectivesModule, FormControlModule, FormGroupModule, IconModule],
    exports: [CoveredMembersComponent],
})
export class CoveredMembersModule {}
