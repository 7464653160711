import Address from './Address.model';
import Contact from './Contact.model';
import Group from './Group.model';
import Plan from './Plan.model';

export default class Employee {
    addresses: Array<Address>;
    contact: any;
    created_at: string;
    data: any;
    date_of_birth: string;
    effective_date: string;
    employee_class: any;
    employee_number: string;
    employment_status: string;
    family_enrollment_type: string;
    gender: string;
    groups: Array<Group>;
    id?: number;
    job_title: string;
    marital_status: string;
    office: any;
    plans: Array<Plan>;
    tenant: string;
    termination_date: string;
    tobacco_usage: any;
    updated_at: string;
    uses_tobaco: boolean;
}

class CoverageInformation {
    npi: string;
    medical: string;
    vision: string;
    bundle: string;
    dependents_covered: boolean;
    dental: string;
}

class Dependent {
    suffix: string;
    student: boolean;
    include_medical: boolean;
    prefix: string;
    tobacco: boolean;
    include_vision: boolean;
    gender: string;
    include_dental: boolean;
    npi_number: string;
    last_name: string;
    date_of_birth: string;
    middle_initial: string;
    first_name: string;
    include_bundle: boolean;
}

class SpouseDependents {
    add_spouse: boolean;
    dependents: Array<Dependent>;
    add_dependents: boolean;
    spouse: Array<Dependent>;
}

class EmployeeInformation {
    date_of_birth: string;
    suffix: string;
    student: boolean;
    employee_status: string;
    enrolled_sapd: boolean;
    health_application_type: string;
    prefix: string;
    tobacco: boolean;
    gender: string;
    enrolled_vision: boolean;
    last_name: string;
    contract_type: string;
    middle_initial: string;
    first_name: string;
    date_of_employment: string;
    work_location: string;
}

class AdditionalCoverages {
    other_reason: string;
    medicare_part_b: boolean;
    policy_number: string;
    reason: string;
    carrier_name: string;
    continuing_coverage: boolean;
    esrd: boolean;
    date_began: string;
    other_coverage: boolean;
    medicare_part_a: boolean;
    medicare_eligible: boolean;
}

class ContactInformation {
    address2?: any;
    city: string;
    phone_number: string;
    county_name: string;
    zip_code: string;
    county_code: string;
    address1: string;
    email: string;
    state: string;
}

class PersonalInformation {
    ssn: string;
    active: boolean;
    hours: string;
    occupation: string;
}

// employee created from template
export class TemplatedEmployee {
    addresses: Array<any>;
    contact: any;
    created_at: string;
    data_1: number;
    date_of_birth: string;
    effective_date: string;
    email: string;
    employee_class: string;
    employee_number: string;
    employment_status: string;
    family_enrollment_type: string;
    first_name: string;
    gender: string;
    groups: Array<any>;
    id: number;
    job_title: string;
    last_name: string;
    marital_status: string;
    office: string;
    plans: [];
    tenant: string;
    termination_date: string;
    tobacco_usage: boolean;
    updated_at: boolean;
    uses_tobaco: boolean;
}

// manually entered employee (from employee info form)
export class EmployeeInfo extends TemplatedEmployee {
    coverage_information: CoverageInformation;
    spouse_dependents: SpouseDependents;
    employee_information: EmployeeInformation;
    additional_coverages: AdditionalCoverages;
    contact_information: ContactInformation;
    personal_information: PersonalInformation;
    hasExpand: boolean;
}

export class EmployeeDisplay extends EmployeeInfo {
    name: any;
    age: number;
    dependents: number;
    enrollment_status?: string;
}
