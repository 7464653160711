export default class Procedure {
    readonly id?: number;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    tenant?: string;
    procedure_code: string;
    procedure_description?: string;
}
