import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SquidexContentResponseTypes } from '@zipari/shared-sbp-constants';
import { ConfigService, LoggerService, SquidexApiService } from '@zipari/shared-sbp-services';
import { SquidexConfig, SquidexGetContentParams, SquidexContentOptions } from '@zipari/shared-sbp-models';
import { Observable, of, combineLatest } from 'rxjs';
import { catchError, map, switchAll, mergeMap } from 'rxjs/operators';

export interface ConfigDispenserGetConfigOptions {
    // config to return if not using squidex content or page/feat config from preloaded configs
    fallbackConfig?: any;

    // endpoint to fetch config from
    httpEndpoint?: string;
    // options for http request. sub fields must work with angular http client
    httpOptions?: any;

    // function used to format content gotten from squidex api call (if we get content from squidex)
    squidexFormatterFunction?: Function;
    // additional params to pass to the formatter function. can also be used to pass context to the function since its scope is limited
    squidexFormatterFunctionParams?: Array<any>;
}

/**
 * The Config Dispenser is a service that will take in some input and send out some config to our components
 *
 * We're either going to be dispensing out our previous config implementation (config stored on the window sent to the FE from the BE
 * on app init, or from http requests) or we're going to be dispensing out config from a Squidex CMS application. In some cases we'll
 * dispense out a set of config passed to this service as an option, this config will likely be a subset of config, already gotten from
 * one of our config sources, for smaller features.
 *
 * We will handle all the logic of config dispensing & modification to config responses right here in this service so none of the
 * components have to worry about it, because the components shouldn't worry about any of that anyways :)
 */

@Injectable({
    providedIn: 'root',
})
export class ConfigDispenserService {
    /**
     * Returns true if the current tenant has a Squidex Application set up for this portal & they want to pull content from squidex
     */
    public get useSquidexContent(): boolean {
        const squidexConfig: SquidexConfig = this.configService.getPageConfig<any>('global')?.squidex;
        return !!squidexConfig?.useSquidexContent;
    }

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private loggerService: LoggerService,
        private squidexApiService: SquidexApiService
    ) {}

    /**
     * Returns (dispenses!) an observable containing config for a feature/page. The config can be gotten from a few
     * places: Squidex CMS via api call, an http request, a page config gotten from preloaded configs stored on the window, or a fallback
     * config object passed to this function as an option.
     *
     * We return Squidex Content if we are using squidex for a client && the content exists in squidex,
     * else we return config from an http request, preloaded page config, or the fallback config.
     *
     * If we return squidex content, we may also apply a formatting function to the data to keep the config backwards compatable with
     * our components. There may be cases where squidex can't return the same obj model as the config we have used in our previous
     * config implementations.
     *
     * @param configPageName the page name associated with a preloaded page config (same as configService.getPageConfig(page))
     * @param squidexGetContentParams object holding the parameters needed to call squidexApiService.getContent()
     * @param options additional options used by this function and helper functions
     */
    public dispenseConfig(
        configPageName: string,
        squidexGetContentParams: SquidexGetContentParams,
        dispenseConfigOptions: ConfigDispenserGetConfigOptions = {}
    ): Observable<any> {
        if (this.useSquidexContent && squidexGetContentParams) {
            squidexGetContentParams.options = {
                ...squidexGetContentParams.options,
                filterByStatus: true,
            };
            // get config from squidex. return observable containing squidex CMS content
            return this.squidexApiService
                .getSquidexContent(squidexGetContentParams.contentName, squidexGetContentParams.featureKey, squidexGetContentParams.options)
                .pipe(
                    // makes a second call to squidex to get content without filter by status
                    // does this to get CMS content from lower environments
                    mergeMap((flatResponse) => {
                        squidexGetContentParams.options = {
                            ...squidexGetContentParams.options,
                            filterByStatus: false,
                        };
                        return combineLatest(
                            of(flatResponse),
                            this.squidexApiService.getSquidexContent(
                                squidexGetContentParams.contentName,
                                squidexGetContentParams.featureKey,
                                squidexGetContentParams.options
                            )
                        );
                    }),
                    map(([squidexResponseFilteredByStatus, squidexResponsePublishedOnly]) => {
                        // Returns Observable of squidexResponse to be used for content
                        return this.handleSquidexResponseLogic(
                            squidexResponseFilteredByStatus,
                            squidexResponsePublishedOnly,
                            configPageName,
                            squidexGetContentParams,
                            dispenseConfigOptions
                        );
                    }),
                    switchAll(),
                    catchError((error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            // http error occurred, return preloaded page config or fallbackConfig
                            return this.getNonSquidexConfig(configPageName, dispenseConfigOptions);
                        }

                        // non http error, likely a formatter function error. return empty obj to indicate app level error occurred
                        this.loggerService.error(`ConfigDispenserService Error`, error.message);
                        return of({});
                    })
                );
        } else {
            // return non squidex config
            return this.getNonSquidexConfig(configPageName, dispenseConfigOptions);
        }
    }

    /**
     * Get config from the source our application got configs prior to squidex. We can get these configs from an http request,
     * a page level config stored on our preloaded configs, or from our fallbackConfig value (prob gotten from parent component).
     *
     * @param configPageName the page name associated with a preloaded page config
     * @param dispenseConfigOptions ConfigDispenserGetConfigOptions containing http request data & fallbackConfig
     */
    private getNonSquidexConfig(configPageName: string, dispenseConfigOptions: ConfigDispenserGetConfigOptions): Observable<any> {
        return dispenseConfigOptions?.httpEndpoint
            ? this.http.get<any>(dispenseConfigOptions.httpEndpoint, dispenseConfigOptions.httpOptions || {})
            : this.getPreloadedPageConfig(configPageName, dispenseConfigOptions?.fallbackConfig);
    }

    /**
     * Returns an observable of a preloaded config for a page/feat. If no pageName is specified return fallbackConfig as an Observable
     *
     * @param pageName the name for a page/feat's high level config
     * @param fallbackConfig the default config to use. this is usually a subset of config a component/feature already has.
     */
    private getPreloadedPageConfig(pageName: string, fallbackConfig: any): Observable<any> {
        return of(pageName ? this.configService.getPageConfig(pageName) : fallbackConfig);
    }

    /**
     * Takes in the content response from squidex for both the filter by status and publish only content
     * Returns the process response from the content response. If one of the responses contains data it will return that data
     * Else will fallback to the default configs
     */
    private handleSquidexResponseLogic(
        squidexResponseFilteredByStatus: any,
        squidexResponsePublishedOnly: any,
        configPageName: string,
        squidexGetContentParams: SquidexGetContentParams,
        dispenseConfigOptions: ConfigDispenserGetConfigOptions
    ): Observable<any> {
        const options = new SquidexContentOptions(squidexGetContentParams.options);
        let squidexResponse;
        switch (options.responseType) {
            case SquidexContentResponseTypes.object:
                squidexResponse = this.getSquidexResponseFromCalls(squidexResponsePublishedOnly, squidexResponseFilteredByStatus)[0] || [];
                break;
            default:
                squidexResponse = this.getSquidexResponseFromCalls(squidexResponsePublishedOnly, squidexResponseFilteredByStatus);
        }

        // If an empty array is returned, the client does not have the specified feature key, or content matching params
        // sent for squidex content, but they do have the content's schema in squidex.
        // This means the client doesn't have this feature configured yet, so we return the non squidex config instead.
        if (Array.isArray(squidexResponse) && !squidexResponse.length) {
            return this.getNonSquidexConfig(configPageName, dispenseConfigOptions);
        }

        /**
         * Call formatter function to modify the squidex response. The formatter function is used when squidex cannot
         * return content that is backwards compatable with our features. Bascially use this when we can't reproduce
         * the correct obj model with Squidex, and we have to reformat the data to work with our components.
         *
         * We call the formatter function here, and not in a component file, because the component shouldn't have to
         * figure out where the config comes from to determine if it should format the data or not, we only want to send
         * the components config that works for the component.
         */
        if (!!dispenseConfigOptions.squidexFormatterFunction) {
            const additionalFormatterFunctionParams = dispenseConfigOptions.squidexFormatterFunctionParams || [];
            const formatterFunctionParams = [squidexResponse, ...additionalFormatterFunctionParams];

            // call format function with squidex response as first param.
            // use squidexFormatterFunctionParams to pass additional params/context to the formatter function
            squidexResponse = dispenseConfigOptions.squidexFormatterFunction(...formatterFunctionParams);
        }

        return of(squidexResponse);
    }

    //Processes the squidex responses and returns the call with content
    //else returns an empty array
    private getSquidexResponseFromCalls(squidexResponsePublishedOnly, squidexResponseFilteredByStatus) {
        if (squidexResponsePublishedOnly.length === 1) {
            return squidexResponsePublishedOnly;
        } else if (squidexResponseFilteredByStatus.length === 1) {
            return squidexResponseFilteredByStatus;
        }

        return [];
    }
}
