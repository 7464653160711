import { ColumnConfig } from '@zipari/design-system/lib/chords/table/models';

export const documentEndpoint = 'api/documents/';
export const tableConfig = {
    folders: {
        title: 'Resource Center',
        columns: [
            {
                name: 'Folder',
                prop: 'folder',
                format: 'LINK',
            },
            {
                name: 'Description',
                prop: 'description',
                format: 'text',
            },
        ],
        data: [
            {
                folder: 'Plan Materials',
                description: 'Includes SBCs, Provider Directories, Drug Formularies, and other plan related materials.',
                endpoint: '/api/documents/?document_category=resources&search_string=plan_materials',
            },
            {
                folder: 'Enrollment Materials',
                description: 'Includes applications, supplemental documents, waivers, and other enrollment related materials.',
                endpoint: '/api/documents/?document_category=resources&search_string=enrollment_materials',
            },
            {
                folder: 'Agent Materials',
                description: 'Includes documents related to appointment and compensation.',
                endpoint: '/api/documents/?document_category=resources&search_string=agent_materials',
            },
            {
                folder: 'Marketing Materials',
                description: 'Includes marketing communications about items such as value add benefits and services and special events',
                endpoint: '/api/documents/?document_category=resources&search_string=marketing_materials',
            },
        ],
        pagination: 10,
        paging: {
            enabled: true,
            pageSize: 10,
        },
    },
    documents: {
        title: 'Plan Materials',
        backTitle: 'Back to Resource Center',
        columns: [
            {
                name: 'Resources',
                prop: 'file_name',
                format: 'LINK',
            },
            {
                name: '',
                actions: ['download'],
                format: 'ACTIONS',
                useRoute: false,
            },
        ],
        endpoint: documentEndpoint,
        pagination: 10,
        paging: {
            enabled: true,
            pageSize: 10,
        },
    },
};

export interface ResourceCenterDocumentsTableCellClickContext {
    col: ResourceCenterDocumentsTableColumnConfig;
    row: any;
    val: any;
}

export interface ResourceCenterDocumentsTableColumnConfig extends ColumnConfig {
    /**
     * Flag used to determine if we should use the default document api endpoint to
     * retrieve a document when the user attempts to download a file from the document's table.
     */
    useDocumentsEndpoint?: boolean;
}
