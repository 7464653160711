<article
    class="table__filters"
    [ngClass]="{
        'table__filters--inline': filters.length < 3,
        'table__filters--align': !filtersSame
    }"
>
    <ng-container *ngFor="let filter of zipFilters; let i = index">
        <div class="table__filter table__filter--search" *ngIf="filter.prop === 'search'; else nonSearchFilter">
            <form-control [group]="formGroup" [config]="filter" [control]="formGroup.get(filter.controlProp)" direction="search">
            </form-control>
        </div>

        <ng-template #nonSearchFilter>
            <div
                class="table__filter"
                [ngClass]="{
                    'table__filter--small': !!filter.size && filter.size === 'small',
                    'table__filter--medium': (!!filter.size && filter.size === 'medium') || !filter.size,
                    'table__filter--large': !!filter.size && filter.size === 'large'
                }"
                *ngIf="!filter.hide && filter.type !== 'query'"
            >
                <form-control [group]="formGroup" [config]="filter" [control]="formGroup.get(filter.controlProp)" direction="filter">
                </form-control>
            </div>
        </ng-template>
    </ng-container>
</article>
