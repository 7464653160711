<accordion [header]="config.header">
    <ng-container header-action>
        <i class="icon icon--medium">edit</i>
        <p class="t-data t-link" (click)="toggleEdit()">Edit</p>
    </ng-container>

    <ng-container *ngIf="!isEditing">
        <ng-container *ngFor="let detail of config.details">
            <div class="form-control__wrapper form-control__wrapper--inline">
                <label class="form-control__label">
                    <p class="t-data">{{ detail.label }}</p>
                </label>

                <p class="t-data t-bold">{{ formGroup.value | getValue : detail.prop | mask : detail.mask }}</p>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isEditing">
        <div class="details-accordion__wrapper">
            <form-group class="details-accordion__form" [configs]="config.groups" direction="default" [form]="formGroup"> </form-group>
        </div>
    </ng-container>
</accordion>
