import { IdentityProofingChannelType } from '../sharedModels';

export interface StoreIDProofingPayload {
    identityProofingMethodType: IdentityProofingMethodType;
    identityProofingChannelType: IdentityProofingChannelType;
    firstName: string;
    lastName: string;
    birthDate: string;
    onlineTransactionIdentifier?: string;
    nationalProducerNumber?: string;
    identityProofingDocuments?: {
        [identityProofingDocumentType in IdentityProofingDocumentType]?: string;
    };
}

export enum IdentityProofingMethodType {
    DOCUMENT_REVIEW = 'DOCUMENT_REVIEW',
    RIDP_FARS = 'RIDP_FARS',
    THIRD_PARTY = 'THIRD_PARTY',
}

export enum IdentityProofingDocumentType {
    CERTIFICATE_OF_NATURALIZATION_FORM_N_550_OR_N_570_OR_US_CITIZENSHIP_FORM_N_560_OR_N_561 = 'CERTIFICATE_OF_NATURALIZATION_FORM_N_550_OR_N_570_OR_US_CITIZENSHIP_FORM_N_560_OR_N_561',
    DIVORCE_DECREE = 'DIVORCE_DECREE',
    DRIVER_S_LICENSE_ISSUED_BY_STATE_OR_TERRITORY = 'DRIVER_S_LICENSE_ISSUED_BY_STATE_OR_TERRITORY',
    EMPLOYER_IDENTIFICATION_CARD = 'EMPLOYER_IDENTIFICATION_CARD',
    EMPLOYMENT_AUTHORIZATION_DOCUMENT_THAT_CONTAINS_A_PHOTOGRAPH_FORM_I_766 = 'EMPLOYMENT_AUTHORIZATION_DOCUMENT_THAT_CONTAINS_A_PHOTOGRAPH_FORM_I_766',
    FOREIGN_PASSPORT_OR_ID_ISSUED_BY_A_FOREIGN_EMBASSY_OR_CONSULATE_CONTAINS_A_PHOTOGRAPH = 'FOREIGN_PASSPORT_OR_ID_ISSUED_BY_A_FOREIGN_EMBASSY_OR_CONSULATE_CONTAINS_A_PHOTOGRAPH',
    HIGH_SCHOOL_OR_COLLEGE_DIPLOMA_INCLUDING_HIGH_SCHOOL_EQUIVALENCY_DIPLOMAS = 'HIGH_SCHOOL_OR_COLLEGE_DIPLOMA_INCLUDING_HIGH_SCHOOL_EQUIVALENCY_DIPLOMAS',
    IDENTIFICATION_CARD_ISSUED_BY_THE_FEDERAL_STATE_OR_LOCAL_GOVERNMENT = 'IDENTIFICATION_CARD_ISSUED_BY_THE_FEDERAL_STATE_OR_LOCAL_GOVERNMENT',
    MARRIAGE_CERTIFICATE = 'MARRIAGE_CERTIFICATE',
    MILITARY_DEPENDENT_S_IDENTIFICATION_CARD = 'MILITARY_DEPENDENT_S_IDENTIFICATION_CARD',
    NATIVE_AMERICAN_TRIBAL_DOCUMENT = 'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
    PERMANENT_RESIDENT_CARD_OR_ALIEN_REGISTRATION_RECEIPT_CARD_FORM_I_551 = 'PERMANENT_RESIDENT_CARD_OR_ALIEN_REGISTRATION_RECEIPT_CARD_FORM_I_551',
    PROPERTY_DEED_OR_TITLE = 'PROPERTY_DEED_OR_TITLE',
    SCHOOL_IDENTIFICATION_CARD = 'SCHOOL_IDENTIFICATION_CARD',
    SOCIAL_SECURITY_CARD = 'SOCIAL_SECURITY_CARD',
    US_PUBLIC_BIRTH_REC = 'US_PUBLIC_BIRTH_REC',
    U_S_COAST_GUARD_MERCHANT_MARINER_CARD = 'U_S_COAST_GUARD_MERCHANT_MARINER_CARD',
    U_S_MILITARY_CARD_OR_DRAFT_RECORD = 'U_S_MILITARY_CARD_OR_DRAFT_RECORD',
    U_S_PASSPORT_OR_U_S_PASSPORT_CARD = 'U_S_PASSPORT_OR_U_S_PASSPORT_CARD',
    VOTER_REGISTRATION_CARD = 'VOTER_REGISTRATION_CARD',
}
