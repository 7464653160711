import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { LoggerService, WINDOW } from '@zipari/shared-sbp-services';
import { saveAs } from 'file-saver';

export class PrintConfig {
    fileName: string;
    type: string;
    zip_pdf_main_html: string;
    zip_pdf_main_css: string;
    static_values: string;
    zip_pdf_support_files: string;
    endpoint: string;
    dateFormat?: {
        format: formatTypes;
        formatOptions?: {
            originalTimezone?: string;
            timezone?: string;
        };
    };

    constructor(options) {
        Object.assign(this, options);
    }
}

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    constructor(private loggerService: LoggerService, private http: HttpClient, @Inject(WINDOW) private window: Window) {}

    downloadPdf(printConfig: PrintConfig, data: Function | any, fileNameInput?: string) {
        // todo legacy code ported from V2... CLEANUP
        if (!printConfig) {
            this.loggerService.warn('Print configuration has not been set up correctly');

            return;
        }
        if (!data) {
            this.loggerService.warn('No data provided');

            return;
        }

        const printValues = this.formatPrintValues(data, printConfig);

        const options: any = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
        };

        return this.http.post(printConfig.endpoint, printValues).subscribe((response) => {
            const fileName: string = fileNameInput || printConfig.fileName;

            this.download_file(`${this.window.location.origin}/api/documents/${response['id']}/`, fileName);
        });
    }

    formatPrintValues(data, printConfig) {
        // set up a single plan for backwards compatibility
        if (data.workflow && !data.workflow.plan && data.workflow.plans && !!data.workflow.plans.length) {
            data.workflow.plan = data.workflow.plans[0];
        }

        // if there are extra values to add to the data based on the config then assign them to the data object
        if (printConfig.static_values) {
            Object.assign(data, { static_values: printConfig.static_values });
        }
        const printValues = {
            file_name: printConfig.fileName,
            type: printConfig.type,
            zip_pdf_main_html: printConfig.zip_pdf_main_html,
            zip_pdf_main_css: printConfig.zip_pdf_main_css,
            zip_pdf_template_vars: typeof data === 'object' ? data : data(),
        };

        if (printConfig.hasOwnProperty('zip_pdf_support_files')) {
            printValues['zip_pdf_support_files'] = printConfig.zip_pdf_support_files;
        }

        return printValues;
    }

    generatePdf(
        printConfig: PrintConfig,
        data: Function | any,
        fileName: string = `${new Date().toLocaleDateString()}--application-summary.pdf`
    ) {
        // todo legacy code ported from V2... CLEANUP
        if (!printConfig) {
            this.loggerService.warn('Print configuration has not been set up correctly');

            return;
        }
        if (!data) {
            this.loggerService.warn('No data provided');

            return;
        }

        // set up a single plan for backwards compatibility
        if (data.workflow && !data.workflow.plan && data.workflow.plans && !!data.workflow.plans.length) {
            data.workflow.plan = data.workflow.plans[0];
        }

        // if there are extra values to add to the data based on the config then assign them to the data object
        if (printConfig.static_values) {
            Object.assign(data, { static_values: printConfig.static_values });
        }
        const printValues = {
            file_name: printConfig.fileName,
            type: printConfig.type,
            zip_pdf_main_html: printConfig.zip_pdf_main_html,
            zip_pdf_main_css: printConfig.zip_pdf_main_css,
            zip_pdf_template_vars: typeof data === 'object' ? data : data(),
        };

        if (printConfig.hasOwnProperty('zip_pdf_support_files')) {
            printValues['zip_pdf_support_files'] = printConfig.zip_pdf_support_files;
        }
        const options: any = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
        };

        return this.http.post(printConfig.endpoint, printValues, options);
    }

    formatPdfSections(config) {
        const formatted = config
            .filter(({ type }) => type === 'attributes')
            .reduce((acc, cur) => {
                acc[cur.label] = cur.attributeGroups[0]?.attributes || [];
                return acc;
            }, {});

        return formatted;
    }

    downloadByteStream(url: string, fileType: string): Observable<any> {
        const headers = new HttpHeaders({
            Accept: '*/*',
        });

        return this.http.get(url, { responseType: 'blob' as 'json', headers }).pipe(
            catchError((e) => {
                return of(e);
            }),
            map((fileData) => {
                return fileData;
            })
        );
    }

    saveDownloadedDocument(data: Blob, filename: string, fileType: string = 'pdf') {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');

        a.href = url;
        a.setAttribute('download', `${filename}.${fileType}`);
        a.click();
    }

    /* Helper function to download file from url */
    download_file(fileURL: string, fileName?: string) {
        saveAs(fileURL, fileName);
    }
}
