import { Injectable } from '@angular/core';
import { getValue } from '@zipari/web-utils';

@Injectable({
    providedIn: 'root',
})
export class EdeIncomeService {
    deductionsDisplayKeys = {
        ALIMONY_PAYMENT: 'Alimony',
        OTHER_DEDUCTION: 'Other',
        STUDENT_LOAN_INTEREST: 'Student Loan Interest',
    };
    incomeDisplayKeys = {
        OTHER_INCOME: 'Other Income',
        CASH_SUPPORT: 'Cash',
        UNEMPLOYMENT: 'Unemployment',
        SCHOLARSHIP: 'Scholarship',
        JOB: 'Job',
        SELF_EMPLOYMENT: 'Self-employment',
        FARMING_OR_FISHING_INCOME: 'Farming & Fishing',
        SOCIAL_SECURITY_BENEFIT: 'Social Security benefits',
        RETIREMENT: 'Retirement',
        PENSION: 'Pension',
        INVESTMENT_INCOME: 'Investment',
        RENTAL_OR_ROYALTY_INCOME: 'Rental or royalty income',
        CAPITAL_GAINS: 'Capital Gains',
        ALIMONY_RECEIVED: 'Alimony Received',
        PRIZES_AWARDS_GAMBLING_WINNINGS: 'Gambling, prizes or awards',
        COURT_AWARDS: 'Court Awards',
        JURY_DUTY_PAY: 'Jury Duty Pay',
        CANCELED_DEBT: 'Canceled Debts',
    };
    incomeFrequencyKeys = {
        Weekly: 'week',
        Monthly: 'month',
        Yearly: 'year',
        'Every 2 weeks': 'every two weeks',
        'Twice a month': 'twice a month',
        'One time only': 'once',
        HOURLY: 'hour',
        DAILY: 'day',
        WEEKLY: 'week',
        MONTHLY: 'month',
        ANNUALLY: 'year',
        BI_WEEKLY: 'every two weeks',
        SEMI_MONTHLY: 'twice a month',
        ONE_TIME: 'once',
    };

    constructor() {}

    public getOnlyDeductionsFromCurrentIncome(member) {
        const currentIncome = getValue(member, 'value.income.currentIncome') || getValue(member, 'income.currentIncome');
        return (currentIncome || [])
            .map((deduction, ind) => {
                deduction.index = ind;
                return deduction;
            })
            .filter((income) => {
                return (
                    income.incomeSourceType === 'STUDENT_LOAN_INTEREST' ||
                    income.incomeSourceType === 'ALIMONY_PAYMENT' ||
                    income.incomeSourceType === 'OTHER_DEDUCTION'
                );
            })
            .map((deduction) => {
                return {
                    incomeSourceType: deduction.incomeSourceType,
                    incomeFrequencyType: deduction.incomeFrequencyType,
                    incomeAmount: Math.abs(deduction.incomeAmount),
                    index: deduction.index,
                };
            });
    }

    public getOnlyIncomeFromCurrentIncome(member) {
        const currentIncome = getValue(member, 'value.income.currentIncome') || getValue(member, 'income.currentIncome');

        return (currentIncome || [])
            .map((income, ind) => {
                income.index = ind;
                return income;
            })
            .filter((income) => {
                const isHardToPredictEstimate = income.incomeSourceType === 'OTHER_INCOME' && income.estimatedForAptcIndicator === true;
                return (
                    income.incomeSourceType !== 'STUDENT_LOAN_INTEREST' &&
                    income.incomeSourceType !== 'ALIMONY_PAYMENT' &&
                    income.incomeSourceType !== 'OTHER_DEDUCTION' &&
                    !isHardToPredictEstimate
                );
            });
    }
}
