import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CardModule, FormattingService, FormGroupModule, ModelAttributeModule, ZipBusyModule } from '@zipari/design-system';
import { SubsidyComponent } from './subsidy.component';
import { StateSubsidyResultsComponent } from './state-subsidy-results/state-subsidy-results.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [SubsidyComponent, StateSubsidyResultsComponent],
    imports: [CommonModule, SharedModule, CardModule, ModelAttributeModule, ZipBusyModule, FormGroupModule, ButtonModule],
    providers: [FormattingService],
    exports: [SubsidyComponent, StateSubsidyResultsComponent],
})
export class SubsidyModule {}
