<div [attr.data-plan_variation]="plan.plan_variation" class="card plan__card plan__card_design" *ngIf="plan">
    <div class="plan-card">
        <div class="highlight-attributes">
            <header class="plan__card__header" [ngClass]="{ 'with-star-rating': !!plan.star_rating && showStarRating }">
                <ng-container [ngSwitch]="!!pdfUrl">
                    <h6 class="plan__card__title t-bold" *ngSwitchCase="true">
                        <a [href]="pdfUrl" target="_blank">{{ plan.display_name || plan.name }}</a>
                    </h6>
                    <h6 class="plan__card__title t-bold" *ngSwitchDefault>{{ plan.display_name || plan.name }}</h6>
                    <div *ngIf="!showActions" class="PlanCard--Mobile" (click)="toggleDetails()">
                        <i class="icon icon--medium">chevron_right</i>
                    </div>
                </ng-container>

                <div class="plan__card__banner plan__card__banner--primary" *ngIf="!!plan.isRecommended">
                    <i class="icon icon--small">thumb_up_active</i>
                    <p class="t-caption t-bold">Recommended</p>
                </div>

                <div class="plan__card__banner" *ngIf="!!plan.isGood">
                    <p class="t-caption t-bold" [innerHTML]="goodFitMessage"></p>
                </div>

                <icon
                    *ngIf="!showRemovePlanIcon ? showRemovePlanIcon : !!planDetailConfigs?.showRemovePlanIcon"
                    role="button"
                    class="RemovePlan"
                    [name]="planDetailConfigs?.removePlanIcon || 'delete'"
                    [config]="removePlanIconConfig"
                    [attr.aria-label]="removePlanIconConfig.ariaLabel"
                    (click)="removePlanIconClicked.emit(plan)"
                ></icon>

                <icon
                    *ngIf="!!showActions"
                    role="button"
                    class="ViewPlanDetails--Mobile"
                    name="chevron_right"
                    [config]="viewPlanDetailsIconConfig"
                    [attr.aria-label]="viewPlanDetailsIconConfig.ariaLabel"
                    (click)="toggleDetailsModal()"
                ></icon>
            </header>

            <section class="plan__card__rating" *ngIf="!!plan.star_rating && showStarRating">
                <star-rating
                    [starIcon]="starRatingConfig?.starIcon ? starRatingConfig.starIcon : 'star'"
                    [starFilledIcon]="starRatingConfig?.starFilledIcon ? starRatingConfig.starFilledIcon : 'star_active'"
                    [rating]="plan.star_rating"
                ></star-rating>
            </section>

            @if(plan.plan_marketing_description) {
            <section class="PlanCard__Description" data-test-id="planCardDescription">
                <p class="t-data" [innerHTML]="plan.plan_marketing_description"></p>
            </section>
            }

            <section class="plan__card__note" *ngIf="getNoteForOMNIAPlan">
                <small class="t-data t-alt" [innerHTML]="getNoteForOMNIAPlan"></small>
            </section>

            <div class="PlanCard__OriginalPriceContent PlanCard__OriginalPriceContent--Mobile">
                <ng-container *ngTemplateOutlet="originalPrice"></ng-container>
            </div>

            <section class="PlanCard__Prices" *ngIf="!hideHighlights && highlightAttributesFormatted">
                <ng-container class="PlanCard__Price" *ngFor="let highlight of highlightAttributes">
                    <div class="value-wrap" *ngIf="!!highlight.prop && highlight.prop !== 'price'">
                        <h5 class="PlanCard__PriceHeader" data-test-id="planCardPriceHeader" [innerHTML]="highlight.value"></h5>
                        <p class="t-data t-alt" [innerHTML]="highlight.label"></p>
                    </div>
                </ng-container>
            </section>

            <section class="PlanCard__Benefits" *ngIf="plan.primary_benefits && showPrimaryBenefits">
                <div class="plan__card__benefit" *ngFor="let benefit of primaryBenefits">
                    <p class="t-data t-bold" [innerHTML]="benefit.label"></p>
                    <p class="t-data" [innerHTML]="benefit.value"></p>
                </div>
            </section>

            <ng-container>
                <div class="plan-card__badges" *ngIf="badges">
                    <ng-container *ngFor="let badge of badges">
                        <plan-card-badge [badge]="badge"></plan-card-badge>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="PlanCard__OriginalPriceContent PlanCard__OriginalPriceContent--Desktop">
            <ng-container *ngTemplateOutlet="originalPrice"></ng-container>
        </div>
    </div>

    <plan-card-footer
        *ngIf="!!showActions"
        class="PlanCard__Footer"
        data-test-id="planCardFooter"
        [showActions]="showActions"
        [showDetails]="showDetails"
        [hideSelection]="hideSelection"
        [canCompare]="canCompare"
        [isCompareLocked]="isCompareLocked"
        [isCompareDisabled]="isCompareDisabled"
        [isSelectDisabled]="isSelectDisabled"
        [viewDetailConfig]="viewDetailConfig"
        [plan]="plan"
        [compareTitle]="compareTitle"
        [customButton]="customButton"
        [selectedButtonConfig]="selectedButtonConfig"
        [selectButtonConfig]="selectButtonConfig"
        (toggleDetails)="toggleDetails()"
        (toggleComparison)="toggleComparison()"
        (customButtonClicked)="customButtonClicked.emit()"
        (toggleSelection)="toggleSelection()"
    ></plan-card-footer>

    <section class="plan__card__panel" *ngIf="!!showDetails" @slideRight>
        <header class="plan__card__panel__header">
            <i class="icon icon--medium icon--pointer" (click)="showDetails = false"> arrow_left </i>

            <p class="t-bold">{{ plan.display_name || plan.name }}</p>
        </header>

        <main class="plan__card__panel__body">
            <div class="plan__card__attributes">
                <section class="plan__card__panel__recommended" *ngIf="!!plan.isRecommended || !!plan.isGood">
                    <div class="chip chip--1" *ngIf="!!plan.isRecommended">
                        <p class="t-caption t-bold">Recommended</p>
                    </div>

                    <div class="chip chip--3" *ngIf="!!plan.isGood">
                        <p class="t-caption t-bold">Good Fit</p>
                    </div>

                    <p class="t-caption">Recommended based on your high pharmacy utilization.</p>
                </section>

                <div class="plan__card__attribute" *ngFor="let benefit of plan.formattedBenefits">
                    <p class="t-data t-bold">{{ benefit.label }}</p>

                    <p class="t-data" [innerHTML]="benefit.value" *ngIf="benefit.type !== 'link'; else benefitLink"></p>

                    <ng-template #benefitLink>
                        <a class="t-data t-link" target="_blank" [attr.href]="benefit.value"> View Link </a>
                    </ng-template>
                </div>
            </div>

            <div class="plan__card__links" *ngIf="planDetailConfigs?.showLinks || showLinks">
                <ng-container *ngTemplateOutlet="planLinkTemplate"></ng-container>
            </div>
        </main>
    </section>

    <section class="plan__card__details" *ngIf="!!showDetails" @accordion>
        <div class="plan__card__detail-columns">
            <div class="plan__card__attributes">
                <div class="plan__card__attribute" *ngFor="let benefit of plan.formattedBenefits">
                    <p class="t-data t-bold">{{ benefit.label }}</p>

                    <p class="t-data" [innerHTML]="benefit.value" *ngIf="benefit.type !== 'link'; else benefitLink"></p>

                    <ng-template #benefitLink>
                        <a class="t-data t-link" target="_blank" [attr.href]="benefit.value" (click)="linkClicked(benefit, true)">
                            {{ planBenefitLinkLabelMap[benefit.label] || 'View Link' }}
                        </a>
                    </ng-template>
                </div>
            </div>

            <div class="plan__card__links" *ngIf="planDetailConfigs?.showLinks || showLinks">
                <ng-container *ngTemplateOutlet="planLinkTemplate"></ng-container>
            </div>
        </div>
        <div *ngIf="planDetailConfigs?.displayAllDocuments || planDetailConfigs?.displayDocuments; else showSinglePdf">
            <div class="PlanCard__Pdf">
                <div *ngFor="let document of documentList">
                    <a class="PlanCard__DocumentLink" [href]="document.document_id" target="_blank">
                        <icon name="pdf" [config]="{ type: 'link' }"></icon>
                        <span class="t-data t-bold">{{ document.label || formatDocumentName(document.type) }}</span>
                    </a>
                </div>
            </div>
        </div>
        <ng-template #showSinglePdf>
            <div class="PlanCard__Pdf" *ngIf="!!pdfUrl">
                <a class="PlanCard__DocumentLink" [href]="pdfUrl" target="_blank">
                    <icon name="pdf" [config]="{ type: 'link' }"></icon>
                    <span class="t-data t-bold">All {{ plan.display_name }} Benefits</span>
                </a>
            </div>
        </ng-template>
    </section>
</div>

<ng-template #planLinkTemplate>
    <ng-container *ngIf="plan.links; then dataLinksTemplate; else configLinksTemplate"></ng-container>

    <ng-template #dataLinksTemplate>
        <ng-container *ngFor="let link of plan.links">
            <div class="plan__card__link">
                <i class="icon icon--medium icon--link">{{ link.icon }}</i>
                <a class="t-data t-bold" target="_blank" [attr.href]="link.url" (click)="linkClicked(link)">
                    {{ link.label }}
                </a>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #configLinksTemplate>
        <div class="plan__card__link" *ngIf="planDetailConfigs?.summary_benefits">
            <i class="icon icon--medium icon--link">pdf</i>
            <a class="t-data t-bold" target="_blank" [attr.href]="planDetailConfigs?.summary_benefits.link" (click)="linkClicked(link)">
                {{ planDetailConfigs?.summary_benefits?.label || 'See All Benefits' }}
            </a>
        </div>

        <div class="plan__card__link" *ngIf="planDetailConfigs.find_doctor">
            <i class="icon icon--small icon--link">{{ planDetailConfigs.find_doctor?.icon || 'doctors' }}</i>
            <a class="t-data t-bold" target="_blank" [attr.href]="planDetailConfigs.find_doctor?.link" (click)="linkClicked(link)">
                {{ planDetailConfigs.find_doctor?.label || 'Find a Doctor' }}
            </a>
        </div>

        <div *ngIf="planDetailConfigs.links">
            <div class="plan__card__link" *ngFor="let link of planDetailConfigs.links">
                <a *ngIf="link.modal; else normalLink" class="t-data t-bold" target="_blank" (click)="modalAction('display', link)">
                    <i class="icon icon--medium icon--link">{{ link.icon || 'person' }}</i>
                    {{ link.label || 'Find a Provider' }}
                </a>
                <ng-template #normalLink>
                    <a class="t-data t-bold" target="_blank" [attr.href]="link.link">
                        <i class="icon icon--medium icon--link">{{ link.icon || 'person' }}</i>
                        {{ link.label || 'Find a Provider' }}
                    </a>
                </ng-template>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #originalPrice>
    <div class="PlanCard__Premium" *ngIf="!!subsidyAmount && !!originalPriceObj?.discountedPrice; else withoutSubsidy">
        <h5 class="t-bold PlanCard__PremiumHeader" data-test-id="planCardPremiumHeader" [innerHTML]="originalPriceObj.discountedPrice"></h5>
        <p class="t-data t-alt" [innerHTML]="originalPriceObj.label"></p>

        <div>
            <p class="t-bold" [innerHTML]="subsidyTextConfig.subsidyLabel"></p>
            <p class="t-data t-alt" [innerHTML]="subsidyTextConfig.originalPriceLabel"></p>
        </div>
    </div>
    <ng-template #withoutSubsidy>
        <div class="PlanCard__Premium">
            <h5 class="t-bold PlanCard__PremiumHeader" data-test-id="planCardPremiumHeader" [innerHTML]="originalPriceObj?.value"></h5>
            <p class="t-data t-alt" [innerHTML]="originalPriceObj?.label"></p>
        </div>
    </ng-template>
</ng-template>

<view-details-modal
    *ngIf="plan && showViewDetailsModal"
    [viewDetailsModalConfig]="viewDetailsModalConfig"
    [plan]="plan"
    [planDetailConfigs]="planDetailConfigs"
    [documentList]="documentList"
    (toggleDetailsModal)="toggleDetailsModal()"
></view-details-modal>

<modal class="PlanCardModal" [config]="textPopUpModalConfig" [condition]="displayModal" (cancel)="modalAction('close')">
    <div body>
        <div class="Modal__Body--PrimaryText" *ngFor="let paragraph of modalContent?.paragraphs">
            {{ paragraph }}
        </div>
    </div>
    <div footer>
        <zip-button class="Modal__Footer--CloseButton" [config]="modalContent?.buttons?.close" (zipButtonClicked)="modalAction('close')">
        </zip-button>
        <zip-button class="Modal__Footer--OpenButton" [config]="modalContent?.buttons?.open" (zipButtonClicked)="modalAction('open')">
        </zip-button>
    </div>
</modal>
