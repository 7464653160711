import Address from './Address.model';

export default class Contact {
    readonly id?: number;
    addresses: Array<Address>;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    fax?: string;
    type?: string;
}
