<div class="universal__shopping__footer__wrapper" [attr.data-footer-layout]="layout">
    <div class="universal__shopping__footer">
        <div *ngIf="enrollmentSubfooter?.enable && showStateHeaderFooter" class="container enrollment__subfooter">
            <p class="t-data">{{ enrollmentSubfooter?.code }}</p>
            <p class="t-data">{{ enrollmentSubfooter?.message }}</p>
            <p class="t-data">{{ enrollmentSubfooter?.coded_date }}</p>
            <p class="t-data">{{ enrollmentSubfooter?.copyright }}</p>
        </div>

        <div class="universal__shopping__footer__content container">
            <div class="universal__shopping__footer__content__section" *ngIf="contact1 || contact2">
                <h2 *ngIf="!useSmallerTitle" class="t-bold" [innerHTML]="contactTitle"></h2>
                <h4 *ngIf="useSmallerTitle" class="t-bold" [innerHTML]="contactTitle"></h4>
                <div class="model-attribute-group">
                    <img
                        *ngIf="showImg"
                        src="https://zipari-uploads-dev.s3.amazonaws.com/files/36922e34-a750-47d2-a484-262b3319aeeb.png"
                        alt="Footer"
                    />
                    <div class="model-attribute-columns universal__shopping__footer__data-columns">
                        <ng-container *ngFor="let col of [contact1, contact2]; index as i">
                            <model-attribute
                                *ngFor="let config of col.attributes; index as j"
                                class="model-attribute universal__shopping__footer__data-column"
                                [config]="config"
                                [attr.style]="getGridAreaStyle(i, j)"
                            ></model-attribute>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="universal__shopping__footer__content__section">
                <h4 class="t-bold">{{ quickLinksHeaderLabel }}</h4>

                <div class="quick-links-container" *ngIf="quickLinks1?.attributes || quickLinks2?.attributes">
                    <div class="quick-links universal__shopping__footer__data-columns">
                        <ng-container *ngFor="let col of [quickLinks1?.attributes, quickLinks2?.attributes]; index as i">
                            <p
                                *ngFor="let link of col; index as j"
                                class="universal__shopping__footer__data-column t-data"
                                [attr.style]="getGridAreaStyle(i, j)"
                            >
                                <a
                                    class="t-data t-link"
                                    [href]="link.value"
                                    [attr.aria-label]="link.value"
                                    target="_blank"
                                    (click)="sendDataAnalytics(link)"
                                    >{{ link.label }}</a
                                >
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="universal__shopping__footer__content__section universal__shopping__footer__text" *ngIf="contactText">
                <p class="t-data" *ngFor="let text of contactText" [innerHtml]="text"></p>
            </div>

            <div class="universal__shopping__footer__social universal__shopping__footer__content__section" *ngIf="socialMedia">
                <a *ngIf="socialMedia.facebook" [href]="socialMedia.facebook" target="_blank" aria-label="Facebook">
                    <img
                        class="icon icon--medium icon--pointer sm--icon"
                        alt="Facebook"
                        src="https://zipari-uploads-dev.s3.amazonaws.com/files/80b82888-5af0-4aff-b06b-dbc13b9a2366.png"
                    />
                </a>
                <a *ngIf="socialMedia.twitter" [href]="socialMedia.twitter" target="_blank" aria-label="Twitter">
                    <img
                        class="icon icon--medium icon--pointer sm--icon"
                        alt="Twitter"
                        src="https://zipari-uploads-dev.s3.amazonaws.com/files/2991d83e-4368-434f-9c8c-6dd7210c56bc.png"
                    />
                </a>
                <a *ngIf="socialMedia.instagram" [href]="socialMedia.instagram" target="_blank" aria-label="Instagram">
                    <img
                        class="icon icon--medium icon--pointer sm--icon"
                        alt="Instagram"
                        src="https://zipari-uploads-dev.s3.amazonaws.com/files/045fdc48-53a3-4b8c-979f-edfb1a344f3a.png"
                    />
                </a>
            </div>
        </div>
    </div>

    <div class="universal__shopping__sub__footer">
        <div class="universal__shopping__sub__footer__content container">
            <p class="t-data" [innerHTML]="'© ' + copyright.text"></p>
            <a
                tabindex="-1"
                aria-label="Copyright Url"
                href="https://www.trustarc.com/consumer-resources/dispute-resolution-2/"
                target="_blank"
            >
                <img
                    *ngIf="copyright.image && copyright.image === 'TRUSTe'"
                    [attr.alt]="copyright.image"
                    src="https://zipari-uploads-dev.s3.amazonaws.com/files/e7ca7470-e072-496e-9f0a-b7b3e1e7cf83.png"
                />
            </a>
        </div>
    </div>
</div>
