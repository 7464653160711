<form-control [group]="form" [config]="toggleConfig" [control]="toggle"> </form-control>

<ng-container *ngIf="toggle.value">
    <form-control
        (selected)="handleBrokerSelected($event)"
        [group]="form"
        [config]="brokerAssistedControlConfig"
        [control]="brokerTypeahead"
    >
    </form-control>
</ng-container>
