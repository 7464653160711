import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ModalConfig, ModalHeader, ModalTypes } from '@zipari/shared-ds-util-modal';
import { ColumnConfig } from '@zipari/design-system/lib/chords/table/models';
import { ConfigService } from '@zipari/shared-sbp-services';
import { PopUpModalConfig } from '@zipari/shared-sbp-templates';
import { cloneObject } from '@zipari/web-utils';
import {
    dragAndDropPopUpModalConfig,
    gridPreferencePageName,
    propOfNeverHiddenColumn,
    gridErrorMessage,
    columnIcons,
    iconLabel,
    actionContent,
    ariaLabel,
} from './drag-drop-modal.constant';

@Component({
    selector: 'drag-drop-modal',
    templateUrl: './drag-drop-modal.component.html',
    styleUrls: ['./drag-drop-modal.component.scss'],
})
export class DragDropComponent implements OnInit {
    @Input() pageConfig: ColumnConfig[];
    @Input() errorResponse: Error;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<ColumnConfig[]>();

    popUpModalConfig: ModalConfig;
    dragAndDropModalConfig: PopUpModalConfig;
    dragAndDropColumns: ColumnConfig[];

    errorMessage: string = gridErrorMessage;

    constructor(private configService: ConfigService) {}

    ngOnInit() {
        const modalConfigs: PopUpModalConfig = this.configService.getPageConfig(gridPreferencePageName);
        this.dragAndDropModalConfig = { ...dragAndDropPopUpModalConfig, ...modalConfigs };

        this.popUpModalConfig = new ModalConfig({
            type: ModalTypes.popup,
            header: new ModalHeader({ title: this.dragAndDropModalConfig.header }),
        });

        this.dragAndDropColumns = cloneObject(this.pageConfig);
    }

    cancelDragAndDrop(): void {
        this.cancel.emit();
    }

    saveGridPreference(): void {
        this.save.emit(this.dragAndDropColumns);
    }

    hidableField(columnProp: string): boolean {
        if (!columnProp) return false;
        return !propOfNeverHiddenColumn.includes(columnProp);
    }

    toggleShowHide(columnIndex: number): void {
        this.dragAndDropColumns[columnIndex].isHidden = !this.dragAndDropColumns[columnIndex].isHidden;
    }

    getIconName(column: ColumnConfig): string {
        return column.isHidden ? columnIcons.eye : columnIcons.eye_active;
    }

    getIconAriaLabel(column: ColumnConfig): string {
        return column.isHidden ? ariaLabel.show : ariaLabel.hide;
    }

    getIconLabel(column: ColumnConfig): string {
        return column.isHidden ? iconLabel.show : iconLabel.hide;
    }

    getActionContent(column: ColumnConfig): string {
        return column.isHidden ? actionContent.show : actionContent.hide;
    }

    public resortColumn(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.dragAndDropColumns, event.previousIndex, event.currentIndex);
    }
}
