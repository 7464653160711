<div [formGroup]="form" [libGridCell]="config.grid">
    <ng-container *ngIf="config.type === customFormElements.hidden">
        <hidden [group]="form" [config]="config"></hidden>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.termsAndConditions">
        <terms-and-conditions [config]="config" [group]="form"> </terms-and-conditions>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.coverageEffective">
        <coverage-effective-control (formCreated)="formCreated.emit($event)" [group]="form" [config]="config" [prefillVal]="prefillVal">
        </coverage-effective-control>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.autopay">
        <autopay [form]="form" [config]="config"> </autopay>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.broker_assistance">
        <broker-assistance [config]="config" [form]="form"> </broker-assistance>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.passwords">
        <passwords [config]="config" [group]="form"> </passwords>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.whoseCovered">
        <whose-covered
            (formCreated)="formCreated.emit($event)"
            [group]="form"
            [direction]="direction"
            [config]="config"
            [prefillVal]="prefillVal"
        >
        </whose-covered>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.industryGroup">
        <industries [formControlName]="config.prop" [direction]="direction" [config]="config"> </industries>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.address">
        <address [formControlName]="config.prop" [config]="config"></address>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.companyAddress">
        <company-address [formControlName]="config.prop" [config]="config"> </company-address>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.basicAddress">
        <basic-address [direction]="direction" [config]="config" [group]="form"> </basic-address>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.officeHours">
        <office-hours [direction]="direction" [config]="config" [group]="form"> </office-hours>
    </ng-container>

    <ng-container *ngIf="config.type === customFormElements.formArray">
        <form-array [group]="form" [formArrayConfig]="config" [direction]="direction"> </form-array>
    </ng-container>

    <div *ngIf="config.type === customFormElements.dropdownOther && form.get(config.prop)">
        <dropdown-other [direction]="direction" [config]="config" [group]="form"> </dropdown-other>
    </div>

    <div *ngIf="config.type === customFormElements.optionalDate">
        <optional-date [direction]="direction" [config]="config" [group]="form"> </optional-date>
    </div>

    <div *ngIf="config.type === customFormElements.inputNA && form.get(config.prop)">
        <input-na [formControlName]="config.prop" [direction]="direction" [config]="config"> </input-na>
    </div>

    <div *ngIf="config.type === customFormElements.ssnMultiField">
        <ssn-multi-field [formControlName]="config.prop" [config]="config" [group]="form"> </ssn-multi-field>
    </div>
</div>
