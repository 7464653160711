export const modelAttributeConfig = {
    value: '${plan.price}',
    label: '${plan.display_name}',
    format: 'CURRENCY',
};

export const buttonConfig = {
    content: 'Enroll',
    level: 'medium',
};
