import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    AddressService,
    AuthService,
    ConfigService,
    DocumentsService,
    SessionService,
    SQUIDEX_AUTH_INTERCEPTOR_PROVIDER,
    WINDOW_PROVIDERS,
} from '@zipari/shared-sbp-services';

@NgModule({
    imports: [HttpClientModule],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ConfigService,
                AuthService,
                SessionService,
                AddressService,
                DocumentsService,
                SQUIDEX_AUTH_INTERCEPTOR_PROVIDER,
                WINDOW_PROVIDERS,
            ],
        };
    }
}
