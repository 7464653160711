export const mockDragAndDropColumn1 = {
    name: 'Quote Status',
    prop: 'status',
    sortable: true,
    stackedIcons: false,
    icon: null,
    sortByProp: 'status',
};

export const mockDragAndDropColumn2 = {
    isHidden: true,
    name: 'Broker Name',
    prop: null,
    sortable: true,
    stackedIcons: false,
    icon: null,
    sortByProp: 'name',
};

export const mockDragAndDropColumn3 = {
    name: 'Quote ID',
    prop: 'id',
    sortable: true,
    stackedIcons: false,
    icon: null,
    sortByProp: 'id',
};

export const mockDragAndDropColumns = [mockDragAndDropColumn3];

export const mockColumnBeforeSettingPreference = [mockDragAndDropColumn1, mockDragAndDropColumn2];

export const mockColumnAfterSettingPreference = [mockDragAndDropColumn2, mockDragAndDropColumn1];

export const mockResortColumnEvent = {
    distance: { x: 7, y: 51 },
    dropPoint: { x: 360, y: 357 },
    event: null,
    isPointerOverContainer: false,
    previousIndex: 0,
    item: null,
    currentIndex: 1,
    container: null,
    previousContainer: null,
};
