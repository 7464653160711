import { Component, OnInit, Input } from '@angular/core';
import { accordion } from '@zipari/design-system';
import { Router } from '@angular/router';

import { NotificationModel } from '../notifications.constant';
import { NotificationsService } from '../notifications.service';

@Component({
    selector: 'all-notifications',
    templateUrl: './all-notifications.component.html',
    styleUrls: ['./all-notifications.component.scss', '../notifications.component.scss'],
    animations: [accordion],
})
export class AllNotificationsComponent implements OnInit {
    @Input() notifications: Array<NotificationModel>;
    @Input() icon: any;

    constructor(private notificationsService: NotificationsService, public router: Router) {}

    ngOnInit() {}

    toggle(notification: NotificationModel) {
        notification.open = !notification.open;

        if (!notification.is_read && notification.open) {
            this.notificationsService.registerNotificationAsRead(notification.id);
        }
    }

    linkClicked(url) {
        if (url.includes('http')) {
            window.open(url, '_blank');
        } else {
            this.router.navigate([url]);
        }
    }
}
