import Contact from './Contact.model';

export default class Household {
    readonly id?: number;
    readonly updated_at?: string;
    created_at?: string;
    household_type?: string;
    household_size?: number;
    household_members?: Array<Contact>;
}
