import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownConfiguration } from '@zipari/shared-ds-util-form';
import { zipcodeLocationEndpoint } from '@zipari/shared-sbp-modules';
import { MARKET_SEGMENTS, marketSegment } from '@zipari/shared-sbp-constants';
import { Address } from '@zipari/shared-sbp-models';
import { APIResponse, ConfigService } from '@zipari/shared-sbp-services';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
export interface ZipcodeLocationData {
    cities: Array<{
        city_name: string;
        zipcodes: Array<any>;
    }>;
    county_code: string;
    county_name: string;
    plan_type_name: Array<string>;
    plan_year: Array<number>;
    state_code: string;
    state_name: string;
    zipcodes: Array<any>;
}

export interface VerifyAddressResponse {
    address?: {
        city: string;
        state: string;
        street_name_1: string;
        street_name_2: string;
        zip_code: string;
    };
    id?: string;
    errors?: any;
}

export interface VerifyAddressPayload {
    street_name_1: string;
    street_name_2?: string;
    city: string;
    state: string;
    zip_code?: string;
}

@Injectable()
export class AddressService {
    readonly verifyAddressEndpoint = 'api/locations/verify_us_address/';
    private cache = new Map<string, Observable<Array<ZipcodeLocationData>>>();

    constructor(private httpClient: HttpClient, private configService: ConfigService) {}

    public getZipcodeLocationData(zipCode: string, breakCache: boolean = false): Observable<Array<ZipcodeLocationData>> {
        if (!breakCache && this.cache.has(zipCode)) {
            return this.cache.get(zipCode);
        }

        const marketSegmentValue = this.getMarketSegmentValue();
        const zipcodeData$ = this.httpClient
            .get<APIResponse<ZipcodeLocationData>>(`${zipcodeLocationEndpoint}${zipCode}?market_segment=${marketSegmentValue}`)
            .pipe(
                map((zipcodeLocationResponse: APIResponse<ZipcodeLocationData>) => zipcodeLocationResponse.results),
                shareReplay(),
                catchError((err) => {
                    return throwError(err);
                })
            );
        this.cache.set(zipCode, zipcodeData$);

        return zipcodeData$;
    }

    public verifyAddress(addressToVerify: Address): Observable<VerifyAddressResponse> {
        const payload: VerifyAddressPayload = this.mapVerifyAddressPayload(addressToVerify);
        return this.httpClient.post<VerifyAddressResponse>(this.verifyAddressEndpoint, payload).pipe(
            map((response) => {
                if (response.errors) {
                    return response;
                }
                const { address, id } = response;
                return {
                    id,
                    address: {
                        ...address,
                        zip_code: address.zip_code.replace(/-\d*/, ''),
                    },
                };
            })
        );
    }

    public validateAddressPayload(address: Address): boolean {
        const addressPayload: VerifyAddressPayload = this.mapVerifyAddressPayload(address);
        const hasStreetName = !!addressPayload.street_name_1;
        const hasCityName = !!addressPayload.city;
        const hasState = !!addressPayload.state;

        return hasStreetName && hasCityName && hasState;
    }

    public mapVerifyAddressPayload(address: any): VerifyAddressPayload {
        return {
            street_name_1: address.street_name_1 || address.address_1 || address.streetName1,
            street_name_2: address.street_name_2 || address.address_2 || address.streetName2,
            city: address.city || address.city_name || address.cityName,
            state: address.state || address.stateCode,
            zip_code: address.zipcode || address.zipCode || address.zip_code,
        };
    }

    public getStateForZipCode(zipcode: string): Observable<string> {
        return this.getZipcodeLocationData(zipcode).pipe(map((zipcodeData) => zipcodeData[0] && zipcodeData[0].state_code));
    }

    /**
     * returns formatted city or county options to populate dropdown based on  provided zipcode
     * @param zipCode
     * @param type
     */
    public getCityOrCountyOverride(zipCode, type: 'city' | 'county') {
        switch (type) {
            case 'city':
                return this.getCityOptions(zipCode);
            case 'county':
                return this.getCountyOptions(zipCode);
            default:
                break;
        }
    }

    /**
     * transform zipcode response and return an array of city options w/ duplicates removed
     * @param zipcode
     */
    public getCityOptions(zipcode: string): Observable<Array<{ label: string; value: string }>> {
        return this.getZipcodeLocationData(zipcode).pipe(
            map((data) =>
                data.reduce((cityOptions: Array<{ label: string; value: string }>, zipData) => {
                    // cities w/ dupes removed - formatted for dropdown
                    const newOptions = zipData.cities.reduce((formattedOptions, city) => {
                        if (!cityOptions.some((option) => option.value === city.city_name)) {
                            formattedOptions.push({
                                label: city.city_name,
                                value: city.city_name,
                            });
                        }
                        return formattedOptions;
                    }, []);

                    return cityOptions.concat(newOptions);
                }, [])
            )
        );
    }

    public getCountyOptions(
        zipcode: string,
        utils?: { config: DropdownConfiguration; control: FormControl },
        breakCache: boolean = false
    ): Observable<Array<{ label: string; value: any }>> {
        return this.getZipcodeLocationData(zipcode, breakCache).pipe(
            map((zipcodeData) =>
                zipcodeData.map(({ county_code, county_name }) => {
                    return {
                        label: county_name,
                        value: county_code,
                    };
                })
            ),
            tap((zipCodeData) => {
                if (utils) {
                    // make sure to salvage the placeholder if there is one
                    const placeholder = utils.config.options.find((option) => option.value === null);
                    if (placeholder) {
                        utils.config['options'] = [placeholder];
                    } else {
                        utils.config['options'] = [];
                    }

                    if (utils.config) {
                        utils.config['options'] = utils.config['options'].concat(zipCodeData);
                    }

                    if (zipCodeData.length === 1 && utils.control) {
                        utils.control.patchValue(zipCodeData[0].value);
                    }
                }
            })
        );
    }

    public checkForPOBOX(addressLine: string): boolean {
        const poBoxRegex = /\b(?:p\.?\s*o\.?)(\s+)?(?:box|[0-9]*)?\b/gim;
        return poBoxRegex.test(addressLine);
    }

    private getMarketSegmentValue(): string {
        const isMedicare = this.configService.app.includes(MARKET_SEGMENTS.MEDICARE);

        return isMedicare ? MARKET_SEGMENTS.MEDICARE : MARKET_SEGMENTS.INDIVIDUAL;
    }

    public getMarketSegmentParam(): string {
        return `${marketSegment}=${this.getMarketSegmentValue()}`;
    }
}
