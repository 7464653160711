import { clientsURLS } from '../app-urls.constant';
import { newClientConfig } from './review-stepper.constants';

export const mockReviewStep = [
    {
        review: [
            {
                label: 'Get Started',
                icon: 'auths',
                type: 'attributes',
                stepIndex: 1,
                attributes: [
                    {
                        label: 'Coverage Type',
                        value: '${demographics.displayCoverageType}',
                    },
                    {
                        label: 'Coverage Start Date',
                        value: '${demographics.coverage_effective_date}',
                        format: 'DATE',
                    },
                    {
                        label: 'ZIP Code',
                        value: '${getStarted.zipcode}',
                    },
                    {
                        label: 'County',
                        value: '${county.county_name}',
                    },
                ],
                stepType: 'demographics',
            },
            {
                label: 'Who Needs Coverage',
                icon: 'auths',
                type: 'attributes',
                stepIndex: 1,
                attributes: [
                    {
                        title: 'Subscriber',
                        prop: 'coverage.whoseCovered.subscriber',
                        attributes: [
                            {
                                label: 'Date of Birth',
                                value: '${coverage.whoseCovered.subscriber.date_of_birth}',
                                format: 'DATE',
                            },
                            {
                                label: 'Gender',
                                value: '${coverage.whoseCovered.subscriber.gender}',
                                format: 'SNAKE_CASE_TO_TITLE',
                            },
                            {
                                label: 'Tobacco User',
                                value: '${coverage.whoseCovered.subscriber.tobacco_user}',
                                format: 'YES_NO',
                                noValueMessage: 'HIDE',
                            },
                        ],
                    },
                    {
                        title: 'Spouse',
                        prop: 'coverage.whoseCovered.spouse',
                        attributes: [
                            {
                                label: 'Date of Birth',
                                value: '${coverage.whoseCovered.spouse.date_of_birth}',
                                format: 'DATE',
                            },
                            {
                                label: 'Gender',
                                value: '${coverage.whoseCovered.spouse.gender}',
                                format: 'SNAKE_CASE_TO_TITLE',
                            },
                            {
                                label: 'Tobacco User',
                                value: '${coverage.whoseCovered.spouse.tobacco_user}',
                                format: 'YES_NO',
                                noValueMessage: 'HIDE',
                            },
                        ],
                    },
                    {
                        title: 'Dependent ${displayIndex}',
                        prop: 'coverage.whoseCovered.dependents',
                        repeat: 'coverage.whoseCovered.dependents',
                        attributes: [
                            {
                                label: 'Date of Birth',
                                value: '${coverage.whoseCovered.dependents.${index}.date_of_birth}',
                                format: 'DATE',
                            },
                            {
                                label: 'Gender',
                                value: '${coverage.whoseCovered.dependents.${index}.gender}',
                                format: 'SNAKE_CASE_TO_TITLE',
                                noValueMessage: 'HIDE',
                            },
                            {
                                label: 'Tobacco User',
                                value: '${coverage.whoseCovered.dependents.${index}.tobacco_user}',
                                format: 'YES_NO',
                                noValueMessage: 'HIDE',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        review: [
            {
                label: 'Product/Plan Selection',
                type: 'planDisplay',
                stepIndex: 2,
            },
        ],
    },
];

export const mockClientDetail = {
    id: 479,
    broker: 'Brokerdev',
    broker_name: ' ',
    agency: null,
    agency_name: null,
    email: 'sparrow@kzrxyka.com',
    phone_number: '1034686063',
    webuser_id: '',
    status: '',
    group: null,
    external_lead_id: null,
    created_at: '2020-06-01T14:04:38.041460',
    updated_at: '2020-07-16T13:34:09.889133',
    effective_date: null,
    termination_date: null,
    tenant: 'democare',
    first_name: 'Kaushal',
    last_name: 'Broker',
    notes: null,
    locked: false,
    market_segment: 'individual',
    home_phone: '1034686063',
    email_address: 'sparrow@kzrxyka.com',
};

export const mockClientConfig = {
    checkApplicationForSubmittedWorkflow: true,
    detailPageName: 'client-details',
    endpoint: `/${clientsURLS.brokerPortalEndpoint}`,
    forceUnique: 'force_unique=true',
    addRow: {
        header: 'New Client',
        button: {
            content: 'New Client',
            icon: '',
            level: 'high',
        },
        save: 'Save New Client',
        messages: {
            success: 'New Client Successfully Created',
            error: 'New Client Could Not Be Created',
        },
        cancelConfirm: {
            header: 'Sure You Want to Exit?',
            body: "We will not save any information that you've added thus far.",
            confirm: 'Yes, I Want to Exit',
            cancel: true,
        },
        form: {
            title: '',
            groups: [
                {
                    header: 'Basic Information',
                    prop: 'basic_information',
                    controls: newClientConfig,
                },
            ],
        },
        attributes: {
            label: '',
            attributes: [
                {
                    label: 'First Name',
                    prop: 'first_name',
                    format: '',
                },
                {
                    label: 'Last Name',
                    prop: 'last_name',
                    format: '',
                },
                {
                    label: 'Phone',
                    prop: 'phone_number',
                    format: 'PHONE',
                },
                {
                    label: 'Email',
                    prop: 'email',
                    format: 'EMAIL',
                },
            ],
        },
    },
    table: {},
};

export const mockSpouse = {
    sex: 'male',
    name: '',
    uuid: 'db262cfd-3f78-48e7-916e-2001f3eea0a5',
    gender: 'male',
    memberType: 'Spouse',
    tobacco_use: false,
    tobacco_user: false,
    coverage_type: ['health'],
    date_of_birth: '1999-07-13',
    memberTypeUnique: 'Spouse',
    medicare_enrolled: 'false',
};

export const mockSubscriber = {
    sex: 'male',
    name: '',
    uuid: '4317eaca-bffc-4586-a7cf-e2482a417c3c',
    email: null,
    phone: null,
    gender: 'male',
    home_phone: null,
    memberType: 'Subscriber',
    tobacco_use: false,
    tobacco_user: false,
    coverage_type: ['health'],
    date_of_birth: '1999-02-23',
    email_address: null,
    primary_phone: null,
    memberTypeUnique: 'Subscriber',
    medicare_enrolled: 'false',
};

export const mockChildDependents = [
    {
        sex: 'male',
        name: '',
        uuid: 'bd8ac8ff-1ab2-4044-a761-ec38e995a4c4',
        gender: 'male',
        memberType: 'Dependent',
        tobacco_use: false,
        tobacco_user: false,
        coverage_type: ['health'],
        date_of_birth: '2023-02-13',
        memberTypeUnique: 'Dependent0',
    },
];

export const mockAdultDependents = [
    {
        sex: 'male',
        name: '',
        uuid: 'bd8ac8ff-1ab2-4044-a761-ec38e995a4c5',
        gender: 'male',
        memberType: 'Parent/StepParent',
        tobacco_use: false,
        tobacco_user: false,
        coverage_type: ['health'],
        date_of_birth: '1945-02-23',
        memberTypeUnique: 'Parent/StepParent0',
    },
    {
        sex: 'male',
        name: '',
        uuid: 'bd8ac8ff-1ab2-4044-a761-ec38e995a4c6',
        gender: 'male',
        memberType: 'Parent/StepParent',
        tobacco_use: false,
        tobacco_user: false,
        coverage_type: ['health'],
        date_of_birth: '1947-02-23',
        memberTypeUnique: 'Parent/StepParent1',
    },
];

export const mockDependents = [...mockChildDependents, ...mockAdultDependents];

export const mockMembers = [mockSubscriber, mockSpouse, ...mockDependents];

export const mockReviewQuoteWorkflow = {
    id: 366756,
    source: [],
    target: [],
    relations: {},
    state: 'in_progress',
    web_session_id: 'ibozh0o4ejwzp1y3jqft96tndl2pf3dy',
    webuser_id: 10013679,
    current_step_number: 4,
    steps: [],
    data: {
        agency_id: '',
        broker_id: '181167',
        client_id: '20043',
        created_by: {
            username: 'broker',
            last_name: 'Brokerson',
            first_name: 'Broker',
        },
        query_params: {
            client_id: '20043',
        },
        broker_internal_id: 147,
    },
    created_at: '2023-02-14T04:14:15.044795',
    schema: {},
    type: 'bp_individual_quote',
    current_step: {},
    values: {
        client: {
            id: 20043,
            city: 'Pune',
            email: 'ram.upadhyay@zipari.com',
            group: null,
            notes: null,
            state: 'GA',
            agency: '991387',
            broker: '181167',
            locked: false,
            status: null,
            tenant: 'democare',
            zipcode: '00000',
            address_1: 'Hinjewadi',
            address_2: null,
            last_name: 'Upadhyay',
            clientType: 'new',
            created_at: '2022-05-23T22:10:44.286206',
            first_name: 'Ram',
            home_phone: '9876543210',
            updated_at: '2023-02-09T10:12:34.491963',
            webuser_id: '',
            agency_name: 'Topdrive',
            broker_name: 'Yolanthe McQuillan',
            phone_number: '9876543210',
            email_address: 'ram.upadhyay@zipari.com',
            effective_date: null,
            market_segment: 'individual',
            middle_initial: 'U',
            external_lead_id: null,
            termination_date: null,
        },
        county: {
            id: 2243,
            zipcodes: [
                {
                    lat: 42.8579471,
                    lng: -73.9021788,
                    code: '12008',
                },
            ],
            plan_year: [2021, 2022],
            state_code: 'NY',
            state_name: '',
            county_code: '36093',
            county_name: 'Schenectady',
            plan_type_name: [
                'medical_gold',
                'medical_silver',
                'd_snp',
                'medicare_advantage',
                'medical_core',
                'medicare_partd',
                'medical_bronze',
                'vision',
                'medicare_supplement',
                'dental',
                'mapd',
                'medical',
            ],
            has_plans_in_service: null,
        },
        spouse: mockSpouse,
        contact: {
            email: null,
            phone: null,
            county: '36093',
            zipcode: '12008',
            zip_code: '12008',
            home_phone: null,
            county_name: 'Schenectady',
            coverage_type: ['health', {}, {}],
            email_address: null,
            primary_phone: null,
            street_name_1: null,
            street_name_2: null,
            county_display: 'Schenectady',
            secondary_phone: null,
            secondary_phone_number: null,
        },
        members: mockMembers,
        coverage: {
            whoseCovered: {
                spouse: mockSpouse,
                dependents: mockDependents,
                subscriber: mockSubscriber,
                whose_covered: 'family',
            },
        },
        is_spouse: true,
        child_only: false,
        dependents: mockDependents,
        getStarted: {
            county: '36093',
            zipcode: '12008',
            coverage_type: ['health', {}, {}],
            coverage_effective_date: '2023-02-01',
        },
        subscriber: mockSubscriber,
        contact_info: {
            email: null,
            phone: null,
            county: '36093',
            zipcode: '12008',
            zip_code: '12008',
            home_phone: null,
            county_name: 'Schenectady',
            coverage_type: ['health', {}, {}],
            email_address: null,
            primary_phone: null,
            street_name_1: null,
            street_name_2: null,
            county_display: 'Schenectady',
            secondary_phone: null,
            secondary_phone_number: null,
        },
        demographics: {
            county: '36093',
            is_sep: true,
            spouse: mockSpouse,
            contact: {
                email: null,
                phone: null,
                county: '36093',
                zipcode: '12008',
                zip_code: '12008',
                home_phone: null,
                coverage_type: ['health', {}, {}],
                email_address: null,
                primary_phone: null,
                street_name_1: null,
                street_name_2: null,
                secondary_phone: null,
                secondary_phone_number: null,
            },
            zipcode: '12008',
            child_only: false,
            dependents: mockDependents,
            subscriber: mockSubscriber,
            coverage_type: ['health', {}, {}],
            total_members: 3,
            whose_covered: 'family',
            coverage_types: ['health'],
            displayCoverageType: 'Medical',
            coverage_effective_date: '2023-02-01',
        },
        coverage_type: ['health', {}, {}],
        is_dependents: true,
        total_members: 3,
        whose_covered: 'family',
        coverage_types: ['health'],
        effective_date: {
            enrollment_period: 'SEP',
            coverage_effective_date: '2023-02-01',
        },
        county_location: {
            id: 2243,
            zipcodes: [
                {
                    lat: 42.8579471,
                    lng: -73.9021788,
                    code: '12008',
                },
            ],
            plan_year: [2021, 2022],
            state_code: 'NY',
            state_name: '',
            county_code: '36093',
            county_name: 'Schenectady',
            plan_type_name: [
                'medical_gold',
                'medical_silver',
                'd_snp',
                'medicare_advantage',
                'medical_core',
                'medicare_partd',
                'medical_bronze',
                'vision',
                'medicare_supplement',
                'dental',
                'mapd',
                'medical',
            ],
            has_plans_in_service: null,
        },
        enrollment_period: 'SEP',
        permanent_address: {
            state: 'NY',
            county: '36093',
            zipcode: '12008',
            zip_code: '12008',
            county_name: 'Schenectady',
            street_name_1: null,
            street_name_2: null,
            county_display: 'Schenectady',
        },
        special_enrollment: {
            id: 495,
            qle: 'BHSQLE01_Special',
            code: 'BHSQLE01_Special',
            date: '2023-02-13',
            icon: 'birth_adoption',
            label: 'Birth/Adoption/Foster Care',
            state: '',
            reason: 'Birth/Adoption/Foster Care',
            primary: {
                id: 495,
                code: 'BHPQLE01_Special',
                icon: 'birth_adoption',
                label: 'Change in Household Size',
                state: '',
                value: 'BHPQLE01_Special',
                controls: [
                    {
                        prop: 'secondary',
                        type: 'dropdown',
                        label: 'How did this happen?',
                        options: [
                            {
                                label: 'Birth/Adoption/Foster Care',
                                value: 495,
                            },
                            {
                                label: 'Gained a dependent by Court order or child support order',
                                value: 610,
                            },
                            {
                                label: 'Civil union partners (same gender only), Domestic Partners (Same gender only),Common law marriage (from another state)',
                                value: 611,
                            },
                        ],
                        ariaLabel: 'How did this happen?',
                        validators: ['required'],
                    },
                    {
                        max: '9999-01-01',
                        prop: 'date',
                        type: 'date',
                        label: 'When did this happen?',
                        message: 'Qualifying life event date must be within 60 days of application submission date',
                        ariaLabel: 'When did this happen?',
                        validators: ['required', 'sepQualifyingDate'],
                    },
                ],
                helpText: '',
                help_text: '',
                emac_value: '',
                description: 'Change in House Hold Size QLE Description',
                secondary_qles: [
                    {
                        id: 495,
                        code: 'BHSQLE01_Special',
                        icon: 'birth_adoption',
                        label: 'Birth/Adoption/Foster Care',
                        state: '',
                        help_text: '',
                        emac_value: '',
                        description: 'Gained a dependent by birth/adoption/foster care',
                        required_documents: {
                            text: '•\tBirth/Adoption/foster care',
                            display: [
                                {
                                    docs: [
                                        [
                                            {
                                                docType: 'Birth certificate',
                                            },
                                            {
                                                docType: 'Placement documents',
                                            },
                                        ],
                                    ],
                                    type: 'details',
                                },
                            ],
                            docTypes: [
                                {
                                    label: 'Birth/Adoption/foster care',
                                    value: 'Birth/Adoption/foster care',
                                },
                            ],
                        },
                    },
                    {
                        id: 610,
                        code: 'BHSQLE02_Special',
                        icon: 'birth_adoption',
                        label: 'Gained a dependent by Court order or child support order',
                        state: '',
                        help_text: 'Gained a dependent by Court order or child support order',
                        emac_value: '',
                        description: 'Gained a dependent by Court order or child support order',
                        required_documents: {
                            text: '•Gained a dependent by Courtorder or child support order',
                            display: [
                                {
                                    docs: [
                                        [
                                            {
                                                docType: 'Child support or other court order',
                                            },
                                        ],
                                    ],
                                    type: 'details',
                                },
                            ],
                            docTypes: [
                                {
                                    label: 'Gained a dependent by Court order or child support order',
                                    value: 'Gained a dependent by Court order or child support order',
                                },
                            ],
                        },
                    },
                    {
                        id: 611,
                        code: 'BHSQLE03_Special',
                        icon: 'birth_adoption',
                        label: 'Civil union partners (same gender only), Domestic Partners (Same gender only),Common law marriage (from another state)',
                        state: '',
                        help_text:
                            'Civil union partners (same gender only), Domestic Partners (Same gender only), Common law marriage (from another state)',
                        emac_value: '',
                        description:
                            'Civil union partners (same gender only), Domestic Partners (Same gender only),Common law marriage (from another state)',
                        required_documents: {
                            text: '•Civil union partners (same gender only),Domestic Partners (Same gender only), Common law marriage (from another state)',
                            display: [
                                {
                                    docs: [
                                        [
                                            {
                                                docType: 'Marriage Certificate',
                                            },
                                            {
                                                docType: 'Birth Certificate',
                                            },
                                            {
                                                docType: 'Civil Union Certificate',
                                            },
                                            {
                                                docType: 'Domestic Partner Certificate',
                                            },
                                        ],
                                    ],
                                    type: 'details',
                                },
                            ],
                            docTypes: [
                                {
                                    label: 'Civil union partners (same gender only), Domestic Partners (Same gender only), Common law marriage (from another state)',
                                    value: 'Civil union partners (same gender only), Domestic Partners (Same gender only), Common law marriage (from another state)',
                                },
                            ],
                        },
                    },
                ],
                required_documents: {},
                secondary_qle_options: [],
            },
            help_text: '',
            emac_value: '',
            description: 'Gained a dependent by birth/adoption/foster care',
            required_documents: {
                text: '•\tBirth/Adoption/foster care',
                display: [
                    {
                        docs: [
                            [
                                {
                                    docType: 'Birth certificate',
                                },
                                {
                                    docType: 'Placement documents',
                                },
                            ],
                        ],
                        type: 'details',
                    },
                ],
                docTypes: [
                    {
                        label: 'Birth/Adoption/foster care',
                        value: 'Birth/Adoption/foster care',
                    },
                ],
            },
            updated_coverage_effective_date: '2023-02-01',
        },
        memberCoverageOption: {},
        coverage_effective_date: '2023-02-01',
        plans: [
            {
                id: 101591,
                hmo: null,
                hsa: true,
                year: 2023,
                index: 0,
                links: [],
                price: 35,
                county: {
                    county_code: '36093',
                    county_name: 'Schenectady',
                },
                add_ons: [],
                premium: null,
                benefits: [
                    {
                        type: null,
                        label: 'Monthly Plan Premium',
                        value: 'You must pay an additional $17 per month.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Deductible',
                        value: 'There is no deductible.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Annual Benefit Maximum',
                        value: 'There is no annual maximum limit.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Fluoride',
                        value: 'Fluoride treatment - twice per calendar year.',
                        benefit_display_index: null,
                    },
                ],
                hios_ids: null,
                networks: [],
                selected: true,
                comparing: false,
                documents: {
                    plancontract: {
                        type: 'plancontract',
                        document_id: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                    },
                    summaryofbenefit: {
                        type: 'summaryofbenefit',
                        document_id: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                    },
                },
                is_active: true,
                plan_type: 'dental',
                deductible: 700,
                generic_rx: '',
                metal_tier: '',
                plan_codes: [],
                external_id: 'ZIPBH2023-005',
                star_rating: 3,
                display_name: 'ZipBrandHealth Dental Plan',
                is_benchmark: false,
                primary_care: '',
                static_price: 25,
                exchange_type: null,
                internal_name: null,
                member_prices: {
                    subscriber: {
                        age: 23,
                        price: '35.00',
                        gender: null,
                        is_eligible: true,
                        tobacco_use: false,
                        date_of_birth: '1999-02-23',
                    },
                },
                network_codes: [],
                rating_region: {
                    plan_year: 2023,
                    region_name: 'rating_area_1',
                },
                is_auto_bundle: false,
                market_segment: 'individual',
                plan_variation: '00',
                is_catastrophic: false,
                primary_benefits: [
                    {
                        type: null,
                        label: 'Copay',
                        value: 'You pay a $0 copay for routine dental services.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Routine Exams',
                        value: 'Routine oral exams - two per calendar year.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Periodontal Exam',
                        value: 'Comprehensive periodontal exam - one per calendar year.',
                        benefit_display_index: null,
                    },
                ],
                deductible_family: 0,
                formattedBenefits: [
                    {
                        type: null,
                        label: 'Monthly Plan Premium',
                        value: 'You must pay an additional $17 per month.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Deductible',
                        value: 'There is no deductible.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Annual Benefit Maximum',
                        value: 'There is no annual maximum limit.',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Fluoride',
                        value: 'Fluoride treatment - twice per calendar year.',
                        benefit_display_index: null,
                    },
                ],
                max_out_of_pocket: 2500,
                percent_of_premium: '',
                max_out_of_pocket_family: 0,
                plan_marketing_description: 'Basic Dental Plan',
            },
            {
                id: 101588,
                hmo: null,
                hsa: true,
                year: 2023,
                index: 0,
                links: [],
                price: 450,
                county: {
                    county_code: '36093',
                    county_name: 'Schenectady',
                },
                add_ons: [],
                premium: null,
                benefits: [
                    {
                        type: null,
                        label: 'Deductible',
                        value: '3900',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'In Network Out Of Pocket Maximum',
                        value: '9900',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Inpatient Physician and Surgical Services',
                        value: 'Copay - $0.00 after deductible: Coinsurance - Not Applicable',
                        benefit_display_index: null,
                    },
                ],
                hios_ids: null,
                networks: [],
                selected: true,
                comparing: false,
                documents: {
                    plancontract: {
                        type: 'plancontract',
                        document_id: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                    },
                    summaryofbenefit: {
                        type: 'summaryofbenefit',
                        document_id: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                    },
                },
                is_active: true,
                plan_type: 'medical',
                deductible: 3800,
                generic_rx: '',
                metal_tier: 'Core',
                plan_codes: [],
                external_id: 'ZIPBH2023-004',
                star_rating: 3.5,
                display_name: 'ZipBrandHealth Medical Core Plan',
                is_benchmark: false,
                primary_care: '',
                static_price: 395,
                exchange_type: null,
                internal_name: null,
                member_prices: {
                    subscriber: {
                        age: 23,
                        price: '450.00',
                        gender: null,
                        is_eligible: true,
                        tobacco_use: false,
                        date_of_birth: '1999-02-23',
                    },
                },
                network_codes: [],
                rating_region: {
                    plan_year: 2023,
                    region_name: 'rating_area_1',
                },
                is_auto_bundle: false,
                market_segment: 'individual',
                plan_variation: '00',
                is_catastrophic: false,
                primary_benefits: [
                    {
                        type: null,
                        label: 'Office Visits',
                        value: '75',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Specialist Visits',
                        value: '100',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Emergency Coverage',
                        value: 'Copay - $50.00 with deductible: Coinsurance - 50.00% after deductible',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Generic Drugs',
                        value: 'Copay - $25.00: Coinsurance - Not Applicable',
                        benefit_display_index: null,
                    },
                ],
                deductible_family: 0,
                formattedBenefits: [
                    {
                        type: null,
                        label: 'Deductible',
                        value: '3900',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'In Network Out Of Pocket Maximum',
                        value: '9900',
                        benefit_display_index: null,
                    },
                    {
                        type: null,
                        label: 'Inpatient Physician and Surgical Services',
                        value: 'Copay - $0.00 after deductible: Coinsurance - Not Applicable',
                        benefit_display_index: null,
                    },
                ],
                max_out_of_pocket: 10000,
                percent_of_premium: '',
                max_out_of_pocket_family: 0,
                plan_marketing_description: 'Core Plan',
            },
            [{ id: 101589 }],
        ],
        onExchange: false,
        offExchange: true,
    },
    helpers: {
        checkIfStepTypeExists: function (params: string) {
            return params;
        },
        getStepNumberByStepType: function (stepType: string) {
            return 1;
        },
    },
};

export const mockReviewQuoteWorkflowValue = mockReviewQuoteWorkflow.values;

export const mockReviewQuoteWorkflowData = mockReviewQuoteWorkflow.data;

export const mockReviewQuoteCurrentStep = {
    label: 'Review',
    type: 'review',
    printConfig: {
        type: 'pdf',
        fileName: 'quote_pdf',
        zip_pdf_main_css: 'democare.quote_pdf.template',
        zip_pdf_main_html: 'democare.quote_pdf.template',
        static_values: {
            logo: 'https://zipari-assets-prod.s3-us-west-1.amazonaws.com/melody/melody.0.0.42/img/brand/Friday_logo_scalable.svg',
        },
        endpoint: 'api/documents/external/persist/?download=True',
    },
    allowEditInformation: true,
    editConfirmation: {
        modalConfig: {
            bodyText:
                'You are about to navigate to a previous step in the application. A new application is required to change your coverage type.',
            backdrop: 'true',
            type: 'popup',
        },
        footerButtons: {
            okay: {
                level: 'high',
                content: 'Okay',
            },
            cancel: {
                level: 'high',
                content: 'Cancel',
            },
        },
        zshopButton: {
            action: 'completeQuoteIFPZshopQuote',
            content: 'Complete Quote',
        },
    },
    slideOutConfig: {
        reviewQuoteDescription:
            'You can assign and save this quote to an Existing or New Client. A completed quote will be saved on the Client’s record on your dashboard for future reference. This will help you find this quote if you need it later on.',
        newClientConfig: [
            {
                type: 'text',
                prop: 'first_name',
                label: 'First Name',
                validators: ['required'],
            },
            {
                type: 'text',
                prop: 'last_name',
                label: 'Last Name',
                validators: ['required'],
            },
            {
                type: 'text',
                prop: 'email',
                label: 'Email Address',
                validators: ['required', 'email'],
            },
            {
                type: 'tel',
                prop: 'phone_number',
                mask: 'phone',
                maxLength: 12,
                label: 'Phone Number',
                validators: ['required', 'phone'],
            },
            {
                prop: 'street_name_1',
                type: 'text',
                label: 'Address Line 1',
                maxLength: 25,
                validators: ['required'],
            },
            {
                prop: 'street_name_2',
                type: 'text',
                label: 'Address Line 2',
                maxLength: 25,
                validators: [],
            },
            {
                prop: 'city',
                type: 'text',
                label: 'City',
                maxLength: 25,
                mask: 'alpha',
                validators: ['required'],
            },
            {
                prop: 'state',
                type: 'states',
                label: 'State',
                validators: ['required'],
            },
            {
                prop: 'zip_code',
                type: 'tel',
                label: 'Zip Code',
                maxLength: 5,
                mask: 'number',
                validators: ['required', 'zipcode'],
            },
        ],
        existingClientConfig: {
            type: 'typeahead',
            prop: 'search',
            icon: 'search',
            noResults: 'No clients found',
            label: 'Search by client name',
            endpointDebounce: 100,
            options: [],
            endpoint: `${clientsURLS.brokerPortalEndpoint}?search=`,
            endpointTemplate: {
                label: '${first_name} ${last_name}: ${email}',
                value: '${id}',
            },
        },
    },
    isDownloadButtonDisabled: true,
    reviewStepperHeader: {
        label: 'Please Review Your Quote',
        description:
            'Please review your quote and make any further edits before submitting. A new proposal will be generated when your quote is saved. All saved quotes will be available in your dashboard.',
    },
    showReviewStepperFooterEffectiveLabel: false,
};

export const mockClientError = {
    error: {
        errors: [
            {
                user_error: {
                    email: 'Client Already Enrolled With This Email',
                },
            },
        ],
    },
};

export const mockClientEvent = {
    config: { mockClientConfig },
    event: {
        label: '<b class="form-control__menu__option__highlight">Broker </b>Brokerson: <b class="form-control__menu__option__highlight">broker</b>upadhyay@zipari.com',
        value: '20043',
    },
};
