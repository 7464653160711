export enum chatTypes {
    'liveagent' = 'liveagent',
}

export class ChatConfig {
    type: chatTypes;
    options: LiveAgentOptions;
}

export class LiveAgentOptions {
    apiEndpoint: string;
    buttonIdentifier: string;
    deploymentId: string;
    orgId: string;
}

/** left for documentation */
export class exampleChatOptions {
    [chatTypes.liveagent]: LiveAgentOptions;
}
