<div class="covered_members__form">
    <!-- START SUBSCRIBER SECTION -->
    <ng-container *ngIf="cardsConfig.subscriber && memberCoverageOption.value !== optionsConfig.children.value">
        <header class="demographics__coverage__header u-flex u-justifyBetween">
            <h6 class="t-bold">
                {{ cardsConfig.subscriber?.text?.subscriber || cardsConfig.subscriber?.label }}
            </h6>
        </header>

        <section class="demographics__coverage__card__body">
            <ng-container *ngIf="subscriberMinAgeMsg && coverageConfig?.text?.insurance_to_only_cover_dependent">
                <div class="banner">
                    <p class="t-data">{{ coverageConfig?.text?.insurance_to_only_cover_dependent }}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="maxAgeForSubscriber">
                <div class="banner banner--error">
                    <p class="t-data" [innerHTML]="coverageConfig?.text?.max_age_subscriber_medicare_or_individual_or_dental"></p>
                </div>
            </ng-container>

            <ng-container *ngIf="isSubscriberOverSixtyFive">
                <div class="banner">
                    <p
                        class="t-data"
                        [innerHTML]="cardsConfig?.subscriber?.maxAllowedText || coverageConfig?.subscriber?.maxAllowedText"
                    ></p>
                </div>
            </ng-container>

            <form-group [configs]="cardsConfig.subscriber?.controls" [form]="formGroup.get('subscriber')"> </form-group>
        </section>
    </ng-container>
    <!-- END SUBSCRIBER SECTION -->

    <!-- START SPOUSE SECTION -->
    <ng-container *ngIf="!(memberCoverageOption.value === optionsConfig.children.value)">
        <ng-container *ngIf="formGroup.get('spouse'); else addSpouse">
            <header class="demographics__coverage__header u-flex u-justifyBetween">
                <h6 class="t-bold">
                    <div class="u-flex u-alignCenter">
                        {{ cardsConfig.spouse?.text?.spouse || cardsConfig.spouse?.label }}
                    </div>
                </h6>
                <i class="icon icon--medium icon--pointer" *ngIf="allowAddOrRemoveSpouse" (click)="removeSpouse()">{{
                    removeMemberIcon
                }}</i>
            </header>

            <section class="demographics__coverage__card__body">
                <ng-container *ngIf="maxAgeForSpouse">
                    <div class="banner banner--error">
                        <p class="t-data" [innerHTML]="coverageConfig?.text?.max_age_spouse_medicare_or_individual_or_dental"></p>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSpouseOverSixtyFive">
                    <div class="banner">
                        <p class="t-data" [innerHTML]="cardsConfig?.spouse?.maxAllowedText || coverageConfig?.spouse?.maxAllowedText"></p>
                    </div>
                </ng-container>

                <form-group [configs]="cardsConfig.spouse.controls" [form]="formGroup.get('spouse')"> </form-group>
            </section>
        </ng-container>

        <ng-template #addSpouse>
            <footer *ngIf="allowAddOrRemoveSpouse" class="demographics__coverage__card__footer" (click)="addSpouseControls()">
                <i class="icon icon--medium">add</i>
                <p class="t-data t-bold">Add Spouse</p>
            </footer>
            <br />
        </ng-template>
    </ng-container>
    <!-- END SPOUSE SECTION -->

    <!-- START CHILD ONLY SECTION -->
    <ng-container *ngIf="memberCoverageOption.value === optionsConfig.children.value">
        <div
            class="demographics__coverage__card"
            [ngClass]="'demographics__coverage__card__dependent' + i"
            *ngFor="let child of memberChildrenControls; let i = index"
        >
            <header class="demographics__coverage__header u-flex u-justifyBetween">
                <h6 class="t-bold">
                    {{ cardsConfig.child_only?.text?.child || cardsConfig.child_only?.label }}
                    <span *ngIf="!optionsConfig.children.one_per_policy && !(memberCoverageOption.value === 'children')">{{ i + 1 }}</span>
                </h6>

                <i
                    class="icon icon--medium icon--pointer"
                    *ngIf="allowAddOrRemoveDependents && !cardsConfig['child_only'].isDisabled && memberChildrenControls.length > 1"
                    (click)="removeDependent(formGroup.get('children'), i)"
                >
                    {{ removeMemberIcon }}
                </i>
            </header>

            <section class="demographics__coverage__card__body">
                <ng-container
                    *ngIf="
                        memberCoverageOption.value === optionsConfig.children.value &&
                        (optionsConfig.children.one_per_policy || memberCoverageOption.value === 'children') &&
                        !!cardsConfig.child_only.notes
                    "
                >
                    <div class="banner" *ngFor="let note of cardsConfig.child_only.notes">
                        <p class="t-data">{{ note }}</p>
                    </div>
                </ng-container>

                <form-group [configs]="cardsConfig.child_only.controls" [form]="memberChildrenControls[i]"> </form-group>
            </section>

            <footer class="demographics__coverage__card__footer" *ngIf="isAddNewDependent()" (click)="addDependent('child_only')">
                <icon class="icon" [name]="'add'"></icon>
                <p class="t-data t-bold" [innerHTML]="demographicsConfig?.text?.add_another_child"></p>
            </footer>
        </div>
    </ng-container>
    <!-- END CHILD ONLY SECTION -->

    <!-- START DEPENDENTS SECTION -->
    <ng-container *ngIf="!(memberCoverageOption.value === optionsConfig.children.value)">
        <div
            class="demographics__coverage__card"
            [ngClass]="'demographics__coverage__card__dependent' + i"
            *ngFor="let child of memberChildrenControls; let i = index"
        >
            <header class="demographics__coverage__header u-flex u-justifyBetween">
                <h6 class="t-bold">
                    {{ cardsConfig.children?.text?.child || cardsConfig.children?.label }}
                    <span *ngIf="!optionsConfig.children.one_per_policy && formGroup.get('children')?.value.length > 1">{{ i + 1 }}</span>
                </h6>
                <i
                    class="icon icon--medium icon--pointer"
                    *ngIf="allowAddOrRemoveDependents && memberChildrenControls.length && !cardsConfig['children'].isDisabled"
                    (click)="removeDependent(formGroup.get('children'), i)"
                >
                    {{ removeMemberIcon }}
                </i>
            </header>

            <section class="demographics__coverage__card__body">
                <form-group [configs]="cardsConfig['children'].controls" [form]="memberChildrenControls[i]"> </form-group>
            </section>
        </div>
        <footer
            *ngIf="
                allowAddOrRemoveDependents && !cardsConfig['children'].isDisabled && formGroup.get('children')?.value.length < childLimit
            "
            class="demographics__coverage__card__footer"
            (click)="addDependent('children')"
        >
            <i class="icon icon--medium">add</i>
            <p class="t-data t-bold">{{ addAnotherChildLabel }}</p>
        </footer>
    </ng-container>
    <!-- END DEPENDENTS SECTION -->

    <!-- START Parent/Step Parent DEPENDENTS SECTION -->
    <ng-container *ngIf="cardsConfig.stepParents && !(memberCoverageOption.value === optionsConfig.children.value)">
        <div
            class="demographics__coverage__card"
            [ngClass]="'demographics__coverage__card__dependent' + i"
            *ngFor="let parent of memberParentStepParentControls; let i = index"
        >
            <header class="demographics__coverage__header u-flex u-justifyBetween">
                <h6 class="t-bold">
                    {{ cardsConfig.stepParents?.text?.parent || cardsConfig.stepParents?.label }}
                    <span *ngIf="formGroup.get('stepParents')?.value.length > 1">{{ i + 1 }}</span>
                </h6>
                <i
                    class="icon icon--medium icon--pointer"
                    *ngIf="allowAddOrRemoveDependents && memberParentStepParentControls.length && !cardsConfig.stepParents.isDisabled"
                    (click)="removeDependent(formGroup.get('stepParents'), i)"
                >
                    {{ removeMemberIcon }}
                </i>
            </header>

            <section class="demographics__coverage__card__body">
                <form-group [configs]="cardsConfig.stepParents.controls" [form]="memberParentStepParentControls[i]"> </form-group>
            </section>
        </div>
        <footer
            *ngIf="hasParentDependents && !cardsConfig.stepParents.isDisabled && formGroup.get('stepParents')?.value.length < parentLimit"
            class="demographics__coverage__card__footer"
            (click)="addDependent('stepParents')"
        >
            <i class="icon icon--medium">add</i>
            <p class="t-data t-bold">{{ addAnotherParentLabel }}</p>
        </footer>
    </ng-container>
    <!-- END Parent/Step Parent  DEPENDENTS SECTION -->
</div>
