import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { FormControlConfiguration, Condition } from '@zipari/shared-ds-util-form';
import { FormStepCardConfig } from '../../../templates/src/lib/enrollment/form-step/form-step.constant';
import EnrollmentBroker from './EnrollmentBroker.model';
import IndividualApplication from './IndividualApplication.model';
import Policy from './Policy.model';

export default class Enrollment {
    readonly id?: number;
    agents?: Array<EnrollmentBroker>;
    readonly policies: Array<Policy>;
    application?: IndividualApplication;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
}

export interface StepConfig {
    sectionKey?: string;
    sections?: SectionConfig[];
    type?: string;
    title?: string;
    subtitle?: string;
    assignClientIfNone?: boolean;
    showFooter?: boolean;
    cards?: FormStepCardConfig[];
    enableMedicarePrescriptionPlanType?: string[];
    medicarePrescriptionPaymentOptInPlanYear?: number;
}

export interface SectionConfig {
    sections?: SectionConfig[];
    label?: string;
    description?: string;
    properties?: Array<PropertyConfig>;
}

export interface PropertyConfig {
    control?: FormControlConfiguration;
    display?: ModelAttributeConfig;
    condition?: Condition;
    show?: ShowConfig;
}

export interface ShowConfig {
    pdf?: boolean;
    details?: boolean;
    review?: boolean;
}
