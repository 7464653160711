<modal [config]="modalConfig" [condition]="isIdling" (cancel)="handleCancel()">
    <div class="u-flex u-column u-alignCenter" body>
        <img [class.imgShown]="!!config.imgLink" class="timeout-modal__img" [src]="config.imgLink" />
        <div [innerHTML]="modalBody"></div>
    </div>

    <div footer class="timeout-modal__footer">
        <div class="button__group">
            <zip-button level="medium" content="{{ btn_logout }}" (zipButtonClicked)="handleLogout()"> </zip-button>

            <zip-button level="high" content="{{ btn_continue }}" (zipButtonClicked)="handleCancel()"> </zip-button>
        </div>
    </div>
</modal>
