import { NgModule } from '@angular/core';
import { ButtonModule, ModalModule } from '@zipari/design-system';
import { TimeoutModalComponent } from './timeout-modal.component';

@NgModule({
    declarations: [TimeoutModalComponent],
    imports: [ButtonModule, ModalModule],
    exports: [TimeoutModalComponent],
})
export class TimeoutModalModule {}
