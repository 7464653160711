import {
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { accordion } from '@zipari/design-system';
import { NavigationService } from '@zipari/shared-sbp-modules';
import { WorkflowStep, WorkflowStepSchema } from '@zipari/shared-sbp-models';
import { AuthService } from '@zipari/shared-sbp-services';
import { overflowDirections, StepEmitter, StepperDisplaySchema, validStepperTypes } from './stepper.constants';

@Component({
    selector: 'stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    animations: [accordion],
})
export class StepperComponent implements AfterViewInit, OnChanges, DoCheck {
    /** Which display stepper type to use */
    @Input() displayType: validStepperTypes = validStepperTypes.check;

    /** Step index for the current active step */
    @Input() activeStepNumber: number;

    /** Additional boolean to account for when there is another navbar/toolbar that may impact the position of the stepper
     * todo: make sure this is configurable through css variables
     * */
    @Input() withToolbar: boolean;
    @Input() withNav: boolean;

    /** Whether or not steps are allowed to be clicked. Often we want steps to be clicked to jump backwards in the workflow. */
    @Input() clickableSteps: boolean;

    /** Whether or not the step type should be used for display purposes.
     * These two inputs (useStepType and activeStepType) should be used together. */
    @Input() useStepType: boolean = false;
    @Input() activeStepType: string;

    /** Function to be ran when determining which steps should be displayed. */
    @Input() customFilter: Function;

    /* Stepper config */
    @Input() config?;

    @Output() stepClicked: EventEmitter<StepEmitter> = new EventEmitter<StepEmitter>();

    @ViewChild('headerStepsContainer', { static: true }) headerStepsContainer: any;
    @ViewChild('stepperHeader') stepperHeaderRef: ElementRef;

    /** Only for table/mobile view
     * Whether or not the stepper should be opened
     * */
    showMobileStepper: boolean;

    /** Which step is active */
    activeStepIndex: number = 1;

    /** Enum for valid step types */
    validStepperTypes = validStepperTypes;

    /** Directions that overflow can happen for stepper (left/right) */
    overflowDirections = overflowDirections;

    /** Booleans for whether or not there is overflow to the left or right side */
    showLeftScrollIcon: boolean = null;
    showRightScrollIcon: boolean = null;

    constructor(public authService: AuthService, public navigationService: NavigationService) {}

    @HostBinding('class.authenticated_stepper') get authenticated_stepper() {
        return this.authenticated;
    }

    _steps: Array<any>;

    get steps(): Array<any> {
        return (this._steps || [])
            .filter((step: WorkflowStep) => {
                // check state of step for if the step is skipped.
                // don't show the step if the step is skipped
                const stepState: string = step.state;
                const stepStateCheck: boolean = stepState.toLowerCase() !== 'skipped';

                // utilize custom filter to check if step should be shown
                let customFilterCheck: boolean = true;
                if (this.customFilter) {
                    customFilterCheck = this.customFilter(step);
                }

                return customFilterCheck && stepStateCheck;
            })
            .map((step: WorkflowStep, index: number) => {
                // add a visible step index onto the step to be used in the template for display purposes
                step['visibleStepIndex'] = index + 1;

                return step;
            });
    }

    @Input()
    set steps(steps: Array<any>) {
        if (steps) {
            let stepInd: number = 1;

            // setup step index for display to prepare for when steps are skipped
            this._steps = steps.map((step: WorkflowStep) => {
                const formattedStep: WorkflowStepSchema & StepperDisplaySchema = { ...step.schema, ...step };

                if (formattedStep.label) {
                    formattedStep.stepIndex = stepInd;

                    stepInd++;
                }

                formattedStep.uniqueId = `stepper-step--${formattedStep.type}`;

                return formattedStep;
            });
        }
    }

    public get authenticated() {
        return !!this.authService.loggedInUser;
    }

    ngAfterViewInit(): void {
        this.scrollToActiveStepType(this.activeStepType);

        // Notify navigation service of this component's height
        const height: number = this.stepperHeaderRef ? this.stepperHeaderRef.nativeElement.getBoundingClientRect().height : 0;
        this.navigationService.setStepperHeight(height);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('activeStepType' in changes && changes.activeStepType.currentValue) {
            this.activeStepIndex = this.steps.find((step) => step.type === changes.activeStepType.currentValue).visibleStepIndex;
            this.scrollToActiveStepType(changes.activeStepType.currentValue);
        }
    }

    ngDoCheck() {
        this.checkForOverflow();
    }

    scrollToActiveStepType(type: string) {
        if (type) {
            const activeStep = this.steps.find((step) => step.type === type);

            try {
                setTimeout(() => {
                    const stepperEl = document.getElementById(activeStep.uniqueId);
                    if (stepperEl) {
                        stepperEl.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                }, 250);
            } catch (err) {}
        }
    }

    stepIsClicked(event: StepEmitter, clickable: boolean) {
        if (clickable) {
            this.stepClicked.emit(event);
        }
    }

    /** Handle a left/right arrow clicked. Scrolls the container that direction based on the client width. */
    handleScrollClicked(direction: overflowDirections) {
        const element: Element = this.headerStepsContainer.nativeElement;

        switch (direction) {
            case overflowDirections.left:
                element.scroll({
                    top: element.clientHeight,
                    left: element.scrollLeft - element.clientWidth,
                    behavior: 'smooth',
                });
                break;
            case overflowDirections.right:
                element.scroll({
                    top: element.clientHeight,
                    left: element.scrollLeft + element.clientWidth,
                    behavior: 'smooth',
                });
                break;
        }
    }

    toggleShowMobileStepper() {
        this.showMobileStepper = !this.showMobileStepper;
    }

    /** Calculate the overflow for scrolling consistently so that we know whether or not to display icons to scroll left/right */
    checkForOverflow() {
        this.showLeftScrollIcon = this.determineIfThereIsLeftOverflow();
        this.showRightScrollIcon = this.determineIfThereIsRightOverflow();
    }

    /* If there is a step label in the config return that label otherwise return the label in the step data */
    getStepLabel(step) {
        if (!step) return undefined;
        if (step.type && this.config?.labels && this.config?.labels[step.type]) {
            return this.config?.labels[step.type] || step.label;
        }
        return step.label;
    }

    public determineIfThereIsLeftOverflow(): boolean {
        const element: Element =
            this.headerStepsContainer && this.headerStepsContainer.nativeElement ? this.headerStepsContainer.nativeElement : null;

        if (!element) {
            return null;
        }

        return element.scrollLeft > 0;
    }

    public determineIfThereIsRightOverflow(): boolean {
        const element: Element =
            this.headerStepsContainer && this.headerStepsContainer.nativeElement ? this.headerStepsContainer.nativeElement : null;

        if (!element) {
            return null;
        }

        const bufferForScrollCalculations = 2;

        return element.scrollLeft + element.clientWidth < element.scrollWidth - bufferForScrollCalculations;
    }
}
