// import {allowedZipFormControlTypes} from '../zip-form-control/constants/zip-form-control-types.constant';

export class TableFilter {
    type: 'searchField' | 'select' | 'checkbox' | 'datePicker';
    placeholder: string;
    label: string;
    prop: any;
    value?: string;
    hide?: boolean;
    hideFilters: any;
    options?: Array<any>;
    rowsOrColumns: any;
}
