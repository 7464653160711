import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { ConfigService, LoggerService } from '@zipari/shared-sbp-services';
import { SquidexConfig, SquidexContentFlatResponse, SquidexContentOptions } from '@zipari/shared-sbp-models';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

const defaultContentLanguage = 'en';
const featureKeyQueryParamTemplate = "data/featureKey/iv eq '${featureKey}'";
const squidexContentEndpontTemplate = 'api/content/${appName}/${contentName}';

@Injectable({
    providedIn: 'root',
})
export class SquidexApiService {
    /**
     * Returns the base url for a squidex instance. Gotten from environment vars
     */
    public get baseUrl(): string {
        return this.configService['environment']['squidex']?.['baseUrl'];
    }

    /**
     * Returns the client id for our squidex application
     */
    public get clientId(): string {
        return this.configService.getPageConfig<any>('global')?.squidex?.clientId || '';
    }

    /**
     * Returns the client secret for the client id of our squidex application
     */
    public get clientSecret(): string {
        return this.configService.getPageConfig<any>('global')?.squidex?.clientSecret || '';
    }

    /**
     * Returns the name of the Squidex Application we are getting content from
     */
    private get appName(): string {
        const squidexConfig: SquidexConfig = this.configService.getPageConfig<any>('global')?.squidex;
        return squidexConfig.appName;
    }

    /**
     * Returns the environment of application from configs for Squidex
     */
    private get squidexEnvionment(): string {
        const squidexConfig: SquidexConfig = this.configService.getPageConfig<any>('global')?.squidex;
        return squidexConfig.environment;
    }

    constructor(private configService: ConfigService, private http: HttpClient, private loggerService: LoggerService) {}

    /**
     * Gets content from the associated squidex application. Returns an observable containing a content item object, or an array of content.
     *
     * @param contentName the name of the content we want to get. this will be the name of the content we want to pull from squidex
     * @param featureKey a content type's key used to associate it with a specific feature in this app (ie. 'dashboard', 'enrollment', etc.)
     * @param options SquidexContentOptions to modify the request and response
     */
    public getSquidexContent(contentName: string, featureKey: string, options?: SquidexContentOptions): Observable<any> {
        options = new SquidexContentOptions(options);
        const currentLanguage = this.configService.getPageConfig<any>('global')?.squidex?.language || defaultContentLanguage;
        let headers = new HttpHeaders({ 'X-Flatten': 'true', 'X-Languages': currentLanguage });
        if (options.filterByStatus) {
            // Add the X-Unpublished header to get non published Squidex content (for non prod envs).
            // HttpHeader objects are immutable, append() returns a cloned HttpHeader including the newly appended header.
            // We need to reset our headers var to the cloned HttpHeader object returned by append().
            headers = headers.append('X-Unpublished', '1');
        }

        const apiUrl = this.buildSquidexContentApiUrl(this.appName, contentName);
        const params = this.getSquidexContentApiQueryParams(featureKey, options);

        return this.http.get<SquidexContentFlatResponse>(apiUrl, { headers, params }).pipe(
            first(),
            map((flatResponse) => {
                return flatResponse.items?.map((contentItem) => contentItem?.data);
            }),
            catchError((error) => {
                // log warning msg depending on http error type. throw error to allow users of this function to handle err
                error instanceof HttpErrorResponse && this.logSquidexHttpError(error, contentName, featureKey);
                throw error;
            })
        );
    }

    /**
     * Returns the api url needed to fetch content from a squidex application.
     *
     * @param appName the name of the application in squidex
     * @param contentName the name of a piece of content stored in the squidex application
     */
    private buildSquidexContentApiUrl(appName: string, contentName: string): string {
        const endpoint = stringBuilder(squidexContentEndpontTemplate, { appName, contentName });
        return `${this.baseUrl}/${endpoint}`;
    }

    /**
     * Returns query params for our content api call. Each param should match a valid squidex api param for their content api
     *
     * @param featureKey a content type's key used to associate it with a specific feature in this app (ie. 'dashboard', 'enrollment', etc
     * @param queryParams additional query params that can be appended to the squidex content GET api call
     */
    private getSquidexContentApiQueryParams(featureKey: string, options: SquidexContentOptions): Record<string, string> {
        const params = {};
        const queryParams: { [filterProp: string]: string } = options.queryParams;
        const filterByStatus = options.filterByStatus;

        // add params to params obj (each squidex query param starts with a '$')
        Object.keys(queryParams).forEach((filterProp) => {
            const qpKey = filterProp.charAt(0) === '$' ? filterProp : `$${filterProp}`;
            Object.assign(params, { [qpKey]: queryParams[filterProp] });
        });

        // If we have a featureKey and we don't already have a $filter param for this api call, set
        // the featureKey to be our filter value.
        if (featureKey && !getValue(params, '$filter')) {
            Object.assign(params, { ['$filter']: stringBuilder(featureKeyQueryParamTemplate, { featureKey }) });
        }

        // Adds filtering for workflow content else fallsback to config content
        if (filterByStatus) params['$filter'] += ` and status eq '${this.squidexEnvionment}'`;

        return params;
    }

    /**
     * Log a warning message indicating the tenant's configuration or squidex application is not set up properly to make api calls from
     * this application
     *
     * @param error the HttpErrorResponse returned by Squidex
     * @param contentName the name of the content we want to get. this will be the name of the content we want to pull from squidex
     * @param featureKey a content type's key used to associate it with a specific feature in this app (ie. 'dashboard', 'enrollment', etc.)
     */
    private logSquidexHttpError(error: HttpErrorResponse, contentName: string, featureKey: string): void {
        // if the tenant's squidex app cannot find or the tenant may not have squidex content created for requested feature.
        let loggerWarningMsg;

        switch (error.status) {
            case 401 || 403:
                loggerWarningMsg = "Check tenant's Squidex App is set up properly to make api calls.";
                break;
            case 404:
                loggerWarningMsg = `No Squidex Content found for content "${contentName}" and featureKey "${featureKey}"`;
                break;
        }

        this.loggerService.warn(`Squidex Warning: ${loggerWarningMsg}`);
    }
}
