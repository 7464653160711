import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { ModalTypes } from '@zipari/shared-ds-util-modal';

export class AttributeHighlightByPlanTypeModel {
    medical: Array<AttributeHighlight>;
    health: Array<AttributeHighlight>;
    dental: Array<AttributeHighlight>;
    vision: Array<AttributeHighlight>;
    bundled: Array<AttributeHighlight>;
    medical_family: Array<AttributeHighlight>;
    medicare: Array<AttributeHighlight>;
    default: Array<AttributeHighlight>; // stop breaking with unexpected plan types from medicare
}

export class AttributeHighlight extends ModelAttributeConfig {
    fallback?: Array<string>;
}

export const medicalAttributeHighlights: Array<AttributeHighlight> = [
    {
        label: 'Monthly Premium',
        prop: 'price',
        fallback: ['monthly_premium'],
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Deductible',
        prop: 'deductible',
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Out of Pocket Max',
        prop: 'max_out_of_pocket',
        format: formatTypes.CURRENCY,
    },
];

export const medicalAttributeHighlightsFamily: Array<AttributeHighlight> = [
    {
        label: 'Monthly Premium',
        prop: 'price',
        fallback: ['monthly_premium'],
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Deductible',
        prop: 'deductible',
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Family Deductible',
        prop: 'deductible_family',
        format: formatTypes.CURRENCY,
    },
    {
        label: '',
        prop: '',
    },
    {
        label: 'Individual Out of Pocket Max',
        prop: 'max_out_of_pocket',
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Family Out of Pocket Max',
        prop: 'max_out_of_pocket_family',
        format: formatTypes.CURRENCY,
    },
];

export const dentalAttributeHighlights: Array<AttributeHighlight> = [
    {
        label: 'Monthly Premium',
        prop: 'price',
        fallback: ['monthly_premium'],
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Deductible',
        prop: 'deductible',
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Out of Pocket Max',
        prop: 'max_out_of_pocket',
        format: formatTypes.CURRENCY,
    },
];

export const visionAttributeHighlights: Array<AttributeHighlight> = [
    {
        label: 'Monthly Premium',
        prop: 'price',
        fallback: ['monthly_premium'],
        format: formatTypes.CURRENCY,
    },
];

export const bundledAttributeHighlights: Array<AttributeHighlight> = [
    {
        label: 'Monthly Premium',
        prop: 'price',
        fallback: ['monthly_premium'],
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Individual Deductible',
        prop: 'deductible',
        format: formatTypes.CURRENCY,
    },
    {
        label: 'Calendar Year Max',
        prop: 'max_out_of_pocket',
        format: formatTypes.CURRENCY,
    },
];

export const defaultAttributeHighlightByPlanType: AttributeHighlightByPlanTypeModel = {
    health: medicalAttributeHighlights,
    medical: medicalAttributeHighlights,
    medicare: medicalAttributeHighlights,
    medical_family: medicalAttributeHighlightsFamily,
    dental: dentalAttributeHighlights,
    vision: visionAttributeHighlights,
    bundled: bundledAttributeHighlights,
    default: medicalAttributeHighlights,
};

export class SubsidyText {
    subsidyLabel: string;
    originalPriceLabel: string;
}

export const subsidyTextObj: SubsidyText = {
    subsidyLabel: 'with a ${subsidyAmount} subsidy',
    originalPriceLabel: '(Originally ${originalPrice})',
};

export const defaultDisabledTooltipText: string = 'Only one plan can be selected at a time.';

export const planCompareActivatingMessage: string =
    'Activating this element will cause content on the page to be updated. Navigate to Compare heading within the complimentary region.';

export const goodFitMessage: string = 'Good Fit';

export const defaultViewDetailsTitle: string = 'Plan Details';

export const viewDetailsModalConfig = {
    type: ModalTypes.flyout,
    header: {
        title: '',
        showClose: true,
    },
};

export const textPopUpModalConfig = {
    type: ModalTypes.popup,
    backdrop: true,
    clickOutside: false,
    header: {
        showClose: false,
    },
};

export const removePlanIconConfig = {
    type: 'link',
    ariaLabel: 'delete button',
};

export const viewPlanDetailsIconConfig = {
    type: 'link',
    ariaLabel: 'View Plan Details',
};

export const defaultSelectButtonConfig = {
    level: 'high',
    content: 'Select Plan',
    size: 'fullWidth',
};

export const defaultSelectedButtonConfig = {
    ...defaultSelectButtonConfig,
    content: 'Plan Selected',
    icon: 'check_circle_active',
};

export const defaultViewDetailConfig = {
    viewText: 'View details',
    hideText: 'Hide details',
};
