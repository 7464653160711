import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { stringBuilder } from '@zipari/web-utils';
import { GlobalConfig, TimeoutConfig } from '@zipari/shared-sbp-constants';
import { AuthService, ConfigService, WINDOW } from '@zipari/shared-sbp-services';

import { SessionExpiredConfig } from './session-expired.constants';

const SECONDS: number = 60;

@Component({
    selector: 'session-expired',
    templateUrl: './session-expired.component.html',
    styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent {
    public inactivityMinutes: number;
    public config: SessionExpiredConfig;

    constructor(
        public authService: AuthService,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        public configService: ConfigService
    ) {
        this.inactivityMinutes = this.getTimeoutInMinutes();

        const sessionExpiredPageConfig: SessionExpiredConfig = this.configService.getPageConfig<SessionExpiredConfig>('session-expired');
        this.config = new SessionExpiredConfig(sessionExpiredPageConfig);
    }

    public get contentBody(): string {
        return stringBuilder(this.config.body, { timeout: this.getTimeoutInMinutes() });
    }

    getTimeoutInMinutes() {
        const globalConfig: GlobalConfig = this.configService.getPageConfig<GlobalConfig>('global');
        const timeoutConfig: TimeoutConfig = new TimeoutConfig(globalConfig.timeout);
        const { timeToIdle, timeoutAfterIdle }: { timeToIdle: number; timeoutAfterIdle: number } = timeoutConfig.idle;

        return Math.floor(timeToIdle + timeoutAfterIdle) / SECONDS;
    }

    handleLogin() {
        this.authService.handleExternalLogin();

        if (this.config.loginButtonUrl) {
            this.window.location.assign(this.config.loginButtonUrl);
        } else {
            this.router.navigate([this.configService.appRoute, 'login']);
        }
    }
}
