import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonConfig, ButtonLevels, ButtonSizes } from '@zipari/shared-ds-util-button';
import { ConfigService } from '@zipari/shared-sbp-services';
import { enrollmentInfoCases } from './de-enrollment-info-card.constants';

@Component({
    selector: 'de-enrollment-info-card',
    templateUrl: './de-enrollment-info-card.component.html',
    styleUrls: ['./de-enrollment-info-card.component.scss'],
})
export class DeEnrollmentInfoCardComponent {
    @Input() case: string;
    @Input() extended: boolean;
    @Output() routeToInformationTab: EventEmitter<undefined> = new EventEmitter<any>();

    enrollmentInfoCases = enrollmentInfoCases;

    cardText = {
        noEnrollment: {
            heading: "You aren't able to enroll in this plan, but there are other plans that are available.",
        },
        postEnrollmentNoSVI: {
            nextSteps: [
                `Login to you HealthCare.gov account to <b>pay your premiums</b>.`,
                `You may need to <b>provide documentation</b> to verify invome, citizenship, or other information. Please refor to your Eligibility Determination Notice from HealthCare.gov`,
                `<b>Watch for an email</b> with the results for the Marketplace's review of your documents, if applicable. Your may access your Marketplace notices on HealthCare.gov`,
            ],
        },
        postEnrollmentSVI: {
            nextSteps: [
                `You need to <b>submit documents</b> to the Marketplace. To do this, login to you HealthCare.gov account and follow the instructions.`,
                `<b>Watch for an email</b> with the results of the Marketplace's review of your documents. You may access your Marketplace notices on HealthCare.gov`,
                `<b>Pay you premium</b> after your eligibility is confirmed. You'll receive an email when it's time to take this step. You can pay your premium on HealthCare.gov.`,
            ],
        },
        confirmedEnrollment: {
            active: `<b>Your healthcare coverage is now active.</b> You don't need to take any further action.`,
        },
        shared: {
            submitted: `<i class="icon icon--medium icon--success">check_circle_active</i> Your enrollment has been <b>submitted</b> to HealthCare.gov`,
            whatsNext: "What's Next?",
            reminder: `<b>Remember:</b> You can't start using your health care coverage until the Marketplace reviews your documents and confirms your information, and you pay your premium.`,
            payPremium: 'How to pay your premium on HealthCare.gov',
            payPremiumSteps: [
                'Log in to your Marketplace account',
                `Click your name in the top right, and select "My Applications & Coverage." Choose your application under "Your Existing Applications."`,
                `Click the blue button called, "Pay Your First Health Insurance Monthly Premium.`,
                `If online payment is available, choose the green button called, "Pay for Health Plan Now" to go to your insurance company's website to pay.`,
            ],
            hearFromHealthCare: 'When you hear from HealthCare.gov',
            hearFromHealthCareSteps: [
                'Read your notices and emails',
                'HealthCare.gov may send you notices about your coverage. Log in to your account to view your notices, make updates to your application, and upload required documents.',
                "Download the forms you'll need when you file your federal income tax return.",
            ],
            updateMyInfo: 'What if I need update my information later?',
            loginToHealthCare: 'Please log in to HealthCare.gov to make changes or update your information',
        },
    };

    noHeaderCardConfig = {
        hideHeader: true,
    };

    shopForPlansButtonConfig = new ButtonConfig({
        level: ButtonLevels.high,
        size: ButtonSizes.fit,
        content: 'Shop For Plans',
    });

    goToHealthCareDotGovButton = new ButtonConfig({
        level: ButtonLevels.high,
        size: ButtonSizes.fit,
        content: 'Go to HealthCare.gov',
    });

    learnMoreButton = new ButtonConfig({
        level: ButtonLevels.low,
        size: ButtonSizes.fit,
        content: 'Learn More',
    });

    constructor(private router: Router, private route: ActivatedRoute, private configService: ConfigService) {}

    shopForPlans() {
        this.router.navigate([this.configService.appRoute, 'individual']);
    }

    goToHealthCareDotGov() {
        window.open('https://www.healthcare.gov/login', '_blank');
    }

    learnMore() {
        this.routeToInformationTab.emit();
    }
}
