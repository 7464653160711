export interface HighlightedInCompare {
    header: string;
    external_id: string;
}

export interface ComparePlansConfig {
    comparePlaceholder?: string;
    showNavigation?: string;
    headerCardType?: string;
    showStarRating?: string;
    compareMessage?: string;
    compareTitle?: string;
    showEnroll?: boolean;
    displayAllDocuments?: boolean;
    displayDocuments?: boolean;
    showLevel1Benefits?: boolean;
    showFooter?: boolean;
    premiumSuffix?: string;
    label?: {
        backToPlans?: string;
        linkLabel?: string;
        monthlyPremium?: string;
        noValueLabel?: string;
    };
    enrollButton?: string;
}

export interface HighlightsData {
    label: string;
    value: string;
}

export interface HighlightedConfigItems {
    label: string;
    prop: string;
}

export interface SinglePlanSelectionDetail {
    showLinks: boolean;
    compareLabel: string;
}

export interface ExtraPlanBenefit {
    labelToSearchForInBenefits?: string;
    planKey: string;
    label: string;
    value: string;
    type: string;
    format: string;
}
