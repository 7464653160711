import { ButtonConfig, ButtonLevels } from '@zipari/shared-ds-util-button';
import { MessageBannerConfig, BannerTypes } from '@zipari/shared-ds-util-messages';

export const healthcareButton: ButtonConfig = {
    content: 'Go to HealthCare.gov',
    level: ButtonLevels.high,
};

export const noCoverageConfig: MessageBannerConfig = {
    type: BannerTypes.error,
    messageHeading: 'Coverage Not Available',
    icon: 'warning',
    message:
        'Unfortunately, we don\'t offer health care coverage in ${state}. Please go to HealthCare.gov or reach out to the Call Center at <a href="tel:1-800-318-2596">1 (800) 318-2596</a> to finish your enrollment.',
    actionButton: healthcareButton,
};

export const noPlanConfig: MessageBannerConfig = {
    type: BannerTypes.error,
    messageHeading: 'Plan Not Available',
    icon: 'warning',
    message: 'The original plan you selected in not available in ${state}, but there are other plans available.',
};
