export default class IndividualApplication {
    readonly id?: number;
    client?: number;
    broker?: string;
    workflow_id?: number;
    confirmation_number?: string;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    application_type?: string;
    status?: string;
    submitted_date?: string;
    status_internal?: 'in_progress' | 'submitted' | 'complete' | 'failed' | null;
    enrollment_json?: string;
    webuser_id?: string;
    application_source?: string;
    is_effectuated?: boolean;
}
