<article class="notifications">
    <section class="notifications__content">
        <header class="notifications__header" *ngIf="!!showHeader">
            <h4 class="t-bold">Notifications</h4>
        </header>
        <ng-container *ngIf="!!allowPaging; else allNotifications">
            <notifications-allow-paging [notifications]="notifications" [icon]="notificationSubtypeToIconMapping">
            </notifications-allow-paging>
        </ng-container>
        <ng-template #allNotifications>
            <all-notifications [notifications]="notifications" [icon]="notificationSubtypeToIconMapping"> </all-notifications>
        </ng-template>
    </section>
</article>
