import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'info-page',
    templateUrl: './info-page.component.html',
    styleUrls: ['./info-page.component.scss'],
})
export class InfoPageComponent implements OnInit {
    @Output() close = new EventEmitter<any>();
    @Input() config: any;

    constructor() {}

    ngOnInit(): void {}
}
