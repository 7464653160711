export class PolicyInformation {
    marketplaceGroupPolicyIdentifier: number;
    isSubscriber: boolean;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class PersonSearchResultApplication {
    insuranceApplicationIdentifier: number;
    latestApplicationVersion: number;
    latestApplicationVersionConsumerIsOn: number;
    coverageYearNumber: number;
    tenant: string;
    primaryContactPreferredPhoneNumber: number;
    primaryContactSecondaryPhoneNumber: number;
    hasAdditionalPersonTrackingNumbersTiedToApplication: boolean;
    applicationProcessStatus: string;
    applicationLastModifiedDateTime: string;
    hasAdditionalPoliciesTiedToApplication: boolean;
    policyInformation: PolicyInformation;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class PersonSearchResultDetails {
    applications: Array<PersonSearchResultApplication>;
    personTrackingNumber: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    ssn: string;
    primaryContactEmail?: string;
    streetName1?: string;
    city?: string;
    stateCode?: string;
    zip5?: number;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class PersonSearchResults {
    data: Array<PersonSearchResultDetails>;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
