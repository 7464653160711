<div class="file--info u-flex u-justifyBetween">
    <div class="u-flex">
        <i class="icon icon--medium file--info__icon">pdf</i>
        <p class="t-data t-bold">{{ file.name }}</p>
    </div>
    <p class="t-data file--info__size">{{ size }}</p>

    <zip-button
        [config]="{
            level: 'text',
            icon: 'close'
        }"
        (zipButtonClicked)="closeClicked.emit()"
    >
    </zip-button>
</div>

<div class="progressbar">
    <div class="progressbar__total">
        <div [ngClass]="{ progressbar__error: error }" class="progressbar__partial" [ngStyle]="progressWidth"></div>
    </div>
    <p class="t-data t-alt progressbar__percentage" *ngIf="!error">{{ percentComplete }} done</p>
</div>
