import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class APIService {
    constructor(private http: HttpClient) {}

    get(endpoint: string, params?: any) {
        return this.http.get<any>(endpoint, { params });
    }

    post(endpoint: string, body: any, httpOptions?: any): Observable<any> {
        return this.http.post<any>(endpoint, body, httpOptions);
    }
}

export class APIResponse<T> {
    count: number;
    next?: string;
    previous?: string;
    results: Array<T>;
}
