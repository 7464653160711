import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { APIList } from '@zipari/design-system/lib/shared/interfaces/api-list';
import { ProviderResult } from '@zipari/shared-sbp-modules';
import { ButtonConfig } from '@zipari/shared-ds-util-button';

export interface FilterResponse {
    key: string;
    label: string;
    options?: string[];
    placeholder?: string;
    type: string;
    filter_type?: string;
    choices?: string[] | FilterOption[];
}

export interface FilterConfig {
    prop: string;
    controls: AllControlsConfiguration[];
}

export interface FilterOption {
    label?: string;
    value?: number | string | boolean;
}

export interface UpdatedFilterDataConfig {
    accepting_new_patients: string[];
    facility_type: number;
    gender: string;
    language: string;
    medical_groups: string;
    specialties: number;
    wheelchair_access: string[];
    hospital_affiliations: string;
    sort_by: string;
    filters?: {
        accepting_new_patients: string;
        gender: string;
        language: string;
        sort_by: string;
    };
}

export interface PreFiltersResponse {
    external_id?: string;
    results?: PreFiltersResponseResult[];
    zip_code?: string;
}

export interface PreFiltersResponseResult {
    code?: string;
    id?: string;
    name?: string;
    value: string;
    label: string;
    specialty_description?: string;
}

export interface ProviderSearchResponse extends APIList<ProviderSearchResponse> {
    filters?: FilterResponse[];
}

export interface PersistentFilterPayload {
    accepting_new_patients?: string;
    wheelchair_access?: string;
}

export interface LocationPin {
    infoWindow: string;
    resultIndex: number;
    latlng: {
        lat: string;
        lng: string;
    };
}

export interface SelectedProvidersConfig {
    subscriber?: ProviderResult;
    spouse?: ProviderResult;
    dependents?: ProviderResult[];
}

export interface ProviderSearchFooterButtons {
    save?: ButtonConfig;
    back: ButtonConfig;
    continue: ButtonConfig;
    skip?: ButtonConfig;
}
