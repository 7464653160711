<div class="plan--enroll" *ngIf="plan">
    <model-attribute *ngIf="config" [config]="config" direction="row_equal"> </model-attribute>

    <zip-button
        *ngIf="showEnrollButton"
        (zipButtonClicked)="enroll.emit(plan)"
        [disabled]="disableEnrollButton"
        [level]="buttonConfig.level"
        [config]="buttonConfig"
        [content]="buttonConfig.content"
    >
    </zip-button>
</div>
