import { SquidexContentResponseTypes, SquidexEnvironment } from '@zipari/shared-sbp-constants';

export class SquidexContentOptions {
    responseType?: SquidexContentResponseTypes;
    queryParams?: { [filterProp: string]: string };
    filterByStatus: boolean;

    constructor(options) {
        // default to 'object' since this is the most common use case
        this.responseType = SquidexContentResponseTypes.object;
        this.queryParams = {};
        this.filterByStatus = options.filterByStatus || false;

        Object.assign(this, options);
    }
}

// parameters for the SquidexApiService.getContent() function
// this model is used by the ConfigDispenserService
export interface SquidexGetContentParams {
    contentName?: string;
    featureKey?: string;
    options?: SquidexContentOptions;
}

export interface SquidexConfig {
    appName: string;
    clientId: string;
    clientSecret: string;
    language: string;
    useSquidexContent: boolean;
    environment: SquidexEnvironment;
}

export interface SquidexTokenResponse {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}

export interface SquidexContentFlatResponse {
    items: Array<SquidexContentFlatResponseItem>;
    statuses: Array<any>;
    total: number;
    _links: any;
}

export interface SquidexContentFlatResponseItem {
    id: string;
    created: string;
    createdBy: string;
    lastModified: string;
    lastModifiedBy: string;
    newStatus: string;
    newStatusColor: string;
    schema: string;
    SchemaName: string;
    status: string;
    statusColor: string;

    // this field is where the actual content in a response is stored
    data: any;
}
