<section class="documents-list" *ngIf="config?.showOnlyDocuments; else foldersList">
    <div class="container">
        <section class="documents-list__table">
            <zip-table [data]="documentsConfig?.data" [options]="documentsConfig" (cellClicked)="getDocument($event)"> </zip-table>
        </section>
    </div>
</section>

<ng-template #foldersList>
    <section class="resource-center" *ngIf="showFolders">
        <div class="container">
            <section class="resource-center__table">
                <zip-table [data]="config?.folders?.data" [options]="config.folders" (cellClicked)="displayFolderContents($event)">
                </zip-table>
            </section>
        </div>
    </section>
    <section class="documents-list" *ngIf="!showFolders">
        <div class="container">
            <div class="documents-list__back t-link t-bold" (click)="toggleDisplay()">
                < {{ config?.documents?.backTitle || 'Back to Resource Center' }}
            </div>
        </div>
        <div class="container">
            <section class="documents-list__table">
                <zip-table [data]="documentsConfig?.data" [options]="documentsConfig" (cellClicked)="getDocument($event)"> </zip-table>
            </section>
        </div>
    </section>
</ng-template>
<zip-footer *ngIf="config?.showFooter && !footerConfig?.usesDesignSystem"></zip-footer>
