import { ButtonModule } from '@zipari/design-system';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPageComponent } from './info-page.component';

@NgModule({
    declarations: [InfoPageComponent],
    imports: [CommonModule, ButtonModule],
    exports: [InfoPageComponent],
})
export class InfoPageModule {}
