import { Component, Input } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';

@Component({
    selector: 'content-skip',
    templateUrl: './content-skip.component.html',
    styleUrls: ['./content-skip.component.scss'],
})
export class ContentSkipComponent {
    @Input() target: string = '#main';
    @Input() buttonConfig: ButtonConfig;

    targetFocus() {
        const el = document.querySelector(this.target);
        const windowY = window.pageYOffset;

        if (el && el['focus']) {
            el['focus']();
            window.scrollTo(0, windowY);
        }
    }
}
