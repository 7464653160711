import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionModule, ButtonModule, FormattingService, ModalModule, StarRatingModule, IconModule } from '@zipari/design-system';
import { FormGroupModule, PlansAPIService } from '@zipari/shared-sbp-modules';
import {
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';

import { PlanCardBadgeComponent } from './plan-card-badge/plan-card-badge.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { PlanCardFooterComponent } from './plan-card-footer/plan-card-footer.component';
import { PlanCompareComponent } from './plan-compare/plan-compare.component';
import { PlanFiltersComponent } from './plan-filters/plan-filters.component';
import { ViewDetailsModalComponent } from './view-details-modal/view-details-modal.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [
        PlanFiltersComponent,
        PlanCompareComponent,
        PlanCardBadgeComponent,
        PlanCardComponent,
        PlanCardFooterComponent,
        ViewDetailsModalComponent,
    ],
    imports: [
        CommonModule,
        FormGroupModule,
        ButtonModule,
        SharedModule,
        PipesModule,
        StarRatingModule,
        ModalModule,
        AccordionModule,
        IconModule,
    ],
    providers: [
        PlansAPIService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
        FormattingService,
        CurrencyPipe,
    ],
    exports: [PlanFiltersComponent, PlanCompareComponent, PlanCardComponent, PlanCardFooterComponent],
})
export class PlansModule {}
