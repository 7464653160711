export interface IDProofingQuestionsPayload {
    VerificationAnswerSet: VerificationAnswerSet;
    SessionIdentification: string;
}
interface VerificationAnswerSet {
    VerificationAnswers?: VerificationAnswersEntity[] | any;
}
interface VerificationAnswersEntity {
    // spelling mistake in response from experian
    VerificatonAnswer: string;
    VerificationQuestionNumber: string;
}
