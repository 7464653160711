import { CellClickEvent, ColumnConfig, TableConfig } from '@zipari/design-system';
import { FormControlConfiguration } from '@zipari/shared-ds-util-form';
import { Address, County, Member, Plan } from '@zipari/shared-sbp-models';

export interface ListPageConfig {
    addRow?: AddRowConfig;
    buttonClick?: {
        'new quote': RouteConfig;
        'New Quote': RouteConfig;
    };
    cellClicked?: RouteConfig;
    customListAction?: {
        type?: string;
        statuses?: string[];
    };
    detailPageName?: string;
    endpoint?: string;
    forceUnique?: string;
    pageName?: string;
    pageSize?: number;
    routeAllAppsToDetails?: boolean;
    showDetailModal?: boolean;
    showFooter?: boolean;
    uniqueIdentifier?: string;
    table?: TableConfig;
    tableAction?: TableAction;
}

export interface AddRowConfig {
    attributes: {
        label: string;
        attributes: string[];
    };
    button: { content: string };
    cancelConfirm: {
        header: string;
        body: string;
        confirm: string;
    };
    form: {
        title: string;
        groups: string[];
    };
    header: string;
    messages: {
        success: string;
        error: string;
    };
    save: string;
    existingClientListConfig?: FormControlConfiguration;
}

export interface RouteConfig {
    route: string;
}

export interface TableAction {
    route: string;
    statuses?: string[];
    checkApplicationForSubmittedWorkflow: boolean;
}

export interface TableColumnConfig extends Omit<ColumnConfig, 'prop'> {
    actionProp?: string;
    actionUrl?: string;
    action?: string;
    prop?: string;
    fileNameProp: string;
    route?: string;
    routeProp?: string;
    useRoute?: boolean;
}

export interface TableCellClickEvent extends Omit<CellClickEvent, 'action' | 'context'> {
    action?: string;
    target?: {
        innerText?: string;
        checked?: boolean;
    };
    stopPropagation?: () => void;
    context?: EventContext;
}

export interface EventContext {
    col?: TableColumnConfig;
    row?: TableRowData;
    val?: number | string;
}

export interface TableContext {
    actionData?: EventContext;
    data?: TableRowData;
    params?: {
        id: number;
    };
}

export interface TableRowData {
    agency?: string;
    agency_id?: string;
    agency_name?: string;
    application_source?: string;
    application_type?: string;
    associated_clients?: [];
    authorized_rep_client?: [];
    broker: string;
    broker_name: string;
    client?: any;
    client_id?: number;
    confirmation_number?: string;
    county?: County;
    county_code?: string;
    county_id?: number;
    coverage_effective_date?: string;
    created_at: string;
    deleted_at?: string;
    dependents?: Member[];
    effective_date: string;
    email?: string;
    enrollment_period?: string;
    external_application_id?: string;
    external_lead_id?: string;
    first_name?: string;
    id: number | string;
    index: number;
    is_effectuated?: boolean;
    is_renewal?: boolean;
    is_shared?: boolean;
    last_name?: string;
    line_of_business?: string;
    market_segment: string;
    marketing_source: string;
    members?: Member[];
    permanent_address: Address;
    plans?: Plan;
    quote_id: number | string;
    spouse?: Member;
    status: string;
    status_internal?: string;
    submitted_date?: string;
    subscriber?: Member;
    subscriber_first?: string;
    subscriber_first_name?: string;
    subscriber_last?: string;
    subscriber_last_name?: string;
    tenant: string;
    termination_date: string;
    updated_at: string;
    webuser_id?: string;
    workflow_id: number;
}

export interface PreferredColumnConfig {
    prop?: string;
    isHidden?: boolean;
}

export interface PreferenceGrid {
    preference_grid: {
        IFP?: PreferenceGrids;
        MA?: PreferenceGrids;
    };
}

export interface PreferenceGrids {
    applications_grid?: ColumnConfig[];
    clients_grid?: ColumnConfig[];
    leads_grid?: ColumnConfig[];
    policies_grid?: ColumnConfig[];
    quotes_grid?: ColumnConfig[];
}

export interface QueryParams {
    step?: string;
    application_id?: number;
}
