import { ResponseLinks, ResultType, UseCaseTypes, InsuranceApplicationType } from '../sharedModels';

export class CreateApplicationResponse__Result__ApplicationMembers {
    firstName: string = null;
    lastName: string = null;
    birthDate: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class CreateApplicationResponse__Result {
    insuranceApplicationIdentifier: number = null;
    applicationVersionNumber: number = null;
    applicationCreationDateTime: string = null;
    linkedSystemUserIdentifier: string = null;
    useCase: UseCaseTypes = null;
    insuranceApplicationType: InsuranceApplicationType = null;
    applicationMembers: {
        [memberKey: string]: CreateApplicationResponse__Result__ApplicationMembers;
    };
    creatingDePartner: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class CreateApplicationResponse {
    resultType: ResultType = null;
    links: ResponseLinks = null;
    result: CreateApplicationResponse__Result = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
