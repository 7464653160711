import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { cloneObject, deepCompare, getValue, isEmptyObj } from '@zipari/web-utils';
import {
    AnalyticsContext,
    CXCaptureConfig,
    GAConfig,
    ProductPrefixes,
    validCXEvents,
    validGAEvents,
    validPosthogEvents,
    workflowContext,
    WorkflowPrefixes,
} from '@zipari/shared-sbp-constants';
import { Plan, WorkflowType } from '@zipari/shared-sbp-models';
import {
    AuthService,
    ConfigService,
    GAEcommerceKeys,
    GoogleAnalyticsService,
    LoggerService,
    MtmService,
    PosthogConfiguration,
    WINDOW,
} from '@zipari/shared-sbp-services';
import { fromEvent } from 'rxjs';
import { CXCaller } from 'zipari-cx/dist';
import { chain, each, get } from 'lodash';

const { version } = require('package.json');

export class UTM_PARAMS {
    utm_source?: string = '';
    utm_medium?: string = '';
    utm_campaign?: string = '';
    utm_term?: string = '';
    utm_content?: string = '';

    constructor(params) {
        const keys = Object.keys(this);

        keys.forEach((key) => {
            if (params[key]) {
                this[key] = params[key];
            } else {
                delete this[key];
            }
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public analyticsDebounce: number = 500;

    CXCaller: CXCaller;
    CX_Config: CXCaptureConfig;
    GA_Config: GAConfig;
    Adobe_Config: any;
    Posthog_Config: PosthogConfiguration;

    /* Adobe data layer key name that is being listened for Adobe analytics events */
    taggingDataLayerKey = 'dtm_dataLayer';

    workflowContext: workflowContext;
    currentPage: { eventLabel: string; pageName: string } = { eventLabel: '', pageName: '' };

    prevDictionaryAttribute: any = {};

    constructor(
        public loggerService: LoggerService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public mtmService: MtmService,
        public configService: ConfigService,
        public authService: AuthService,
        public http: HttpClient,
        @Inject(WINDOW) private window: Window
    ) {
        const globalConfig = this.configService.getPageConfig<any>('global');

        if (globalConfig?.analytics) {
            if (globalConfig.analytics.cx_capture && this.checkForCXCaptureConfigs(globalConfig.analytics.cx_capture)) {
                this.CX_Config = globalConfig.analytics.cx_capture;
                this.CXCaller = new CXCaller(
                    { endpoint: this.CX_Config.endpoint },
                    { source_system_name: this.CX_Config.source_system_name }
                );
            } else {
                this.loggerService.warn('No CX Capture configuration provided');
            }

            /* update data layer key from the configuration */
            if (globalConfig.analytics.dataLayerKey) {
                this.taggingDataLayerKey = globalConfig.analytics.dataLayerKey;
            }

            /* init tagging data layer */
            if (!window[this.taggingDataLayerKey]) {
                window[this.taggingDataLayerKey] = {};
            }

            if (globalConfig.analytics.google_analytics) {
                this.GA_Config = globalConfig.analytics.google_analytics;
            }

            if (globalConfig.analytics.adobe_analytics) {
                this.Adobe_Config = globalConfig.analytics.adobe_analytics;
            }

            if (globalConfig.analytics.posthog) {
                this.Posthog_Config = globalConfig.analytics.posthog;
            }
        } else {
            this.loggerService.warn('No analytics configuration provided');
        }

        this.initPosthogCapture();
        this.initGlobalClickEvents();
    }

    public dispatchAnalytics(keys: AnalyticsContext, dictionary_attributes: any = {}, ga_specific_attributes?: any) {
        const globalConfig = this.configService.getPageConfig<any>('global');
        const cxConfig = globalConfig?.analytics?.cx_capture;

        if (dictionary_attributes.eventLabel && dictionary_attributes.pageName) {
            this.currentPage.eventLabel = dictionary_attributes.eventLabel;
            this.currentPage.pageName = dictionary_attributes.pageName;
        }

        if (keys) {
            if (keys.GAKey && this.Adobe_Config) {
                // if we have GA specifc attrs then use them
                const attrs = ga_specific_attributes || dictionary_attributes;
                this.sendAdobeEvent(keys.GAKey, attrs);
            }

            if (keys.CXKey && this.CX_Config && cxConfig) {
                this.sendCXEvent(keys.CXKey, dictionary_attributes);
            }

            if (keys.GAKey && this.GA_Config) {
                if (typeof ga !== 'undefined' || typeof gtag !== 'undefined') {
                    // if we have GA specifc attrs then use them
                    const attrs = ga_specific_attributes || dictionary_attributes;
                    this.sendGAEvent(keys.GAKey, attrs);
                } else {
                    this.loggerService.warn('Trying to call google analytics without ga available. Please provide google analytics tag');
                }
            }

            if (keys.PosthogKey && this.Posthog_Config) {
                if (keys.PosthogKey === validPosthogEvents.user_login) {
                    const username = getValue(this.authService.loggedInUser, 'app_user_data.user_name');
                    this.mtmService.identifyUserOnLogin(username);
                }

                if (keys.PosthogKey === validPosthogEvents.user_logout) {
                    this.mtmService.resetUserOnLogout();
                }

                if (this.Posthog_Config?.customEvent_on) {
                    this.sendPosthogEvent(keys.PosthogKey, dictionary_attributes);
                }
            }
        }
    }

    public setWorkflowContext(context: workflowContext) {
        this.workflowContext = context;
    }

    retrieveUTMParamsFromUrl(params = {}): UTM_PARAMS {
        return new UTM_PARAMS(cloneObject(params));
    }

    public initGlobalClickEvents(): void {
        fromEvent(this.window.document, 'click').subscribe((clickEvent: MouseEvent) => {
            this.dispatchClickEvent(clickEvent.target as HTMLElement);
        });
    }

    /**
     * Method to send Adobe event when button or link is clicked
     * @param element HTMLElement that was clicked
     */
    public dispatchClickEvent(element: HTMLElement, artificialEvent: boolean = false): void {
        if (!element || isDevMode()) return;

        const tagName = element.tagName.toLowerCase();
        const isAchor = tagName === 'a';
        // Depending on where user clicked, sometimes the button is disguised as a SPAN element
        //  can safely find `button` in CSS class in this case
        const isButton = tagName === 'button' || element.className.indexOf('button') > -1;

        if (isAchor || isButton || artificialEvent) {
            const dictionary_attributes = {
                'data-object': isAchor ? 'link' : 'button',
                'data-name': element.innerText,
                'data-eventLabel': this.currentPage.eventLabel,
                'data-pageName': this.currentPage.pageName,
                timestamp: new Date().toUTCString(),
            };

            // sends event to Adobe
            if (
                window[this.taggingDataLayerKey] &&
                window[this.taggingDataLayerKey].event &&
                !deepCompare(dictionary_attributes, this.prevDictionaryAttribute)
            ) {
                window[this.taggingDataLayerKey].event.push(dictionary_attributes);
                this.prevDictionaryAttribute = dictionary_attributes;
            }

            // sends event to google service
            if (this.GA_Config) {
                this.sendGAEvent(validGAEvents.button_clicked, dictionary_attributes);
            }
        }
    }

    private sendAdobeEvent(key: validGAEvents, context) {
        const setupCart = (types, context = null) => {
            if (isEmptyObj(types)) {
                delete window[this.taggingDataLayerKey]['cart'];
                return;
            }

            let price = 0;

            Object.keys(types).forEach((typeKey) => {
                price += types[typeKey].price;
            });

            const cart = {
                basePrice: price,
                currency: 'USD',
                cartTotal: price,
            };

            window[this.taggingDataLayerKey]['cart'] = {
                cart,
                types,
            };
        };

        if (!window[this.taggingDataLayerKey]) {
            window[this.taggingDataLayerKey] = {};
        }

        if (!window[this.taggingDataLayerKey]['cart']) {
            window[this.taggingDataLayerKey]['cart'] = {};

            let types = {
                ...(window[this.taggingDataLayerKey]['cart']['types'] || {}),
            };

            if (context.plans) {
                for (const plan_type in context.plans) {
                    types[plan_type] = {
                        price: Number.parseFloat(context.plans[plan_type].price),
                        id: context.plans[plan_type].external_id,
                        deductible: context.plans[plan_type].deductible,
                        display_name: context.plans[plan_type].display_name,
                        external_id: context.plans[plan_type].external_id,
                        index: context.plans[plan_type].index,
                        max_out_of_pocket: context.plans[plan_type].max_out_of_pocket,
                        max_out_of_pocket_family: context.plans[plan_type].max_out_of_pocket_family,
                        metal_tier: context.plans[plan_type].metal_tier,
                        numOfApplicants: context.plans[plan_type].numOfApplicants,
                    };
                }
            }

            if (context.quoted) {
                context.quoted.forEach((plan) => {
                    types[plan.plan_type] = {
                        price: Number.parseFloat(plan.price),
                        id: plan.external_id,
                        deductible: plan.deductible,
                        display_name: plan.display_name,
                        external_id: plan.external_id,
                        index: plan.index,
                        max_out_of_pocket: plan.max_out_of_pocket,
                        max_out_of_pocket_family: plan.max_out_of_pocket_family,
                        metal_tier: plan.metal_tier,
                        numOfApplicants: plan.numOfApplicants,
                    };
                });
            }
            setupCart(types);
        }

        // set up virtual page view OR special commerce events
        if (key === 'virtualPageView') {
            const pageInfo = {
                pageName: context.eventLabel,
                pageID: context.workflow_id,
                version,
            };

            window[this.taggingDataLayerKey]['page'] = {
                pageInfo,
            };

            const username = getValue(this.authService.loggedInUser, 'app_user_data.user_name');
            const email_address = getValue(this.authService.loggedInUser, 'app_user_data.email_address');

            window[this.taggingDataLayerKey]['user1'] = {
                profile1: {
                    profileInfo: {
                        session: context.web_session_id,
                        profileID: context.webuser_id,
                        userName: username,
                        email: email_address,
                    },
                },
            };
        } else if (GAEcommerceKeys[key]) {
            let types;
            switch (key) {
                case 'removeFromCart':
                    if (window[this.taggingDataLayerKey]['cart']) {
                        const plantype = getValue(window[this.taggingDataLayerKey]['cart'], `types.${context.plan_type}`);

                        types = cloneObject(window[this.taggingDataLayerKey]['cart']['types']);
                        if (plantype && types) {
                            delete types[context.plan_type];
                        }

                        setupCart(types);
                    }
                    break;
                case 'addToCart':
                    types = {
                        ...(window[this.taggingDataLayerKey]['cart']['types'] || {}),
                        [context.plan_type]: {
                            price: Number.parseFloat(context.price),
                            id: context.external_id,
                            deductible: context.deductible,
                            display_name: context.display_name,
                            external_id: context.external_id,
                            index: context.index,
                            max_out_of_pocket: context.max_out_of_pocket,
                            max_out_of_pocket_family: context.max_out_of_pocket_family,
                            metal_tier: context.metal_tier,
                            numOfApplicants: context.numOfApplicants,
                        },
                    };

                    setupCart(types);
                    break;
                case 'productImpressions':
                    const products = context.plans.map((plan: Plan) => {
                        return {
                            productID: plan.external_id,
                            productName: plan.display_name,
                        };
                    });

                    const dataLayer = window[this.taggingDataLayerKey];

                    each(dataLayer.event, (event) => {
                        const planTypes = chain(event.plans)
                            .map((plan) => get(plan, 'plan_type'))
                            .uniq()
                            .value();

                        if (event && event.key === 'productImpressions' && planTypes) {
                            event.planTypes = planTypes.join(',');
                        }
                    });

                    // delete any current products
                    Object.keys(dataLayer)
                        .filter((key) => key.indexOf('product') >= 0)
                        .forEach((key) => {
                            delete window[this.taggingDataLayerKey][key];
                        });

                    dataLayer['product'] = products;

                    // Turn off individual product event
                    // products.forEach((plan, ind) => {
                    //     dataLayer[`product${ind}`] = plan;
                    // });

                    break;
            }
        }

        // send event
        const events = window[this.taggingDataLayerKey]['event'] || [];

        const newEvent = {
            ...this.formatBrowserContext(),
            ...context,
            key,
            referrer: document.referrer,
        };

        /* Do not dispatch duplicate virtual page view event */
        let duplicatePageView = false;
        if (key === 'virtualPageView' && events && events.length) {
            const previousEvent = events[events.length - 1];
            if (previousEvent && previousEvent.eventLabel === newEvent.eventLabel && previousEvent.pageName === newEvent.pageName) {
                duplicatePageView = true;
            }
        }

        /* push the event */
        if (!duplicatePageView) {
            events.push(newEvent);
        }

        window[this.taggingDataLayerKey]['event'] = events;

        // Turn off individual events
        // events.forEach((event, ind) => {
        //     window[this.taggingDataLayerKey][`event${ind}`] = event;
        // });
    }

    private checkForCXCaptureConfigs(config: CXCaptureConfig) {
        const newConfig = new CXCaptureConfig(config);
        const validConfigs = Object.keys(newConfig);

        let valid = true;

        validConfigs.forEach((key) => {
            if (!config[key]) {
                this.loggerService.warn(`${key} not provided in config`);

                valid = false;
            }
        });

        return valid;
    }

    private formatBrowserContext() {
        const context = {};

        context['timestamp'] = new Date().toISOString();

        // attempt to retrieve timezone
        try {
            context['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (err) {
            // intentionally swallow the timezone catch if we can't retrieve the timezone
        }

        context['page'] = Object.assign({
            url: window.location.href,
        });

        context['app'] = {
            name: this.configService.app,
            version,
        };

        return context;
    }

    private formatBody(name: validCXEvents | validGAEvents, dictionary_attributes: any) {
        let result = {};

        try {
            const authentication_flag = !!this.authService.loggedInUser;

            let consumer_id;
            if (authentication_flag) {
                consumer_id =
                    this.authService.loggedInUser.app_user_data.broker_id ||
                    this.authService.loggedInUser.app_user_data.member_id ||
                    this.authService.loggedInUser.app_user_data.user_name ||
                    null;
            } else {
                consumer_id = this.workflowContext ? this.workflowContext.webuser_id : null;
            }

            const session_id = this.workflowContext ? this.workflowContext.web_session_id : null;
            const source_system_name = this.CX_Config.source_system_name;
            const context = this.formatBrowserContext();
            const workflowType = this.workflowContext ? this.workflowContext.type : null;

            const plans = dictionary_attributes.plans;
            if (plans) {
                dictionary_attributes.planNames = Array.isArray(plans)
                    ? plans.map(
                          (plan: Plan) =>
                              <any>{
                                  id: plan.external_id,
                                  name: plan.display_name,
                                  category: plan.hmo ? 'HMO' : 'PPO',
                                  list: this.googleAnalyticsService.determinePlanType(plan.plan_type),
                                  price: plan.price,
                                  position: plan.index,
                              }
                      )
                    : Object.keys(plans)
                          .map((planKey: string) => plans[planKey]?.display_name)
                          .join(', ');
            }

            result = {
                name: `${this.CXDetermineProductPrefix(this.configService.app, workflowType)}${name}`,
                source_system_name,
                authentication_flag,
                dictionary_attributes,
            };

            // set consumer_id as 'unknown-user' if not logged in or if data is missing
            if (consumer_id) {
                result['consumer_id'] = consumer_id;
            } else {
                result['consumer_id'] = 'unknown-user';
            }

            if (context) {
                result['context'] = context;
            }

            if (session_id) {
                result['session_id'] = session_id;
            }
        } catch (err) {
            this.loggerService.error(err);
        }

        return result;
    }

    private sendCXEvent(event_key: validCXEvents, dictionary_attributes: any) {
        const ignoreKeys = [validCXEvents.virtualPageView];

        if (ignoreKeys.includes(event_key)) {
            return;
        }

        const mappedKey = this.CX_Config.mapping && this.CX_Config.mapping[event_key] ? this.CX_Config.mapping[event_key] : event_key;
        const body: any = this.formatBody(mappedKey, dictionary_attributes);
        const workflowType = this.workflowContext ? this.workflowContext.type : null;

        this.http
            .post('api/cx/capture/events/', {
                payload: body,
                key: `${this.CXDetermineProductPrefix(this.configService.app, workflowType)}${mappedKey}`,
            })
            .subscribe();
    }

    private sendGAEvent(key: validGAEvents, dictionary_attributes) {
        const body = {
            ...dictionary_attributes,
            context: this.formatBrowserContext(),
        };

        // handles mapping key to a custom google analytics key required by client
        const mappedKey = this.GA_Config.mapping && this.GA_Config.mapping[key] ? this.GA_Config.mapping[key] : key;
        this.googleAnalyticsService.emitGAEvent(mappedKey, body);
    }

    private sendPosthogEvent(key: validPosthogEvents, dictionary_attributes) {
        const body = {
            ...dictionary_attributes,
            context: this.formatBrowserContext(),
        };

        this.mtmService.sendEvent(key, body);
    }

    public initPosthogCapture() {
        if (this.Posthog_Config) {
            this.mtmService.initialize(this.Posthog_Config);
        }
    }

    /** A product prefix is used to indicate to cx capture team which product is being used...
     * our code generically utilizes these keys so that we do not have to concern ourselves
     * with adding the product prefix within our code */
    private CXDetermineProductPrefix(product, type: WorkflowType) {
        let productPrefix = '';

        if (ProductPrefixes[product]) {
            productPrefix += ProductPrefixes[product];
        } else {
            this.loggerService.warn('No configuration for product');
        }

        if (WorkflowPrefixes[type]) {
            productPrefix += WorkflowPrefixes[type];
        }

        return productPrefix;
    }
}
