import Broker from './Broker.model';
import Group from './Group.model';

export default class Client {
    id?: number;
    broker?: Broker;
    agency?: string;
    first_name: string;
    middle_initial?: string;
    last_name: string;
    email: string;
    phone_number: string;
    notes?: string;
    group?: Group;
    created_at?: string;
    updated_at?: string;
    effective_date?: string;
    termination_date?: string;
    data?: string;
    address_1?: string;
    address_2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
}

export interface ClientFormValue {
    createNewClient?: boolean;
    clientData?: Client;
}

export interface ClientDetailLabel {
    key: string;
    value: string | string[];
}
