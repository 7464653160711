export interface PlanCardBadge {
    type?: string;
    img?: string;
    title: string;
    description: string;
    conditions?: any;
}

export interface PlanCardBadgeConfig {
    enabled: boolean;
    types: PlanCardBadgeTypes;
}

export interface PlanCardBadgeTypes {
    [type: string]: PlanCardBadge;
}

export const planCardBadgeMetalTiers: Array<string> = ['bronze', 'silver', 'gold', 'platinum'];

export const planCardBadgeTypesDefaults: PlanCardBadgeTypes = {
    bronze: {
        title: 'Bronze',
        description: 'Expect to pay the most out-of-pocket',
    },
    silver: {
        title: 'Silver',
        description: 'Expect to pay more out-of-pocket',
    },
    gold: {
        title: 'Gold',
        description: 'Expect to pay less out-of-pocket',
    },
    platinum: {
        title: 'Platinum',
        description: 'Expect to pay the least out-of-pocket',
    },
    csr: {
        title: 'CSR Eligible',
        description: 'Extra savings available',
        conditions: {
            all: [
                {
                    name: 'plan_variation',
                    operation: 'EQUALS',
                    value: '01',
                },
            ],
        },
    },
    hsa: {
        title: 'HSA Eligible',
        description: 'Use a pre-tax savings account',
        conditions: {
            all: [
                {
                    name: 'hsa',
                    operation: 'EQUALS',
                    value: true,
                },
            ],
        },
    },
};
