<div [ngClass]="config?.displayType" class="CoverageEffectiveDate" [zipBusy]="coverageFormErrorSubscription">
    <div class="form--group--wrapper" [hidden]="shouldDisableSEP">
        <form-control [config]="whichEnrollment" [group]="form" [control]="form.get(whichEnrollment.prop)"> </form-control>
    </div>

    <div class="form--group--wrapper" *ngIf="showCoverageDateSelector">
        <form-control [config]="effectiveDateDropdownConfig" [group]="form" [control]="form.get(effectiveDateDropdownConfig.prop)">
        </form-control>
    </div>

    <div *ngIf="!isQLEenabled && !!enrollmentChosen">
        <h6 class="t-bold" [innerHTML]="formattedText.title"></h6>
        <p class="t-data coverage-subtitle" *ngIf="enrollmentChosen === 'SEP'" [innerHTML]="formattedText.subtitle"></p>
    </div>

    <div *ngIf="checkForCoverageEffectiveErrorMessage" class="CoverageEffectiveDate__errorMessage">
        <small class="t-error t-bold" [innerHTML]="effectiveDateDropdownConfig?.errors?.customErrMessage"></small>
    </div>
    <h6 *ngIf="showCoverageEffectiveText" class="t-bold" [innerHTML]="formattedText.title"></h6>
</div>
