import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { concat, of, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, debounceTime } from 'rxjs/operators';

import { reimbursementCardConfig } from './reimbursement-card.constant';

@Component({
    selector: 'reimbursement-card',
    templateUrl: './reimbursement-card.component.html',
    styleUrls: ['./reimbursement-card.component.scss'],
})
export class ReimbursementCardComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;

    @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    config = reimbursementCardConfig;
    private destroy = new Subject();

    _displayPaperFormLink: boolean;

    get displayPaperFormLink(): boolean {
        return this._displayPaperFormLink;
    }

    _employerReimbursement: boolean = false;

    get employerReimbursement(): boolean {
        return this._employerReimbursement;
    }

    get isFormValid(): boolean {
        return this.form.valid;
    }

    ngOnInit() {
        concat(of(this.form.value), this.form.valueChanges)
            .pipe(takeUntil(this.destroy), debounceTime(100), distinctUntilChanged())
            .subscribe((value: any) => {
                if (!value.employer_reimbursment) {
                    this.formValid.emit(this.isFormValid);
                    this._employerReimbursement = value.employer_reimbursment;
                } else if (value.employer_reimbursment) {
                    this._employerReimbursement = value.employer_reimbursment;
                    this.handleMessageDisplay(value);
                }
            });
    }

    ngOnDestroy() {
        this.destroy.next(null);
        this.destroy.complete();
    }

    handleMessageDisplay(formValues: any) {
        const is_qsehra = formValues.is_qsehra;
        const is_sg_coverage = formValues.is_sg_coverage;

        if (is_qsehra === false && is_sg_coverage === true) {
            this._displayPaperFormLink = false;
            this.formValid.emit(false);
        } else {
            this._displayPaperFormLink = true;
            this.formValid.emit(false);
        }
    }
}
