export default class PayeeInformation {
    readonly id?: number;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    tenant?: string;
    readonly check_date?: string;
    check_number?: string;
    payee_name?: string;
    payee_type?: string;
    check_amount: number;
}
