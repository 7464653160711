import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneObject } from '@zipari/web-utils';
import { clientsURLS, ClientData } from '@zipari/shared-sbp-constants';
import { ApiListResponse } from '@zipari/shared-sbp-models';
import { AppUserData, AuthService, ConfigService } from '@zipari/shared-sbp-services';
import { Observable } from 'rxjs';
export class NewClientModel {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    endpoint: string;
    user: AppUserData;
    clientConfigs;

    constructor(private http: HttpClient, private configService: ConfigService, public authService: AuthService) {
        const baseEnrollmentClientsUrl: string = this.configService.getEndpoint(clientsURLS);

        this.clientConfigs = cloneObject(this.configService.getPageConfig('clients'));
        this.endpoint = this.clientConfigs?.endpoint || baseEnrollmentClientsUrl;
    }

    getClientDetail(id) {
        return this.http.get(`${this.endpoint}/${id}/`);
    }

    getClientsByBrokerId(id: string | number) {
        return this.http.get(`${this.endpoint}/?broker_id=${id}/`);
    }

    retrieveLatestClients(amount) {
        return this.http.get(`${this.endpoint}/?ordering=-created_at&page=1&page_size=${amount}`);
    }

    createClient(value) {
        return this.http.post(this.endpoint, new NewClientModel(value));
    }

    getClientsByEmail(pageSize: string | number, clientEmail): Observable<ApiListResponse<ClientData>> {
        this.user = this.authService.loggedInUser.app_user_data;
        const encodedEmail: string = encodeURIComponent(clientEmail);

        return this.http.get<ApiListResponse<ClientData>>(
            `${this.endpoint}?email_iexact=${encodedEmail}&page=1&page_size=${pageSize}&ordering=-updated_at`
        );
    }
}
