import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { getValue } from '@zipari/web-utils';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
    @Input() isLoggedIn: boolean;
    @Input() notifications: number;
    @Output() logoClicked = new EventEmitter();
    @Output() buttonClicked = new EventEmitter();

    dropdownOpen: boolean;

    constructor(public navigationService: NavigationService, public router: Router) {}

    handleIconClick(icon) {
        if (icon.route) {
            this.router.navigate([icon.route]);
        }

        if (icon.onClick) {
            this.buttonClicked.emit(icon);
        }

        /* This is hack to log user out before assigning new url. It does not log user out if setTimeout is not used */
        if (!!icon.logout && icon.link) {
            setTimeout(() => {
                window.location.assign(icon.link);
            }, 250);
        }

        if (icon.link && !icon.logout) {
            window.location.assign(icon.link);
        }

        if (icon.logout) {
            this.buttonClicked.emit({ ...icon, onClick: 'logout' });
        }
    }

    get icons() {
        const icons = getValue(this.navigationService, 'config.toolbar.icons');

        if (!icons) {
            return;
        }

        return icons.filter((tool) => {
            let pass = true;
            if (tool.hasOwnProperty('auth')) {
                const showLoggedIn = tool['auth'] === 'loggedIn';
                pass = showLoggedIn === this.isLoggedIn;
            }
            return pass;
        });
    }
}
