import { Component, Input, OnInit } from '@angular/core';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { accordion, fade, slideRight } from '@zipari/design-system';
import { AnalyticsService } from '@zipari/shared-sbp-services';

import { modelAttributeConfigs } from './pcp-card.constants';

@Component({
    selector: 'pcp-card',
    templateUrl: './pcp-card.component.html',
    styleUrls: ['./pcp-card.component.scss'],
    animations: [fade, slideRight, accordion],
})
export class PcpCardComponent implements OnInit {
    @Input() pcp;
    @Input() attributeConfigs: Array<ModelAttributeConfig>;

    constructor(public analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        if (!this.attributeConfigs) {
            this.attributeConfigs = modelAttributeConfigs;
        }
    }
}
