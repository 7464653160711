<div class="u-relative u-maxX" [zipBusy]="gettingSubsidy">
    <div class="subsidy--toggle">
        <form-group [form]="showSubsidyFormGroup" [configs]="[subsidyControl]"> </form-group>
    </div>

    <card *ngIf="isSubsidySelected">
        <div body>
            <div class="demographics__form">
                <form-group [form]="form" [configs]="config.controls"> </form-group>
            </div>

            <zip-button
                (zipButtonClicked)="submitSubsidyCalculator()"
                [disabled]="!form.valid"
                [content]="config.button.content"
                [level]="config.button.level || 'medium'"
            >
            </zip-button>

            <div class="demographics__subsidy__result" *ngIf="subsidy_result; else subsidyAmounts">
                <p class="t-data t-bold">{{ resultsTitle }}</p>
                <p class="t-data" [innerHTML]="subsidy_result"></p>
            </div>
            <ng-template #subsidyAmounts>
                <div *ngIf="!state_subsidy_amount && subsidy_text && subsidy_note" class="demographics__subsidy__result">
                    <p class="t-data t-bold">{{ resultsTitle }}</p>
                    <p class="t-data">
                        {{ subsidy_text }}<span>{{ subsidyDisplay }}. </span>{{ subsidy_note }}
                    </p>
                </div>

                <state-subsidy-results
                    *ngIf="state_subsidy_amount"
                    [config]="stateSubsidyConfig"
                    [amount]="subsidy_amount"
                    [federal_subsidy_amount]="federal_subsidy_amount"
                    [state_subsidy_amount]="state_subsidy_amount"
                ></state-subsidy-results>
            </ng-template>
        </div>
    </card>
</div>
