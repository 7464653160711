import { PopUpModalConfig } from '@zipari/shared-sbp-templates';

export const dragAndDropPopUpModalConfig: PopUpModalConfig = {
    header: 'Edit Columns',
    body: 'You can add, remove and sort columns here.',
    cancelButton: {
        level: 'low',
        content: 'Cancel',
    },
    submitButton: {
        level: 'high',
        content: 'Save',
    },
};

export enum columnIcons {
    eye = 'eye',
    eye_active = 'eye_active',
}

export enum actionContent {
    show = 'Show',
    hide = 'Hide',
}

export enum iconLabel {
    show = 'Show Column',
    hide = 'Hide Column',
}

export enum ariaLabel {
    show = 'Show Button',
    hide = 'Hide Button',
}

export const gridPreferencePageName = 'gridPreference';

export const gridErrorMessage = 'An Error Occurred, Please Retry!';

const memberProp = 'product_coverages.0.member_coverages.0.member';

export const propOfNeverHiddenColumn: string[] = [
    'client',
    'first_name',
    'last_name',
    'prospect_name',
    'subscriber.first_name',
    'subscriber.last_name',
    'id',
    `${memberProp}.name`,
    `${memberProp}.member_number`,
];
