import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RawHtmlScriptString, ScriptRenderService } from '@zipari/shared-sbp-services';

export interface ServiceBotConfig {
    /** The ID for the app that is going to use the CX Messenger script. */
    appId: string;
    /** The URL that the script makes requests to when authenticating and operating. */
    baseUrl: string;
    /** The ID for the consumer that is going to use the CX Messenger script. */
    consumerId: string;
    /** The signed token used to validate the authenticity of the config during authentication.  */
    jwtToken: string;
}

@Injectable({ providedIn: 'root' })
export class ServiceBotService {
    constructor(private http: HttpClient, private scriptRender: ScriptRenderService) {}

    /** Builds and injects into the head of the document the script that authenticates and renders the CX messenger. */
    renderServiceBot(): void {
        this.getConfig().subscribe((config: ServiceBotConfig) => {
            this.scriptRender.renderScript(this.scriptRender.scriptElement(this.authenticationRawHtml(config)));
        });
    }

    /** Injects into the head of the document the script that calls the CX messenger script with the logout argument.
     * Requires the authentication script to have been injected and rendered into the document head already. */
    renderLogout(): void {
        this.scriptRender.renderScript(this.scriptRender.scriptElement(this.logoutRawHtml()));
    }

    /** Retrieves the config values which are in tenant settings for a particular tenant, here's an example:
        SERVICE_BOT": {
            "app_id": "0ca780e4-3e21-47cb-9f1b-ede8b690ecb4",
            "base_url": "https://sb0.zip.zipari.io",
            "consumer_id_field": "broker_id",
            "app_secret": "f81acc4a-f993-47be-9308-413481e852e3"
        } 
     */
    getConfig(): Observable<ServiceBotConfig> {
        return this.http.get<ServiceBotConfig>('/api/service_bot/jwt');
    }

    /** Raw string of the service bot injection JavaScript within HTML script tags. */
    authenticationRawHtml(config?: ServiceBotConfig): RawHtmlScriptString {
        return `
            window.CXMessengerSettings = {
                alignment: 'right',
                appId : '${config.appId}',
                baseUrl: '${config.baseUrl}',
                consumerId: '${config.consumerId}',
                jwtToken: '${config.jwtToken}',
                offsetBottom: '20px'
            };
            (function (w, d, s, o, f, js, fjs) {
                w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
            }(
                window,
                document,
                'script',
                'CXMessenger',
                CXMessengerSettings.baseUrl + "/v1/cx/messenger/widget/" + CXMessengerSettings.appId + "/"
            ));
            CXMessenger("authenticate", CXMessengerSettings.jwtToken);
        `;
    }

    /** Assumes that the authentication script has already been rendered in the document head. */
    logoutRawHtml(): RawHtmlScriptString {
        return "CXMessenger('logoff')";
    }
}
