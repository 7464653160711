import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    AddressPipe,
    BuildQueryPipe,
    DirectivesModule,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    GridCellDirective,
    MaskPipe,
    OutsideClickDirective,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';
import { StarRatingModule, ButtonModule, ZipBusyModule } from '@zipari/design-system';

import { AsideMenuComponent, ZipCardComponent } from '@zipari/shared-sbp-components';
import {
    StateAutoCompleteDirective,
    TranslateDirective,
    TriggerConfirmationModalDirective,
    VerifyAddressDirective,
} from '@zipari/shared-sbp-directives';
import { ModalService } from '../../../services/src/lib/modal.service';

export const SHARED_ELEMENTS = [
    ZipCardComponent,
    TriggerConfirmationModalDirective,
    AsideMenuComponent,
    StateAutoCompleteDirective,
    VerifyAddressDirective,
    TranslateDirective,
];

export const pipes = [
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
];

@NgModule({
    declarations: SHARED_ELEMENTS,
    exports: [SHARED_ELEMENTS, GridCellDirective, OutsideClickDirective, pipes],

    imports: [CommonModule, FormsModule, OverlayModule, ZipBusyModule, PipesModule, DirectivesModule, StarRatingModule, ButtonModule],
    providers: [
        CurrencyPipe,
        DatePipe,
        ModalService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
        GridCellDirective,
        OutsideClickDirective,
    ],
})
export class SharedModule {}
