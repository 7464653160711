import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column2 } from '../../../models/column.model';
import { ZipTableSorts2 } from '../../../models/zip-table.model';

@Component({
    selector: 'zip-table-header-cell-2',
    templateUrl: './header-cell.component.html',
})
export class HeaderCellComponent {
    @Input() column: Column2;
    @Input() sorts: ZipTableSorts2;
    @Output() onSortByField: EventEmitter<any> = new EventEmitter<any>();

    sortOrder: string;

    constructor() {}

    onClick(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.column.sortable) {
            if (this.sortOrder === 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
            this.onSortByField.emit({
                column: this.column,
                sortOrder: this.sortOrder,
            });
        }
    }
}
