import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormattingService, FormControlService } from '@zipari/design-system';
import { formatConfig, getValue, isEmptyObj } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';

import { SHOW_SUBSIDY_CONFIG, StateSubsidyConfig, SubsidyData, SubsidyDemographicParams, SubsidyResponse } from './subsidy.constants';
import { SubsidyService } from './subsidy.service';
import { formatTypes } from '@zipari/shared-ds-util-format';

/** handles subsidy calculation interactions with the BE */
@Component({
    selector: 'subsidy',
    templateUrl: './subsidy.component.html',
    styleUrls: ['./subsidy.component.scss'],
})
export class SubsidyComponent implements OnInit, OnChanges, OnDestroy {
    @Input() config;
    @Input() demographicParams: SubsidyDemographicParams;
    @Input() form: FormGroup;
    @Input() coverageEffectiveDate;
    @Input() subsidyData: SubsidyData;
    @Output() complete = new EventEmitter();

    private destroy$: Subject<void> = new Subject();
    pendingSubsidyUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    subsidy_amount: string;
    federal_subsidy_amount: number;
    state_subsidy_amount: number;
    subsidy_total_amount: number;
    subsidy_text: string;
    subsidy_note: string;
    gettingSubsidy: Subscription;
    subsidy_result: string;
    subsidyControl = SHOW_SUBSIDY_CONFIG;
    showSubsidyFormGroup: FormGroup = new FormGroup({});
    plan_variations: Array<any>;
    private _stateSubsidyConfig: StateSubsidyConfig;

    coverageData;

    constructor(
        public formattingService: FormattingService,
        public http: HttpClient,
        private subsidyService: SubsidyService,
        private formControlService: FormControlService
    ) {}

    /** simplified getter for accessing yes/no question displayed by the component */
    public get isSubsidySelected(): boolean {
        return this.showSubsidyFormGroup.get('showSubsidy') && this.showSubsidyFormGroup.get('showSubsidy').value;
    }

    get subsidyDisplay() {
        return this.formattingService.restructureValueBasedOnFormat(this.subsidy_total_amount, {
            format: formatTypes.CURRENCY,
        });
    }

    get stateSubsidyConfig(): StateSubsidyConfig {
        if (!this._stateSubsidyConfig) {
            this._stateSubsidyConfig = new StateSubsidyConfig(formatConfig(getValue(this.config, 'stateSubsidy')));
        }
        return this._stateSubsidyConfig;
    }

    get resultsTitle(): string {
        return this.config?.resultsTitle ? this.config?.resultsTitle : 'Eligibility Results';
    }

    ngOnInit(): void {
        this.prefillSubsidyFormControls();
        this.listenForShowSubsidyFormControlChanges();
        this.updateSubsidyOnPendingParams();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('demographicParams' in changes && changes.demographicParams.currentValue) {
            // Trigger API call if subsidy params have changed
            if (this.isSubsidySelected && this.form.valid) {
                this.submitSubsidyCalculator();
            } else {
                this.pendingSubsidyUpdate$.next(true);
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    prefillSubsidyFormControls() {
        if (this.subsidyData && !isEmptyObj(this.subsidyData)) {
            this.config.controls.forEach((control) => {
                const context: SubsidyData = {
                    ...this.subsidyData,
                };
                this.formControlService.addControlToFormGroup(this.form, control, context);
            });

            this.formControlService.addControlToFormGroup(this.showSubsidyFormGroup, this.subsidyControl, {
                showSubsidy: this.subsidyData.showSubsidy,
            });
        }
    }

    listenForShowSubsidyFormControlChanges() {
        this.showSubsidyFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value.showSubsidy === false) {
                this.subsidyData = new SubsidyData();
                this.complete.emit(this.subsidyData);
            }
        });
    }

    /** handles user submitting subsidy values to BE */
    public submitSubsidyCalculator() {
        // reset values when API is called.
        this.subsidy_result = '';
        this.subsidy_text = '';
        this.subsidy_note = '';
        this.subsidy_amount = '';
        this.state_subsidy_amount = null;
        this.federal_subsidy_amount = null;

        this.gettingSubsidy = this.subsidyService
            .getSubsidyResult(this.demographicParams, this.config.useAdvancedSubsidy, this.coverageEffectiveDate, this.form.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: SubsidyResponse) => {
                const isEligible: boolean = response['eligible'];

                if (!isEligible) {
                    this.subsidy_result = this.config.eligibilityTypes.ineligible;
                } else if (isEligible && this.config.eligibilityTypes.hasOwnProperty(response['eligibility_type'])) {
                    if (!!this.config.isSubsidyOffExchange) {
                        this.plan_variations = [''];
                    } else {
                        this.plan_variations = response['plan_variations'];
                    }

                    this.federal_subsidy_amount =
                        typeof response['federal_subsidy_amount'] === 'number' && !isNaN(response['federal_subsidy_amount'])
                            ? response['federal_subsidy_amount']
                            : 0;
                    this.state_subsidy_amount = response['state_subsidy_amount'] || 0;

                    if (!!this.config.eligibilityTypes[response['eligibility_type']].subsidy_text) {
                        this.subsidy_text = this.config.eligibilityTypes[response['eligibility_type']].subsidy_text;
                        this.subsidy_note = this.config.eligibilityTypes[response['eligibility_type']].subsidy_note;
                    } else {
                        this.subsidy_result = this.config.eligibilityTypes[response['eligibility_type']];
                    }

                    this.subsidy_total_amount = this.federal_subsidy_amount + this.state_subsidy_amount;
                }

                // note the name change to put 'plan_variations' from response to 'plan_variation' key on workflow
                this.subsidyData = new SubsidyData({
                    household_size: this.form.get('household_size').value,
                    native_indian_alaskan: this.form.get('native_indian_alaskan').value,
                    annual_income: this.form.get('annual_income').value,
                    subsidy_amount: this.subsidy_total_amount,
                    plan_variation: this.plan_variations,
                    federal_subsidy_amount: this.federal_subsidy_amount,
                    state_subsidy_amount: this.state_subsidy_amount,
                    showSubsidy: true,
                });
                this.complete.emit(this.subsidyData);
            });
    }

    updateSubsidyOnPendingParams() {
        this.showSubsidyFormGroup.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                map((value) => !!value.showSubsidy),
                distinctUntilChanged()
            )
            .subscribe((showSubsidy: boolean) => {
                if (showSubsidy && this.pendingSubsidyUpdate$.value && this.form.valid && this.form.dirty) {
                    this.pendingSubsidyUpdate$.next(false);
                    this.submitSubsidyCalculator();
                }
            });
    }
}
