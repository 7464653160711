<section class="table__pagination">
    <button
        class="table__pagination__button"
        type="button"
        [disabled]="disablePrevious"
        (click)="goToFirstPage()"
        [attr.aria-label]="'Go to first page'"
        tabindex="0"
    >
        <i class="icon icon--medium">first_page</i>
    </button>

    <button
        class="table__pagination__button"
        [disabled]="disablePrevious"
        type="button"
        (click)="goToPreviousPage()"
        [attr.aria-label]="'Go to previous page'"
        tabindex="0"
    >
        <i class="icon icon--medium">chevron_left</i>
    </button>

    <button class="table__pagination__button" type="button" tabindex="0">
        <p class="t-data t-bold">{{ currentPage }} / {{ totalCount }}</p>
    </button>

    <button
        class="table__pagination__button"
        type="button"
        [disabled]="disableNext"
        (click)="goToNextPage()"
        [attr.aria-label]="'Go to next page'"
        tabindex="0"
    >
        <i class="icon icon--medium">chevron_right</i>
    </button>

    <button
        class="table__pagination__button"
        type="button"
        [disabled]="disableNext"
        (click)="goToLastPage()"
        [attr.aria-label]="'Go to last page'"
        tabindex="0"
    >
        <i class="icon icon--medium">last_page</i>
    </button>
</section>
