import { controlTypes } from '@zipari/shared-ds-util-form';

export const brokerAssistedControlConfig = {
    endpoint: 'api/enrollment/brokers/search/?search=',
    type: controlTypes.typeahead,
    label: 'Please select a broker',
    prop: 'broker_assistance',
    requireSelect: true,
    errors: {
        customErrMessage: 'Choose a valid broker',
    },
    endpointTemplate: { label: '${first_name} ${last_name}: ${broker_id}', value: '${broker_id}' },
    options: [],
    validators: ['required'],
};

export const brokerUnassistedControlConfig = {
    type: controlTypes.text,
    prop: 'broker_assistance',
};

export const toggleConfig = {
    prop: 'broker_assistance_toggle',
    label: 'Did a broker assist you?',
    type: controlTypes.toggle,
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
};
