<section #contentEl class="modal custom--modal modal--panel" @slideRight>
    <ng-container [ngSwitch]="viewType">
        <!-- SUCCESS VIEW -->
        <ng-container *ngSwitchCase="'success'">
            <header class="modal__header">
                <p class="t-data t-bold">{{ config?.messages?.success }}</p>
            </header>

            <section class="modal__body">
                <div class="modal__message">
                    <h4 class="t-bold">ID # {{ successResponse?.id }}</h4>
                    <p class="t-data t-bold">{{ config?.messages?.success }}</p>
                </div>

                <div class="model-attributes">
                    <ng-container *ngFor="let attribute of attributes.attributes">
                        <h6 *ngIf="attribute?.title; else modelAttribute" class="add-row-attribute__title t-bold">
                            {{ attribute.title }}
                        </h6>

                        <ng-template #modelAttribute>
                            <model-attribute
                                [attr.direction]="attribute?.direction ? attribute?.direction : null"
                                [config]="attribute"
                                [context]="successResponse"
                            >
                            </model-attribute>
                        </ng-template>
                    </ng-container>
                </div>
            </section>

            <footer class="modal__footer">
                <zip-button level="low" content="Exit" (click)="onCancel()"> </zip-button>

                <zip-button *ngIf="viewProfile" level="high" content="View Profile" (click)="onContinue()"> </zip-button>
            </footer>
        </ng-container>

        <!-- ERROR VIEW -->
        <ng-container *ngSwitchCase="'error'">
            <header class="modal__header">
                <p class="t-data t-bold">{{ config?.messages?.error }}</p>
            </header>

            <section class="modal__body">
                <div class="modal__message">
                    <h4 class="t-bold">There was an error processing your request.</h4>
                </div>
                <div class="t-bold error__message">
                    <h6 *ngIf="emailError">Email {{ emailError[0] | slice : 5 }}</h6>
                    <h6 *ngIf="phoneError">PhoneNumber {{ phoneError[0] | slice : 5 }}</h6>
                </div>
            </section>

            <footer class="modal__footer">
                <zip-button level="low" content="Exit" (click)="onCancel()"> </zip-button>

                <zip-button level="high" content="Try Again" (click)="onRetry()"> </zip-button>
            </footer>
        </ng-container>

        <!-- Existing CLIENTS DATA VIEW -->
        <ng-container *ngSwitchCase="'existingClientList'">
            <existing-client-list-modal
                [config]="config"
                [existingClientsData]="existingClientsData"
                [isSaveButtonDisabled]="isSaveButtonDisabled"
                (cancel)="onCancel()"
                (save)="onSave($event)"
                (continue)="onContinue($event)"
            >
            </existing-client-list-modal>
        </ng-container>

        <!-- FORM VIEW IS DEFAULT -->
        <ng-container *ngSwitchDefault>
            <header class="modal__header">
                <p class="t-data t-bold">{{ config.header }}</p>
            </header>

            <section class="modal__body">
                <div class="form-group" *ngFor="let group of config.form.groups">
                    <header class="form-group__header">
                        <h6 class="t-bold title">{{ group.header }}</h6>
                    </header>

                    <form-group [configs]="group.controls" [form]="addRowForm"> </form-group>
                </div>
            </section>

            <footer class="modal__footer">
                <zip-button
                    level="low"
                    content="Cancel"
                    [disabled]="isModalOpen"
                    (modalConfirm)="onCancel()"
                    (modalOpen)="isModalOpen = $event"
                    [triggerConfirmationModal]="config.cancelConfirm"
                >
                </zip-button>

                <zip-button
                    level="high"
                    [disabled]="!addRowForm.valid || isModalOpen || isNextDisabled"
                    [content]="config.save"
                    (zipButtonClicked)="onSave()"
                >
                </zip-button>
            </footer>
        </ng-container>
    </ng-container>
</section>
