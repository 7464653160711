<div *ngIf="config.description" class="form-group-array__description">
    {{ config.description }}
</div>

<ng-container *ngFor="let arrayGroup of formArray.controls; let i = index">
    <zip-card>
        <ng-container title> {{ config.title }} {{ !config?.hideTitleCount ? i + 1 : '' }} </ng-container>
        <ng-container header-action>
            <zip-button
                (keydown.enter)="removeGroup(i)"
                *ngIf="formArray?.length > 1 && !(i === 0 && config.protectMain)"
                [config]="{
                    level: 'text',
                    icon: 'close'
                }"
                (zipButtonClicked)="removeGroup(i)"
            >
            </zip-button>
        </ng-container>
        <ng-container body-group>
            <div [formGroup]="arrayGroup">
                <form-group [configs]="config.controls" [form]="arrayGroup"> </form-group>
            </div>
        </ng-container>
    </zip-card>
</ng-container>

<div class="form-group-array__footer">
    <zip-button
        *ngIf="!(config?.maxLength === formArray?.length)"
        level="medium"
        icon="add"
        (zipButtonClicked)="addGroup()"
        [content]="config.addButtonText"
    >
    </zip-button>
</div>
