import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ButtonModule, ModelAttributeModule, ModalModule, IconModule } from '@zipari/design-system';
import { DragDropComponent } from './drag-drop-modal.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [DragDropComponent],
    imports: [CommonModule, ButtonModule, DragDropModule, FormGroupModule, IconModule, ModalModule, SharedModule, ModelAttributeModule],
    exports: [DragDropComponent],
})
export class DragAndDropModule {}
