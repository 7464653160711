import { FormControl, FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { AddressService } from '@zipari/shared-sbp-services';
import { concat, of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';

import { BaseCVAComponent } from './base-cva.component';

/*
    Base class contains shared funtionality for city and county dropdown components
*/
export class BaseAddressDropdownComponent extends BaseCVAComponent {
    constructor(private addressService: AddressService, private formControlService: FormControlService) {
        super();
    }

    addressFormGroup;
    config: any = {};

    get zipCodeControl(): FormControl {
        return this.addressFormGroup.get('zip_code');
    }

    /**
     * Initialization logic for component
     * @param addressFormGroup parent form group
     * @param config form control config
     * @param type are we initializing a city or county component
     */
    baseInit(addressFormGroup, config, type: 'city' | 'county') {
        this.addressFormGroup = addressFormGroup;
        this.config = config;

        this.formGroup = new FormGroup({});
        this.formControlService.addControlToFormGroup(this.formGroup, this.config);

        this.zipCodeControl &&
            concat(of(this.zipCodeControl.value), this.zipCodeControl.valueChanges)
                .pipe(
                    distinctUntilChanged(),
                    filter((zipcodeValue) => zipcodeValue && zipcodeValue.length && zipcodeValue.length === 5),
                    mergeMap((zipCodeValue) => this.addressService.getCityOrCountyOverride(zipCodeValue, type))
                )
                .subscribe((options) => {
                    /*
                Patch value if only one option available
                Dont update if zip pristine (e.g. persisted from wf)
                Null if many options (dont auto select for user)
            */
                    this.addOptionsKeepPlaceholder(options);
                    if (options.length === 1) {
                        this.formGroup.patchValue({ [this.config.prop]: options[0].value });
                    } else if (!this.zipCodeControl.pristine) {
                        this.formGroup.patchValue({ [this.config.prop]: null });
                    }
                });
    }

    /**
     * clear any existing options and add new options from api
     * keep placeholder if it exists
     * placeholder added on dropdown init if it doesn't yet exist
     */
    addOptionsKeepPlaceholder(options) {
        const placeholderOption = this.config.options.filter((option) => option.value === null);

        this.config.options = [...placeholderOption, ...options];
    }
}
