export default class ClaimLine {
    readonly id?: number;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    tenant?: string;
    billed_amount?: number;
    claimline_number?: number;
    paid_amount?: number;
    type_of_service_description?: string;
    coinsurance_amount?: number;
    copay_amount?: number;
    patient_responsibility_amount?: number;
    service_units?: string;
    deductible_amount?: number;
    allowed_amount?: number;
    amount_not_covered?: number;
    benefit_category_code_description?: string;
    place_of_service_code?: string;
    place_of_service_description?: string;
    service_from_date?: string;
    service_to_date?: string;
    status?: string;
    notes?: string;
    procedure?: number;
}
