import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { Attestation, RestructuredSignaturesConfig } from '@zipari/shared-sbp-modules';

export class FormStepControlGroupConfig {
    label?: string;
    prop?: string;
    controls?: Array<AllControlsConfiguration>;
}

// cards configured with a `prop` will result in a nested form group for the cards controls
export class FormStepCardConfig {
    label?: string;
    prop?: string;
    controlGroups?: Array<FormStepControlGroupConfig>;
    controls?: Array<AllControlsConfiguration>;
    conditional?: any;
    enableVerifyAddress?: boolean;
    addressNeedsVerification?: boolean;
    disableDisparateZip?: boolean;
    disallowPOBox?: boolean;
    paragraphs?: string[];
    updateOnBlur?: boolean;
    verifiedIfSelected?: boolean;
}

export class FormStepConfig {
    header?: {
        title?: string;
        subtitle?: string;
    };
    footer?: any;
    cards: Array<FormStepCardConfig>;
    canSkip?: boolean;
    allowSaveAndExit?: boolean;
    showHelpButton?: boolean;
    attestations?: Array<Attestation>;
    signatures?: Array<RestructuredSignaturesConfig>;
    disablePrepopulatedFields?: Array<any>;
    removeUnusedControlsOnConfigUpdate?: boolean;
}

export const defaultFooterButtons = {
    save: 'Save & Exit',
    back: 'Back',
    next: 'Save & Continue',
    skip: 'Skip',
};
