<section class="plan-filters" *ngIf="!hideFilters">
    <accordion [header]="sortHeader" [isOpen]="true">
        <form-group (formCreated)="handleSortFormCreated()" [configs]="sortForms" [form]="filterForm"> </form-group>
    </accordion>

    <accordion [header]="filterHeader" [isOpen]="openAccordion">
        <form-group (formCreated)="handleFormGroupCreated()" [configs]="filterForms" [form]="filterForm"> </form-group>

        <div class="filters--buttons">
            <zip-button (zipButtonClicked)="clearFilters()" level="medium" [content]="filterResetButton"> </zip-button>

            <zip-button (zipButtonClicked)="applyFilters()" level="high" [content]="filterApplyButton"> </zip-button>
        </div>
    </accordion>

    <footer class="plan-filters__footer" *ngIf="!hideFilterFooter">
        <div class="container">
            <div class="u-flex u-justifyBetween u-maxX">
                <zip-button level="low" content="Cancel" (zipButtonClicked)="clearFilters(); close()"> </zip-button>

                <zip-button level="high" content="Apply" (zipButtonClicked)="applyFilters()"> </zip-button>
            </div>
        </div>
    </footer>
</section>
