import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { applicationsURLS } from '@zipari/shared-sbp-constants';
import { ApplicationModel } from '@zipari/shared-sbp-models';
import { ConfigService } from '@zipari/shared-sbp-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplicationService {
    applicationEndpoint: string;

    constructor(private http: HttpClient, private formattingService: FormattingService, private configService: ConfigService) {
        this.applicationEndpoint = this.configService.getEndpoint(applicationsURLS);
    }

    getApplication(application_id: string | number): Observable<ApplicationModel> {
        return this.http.get<ApplicationModel>(`${this.applicationEndpoint}${application_id}/`).pipe(
            map((app: ApplicationModel) => {
                app.status_display = this.formattingService.restructureValueBasedOnFormat(app.status, {
                    format: formatTypes.SNAKE_CASE_TO_TITLE,
                });

                return app;
            })
        );
    }

    getApplications(): Observable<Array<ApplicationModel>> {
        return this.http.get(this.applicationEndpoint).pipe(
            map((response: { results: Array<ApplicationModel> }) =>
                response['results'].map((app: ApplicationModel) => {
                    app.status_display = this.formattingService.restructureValueBasedOnFormat(app.status, {
                        format: formatTypes.SNAKE_CASE_TO_TITLE,
                    });
                    return app;
                })
            )
        );
    }

    updateApplication(appId: string | number, payload: any): Observable<any> {
        return this.http.put(`${this.applicationEndpoint}${appId}/`, payload);
    }

    createApplication(payload: any): Observable<any> {
        return this.http.post(this.applicationEndpoint, payload);
    }
}
