export enum CELL_TYPE_FORMATS {
    FULL_NAME = 'FULL_NAME',
    ADDRESS = 'ADDRESS',
    CURRENCY = 'CURRENCY',
    DATE = 'DATE',
    LINK = 'LINK',
    LIST = 'LIST',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    STATUS = 'STATUS',
    SNAKE_CASE_TO_TITLE = 'SNAKE_CASE_TO_TITLE',
}

export const defaultCurrencyOptions = {
    locale: 'en-US',
    digitsInfo: '1.2-2',
    currencyCode: 'USD',
    display: 'symbol',
};

export const defaultDateOptions = {
    format: 'shortDate',
};
