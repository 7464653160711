<coverage-effective
    [prefilledValue]="prefillVal.coverageEffectiveDate"
    [_config]="config"
    [form]="coverageGroup"
    [productType]="prefillVal.coverage_types"
    [initialEnrollmentPeriod]="group.get('enrollment_period')?.value"
    [openEnrollmentRange]="config?.openEnrollmentRange"
    [windowShoppingRange]="config?.windowShoppingRange"
    [isQLEenabled]="config?.isQLEenabled"
    [coverageTypeControl]="coverageTypeControl"
    (enrollmentPeriodChosen)="handleNewEnrollmentPeriod($event)"
    [applicationType]="applicationType"
    (effectiveYearFound)="handleNewEffectiveDate($event)"
    [showCoverageEffectiveDateError]="showCoverageEffectiveDateError"
>
</coverage-effective>
