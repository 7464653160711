export interface DependentDetail {
    whoseCoveredValue?: any;
    index?: number;
    shouldAddDependent?: boolean;
    addDependentValue: string;
}

export enum DependentEnum {
    childDependent = 'childDependent',
    parentDependent = 'parentDependent',
    chilOnlyDependent = 'chilOnlyDependent',
    spouseDependent = 'spouseDependent',
    subscriberDependent = 'subscriberDependent',
    familyDependent = 'familyDependent',
}

export enum DependentTypeEnum {
    dependents = 'dependents',
    stepParents = 'stepParents',
    spouse = 'spouse',
    subscriber = 'subscriber',
    child_only = 'child_only',
    whose_covered = 'whose_covered',
}

export interface DependentDetail {
    index?: number;
    dependentValue?: string;
}

export interface DependentButtonConfig {
    icon: string;
    level: string;
    content: string;
}

export const addSpouseButton = {
    level: 'text',
    icon: 'add',
    content: 'Add Spouse',
};

export const addChildButton = {
    level: 'text',
    icon: 'add',
    content: 'Add Child',
};

export const addParentButton = {
    level: 'text',
    icon: 'add',
    content: 'Add Parent / Step-parent',
};

export const closeDependentCard = {
    level: 'text',
    icon: 'close',
};
