export interface IRawValue {
    child_only: boolean;
    contact: ContactInfo;
    contact_info: ContactInfo;
    county: CountyLocation;
    county_location: CountyLocation;
    coverage: ICoverage;
    coverage_effective_date: string;
    coverage_type: [];
    coverage_types: [];
    demographics: IDemographics;
    dependents?: IndividualsCovered[];
    effective_date: IEffectiveDate;
    enrollment_period: string;
    getStarted: GetStarted;
    is_dependents: boolean;
    is_spouse: boolean;
    memberCoverageOption: {};
    members: IndividualsCovered[];
    permanent_address: IAddress;
    special_enrollment: any;
    spouse?: IndividualsCovered;
    stepParents?: IndividualsCovered[];
    subscriber: IndividualsCovered;
    total_members: number;
    whose_covered: string;
}

interface ContactInfo {
    county: string;
    county_display: string;
    county_name: string;
    coverage_type: [];
    coverage_types: [];
    email: string;
    email_address: string;
    home_phone: number;
    phone: number;
    primary_phone: number;
    secondary_phone: number;
    secondary_phone_number: number;
    state: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
    zipcode: string;
}

interface CountyLocation {
    county_code: string;
    county_name: string;
    has_plans_in_service: boolean;
    id: number;
    plan_type_name: [];
    plan_year: [];
    state_code: string;
    state_name: string;
    zipcodes: [];
}

interface ICoverage {
    whoseCovered: IWhoseCovered;
}

export interface IWhoseCovered {
    dependents?: IndividualsCovered[];
    spouse?: IndividualsCovered;
    stepParents?: IndividualsCovered[];
    subscriber: IndividualsCovered;
    whose_covered: string;
}

interface IEffectiveDate {
    coverage_effective_date: string;
    enrollment_period: string;
}

interface IAddress {
    county: string;
    county_display?: string;
    county_name?: string;
    state?: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
    zipcode: string;
}

interface GetStarted {
    county: string;
    coverage_effective_date: string;
    coverage_type: [];
    zipcode: string;
}

interface IDemographics {
    child_only: boolean;
    contact: ContactInfo;
    county: string;
    coverage_effective_date: string;
    coverage_type: [];
    coverage_types: [];
    dependents?: IndividualsCovered[];
    displayCoverageType: string;
    is_sep: boolean;
    spouse?: IndividualsCovered;
    stepParents?: IndividualsCovered[];
    subscriber: IndividualsCovered;
    total_members: number;
    whose_covered: string;
    zipcode: string;
}

interface IndividualsCovered {
    address: IAddress;
    coverage_type: [];
    date_of_birth: string;
    gender?: string;
    mailing_address?: IAddress;
    home_phone?: string;
    medicare_enrolled: boolean;
    memberType: string;
    memberTypeUnique?: string;
    name: string;
    phone?: string;
    primary_phone?: string;
    tobacco_use?: boolean;
    tobacco_user: boolean;
    uuid: string;
}

export interface CoveredMembers {
    children?: IndividualsCovered[];
    spouse?: IndividualsCovered;
    stepParents?: IndividualsCovered[];
    subscriber?: IndividualsCovered;
    coverage_effective_date?: string;
}

export const coverageControlField = {
    coverageType: 'coverage_type',
};
