import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';

export class InitialSignaturesConfig {
    applicantLabel?: string;
    guardianLabel?: string;
    spouseLabel?: string;
    controls?: Array<AllControlsConfiguration>;
}

export class RestructuredSignaturesConfig {
    label?: string;
    attestation?: string;
    controls?: Array<AllControlsConfiguration>;
}
