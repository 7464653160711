import { Injectable, Input } from '@angular/core';
import { get, isArray } from 'lodash';
import { FormattingService } from '@zipari/design-system';
import { getValue, isEmptyObj } from '@zipari/web-utils';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { Benefit, MultiPlanTypes, Plan, PlanTypes, Workflow } from '@zipari/shared-sbp-models';
import { LoggerService } from '@zipari/shared-sbp-services';
import { BehaviorSubject } from 'rxjs';

export interface BenefitStructure {
    [benefitKey: string]: Benefit | BenefitStructure | any;
}

export class ShoppingCartOptions {
    formatPriceFn?: Function;
}

export class PlanTypeDisplay {
    label?: string;
    planType: PlanTypes;
    value?: number;
}

export class MapPlanTypeDisplay {
    [planType: string]: number;
}

/** Full summary of all plans that are currently being shopped for */
export class ShoppingCart {
    /** Array of all of the plan types that are in the shopping cart.
     * Useful for displaying a rollup of all of the plan types within the shopping cart
     * */
    planTypeDisplayArr?: Array<PlanTypeDisplay>;

    /** Map of plan type to count of plans within the shopping cart
     * Useful for quickly knowing how many plans of a particular plan type there are.
     * */
    planTypeDisplayMap?: MapPlanTypeDisplay;

    /** Total price added up for all plans in shopping cart */
    totalPrice: number;

    /** A display version of the total price */
    displayTotalPrice?: string;

    /** Helper structure for all plans in the shopping cart keyed by plan type
     * EX. vision: [Plan, Plan]
     * */
    plansByType: SelectedPlan;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export enum validShoppingCartEvents {
    addPlan = 'addPlan',
    removePlan = 'removePlan',
    removeAllPlans = 'removeAllPlans',
}

export class ShoppingCartEvent {
    event: validShoppingCartEvents;
    payload?: any;
    type?: string = 'quotes' || 'enrollment';
}

export class SelectedPlan {
    [planType: string]: Array<Plan>;
}

enum SelectedPlanRuleOperator {
    all = 'ALL',
    any = 'ANY',
    equals = 'EQUALS',
    notEquals = 'NOT_EQUALS',
    notExists = 'NOT_EXISTS',
}

interface SelectedPlanExclusionRule {
    // used to check if multiple rules pass
    all?: SelectedPlanExclusionRule[];
    any?: SelectedPlanExclusionRule[];

    // exclusionList represents a values of planToExclude.exclusionProp (name really should be exclusionValues)
    exclusionList: any[];
    exclusionProp: string;
    operator: SelectedPlanRuleOperator;
    prop: string;
    value: any;
}

@Injectable({
    providedIn: 'root',
})
export class PlanUtilService {
    /** Subject that tracks when a shopping cart event has been fired
     * You can subscribe to this to run code whenever an event has occurred
     * */
    shoppingCartEvent$: BehaviorSubject<ShoppingCartEvent> = new BehaviorSubject(null);

    constructor(private loggerService: LoggerService, private formattingService: FormattingService) {}

    _selectedPlans: SelectedPlan = {};

    multiPlansCoveredTypes: MultiPlanTypes[] = [];

    get selectedPlans(): SelectedPlan {
        return this._selectedPlans || {};
    }

    get multiPlanTypes(): MultiPlanTypes[] {
        return this.multiPlansCoveredTypes;
    }

    @Input('selectedPlans')
    set selectedPlans(selectedPlans: SelectedPlan) {
        this._selectedPlans = selectedPlans;
    }

    public clearSelectedPlans(emptyCart: boolean = false) {
        this.selectedPlans = {};
        if (emptyCart) {
            this.emptyShoppingCart();
        }
    }

    /** Removes a particular plan from the current shopping cart */
    public removePlanFromShoppingCart(plan: Plan, isQuoteWorkflow: boolean = false) {
        if (this.selectedPlans[plan.plan_type]) {
            this.selectedPlans[plan.plan_type] = this.selectedPlans[plan.plan_type].filter((_plan: Plan) => plan.id !== _plan.id);

            this.sendRemoveEvent(plan.plan_type, isQuoteWorkflow);
        }
    }

    public emptyShoppingCart() {
        this.shoppingCartEvent$.next({ event: validShoppingCartEvents.removeAllPlans });
    }

    public sendRemoveEvent(plan_type, isQuoteWorkflow: boolean = false) {
        this.shoppingCartEvent$.next({
            event: validShoppingCartEvents.removePlan,
            payload: plan_type,
            type: isQuoteWorkflow ? 'quotes' : 'enrollment',
        });
    }

    /** Adds a particular plan from the current shopping cart */
    public addPlanFromShoppingCart(plan: Plan) {
        if (!this.selectedPlans[plan.plan_type]) {
            this.selectedPlans[plan.plan_type] = [];
        }

        if (Array.isArray(this.selectedPlans[plan.plan_type])) {
            this.selectedPlans[plan.plan_type].push(plan);
        }

        this.shoppingCartEvent$.next({
            event: validShoppingCartEvents.addPlan,
        });
    }

    /** Helper that formats the benefits on the plan object to a standardized key/structure */
    formatBenefitsOnPlan(plan: Plan) {
        const benefits: Array<Benefit> = [];

        // create formatted benefits from the plan
        plan.formattedBenefits = this.formatBenefits(plan.benefits, benefits);

        return plan;
    }

    /** Recursive function that handles formatting benefit structure into one final array of benefits
     * This is tech debt like due to the many different benefit structures that have existed in our systems
     * */
    public formatBenefits(benefits: BenefitStructure | Array<Benefit>, acc: Array<Benefit>, key: string | null = null) {
        if (!benefits) {
            this.loggerService.warn('No benefits found');

            return [];
        }

        if (typeof benefits === 'object' && Array.isArray(benefits)) {
            benefits.forEach((benefit: Benefit) => {
                acc.push(benefit);
            });
        } else if (typeof benefits === 'object' && !Array.isArray(benefits) && benefits.hasOwnProperty('value')) {
            const benefit: Benefit = { ...benefits };

            if (!benefit.label && key) {
                benefit.label = key;
            }

            acc.push(benefit);
        } else {
            const benefitKeys: Array<string> = Object.keys(benefits || {});

            benefitKeys.forEach((benefitKey: string) => {
                return this.formatBenefits(benefits[benefitKey], acc, benefitKey);
            });
        }

        return acc;
    }

    /** Takes in the different plan structures and reformats them so that they are in array format.
     * Extremely helpful when trying to loop over plans.
     * */
    public formatPlansAsArr(plans: Array<Plan> | { [planType: string]: Plan | Array<Plan> }): Array<Plan> {
        let arrPlans: Array<Plan> = [];

        if (plans) {
            if (!Array.isArray(plans)) {
                const planKeys: Array<string> = Object.keys(plans);

                planKeys
                    .map((planType: string) => {
                        if (Array.isArray(plans[planType])) {
                            return plans[planType];
                        }

                        return [plans[planType]];
                    })
                    .forEach((planAsArr: Array<Plan>) => {
                        planAsArr.forEach((plan: Plan) => {
                            arrPlans.push(plan);
                        });
                    });
            } else {
                arrPlans = plans;
            }
        }

        return arrPlans;
    }

    /** Appends the primary benefits onto the benefit array list
     * Super useful for looping through all of the benefits for the plan easily
     * */
    public addPrimaryBenefitsToBenefits(workflowValues: any) {
        if (!workflowValues || !workflowValues.plans) {
            this.loggerService.warn('Unable to add primary benefits to benefits. Missing workflow values and/or plans.');
            return;
        }
        // map elements of the primary_benefits array into the benefits array.
        Object.keys(workflowValues.plans).forEach((planKey: string) => {
            const plan = workflowValues.plans[planKey];
            const primaryBenefits: Array<any> = plan ? plan['primary_benefits'] : null;
            if (plan.benefits && primaryBenefits) {
                plan.benefits = [...primaryBenefits, ...plan.benefits];
            }
        });
    }

    /** Helper to sort plans provided by an order provided as an array of plan types
     *
     * EX.
     * plans =  [{"plan_type": "vision"}, {"plan_type": "medical"}]
     * order = ["medical","vision"]
     *
     * Returns [{"plan_type": "medical"}, {"plan_type": "vision"}]
     * */
    public sortByPlanType(plans: Array<Plan>, order: Array<String>): Array<Plan> {
        // if no order is provided then return the plans in the same order they were given
        if (!order) {
            return plans;
        }

        // sort the plans by the plan types provided in order
        // then return the result
        return plans.slice().sort((a: Plan, b: Plan) => {
            return order.indexOf(a.plan_type) - order.indexOf(b.plan_type);
        });
    }

    /** Sums up the price of all plans provided to the function */
    retrievePriceOfPlans(plans): number {
        try {
            const plansArr = this.formatPlansAsArr(plans);

            return plansArr.map((plan: Plan) => Number(plan.price)).reduce((current: number, sum: number) => (sum += current), 0);
        } catch (err) {
            this.loggerService.error(err);

            return 0;
        }
    }

    /** Helper to pull plans from a workflow safely.
     * This wraps the standard of only using "plans" and "quoted" as ways to add plans in the workflow values
     * */
    public retrievePlansFromWorkflow(workflow) {
        if (!workflow) {
            return {};
        }

        return getValue(workflow.values || {}, 'plans') || getValue(workflow.values || {}, 'quoted') || {};
    }

    /** Helper function that handles all dependencies for when the shopping cart needs to be retrieved */
    public shoppingCartWithDependencies(workflow: Workflow<any> | any, formattingService: FormattingService) {
        const plans = this.retrievePlansFromWorkflow(workflow);
        const format = formatTypes.CURRENCY;
        const subsidy = this.getSubsidyAmount(workflow);
        const formatPriceFn = (price: number): string => formattingService.restructureValueBasedOnFormat(price.toString(), { format });

        return this.retrieveShoppingCart(plans, { formatPriceFn }, subsidy);
    }

    getSubsidyAmount(workflow) {
        return get(workflow, 'values.subsidy_amount');
    }

    /** Compilation of all plans that are currently being shopped for including the total price. */
    public retrieveShoppingCart(plans: SelectedPlan | any, options: ShoppingCartOptions = {}, subsidy): ShoppingCart {
        // Make sure that the plans passed in here are an "array" of a single plan if the selected plan is a map
        let providedPlans: any = {};

        if (Array.isArray(plans)) {
            plans.forEach((plan) => {
                if (!providedPlans[plan.plan_type]) {
                    providedPlans[plan.plan_type] = [];
                }

                providedPlans[plan.plan_type].push(plan);
            });
        } else {
            Object.keys(plans || {}).forEach((planType: PlanTypes) => {
                const plan = plans[planType];

                if (!Array.isArray(plan) && !!plan) {
                    providedPlans[planType] = [plans[planType]];
                }
            });
        }

        // if redirected to the plan-selection step from review (or direct url)
        // then `selectedPlans` doesn't refelect what's in the workflow
        // adding the selected plans from workflow.values to account for this
        if (isEmptyObj(this.selectedPlans) && !isEmptyObj(providedPlans)) {
            this._selectedPlans = providedPlans;
        }

        // Setup full plans for the shopping cart
        const plansByType: SelectedPlan = Object.assign({}, providedPlans, this.selectedPlans);

        // Retrieve unique plan types and their counts
        const planTypeDisplayMap: MapPlanTypeDisplay = {};
        Object.keys(plansByType).forEach((planType: PlanTypes) => {
            const allPlansBySpecificType = this.removeDuplicatePlanById(plansByType[planType]);

            if (Array.isArray(allPlansBySpecificType)) {
                allPlansBySpecificType.forEach(() => {
                    if (!planTypeDisplayMap[planType]) {
                        planTypeDisplayMap[planType] = 1;
                    } else {
                        planTypeDisplayMap[planType]++;
                    }
                });
            }
        });

        // Convert the unique plan type map into an array
        const planTypeDisplayArr = Object.keys(planTypeDisplayMap).map((planType: PlanTypes) => {
            const label: string = `${planType.substring(0, 1).toUpperCase()}${planType.substring(1, planType.length)}`;
            return {
                count: planTypeDisplayMap[planType],
                planType,
                label,
            };
        });

        // Calculate price of all provided plans
        // If there is more than one plan for a particular plan type (which should only happen during quoting) then
        // don't calculate the price and set it back to 0
        let totalPrice: number = 0;
        let stopCalculating: boolean = false;
        Object.keys(plansByType).forEach((planType: PlanTypes) => {
            if (stopCalculating) {
                totalPrice = 0;
            } else {
                const planTypeSummary = this.removeDuplicatePlanById(plansByType[planType]);
                if (Array.isArray(planTypeSummary) && planTypeSummary.length > 1) {
                    stopCalculating = true;
                } else if (Array.isArray(planTypeSummary) && planTypeSummary.length === 1) {
                    const plan = plansByType[planType][0];
                    if (plan) {
                        totalPrice += Number.parseFloat(plan.price);
                    }
                }
            }
        });

        let medicalPlan = isArray(this.selectedPlans.medical) ? this.selectedPlans.medical[0] : this.selectedPlans.medical;
        const isPlanOffExchange = medicalPlan && medicalPlan['plan_variation'] === '00';

        if (subsidy && !isPlanOffExchange && medicalPlan) {
            const subsidizedPrice = totalPrice - subsidy;
            totalPrice = subsidizedPrice >= 0 ? subsidizedPrice : 0;
        }

        // Format the price if a format price function is provided
        let displayTotalPrice: string = '';
        if (options.formatPriceFn) {
            displayTotalPrice = options.formatPriceFn(totalPrice);
        }

        return new ShoppingCart({
            planTypeDisplayArr,
            displayTotalPrice,
            totalPrice,
            plansByType,
        });
    }

    private removeDuplicatePlanById(allPlansByPlanType: Plan[]): Plan[] {
        return allPlansByPlanType.reduce(
            (allPlans, currentPlan) =>
                allPlans.find((planByPlanType: Plan) => planByPlanType.id === currentPlan.id) ? allPlans : [...allPlans, currentPlan],
            []
        );
    }

    /** Filter available plans in scenarios where BRs and cohorts cannot be used
     *
     * TODO: Move to BE
     *
     *  Sample:
     *  "planSelectionFilterRules": [
     *      {
     *          "name": "over65medABafter2020",
     *          "plan_type": "medicare_supplemental",
     *          "conditions": {
     *              "over65in2020": true,
     *              "medABafter2020": true
     *          },
     *          "filterBy": {
     *              "prop": "external_id",
     *              "values": ["Supplement Plan A", "Supplement Plan D", "Supplement Plan G", "Supplement Plan K", "Supplement Plan N"]
     *          }
     *      }
     *  ]
     *
     **/
    public filterPlansByConfigRules(plans: Array<Plan>, config: any, planFilterConditionVars: any) {
        let filteredPlans = plans.filter((plan) => {
            let showPlan = true;
            config.forEach((rule) => {
                let matchesPlanType, meetsConditions;
                let conditionVars = Object.keys(rule.conditions);
                matchesPlanType = plan.plan_type === rule.plan_type;

                let conditions = conditionVars.map((c) => planFilterConditionVars[c] && planFilterConditionVars[c] === rule.conditions[c]);
                meetsConditions = conditions.every((o) => o === true);

                if (matchesPlanType && meetsConditions) {
                    const prop = rule.filterBy.prop;
                    const values = rule.filterBy.values;
                    showPlan = values.includes(plan[prop]);
                } else if (matchesPlanType && !meetsConditions) {
                    // do nothing
                } else if (!matchesPlanType) {
                    showPlan = true;
                }
            });

            if (showPlan) {
                return plan;
            }
        });

        return filteredPlans;
    }

    /**
     * Plan selection filter rule methods. Many of these will be temporary.
     * TODO: Move to BE
     **/
    public over65in2020(date) {
        if (!date) return false;
        const lastDay = new Date('12/31/2020'); // check against last day of the year
        const birthDate = new Date(date.replace('-', '/')); // convert YYYY-MM-DD to YYYY/MM/DD to avoid JS date issue
        let age = lastDay.getFullYear() - birthDate.getFullYear();
        const m = lastDay.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && lastDay.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= 65;
    }

    // TODO: Move to BE
    public medABafter2020(medA, medB) {
        if (!medA || !medB) return false;
        const a = new Date(medA.replace('-', '/'));
        const b = new Date(medB.replace('-', '/'));
        return a >= new Date('01/01/2020') && b >= new Date('01/01/2020');
    }

    // TODO: Move to BE
    public medApriorTo2020(medA) {
        if (!medA) return false;
        const a = new Date(medA.replace('-', '/'));
        return new Date('01/01/2020') > a;
    }

    /**
     * Determine if selected plan(s) have a different exchange type from input plan
     * @param selectedPlans Either a list or single plan selected by user
     * @param plan Plan to compare exchange types against selected plan(s)
     */
    public hasSelectedAlternateExchangeType(selectedPlans: Plan | Array<Plan>, plan: Plan): boolean {
        if (!selectedPlans) {
            return false;
        }
        const isPlanOffExchange: boolean = this.isPlanOffExchange(plan);
        if (Array.isArray(selectedPlans)) {
            return !!selectedPlans.find((selectedPlan: Plan) => {
                const isSelectedPlanOffExchange: boolean = this.isPlanOffExchange(selectedPlan);
                return isPlanOffExchange !== isSelectedPlanOffExchange;
            });
        } else {
            const isSelectedPlanOffExchange: boolean = this.isPlanOffExchange(selectedPlans);
            return isPlanOffExchange !== isSelectedPlanOffExchange;
        }
    }

    /**
     * Determine whether plan is on or off exchange based on `plan_variation`
     * Logic taken from https://jira.zipari.net/browse/CE-12129
     * @param plan Plan to determine on/off exchange
     */
    public isPlanOffExchange(plan: Plan): boolean {
        const offExchangePlanVariation: string = '00';
        return plan.plan_variation === offExchangePlanVariation;
    }

    /**
     * TODO: Should ideally be handled by the BE
     *
     * Return plans that pass the SelectedPlanRules. These rules will exclued plans based on the logic below.
     *
     * The purpose of this function is to remove specified selectable plans from the view after a user selects one plan.
     *
     * NOTE - These rules don't follow the same logic as our zipBusiness Rules. Although the rule names are the same (and misleading)
     *        the logic performed is NOT the same
     *
     * @param rules Rules from the config that specify the details
     * @param allPlans All available plans
     * @param selectedPlans Plans currently selected by the user
     *
     * @returns Plans within allPlans that have passed the rules based on the currently selectedPlans
     */
    public selectedPlanRules(
        exclusionRules: SelectedPlanExclusionRule[],
        allPlans: Plan[],
        selectedPlans: Plan[] | { [planType: string]: Plan | Plan[] }
    ): Plan[] {
        // set to array of selected plans and remove null/undefined plans
        const selectedPlansArr: Plan[] = Array.isArray(selectedPlans) ? [...selectedPlans] : this.formatPlansAsArr(selectedPlans);

        // Iterate over each rule. If a rule should be applied to one of the selected plans, apply the rule to the plan
        // and exclude the plan from selected plans if the rule passes.
        let allSelectablePlans = [...allPlans];
        exclusionRules.forEach((rule) => {
            // if a condition passes, remove the plans on rule.exclusionList from allPlans
            if (this.getSelectedPlanExclusionRuleResult(rule, selectedPlansArr)) {
                allSelectablePlans = allSelectablePlans.filter(
                    (plan) => rule.exclusionList.indexOf(getValue(plan, rule.exclusionProp)) === -1
                );
            }
        });

        // return plans that pass rules based on the selectedPlans and rules
        return allSelectablePlans;
    }

    /**
     * Returns true if a selected plan matches rule(s) specified in the config. If a selected plan is found and
     * a rule passes, then we will exclude specified plans from All Selectable plans
     * This will basically prevent the user from viewing specified plans as being selectable after they have already selected a plan
     *
     * @param rule
     * @param selectedPlans
     * @param multiRuleIndex
     */
    private getSelectedPlanExclusionRuleResult(exclusionRule: SelectedPlanExclusionRule, selectedPlans: Plan[]): boolean {
        const plansMatchingRuleProp = selectedPlans.filter((plan) => getValue(plan, exclusionRule.prop) === exclusionRule.value);

        switch (exclusionRule.operator) {
            case SelectedPlanRuleOperator.notEquals:
                return selectedPlans.length && (!plansMatchingRuleProp || !plansMatchingRuleProp.length);
            case SelectedPlanRuleOperator.equals:
                return plansMatchingRuleProp && !!plansMatchingRuleProp.length;
            case SelectedPlanRuleOperator.notExists:
                return selectedPlans.length === 0;
            case SelectedPlanRuleOperator.all:
                // apply multiple rules dammit!
                return exclusionRule.all.every((rule) => this.getSelectedPlanExclusionRuleResult(rule, selectedPlans));
            case SelectedPlanRuleOperator.any:
                // apply multiple rules dammit!
                return exclusionRule.any.some((rule) => this.getSelectedPlanExclusionRuleResult(rule, selectedPlans));
            default:
                // no rule operator specified, return false yo
                return false;
        }
    }

    public isFamily(workflowValues) {
        const childOnly = getValue(workflowValues, 'child_only');
        const whose_covered = getValue(workflowValues, 'whose_covered');
        const isDependents = getValue(workflowValues, 'is_dependents');
        const isSpouse = getValue(workflowValues, 'is_spouse');
        return (
            (isDependents && !childOnly) ||
            isSpouse ||
            whose_covered === 'spouse' ||
            whose_covered === 'parent' ||
            whose_covered === 'family'
        );
    }

    public getBenefitFromLabel(
        planBenefits: Benefit[],
        primaryBenefits: Benefit[],
        benefitConfig,
        plan: Plan,
        subsidyAmount: number
    ): Benefit {
        const label: string = benefitConfig.label;
        const type: string = benefitConfig.type;

        if (type === 'plan') {
            let value = getValue(plan, benefitConfig.planKey || benefitConfig.value);

            // Format the value if a format is provided
            if (benefitConfig.format) {
                value = this.formattingService.restructureValueBasedOnFormat(value, benefitConfig);
            }

            // If we have a subsidy, and the plan being compared is on-exchange, modify the monthly premium displayed on compare
            if (benefitConfig.prop === 'price' && subsidyAmount > 0 && parseInt(plan.plan_variation, 10) > 0) {
                // use regex to remove `$` from the value string to return the number with decimals
                const planBenefitsValue: number = parseFloat(value.replace(/[^0-9, ^.]/g, ''));
                const base: number = 100;
                // calculatedPrice will always be a string, but we're marking it type any to leverage type coercion
                const calculatedPrice: string = (Math.round((planBenefitsValue - subsidyAmount) * base) / base).toFixed(2);
                // using type coercion to account for values between `0` and `1` which parseInt() would default to 0
                value =
                    (parseFloat(calculatedPrice) * base) / base <= 0
                        ? `$0.00 <br> with a $${subsidyAmount} subsidy <br> (originally ${value})`
                        : `$${calculatedPrice} <br> with a $${subsidyAmount} subsidy <br> (originally ${value})`;
            }

            return {
                value,
                label,
            };
        }
        if (primaryBenefits) {
            for (let i = 0; i < primaryBenefits.length; i++) {
                const currentBenefit = primaryBenefits[i];

                if (currentBenefit.label === label) {
                    return currentBenefit;
                }
            }
        }

        for (let i = 0; i < planBenefits.length; i++) {
            const currentBenefit = planBenefits[i];

            if (currentBenefit.label === label) {
                return currentBenefit;
            }
        }

        return {
            label: '',
            value: '',
        };
    }

    public setCoveredMultiPlanTypes(multiPlanTypes: MultiPlanTypes[]) {
        this.multiPlansCoveredTypes = multiPlanTypes;
    }
}
