import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormGroupComponent, FormControlService } from '@zipari/design-system';
import { RadioConfiguration, FormControlConfiguration, controlTypes } from '@zipari/shared-ds-util-form';
import { MessageBannerConfig, BannerTypes } from '@zipari/shared-ds-util-messages';
import { coverageControlField } from '@zipari/shared-sbp-constants';
import { Broker, County } from '@zipari/shared-sbp-models';
import {
    AddressService,
    AuthService,
    BrokerService,
    BrokerAssistanceService,
    ConfigService,
    CoverageEffectiveDateService,
    ZipcodeLocationData,
} from '@zipari/shared-sbp-services';
import { whichEnrollment } from '@zipari/shared-sbp-templates';
import { cloneObject, getValue, isEmptyObj, isObj } from '@zipari/web-utils';
import { concat, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import {
    CoverageFormConfig,
    StateNotLicensedErrorConfig,
    coverageFormControlFieldConfig,
    updateErrorMessage,
} from '@zipari/shared-sbp-enrollment';

@Component({
    selector: 'coverage-form',
    templateUrl: './coverage-form.component.html',
    styleUrls: ['./coverage-form.component.scss'],
})
export class CoverageFormComponent implements AfterViewInit, OnInit, OnChanges, DoCheck, OnDestroy {
    @Input() disabled: boolean;
    @Input() coverageFormGroup: FormGroup;
    @Input() hideCoverageTypes;
    @Input() workflowValues;
    @Input() workflowData: any;
    @Input() responsive = true;
    @Input() coverageDisplay;
    @Input() zipcode: string;
    @Input() county: string;

    @Output() countySelected = new EventEmitter<County>();
    @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() hasChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() newCountyOptions: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() newCoverageTypeOptions: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() afterFormInit: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild('countyFormGroup') countyFormGroup: FormGroupComponent;
    @ViewChild('coverageTypesFormGroup') coverageTypesFormGroup: FormGroupComponent;

    debounce = 1500;
    countyOptionsByValue = {};
    countyOptions;
    coverageOptionsSet;
    zipcodeResponseQueue;
    previousZipcodeResponse;
    prevCounty: any;

    zipcodeSub: Subscription;
    coverageSub: Subscription;
    formValidSub: Subscription;
    countySub: Subscription;
    formHasChangesSub: Subscription;
    busy: Subscription;

    originalValues;
    coverageByState: boolean;
    coverageFormConfig: CoverageFormConfig;
    stateNotLicensedErrorConfig: StateNotLicensedErrorConfig;
    stateNotLicensedError: MessageBannerConfig;
    whichEnrollment: RadioConfiguration = cloneObject(whichEnrollment);
    isZipcodeValid: boolean = true;

    constructor(
        private addressService: AddressService,
        private authService: AuthService,
        private brokerAssistanceService: BrokerAssistanceService,
        private brokerService: BrokerService,
        private cdr: ChangeDetectorRef,
        private configService: ConfigService,
        private coverageEffectiveService: CoverageEffectiveDateService,
        private formControlService: FormControlService
    ) {}

    public get valid(): boolean {
        let valid: boolean = true;
        const excludedProps = {
            coverage_type: !!this.hideCoverageTypes,
            coverage_types: !!this.hideCoverageTypes,
        };

        const checkboxesRequired: Array<FormControlConfiguration> = Object.values(this.coverageFormConfig).filter(
            (controlConfig) =>
                ['singleCheckbox', 'checkbox'].includes(controlConfig.type) &&
                controlConfig.validators &&
                controlConfig.validators.includes('required')
        );

        const noCheckboxesSelected: boolean = checkboxesRequired.every((controlConfig) => {
            const checkboxVals = getValue(this.coverageFormGroup, `controls.${controlConfig.prop}.value`);
            if (Array.isArray(checkboxVals)) {
                return checkboxVals.every((val) => {
                    return val === '' || val === null || (typeof val === 'object' && isEmptyObj(val));
                });
            } else {
                return !checkboxVals;
            }
        });
        if (!excludedProps.coverage_types && checkboxesRequired.length && noCheckboxesSelected) valid = false;

        const includedPropsAreValid = Object.keys(this.coverageFormGroup.controls).reduce((acc: boolean, prop: string) => {
            // Ignore fields that are not part of this component's config. Needed because
            // in demographics.component, coverageFormGroup is shared with another component,
            // which adds it's own fields such as whichEnrollment and coverage_effective_date
            if (excludedProps[prop] || !this.coverageFormConfig[prop]) return acc;
            return acc && this.coverageFormGroup.controls[prop].valid;
        }, true);
        if (!includedPropsAreValid) valid = false;

        if (this.stateNotLicensedError) valid = false;

        return valid;
    }

    public get coverageOptions() {
        return getValue(this.coverageFormConfig, 'coverage_type.options');
    }

    public get coverageControl() {
        return this.coverageFormGroup.get(coverageControlField.coverageType);
    }

    public get countyControl() {
        return this.coverageFormGroup.get('county');
    }

    public get zipcodeControl() {
        return this.coverageFormGroup.get('zipcode');
    }

    public getCountyLocationData(countyCode: string): any {
        if (!getValue(this.countyOptionsByValue, `${countyCode}.data`)) {
            return null;
        }

        return this.countyOptionsByValue[countyCode].data;
    }

    ngOnInit() {
        this.retrieveConfig();
        this.buildCoverageForm();

        this.handleZipcodeCountyMonitor();

        this.coverageByState && this.handleCoverageMonitor();

        this.handlePrefillingComponentValues();

        this.setupFormValidSub();
        this.setupCountySub();
        this.setupFormHasChangesSub();
    }

    ngAfterViewInit(): void {
        // Prefill county
        if (this.countyFormGroup && this.countyOptions) {
            this.countyFormGroup.updateOptions('county', this.countyOptions);
        }
    }

    ngDoCheck() {
        if (!!this.zipcodeResponseQueue?.length) {
            this.retrieveCounty(this.zipcodeResponseQueue);

            this.zipcodeResponseQueue = null;
        }

        // TODO: SBP-11790 Refactor to use built-in validators
        if (!this.isZipcodeValid) {
            this.updateZipcodeErrorMessage();
        }

        const selectedCountyValue = this.coverageFormGroup.get(coverageFormControlFieldConfig.county)?.value;

        if (selectedCountyValue) {
            this.updateErrorMessageIfPlansExist();
        }
    }

    private updateErrorMessageIfPlansExist(): void {
        const countyLocation = this.getCountyLocationData(this.coverageFormGroup.controls.county.value);

        if (countyLocation && !countyLocation?.has_plans) {
            this.coverageFormGroup.controls.county.setErrors({ customErrMessage: true });
        }
    }

    private updateZipcodeErrorMessage(): void {
        this.coverageFormGroup.controls.zipcode.setErrors({ customErrMessage: true });
    }

    ngOnDestroy() {
        const subscriptions = [this.coverageSub, this.zipcodeSub, this.formValidSub, this.formHasChangesSub, this.busy];
        subscriptions.forEach((sub: Subscription) => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('workflowValues' in changes) {
            const { previousValue, currentValue } = changes.workflowValues;
            const previousWorkflowHasData: boolean = isObj(previousValue) && !isEmptyObj(previousValue);
            const currentWorkflowHasData: boolean = isObj(currentValue) && !isEmptyObj(currentValue);

            // On workflow.service continueWorkflow(), the first value emitted is null.
            // Prefill if workflow values were empty and are now not empty to handle continueWorkflow().
            if (!previousWorkflowHasData && currentWorkflowHasData) this.handlePrefillingComponentValues();
        }
        if ('updateConfigs' in changes || 'stepConfig' in changes) {
            this.retrieveConfig();

            if (this.previousZipcodeResponse) {
                this.retrieveCounty(this.previousZipcodeResponse);
            }
        }
    }

    /**
     * Emit true if any value is different than its original, pre-populated value
     */
    setupFormHasChangesSub() {
        this.originalValues = this.coverageFormGroup.value;

        this.formHasChangesSub = concat(of(this.coverageFormGroup.value), this.coverageFormGroup.valueChanges)
            .pipe(
                map((value) => {
                    let anyChanges = false;
                    const propNames = Object.keys(this.coverageFormConfig);

                    propNames.forEach((key: string) => {
                        const originalVal =
                            this.originalValues[key] ||
                            getValue(this.workflowValues, `contact.${key}`) ||
                            getValue(this.workflowValues, `permanent_address.${key}`);
                        const valueHasChanged = !!value[key] && value[key] !== originalVal;
                        if (valueHasChanged) anyChanges = true;
                    });
                    return anyChanges;
                }),
                distinctUntilChanged()
            )
            .subscribe((hasChanges) => {
                this.hasChanges.emit(hasChanges);
            });
        this.afterFormInit.emit(null);
        this.handlePrefillingValidation();
    }

    setupCountySub() {
        this.countySub = this.countyControl?.valueChanges.subscribe((val) => {
            if (this.prevCounty !== val) {
                this.prevCounty = val;
                this.emitCountySelected(val);
            }
        });
    }

    setupFormValidSub() {
        this.formValidSub = concat(of(this.coverageFormGroup.status), this.coverageFormGroup.statusChanges)
            .pipe(
                map((status) => this.valid),
                distinctUntilChanged()
            )
            .subscribe((valid) => this.formValid.emit(valid));
    }

    handlePrefillingComponentValues() {
        const addressZipcode = getValue(this.workflowValues, 'permanent_address.zipcode');
        const clientZipcode = getValue(this.workflowValues, 'client.zip_code');
        const selectedCountyCode = getValue(this.workflowValues, 'county.county_code');
        const demographicsZipcode = getValue(this.workflowData, 'demographics.zipcode');
        const zipCodePrefill = this.zipcode || addressZipcode || clientZipcode || demographicsZipcode;
        const coverageTypePrefill =
            getValue(this.workflowValues, 'demographics.coverage_type') || getValue(this.workflowValues, 'demographics.coverage_types');

        if (this.zipcodeControl && zipCodePrefill) {
            this.zipcodeControl.patchValue(zipCodePrefill);
            this.zipcodeControl.markAsTouched();
        }

        if (this.countyControl && selectedCountyCode) {
            this.countyControl.patchValue(selectedCountyCode);
            this.countyControl.markAsTouched();
            this.emitCountySelected(selectedCountyCode);
        }

        if (this.coverageControl && coverageTypePrefill) {
            this.coverageControl.patchValue(coverageTypePrefill);
            this.coverageControl.markAsTouched();
        }
    }

    handlePrefillingValidation() {
        if (!!this.coverageDisplay) {
            let prop = this.coverageFormGroup;
            if (!!prop) {
                Object.keys(prop.value).forEach((key) => {
                    if (prop.get(key) && !prop.get(key).value) {
                        prop.get(key).markAsTouched();
                        prop.get(key).updateValueAndValidity();
                    }
                });
            }
        }
    }

    handleZipcodeCountyMonitor() {
        let previousZip: string = '';
        this.zipcodeSub = this.zipcodeControl?.valueChanges
            .pipe(
                distinctUntilChanged(),
                filter((value) => value !== '')
            )
            .subscribe((zipcode: string) => {
                this.countyControl.patchValue(null);

                if (this.coverageFormGroup.get(this.whichEnrollment.prop)) {
                    this.coverageFormGroup.get(this.whichEnrollment.prop).reset();
                }

                if (!this.zipcodeControl.valid) {
                    /* set empty if the zip code is not valid  */
                    this.zipcodeResponseQueue = [];
                } else if (!!this.zipcodeControl.valid && previousZip !== this.zipcodeControl.value) {
                    this.busy = this.addressService.getZipcodeLocationData(this.zipcodeControl.value, true).subscribe(
                        (zipcodeData: ZipcodeLocationData[]) => {
                            this.zipcodeResponseQueue = zipcodeData;
                            this.isZipcodeValid = true;
                            // httpClient not throwing an error when the API call returns 404, placing a catch in case the response is empty
                            if (zipcodeData.length === 0) {
                                this.countyFormGroup.updateOptions(coverageFormControlFieldConfig.county, []);
                                this.coverageFormGroup.controls.zipcode.setErrors({ customErrMessage: true });
                            }
                        },
                        (error) => {
                            this.isZipcodeValid = false;
                            this.zipcodeResponseQueue = [];
                            this.countyFormGroup.updateOptions(coverageFormControlFieldConfig.county, []);
                            this.coverageFormGroup.controls.zipcode.setErrors({ customErrMessage: true });
                        }
                    );

                    if (previousZip) {
                        this.coverageControl.reset();
                    }
                }
                previousZip = zipcode;
            });
    }

    /**
     * Watch for changes to county control
     * Clear coverage options when no control value
     * Get options from api when valid county data in dropdown
     * Set coverage dropdown
     */
    handleCoverageMonitor() {
        const countyControl$: Observable<string> = concat(of(this.countyControl.value), this.countyControl.valueChanges);

        this.coverageSub = countyControl$
            .pipe(
                distinctUntilChanged(),
                debounceTime(this.debounce),
                tap((county) => !county && this.setCoverageOptions()),
                filter((county) => county && this.getCountyLocationData(county)),
                switchMap((county) => this.coverageEffectiveService.getCoverageOptionsByCounty(this.getCountyLocationData(county)))
            )
            .subscribe((coverageOptions) => {
                this.setCoverageOptions(coverageOptions);
                this.emitCountySelected(this.countyControl.value);
            });
    }

    retrieveCounty(zipcodeResponse) {
        const zipcodeValue = this.zipcodeControl.value;
        const workflowZip = getValue(this.workflowValues, 'permanent_address.zipcode');
        const workflowCounty = getValue(this.workflowValues, 'permanent_address.county');
        /** match between available county options and county value input to component */
        let inputCountyMatch = false;

        const countyOptions = this.mapCounty(zipcodeResponse);

        this.newCountyOptions.emit(countyOptions);
        this.countyOptions = countyOptions;
        if (this.countyFormGroup) {
            // Will not trigger on init because countyFormGroup is a DOM selector. On init pre-populate handled in ngAfterViewInit.
            this.countyFormGroup.updateOptions('county', countyOptions);
        }
        countyOptions.forEach((countyOption) => {
            if (countyOption?.value === this.county) {
                inputCountyMatch = true;
            }

            this.countyOptionsByValue[countyOption.value] = cloneObject(countyOption);
        });
        // patch values from workflow on init, else only patch when a single option exists
        if (inputCountyMatch) {
            this.countyControl.patchValue(this.county);
        } else if (workflowZip === zipcodeValue && workflowCounty) {
            this.countyControl.patchValue(workflowCounty);
        } else if (countyOptions.length === 1) {
            this.countyControl.patchValue(countyOptions[0].value);
        }

        this.previousZipcodeResponse = cloneObject(zipcodeResponse);
        this.emitCountySelected(this.countyControl.value);
    }

    mapCounty(resArr: Array<any>) {
        return resArr.map((county) => {
            return {
                label: county.county_name,
                value: county.county_code,
                data: county,
            };
        });
    }

    private emitCountySelected(val) {
        const countyLocation = this.getCountyLocationData(val);
        this.countySelected.emit(countyLocation);
        if (countyLocation) {
            this.checkStateNotLicensedError(countyLocation.state_code);
        }
    }

    /**
     * Set options in coverage dropdown
     * Existing options get removed (placeholder stays)
     * Reset ctrl value if no option values are a match
     * @param coverageOptions
     */
    private setCoverageOptions(coverageOptions: Array<{ label; value }> = []) {
        const options = this.coverageOptions;
        const control = this.coverageControl;

        for (let i = options.length - 1; i >= 0; i--) {
            options[i].value && options.splice(i, 1);
        }

        let resetValue = true;
        coverageOptions.forEach((option) => {
            control && option.value === control.value && (resetValue = false);
            options.push(option);
        });

        if (resetValue && control) {
            if (control instanceof FormArray) {
                control.patchValue(coverageOptions.map((option) => null));
            } else {
                control.patchValue(null);
            }
        }

        const coverageConfig = this.coverageFormConfig.coverage_type;

        // when coverage type is a dropdown, update the options with updateOptions
        if (coverageConfig && (coverageConfig.type === controlTypes.dropdown || coverageConfig.type === controlTypes.checkbox)) {
            this.newCoverageTypeOptions.emit(options);

            if (this.coverageTypesFormGroup && coverageConfig.type === controlTypes.dropdown) {
                this.coverageTypesFormGroup.updateOptions('coverage_type', options);
            }
        }

        this.cdr.detectChanges();

        // Track that coverage options have been set
        if (coverageOptions && coverageOptions.length > 0 && !this.coverageOptionsSet) {
            this.coverageOptionsSet = true;

            const previousCoverageType =
                getValue(this.workflowValues, 'demographics.coverage_types') || getValue(this.workflowValues, 'demographics.coverage_type');

            if (previousCoverageType) {
                // coverage type value could be an array or string
                if (control instanceof FormArray) {
                    const prefillFromPreviousCoverageType = coverageOptions.map((option) =>
                        previousCoverageType.includes(option.value) ? option.value : null
                    );

                    // if options don't exist, then add them in before patching
                    if (!control.controls.length) {
                        const arrayControl: FormArray = this.coverageControl as FormArray;
                        coverageOptions.forEach((option) => {
                            arrayControl.push(new FormControl(option));
                        });
                    }
                    this.coverageControl.patchValue(prefillFromPreviousCoverageType);
                } else {
                    if (Array.isArray(previousCoverageType) && coverageConfig.type === 'dropdown') {
                        this.coverageControl.patchValue(previousCoverageType[0]);
                    } else {
                        this.coverageControl.patchValue(previousCoverageType);
                    }
                }
            }
        }
    }

    private buildCoverageForm() {
        Object.keys(this.coverageFormConfig).forEach((key) => {
            this.formControlService.addControlToFormGroup(this.coverageFormGroup, this.coverageFormConfig[key]);
        });
    }

    private retrieveConfig(): void {
        const isBrokerPortal = this.configService.appRoute === 'broker-portal';
        const enrollmentConfig = this.configService.getPageConfig('enrollment');

        this.coverageByState =
            getValue(enrollmentConfig, 'demographics.coverageByState') || getValue(enrollmentConfig, 'eligibility.coverageByState');

        this.stateNotLicensedErrorConfig =
            getValue(enrollmentConfig, 'demographics.stateNotLicensedError') ||
            getValue(enrollmentConfig, 'eligibility.stateNotLicensedError');

        let config;
        if (isBrokerPortal) {
            config = this.getBrokerPortalCoverageFormConfig(enrollmentConfig);
        } else {
            config = this.getShoppingCoverageFormConfig(enrollmentConfig);
        }

        this.coverageFormConfig = formatCoverageFormConfig(config);
        this.coverageFormConfig = this.setDisabledConfig(this.coverageFormConfig);
        if (!!this.coverageFormConfig) {
            this.coverageFormConfig = updateErrorMessage(this.coverageFormConfig);
        }
    }

    private setDisabledConfig(config) {
        const configCopy = cloneObject(config);
        Object.keys(config).forEach((key: string) => {
            configCopy[key].isDisabled = !!this.disabled;
        });
        return configCopy;
    }

    private getShoppingCoverageFormConfig(enrollmentConfig) {
        return getValue(enrollmentConfig, 'demographics.coverage.form') || {};
    }

    private getBrokerPortalCoverageFormConfig(enrollmentConfig) {
        const eligibilitySteps = getValue(enrollmentConfig, 'eligibility.steps');
        const eligibilityStepGetStartedForm = eligibilitySteps && eligibilitySteps.find((step) => step.id === 'getStarted').form;
        return eligibilityStepGetStartedForm || {};
    }

    // Return an error when the broker is not licensed for this state
    private checkStateNotLicensedError(state: string) {
        const enabled: boolean = this.stateNotLicensedErrorConfig && this.stateNotLicensedErrorConfig.enabled;
        const isBrokerPortal = this.configService.appRoute === 'broker-portal';
        const assistingBroker = this.brokerAssistanceService.assistingBroker;
        const userData = this.authService.loggedInUser?.app_user_data;

        if (enabled && isBrokerPortal && userData?.broker_id) {
            this.brokerService.getBroker(userData.broker_id).subscribe((broker: Broker) => {
                const { licenses } = broker;
                if (licenses) {
                    const licensedStates: Array<string> = licenses.map((license) => license.state);
                    this.setStateNotLicensedError(state, licensedStates);
                }
            });
        } else if (enabled && assistingBroker?.licensed_in) {
            this.setStateNotLicensedError(state, assistingBroker.licensed_in);
        }
    }

    private setStateNotLicensedError(state: string, licensedStates: Array<string>) {
        const message: string = this.stateNotLicensedErrorConfig?.message;

        if (!licensedStates.includes(state)) {
            this.stateNotLicensedError = {
                type: BannerTypes.error,
                icon: 'error',
                message,
            };
        } else {
            this.stateNotLicensedError = null;
        }
    }
}

const formatCoverageFormConfig = (inputConfig: any): CoverageFormConfig => {
    let configObjFromArr;

    // Handle array format (broker portal eligibility stepper)
    if (inputConfig && Array.isArray(inputConfig)) {
        configObjFromArr = inputConfig.reduce((acc, config) => ({ ...acc, [config.prop]: config }), {});
    }

    return configObjFromArr || inputConfig;
};
