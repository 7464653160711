import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '@zipari/design-system';

import { DocumentCenterComponent } from './document-center.component';
import { SharedModule } from '../../../../modules/src/lib/shared.module';
import { TableModule } from '../../../../modules/src/lib/table/table.module';

@NgModule({
    declarations: [DocumentCenterComponent],
    imports: [CommonModule, SharedModule, TableModule, TabsModule],
    exports: [DocumentCenterComponent],
})
export class DocumentCenterModule {}
