import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'optional-date',
    templateUrl: './optional-date.component.html',
})
export class OptionalDateComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() config;
    @Input() direction;
    control: AbstractControl;

    ngOnInit(): void {
        this.control = this.group.get(this.config.prop);
    }

    triggerVal() {
        this.control.updateValueAndValidity();
    }
}
