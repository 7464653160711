import { ErrorResponseStructure } from '../add member/workflow-ede--add-member-response.constants';
import { ResponseLinks, ResultType } from '../sharedModels';

export class RemoveMemberResponse__Result {
    insuranceApplicationIdentifier?: number;
    applicationVersionNumber?: number;
    lastModifiedDateTime?: string;
    useCase?: string;
}

export class RemoveMemberResponse {
    resultType: ResultType = null;
    links: ResponseLinks = null;
    result: RemoveMemberResponse__Result = null;
    error: ErrorResponseStructure = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
