import { ButtonConfig } from '@zipari/shared-ds-util-button';

export interface PopUpModalConfig {
    header?: string;
    body: string;
    cancelButton?: ButtonConfig;
    submitButton?: ButtonConfig;

    // depreciated
    cancel?: string;
    // depreciated
    submit?: string;
}

export const DeletePopUpModalConfig: PopUpModalConfig = {
    header: 'Delete Quote',
    body: 'Are you sure you want to permanently delete this quote?',
    cancelButton: {
        level: 'text',
        content: 'Cancel',
    },
    submitButton: {
        level: 'high',
        content: 'Yes, Delete',
    },
};
