import { StoreIDProofingResponse } from './store-id-proofing-response.model';

export interface RIDPFinalWithStoreResponse {
    final: IDProofingQuestionResponse;
    store?: StoreIDProofingResponse;
}

export interface IDProofingQuestionResponse {
    id?: any;
    ResponseMetadata: ResponseMetadata;
    metadata?: any;
    linkMetadata?: any;
    VerificationResponse: VerificationResponse;
    identityProofingIdentifier?: string;
}
interface ResponseMetadata {
    id?: any;
    ResponseCode: ResponseCode;
    TDSResponseDescriptionText?: any;
    ResponseDescriptionText: ResponseCode;
}
interface ResponseCode {
    id?: any;
    linkMetadata?: any;
    _value_1: string;
    metadata?: any;
}
interface VerificationResponse {
    id?: any;
    linkMetadata?: any;
    metadata?: any;
    DSHReferenceNumber: string;
    VerificationQuestions?: any;
    FinalDecisionCode: string;
    SessionIdentification: string;
}
