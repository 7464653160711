import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Message {
    county_code: string;
    external_id: string;
    id: number;
    market_segment: string;
    message: string;
    network_plan_type: string;
    zip_code: string;
}

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    constructor(private http: HttpClient) {}

    /**
     * Get available messages to display during plan selection based on zipcode or zip/county combination
     * @param zip_code
     * @param county_code
     */
    getMessages(zip_code: string = '', county_code: string = '', getLocationBasedMessages) {
        const url =
            `api/shopping/messages/?zip_code=${zip_code}` +
            (getLocationBasedMessages.zidecodeCountyMarketing ? `&county_code=${county_code}` : '');
        return this.http.get(url);
    }
}
