import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggerService } from '@zipari/shared-sbp-services';

export class ActiveLanguageResponse {
    language: string;
    config: any;
}

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    public changeLanguageSubject = new BehaviorSubject('en');
    public activeLanguageSubject = new Subject<ActiveLanguageResponse>();
    private languageStore;
    private changeLanguageSubscription;

    constructor(private loggerService: LoggerService) {
        this.retrieveLanguageStore();
        this.activeLanguage = 'en';
    }

    private _activeLanguage;

    public get activeLanguage() {
        return this._activeLanguage;
    }

    public set activeLanguage(language: string) {
        this._activeLanguage = language;
        this.activeLanguageSubject.next({ language: this.activeLanguage, config: this.activeLanguageConfig });
    }

    public get activeLanguageConfig() {
        const config = this.languageStore[this.activeLanguage];

        if (!config) {
            this.loggerService.warn(`configuration for language ${this.activeLanguage} not found`);
            return {};
        } else {
            return config;
        }
    }

    ngOnInit() {
        this.changeLanguageSubscription = this.changeLanguageSubject.subscribe((language) => {
            this.activeLanguage = this.languageStore[language];
        });
    }

    private retrieveLanguageStore() {
        // todo: we need to retrieve language store once we get everything from backend
        this.languageStore = {
            en: {},
            sp: {},
        };
    }
}
