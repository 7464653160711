<article class="loader__mask" *ngIf="ztService?.zipTableOptions?.showSpinner && ztService?.loading">
    <div class="loader"></div>
</article>

<header class="table__top" *ngIf="!!ztService?.zipTableOptions?.title || ztService.zipTableOptions?.nonQueryFilters.length > 0">
    <div class="table__top__title">
        <h4 class="t-bold">{{ ztService.zipTableOptions.title }}</h4>
    </div>
    <table-filters
        class="table__top__filters"
        [ngClass]="!!ztService?.zipTableOptions?.visuals?.filtersOwnRow ? 'table__top__filters--new-row' : ''"
        [filters]="ztService?.zipTableOptions?.nonQueryFilters"
        (value)="ztService.filterData($event)"
    >
    </table-filters>
    <section class="modal__mask" *ngIf="!!showMobileActionMenu" (click)="showMobileActionMenu = false" @fade></section>

    <section class="table__actions--mobile__menu" *ngIf="!!showMobileActionMenu" @fade>
        <div
            class="form-control__menu__option"
            *ngIf="ztService?.showButton"
            (click)="buttonClicked.emit($event); showMobileActionMenu = false"
        >
            <p class="t-data">{{ ztService?.zipTableOptions?.button?.content }}</p>
        </div>

        <ng-container *ngIf="ztService?.showExport">
            <div
                class="form-control__menu__option"
                *ngIf="
                    ztService?.zipTableOptions?.paging?.count > ztService?.zipTableOptions?.exportConfig?.exportAsyncLimit;
                    else csvDownloadLink
                "
                (click)="ztService.getAsyncExport(); showMobileActionMenu = false"
            >
                <p class="t-data">Export</p>
            </div>

            <ng-template #csvDownloadLink>
                <div class="form-control__menu__option" (click)="ztService.getSynchronousExport(); showMobileActionMenu = false">
                    <p class="t-data">Export CSV</p>
                </div>
            </ng-template>
        </ng-container>
    </section>

    <section class="table__actions--mobile">
        <div class="table__actions--mobile__toggle" (click)="showMobileActionMenu = true">
            <i class="icon icon--medium">more</i>
        </div>
    </section>

    <div class="table__top__actions">
        <div
            class="button__group"
            *ngIf="ztService?.showExport || ztService?.zipTableOptions?.addRow || ztService?.zipTableOptions?.button"
        >
            <zip-button
                *ngIf="ztService?.showButton"
                (zipButtonClicked)="buttonClicked.emit($event)"
                [disabled]="ztService.loading"
                [content]="ztService?.zipTableOptions?.button?.content"
                [level]="ztService?.zipTableOptions?.buton?.level || 'high'"
                [icon]="ztService?.zipTableOptions?.button?.icon"
            >
            </zip-button>

            <ng-container *ngIf="ztService?.showExport">
                <ng-container
                    *ngIf="
                        ztService?.zipTableOptions?.paging?.count > ztService?.zipTableOptions?.exportConfig?.exportAsyncLimit;
                        else csvDownloadLink
                    "
                >
                    <zip-button
                        [disabled]="ztService.loading"
                        (zipButtonClicked)="ztService.getAsyncExport()"
                        [content]="ztService?.zipTableOptions?.exportConfig.text || null"
                        level="medium"
                        [icon]="ztService?.zipTableOptions?.exportConfig?.icon"
                    >
                    </zip-button>
                </ng-container>

                <ng-template #csvDownloadLink>
                    <zip-button
                        [disabled]="ztService.loading"
                        (zipButtonClicked)="ztService.getSynchronousExport()"
                        [content]="ztService?.zipTableOptions?.exportConfig.text || null"
                        level="medium"
                        iconSize="medium"
                        [icon]="ztService?.zipTableOptions?.exportConfig?.icon"
                    >
                    </zip-button>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!!ztService?.zipTableOptions?.export">
                <zip-button
                    (zipButtonClicked)="ztService.getSynchronousExport()"
                    [content]="ztService?.zipTableOptions?.export.content || null"
                    [level]="ztService?.zipTableOptions?.export?.level"
                    [icon]="ztService?.zipTableOptions?.export?.icon"
                >
                </zip-button>
            </ng-container>
        </div>
    </div>
</header>

<div #tableWrapper class="table u-relative" [attr.aria-role]="'table'" [attr.aria-label]="'Table'" tabindex="0">
    <ng-container *ngIf="ztService.columns">
        <zip-table-header-row-2
            (deleteClicked)="deleteClicked.emit(ztService.selectedRow)"
            (editClicked)="editClicked.emit(ztService.selectedRow)"
            class="table__header__wrapper"
            [ngClass]="{ 'table__header__wrapper--dark': ztService?.zipTableOptions?.visuals?.theme === 'dark' }"
            [weHaveData]="ztService.weHaveData"
            [detailView]="ztService.detailEnabled && ztService.detailColumns.length > 0"
            [columns]="ztService.columns"
            [options]="ztService?.zipTableOptions"
            (onSortEvent)="ztService.sortData($event.column, $event.sortOrder)"
            [grouping]="ztService.groupingEnabled"
            [multiExpandedAllowed]="ztService.detailEnabled && ztService?.zipTableOptions?.detail?.multi"
            [allExpanded]="ztService.allRowsExpanded"
            (expandAllRows)="ztService.handleExpandingAllRows()"
            [attr.aria-label]="'Column headers'"
            tabindex="0"
        >
        </zip-table-header-row-2>
    </ng-container>

    <div class="table__body" #tableBody>
        <ng-container *ngIf="ztService.weHaveData; else nodata">
            <ng-container *ngIf="ztService.groupingEnabled">
                <ng-container *ngFor="let group of ztService.groupedData; let ind = index">
                    <zip-table-group-header-row-2
                        [attr.title]="ztService?.zipTableOptions?.messages?.tooltip"
                        class="table__row__wrapper table__row__wrapper--group"
                        [ngClass]="{
                            tooltip: ztService?.zipTableOptions?.messages?.tooltip,
                            'table__row__wrapper--hover': ztService.zipTableOptions?.selection?.enabled,
                            'table__row__wrapper--nested': ztService.groupingEnabled,
                            'is-expanded': ztService.expandedGroups[group.display]
                        }"
                        [expanded]="ztService.expandedGroups[group.display]"
                        [columns]="ztService.columns"
                        (groupClicked)="ztService.toggleExpandingGrouping(group)"
                        [grouping]="ztService.groupingEnabled"
                        [detailView]="ztService.detailEnabled && ztService.detailColumns.length > 0"
                        [options]="ztService?.zipTableOptions"
                        [data]="group"
                    >
                    </zip-table-group-header-row-2>

                    <ng-container *ngIf="ztService.expandedGroups[group.display]">
                        <ng-container
                            *ngTemplateOutlet="
                                normalRowSetup;
                                context: {
                                    currentData: group.rows,
                                    ztService: ztService
                                }
                            "
                        >
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!ztService.groupingEnabled">
                <ng-container
                    *ngTemplateOutlet="
                        normalRowSetup;
                        context: {
                            currentData: ztService?.currentData,
                            ztService: ztService
                        }
                    "
                >
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <ng-template #nodata>
        <div class="table__row table__row-nodata">
            <div class="u-flex u-alignCenter u-justifyCenter u-maxX">
                <div class="table__cell">
                    <p *ngIf="!ztService.loading" class="t-data">{{ ztService?.zipTableOptions?.messages?.noData }}</p>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #normalRowSetup let-ztService="ztService" let-currentData="currentData">
    <ng-container *ngFor="let row of currentData; let ind = index">
        <zip-table-generic-row-2
            (rowClicked)="clickEvent(row, ind)"
            (cellClicked)="cellClicked.emit($event)"
            [detailView]="ztService.detailEnabled && ztService.detailColumns.length > 0"
            class="table__row__wrapper"
            [attr.title]="ztService?.zipTableOptions?.visuals?.tooltip"
            [ngClass]="{
                tooltip: ztService?.zipTableOptions?.visuals?.tooltip,
                'table__row__wrapper--hover': ztService.zipTableOptions?.selection?.enabled,
                'table__row__wrapper--nested': ztService.groupingEnabled,
                'is-expanded': ztService.detailEnabled && row.expanded && ztService.detailColumns.length > 0
            }"
            (expandRow)="ztService.expandRow(ind)"
            [columns]="ztService.columns"
            [grouping]="ztService.groupingEnabled"
            [data]="row"
            [options]="ztService?.zipTableOptions"
            [attr.aria-label]="'Next row'"
            tabindex="0"
        >
        </zip-table-generic-row-2>

        <ng-container *ngIf="ztService.detailEnabled && row.expanded && ztService.detailColumns.length > 0">
            <ng-container
                *ngTemplateOutlet="
                    ztService.zipTableOptions.detail.template || defaultDetailTemplate;
                    context: {
                        detailColumns: ztService.detailColumns,
                        row: row
                    }
                "
            >
            </ng-container>

            <ng-template #defaultDetailTemplate let-row="row">
                <div class="table__row__details" @accordion>
                    <ng-container *ngFor="let extraColumn of ztService.detailColumns">
                        <div class="table__row__detail" [ngClass]="{ tooltip: extraColumn.tooltip }" [attr.title]="extraColumn.tooltip">
                            <div class="table__row__detail__label">
                                <p class="t-caption t-bold">{{ extraColumn.name }}</p>
                            </div>

                            <div class="table__row__detail__value">
                                <p class="t-data">{{ ztService.getValueIncludingFormat(row, extraColumn) }}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<zip-table-footer-2
    (page)="ztService.setPage($event)"
    (pageSelected)="setCurrentPage($event)"
    [currentPage]="currentPage"
    [length]="ztService.getCurrentPageCount"
    [options]="ztService?.zipTableOptions"
>
</zip-table-footer-2>
