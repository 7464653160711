import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStepLayoutComponent } from './auth-step-layout.component';

@NgModule({
    declarations: [AuthStepLayoutComponent],
    imports: [CommonModule],
    exports: [AuthStepLayoutComponent],
})
export class AuthStepLayoutModule {}
