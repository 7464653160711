import { Component, Input, OnInit } from '@angular/core';
import { FormControlService } from '@zipari/design-system';
import { cityStateZipConfig, streetAddressConfig } from './basic-address.constants';

@Component({
    selector: 'basic-address',
    templateUrl: './basic-address.component.html',
    styleUrls: ['./basic-address.component.scss'],
})
export class BasicAddressComponent implements OnInit {
    @Input() group;
    @Input() config;
    @Input() direction;

    addressLine1 = streetAddressConfig;
    addressLine2 = cityStateZipConfig;

    constructor(public formControlService: FormControlService) {}

    ngOnInit() {
        this.formControlService.addFormGroupToFormGroup(this.group, this.config);

        streetAddressConfig.forEach((config) => {
            this.formControlService.addControlToFormGroup(this.group.controls[this.config.prop], config);
        });

        cityStateZipConfig.forEach((config) => {
            this.formControlService.addControlToFormGroup(this.group.controls[this.config.prop], config);
        });
    }
}
