import Contact from './Contact.model';
import EnrollmentBroker from './EnrollmentBroker.model';
import Group from './Group.model';
import ProductCoverage from './ProductCoverage.model';

export default class Policy {
    readonly id?: number;
    groups?: Array<Group>;
    contacts?: Array<Contact>;
    product_coverages: Array<ProductCoverage>;
    readonly agents?: Array<EnrollmentBroker>;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    policy_number: number;
    policy_name?: string;
    policy_premium?: number;
    policy_status?: string;
    policy_status_reason?: string;
    crm_id?: string;
}
