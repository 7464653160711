import { Column2 } from './column.model';

export class Cell2 {}

export interface CustomTemplateInterface2 {
    value?: any;
    column?: Column2;
    cellValue?: any;
    rowValue?: any;
    /**
     * Function to help detect changes made. Lifecycles hooks do not apply to dynamically created components
     * Will be ignored if not added to the component
     * For more information have a look at this issue talking about it.
     * https://github.com/angular/angular/issues/8903
     */
    detectChanges?: Function;
}
