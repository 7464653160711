<div class="coverage_form__form" [ngClass]="{ coverage_form_responsive: responsive }" [zipBusy]="busy">
    <ng-container *ngIf="coverageFormConfig && coverageFormGroup">
        <form-group class="coverage_form__form__control" [configs]="[coverageFormConfig['zipcode']]" [form]="coverageFormGroup">
        </form-group>

        <form-group
            #countyFormGroup
            class="coverage_form__form__control"
            [configs]="[coverageFormConfig['county']]"
            [form]="coverageFormGroup"
        >
        </form-group>

        <form-group
            #coverageTypesFormGroup
            class="coverage_form__form__control"
            *ngIf="!hideCoverageTypes"
            [configs]="[coverageFormConfig['coverage_types'] || coverageFormConfig['coverage_type']]"
            [form]="coverageFormGroup"
        >
        </form-group
    ></ng-container>
</div>

<message-banner *ngIf="stateNotLicensedError" [config]="stateNotLicensedError"> </message-banner>
