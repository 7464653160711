import { Component, Input } from '@angular/core';
import { PlanCardBadge } from './plan-card-badge.constants';

@Component({
    selector: 'plan-card-badge',
    templateUrl: './plan-card-badge.component.html',
    styleUrls: ['./plan-card-badge.component.scss'],
})
export class PlanCardBadgeComponent {
    @Input() badge: PlanCardBadge;
}
