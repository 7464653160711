import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, ModelAttributeModule, FormControlModule } from '@zipari/design-system';
import { AddOrEditModalComponent } from './add-or-edit-modal.component';
import { ExistingClientListModalModule } from '../existing-client-list-modal/existing-client-list-modal.module';
import { FormGroupModule } from '../form-group/form-group.module';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [AddOrEditModalComponent],
    imports: [
        CommonModule,
        ButtonModule,
        FormGroupModule,
        FormControlModule,
        SharedModule,
        ModelAttributeModule,
        ExistingClientListModalModule,
    ],
    exports: [AddOrEditModalComponent],
})
export class AddNewModalModule {}
