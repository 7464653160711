import { MetalLevelType, TrueFalseString } from '../../../helpers/ede-ses/ede-ses.service.constants';

export interface FetchEligibilityContactInformationContactEmailId {
    ContactEmailID?: string;
    ContactMainTelephoneNumber?: string;
}

export interface FetchEligibilityContactInformationMailingAddress {
    ContactMailingAddress?: {
        LocationStreet?: string;
        LocationStateUSPostalServiceCode?: string;
        LocationCountyCode?: string;
        LocationPostalCode?: string;
        LocationCountyName?: string;
        LocationCityName?: string;
    };
}

export class FetchEligibilityInsuranceApplicant {
    CurrentMetalLevelType?: any;
    InsuranceApplicantFinancialAssistanceIndicator?: TrueFalseString;
    InitialEnrollmentPeriodEligibility?: {
        EarliestQHPEffectiveDate?: string;
        EligibilityDateRange?: { EndDate?: string; StartDate?: string };
        EligibilityIndicator?: TrueFalseString;
    };
    SpecialEnrollmentPeriodEligibility?: {
        EarliestQHPEffectiveDate?: string;
        EligibilityDateRange?: { EndDate?: string; StartDate?: string };
        LatestQHPEffectiveDate?: string;
        SpecialEnrollmentVerificationPendingIndicator?: boolean;
        SpecialEnrollmentPeriodEligibilityReasonCode?: string;
        EligibilityIndicator?: boolean;
    };
    PlanMetalLevelOption?: {
        CurrentPlanOnlyIndicator?: TrueFalseString;
        AllowedPlanMetalLevelType?: Array<MetalLevelType>;
    };
    ProgramEligibilitySnapshot?: {
        CSREligibility?: {
            InsurancePlanVariantCategoryNumericCode?: '06';
            ProgramEligibilityStatusCode?: 'Gain';
            EligibilityDateRange?: '2020-04-01';
            EligibilityIndicator?: TrueFalseString;
        };
        ExchangeEligibility?: {
            ProgramEligibilityStatusCode?: string;
            EligibilityDateRange?: string;
            EligibilityIndicator?: TrueFalseString;
        };
        MedicaidEligibility?: TrueFalseString;
        ActivityDate?: string;
        CHIPEligibility?: TrueFalseString;
        APTCEligibility?: {
            ProgramEligibilityStatusCode?: string;
            MonthlyAPTCAmount?: string;
            EligibilityDateRange?: string;
            EligibilityIndicator?: TrueFalseString;
        };
    };
    InsuranceApplicantStudentIndicator?: TrueFalseString;
    ExchangeAssignedInsuranceApplicantIdentification?: {
        IdentificationSourceText?: string;
        IdentificationID?: string;
    };
    DependentSEPOrNewMemberIndicator?: TrueFalseString;
    RoleOfPersonReference?: { __attrs__?: { ref?: string } };
    __attrs__?: { id?: string };
}

export class AddressContact {
    AddressTypeCode?: string;
    ContactInformationCategoryCode?: string;
    ContactInformationIsHomeIndicator?: TrueFalseString;
    __attrs__?: { type?: string };
    ContactInformationReference?: { __attrs__?: { ref?: string } };
}

export class FetchEligibilityPerson {
    PersonName?: { PersonSurName: string; PersonGivenName?: string; PersonMiddleName?: string; PersonNameSuffixText?: string };
    PersonMarriedIndicator?: TrueFalseString;
    PersonSexCode?: 'F' | 'M';
    PersonSSNIdentification?: { IdentificationSourceText?: null; IdentificationID?: string };
    PersonAugmentation?: {
        PersonContactInformationAssociation?: Array<AddressContact>;
        PersonAssociation?: Array<{ PersonReference?: { __attrs__?: { ref?: string } }; FamilyRelationshipCode?: string }>;
        PersonPreferredLanguage?: Array<{
            PersonSpeaksLanguageIndicator?: TrueFalseString;
            PersonWritesLanguageIndicator?: TrueFalseString;
            LanguageName?: string;
        }>;
    };
    __attrs__?: { id?: string };
    PersonBirthDate?: string;
}

export class FetchEligibilityInsuranceMember {
    __attrs__: { id: string };
    TobaccoUseIndicator?: boolean;
    TobaccoLastUsageDate?: string;
    OriginatingInsuranceApplicantReference?: {
        __attrs__: { ref: string };
    };
    InsuranceMemberSubscriberAssociation?: {
        FamilyRelationshipHIPAACode: string;
        InsuranceSubscriberReference: {
            __attrs__: { ref: string };
        };
    };
}

export class FetchEligibilityResponse {
    Person?: Array<FetchEligibilityPerson>;
    SSFPrimaryContact?: {
        RoleOfPersonReference?: {
            __attrs__?: { ref?: string };
        };
        ContactEmailID?: string;
        __attrs__?: {
            id?: string;
        };
        ContactMobileTelephoneNumber?: string;
    };
    PrimaryTaxFilerPersonReference?: {
        __attrs__?: { ref?: string };
    };
    InsurancePolicy?: {
        InsuranceMember?: Array<FetchEligibilityInsuranceMember>;
    };
    InsuranceApplication?: {
        InsuranceApplicationMultipleTaxHouseholdIndicator?: boolean;
        InsuranceApplicant?: Array<FetchEligibilityInsuranceApplicant>;
    };
    ContactInformation?: Array<FetchEligibilityContactInformationMailingAddress | FetchEligibilityContactInformationContactEmailId>;
}
