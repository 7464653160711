import Broker from './Broker.model';
import Quote from './Quote.model';
import Address from './Address.model';

export default class Group {
    readonly id?: number;
    readonly brokers?: Array<Broker>;
    readonly broker_ids?: Array<string>;
    readonly agency_ids?: Array<string>;
    readonly quote_set?: Array<Quote>;
    readonly status?: string;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    group_number?: number;
    group_ein?: number;
    enrollment_status?: string;
    company_name?: string;
    company_alias?: string;
    company_type?: string;
    industry?: any;
    termination_date?: string;
    termination_reason?: string;
    number_of_employees?: number;
    number_of_employees_enrolling?: number;
    renewal_date?: string;
    tax_id?: number;
    sic_code?: number;
    premium?: number;
    employer_contribution?: number;
    employer_minimum_contribution?: number;
    employer_minimum_participation?: number;
    employee_insured_parties?: string;
    new_hire_waiting_period?: number;
    covers_domestic_partners?: boolean;
    covers_domestic_partners_children?: boolean;
    employer_is_cobra?: boolean;
    current_carrier_name?: string;
    current_carrier_effective_date?: string;
    current_carrier_termination_date?: string;
    renewal_document_id?: number;
    is_active?: boolean;
    parent?: number;
    users?: Array<number>;
    employees?: Array<number>;
    plans?: Array<number>;
    contacts?: Array<number>;
    addresses?: Array<Address>;
}
