export class DMIPersonInformationMatchingIssue {
    eventIdentifier?: string;
    eventDateTime?: string;
    verificationType?: string;
    dmiStatus?: string;
    dmiSubType?: string;
    dmiStatusReasonCode?: Array<string>;
    dmiTimerStartDate?: string;
    dmiTimerEndDate?: string;
    dmiTimerStatus?: string;
    dmiTimerStatusReason?: string;
    consolidatedDmiStatus?: string;
    nonEscMecType?: Array<string>;

    // todo: type this out
    documents?: Array<any>;

    /** intenal use */
    status?: any;
    uploadedDocs?: any;
    statusConfig?: any;
    name?: string;
    personTrackingNumber?: number;
    /** intenal use */
}

export class DMIPersonInformation {
    personTrackingNumber?: string;
    firstName?: string;
    lastName?: string;
    ssn?: string;
    birthDate?: string;
    dataMatchingIssue?: Array<DMIPersonInformationMatchingIssue>;
}

export class DMIApplicantInformation {
    insuranceApplicationIdentifier?: number;
    applicationVersionNumber?: number;
    applicationVersionDateTime?: string;
    applicationSubmissionDateTime?: string;
    stateCode?: string;
    coverageYear?: number;
    hasAdditionalPersonsTiedToApplication?: boolean;
    isSoftDeleted?: boolean;
    isCurrentVersion?: boolean;
    personInformation?: Array<DMIPersonInformation>;
}

export class ESWDMIStatusPersonalInformationDispositionInfo {
    taskFromGroup?: string;
    taskToGroup?: string;
    dmiReviewDateTime?: string;
    dmiReason?: string;
    dmiDisposition?: string;
}

export class ESWDmiStatusPersonInformationDataMatchingIssue {
    eventIdentifier?: string;
    applicationVersionNumber?: number;
    dmiType?: string;
    dmiSubType?: string;
    calculatedClockEndDate?: string;
    eswDmiStatus?: string;
    dmiDispositionInfo?: Array<ESWDMIStatusPersonalInformationDispositionInfo>;
}

export class ESWDmiStatusPersonInformation {
    personTrackingNumber?: string;
    dataMatchingIssue?: Array<ESWDmiStatusPersonInformationDataMatchingIssue>;
}

export class ESWApplicationInfo {
    insuranceApplicationIdentifier?: number;
    personInformation: Array<ESWDmiStatusPersonInformation>;
}

export class ESWDmiStatus {
    applicationInformation?: ESWApplicationInfo;
}

export class DMIffmDmiStatus {
    applicationInformation?: DMIApplicantInformation;
}

export class DataMatchingIssueModel {
    dmiStatus?: {
        partialDataIndicator?: boolean;
        ffmDmiStatusResponse?: {
            ffmDmiStatus?: Array<DMIffmDmiStatus>;
            result?: string;
        };
        eswDmiStatusResponse?: {
            eswDmiStatus?: Array<ESWDmiStatus>;
            result?: string;
            errorTrackingId?: string;
        };
    };
}

export class DMIffmAndEsw {
    esw: Array<ESWApplicationInfo>;
    ffm: Array<DMIApplicantInformation>;
}

export class PersonTrackingNumberDMIMap {
    [personTrackingNumber: string]: Array<DMIPersonInformationMatchingIssue>;
}
