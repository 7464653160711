import { PlanAddOn } from './Plan.model';
import PlanDocumentNested from './PlanDocumentNested.model';
import PlanType from './PlanType.model';
import Rate from './Rate.model';
import ServiceArea from './ServiceArea.model';

export default class PlanUpsert {
    readonly id?: number;
    add_ons: Array<PlanAddOn>;
    benefits?: string;
    display_name?: string;
    external_id: string;
    hsa?: boolean;
    is_active?: boolean;
    is_benchmark?: boolean;
    metal_tier?: string;
    plan_type: PlanType;
    plan_variation?: string;
    static_price?: number;
    waiting_period?: number;
    data?: string;
    max_out_of_pocket?: number;
    year: number;
    deductible?: number;
    price?: string;
    documents?: Array<PlanDocumentNested>;
    zipcodes?: string;
    rates?: Array<Rate>;
    service_areas?: Array<ServiceArea>;
}
