import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const CRM_SEND_OPPORTUNITY_ENDPOINT = '/api/crm/opportunity/';

export interface CRMSendOpportunity {
    workflow_id: string;
}

@Injectable({
    providedIn: 'root',
})
export class CRMOpportunityService {
    constructor(private http: HttpClient) {}

    /**
     * Signal backend to send opportunity to CRM based on what is in the workflow values
     * https://gitlab.zipari.net/api-specifications/product-api-specs/-/blob/master/apis/crm/opportunity/swagger.yaml
     */
    crmSendOpportunity(workflowId: string | number) {
        const payload: CRMSendOpportunity = {
            workflow_id: workflowId.toString(),
        };
        return this.http.post(CRM_SEND_OPPORTUNITY_ENDPOINT, payload);
    }
}
