import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SearchService } from '../../search.service';
import { ProviderResult } from '../provider-result.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'provider-profile',
    templateUrl: './provider-profile.component.html',
    styleUrls: ['./provider-profile.component.scss'],
})
export class ProviderProfileComponent {
    @Input() resultIsSelected: boolean;
    @Input() selectedResult: ProviderResult = null;
    @Input() selectedResultIdx: number;
    @Input() backendResults: ProviderResult[] = [];
    @Input() contactConfigs = [];
    @Input() profileConfigs = [];
    @Input() educationAndTrainingConfigs = [];
    @Input() showDetails = false;
    @Input() pins = [];
    @Input() busy: Subscription = null;
    @Input() removePCPavatar: boolean;
    @Output() viewAllResultsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() previousNextChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() addProviderChange: EventEmitter<ProviderResult> = new EventEmitter<ProviderResult>();
    @Output() removeProviderChange: EventEmitter<ProviderResult> = new EventEmitter<ProviderResult>();
    @Output() pinSelect: EventEmitter<any> = new EventEmitter<any>();
    zoom = 15;
    centerLat: number = 40.703039;
    centerLng: number = -73.990457;
    constructor(private searchService: SearchService) {}

    public get selectedResultDirectionsUrl(): string {
        return this.searchService.getDirections(
            [
                this.selectedResult.location.street_name_1,
                this.selectedResult.location.city_name,
                this.selectedResult.location.state,
                this.selectedResult.location.zip_code,
            ].join(' ')
        );
    }

    get contactContext() {
        const contactCtxt = {
            phoneNumber: this.selectedResult ? this.selectedResult.phone : '',
            location: this.selectedResult
                ? this.selectedResult.location.street_name_1 +
                  '\n' +
                  this.selectedResult.location.city_name +
                  ', ' +
                  this.selectedResult.location.state +
                  ' ' +
                  this.selectedResult.location.zip_code
                : '',
            getDirections: 'Get Directions',
        };
        return contactCtxt;
    }

    get profileContext() {
        const languages: string[] =
            this.selectedResult && this.selectedResult.languages ? this.selectedResult.languages.map((language) => language.name) : [];
        const groupAffiliations: string[] =
            this.selectedResult && this.selectedResult.medical_groups ? this.selectedResult.medical_groups.map((group) => group.name) : [];
        const hospitalAffiliations: string[] =
            this.selectedResult && this.selectedResult.hospital_affiliations
                ? this.selectedResult.hospital_affiliations.map((aff) => aff.name)
                : [];
        const profileCtxt = {
            specialties: this.selectedResult ? this.selectedResult.primary_specialty : '',
            languagesSpoken: languages.join('\n'),
            groupAffiliation: groupAffiliations.join('\n'),
            hospitalAffiliation: hospitalAffiliations.join('\n'),
        };
        return profileCtxt;
    }

    get educationAndTrainingContext() {
        const education =
            this.selectedResult && this.selectedResult.degree
                ? this.selectedResult.degree.school_name + '\n' + this.selectedResult.degree.degree_name
                : '';
        const eduAndTrainCtxt = {
            education: education,
            residency: this.selectedResult && this.selectedResult.residency ? this.selectedResult.residency : '',
            internship: this.selectedResult && this.selectedResult.internship ? this.selectedResult.internship : '',
            boardCertified: this.selectedResult && this.selectedResult.boardCertification ? this.selectedResult.boardCertification : '',
        };
        return eduAndTrainCtxt;
    }

    shouldShowModelAttribute(modelProp: string) {
        const modelsToShow = {
            specialties: true,
            languagesSpoken: true,
            groupAffiliation:
                this.selectedResult && this.selectedResult.medical_groups && this.selectedResult.medical_groups.length ? true : false,
            hospitalAffiliation:
                this.selectedResult && this.selectedResult.hospital_affiliations && this.selectedResult.hospital_affiliations.length
                    ? true
                    : false,
            education: !!(this.selectedResult && this.selectedResult.degree),
            residency: !!(this.selectedResult && this.selectedResult.residency),
            internship: !!(this.selectedResult && this.selectedResult.internship),
            boardCertified: !!(this.selectedResult && this.selectedResult.boardCertification),
        };
        return modelsToShow[modelProp];
    }

    onDirectionsLinkClick(e) {
        window.open(this.selectedResultDirectionsUrl, '_blank');
    }

    onViewAllResultsClick() {
        this.viewAllResultsChange.emit(true);
    }

    onPreviousNextClick(index: number) {
        this.previousNextChange.emit(index);
    }

    onAddProviderClick() {
        this.addProviderChange.emit(this.selectedResult);
    }

    onRemoveProviderClick() {
        this.removeProviderChange.emit(this.selectedResult);
    }

    onPinSelect(pin: any) {
        this.pinSelect.emit(pin);
    }
}
