import Plan from './Plan.model';

export default class Quote {
    data: {};
    created_at: string;
    user_id: number;
    prospect_id: number;
    url: string;
    status: string;
}

export interface QuoteDetailPayload {
    client_id?: number;
    old_workflow_id?: string;
    plans?: {
        dental?: Plan;
        medical?: Plan;
        vision?: Plan;
    };
    quote_id?: string;
    type?: string;
}
