<div class="market-selection" [ngClass]="{ 'full-screen': isFullScreen }">
    <div class="container">
        <h4>{{ config?.header?.title }}</h4>
        <p>{{ config?.header?.subtitle }}</p>

        <section class="market-selection--cards u-flex u-justifyCenter">
            <div class="card" *ngFor="let card of config?.cards" (click)="onClick(card)">
                <i class="icon icon--large icon--link">{{ card.icon }}</i>
                <h6>{{ card.label }}</h6>
            </div>
        </section>
    </div>
</div>
