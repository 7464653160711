<section
    class="notification"
    [ngClass]="{
        'is-open': !!notification.open,
        'is-read': !!notification.is_read
    }"
    *ngFor="let notification of notifications | slice : notificationStart : notificationStart + 5"
>
    <div class="notification__datetime">
        <p class="t-data t-bold">{{ notification.start_date | date : 'mediumDate' }}</p>
        <p class="t-caption t-caps t-alt">{{ notification.start_date | date : 'shortTime' }}</p>
    </div>

    <div class="notification__icon" *ngIf="notification.notification_subtype">
        <i class="icon icon--medium">{{
            icon[notification.notification_subtype] ? icon[notification.notification_subtype] : notification.notification_subtype
        }}</i>
    </div>

    <div class="notification__timeline"></div>

    <div class="notification__content">
        <div class="notification__pointer"></div>

        <ng-container *ngIf="notification.url; else notificationContent">
            <a (click)="linkClicked(notification.url)">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </a>
        </ng-container>

        <ng-template #notificationContent>
            <header class="notification__content__header icon--pointer" (click)="toggle(notification)">
                <p class="t-data t-bold">{{ notification.title }}</p>
                <i class="icon icon--medium icon--pointer" [ngClass]="{ 'icon--rotate180': notification.open }"> chevron_down </i>
            </header>
        </ng-template>

        <section class="notification__content__body" *ngIf="!!notification.open" @accordion>
            <p class="t-data" [innerHtml]="notification.content"></p>
        </section>
    </div>
</section>
<footer class="table__footer">
    <section class="table__footer__count">
        <p class="t-data t-alt">
            Showing {{ notificationStart + 1 }} -
            {{ notificationStart + 5 >= notifications?.length ? notifications?.length : notificationStart + 5 }} of
            {{ notifications?.length }}
        </p>
    </section>
    <section class="table__pagination">
        <i
            *ngIf="notificationStart > notifications?.length - 5"
            class="icon icon--medium icon--pointer"
            (click)="notificationStart !== 0 && (notificationStart = notificationStart - 5)"
        >
            chevron_left
        </i>
        <i
            *ngIf="notificationStart < notifications?.length - 5"
            class="icon icon--medium icon--pointer"
            (click)="notificationStart !== notifications?.length - 5 && (notificationStart = notificationStart + 5)"
        >
            chevron_right
        </i>
    </section>
</footer>
