<div class="grid--container" [ngClass]="columns">
    <ng-container *ngIf="columns !== 'col-12'; else allContent">
        <div class="grid--section-left">
            <ng-content select="[left]"></ng-content>
        </div>
        <div class="grid--section-right">
            <ng-content select="[right]"></ng-content>
        </div>
    </ng-container>

    <ng-template #allContent>
        <ng-content></ng-content>
    </ng-template>
</div>
