import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormElementsService {
    constructor() {}

    checkRecursivelyForDeepMerge(previous, current) {
        if (!previous || !current) {
            return;
        }

        // loop through the previous object and make sure that any of the keys that should be there dont get removed
        for (const innerKey in previous.controls) {
            if (previous.controls.hasOwnProperty(innerKey)) {
                if (!current.controls[innerKey]) {
                    current.addControl(innerKey, previous.controls[innerKey]);
                } else if (previous instanceof FormGroup && current instanceof FormGroup) {
                    this.checkRecursivelyForDeepMerge(previous.controls[innerKey], current.controls[innerKey]);
                }
            }
        }
    }

    addOrUpdateFormEl(formGroup: FormGroup, key: string, formElementToSet: FormArray | FormControl | FormGroup) {
        // if the key exists... make sure to remove the current control
        // if it is a form group that is trying to be added then make sure to do a safe merge of the group
        if (formGroup.controls[key]) {
            if (formGroup.controls[key] instanceof FormGroup && formElementToSet instanceof FormGroup) {
                // try to make sure that we don't set over anything
                this.checkRecursivelyForDeepMerge(formGroup.controls[key], formElementToSet);
            }

            formGroup.removeControl(key);
        }

        formGroup.addControl(key, formElementToSet);
    }
}
