import {
    Computed__Members,
    Computed__TaxHouseholds,
    CreatingSystemId,
    CreatingSystemRole,
    InsuranceApplicationType,
    ResponseLinks,
    ResultType,
    UseCaseTypes,
} from '../sharedModels';

export class UpdateApplicationResponse__Result__Computed {
    members: Computed__Members = new Computed__Members();
    taxHouseholds: Computed__TaxHouseholds = new Computed__TaxHouseholds();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse__Result {
    insuranceApplicationIdentifier: number = null;
    applicationVersionNumber: number = null;
    versionDateTime: string = null;
    applicationCreationDateTime: string = null;
    creatingSystemId: CreatingSystemId = null;
    creatingSystemRole: CreatingSystemRole = null;
    creatingUserId: string = null;
    useCase: UseCaseTypes = null;
    deletedIndicator: boolean = null;
    currentVersionIndicator: boolean = null;
    comments: string = null;
    lastModifiedDateTime: string = null;
    lastModifiedUserId: string = null;
    insuranceApplicationType: InsuranceApplicationType = null;
    computed: UpdateApplicationResponse__Result__Computed = new UpdateApplicationResponse__Result__Computed();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse {
    resultType: ResultType = null;
    links: ResponseLinks = null;
    result: UpdateApplicationResponse__Result = new UpdateApplicationResponse__Result();

    constructor(options = null) {
        Object.assign(this, options);
    }
}
