import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    DependentEnum,
    DependentButtonConfig,
    DependentDetail,
    addSpouseButton,
    addChildButton,
    addParentButton,
    closeDependentCard,
} from './whose-covered-form.constants';
import { cloneObject } from '@zipari/web-utils';

@Component({
    selector: 'whose-covered-form',
    templateUrl: './whose-covered-form.component.html',
    styleUrls: ['./whose-covered-form.component.scss'],
})
export class WhoseCoveredFormComponent implements OnInit, OnChanges {
    @Input() whoseCoveredGroup: FormGroup;
    @Input() config;
    @Input() subSpouseConfig;
    @Input() isOverSixtyFive: boolean;
    @Input() maxAgeLimit: number;
    @Input() showSubscriber: boolean;
    @Input() showSpouse: boolean;
    @Input() dependentButtonConfig: DependentButtonConfig;
    @Input() childOnly;
    @Input() dependentVal;
    @Input() showChildOnly: boolean;
    @Input() showChild: boolean;
    @Input() showParent: boolean;
    @Input() hasParentDependents: boolean;
    @Input() dependentConfig;
    @Input() childOnlyConfig;
    @Input() depLimit: number;
    @Input() isSubscriberOnly: boolean;
    @Input() whoseCoveredValue;
    addSpouseButton = cloneObject(addSpouseButton);
    addChildButton = cloneObject(addChildButton);
    addParentButton = cloneObject(addParentButton);
    closeDependentCard = cloneObject(closeDependentCard);
    addDependentValue: string;
    addSpouse: boolean;
    addChild: boolean;
    addParent: boolean;

    @Output() addDependent: EventEmitter<DependentDetail> = new EventEmitter();
    @Output() removeDependent: EventEmitter<DependentDetail> = new EventEmitter();

    ngOnInit() {
        if (this.showChildOnly) this.addDependentValue = DependentEnum.chilOnlyDependent;
        if (this.showChild) this.addDependentValue = DependentEnum.childDependent;
        if (this.showParent) this.addDependentValue = DependentEnum.parentDependent;
        this.addSpouseButton = this.config?.addSpouseButton || addSpouseButton;
        this.addParentButton = this.config?.addParentButton || addParentButton;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('whoseCoveredValue' in changes) {
            this.getWhoseCovered();
        }
    }

    buttonClicked(whoseCoveredValue, shouldAddDependent: boolean, dependentValue?: string) {
        if (dependentValue) this.addDependentValue = dependentValue;
        this.addDependent.emit({
            whoseCoveredValue: whoseCoveredValue,
            shouldAddDependent: shouldAddDependent,
            addDependentValue: this.addDependentValue,
        });
    }

    removeButtonClicked(index: number, dependentValue?: string) {
        if (dependentValue) this.addDependentValue = dependentValue;
        this.removeDependent.emit({ index: index, addDependentValue: this.addDependentValue });
    }

    getWhoseCovered() {
        this.addSpouse = this.whoseCoveredValue.hasOwnProperty('spouse');
        this.addChild = this.whoseCoveredValue.hasOwnProperty('dependents');
        this.addParent = this.whoseCoveredValue.hasOwnProperty('stepParents');
    }
}
