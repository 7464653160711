import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CardModule, FormattingService, FormGroupModule, ModelAttributeModule, ZipBusyModule } from '@zipari/design-system';

import { ReimbursementCardComponent } from './reimbursement-card.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [ReimbursementCardComponent],
    imports: [CommonModule, SharedModule, CardModule, ModelAttributeModule, ZipBusyModule, FormGroupModule, ButtonModule],
    providers: [FormattingService],
    exports: [ReimbursementCardComponent],
})
export class ReimbursementCardModule {}
