import { Component, Input, OnInit } from '@angular/core';
import { GridViews } from './grid.constants';

@Component({
    selector: 'grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
    @Input() columns: GridViews;

    constructor() {}

    ngOnInit() {}
}
