import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@zipari/shared-sbp-services';
import { stringBuilder } from '@zipari/web-utils';

@Component({
    selector: 'zip-page-count',
    templateUrl: './page-count.component.html',
    styleUrls: ['./page-count.component.scss'],
})
export class PageCountComponent {
    @Input() firstShowing: number;
    @Input() lastShowing: number;
    @Input() totalCount: number;
    config;

    constructor(private configService: ConfigService) {}

    ngOnInit() {
        this.config = this.configService.getPageConfig('footer');
    }

    get paginationText() {
        if (!!this.config.hasOwnProperty('paginatorText')) {
            return stringBuilder(
                this.config.paginatorText,
                Object.assign(
                    {},
                    {
                        firstShowing: this.firstShowing,
                        lastShowing: this.lastShowing,
                        totalCount: this.totalCount,
                    }
                )
            );
        }
        return `Showing ${this.firstShowing} - ${this.lastShowing} of ${this.totalCount}`;
    }
}
