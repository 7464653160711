<section class="ProviderCard">
    <div class="search__result" *ngIf="result">
        <div class="search__result__id" *ngIf="!removePCPavatar && result.picture">
            <div class="avatar search__result__avatar">
                <img [src]="result.picture" alt="doctor image" />
            </div>
            <p class="t-caption">{{ result.gender === 'M' ? 'Male' : 'Female' }}</p>
        </div>

        <div class="search__result__info u-fillRemaining">
            <ng-container *ngFor="let attribute of config.attributes">
                <ng-container [ngSwitch]="attribute.type">
                    <div *ngSwitchCase="'name'" class="search__result__name" (click)="onViewProfileClick()">
                        <h5 class="t-bold">{{ result | fullName : true }}{{ attribute.suffix }}</h5>
                        <icon [name]="attribute.icon || 'chevron_right'" [config]="{ type: 'link', size: 'medium' }"></icon>
                    </div>
                    <ng-container *ngSwitchCase="'specialty'">
                        <p *ngIf="result.primary_specialty" class="t-data">
                            {{ result.primary_specialty }}
                        </p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'phone'">
                        <a *ngIf="result.phone" [href]="'tel: ' + result.phone" class="t-data t-link search__result__phone">
                            {{ result.phone }}
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'address'">
                        <ng-container *ngFor="let location of result.facility_locations">
                            <a
                                *ngIf="location && location?.address?.state"
                                class="search__result__location"
                                target="_blank"
                                [href]="
                                    getDirections(
                                        location?.address?.street_name_1 +
                                            ' ' +
                                            location?.address?.city_name +
                                            ' ' +
                                            location?.address?.state +
                                            ' ' +
                                            location?.address?.zip_code
                                    )
                                "
                            >
                                <p class="search__result__address">
                                    {{ location?.address?.street_name_1 }}, {{ location?.address?.city_name }},
                                    {{ location?.address?.state }}
                                    {{ location?.address?.zip_code }}
                                </p>
                                <p class="t-caption t-link">{{ attribute.text }}</p>
                            </a>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'generic'">
                        <p
                            *ngIf="!!getValue(attribute.prop)"
                            class="t-data search__result__medical-group"
                            [innerHTML]="getFormattedAttributeValue(attribute)"
                        ></p>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <div class="search__result__action">
            <zip-button
                *ngIf="result.selected === false"
                [config]="config.buttonConfig.add"
                [disabled]="isSelectDisabled"
                (zipButtonClicked)="onAddResultClick()"
            >
            </zip-button>

            <zip-button *ngIf="result.selected === true" [config]="config.buttonConfig.selected" (zipButtonClicked)="onRemoveResultClick()">
            </zip-button>

            <ul>
                <li *ngIf="!!result.accepting_new_patients"><i class="icon">check</i> Accepting New Patients</li>
            </ul>
        </div>
    </div>

    <footer class="ProviderCard__footer" *ngIf="whichWorkflow === 'facilitySearch'">
        <zip-button
            class="ProviderCard__toggle"
            [config]="{
                level: 'text',
                content: !!showDetails ? 'Hide Details' : 'View Details',
                icon: !!showDetails ? 'chevron_up' : 'chevron_down',
                isIconRight: true
            }"
            (zipButtonClicked)="toggleDetails()"
        >
        </zip-button>
    </footer>

    <section class="ProviderCard__details u-flex" [ngClass]="{ 'is-open': !!showDetails }" *ngIf="!!showDetails">
        <section [ngClass]="alignContent ? 'ProviderCard__alignedbenefits' : 'ProviderCard__details__section'">
            <div *ngIf="result.specialty_group" class="ProviderCard__details__list">
                <p class="ProviderCard__label">Specialties</p>
                <ng-container *ngFor="let specialty of result.specialties">
                    <div class="ProviderCard__specialty">
                        <p class="ProviderCard__value">{{ specialty }}</p>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="result.languages.length > 0" class="ProviderCard__details__list">
                <p class="ProviderCard__label">Languages</p>
                <ng-container *ngFor="let facility_location of result.facility_locations">
                    <ng-container *ngFor="let language of facility_location.languages">
                        <div class="ProviderCard__language">
                            <p class="ProviderCard__value">{{ language?.name }}</p>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <!-- <div *ngIf="result.plans.length > 0" class="ProviderCard__details__list">
                <p class="ProviderCard__label">Plans</p>
                <ng-container *ngFor="let plan of result.plans">
                    <div class="ProviderCard__networkName">
                        <p class="ProviderCard__value">{{ plan }}</p>
                    </div>
                </ng-container>
            </div> -->
        </section>
    </section>
</section>
