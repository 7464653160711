import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule, FormControlModule } from '@zipari/design-system';
import { SharedModule } from '../shared.module';
import { ComparisonCardComponent } from './comparison-card.component';

@NgModule({
    declarations: [ComparisonCardComponent],
    imports: [CommonModule, ModelAttributeModule, SharedModule, FormControlModule],
    exports: [ComparisonCardComponent],
})
export class ComparisonCardModule {}
