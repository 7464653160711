export const FILTERS = {
    ordering: {
        prop: 'ordering',
        type: 'radio',
        label: 'Sort by',
        options: [
            {
                label: 'Lowest Monthly Premium',
                value: 'price',
            },
            {
                label: 'Lowest Deductible',
                value: 'deductible',
            },
        ],
    },
    price: {
        prop: 'price',
        type: 'radio',
        label: 'Monthly Premium',
        options: [
            {
                label: 'Under $500',
                value: {
                    price_low: 0,
                    price_high: 500,
                },
            },
            {
                label: '$500 to $1000',
                value: {
                    price_low: 500,
                    price_high: 1000,
                },
            },
            {
                label: '$1000 and up',
                value: {
                    price_low: 1000,
                },
            },
        ],
    },
    deductible: {
        prop: 'deductible',
        type: 'radio',
        label: 'Deductible',
        options: [
            {
                label: 'Under $1000',
                value: {
                    deductible_low: 0,
                    deductible_high: 1000,
                },
            },
            {
                label: '$1000 to $2000',
                value: {
                    deductible_low: 1000,
                    deductible_high: 2000,
                },
            },
            {
                label: '$2000 to $5000',
                value: {
                    deductible_low: 2000,
                    deductible_high: 5000,
                },
            },
            {
                label: '$5000 and up',
                value: {
                    deductible_low: 5000,
                },
            },
        ],
    },
};
