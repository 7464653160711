import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, MessageBannerModule } from '@zipari/design-system';
import { DeStateErrorBannerComponent } from './de-state-error-banner.component';

@NgModule({
    declarations: [DeStateErrorBannerComponent],
    imports: [CommonModule, ButtonModule, MessageBannerModule],
    exports: [DeStateErrorBannerComponent],
})
export class DeStateErrorBannerModule {}
