import { StoreIDProofingResponse } from './store-id-proofing-response.model';

export interface FARSWithStoreResponse {
    fars: FARSResponse;
    store?: StoreIDProofingResponse;
}

export interface FARSResponse {
    IDProofedResponse?: any;
    ResponseMetadata: ResponseMetadata;
    linkMetadata?: any;
    metadata?: any;
    id?: any;
    identityProofingIdentifier?: string;
}
interface ResponseMetadata {
    ResponseCode: ResponseCodeOrResponseDescriptionText;
    TDSResponseDescriptionText?: any;
    ResponseDescriptionText: ResponseCodeOrResponseDescriptionText;
    id?: any;
}
interface ResponseCodeOrResponseDescriptionText {
    metadata?: any;
    _value_1: string;
    linkMetadata?: any;
    id?: any;
}
