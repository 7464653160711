export const default__contact1 = [
    {
        label: 'Address',
        value: {
            street_name_1: 'Zipari<br>45 Main St',
            city: '<br>Brooklyn',
            state: 'NY',
            zipcode: '11201',
        },
        format: 'ADDRESS',
    },
];

export const default__contact2 = [
    {
        label: 'Customer Service',
        value: '1-855-558-7884',
        format: 'PHONE',
    },
    {
        label: 'Email',
        value: 'info@zipari.com',
        format: 'EMAIL',
    },
];

export const default__quickLinks1 = {
    label: '',
    attributes: [
        {
            label: 'About Brand Health',
            value: 'https://www.zipari.com/about-us',
        },
        {
            label: 'Contact Us',
            value: 'https://www.zipari.com/connect-with-us/',
        },
        {
            label: 'Media Center',
            value: 'https://www.zipari.com/news/',
        },
        {
            label: 'Careers',
            value: 'https://jobs.lever.co/zipari',
        },
    ],
};

export const default__copyrightConfig = {
    text: 'Brand Health 1999-2020. All rights reserved. Health insurance products are offered by Brand Health. Health plans are offered by Brand Health',
    image: null,
};

export const default__socialMedia = {
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    instagram: 'https://www.instagram.com/?hl=en',
};
