import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeConfig, BadgeSizes, BadgeTypes } from './badge.constants';

@Component({
    selector: 'badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
    @Input() config: BadgeConfig;
    @Output() linkClicked = new EventEmitter();

    BadgeTypes = BadgeTypes;
    BadgeSizes = BadgeSizes;

    constructor() {}

    ngOnInit() {}
}
