<div>
    <img alt="Session expired" [src]="config.image" />

    <h2>{{ config.title }}</h2>

    <p class="session-expired__subtitle" [innerHTML]="contentBody"></p>

    <div class="button__group">
        <zip-button level="high" content="{{ config.loginButton.content }}" (zipButtonClicked)="handleLogin()"> </zip-button>
    </div>
</div>
