import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SearchComponentService {
    handleCheckboxRepeatedCommas(value: any): any {
        const stringValue = value.toString();
        if (stringValue.split(',').length > 1) {
            return value.filter((element) => element !== null && element !== '');
        }
    }

    /**
     * test that a provided coordinate set is a valid location to pass to map
     * @param coordinates longitude and latitude for a given map point
     */
    areValidCoordinates(coordinates: { lat: number; lng: number }): boolean {
        const maxValidLat = 90;
        const maxValidLong = 180;
        if (coordinates.lat < -maxValidLat || coordinates.lat > maxValidLat) {
            return false;
        }
        if (coordinates.lng < -maxValidLong || coordinates.lat > maxValidLong) {
            return false;
        }
        return true;
    }

    getDirections = (address) => `https://www.google.com/maps/dir/?api=1&origin=&destination=${address}`;

    withinDistances(): Array<number> {
        const fiveMiles = 5;
        const tenMiles = 10;
        const twentyFiveMiles = 25;
        const fiftyMiles = 50;
        const oneHundredMiles = 100;
        return [fiveMiles, tenMiles, twentyFiveMiles, fiftyMiles, oneHundredMiles];
    }
}
