import { Injectable } from '@angular/core';
import { getValue } from '@zipari/web-utils';
import { ConfigService } from '@zipari/shared-sbp-services';

@Injectable({
    providedIn: 'root',
})
export class EdeConfigService {
    config;

    _sectionCallback: Function;

    constructor() {}

    get section(): string {
        if (!this._sectionCallback) {
            return null;
        }

        return this._sectionCallback();
    }

    /** Retrieves the entire EDE config from the larger configuration object
     * If master configuration is ... then the ede config will be {"test": "test"}
     * {
     *     "ede": {
     *         "test": "test"
     *     }
     * }
     * */
    public retrieveInitialEdeConfig(configService: ConfigService, sectionCallback?: Function): void {
        this.config = configService.getPageConfig('ede');
        this._sectionCallback = sectionCallback ? sectionCallback : null;
    }

    /** Retrieves a particular step's EDE config
     * EXAMPLE:
     * If ede config is below and the current section is the Get Started section (with id getStarted). Using the
     * function below and passing in 'confirm' will yield you with the below result.
     *
     * Use of Function:
     * this.edeConfigService.getEdeConfig('confirm');
     *
     * Full EDE Config:
     * {
     *     "getStarted": {
     *         "confirm": {
     *             "coverageState": [
     *                 {
     *                     "label: "NY",
     *                     "value": "New York"
     *                 }
     *             ]
     *         }
     *     }
     * }
     *
     * Result:
     * {
     *     "coverageState": [
     *          {
     *              "label: "NY",
     *              "value": "New York"
     *          }
     *     ]
     *  }
     * */
    public getEdeConfig(step: string = null): any {
        let configPath: string = '';

        if (this.section) {
            configPath += this.section;
        }

        if (!configPath) {
            configPath += step;
        } else if (step) {
            configPath += `.${step}`;
        }

        return getValue(this.config || {}, configPath);
    }
}
