<ng-container *ngIf="!file">
    <file-uploader-dropzone [config]="config" [disabled]="disabled" [hasFile]="hasFile">
        <file-uploader-button [config]="config" [disabled]="disabled"> </file-uploader-button>
    </file-uploader-dropzone>

    <p class="t-data t-error" [innerHTML]="errorMessage" *ngIf="errorMessage"></p>
</ng-container>

<ng-container *ngIf="file">
    <div class="u-flex">
        <file-uploader-progress
            [error]="uploadErr"
            (closeClicked)="clearFile()"
            class="u-maxX file-upload-progress"
            [file]="file"
            [total]="total"
            [loaded]="loaded"
        >
        </file-uploader-progress>
    </div>
</ng-container>
