import {
    Application__ContactInformation,
    Computed__Members,
    CreatingSystemId,
    CreatingSystemRole,
    ErrorTypes,
    InsuranceApplicationType,
    ResponseLinks,
    ResultType,
    UseCaseTypes,
} from '../sharedModels';
import { UpdateApplicationRequest_Member } from '../update application/workflow-ede--update-application-request.constants';

export class Attestation__Member__Demographic__Mailing {
    streetName1: string = null;
    cityName: string = null;
    stateCode: string = null;
    zipCode: string = null;
    countyFipsCode: string = null;
    countyName: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestation__Member__Demographic__Name {
    firstName: string = null;
    lastName: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestation__Member__Demographic {
    birthDate: string = null;
    name: Attestation__Member__Demographic__Name = null;
    mailingAddress: Attestation__Member__Demographic__Mailing = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestation__Member__Income {
    incomeExceedFPL: string = null;
    seasonalWorkerIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestation__Member__Family {
    nonCustodialClaimerBothMFSParentonAppIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestation__Member__LawfulPresence {
    naturalizedCitizenIndicator: boolean = null;
    noAlienNumberIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestations__Application {
    coverageYear: number = null;
    coverageState: string = null;
    subjectToSampleIndicator: boolean = null;
    contactMemberIdentifier: string = null;
    contactInformation: Application__ContactInformation = new Application__ContactInformation();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class ErrorResponseStructure {
    errorType: ErrorTypes = null;
    apiMessage: string = null;
    errorCode: string = null;
    errors: Array<IndividualErrorStructure> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class IndividualErrorStructure {
    errorCode: string = null;
    path: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Attestations {
    application: Attestations__Application = null;
    members: UpdateApplicationRequest_Member = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberResponse__Result__Computed {
    members: Computed__Members = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberResponse__Result {
    insuranceApplicationIdentifier: number = null;
    applicationVersionNumber: number = null;
    versionDateTime: string = null;
    applicationCreationDateTime: string = null;
    creatingSystemId: CreatingSystemId = null;
    creatingSystemRole: CreatingSystemRole = null;
    creatingUserId: string = null;
    useCase: UseCaseTypes = null;
    deletedIndicator: boolean = null;
    currentVersionIndicator: boolean = null;
    comments: string = null;
    lastModifiedDateTime: string = null;
    lastModifiedUserId: string = null;
    insuranceApplicationType: InsuranceApplicationType = null;
    attestations: Attestations = null;
    computed: AddMemberResponse__Result__Computed = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberResponse {
    resultType: ResultType = null;
    links: ResponseLinks = null;
    result: AddMemberResponse__Result = null;
    error: ErrorResponseStructure = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
