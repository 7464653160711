export default class DEEligibilityResultsMapping {
    CSRMembers: any;
    QHPMembers: any;
    SEPEligibleMembers: memberMapping;
    QHPEligibleMembers: memberMapping;
    APTCEligibleMembers: memberMapping;
    CHIPEligibleMembers: memberMapping;
    MedicaidEligibleMembers: memberMapping;
    CSREligibleMembers: memberMapping;
    computedMembersArr: Array<any>;
    computedMembersMap: memberMapping;
    DMIPersonTrackingMapping: any;
}

type memberMapping = basicMemberMapping | {};

interface basicMemberMapping {
    [memberKey: string]: boolean;
}
