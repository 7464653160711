import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@zipari/web-utils';
import { SimpleSubnavComponent } from './simple-subnav.component';

@NgModule({
    declarations: [SimpleSubnavComponent],
    imports: [CommonModule, DirectivesModule],
    exports: [SimpleSubnavComponent],
})
export class SimpleSubnavModule {}
