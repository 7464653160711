import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessRulesService } from '@zipari/design-system';
import { ModalConfig, ModalTypes, ModalHeader } from '@zipari/shared-ds-util-modal';
import { applicationsURLS, defaultplanOrdering } from '@zipari/shared-sbp-constants';
import {
    enrollmentConfirmationSteps,
    getCoverageTypes,
    handleOnExchange,
    IndividualEnrollment,
    IndividualEnrollmentStepTypes,
} from '@zipari/shared-sbp-enrollment';
import { Plan, PlanParams, PlanTypes, WorkflowPlanStructure } from '@zipari/shared-sbp-models';
import { InitialSignaturesConfig, RestructuredSignaturesConfig } from '@zipari/shared-sbp-modules';
import {
    AnalyticsService,
    AuthService,
    ConfigService,
    getProductOptionsQueryParams,
    LoggerService,
    ModalService,
} from '@zipari/shared-sbp-services';
import { coverageType, FormStepCardConfig, FormStepConfig } from '@zipari/shared-sbp-templates';
import { cloneObject, getValue } from '@zipari/web-utils';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { WorkflowService } from '../../../../services/src/lib/workflow.service';

export const stateExchangeModalConfig: ModalConfig = new ModalConfig({
    type: ModalTypes.popup,
    header: new ModalHeader({
        title: '',
        showClose: false,
    }),
    cancel: 'Cancel',
});

export class StateExchangeConfig {
    url?: string;
    body?: string;
    header?: string;
    cancel?: string;
}

export const NewCoverageDateModalConfig: ModalConfig = new ModalConfig({
    type: ModalTypes.popup,
    backdrop: true,
    header: new ModalHeader({
        title: 'Coverage Effective Date has changed',
        showClose: false,
    }),
    cancel: 'Cancel',
    accept: 'Accept New Effective Date',
});

@Injectable({
    providedIn: 'root',
})
export class IndividualService extends WorkflowService<IndividualEnrollment> {
    readonly planStepIndex: number = 2;
    readonly multiPlanStepIndex: number = 2;
    readonly specialPlanStepIndex: number = 3;
    readonly planEndpoint: string = 'api/shopping/plans/';

    showStateHeader: boolean;
    planFilters;
    formStepConfig: FormStepConfig;
    selectedPlans;
    appID;

    _newEffectiveDate: string;

    constructor(
        public loggerService: LoggerService,
        public locationStrategy: LocationStrategy,
        public analyticsService: AnalyticsService,
        public http: HttpClient,
        public modalService: ModalService,
        public businessRulesService: BusinessRulesService,
        public router: Router,
        public configService: ConfigService
    ) {
        super(loggerService, locationStrategy, analyticsService, http, modalService, router);
    }

    public get activeStepType() {
        return getValue(this.workflow || {}, 'current_step.schema.type');
    }

    public get activeStepNumber() {
        return this.workflow && this.workflow.current_step_number;
    }

    public get county() {
        return (
            getValue(this.workflow, 'values.permanent_address.county') ||
            (this.workflow.values.demographics && this.workflow.values.demographics.county) ||
            this.workflow.values.county
        );
    }

    public coverageTypes(values): any {
        return getCoverageTypes(values);
    }

    public get showStateHeaderFooter(): boolean {
        return this.showStateHeader;
    }

    public getSelectedPlans(): Array<Plan> {
        const plans: WorkflowPlanStructure = getValue(this.workflow, 'values.plans');

        if (plans) {
            return Object.keys(this.workflow.values.plans).map((planType: string) => this.workflow.values.plans[planType]);
        }

        return [];
    }

    // leaving this for EDE, updated for SP/BP, renewals, and upsell
    public getPlansEndpoint(county = null): string {
        const _county = county ? county : this.county;
        const plansEndpoint: string = `api/shopping/plans/?county_code=${_county}&workflow_id=${this.workflow.id}`;
        return plansEndpoint;
    }

    public getPlansFilters(overrideValues = {}): PlanParams {
        const values = { ...this.workflow.values, ...overrideValues };
        const coverageDate: string = values.coverage_effective_date;
        const year = coverageDate?.slice(0, 4) || new Date().getFullYear();
        const params: PlanParams = {
            coverage_effective_date: values.coverage_effective_date,
            dependants_count: values.dependents?.length ?? 0,
            ordering: defaultplanOrdering,
            year,
            zipcode: values.demographics.zipcode,
        };

        if (values.plan_variation) {
            params['plan_variation'] = values.plan_variation;
        }

        if (!!values.subscriber) {
            params['subscriber_date_of_birth'] = values.subscriber.date_of_birth;

            if (values.subscriber.hasOwnProperty('tobacco_user')) {
                const tobacco_user = values.subscriber.tobacco_user;

                if (typeof tobacco_user === 'object') {
                    params['subscriber_tobacco'] = tobacco_user.tobacco_user;
                } else if (typeof tobacco_user === 'boolean') {
                    params['subscriber_tobacco'] = tobacco_user;
                }
            }
        }

        if (!!values.spouse) {
            params['spouse_date_of_birth'] = values.spouse.date_of_birth;

            if (values.spouse.hasOwnProperty('tobacco_user')) {
                params['spouse_tobacco'] = !!values.spouse.tobacco_user;
            }
        }

        if (!!values.dependents) {
            params['dependants_count'] = values.dependents.length;

            values.dependents.forEach((dependent, index: number) => {
                params[`dependant_${index}_date_of_birth`] = dependent.date_of_birth;

                if (dependent.hasOwnProperty('tobacco_user')) {
                    params[`dependant_${index}_tobacco`] = !!dependent.tobacco_user;
                }
            });
        }
        return params;
    }

    get newEffectiveDate() {
        return this._newEffectiveDate;
    }

    checkCoverageEffectiveDateValidity(stepValues, applicationType: string = '', passedWFvals?): Observable<any> {
        // for some reason renewals and upsell don't have workflow.values.... so we're passing them ¯\_(ツ)_/¯
        const workflowValues = this.workflow.values ? this.workflow.values : passedWFvals;
        const enrollmentPeriod: any = workflowValues.enrollment_period;
        let planType = this.coverageTypes(workflowValues);

        const application_type = this.workflow?.schema?.config?.application_type
            ? this.workflow?.schema?.config?.application_type
            : applicationType;

        const productOptionsQPs: HttpParams = getProductOptionsQueryParams(enrollmentPeriod, planType, application_type);

        const qleDate = workflowValues?.special_enrollment ? workflowValues.special_enrollment.date : '';
        const qleCode = workflowValues?.special_enrollment ? workflowValues.special_enrollment.qle : '';

        const isMedicalSelected: boolean = !!(planType?.includes('medical') || planType?.includes('health'));

        if (enrollmentPeriod === 'SEP' && qleDate && qleCode) {
            const QLEurl: string = '/api/product_changes/qle/';
            const params: HttpParams = new HttpParams().set('code', qleCode);
            // when we're sharing an app started in BP the qle id matches the BP QLEs
            // these will not match the shopping QLEs so we'll need to query the QLE directly using the qle code
            // This will get the correct qle id given the portal we're in, as codes are constants, but ids change
            return this.http.get(QLEurl, { params }).pipe(
                mergeMap((res: any) => {
                    const qleId: number | string = res.results[0].id;
                    const sepEndpoint: string = `/api/product_changes/qle/${qleId}/effective_date/?qle_date=${qleDate}`;
                    return this.http.get(sepEndpoint).pipe(
                        tap((response) => {
                            this.determineCurrentCoverageEffectiveDate(response, stepValues, passedWFvals);
                        })
                    );
                })
            );
        } else if (enrollmentPeriod === 'OE' || enrollmentPeriod === undefined || !isMedicalSelected) {
            // MA doesn't define an enrollmentPeriod
            const endpoint: string = '/api/product_changes/product_options/';

            return this.http.get(endpoint, { params: productOptionsQPs }).pipe(
                tap((resp: any) => {
                    this.determineCurrentCoverageEffectiveDate(resp, stepValues, passedWFvals);
                })
            );
        } else {
            // if in SEP and qleDate or qleCode is missing, throw an error
            console.error('Coverage Effective Date Check is missing qleDate or qleCode in the workflow');
        }
    }

    determineCurrentCoverageEffectiveDate(resp, stepValues, passedWFvals?) {
        // for some reason renewals and upsell don't have workflow.values.... so we're passing them ¯\_(ツ)_/¯
        const workflowValues = this.workflow.values ? this.workflow.values : passedWFvals;
        let planType = this.coverageTypes(workflowValues);

        let newEffectiveDate: string;
        const currentEffectiveDate: string = getValue(Object.assign({}, workflowValues, stepValues), 'coverage_effective_date');

        if (Array.isArray(planType)) {
            planType = planType.filter((type) => typeof type === 'string' && !!type);

            // Use the health/medical business rules for mixed applications
            const isMedicalApp = planType.find((type) => ['health', 'medical'].includes(type));

            if (isMedicalApp) {
                planType = planType.filter((type) => ['health', 'medical'].includes(type));

                // defaulting 'medical' planType to 'health' as that's what we use in product options API call
                planType[0] = planType[0] === 'medical' ? 'health' : planType[0];
            }
        }

        if (resp.results && resp.results.length) {
            const effDatesArray: Array<string> = resp.results.find((date) => date.plan_type === planType[0]).effective_dates;
            if (effDatesArray && effDatesArray.length > 1) {
                newEffectiveDate = effDatesArray.find((date) => date === currentEffectiveDate);
            } else {
                newEffectiveDate = effDatesArray[0];
            }
        } else if (!newEffectiveDate && resp.effective_dates && resp.effective_dates.length) {
            resp.effective_dates.length > 1
                ? (newEffectiveDate = resp.effective_dates.find((date) => date === currentEffectiveDate))
                : (newEffectiveDate = resp.effective_dates[0]);
        } else if (!newEffectiveDate && resp.effective_date) {
            newEffectiveDate = resp.effective_date;
        }

        const effectiveDateMilli = Date.parse(currentEffectiveDate);
        const newEffectiveDateMilli = Date.parse(newEffectiveDate);

        if (newEffectiveDate && !Number.isNaN(newEffectiveDateMilli)) {
            const effectiveDate: Date = new Date(effectiveDateMilli);
            const newEffectiveDateCheck: Date = new Date(newEffectiveDateMilli);

            if (!this.datesAreOnSameDay(effectiveDate, newEffectiveDateCheck)) {
                this._newEffectiveDate = newEffectiveDate;
            } else {
                this._newEffectiveDate = null;
            }
        } else {
            this._newEffectiveDate = null;
        }
    }

    datesAreOnSameDay(first: Date, second: Date): boolean {
        return (
            first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()
        );
    }

    private get isAppConvertedAndOnPersonalInfo(): boolean {
        return this.workflow && this.isAppConverted && this.activeStepType === IndividualEnrollmentStepTypes.personal_info;
    }

    public isEnrollmentOnConfirmation = (): boolean => enrollmentConfirmationSteps.includes(this.activeStepType);

    private get isAppConvertedAndOnAutoBundle(): boolean {
        return this.workflow && this.isAppConverted && this.activeStepType === IndividualEnrollmentStepTypes.autobundle;
    }

    handleBackButtonClick() {
        const isBrowserBackDisabled = () =>
            this.isAppConvertedAndOnPersonalInfo || this.isEnrollmentOnConfirmation || this.isAppConvertedAndOnAutoBundle;

        super.handleBackButtonClick(isBrowserBackDisabled);
    }

    /**
     * Method to restructure configuration for signatures based on workflow values and an initial configuration
     * Will add required signature controls based on enrollment demographics and existence of signature labels
     * @param config configuration that requires at least a `signatures` key to restructure
     */
    public structureSignaturesConfig(config: { signatures?: InitialSignaturesConfig; [key: string]: any }): any {
        if (!config.signatures) {
            return config;
        }
        const structuredConfig = cloneObject(config);
        const signatures: Array<RestructuredSignaturesConfig> = [];

        if (!this.workflow.values.child_only && config.signatures.applicantLabel) {
            signatures.push({
                label: config.signatures.applicantLabel,
                controls: config.signatures.controls,
            });
        }

        if (this.workflow.values.child_only && config.signatures.guardianLabel) {
            signatures.push({
                label: config.signatures.guardianLabel,
                controls: config.signatures.controls,
            });
        }

        if (this.workflow.values.spouse && config.signatures.spouseLabel) {
            signatures.push({
                label: config.signatures.spouseLabel,
                controls: config.signatures.controls,
            });
        }

        // replace signatures initial signatures config with restructured signatures config
        return { ...structuredConfig, signatures };
    }

    public runConditionalsOnCards(config: { cards: Array<FormStepCardConfig>; [key: string]: any }): {
        cards: Array<FormStepCardConfig>;
        [key: string]: any;
    } {
        return {
            ...config,
            cards: config.cards.filter((card) => {
                if (!card.conditional) {
                    return true;
                }

                if (card.conditional) {
                    card['conditions'] = this.businessRulesService.handleDeprecatingConditionalStructure(card.conditional);
                }

                return this.businessRulesService.retrieveResultFromBusinessRule(card['conditions'], this.workflow.values);
            }),
        };
    }

    /**
     * Navigate to the correct 'previous' step from the Personal Info wf step.
     * Called when the user is on the Personal Info step and clicks 'back'.
     */
    public handlePreviousForPersonalInfo(): void {
        if (!this.isAppConverted) {
            // if autobundled plans exist, navigate to the autobundle step
            const autoBundledPlans = getValue(this.workflow.values, 'auto_bundled_plan_types');
            if (autoBundledPlans) {
                this.goToStep(this.getStepNumberByStepType('autobundle'));
            } else {
                // navigate to the correct step based on existing wf coverage types
                const coverageTypeValue =
                    getValue(this.workflow.values, 'demographics.coverage_type') ||
                    getValue(this.workflow.values, 'demographics.coverage_types');
                const coverage_type = Array.isArray(coverageTypeValue)
                    ? coverageTypeValue.includes('health') || coverageTypeValue.includes('medical')
                        ? 'health'
                        : 'dental_vision'
                    : coverageTypeValue;

                switch (coverage_type) {
                    case coverageType.health:
                    case coverageType.medical:
                        this.goToStep(this.planStepIndex);
                        break;
                    case coverageType.dentalVisionWithUnderscore:
                    case coverageType.dentalVision:
                        this.goToStep(this.getStepNumberByStepType(coverageType.dentalVisionWithUnderscore));
                        break;
                    default:
                        this.goToStep(this.multiPlanStepIndex);
                        break;
                }
            }
        }
    }

    public getApplication(): Observable<any> {
        const applicationId: string | number = this.workflow && this.workflow.data && this.workflow.data.application_id;
        const endpoint: string = this.configService.getEndpoint(applicationsURLS);

        if (applicationId) this.http.get<any>(`${endpoint}${applicationId}/`);

        return this.http.get<any>(endpoint).pipe(
            map((response) => {
                return response.results.length ? response.results[0] : {};
            })
        );
    }

    handleBillingComplete(response: any, config: any, callback?) {
        const callbacks = response.callbacks;

        this.completeStepObs$(response.data || {}).subscribe(
            () => {
                if (callbacks && callbacks.success) {
                    callbacks.success();
                }
            },
            (error) => {
                // add special error cases where we show directly what the BE provides to us in the "user_error"
                // otherwise handle default through configuration override
                const specialErrorCases = {
                    coverageEffectiveDate: (errMessage: string) => errMessage.indexOf('not a valid effective coverage date') >= 0,
                };
                const message: string = getValue(error, 'error.errors.0.user_error');
                const baseConfig: { message: string } = config.errorConfigs.handleBillingComplete;
                let finalConfig = cloneObject(baseConfig);

                // if custom user message exists on the BE response handle it in more detail
                if (message) {
                    const isSpecialErrorCase: boolean = !!Object.keys(specialErrorCases).find((funcKey: string) =>
                        specialErrorCases[funcKey](message)
                    );

                    if (isSpecialErrorCase) {
                        finalConfig = { ...baseConfig, message };
                    }
                }

                this.handleError(finalConfig);
                this.setLoading(false);

                if (callbacks && callbacks.error) {
                    callbacks.error();
                }
            }
        );

        callback();
    }

    handlePlanSelectionComplete(event, callback): void {
        this.replaceValuesOnOtherStepsIfTheyExist(event, ['plan', 'plans'], this.activeStepNumber - 1)
            .pipe(
                tap((result) => {
                    this._loading.next(false);
                    this.completeStep(result.currentStepData);
                })
            )
            .subscribe();

        callback();
    }

    /**
     * Given a list of plans, set `discountedPrice` on each on-exchange plan if workflow has calculated `subsidy_amount`
     */
    public setDiscountedPrice(plans: Array<Plan>): Array<Plan> {
        const subsidy_amount = this.workflow.values.subsidy_amount;
        if (!this.workflow.values.subsidy_amount) {
            return plans;
        }
        return plans.map((plan) => {
            const isPlanOnExchange = this.isPlanOnExchange(plan);
            if (isPlanOnExchange) {
                const discountedPrice = Number(plan.price) - subsidy_amount;
                const displayedDiscountedPrice = discountedPrice < 0 ? '0' : String(discountedPrice);
                return { ...plan, discountedPrice: displayedDiscountedPrice };
            }
            return plan;
        });
    }

    public isPlanOnExchange(plan: Plan): boolean {
        const isPlanMedical = plan.plan_type === PlanTypes.medical;
        const hasPlanOnExchangeVariation = parseInt(plan.plan_variation, 10) > 0;

        return isPlanMedical && hasPlanOnExchangeVariation;
    }

    handleOnExchange(workflow, router, configService, edeSes, callback, isDe: boolean, authService) {
        handleOnExchange(
            workflow,
            router,
            configService.getPageConfig('enrollment'),
            configService.appRoute,
            edeSes,
            callback,
            isDe,
            authService,
            this.activeStepType,
            this.http
        );
    }

    handleWorkflowSubLogic(workflow, config, configService: ConfigService, authService: AuthService, route: ActivatedRoute) {
        if (workflow.data) {
            this.appID = workflow.data.application_id;
        }

        const stepType: string = getValue(workflow, 'current_step.schema.type');
        const schemaKey: string = getValue(workflow, 'current_step.schema.key');
        this.showStateHeader = config.hasOwnProperty('hideStateHeader') ? !config.hideStateHeader[stepType] : true;

        // based on particular step types run specific logic
        switch (stepType) {
            case 'login_register':
                if (route.snapshot.params.id && authService.loggedInUser && authService.loggedInUser.app_user_data) {
                    window['_initLiveAgent'] = false;
                    this.completeStep();
                } else {
                    authService.handleExternalRegisterOrLogin();
                }
                break;
            case 'plan_selection':
            case 'dental_vision':
                this.planFilters = this.getPlansFilters();
                break;
            case 'form_step':
                const initialFormStepConfig = config[schemaKey];
                const filteredFormStepConfig = this.runConditionalsOnCards(initialFormStepConfig);

                this.formStepConfig = this.structureSignaturesConfig(filteredFormStepConfig);
                break;
            case 'qle_document_upload':
            case 'pcp_selection':
            case 'special_enrollment_period':
                break;
            case 'confirmation':
            case 'complete':
            case 'billing':
            case 'review':
                this.selectedPlans = this.getSelectedPlans();
                break;
        }
    }
}
