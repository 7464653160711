import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AttestationCardComponent } from './attestation-card.component';

@NgModule({
    declarations: [AttestationCardComponent],
    exports: [AttestationCardComponent],
    imports: [CommonModule, FormsModule],
})
export class AttestationCardModule {}
