import { AfterViewInit, Directive, DoCheck, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { getValue } from '@zipari/web-utils';
import { TranslateService } from '@zipari/shared-sbp-services';

@Directive({
    selector: 'p, h1, h2, h3, h4, h5, h6, span, label',
})
export class TranslateDirective implements OnInit {
    constructor(public el: ElementRef, public translateService: TranslateService) {}

    ngOnInit() {
        this.translateService.changeLanguageSubject.subscribe(() => {
            this.translate(this.el, this.translateService.activeLanguageConfig);
        });
    }

    /**
     * goes through element and changes the child node's value
     * IMPORTANT: we have to change this node value to ensure that we preserve the element's styling
     * */
    translate(el: ElementRef, mapping: any) {
        const nativeEl = el.nativeElement;
        const elText = getValue(nativeEl, 'childNodes.0.nodeValue');

        if (typeof elText === 'string') {
            const mappingResult = mapping[elText.trim()];

            if (mappingResult) {
                nativeEl.childNodes[0].nodeValue = mappingResult;
            }
        }
    }

    ngAfterViewInit() {
        this.translate(this.el, this.translateService.activeLanguageConfig);
    }
}
