import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { cloneObject, deepMerge, getValue } from '@zipari/web-utils';
import { StepperPrefillVal } from '@zipari/shared-sbp-modules';
import { GlobalConfig, IWhoseCovered } from '@zipari/shared-sbp-constants';
import { capFirstLetter, CoveredMemberKey } from '@zipari/shared-sbp-enrollment';
import { ConfigService } from '@zipari/shared-sbp-services';
import { Subscription } from 'rxjs';

import {
    dependentButtonConfig,
    parentButtonConfig,
    childOnlyConfig,
    dependentConfig,
    parentDependentConfig,
    individualsCoveredConfig,
    spouseConfig,
    subscriberConfig,
    IParentDependent,
    whoseCoveredOptions,
    CoveredDependentMembers,
} from './whose-covered.constants';
import { DependentDetail, DependentEnum, DependentTypeEnum } from '../whose-covered-form/whose-covered-form.constants';

@Component({
    selector: 'whose-covered',
    templateUrl: './whose-covered.component.html',
    styleUrls: ['./whose-covered.component.scss'],
})
export class WhoseCoveredComponent implements OnInit, DoCheck, OnChanges {
    @Input() group;
    @Input() config;
    @Input() direction;
    @Input() prefillVal: StepperPrefillVal = {};

    @Output() formCreated = new EventEmitter();

    childOnlyConfig = cloneObject(childOnlyConfig);
    subscriberConfig = cloneObject(subscriberConfig);
    spouseConfig = cloneObject(spouseConfig);
    dependentConfig = cloneObject(dependentConfig);
    parentDependentConfig = cloneObject(parentDependentConfig);
    individualsCoveredConfig = cloneObject(individualsCoveredConfig);
    whoseCoveredOptions = cloneObject(whoseCoveredOptions);
    childOnlySub;
    spouseCoveredSub;
    depCoveredSub;
    dependentVal;
    parentDependentVal: IParentDependent;
    childOnlyVal;
    whoseCoveredSelected;
    childLimit;
    dependentButtonConfig = dependentButtonConfig;
    parentButtonConfig = parentButtonConfig;
    subscriberDateOfBirth;
    spouseDateOfBirth;

    prevDisabled;
    patchedValue;

    showSubscriber: boolean;
    showSpouse: boolean;
    showParent: boolean;
    showChild: boolean;
    showChildOnly: boolean;
    whoseCoveredSelectedValue: string;
    hasParentDependents: boolean;
    addDependentType: string;
    addDependentConfig;
    isSubscriberOnly: boolean;

    constructor(
        private configService: ConfigService,
        private formControlService: FormControlService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.handleConfigOverrides();
        this.addExtraConfigs();
        this.whoseCoveredOptions = whoseCoveredOptions;
        this.formControlService.addControlToFormGroup(this.whoseCoveredGroup, this.individualsCoveredConfig);
        this.prefillForm();
        this.handleDisablingComponent();

        this.prefillForm();
    }

    ngDoCheck() {
        this.handleDisablingComponent();
    }

    ngOnChanges(changes) {
        if (
            'group' in changes &&
            changes.group.currentValue.controls.whoseCovered &&
            changes.group.currentValue.controls.whoseCovered.controls.whose_covered &&
            changes.group.currentValue.controls.whoseCovered.controls.whose_covered.value
        ) {
            this.setupForms(changes.group.currentValue.controls.whoseCovered.controls.whose_covered.value);
        }
    }

    handleDisablingComponent() {
        if (this.config.isDisabled !== this.prevDisabled) {
            const newDisabledValue = this.config.isDisabled;

            const configs = [this.spouseConfig, this.subscriberConfig, this.childOnlyConfig, this.dependentConfig];

            this.individualsCoveredConfig.isDisabled = newDisabledValue;
            configs.map((memberConfig) => {
                memberConfig.controls = memberConfig.controls.map((controlConfig) => {
                    controlConfig.isDisabled = newDisabledValue;

                    return controlConfig;
                });
            });

            this.prevDisabled = this.config.isDisabled;
        }
    }

    handleConfigOverrides() {
        const globalConfig: GlobalConfig = this.configService.getPageConfig('global');
        this.hasParentDependents = globalConfig?.featuresFlag?.whoseCovered?.hasParentDependents || false;

        if (this.config.childLimit) {
            this.childLimit = this.config.childLimit;
        }

        if (this.config?.button) {
            this.dependentButtonConfig = this.config.button;
        }

        if (this.config?.parentButtonConfig) {
            this.parentButtonConfig = this.config.parentButtonConfig;
        }

        if (this.config.childOnlyOptions) {
            this.childOnlyConfig = deepMerge(this.childOnlyConfig, cloneObject(this.config.childOnlyOptions));
        }

        if (this.config.depOptions) {
            this.dependentConfig = deepMerge(this.dependentConfig, cloneObject(this.config.depOptions));
        }

        if (this.config.parentDepOptions) {
            this.parentDependentConfig = deepMerge(this.parentDependentConfig, cloneObject(this.config.parentDepOptions));
        }

        if (this.config.subscriberOptions) {
            this.subscriberConfig = deepMerge(this.subscriberConfig, cloneObject(this.config.subscriberOptions));
        }
        if (this.config.spouseOptions) {
            this.spouseConfig = deepMerge(this.spouseConfig, cloneObject(this.config.spouseOptions));
        }

        if (this.config.individualsCoveredOptions) {
            this.individualsCoveredConfig = this.config.individualsCoveredOptions;
        }
    }

    addExtraConfigs() {
        const extraConfigs = this.config.extraConfigs || {};

        if (extraConfigs.all) {
            extraConfigs.all.forEach((config) => {
                this.subscriberConfig.controls.push(cloneObject(config));
                this.spouseConfig.controls.push(cloneObject(config));
                this.dependentConfig.controls.push(cloneObject(config));
                this.childOnlyConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.subscriber) {
            extraConfigs.subscriber.forEach((config) => {
                this.subscriberConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.spouse) {
            extraConfigs.spouse.forEach((config) => {
                this.spouseConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.dependents) {
            extraConfigs.dependents.forEach((config) => {
                this.dependentConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.child_only) {
            extraConfigs.child_only.forEach((config) => {
                this.childOnlyConfig.controls.push(cloneObject(config));
            });
        }
    }

    public get whoseCoveredGroup(): FormGroup {
        return this.group.controls[this.config.prop];
    }

    public get whoseCoveredValue(): any {
        return this.group.controls[this.config.prop].value;
    }

    public addParent(persistValue: IWhoseCovered): void {
        this.parentDependentVal = this.addDependentForm(DependentTypeEnum.stepParents, this.parentDependentConfig, persistValue);
    }

    public addChildren(persistValue: IWhoseCovered): void {
        this.dependentVal = this.addDependentForm(DependentTypeEnum.dependents, this.dependentConfig, persistValue);
    }

    public addChildOnly(persistValue: IWhoseCovered): void {
        this.dependentVal = this.addDependentForm(DependentTypeEnum.dependents, this.childOnlyConfig, persistValue);
    }

    public addSubscriber(persistValue) {
        if (!this.whoseCoveredGroup.controls.subscriber) {
            this.whoseCoveredGroup.addControl('subscriber', new FormGroup({}));
        }

        this.subscriberConfig.controls.forEach((config) => {
            this.formControlService.addControlToFormGroup(
                this.whoseCoveredGroup.controls.subscriber,
                config,
                persistValue.subscriber || {}
            );
        });

        this.subscriberDateOfBirth = getValue(this.whoseCoveredGroup, 'controls.subscriber.controls.date_of_birth');
    }

    public get maxAgeForSubscriber() {
        return (
            !!this.subscriberDateOfBirth &&
            !!this.subscriberDateOfBirth.errors &&
            !!this.subscriberDateOfBirth.errors.maxAge &&
            (this.subscriberDateOfBirth.touched || this.subscriberDateOfBirth.dirty)
        );
    }

    public get isSubscriberOverSixtyFive(): boolean {
        const isMaxAgeAllowed: boolean = !!getValue(this.config, 'coverage.subscriber.maxAgeAllowed');
        if (this.subscriberDateOfBirth?.valid) {
            return isMaxAgeAllowed ? this.getAge(this.subscriberDateOfBirth.value) >= 65 : false;
        }
    }

    public get maxAgeForSpouse() {
        return !!this.spouseDateOfBirth && !!this.spouseDateOfBirth.errors && !!this.spouseDateOfBirth.errors.maxAge;
    }

    public get isSpouseOverSixtyFive(): boolean {
        const isMaxAgeAllowed: boolean = !!getValue(this.config, 'coverage.spouse.maxAgeAllowed');
        if (this.spouseDateOfBirth?.valid) {
            return isMaxAgeAllowed ? this.getAge(this.spouseDateOfBirth.value) >= 65 : false;
        }
    }

    public addSpouse(persistValue) {
        if (!this.whoseCoveredGroup.controls.spouse) {
            this.whoseCoveredGroup.addControl('spouse', new FormGroup({}));
        }

        this.spouseConfig.controls.forEach((config) => {
            this.formControlService.addControlToFormGroup(this.whoseCoveredGroup.controls.spouse, config, persistValue.spouse || {});
        });

        this.spouseDateOfBirth = getValue(this.whoseCoveredGroup, 'controls.spouse.controls.date_of_birth');
    }

    removeDependent(dependentDetailObj: DependentDetail): void {
        if (dependentDetailObj.addDependentValue === DependentEnum.spouseDependent) {
            if (this.whoseCoveredGroup.controls.spouse) {
                this.whoseCoveredGroup.removeControl(DependentTypeEnum.spouse);
            }
        } else if (dependentDetailObj.addDependentValue === DependentEnum.parentDependent) {
            if (this.whoseCoveredGroup?.controls?.stepParents instanceof FormArray) {
                this.whoseCoveredGroup.controls.stepParents.removeAt(dependentDetailObj.index);
            }

            this.whoseCoveredValue?.stepParents?.length > 0
                ? (this.parentDependentVal = this.whoseCoveredValue.stepParents)
                : delete this.whoseCoveredGroup.controls.stepParents && delete this.whoseCoveredValue.stepParents;
        } else {
            if (this.whoseCoveredGroup.controls.dependents instanceof FormArray) {
                this.whoseCoveredGroup.controls.dependents.removeAt(dependentDetailObj.index);
            }
            this.whoseCoveredValue?.dependents?.length > 0
                ? (this.dependentVal = this.whoseCoveredValue.dependents)
                : delete this.whoseCoveredGroup.controls.dependents && delete this.whoseCoveredValue.dependents;
        }
        this.showWhoseCoveredAdded(dependentDetailObj.addDependentValue);
    }
    // when user click to add more Dependents then we identify Dependent Type here
    // we created a single method for adding all the dependents
    addDependent(whoseCoveredObj) {
        if (whoseCoveredObj.addDependentValue === DependentEnum.spouseDependent) {
            this.addSpouse({});
        } else if (whoseCoveredObj.addDependentValue === DependentEnum.subscriberDependent) {
            this.formControlService.removeAllControls(this.whoseCoveredGroup, { whose_covered: true });
            this.addSubscriber({});
        } else if (whoseCoveredObj.addDependentValue === DependentEnum.childDependent) {
            this.dependentVal = this.addDependentForm(
                DependentTypeEnum.dependents,
                this.dependentConfig,
                whoseCoveredObj.whoseCoveredValue,
                whoseCoveredObj.shouldAddDependent
            );
        } else if (whoseCoveredObj.addDependentValue === DependentEnum.parentDependent) {
            this.parentDependentVal = this.addDependentForm(
                DependentTypeEnum.stepParents,
                this.parentDependentConfig,
                whoseCoveredObj.whoseCoveredValue,
                whoseCoveredObj.shouldAddDependent
            );
        } else if (whoseCoveredObj.addDependentValue === DependentEnum.chilOnlyDependent) {
            this.formControlService.removeAllControls(this.whoseCoveredGroup, { whose_covered: true });
            this.dependentVal = this.addDependentForm(
                DependentTypeEnum.dependents,
                this.childOnlyConfig,
                whoseCoveredObj.whoseCoveredValue,
                whoseCoveredObj.shouldAddDependent
            );
        } else {
            this.formControlService.removeAllControls(this.whoseCoveredGroup, { dependents: true, whose_covered: true });
            this.dependentVal = this.addDependentForm(
                DependentTypeEnum.dependents,
                this.childOnlyConfig,
                whoseCoveredObj.whoseCoveredValue,
                whoseCoveredObj.shouldAddDependent
            );
        }
        this.showWhoseCoveredAdded(whoseCoveredObj.addDependentValue);
    }

    addDependentForm(dependentType: string, dependentConfig, persistValue: IWhoseCovered, shouldAddDependent: boolean = false) {
        const whoseCoveredControl = this.whoseCoveredGroup?.controls;
        if (!whoseCoveredControl[dependentType]) {
            this.whoseCoveredGroup.addControl(dependentType, new FormArray([]));
        }

        const dependentCoveredControl: any = whoseCoveredControl[dependentType];
        if (dependentCoveredControl instanceof FormArray) {
            persistValue[dependentType]?.length && !shouldAddDependent
                ? persistValue[dependentType].forEach(() => (dependentCoveredControl as FormArray).push(new FormGroup({})))
                : dependentCoveredControl.push(new FormGroup({}));

            for (let currDepInd = 0; currDepInd < dependentCoveredControl?.length; currDepInd++) {
                dependentConfig.controls.forEach((config) => {
                    if (!dependentCoveredControl['controls'][currDepInd]['controls'][config.prop]) {
                        this.formControlService.addControlToFormGroup(
                            dependentCoveredControl['controls'][currDepInd],
                            config,
                            persistValue[dependentType] ? persistValue[dependentType][currDepInd] : {}
                        );
                    }
                });
            }
        }
        return this.whoseCoveredValue[dependentType];
    }

    onChange(event) {
        let whosCovered = event.target.value;
        if (whosCovered === CoveredDependentMembers.spouse) whosCovered = CoveredDependentMembers.subscriberSpouse;
        if (whosCovered === CoveredDependentMembers.parent) whosCovered = CoveredDependentMembers.subscriberChildren;
        if (whosCovered === CoveredDependentMembers.family) whosCovered = CoveredDependentMembers.subscriberSpouseChildren;
        this.setupForms(whosCovered);
        this.showWhoseCoveredAdded(whosCovered);
        this.whoseCoveredSelected = whosCovered;
        this.dependentVal = this.whoseCoveredValue.dependents;
        this.childOnlyVal = this.whoseCoveredValue.child_only;
        this.whoseCoveredSelectedValue = this.getwhoseCoveredSelectedValue(whosCovered);
        this.isSubscriberOnly = whosCovered === DependentTypeEnum.subscriber ? true : false;
        this.changeDetector.detectChanges();
    }

    getwhoseCoveredSelectedValue(whosCovered) {
        if (whosCovered === DependentTypeEnum.child_only) {
            return DependentEnum.chilOnlyDependent;
        } else if (whosCovered === DependentTypeEnum.subscriber) {
            return DependentEnum.subscriberDependent;
        } else {
            return DependentEnum.familyDependent;
        }
    }

    prefillForm() {
        if (
            this.whoseCoveredGroup.controls &&
            this.whoseCoveredGroup.controls.whose_covered &&
            this.whoseCoveredGroup.controls.whose_covered.value
        ) {
            this.onChange({ target: { value: this.whoseCoveredGroup.controls.whose_covered.value } });
            this.changeDetector.detectChanges();
        } else {
            this.formCreated.emit();
        }
    }

    setupForms(whosCovered) {
        const persistValue = this.prefillVal.whoseCovered || cloneObject(this.whoseCoveredValue || {});

        const availableTypes = whosCovered.split('_');
        this.formControlService.removeAllControls(this.whoseCoveredGroup, { whose_covered: true });

        if (whosCovered !== 'child_only') {
            availableTypes.forEach((typeName) => {
                this[`add${capFirstLetter(typeName)}`](persistValue);
            });
        } else {
            this.addChildOnly(persistValue);
        }

        this.patchedValue = null;
    }

    getAge(dob: string): number {
        const today: Date = new Date();
        const birthDate: Date = new Date(dob);
        let age: number = today.getFullYear() - birthDate.getFullYear();
        const month: number = today.getMonth() - birthDate.getMonth();

        // due to how we format date values we need to compare UTC date to normal date. birthDate comes from FormControls
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getUTCDate())) {
            age -= 1;
        }

        return age;
    }

    showWhoseCoveredAdded(whoseCoveredSelected?: string) {
        this.showSubscriber = this.whoseCoveredValue.hasOwnProperty(DependentTypeEnum.subscriber);
        this.showSpouse = this.whoseCoveredValue.hasOwnProperty(DependentTypeEnum.spouse);
        this.showParent = this.whoseCoveredValue.hasOwnProperty(DependentTypeEnum.stepParents);
        this.showChildOnly =
            whoseCoveredSelected === DependentEnum.chilOnlyDependent || whoseCoveredSelected === DependentTypeEnum.child_only
                ? true
                : false;
        this.showChild = this.showChildOnly ? false : this.whoseCoveredValue.hasOwnProperty(DependentTypeEnum.dependents);
        this.setWhoseCoveredValue();
    }

    setWhoseCoveredValue() {
        if (this.showChildOnly) {
            this.showChild = !this.showChildOnly;
            this.whoseCoveredGroup.get(DependentTypeEnum.whose_covered).setValue(DependentTypeEnum.child_only);
        } else {
            const members = [];

            if (this.showSubscriber) members.push(CoveredMemberKey.subscriber);
            if (this.showSpouse) members.push(CoveredMemberKey.spouse);
            if (this.showChild) members.push(CoveredMemberKey.children);
            if (this.showParent) members.push(CoveredMemberKey.parent);
            const coveredMembers: string = members.join('_');
            this.whoseCoveredGroup.get(DependentTypeEnum.whose_covered).setValue(coveredMembers);
        }
        this.changeDetector.detectChanges();
    }

    buildMemberForm(selecteddependentType: string) {
        this.whoseCoveredSelectedValue = selecteddependentType;
        this.updateMemberFormControls(this.whoseCoveredSelectedValue);
        const persistValue = this.prefillVal.whoseCovered || cloneObject(this.whoseCoveredValue || {});

        if (selecteddependentType === DependentEnum.chilOnlyDependent) {
            this.addChildOnly(persistValue);
            this.showWhoseCoveredAdded(DependentTypeEnum.child_only);
        } else {
            this.isSubscriberOnly = selecteddependentType === DependentEnum.subscriberDependent ? true : false;
            this.addSubscriber(persistValue);
            this.showWhoseCoveredAdded(selecteddependentType);
        }
    }

    private updateMemberFormControls(whoseCoveredSelectedValue: string): void {
        this.formControlService.removeAllControls(this.whoseCoveredGroup, {
            subscriber: whoseCoveredSelectedValue !== DependentEnum.chilOnlyDependent,
            whose_covered: true,
        });
    }

    keepOrder(a, b) {
        return a.value?.position - b.value?.position;
    }
}
