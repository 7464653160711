import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { BaseCVAComponent } from './../base-cva.component';
import { termsAndConditions } from './terms-and-conditions.constants';

@Component({
    selector: 'terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TermsAndConditionsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TermsAndConditionsComponent),
            multi: true,
        },
    ],
})
export class TermsAndConditionsComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() group;
    @Input() config;

    termsAndConditions = termsAndConditions;
    valSub;
    tempGroup = new FormGroup({});

    constructor(public formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        if (this.config.label) {
            this.termsAndConditions['label'] = this.config.label;
        }

        if (this.config['acceptance']) {
            this.termsAndConditions.options[0].label = this.config['acceptance'];
        }

        if (this.config.prop) {
            this.termsAndConditions.prop = this.config.prop;
        }

        this.formControlService.addControlToFormGroup(this.group, {
            type: 'toggle',
            prop: this.termsAndConditions.prop,
            validators: this.termsAndConditions.validators,
        });
        this.formControlService.addControlToFormGroup(this.tempGroup, this.termsAndConditions);

        // keep the group up to date with the temporary value
        this.valSub = this.tempGroup.get(this.termsAndConditions.prop).valueChanges.subscribe((val) => {
            const checkboxVal = !!val[0];
            this.group.get(this.termsAndConditions.prop).patchValue(checkboxVal ? checkboxVal : null);
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
