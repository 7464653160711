import { UserError } from '@zipari/shared-sbp-models';

export enum ActionStates {
    ROUTE = 'route',
    CONTACT = 'contact',
    INTERNAL_ROUTE = 'INTERNAL_ROUTE',
    EXTERNAL_ROUTE = 'EXTERNAL_ROUTE',
    RETRY = 'RETRY',
}

export class ButtonConfig {
    content: string;
    level: string;
    prop: {
        action: ActionStates;
        route?: string;
        url?: string;
    };
}

export class GenericErrorConfig {
    codes?: number[];
    img?: string;
    header: {
        title: string | UserError;
        subtitle?: string;
    };
    buttons?: ButtonConfig[];
    footer?: {
        buttons: ButtonConfig[];
    };
}
