import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isObservable, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { ApplicationTypes, SelectedQLE, friendlyDateMap } from '@zipari/shared-sbp-constants';
import { ApiListResponse, County } from '@zipari/shared-sbp-models';
import { ConfigService } from '@zipari/shared-sbp-services';
import { CoverageEffectiveDateRange } from '@zipari/shared-sbp-templates';

export class ProductOption {
    id?: number;
    carrier?: string;
    on_exchange?: boolean;
    plan_type?: string;
    state?: string;
}
export class WindowShoppingYearConfig {
    windowShoppingYear?: number;
}

export enum validEnrollmentTypes {
    OE = 'OE',
    SEP = 'SEP',
}

export enum validCoverageEffectiveRetrievalTypes {
    OE = 'OE',
    SEP = 'SEP',
    SEP_ESTIMATE = 'SEP_ESTIMATE',
}

export interface SelectedQLEResponse {
    id: number;
    state: string;
    code: string;
    label: string;
    description: string;
    required_documents: {
        docTypes: Array<{
            label: string;
            value: string;
        }>;
        text: string;
        display: [];
    };
}

export class ProductOptionsQueryParams {
    plan_type?: string;
    plan_type__in?: string;
    enrollment_period: string;
    workflow_type: string | ApplicationTypes;
}

@Injectable({
    providedIn: 'root',
})
export class CoverageEffectiveDateService {
    formattedQLEs;
    cache = {};
    QLEeffectiveText: any;
    state_code: string;
    private dateSource = new Subject<any>();
    date$ = this.dateSource.asObservable();

    constructor(private http: HttpClient, private configService: ConfigService) {}

    /**
     * Return a formatted array containing coverage options for a given county
     * @param County
     */
    getCoverageOptionsByCounty(county: County): Observable<Array<{ label: string; value: unknown }>> {
        this.state_code = county.state_code;
        const queryParams = { county_code: county.county_code };
        const cache = JSON.stringify(queryParams);

        return this.handleCache(
            cache,
            this.http.get('/api/product_changes/product_options/', { params: queryParams }).pipe(shareReplay())
        ).pipe(
            map((coverages: ApiListResponse<{ plan_type: string; plan_type_display: string }>) => {
                const coverageSet: Set<string> = new Set();

                const filteredCoverageOptions = coverages.results.reduce((coverageOptions, coverage) => {
                    const isDuplicateType = coverageSet.has(coverage.plan_type);

                    !isDuplicateType &&
                        coverageOptions.push({
                            label: coverage.plan_type_display,
                            value: coverage.plan_type,
                        });

                    coverageSet.add(coverage.plan_type);
                    return coverageOptions;
                }, []);

                return filteredCoverageOptions;
            })
        );
    }

    isOpenEnrollmentPeriod(openEnrollmentRange: CoverageEffectiveDateRange): boolean {
        // if no configs, then return false.
        if (!openEnrollmentRange || (!!openEnrollmentRange && Object.entries(openEnrollmentRange).length === 0)) {
            return false;
        }

        return isTodayWithinDateRange(openEnrollmentRange.startDate, openEnrollmentRange.endDate);
    }

    /**
     *  Window shopping is a time period where everything is like Open Enrollment Period, except
     *  submit is disabled (submit is on the review step).
     *  To enable this, add the following config for a tenant:
     *  SP: individual.enrollment.demographics.windowShoppingRange: {"startDate": "09/15", "endDate": "10/31"}
     *  BP: individual.enrollment.eligibility.sep.windowShoppingRange: {"startDate": "09/15", "endDate": "10/31"}
     *  SP & BP: individual.enrollment.review.windowShoppingSubmitMessage: { "enabled": true }
     */
    isWindowShoppingPeriod(windowShoppingRange: CoverageEffectiveDateRange): boolean {
        // if no configs, then return false.
        if (!windowShoppingRange || (!!windowShoppingRange && Object.entries(windowShoppingRange).length === 0)) {
            return false;
        }

        return isTodayWithinDateRange(windowShoppingRange.startDate, windowShoppingRange.endDate);
    }

    isEffectiveDateInWindowShoppingPeriod(effectiveDateValue: string): boolean {
        // config may only exist in window_shopping cohort
        const pageConfig: WindowShoppingYearConfig = this.configService.getPageConfig('window_shopping');
        if (effectiveDateValue && pageConfig && pageConfig?.windowShoppingYear) {
            const effectiveYear = Number.parseInt(effectiveDateValue.substring(0, 4), 10);
            return effectiveYear === pageConfig.windowShoppingYear;
        }
        return false;
    }

    retrieveCoverageEffectiveDate(enrollmentPeriod: validEnrollmentTypes, planType: Array<string> = ['health'], workflow_type = '') {
        const queryParams: HttpParams = getProductOptionsQueryParams(enrollmentPeriod, planType, workflow_type);
        const cache = JSON.stringify(queryParams);
        return this.handleCache(cache, this.http.get('/api/product_changes/product_options/', { params: queryParams }).pipe(shareReplay()));
    }

    public retrieveQLEs() {
        if (this.formattedQLEs) {
            return of(this.formattedQLEs);
        } else {
            return this.http.get('api/product_changes/qle/?page_size=20').pipe(
                shareReplay(),
                map((response) => {
                    this.formattedQLEs = this.reformatQLEBasedOnCode(response['results'] || []);
                    return this.formattedQLEs;
                })
            );
        }
    }

    public getQLEConfig(selectedQLE: SelectedQLE): Observable<SelectedQLEResponse> {
        const params = {
            code: selectedQLE.qle,
        };

        if (selectedQLE.state) {
            params['state'] = selectedQLE.state;
        }

        return this.http
            .get<ApiListResponse<SelectedQLEResponse>>('api/product_changes/qle/', {
                params,
            })
            .pipe(
                map((response: ApiListResponse<SelectedQLEResponse>) => {
                    if (selectedQLE.id) {
                        if (response.results.length > 1) {
                            return response.results.find((qle) => qle.id === selectedQLE.id);
                        } else if (response.results.length === 1) {
                            // when a secondary qle is selected, the results length is always 1
                            return response.results[0];
                        }
                    }

                    return response.results.length ? response.results[0] : <SelectedQLEResponse>{};
                })
            );
    }

    public reformatQLEBasedOnCode(results) {
        const formattedResults = {};

        results.forEach((result) => {
            if (!formattedResults[result.code]) {
                formattedResults[result.code] = result;
            }
        });

        return formattedResults;
    }

    handleCache(url, defaultObservable: Observable<any>) {
        if (this.cache[url]) {
            return isObservable(this.cache[url]) ? this.cache[url] : of(this.cache[url]);
        } else {
            this.cache[url] = defaultObservable.pipe(
                tap((response) => {
                    this.cache[url] = response;
                })
            );

            return this.cache[url];
        }
    }

    emitDate(date: any) {
        this.dateSource.next(date);
    }

    setQLECoverageEffectiveText(text) {
        this.QLEeffectiveText = text;
    }

    returnQLECoverageEffectiveText(): any {
        return !!this.QLEeffectiveText ? this.QLEeffectiveText : '';
    }

    getValidEffectiveDates(config, numberOfDates) {
        const effectiveDateOptions = [];

        // Allow client to determine the first available effective date, YYYY-MM-DD
        const date = config.firstEffectiveDate ? new Date(config.firstEffectiveDate) : new Date();

        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        for (let i = 1; i < numberOfDates + 1; i++) {
            let year = currentYear;
            let month = currentMonth + i;
            if (month > 11) {
                year = year + 1;
                month = month - 12;
            }

            if (!(config.excludedYears || []).includes(year)) {
                const label = `${friendlyDateMap[month]}, ${year}`;
                const stringMonth = month + 1 > 9 ? String(month + 1) : '0' + String(month + 1);
                const value = `${year}-${stringMonth}-01`;
                effectiveDateOptions.push({ label, value });
            }
        }

        return effectiveDateOptions;
    }
}

export const getProductOptionsQueryParams = (
    enrollmentPeriod: validEnrollmentTypes,
    planType: Array<string> | string = ['health'],
    workflow_type = ''
): HttpParams => {
    const enrollmentPeriodMap = {
        [validEnrollmentTypes.OE]: 'open',
        [validEnrollmentTypes.SEP]: 'special',
    };

    if (Array.isArray(planType)) {
        planType = planType.filter((type) => typeof type === 'string' && !!type);
    }

    const queryParams: ProductOptionsQueryParams = {
        enrollment_period: enrollmentPeriodMap[enrollmentPeriod],
        workflow_type, // should correspond to this.workflow.schema.config.application_type
    };

    // BE looks for a different param depending on whether we are passing one or multiple plan types
    if (typeof planType === 'string') {
        queryParams.plan_type = planType;
    } else if (Array.isArray(planType) && planType.length === 1) {
        queryParams.plan_type = planType[0];
    } else if (Array.isArray(planType)) {
        queryParams.plan_type__in = planType.join(',');
    }

    if (!queryParams['enrollment_period']) {
        queryParams['enrollment_period'] = workflow_type && workflow_type === ('renewal' || 'upsell') ? 'open' : '';
    }

    // defaulting medical to health as that's what our tenants use.
    if (queryParams.plan_type === 'medical') {
        queryParams.plan_type = 'health';
    }

    return queryParams as unknown as HttpParams;
};

export const isTodayWithinDateRange = (start: string, end: string): boolean => {
    const today: Date = new Date();
    return isDateWithinRange(start, end, today);
};

export const isDateWithinRange = (start: string, end: string, date: Date): boolean => {
    const currentYear: number = date.getUTCFullYear();
    const DATE_INCL_YEAR_MIN_LENGTH: number = 8; // m/d/yyyy
    let startDate: Date;
    let endDate: Date;

    // tslint:disable-next-line: no-magic-numbers
    const END_OF_DAY: Array<number> = [23, 59, 59, 999]; // End of day arguments for Date.setHours()

    if (start.length >= DATE_INCL_YEAR_MIN_LENGTH) {
        // If the date is in format m/d/yyyy
        startDate = new Date(start);
        endDate = new Date(end);
    } else {
        // If the date is in format m/d (deprecated format), derive the year
        const currentYearStart: Date = new Date(`${start}/${currentYear}`);
        const currentYearEnd: Date = new Date(`${end}/${currentYear}`);
        currentYearEnd.setHours.apply(currentYearEnd, END_OF_DAY);

        if (currentYearStart <= currentYearEnd) {
            // start and end are in current year
            startDate = currentYearStart;
            endDate = currentYearEnd;
        } else if (date <= currentYearEnd) {
            // start is in previous year, example start: 12/1, end: 1/31, today: 1/5
            startDate = new Date(`${start}/${currentYear - 1}`);
            endDate = currentYearEnd;
        } else {
            // end is in next year, example start: 12/1, end: 1/31, today: 12/5
            startDate = currentYearStart;
            endDate = new Date(`${end}/${currentYear + 1}`);
        }
    }

    endDate.setHours.apply(endDate, END_OF_DAY);

    const isDateAfterStart: boolean = date >= startDate;
    const isDateBeforeEnd: boolean = date <= endDate;

    return isDateAfterStart && isDateBeforeEnd;
};
