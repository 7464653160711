import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { accordionX, fade, FormattingService, FormControlService, slideRight } from '@zipari/design-system';
import { ZipGoogleMapService } from '@zipari/shared-sbp-modules';
import { BuildQueryPipe, cloneObject, getValue, isEmptyObj } from '@zipari/web-utils';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import {
    FilterOption,
    FilterResponse,
    LocationPin,
    Member,
    PersistentFilterPayload,
    PreFiltersResponse,
    ProviderSearchFooterButtons,
    ProviderSearchResponse,
    SelectedProvidersConfig,
    UpdatedFilterDataConfig,
} from '@zipari/shared-sbp-models';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { AnalyticsService, ConfigService, LoggerService } from '@zipari/shared-sbp-services';
import { Subscription } from 'rxjs';
import { SearchService } from '../search.service';
import { AsideHeaderConfig, ProviderResult } from './provider-result.model';
import {
    providerSearchCoveredTypes,
    providerSearchTypes,
    FacilityFiltersKey,
    providerSearchFooterButtons,
    facilityFilterControlProp,
    defaultPCPModalConfig,
    defaultFiltersModalConfig,
} from './provider-search.constant';
import { SearchBarConfig } from '../search-bar/search-bar-config.interface';

@Component({
    selector: 'provider-search',
    templateUrl: './provider-search.component.html',
    styleUrls: ['./provider-search.component.scss'],
    animations: [accordionX, fade, slideRight],
})
export class ProviderSearchComponent implements OnInit {
    @Input() queryParams: string;
    @Input() stepConfig;
    @Input() workflow;
    @Input() initialDistance: number;
    @Output() previousStep = new EventEmitter();
    @Output() completeStep = new EventEmitter();
    @Output() saveAndExit = new EventEmitter();

    @ViewChild('scrollToSearchResults') scrollToSearchResults: ElementRef;

    preFiltersResponse: PreFiltersResponse;
    backendResponse: ProviderSearchResponse;
    hospitalAffiliationsOptions: string[] | FilterOption[];
    hospitalAffiliationParamID: string | number | boolean;
    hospitalAffiliationParamLabel: string;
    medicalGroupsOptions: string[] | FilterOption[];
    medicalGroupsParamID: string | number | boolean;
    medicalGroupsParamLabel: string;
    backendResults: ProviderResult[] = [];
    isAPILoading: boolean = true;
    selectedProviders: SelectedProvidersConfig;
    showDetails: boolean;
    busy: Subscription;
    facilityTypeOptions: string[];
    facilityTypeParamLabel: string;
    languageParamLabel: string;
    public config;
    public searchBarConfig: SearchBarConfig;
    asideHeaderConfig: AsideHeaderConfig;
    public filtersForm: FormGroup;
    public showFilters: boolean = false;
    selectedResult: ProviderResult;
    selectedResultIdx: number;
    pins: LocationPin[] = [];
    members: Member[] = [];
    memberType: string;
    showFiltersModal: boolean;
    showPCPModal: boolean;
    pcpModalConfig: ModalConfig;
    filtersModalConfig: ModalConfig;
    specialtiesConfig: AllControlsConfiguration[];
    hospitalAffiliationsConfig: AllControlsConfiguration[];
    medicalGroupsConfig: AllControlsConfiguration[];
    facilityTypeConfig: AllControlsConfiguration[];
    languageConfig: AllControlsConfiguration[];
    buttonConfig: ProviderSearchFooterButtons;
    defaultFooterButtons: ProviderSearchFooterButtons = providerSearchFooterButtons;

    zipcode: string;
    distance: number;
    name: string = '';
    plan_id: string;
    updatedFilterOption: FilterOption;
    specialty: string;
    specialtyCode: string | number | boolean;
    persistentFilterParamString: string;
    persistentFilterPayload: PersistentFilterPayload = {};

    searchLat: number;
    searchLng: number;

    currentPage: number = 1;
    pageLength: number = 10;
    lastPage: number;
    totalResultsCount: number = 0; // represents count property recieved from response

    workflowId;
    workflowValues;
    contactConfigs = [];
    profileConfigs = [];
    educationAndTrainingConfigs = [];
    whichWorkflow: string;
    facilityPage: number = 1;
    showFilterApi = false;

    constructor(
        public loggerService: LoggerService,
        private http: HttpClient,
        private formControlService: FormControlService,
        private configService: ConfigService,
        private searchService: SearchService,
        private buildQuery: BuildQueryPipe,
        private zipMapService: ZipGoogleMapService,
        public analyticsService: AnalyticsService,
        public formattingService: FormattingService
    ) {}

    get paginatorConfig() {
        const resultsPerPage = this.pageLength;
        const startingPageNumber: number = this.currentPage * resultsPerPage - resultsPerPage + 1;
        const endingPageNumber: number =
            startingPageNumber <= this.totalResultsCount - resultsPerPage
                ? startingPageNumber + resultsPerPage - 1
                : this.totalResultsCount;
        const paginationConfig = {
            rowsPerPage: resultsPerPage,
            showingStart: startingPageNumber,
            showingEnd: endingPageNumber,
            currentPage: this.currentPage,
            totalPages: Math.ceil(this.totalResultsCount / resultsPerPage),
            totalResults: this.totalResultsCount,
        };
        return paginationConfig;
    }

    public get resultIsSelected(): boolean {
        return !!this.backendResults.find((r) => r.selected);
    }

    public get isPcpNotSelected(): boolean {
        let isNextDisabled: boolean = true;
        this.members.forEach(({ isPCPSelected }) => {
            if (isPCPSelected) {
                isNextDisabled = false;
            }
        });
        return isNextDisabled;
    }

    public get removePCPavatar(): boolean {
        return this.config['removePCPavatar'];
    }

    public get showFiltersMembersAside(): boolean {
        return !this.showFilters && !this.config.hideMembers;
    }

    public get showFiltersAside(): boolean {
        return !!this.showFilters && this.showFilterApi;
    }

    get showFiltersClose(): boolean {
        if (!!this.config.hideMembers && this.whichWorkflow === providerSearchTypes.facility) {
            return false;
        }
        return !!this.showFiltersAside;
    }

    get currentMember(): Member {
        return this.members.find((member: Member) => member.isMemberSelected);
    }

    ngOnInit() {
        this.whichWorkflow =
            this.stepConfig?.workflowName === providerSearchTypes.facility ? providerSearchTypes.facility : providerSearchTypes.doctor;
        this.config = this.stepConfig || this.configService.getPageConfig(this.whichWorkflow);
        this.preFiltersResponse = {};
        this.filtersModalConfig = { ...defaultFiltersModalConfig, ...this.config.filtersModalConfig };
        this.pcpModalConfig = { ...defaultPCPModalConfig, ...this.config.pcpModalConfig };

        this.initFields();
        this.setupFiltersForm();

        if (this.config.isMedicare) {
            this.selectConsumer(this.members, 0);
        }

        if (!!this.config.hideMembers && this.whichWorkflow === providerSearchTypes.facility) {
            this.showFilters = true;
        }
    }

    initFields() {
        this.workflowValues = this.workflow.values;
        this.workflowId = this.workflow.id;

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.selectedProviders = this.workflowValues?.pcp && !isEmptyObj(this.workflowValues.pcp) ? this.workflowValues.pcp : {};
        } else if (this.whichWorkflow === providerSearchTypes.facility) {
            this.selectedProviders = this.workflowValues?.pcc && !isEmptyObj(this.workflowValues.pcc) ? this.workflowValues.pcc : {};
        }

        this.zipcode = this.config.isMedicare
            ? this.workflowValues.subscriber.address.zip_code
            : this.workflowValues.permanent_address.zipcode;

        // when user updates zipcode in the searchbar, store it in the workflow, and use it upon step return
        // otherwise just use the zipcode for the address
        if (this.whichWorkflow === providerSearchTypes.doctor) {
            const userEnteredZipcode = !!this.workflowValues?.pcp?.pcpSearchZipcode
                ? this.workflowValues.pcp.pcpSearchZipcode
                : this.zipcode;
            this.zipcode = userEnteredZipcode;
        } else if (this.whichWorkflow === providerSearchTypes.facility) {
            const userEnteredZipcode = !!this.workflowValues?.pcc?.pccSearchZipcode
                ? this.workflowValues.pcc.pccSearchZipcode
                : this.zipcode;
            this.zipcode = userEnteredZipcode;
        }

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            const userSelectedDistance = !!this.workflowValues?.pcp?.pcpSearchDistance
                ? this.workflowValues.pcp.pcpSearchDistance
                : this.distance;
            this.distance = userSelectedDistance;
        } else if (this.whichWorkflow === providerSearchTypes.facility) {
            const userSelectedDistance = !!this.workflowValues?.pcc?.pcpSearchDistance
                ? this.workflowValues.pcc.pcpSearchDistance
                : this.distance;
            this.distance = userSelectedDistance;
        }

        this.searchBarConfig = this.config.searchBarConfig;
        this.pageLength = this.config?.pageLength ? this.config?.pageLength : this.pageLength;
        this.buttonConfig = this.config.buttonConfig;
        const filterFormControls = this.config.filters.groups[0]?.controls;

        this.specialtiesConfig = this.getFilterFormOptions(filterFormControls, facilityFilterControlProp.specialties);
        this.hospitalAffiliationsConfig = this.getFilterFormOptions(filterFormControls, facilityFilterControlProp.hospitalAffiliations);
        this.medicalGroupsConfig = this.getFilterFormOptions(filterFormControls, facilityFilterControlProp.medicalGroups);
        this.facilityTypeConfig = this.getFilterFormOptions(filterFormControls, facilityFilterControlProp.facilityType);
        this.languageConfig = this.getFilterFormOptions(filterFormControls, facilityFilterControlProp.language);

        this.asideHeaderConfig = this.config.asideHeaderConfig;
        this.setMemberDetails();
        this.applySelectedPCP();

        if (!this.workflowValues.child_only) {
            this.memberType = providerSearchCoveredTypes.subscriber;
            if (this.whichWorkflow === providerSearchTypes.doctor) {
                this.getPrefliterResults(this.workflowId, this.memberType);
            } else {
                this.getFilterResultsFacility();
            }
        }

        if (this.workflowValues.child_only) {
            this.memberType = 'dependent_0';
            if (this.whichWorkflow === providerSearchTypes.doctor) {
                this.getPrefliterResults(this.workflowId, this.memberType);
            } else {
                this.getFilterResultsFacility();
            }
        }

        let modelAttributeConfigs = {};
        // setup model-attribute configs for detail view.
        if (this.config.providerDetailConfigs) modelAttributeConfigs = this.config.providerDetailConfigs['modelAttributeConfigs'];

        this.contactConfigs = modelAttributeConfigs['contact'];
        this.profileConfigs = modelAttributeConfigs['profile'];
        this.educationAndTrainingConfigs = modelAttributeConfigs['educationAndTraining'];
    }

    private getFilterFormOptions(filterFormControls: AllControlsConfiguration[], filterFormKey: string): AllControlsConfiguration[] {
        return filterFormControls.filter((control) => control.prop === filterFormKey);
    }

    getPrefliterResults(workflowId, memberType) {
        const prefilterEndpoint = `${this.config.preFilterEndpoint}?workflow_id=${workflowId}&member=${memberType}`;

        this.busy = this.http.get(prefilterEndpoint).subscribe((response) => {
            this.preFiltersResponse = response;
            this.specialtiesConfig[0].options = [];

            this.preFiltersResponse.results.forEach((result) => {
                result.value = result.id;
                result.label = result.name;
                delete result.id;
                delete result.name;
                this.specialtiesConfig[0]?.options.push(result);
            });

            this.showFilterApi = true;

            this.zipMapService.IsGoogleMapAPILoaded().then(() => {
                this.zipMapService.GetLatLngFromAddress(`zipcode +${this.zipcode}`).then((latlng) => {
                    this.searchLat = latlng.lat();
                    this.searchLng = latlng.lng();
                    this.plan_id = this.preFiltersResponse.external_id;
                    this.updateResults();
                });
            });
        });
    }

    getFilterResultsFacility() {
        const filterEndpoint = this.config.filterEndpoint;

        this.busy = this.http.get(filterEndpoint).subscribe((response) => {
            const facilityFilters = response['filters']?.['facility'];

            if (facilityFilters) {
                // reset config options before re-adding them
                !!this.medicalGroupsConfig.length ? (this.medicalGroupsConfig[0].options = []) : null;
                !!this.facilityTypeConfig.length ? (this.facilityTypeConfig[0].options = []) : null;
                !!this.specialtiesConfig.length ? (this.specialtiesConfig[0].options = []) : null;
                !!this.languageConfig.length ? (this.languageConfig[0].options = []) : null;

                facilityFilters.forEach((facilityFilter: FilterResponse) => {
                    if (facilityFilter.key === FacilityFiltersKey.medicalGroup && facilityFilter.options.length > 0) {
                        this.medicalGroupsOptions = facilityFilter.options;
                        this.formatControlOptions(facilityFilter, this.medicalGroupsConfig);
                    }
                    if (facilityFilter.key === FacilityFiltersKey.facilityType && facilityFilter.options.length > 0) {
                        this.facilityTypeOptions = facilityFilter.options;
                        this.formatControlOptions(facilityFilter, this.facilityTypeConfig);
                    }
                    if (facilityFilter.key === FacilityFiltersKey.specialtyGroup && facilityFilter.options.length > 0) {
                        this.formatControlOptions(facilityFilter, this.specialtiesConfig);
                    }
                    if (facilityFilter.key === FacilityFiltersKey.language && facilityFilter.options.length > 0) {
                        this.formatControlOptions(facilityFilter, this.languageConfig);
                    }
                });
            }

            this.showFilterApi = true;

            this.zipMapService.IsGoogleMapAPILoaded().then(() => {
                this.zipMapService.GetLatLngFromAddress(`zipcode +${this.zipcode}`).then((latlng) => {
                    this.searchLat = latlng.lat();
                    this.searchLng = latlng.lng();
                    this.updateResultsFacility();
                });
            });
        });
    }

    formatControlOptions(facilityFilter: FilterResponse, config: AllControlsConfiguration[]): void {
        const filterKey: string[] = [FacilityFiltersKey.medicalGroup, FacilityFiltersKey.language];

        this.facilityTypeOptions = facilityFilter.options;
        this.facilityTypeOptions.forEach((label: string, index: number) => {
            const value: string | number = filterKey.includes(facilityFilter.key) ? label : index;
            config[0]?.options.push({
                value: value,
                label: label,
            });
        });
    }

    public get numberOfFilters() {
        let filterNumber = 0;

        Object.values(this.filtersForm.value).forEach((group) => {
            if (group !== null) {
                Object.values(group).forEach((filter) => {
                    if (filter) {
                        filterNumber += 1;
                    }
                });
            }
        });

        return filterNumber;
    }

    updateResults(): void {
        this.isAPILoading = true;
        this.showFilters = false;

        this.busy = this.http.get(this.fullRequest()).subscribe(
            (response: ProviderSearchResponse) => {
                this.totalResultsCount = response.count;
                this.backendResponse = response;
                this.backendResults = this.buildDoctorResults(this.backendResponse['results']);
                if (this.medicalGroupsConfig.length) {
                    this.medicalGroupsConfig[0].options = [];
                }

                if (this.hospitalAffiliationsConfig.length) {
                    this.hospitalAffiliationsConfig[0].options = [];
                }

                if (this.backendResponse.filters) {
                    this.backendResponse.filters.forEach((filter) => {
                        if (filter.filter_type === facilityFilterControlProp.medicalGroups && this.medicalGroupsConfig.length) {
                            this.medicalGroupsOptions = filter.choices;
                            this.medicalGroupsConfig[0].options = this.medicalGroupsOptions?.map(
                                (medicalGroupsOption) => medicalGroupsOption
                            );
                        }

                        if (
                            filter.filter_type === facilityFilterControlProp.hospitalAffiliations &&
                            this.hospitalAffiliationsConfig.length
                        ) {
                            this.hospitalAffiliationsOptions = filter.choices;
                            this.hospitalAffiliationsConfig[0].options = this.hospitalAffiliationsOptions?.map(
                                (hospitalAffiliationsOption) => hospitalAffiliationsOption
                            );
                        }
                    });
                }

                let medicalGroupsConfigOptions = getValue(this.medicalGroupsConfig, '0.controls.0.options');
                let hospitalAffiliationsConfigOptions = getValue(this.hospitalAffiliationsConfig, '0.controls.0.options');

                medicalGroupsConfigOptions = this.medicalGroupsOptions ? this.medicalGroupsOptions : [];
                hospitalAffiliationsConfigOptions = this.hospitalAffiliationsOptions ? this.hospitalAffiliationsOptions : [];

                this.pins = this.buildDoctorPins(this.backendResults);
                this.backendResults = this.determineSelectedPCP(this.backendResults, this.selectedProviders);
                this.searchService.hasProviderResults = !!this.backendResults.length;
                this.isAPILoading = false;
            },
            (err) => {
                this.loggerService.error(err);

                this.isAPILoading = false;
                this.backendResults = [];
                this.searchService.hasProviderResults = !!this.backendResults.length;
                this.pins = [];
            }
        );
    }

    updateResultsFacility(): void {
        this.isAPILoading = true;

        this.busy = this.http.get(this.fullRequestFacility()).subscribe(
            (response: ProviderSearchResponse) => {
                this.totalResultsCount = response.count;
                this.backendResponse = response;
                this.backendResults = this.buildFacilityResults(this.backendResponse['results']);
                // this.pins = this.buildDoctorPins(this.backendResults);
                this.backendResults = this.determineSelectedPCP(this.backendResults, this.selectedProviders);
                this.searchService.hasProviderResults = !!this.backendResults.length;
                this.isAPILoading = false;
            },
            (err) => {
                this.loggerService.error(err);

                this.isAPILoading = false;
                this.backendResults = [];
                this.searchService.hasProviderResults = !!this.backendResults.length;
                this.pins = [];
            }
        );
    }

    fullRequestFacility(): string {
        let request: string = this.stepConfig.endpoint;

        this.distance = this.distance
            ? this.distance // default to minimum; sent undefined the first time otherwise
            : getValue(this.searchBarConfig, 'searchConfig.locationConfig.within.0');

        // MA has many "health" plan types, this will return the non-ancillary plan in the WF
        const selectedPlan = getValue(this.workflowValues, 'plans');
        // get the types of plans and return the first type that isn't dental or vision (this should be one of the "health" plan types)
        const selectedMedicalPlanType: string = Object.keys(selectedPlan)
            .filter((planType) => planType !== ('dental' || 'vision'))
            .shift();

        // it seems that the plans are saved as an array of the type in MA
        // i.e. plans: {mapd: [{...planData}]}
        // in IFP it's structured as like this: {medical: {...planData}}
        const flattenedPlan = selectedPlan[selectedMedicalPlanType]?.[0];

        const network_code: string =
            !!selectedMedicalPlanType && !!flattenedPlan
                ? getValue(flattenedPlan, 'network_codes.0')
                : getValue(selectedPlan, `${selectedMedicalPlanType}.network_codes.0`);

        const params: Array<any> = [
            { page: this.facilityPage },
            { page_size: this.pageLength ? this.pageLength : 10 },
            { entity_type: 'facility' },
            { latitude: this.searchLat.toString() },
            { longitude: this.searchLng.toString() },
            { distance: this.distance.toString() },
            { network_code: network_code },
            { is_pcp: true },
        ];

        if (!!this.specialty) {
            params.push({ specialty_group: this.specialty });
        }

        if (!!this.facilityTypeParamLabel) {
            params.push({ facility_type: this.facilityTypeParamLabel });
        }

        if (!!this.languageParamLabel) {
            params.push({ language: this.languageParamLabel });
        }

        if (!!this.medicalGroupsParamLabel) {
            params.push({ medical_group: this.medicalGroupsParamLabel });
        }

        if (!!this.name) {
            params.push({ search: this.name });
        }

        if (!!this.zipcode) {
            params.push({ zipcode: this.zipcode });
        }

        for (const filter in this.persistentFilterPayload) {
            if (!!this.persistentFilterPayload[filter]) {
                params.push({ [filter]: !!this.persistentFilterPayload[filter] });
            }
        }

        request = this.buildQuery.transform(request, params);
        return request;
    }

    fullRequest(): string {
        let request: string = this.stepConfig.endpoint;
        this.distance = this.distance
            ? this.distance // default to minimum; sent undefined the first time otherwise
            : getValue(this.searchBarConfig, 'searchConfig.locationConfig.within.0');
        const params: Array<any> = [
            { workflow_id: this.workflowId },
            { member: this.memberType },
            { page: this.currentPage },
            { page_size: this.pageLength },
            { latitude: this.searchLat },
            { longitude: this.searchLng },
            { zipcode: this.zipcode },
            { distance: this.distance },
            { plan_id: this.plan_id },
        ];

        if (!!this.medicalGroupsParamID) {
            const medicalGroup = {
                medical_group_id: this.medicalGroupsParamID,
                medical_group_name: this.medicalGroupsParamLabel,
            };
            params.push(medicalGroup);
        }
        if (!!this.hospitalAffiliationParamID) {
            const hospitalAffiliation = {
                hospital_affiliation_id: this.hospitalAffiliationParamID,
                hospital_affiliation_name: this.hospitalAffiliationParamLabel,
            };
            params.push(hospitalAffiliation);
        }

        request = this.buildQuery.transform(request, params);

        !!this.specialty ? (request += `&specialties=${this.specialty}`) : request;
        !!this.specialtyCode ? (request += `&specialty_code=${this.specialtyCode}`) : request;
        !!this.name ? (request += `&name=${this.name}`) : request;
        if (!!this.persistentFilterParamString) request += this.persistentFilterParamString;

        return request;
    }

    toggleFilters() {
        this.showFilters = !this.showFilters;
        this.showFiltersModal = !this.showFiltersModal;
    }

    getProviderDetail(member: Member): string {
        return this.searchService.getProviderDetail(member, this.whichWorkflow);
    }

    doctorsFoundMessage(): string {
        return `${this.totalResultsCount} Doctor(s) found within ${this.distance} miles of ${this.zipcode}, with ${this.numberOfFilters} filter(s)`;
    }

    selectedProviderName(member: Member): string {
        const selectedProviderFullName = `${member.selectedProvider.first_name ?? ''} ${member.selectedProvider.last_name ?? ''}`;

        return this.whichWorkflow === providerSearchTypes.doctor ? `${selectedProviderFullName}, MD` : selectedProviderFullName;
    }

    selectedConsumer({ members, index }): void {
        this.selectConsumer(members, index);
    }

    setupFiltersForm(): void {
        this.filtersForm = new FormGroup({});
        const filtersFormControls = this.stepConfig.filters?.groups[0]?.controls || null;

        Object.keys(filtersFormControls).forEach((filterKey: string) => {
            this.formControlService.addControlToFormGroup(this.filtersForm, filtersFormControls[filterKey]);
        });
        filtersFormControls.forEach((control: AllControlsConfiguration) =>
            this.formControlService.addFormGroupToFormGroup(this.filtersForm, control)
        );
    }

    /***
     * Added method to filter on filter button click instead of every changes on form value
     */
    public applyFilter(): void {
        this.whichWorkflow === providerSearchTypes.doctor
            ? this.handleFilterChange(this.filtersForm.value)
            : this.handleFilterChangeFacility(this.filtersForm.value);
    }

    handleFilterChange(change: UpdatedFilterDataConfig): void {
        let filterParamString: string = '';
        this.handleFilterChangeValue(change);

        const new_patients =
            change?.accepting_new_patients || change?.filters?.accepting_new_patients
                ? `&accepting_new_patients=${change?.accepting_new_patients || change?.filters?.accepting_new_patients}`
                : '';
        const gender: string = change?.gender || change?.filters?.gender ? `&gender=${change?.gender || change?.filters.gender}` : '';
        const language: string =
            change?.language || change?.filters?.language ? `&language=${change?.language || change?.filters.language}` : '';
        const ordering: string =
            change?.sort_by || change?.filters?.sort_by ? `&ordering=${change?.sort_by || change?.filters.sort_by}` : '';

        const filterParamsArray: Array<any> = [new_patients, gender, language, ordering];

        filterParamsArray.forEach((param: string) => {
            if (!!param) {
                filterParamString += param;
            }
        });

        this.persistentFilterParamString = filterParamString;

        this.currentPage = 1;
        this.updateResults();
    }

    handleFilterChangeFacility(change: UpdatedFilterDataConfig): void {
        this.handleFilterChangeValue(change);

        this.persistentFilterPayload = {
            accepting_new_patients: change.accepting_new_patients[0],
            wheelchair_access: change.wheelchair_access[0],
        };

        this.currentPage = 1;
        this.updateResultsFacility();
    }

    handleFilterChangeValue(change: UpdatedFilterDataConfig): void {
        this.updateFilterOption(change.specialties, this.specialtiesConfig, (option) => {
            this.specialty = option?.label || '';
            this.specialtyCode = option?.value || '';
        });

        this.updateFilterOption(change.medical_groups, this.medicalGroupsConfig, (option) => {
            this.medicalGroupsParamLabel = option?.label || '';
            this.medicalGroupsParamID = option?.value || '';
        });

        this.updateFilterOption(change.facility_type, this.facilityTypeConfig, (option) => {
            this.facilityTypeParamLabel = option?.label || '';
        });

        this.updateFilterOption(change.language, this.languageConfig, (option) => {
            this.languageParamLabel = option?.label || '';
        });

        this.updateFilterOption(change.hospital_affiliations, this.hospitalAffiliationsConfig, (option) => {
            this.hospitalAffiliationParamLabel = option?.label || '';
            this.hospitalAffiliationParamID = option?.value || '';
        });
    }

    private updateFilterOption(
        filterValue: number | string,
        filterConfig: AllControlsConfiguration[],
        updateFilterCallback: (option: FilterOption | undefined) => void
    ): void {
        const updatedFilterOption = this.findFilterOption(filterConfig, filterValue);
        updateFilterCallback(updatedFilterOption);
    }

    findFilterOption(config: AllControlsConfiguration[], filterValue: string | number): FilterOption {
        const filterOption: FilterOption = config[0]?.options?.find((option: FilterOption) => {
            return option.value === filterValue;
        });

        return filterOption;
    }

    clearFilters(): void {
        this.currentPage = 1;
        this.specialty = '';
        this.specialtyCode = '';

        // reset medical group and hospital affiliations params
        this.medicalGroupsParamLabel = null;
        this.medicalGroupsParamID = null;
        this.hospitalAffiliationParamID = null;

        this.persistentFilterParamString = null;

        // reset facility filters
        this.facilityTypeParamLabel = null;
        this.languageParamLabel = null;

        this.filtersForm.reset();

        Object.keys(this.filtersForm?.controls).forEach((filterKey: string) => {
            this.filtersForm.get(filterKey).markAsPristine();
            this.filtersForm.get(filterKey).markAsUntouched();
        });

        this.whichWorkflow === providerSearchTypes.doctor ? this.updateResults() : this.handleFilterChangeFacility(this.filtersForm.value);
    }

    handleFilters(filterActionType: 'clear' | 'apply'): void {
        if (this.showFiltersMembersAside || this.showFiltersModal) this.toggleFilters();
        filterActionType == 'apply' ? this.applyFilter() : this.clearFilters();
    }

    buildDoctorResults(rawResults: any[]) {
        return rawResults.map((result) => ({
            ...result,
            gender: result.gender,
            location: {
                street_name_1: `${result.address.street_name_1} ${result.address.street_name_2 ? result.address.street_name_2 : ''}`,
                city_name: result.address.city_name,
                state: result.address.state,
                zip_code: result.address.zip_code,
                coordinates: {
                    latlng: this.getDoctorCoordinates(result.address.location),
                },
            },
            network_specific_id: result.network_specific_id,
            pcpName: {
                first: result.first_name,
                last: result.last_name,
            },
            medical_group: result.medical_groups.length > 0 ? result.medical_groups[0].name : '',
            phone: result.phone
                ? this.formattingService.restructureValueBasedOnFormat(result.phone.toString(), {
                      format: 'PHONE',
                  })
                : '',
            picture:
                result.gender === 'M'
                    ? 'https://d32ul9oyxvd2n5.cloudfront.net/assets/maleDoctor.svg'
                    : 'https://d32ul9oyxvd2n5.cloudfront.net/assets/femaleDoctor.svg',
            selected: false,
            medical_groups: result?.medical_groups?.map((medicalGroup) => medicalGroup.name)?.join('<br>') || '',
        }));
    }

    buildFacilityResults(rawResults: Array<any>) {
        return rawResults.map((result) => {
            return {
                ...result,
                location: {
                    street_name_1: result.facility_locations
                        ?.map((facility_location) => facility_location?.address?.street_name_1)
                        .join(','),
                    city_name:
                        result.facility_locations.length > 0
                            ? result.facility_locations?.map((facility_location) => facility_location?.address?.city_name).join(',')
                            : result.facility_locations?.map((facility_location) => facility_location?.address?.city).join(','),
                    state: result.facility_locations?.map((facility_location) => facility_location?.address?.state).join(','),
                    zip_code: result.facility_locations?.map((facility_location) => facility_location?.address?.zip_code).join(','),
                },
                network_specific_id: result.facility_locations?.map((facility_location) => facility_location.network_specific_id).join(','),
                // medical_groups: result.facility_location.medical_groups?.map(medical_groups => medical_groups.name).join(','),
                plans: result?.internal_network_names,
                facility_type: result.facility_locations?.map((facility_location) => facility_location.type?.name).join(','),
                phone: this.formattingService.restructureValueBasedOnFormat(
                    result.facility_locations
                        ?.map((phone_numbers) => (phone_numbers?.phone_number ? phone_numbers?.phone_number.toString() : ''))
                        .join(','),
                    { format: 'PHONE' }
                ),
                selected: false,
                accepting_new_patients: result.facility_locations.map(
                    (facility_location) => facility_location.administration_attributes?.accepting_new_patients
                ),
                distance: result?.distance ? result.distance.toFixed(2) : '',
                name: { first_name: result.facility_locations?.map((facility_location) => facility_location.name).join(',') },
                facility_id: result.facility_locations?.map((facility_location) => facility_location.facility_id).join(','),
                languages: result.facility_locations?.map((facility_location) => facility_location.languages),
                specialties: result?.specialty_group,
            };
        });
    }

    getDoctorCoordinates(doctorLocation: { latitude: number; longitude: number }) {
        if (doctorLocation) {
            const lnglat = {
                lat: doctorLocation.latitude,
                lng: doctorLocation.longitude,
            };

            return this.searchService.areValidCoordinates(lnglat) ? lnglat : null;
        }
        return null;
    }

    buildDoctorPins(results: Array<any>) {
        return results.map((result, idx) => {
            return {
                infoWindow: `
                    <div class="popup-container">
                        <img src="${result.picture}" alt="Result">
                        <div class="popup-content">
                            <p class="t-data t-bold">${result.first_name} ${result.last_name}</p>
                            <p class="t-caption">${result.specialties}</p>
                        </div>
                    </div>
                `,
                resultIndex: idx,
                latlng: result.location.coordinates.latlng,
            };
        });
    }

    setSelectedPCP(): void {
        const finalFormat: SelectedProvidersConfig = {};

        this.members
            .filter((member: Member) => member.selectedProvider)
            .forEach((member: Member) => {
                finalFormat[member.type] = member.selectedProvider;

                if (member.type && member.type.indexOf(providerSearchCoveredTypes.dependent) >= 0) {
                    if (!finalFormat.dependents) {
                        finalFormat.dependents = [];
                    }

                    const depInd = member.type.split('_')[1];

                    finalFormat.dependents[depInd] = member.selectedProvider;
                } else {
                    finalFormat[member.type] = member.selectedProvider;
                }
            });

        this.selectedProviders = !isEmptyObj(finalFormat) ? finalFormat : this.selectedProviders;
    }

    determineSelectedPCP(results: Array<any>, selectedPCPs) {
        // determine if any providers have been selected
        if (Object.entries(selectedPCPs).length > 0) {
            // find current member type
            const currentMember = this.currentMember;

            // account for dependents array in this.selectedProviders
            if (!!selectedPCPs[providerSearchCoveredTypes.dependents]) {
                selectedPCPs[providerSearchCoveredTypes.dependents].forEach((dependent, index) => {
                    selectedPCPs[`dependent_${index}`] = dependent;
                });
            }

            // assign pcp for current member
            let pcpSelectedForMember = selectedPCPs[currentMember.type];

            // IF pcp was already selected for the member.... select specific pcp id that was already selected
            if (!!pcpSelectedForMember) {
                results = results.map((result) => {
                    // use facility_id to check for pcc, provider_id for pcp
                    if (this.whichWorkflow === providerSearchTypes.doctor) {
                        result.selected = result.provider_id === pcpSelectedForMember.provider_id;
                    } else {
                        result.selected = result.facility_id === pcpSelectedForMember.facility_id;
                    }

                    // if a pcp was selected... then store the appropriate values on the member
                    if (result.selected) {
                        currentMember.selectedProvider = result;
                        currentMember.isPCPSelected = true;
                    }

                    return result;
                });
            }
        }
        return results;
    }

    applySelectedPCP(): void {
        // determine if any providers have been selected
        if (!!this.selectedProviders) {
            this.members.forEach((member: Member) => {
                if (!!this.selectedProviders[member.type]) {
                    member.selectedProvider = this.selectedProviders[member.type];
                    member.isPCPSelected = !isEmptyObj(member.selectedProvider);
                }
                if (!!this.selectedProviders[providerSearchCoveredTypes.dependents]) {
                    this.selectedProviders[providerSearchCoveredTypes.dependents].forEach((dependent, index) => {
                        if (member.type === `dependent_${index}`) {
                            member.selectedProvider = dependent;
                            member.isPCPSelected = true;
                        }
                    });
                }
            });
        }
    }

    viewSelectedProfile(index: number) {
        this.showDetails = false;
        this.selectedResultIdx = index;
        this.selectedResult = this.backendResults[index];
        this.busy = this.http.get(`/api/scapi/providers/${this.selectedResult.provider_id}/`).subscribe((response: any) => {
            this.selectedResult = {
                ...this.selectedResult,
                ...response,
                boardCertification:
                    !!response.board_certifications && response.board_certifications.length > 0
                        ? response.board_certifications[0].board_certification
                        : '',
                picture:
                    response.gender === 'M'
                        ? 'https://d32ul9oyxvd2n5.cloudfront.net/assets/maleDoctor.svg'
                        : 'https://d32ul9oyxvd2n5.cloudfront.net/assets/femaleDoctor.svg',
                pcpName: {
                    first: response.first_name,
                    last: response.last_name,
                },
                medical_groups: !!response.medical_groups && response.medical_groups.length > 0 ? response.medical_groups : '',
                hospital_affiliations:
                    !!response.hospital_affiliations && response.hospital_affiliations.length > 0 ? response.hospital_affiliations : '',
                languages: !!response.languages && response.languages.length > 0 ? response.languages : '',
                degree: !!response.degrees && response.degrees.length > 0 ? response.degrees[0] : '',
            };
            this.showDetails = true;
            this.backendResults[index] = this.selectedResult;
        });
    }

    updateZipcodeSearch($event) {
        this.searchLat = $event.lat;
        this.searchLng = $event.lng;
        this.zipcode = $event.zipcode;
        this.currentPage = 1;

        // clear selected PCP on ZIPcode change for the active member
        this.clearSelection();

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.updateResults();
        } else {
            // PCC: clear selected facility when zipcode changes
            this.members.forEach((member: Member) => {
                member.isPCPSelected = false;
                delete member.selectedProvider;
                this.selectedProviders[member.type] = {};
            });
            this.updateResultsFacility();
        }
    }

    updateDistance($event) {
        this.distance = $event;
        this.currentPage = 1;

        // clear selected PCP on distance change for the active member
        this.clearSelection();

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.updateResults();
        } else {
            this.updateResultsFacility();
        }
    }

    searchByName($event) {
        this.name = $event;
        this.currentPage = 1;

        // clear selected PCP on name change for the active member
        this.clearSelection();

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.updateResults();
        } else {
            this.updateResultsFacility();
        }
    }

    addMembers = (consumer: string, membersValues, depIndex: number = null) => {
        return {
            name: `${membersValues.first_name} ${membersValues.last_name}`,
            date_of_birth: membersValues.date_of_birth,
            isMemberSelected: consumer === providerSearchCoveredTypes.subscriber || this.workflowValues.child_only ? true : false,
            isPCPSelected: false,
            type: depIndex === null ? consumer : `dependent_${depIndex}`,
        };
    };

    getDependentsValues = (consumer: string) =>
        this.workflowValues.dependents.map((depValue, index) => this.addMembers(consumer, depValue, index));

    setMemberDetails() {
        if (!!this.workflowValues.demographics && !this.config.isMedicare) {
            const consumersArray: string[] = Object.keys(this.workflowValues.demographics);
            consumersArray.sort().reverse();
            const subscriberTypes: string[] = [providerSearchCoveredTypes.subscriber, providerSearchCoveredTypes.spouse];
            const dependentTypes: string[] = [providerSearchCoveredTypes.dependents, providerSearchCoveredTypes.stepParents];

            this.members = consumersArray
                .map((consumer: string) => {
                    if (subscriberTypes.includes(consumer)) {
                        return this.addMembers(consumer, this.workflowValues[consumer]);
                    } else if (dependentTypes.includes(consumer) && !!this.workflowValues[consumer]) {
                        return this.getDependentsValues(consumer);
                    }
                })
                .reduce((acc, val) => acc.concat(val), [])
                .filter((pcpMembers) => pcpMembers);
        } else if (this.config.isMedicare) {
            const subscriberClone = cloneObject(this.workflowValues.subscriber);
            subscriberClone.type = providerSearchCoveredTypes.subscriber;
            this.members = [subscriberClone];
        }

        if (!!this.selectedProviders) {
            this.members.forEach((member: Member) => {
                if (!!this.selectedProviders[member.type]) {
                    member.selectedProvider = this.selectedProviders[member.type];
                }
            });
        }
    }

    selectConsumer(memberArray: Array<any>, selectedIndex) {
        if (!memberArray[selectedIndex].isMemberSelected) {
            memberArray.forEach((member) => {
                member.isMemberSelected = false;
            });

            memberArray[selectedIndex].isMemberSelected = true;
            this.memberType = memberArray[selectedIndex].type;

            if (this.whichWorkflow === providerSearchTypes.doctor) {
                this.getPrefliterResults(this.workflowId, this.memberType);
            } else {
                this.getFilterResultsFacility();
            }
        }

        this.currentPage = 1;
    }

    addResult(result: ProviderResult): void {
        this.members.forEach((member: Member) => {
            if (member.isMemberSelected) {
                member.isPCPSelected = true;
                member.selectedProvider = result;
                this.selectedProviders[member.type] = member.selectedProvider;
            }
        });

        this.analyticsService.dispatchAnalytics({
            GAKey: validGAEvents['pcp_selection'],
            CXKey: validCXEvents['pcp_selection'],
            PosthogKey: validPosthogEvents['pcp_selection'],
        });

        result.selected = true;
    }

    removePCP(clickedMember: Member): void {
        this.members.forEach((member: Member) => {
            if (member === clickedMember) {
                member.isPCPSelected = false;
                delete member.selectedProvider;
                this.selectedProviders[member.type] = {};

                this.backendResults.forEach((result) => {
                    result.selected = false;
                });
            }
        });
    }

    removeResult(result: ProviderResult) {
        this.clearSelection();

        this.analyticsService.dispatchAnalytics({
            GAKey: validGAEvents['pcp_remove_selection'],
            CXKey: validCXEvents['pcp_remove_selection'],
            PosthogKey: validPosthogEvents['pcp_remove_selection'],
        });

        result.selected = false;
    }

    clearSelection(): void {
        this.members.forEach((member: Member) => {
            if (member.isMemberSelected) {
                member.isPCPSelected = false;
                delete member.selectedProvider;
                this.selectedProviders[member.type] = {};
            }
        });
    }

    submit(): void {
        this.setSelectedPCP();

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.completeStep.emit({
                pcp: { ...this.selectedProviders, pcpSearchZipcode: this.zipcode, pcpSearchDistance: this.distance },
            });
        } else {
            this.completeStep.emit({
                pcc: { ...this.selectedProviders, pccSearchZipcode: this.zipcode, pcpSearchDistance: this.distance },
            });
        }
    }

    saveAndExitStep() {
        this.setSelectedPCP();

        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.saveAndExit.emit({ pcp: { ...this.selectedProviders, pcpSearchZipcode: this.zipcode } });
        } else {
            this.saveAndExit.emit({ pcc: { ...this.selectedProviders, pccSearchZipcode: this.zipcode } });
        }
    }

    goToPrevious(): void {
        this.previousStep.emit();
    }

    onSkip(): void {
        this.completeStep.emit({ pcp: {} });
    }

    // ******* profile page methods..
    selectPin(pin: any) {
        this.viewSelectedProfile(pin.resultIndex);
    }

    selectResult(index: number) {
        this.selectedResultIdx = index;
        this.selectedResult = this.backendResults[index];
        this.viewSelectedProfile(this.selectedResultIdx);
    }

    viewAllResults() {
        delete this.selectedResult;
        delete this.selectedResultIdx;
    }

    // end of profile page methods.... ************

    goToPage(page: number) {
        this.currentPage = page;
        this.scrollToSearchResults.nativeElement.scrollIntoView({
            alignToTop: true,
            behavior: 'smooth',
        });
        if (this.whichWorkflow === providerSearchTypes.doctor) {
            this.updateResults();
        } else {
            this.facilityPage = page;
            this.updateResultsFacility();
        }
    }

    getMemberName(member: Member): string {
        return member.name || `${member.first_name} ${member.last_name}`;
    }
}
