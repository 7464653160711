<footer class="PlanCardFooter" data-test-id="planCardFooter">
    <div class="PlanCardFooter__Buttons">
        <zip-button
            class="PlanCardFooter__DetailButton PlanCardFooter__DetailButton--Desktop"
            data-test-id="planCardFooterDetailButtonDesktop"
            [config]="{
                level: 'text',
                content: getToggleDetailButtonContent(),
                icon: !!showDetails ? 'chevron_up' : 'chevron_down',
                isIconRight: true
            }"
            (zipButtonClicked)="toggleDetails.emit()"
        ></zip-button>

        <zip-button
            *ngIf="canCompare"
            data-test-id="planCardFooterCompareButton"
            class="PlanCardFooter__CompareButton"
            [config]="{
                icon: !!plan.comparing ? 'checkbox_active' : 'checkbox',
                iconSize: 'medium',
                content: compareTitle || 'Compare'
            }"
            [ariaDescription]="getCompareActivatingMessage()"
            [disabled]="isCompareDisable()"
            (zipButtonClicked)="toggleComparison.emit()"
        ></zip-button>
    </div>
    <zip-button
        *ngIf="customButton"
        class="PlanCardFooter__CustomButton"
        data-test-id="planCardFooterCustomButton"
        [config]="customButton"
        [disabled]="customButton.disabled"
        [attr.data-tooltip]="customButton.tooltip || null"
        (zipButtonClicked)="customButtonClicked.emit()"
    ></zip-button>
    <zip-button
        *ngIf="!hideSelection && !!showActions"
        data-test-id="planCardFooterSelectButton"
        class="PlanCardFooter__SelectButton"
        [config]="getSelectButtonConfig()"
        [disabled]="isSelectDisable()"
        (zipButtonClicked)="toggleSelection.emit()"
    ></zip-button>
</footer>
