import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, CardModule, ZipBusyModule, MessageBannerModule, ModalModule } from '@zipari/design-system';
import { CalculatePremiumsCardComponent } from './calculate-premiums-card.component';
import { FormGroupModule } from '../form-group/form-group.module';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [CalculatePremiumsCardComponent],
    imports: [CommonModule, ButtonModule, CardModule, ZipBusyModule, SharedModule, FormGroupModule, MessageBannerModule, ModalModule],
    exports: [CalculatePremiumsCardComponent],
})
export class CalculatePremiumsCardModule {}
