import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { BaseCVAComponent } from './../base-cva.component';

@Component({
    selector: 'hidden',
    templateUrl: './hidden.component.html',
    styleUrls: ['./hidden.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HiddenComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => HiddenComponent),
            multi: true,
        },
    ],
})
export class HiddenComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() group;
    @Input() config;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.formControlService.addControlToFormGroup(this.group, this.config, {
            [this.config.prop]: this.config.value,
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
