export enum customFormElements {
    address = 'address',
    basicAddress = 'basicAddress',
    brandColor = 'brandColor',
    industryGroup = 'industryGroup',
    formArray = 'formArray',
    officeHours = 'officeHours',
    dropdownOther = 'dropdownOther',
    conditionalToggle = 'conditionalToggle',
    formGroupArray = 'formGroupArray',
    optionalDate = 'optionalDate',
    companyAddress = 'companyAddress',
    inputNA = 'inputNA',
    passwords = 'passwords',
    whoseCovered = 'whoseCovered',
    broker_assistance = 'broker_assistance',
    autopay = 'autopay',
    coverageEffective = 'coverageEffective',
    termsAndConditions = 'termsAndConditions',
    hidden = 'hidden',
    ssnMultiField = 'ssnMultiField',
}
