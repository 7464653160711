import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    testedUrls: Record<string, boolean> = {};

    constructor(private configService: ConfigService, private http: HttpClient) {}

    createTestUrls(srcUrl: string) {
        const noQueryParams = srcUrl.split('?')[0];
        const queryParams = srcUrl.split('?')[1];
        const diffQueryParams = queryParams
            ? noQueryParams +
              '?' +
              queryParams
                  .split('&')
                  .map((param) => {
                      const paramName = param.split('=')[0];
                      const paramValue = param.split('=')[1];
                      const updatedParam = paramValue.match(/^\d+$/) ? Number(paramValue) + 1 : `${paramValue}`;

                      return `${paramName}=${updatedParam}`;
                  })
                  .join('&')
            : noQueryParams;
        const differentIdParam = noQueryParams
            .split('/')
            .map((x) => (x.match(/^\d/) ? Number(x) + 1 : x))
            .join('/');
        const extraSlash = srcUrl.endsWith('/') ? `${srcUrl}/` : `${srcUrl}//`;
        const noRouteParams = srcUrl.split('/')[2]; // skip the protocol's slashes "https://" to get only the host
        const noLastRouteParam = srcUrl.split('/').slice(0, -1).join('/');

        this.testedUrls[noQueryParams] = true;
        this.testedUrls[noRouteParams] = true;
        this.testedUrls[noLastRouteParam] = true;
        this.testedUrls[extraSlash] = true;
        this.testedUrls[differentIdParam] = true;
        this.testedUrls[diffQueryParams] = true;
        this.testUrl(noQueryParams);
        this.testUrl(noRouteParams);
        this.testUrl(noLastRouteParam);
        this.testUrl(extraSlash);
        this.testUrl(differentIdParam);
        this.testUrl(diffQueryParams);
    }

    testUrl = (urlToTest: string) => {
        try {
            this.http.get(urlToTest).subscribe({ next: (res) => console.log('Success'), error: (e) => console.log(e.message || e) });
        } catch (e: any) {
            console.log(e['message'] || e);
        }
    };

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                const { shouldLog } = this.configService.configs['global'];
                const { url } = event as unknown as any;

                if (shouldLog && url && !this.testedUrls[url]) {
                    console.log('Testing call to:', url);
                    this.testedUrls[url] = true;
                    this.createTestUrls((event as unknown as any)['url']);
                }
            })
        );
    }
}
