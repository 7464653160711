<modal
    *ngIf="showPCPModal"
    [config]="pcpModalConfig"
    [condition]="showPCPModal"
    (cancel)="togglePCPModal.emit()"
    class="ProviderSearchModal"
>
    <div body class="ProviderSearchModal__Body">
        <header class="ProviderSearchModal__Header" *ngIf="asideHeaderConfig.showAsideHeader">
            <h2 class="ProviderSearchModal__HeaderTitle t-bold" [innerHTML]="asideHeaderConfig.header"></h2>
            <span class="ProviderSearchModal__HeaderSubTitle t-data" [innerHTML]="asideHeaderConfig.subHeader"></span>
        </header>
        <section
            class="ProviderSearchModal__Section u-flex"
            (click)="selectConsumer(members, i)"
            [ngClass]="{ SelectPCP: member.isMemberSelected, PCPSelected: member.isPCPSelected }"
            *ngFor="let member of members; let i = index"
        >
            <div class="ConsumerInfo u-flex">
                <div class="u-flex">
                    <div class="BulletContainer">
                        <span
                            *ngIf="member.isMemberSelected && !member.isPCPSelected"
                            class="NumberedBullet--Active"
                            [innerHTML]="i + 1"
                        ></span>
                        <span
                            *ngIf="!member.isPCPSelected && !member.isMemberSelected"
                            class="NumberedBullet--Inactive"
                            [innerHTML]="i + 1"
                        ></span>
                        <i *ngIf="member.isPCPSelected" class="icon icon--medium">check_circle</i>
                    </div>
                    <div class="ConsumerContainer">
                        <div class="u-flex PersonalDetails">
                            <h6 class="SubscriberName t-bold" [innerHTML]="getMemberName(member)"></h6>
                            <span class="SubscriberDOB t-data" [innerHTML]="member.date_of_birth | date : 'MM/dd/yyyy'"></span>
                        </div>
                        <div class="ProviderDetails">
                            <p *ngIf="!member.isPCPSelected" class="ProviderDetails__Name" [innerHTML]="getProviderDetail(member)"></p>
                            <div *ngIf="member.isPCPSelected" class="SelectedProviderContainer">
                                <div class="SelectedProviderDetails">
                                    <span class="SelectedProviderDetails__Name t-bold" [innerHTML]="selectedProviderName(member)"></span>
                                    <span
                                        class="SelectedProviderDetails__PrimarySpecialty t-data"
                                        [innerHTML]="member.selectedProvider.primary_specialty"
                                    ></span>
                                </div>
                                <i (click)="removePCP(member)" class="icon icon--medium icon--pointer">delete</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <i class="icon icon--medium icon--pointer" *ngIf="!member.isPCPSelected"> chevron_right </i>
        </section>
    </div>
</modal>

<modal
    *ngIf="showFiltersModal"
    [config]="filtersModalConfig"
    [condition]="showFiltersModal"
    (cancel)="toggleFiltersModal.emit()"
    class="ProviderSearchModal"
>
    <div body class="ProviderSearchModal__Body">
        <section *ngFor="let group of filterGroupConfig">
            <header *ngIf="group.header">
                <label class="t-data t-bold t-caps" [innerHTML]="group.header"></label>
            </header>

            <form-control
                *ngFor="let control of group.controls"
                [config]="control"
                [group]="filtersForm"
                [control]="filtersForm.get(control.prop)"
            >
            </form-control>
        </section>
    </div>

    <div footer class="ProviderSearchModal__Footer">
        <zip-button
            [config]="{ level: 'medium', content: 'Clear Filters', size: 'fullWidth' }"
            (zipButtonClicked)="reset.emit()"
        ></zip-button>
        <zip-button [config]="{ level: 'high', content: 'Filter', size: 'fullWidth' }" (zipButtonClicked)="filter.emit()"></zip-button>
    </div>
</modal>
