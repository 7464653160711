import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@zipari/design-system';

import { SaveExitFooterComponent } from './save-exit-footer.component';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module';

@NgModule({
    declarations: [SaveExitFooterComponent],
    imports: [CommonModule, ButtonModule, ShoppingCartModule],
    exports: [SaveExitFooterComponent],
})
export class SaveExitFooterModule {}
