import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class IndustriesService {
    constructor(private http: HttpClient) {}

    getIndustries(params = {}): Observable<any> {
        return this.http.get<any>('api/enrollment/industries/', { params });
    }
}
