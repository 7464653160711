import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { ApplicationTypes } from '@zipari/shared-sbp-constants';

export class StepperFormConfigAttestation {
    enabled: boolean;
    content: string;
}

export class StepperFormFooterButton extends ButtonConfig {
    stepValid: boolean;
}

export class StepperFormConfigFooter {
    attestation: StepperFormConfigAttestation;
    buttons: Array<StepperFormFooterButton>;
}

export class StepperFormLeftStepStructure {
    label: string;
    id: string;
}

export class StepperFormRightCardStructure {
    id: string;
    label: string;
    form: Array<AllControlsConfiguration>;
    subTitle?: string;
}

export class StepperFormConfigLeft {
    steps: Array<StepperFormLeftStepStructure>;
}

export class StepperFormConfigRight {
    cards: Array<StepperFormRightCardStructure>;
}

export class StepperFormConfigHeader {
    label: string;
    description: string;
    html: string;
}

export class StepperFormConfig {
    layout:
        | 'layout-template-stepper-forms-1'
        | 'layout-template-stepper-forms-2'
        | 'layout-template-stepper-forms-3'
        | 'layout-template-stepper-forms-review'
        | 'layout-template-stepper-forms-confirmation';
    steps: Array<StepperFormRightCardStructure>;
    footer: StepperFormConfigFooter;
    header?: StepperFormConfigHeader;
    isQLEenabled?: boolean;
    subsidy?: any;
    reimbursementArrangement?: any;
    openEnrollmentRange?: any;

    /** get generated by code */
    left?: StepperFormConfigLeft;
    right?: StepperFormConfigRight;
}

export const attestationConfig = {
    type: 'checkbox',
    prop: 'attestation',
    options: [{ label: '', value: 'true' }],
    validators: ['required'],
};

export interface StepperPrefillVal {
    coverageEffectiveDate?: string;
    applicationType?: ApplicationTypes;
    whoseCovered?: any;
    coverage_types?: any;
}
