export interface GlobalConfig {
    authExceptions?: string[];
    ede?: boolean;
    timeout: object;
    analytics: {
        cx_capture: {
            endpoint: string;
            source_system_name: string;
            mapping: {
                virtualPageView: string;
            };
        };
    };
    baseUrl: string;
    handleAuth: boolean;
    tenantName: string;
    title: string;
    pagesRemoved?: string[];
    payment: {
        vendor: string;
        ProcessingRegionCode: number;
        fiserve_endpoint: string;
    };
    plan_card?: {
        badges: {
            enabled: boolean;
        };
    };
    preventFamilyHighlightAttributes?: boolean;
    preserveRoutingWhenUnauthenticatedFromRoutes: string[];
    navigation?: object;
    formControl?: {
        toggle: {
            strictAll: boolean;
            strictIfRequired: boolean;
        };
        companyAddress: {
            populateCity: boolean;
        };
    };
    contentSkip?: {
        target: string;
        buttonConfig: {
            content: string;
            level: string;
        };
    };
    featuresFlag?: {
        overviewAsideOnDetail: {
            hasBrokerDetails: boolean;
        };
        enableMaPrescriptionPlan: boolean;
    };
}
