<ng-container *ngIf="config?.label">
    <label class="form-control__label" [ngClass]="{ 'is-required': !!config.required }">
        <div class="u-flex u-alignBaseline u-maxX">
            <p class="t-data t-bold">{{ config?.label }} <span *ngIf="required">*</span></p>
        </div>
    </label>
</ng-container>

<div class="ssn-group__controls">
    <form-control *ngFor="let ctrl of ssnControls" [group]="formGroup" [config]="ctrl" [control]="formGroup.get(ctrl.prop)"> </form-control>
</div>

<ng-container *ngIf="errors">
    <div class="u-flex ssn-group__error">
        <i class="icon icon--small icon--error">warning</i>
        <p [attr.aria-label]="displayedError" class="t-caption t-error">
            {{ config.message }}
        </p>
    </div>
</ng-container>
