export enum QuoteStepKeys {
    demographics = 'demographics',
    plan_selection = 'plan_selection',
    dental_vision = 'dental_vision',
    review = 'review',
    login_register = 'login_register',
    confirmation = 'confirmation',
}

export const defaultplanOrdering = 'price';
