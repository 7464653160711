<!-- Add Subscriber and Spouse-->
<ng-container *ngIf="showSubscriber || showSpouse">
    <ng-container *ngTemplateOutlet="subSpouseTemplate"></ng-container>
</ng-container>

<!-- Dependent -->
<ng-container *ngIf="showChild || showChildOnly || showParent">
    <ng-container *ngTemplateOutlet="depTemplate; context: { showChildOnly: showChildOnly }"></ng-container>
</ng-container>

<ng-template #subSpouseTemplate>
    <div class="WhoseCoveredForm">
        <card class="WhoseCoveredForm__WhoseCoveredCardSections">
            <h6 title class="t-bold">{{ showSubscriber ? subSpouseConfig?.text?.subscriber : subSpouseConfig?.text?.spouse }}</h6>
            <div header-action>
                <zip-button *ngIf="showSpouse" [config]="closeDependentCard" (zipButtonClicked)="removeButtonClicked(0, 'spouseDependent')">
                </zip-button>
            </div>

            <div subheader>
                <ng-container *ngIf="maxAgeLimit">
                    <div class="WhoseCoveredForm__Banner WhoseCoveredForm__Banner--Error">
                        <p class="t-data" [innerHTML]="config.coverage?.text?.max_age_subscriber_medicare_or_individual_or_dental"></p>
                    </div>
                </ng-container>
                <ng-container *ngIf="isOverSixtyFive">
                    <div class="WhoseCoveredForm__Banner">
                        <p class="t-data" [innerHTML]="config?.coverage?.subscriber?.maxAllowedText"></p>
                    </div>
                </ng-container>
            </div>

            <div body>
                <div class="WhoseCoveredForm__WhoseCoveredQuestions">
                    <ng-container *ngFor="let config of subSpouseConfig?.controls">
                        <div class="WhoseCoveredForm__Form--Controls">
                            <form-control
                                [group]="showSubscriber ? whoseCoveredGroup.controls.subscriber : whoseCoveredGroup.controls.spouse"
                                [config]="config"
                                [control]="
                                    (showSubscriber
                                        ? whoseCoveredGroup.controls.subscriber?.controls
                                        : whoseCoveredGroup.controls.spouse?.controls)[config.prop]
                                "
                            >
                            </form-control>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div footer class="WhoseCoveredForm__WhoseCoveredFooter" *ngIf="!isSubscriberOnly && !showSpouse">
                <zip-button
                    class="WhoseCoveredForm__WhoseCoveredFooterButton"
                    *ngIf="!addSpouse"
                    [config]="addSpouseButton"
                    (zipButtonClicked)="buttonClicked(whoseCoveredValue, true, 'spouseDependent')"
                >
                </zip-button>

                <zip-button
                    *ngIf="!addChild"
                    class="WhoseCoveredForm__WhoseCoveredFooterButton"
                    [config]="addChildButton"
                    (zipButtonClicked)="buttonClicked(whoseCoveredValue, true, 'childDependent')"
                >
                </zip-button>
                <zip-button
                    *ngIf="!addParent && hasParentDependents"
                    class="WhoseCoveredForm__WhoseCoveredFooterButton"
                    [config]="addParentButton"
                    (zipButtonClicked)="buttonClicked(whoseCoveredValue, true, 'parentDependent')"
                >
                </zip-button>
            </div>
        </card>
    </div>
</ng-template>

<ng-template #depTemplate let-showChildOnly="showChildOnly">
    <ng-container *ngIf="showChildOnly">
        <div class="WhoseCoveredForm__Banner" *ngFor="let note of childOnlyConfig?.notes">
            <p class="t-data">{{ note }}</p>
        </div>
    </ng-container>

    <ng-container *ngFor="let dep of dependentVal; let i = index">
        <div class="WhoseCoveredForm">
            <card class="WhoseCoveredForm__WhoseCoveredCardSections">
                <div title>
                    <h6 class="t-bold" *ngIf="showChild || showChildOnly">{{ dependentConfig.text.child }} {{ i + 1 }}</h6>
                    <h6 class="t-bold" *ngIf="showParent">{{ dependentConfig.text.parent }} {{ i + 1 }}</h6>
                </div>

                <div header-action>
                    <zip-button
                        *ngIf="(dependentVal.length > 1 && this.showChildOnly) || this.showChild || this.showParent"
                        [config]="closeDependentCard"
                        (zipButtonClicked)="removeButtonClicked(i)"
                    >
                    </zip-button>
                </div>

                <div body class="WhoseCoveredForm__WhoseCoveredQuestions">
                    <ng-container *ngIf="showChildOnly; else depTemplateFormControls">
                        <ng-container *ngFor="let config of childOnlyConfig.controls; let x = index">
                            <div
                                class="WhoseCoveredForm__DependentControls"
                                [ngClass]="{ 'WhoseCoveredForm__DependentControls--Extra': x > 1 }"
                            >
                                <div class="WhoseCoveredForm__Form--Controls">
                                    <form-control
                                        [group]="(whoseCoveredGroup?.controls?.dependents?.controls)[i]"
                                        [config]="config"
                                        [control]="((whoseCoveredGroup?.controls?.dependents?.controls)[i]?.controls)[config.prop]"
                                    >
                                    </form-control>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template #depTemplateFormControls>
                        <ng-container *ngFor="let config of dependentConfig.controls; let x = index">
                            <div
                                class="WhoseCoveredForm__DependentControls"
                                [ngClass]="{ 'WhoseCoveredForm__DependentControls--Extra': x > 1 }"
                            >
                                <div class="form--controls">
                                    <form-control
                                        [group]="
                                            showChild
                                                ? (whoseCoveredGroup?.controls?.dependents?.controls)[i]
                                                : (whoseCoveredGroup?.controls?.stepParents?.controls)[i]
                                        "
                                        [config]="config"
                                        [control]="
                                            showChild
                                                ? ((whoseCoveredGroup?.controls?.dependents?.controls)[i]?.controls)[config.prop]
                                                : ((whoseCoveredGroup?.controls?.stepParents?.controls)[i]?.controls)[config.prop]
                                        "
                                    >
                                    </form-control>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>
                <div footer>
                    <ng-container *ngIf="showChildOnly; else depTemplate">
                        <div footer class="WhoseCoveredForm__WhoseCoveredChildFooter">
                            <zip-button
                                *ngIf="
                                    childOnlyConfig?.childLimit
                                        ? dependentVal.length < childOnlyConfig.childLimit
                                        : dependentVal.length < depLimit
                                "
                                [config]="dependentbuttonConfig"
                                (zipButtonClicked)="buttonClicked(whoseCoveredValue, true, 'child_only')"
                            >
                            </zip-button>
                        </div>
                    </ng-container>
                    <ng-template #depTemplate>
                        <zip-button
                            *ngIf="dependentVal.length < depLimit"
                            [config]="dependentButtonConfig"
                            (zipButtonClicked)="buttonClicked(whoseCoveredValue, true)"
                        >
                        </zip-button>
                    </ng-template>
                </div>
            </card>
        </div>
    </ng-container>
</ng-template>
