import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'tablet-toolbar',
    templateUrl: './tablet-toolbar.component.html',
    styleUrls: ['./tablet-toolbar.component.scss'],
})
export class TabletToolbarComponent implements OnInit {
    constructor(public navigationService: NavigationService) {}

    ngOnInit() {}
}
