<form-control
    (inputChanged)="onIndustryTitleInput($event)"
    (selected)="onIndustryTitleSelect($event)"
    [group]="formGroup"
    [direction]="direction"
    [config]="industryTitleConfig"
    [control]="formGroup.get(industryTitleProp)"
>
    <p hint *ngIf="formGroup.get(industryTitleProp).valid && loadingIndustryTitle" class="u-absolute t-caption">
        {{ industryTitleConfig.messages.loading }}
    </p>
    <p
        hint
        *ngIf="formGroup.get(industryTitleProp).valid && !loadingIndustryTitle && showIndustryTitleError"
        class="u-absolute t-caption t-error"
    >
        {{ industryTitleConfig.messages.error }}
    </p>
</form-control>

<form-control
    (inputChanged)="onSICCodeInput($event)"
    (selected)="onSICCodeSelect($event)"
    [group]="formGroup"
    [direction]="direction"
    [config]="sicCodeConfig"
    [control]="formGroup.get(sicCodeProp)"
>
    <p hint *ngIf="formGroup.get(sicCodeProp).valid && loadingSicCodes" class="u-absolute t-caption">
        {{ sicCodeConfig.messages.loading }}
    </p>
    <p hint *ngIf="formGroup.get(sicCodeProp).valid && !loadingSicCodes && showSicCodeError" class="u-absolute t-caption t-error">
        {{ sicCodeConfig.messages.error }}
    </p>
</form-control>
