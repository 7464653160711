import Member from './Member.model';

export default class MemberCoverage {
    readonly id?: number;
    member?: Member;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    member_coverage_id: string;
    subscriber?: boolean;
    relationship_to_subscriber?: string;
    crm_id?: string;
    enrollment_status?: string;
    auto_disenrollment_pending?: boolean;
    auto_disenrollment_reason?: string;
    auto_disenrollment_effective_date?: string;
    auto_disenrollment_scheduled_date?: string;
    auto_disenrollment_cancelled_date?: string;
    cobra_status?: string;
    record_type?: string;
    plan_identifier?: string;
    plan_code?: string;
    status_code?: string;
    status_description?: string;
    proposed_effective_date?: string;
    termination_reason_code?: string;
    termination_description?: string;
    application_receipt_date?: string;
    application_signature_date?: string;
    application_date?: string;
    der_receipt_date?: string;
    der_date?: string;
    pbp_code?: string;
    pbp_description?: string;
    ineligibility_start_date?: string;
    ineligibility_end_date?: string;
    reinstatement_date?: string;
    enrollment_id?: string;
    product_coverage?: number;
    group?: number;
}
