import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericErrorConfig, ActionStates } from '@zipari/shared-sbp-modules';
import { ConfigService } from '@zipari/shared-sbp-services';
import { getValue } from '@zipari/web-utils';
import { errorPageConfig, TOKEN_EXPIRE_TYPE, CHAR_LIMIT, defaultTokenExpired } from './error-page.constants';

@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
    errorConfig: GenericErrorConfig = errorPageConfig;

    constructor(private configService: ConfigService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.errorConfig = this.getErrorConfig();
        this.checkParams();
    }

    getErrorConfig(): GenericErrorConfig {
        const errorType = this.route.snapshot.url[0].path;

        let errorPageConfig: GenericErrorConfig;
        switch (errorType) {
            case TOKEN_EXPIRE_TYPE.QUOTE_ERROR:
                errorPageConfig = getValue(this.configService.configs, 'tokenExpire.quoteTokenExpired');
                break;
            case TOKEN_EXPIRE_TYPE.APPLICATION_ERROR:
                errorPageConfig = getValue(this.configService.configs, 'tokenExpire.applicationTokenExpired');
                break;
            case TOKEN_EXPIRE_TYPE.TOKEN_ERROR:
                errorPageConfig = getValue(this.configService.configs, 'tokenExpire.tokenLinkExpired') || defaultTokenExpired;
                break;
            default:
                errorPageConfig = getValue(this.configService.configs, 'errorPage');
        }

        /**
         * Returns the error configuration based on the current error type.
         * If the pageConfig contains data, then it is assigned to errorConfig, otherwise the default errorConfig is returned.
         */

        if (!!Object.keys(errorPageConfig).length) {
            if (errorPageConfig.header) {
                const subtitle: string = errorPageConfig.header.subtitle || '';
                errorPageConfig.header.subtitle = this.trimSubtitle(subtitle, errorType);
            }

            this.errorConfig = { ...errorPageConfig };
        }

        return this.errorConfig;
    }

    handleAction(prop) {
        const action = prop.action;

        switch (action) {
            case ActionStates.INTERNAL_ROUTE:
                this.router.navigate([prop.route]);
                break;
            case ActionStates.EXTERNAL_ROUTE:
                window.open(prop.url, '_blank');
                break;
        }
    }

    trimSubtitle(subtitle: string, errorType: string): string {
        const maxCharCount: number = getValue(this.configService.configs, 'tokenExpire.maxCharLimit') || CHAR_LIMIT.DEFAULT_CHAR_LIMIT;
        const shouldTrimSubtitle: boolean =
            (errorType === TOKEN_EXPIRE_TYPE.QUOTE_ERROR || errorType === TOKEN_EXPIRE_TYPE.APPLICATION_ERROR) &&
            subtitle.length > maxCharCount;

        if (shouldTrimSubtitle) {
            subtitle = subtitle.substring(0, maxCharCount);
        }

        return subtitle;
    }

    checkParams() {
        const title = this.route.snapshot.queryParams.title;
        const subtitle = this.route.snapshot.queryParams.subtitle;
        if (title) this.errorConfig.header.title = title;
        if (subtitle) this.errorConfig.header.subtitle = subtitle;
    }
}
