import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from '@zipari/shared-sbp-services';

@Injectable({
    providedIn: 'root',
})
export class MarketSegmentGuardService {
    constructor(public router: Router, public configService: ConfigService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const validMarketSegment = this.configService.validateMarketSegment(route);
        if (!validMarketSegment) {
            this.router.navigate([this.configService.appRoute, this.configService.newMarketSegment]).then(() => {
                this.configService.newMarketSegment = null;
            });
        }

        return validMarketSegment;
    }
}
