<div class="productized-auth" [zipBusy]="busy" *ngIf="!externalUrl">
    <!-- switch to change between layouts -->
    <ng-container [ngSwitch]="config?.layout">
        <auth-banner *ngSwitchCase="AuthenticationLayouts.banner" [layoutOptions]="config?.layoutOptions">
            <ng-container authCard *ngTemplateOutlet="authCard"></ng-container>
        </auth-banner>

        <auth-step *ngSwitchCase="AuthenticationLayouts.step" [layoutOptions]="config?.layoutOptions">
            <ng-container authCard *ngTemplateOutlet="authCard"></ng-container>
        </auth-step>
    </ng-container>

    <ng-template #authCard>
        <auth-card
            (buttonClicked)="handleButtonClicked()"
            (linkClicked)="handleLinkClicked($event)"
            [disableButton]="disableButton"
            [success]="success"
            [errorMessage]="errorMessage"
            [formGroup]="formGroup"
            [config]="config?.authCardOptions"
            [date_of_birth]="subscriberDOB"
            [browserSupportConfig]="browserSupportConfig"
        >
        </auth-card>
    </ng-template>
</div>
