export const mockErrorConfig = {
    errorPage: {
        codes: [],
        img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
        header: {
            title: 'We were unable to connect you',
        },
        buttons: [
            {
                content: 'Go To Home',
                level: 'medium',
                prop: {
                    action: 'INTERNAL_ROUTE',
                    route: 'login',
                },
            },
        ],
    },
    tokenExpire: {
        applicationTokenExpired: {
            img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
            header: {
                title: 'Application Token Shared Link Expired',
            },
        },
        defaultTokenExpired: {
            img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
            header: {
                title: 'Token Link Expired',
                subtitle: 'Your token link has expired, please contact your broker or sales representative for a new link.',
            },
        },
        maxCharLimit: 10,
        quoteTokenExpired: {
            img: 'https://zipari-uploads-dev.s3.amazonaws.com/multitenant/images/error-2.original.png',
            header: {
                title: 'Quote Token Shared Link Expired',
            },
        },
    },
};
