export default class Address {
    readonly id?: number;
    address_1?: string;
    address_2?: string;
    address_type?: string;
    street_name_1?: string;
    street_name_2?: string;
    street_name_3?: string;
    city_name?: string;
    zip_code?: string;
    zipcode?: string;
    created_at?: string;
    readonly updated_at?: string;
    data?: string;
    effective_date?: string;
    termination_date?: string;
    title?: string;
    attention?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    state?: string;
    state_name?: string;
    zip?: string;
    type?: string;
    county?: string;
    county_display?: string;
    county_name?: string;
    county_code?: string;
}
