export interface IDProofingResponse {
    metadata?: any;
    ResponseMetadata: ResponseMetadata;
    id?: any;
    linkMetadata?: any;
    VerificationResponse: VerificationResponse;
}
interface ResponseMetadata {
    ResponseCode: string;
    TDSResponseDescriptionText?: any;
    ResponseDescriptionText: string;
    id?: any;
}
interface ResponseValueObject {
    metadata?: any;
    _value_1: string;
    linkMetadata?: any;
    id?: any;
}
interface VerificationResponse {
    metadata?: any;
    FinalDecisionCode?: any;
    linkMetadata?: any;
    id?: any;
    DSHReferenceNumber: string;
    VerificationQuestions: VerificationQuestionSetEntity[] | any;
    SessionIdentification: string;
}
interface VerificationQuestionSetEntity {
    metadata?: any;
    VerificationQuestionText: ResponseValueObject;
    VerificationAnswerChoiceText?: ResponseValueObject[] | any;
    linkMetadata?: any;
    id?: any;
}
