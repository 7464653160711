import { clientsURLS } from '@zipari/shared-sbp-constants';

export const mockBrokerId = 432;

export const mockClientId = 3456;

export const mockPageSize = 9;

export const mockEndpointToGetClientsByBrokerId = `/${clientsURLS.brokerPortalEndpoint}?broker_id=432/`;

export const mockEndpointToGetClientByClientId = `/${clientsURLS.brokerPortalEndpoint}3456/`;

export const mockEndpointToGetAllClients = `/${clientsURLS.brokerPortalEndpoint}?ordering=-created_at&page=1&page_size=9`;

export const mockClientEmail = 'broker@gmail.com';

export const mockEndpointToGetAllClientsByClientEmail = `/${clientsURLS.brokerPortalEndpoint}?email_iexact=broker%40gmail.com&page=1&page_size=9&ordering=-updated_at`;

export const mockAuthService = {
    app_user_data: {
        broker_id: mockBrokerId,
    },
};
