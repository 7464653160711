<main>
    <section class="banner">
        <h3>{{ config.header }}</h3>
        <span *ngIf="config?.subHeader; else noSubHeader">{{ subHeaderText }}</span>
        <ng-template #noSubHeader>Please update the filters below to view covered providers.</ng-template>
    </section>

    <section *ngIf="config?.searchBarHeaderText" class="search__header__textbox">
        <p class="t-data" [innerHTML]="config?.searchBarHeaderText"></p>
    </section>

    <section class="search__header__filters">
        <div class="form-control__wrapper">
            <div class="search__header__distance">
                <div class="form-control form-control--dropdown search__distance--dropdown">
                    <select (change)="onWithinDistanceChange($event.target.value)">
                        <option *ngFor="let distance of searchConfig.locationConfig.within" [selected]="distance === selectedDistance">
                            Within {{ distance }} miles of
                        </option>
                    </select>
                    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
                        <icon [name]="'carat_down'"></icon>
                    </div>
                </div>

                <div class="u-relative">
                    <input
                        class="form-control search__distance--input"
                        [value]="initialZipcode"
                        (keyup.enter)="onSearchAreaInputEnter($event.target.value)"
                    />
                    <div class="form-control__hint">
                        <!--<span-->
                        <!--class="t-caption t-link"-->
                        <!--(click)="onUseMyCurrentLocationClick()">-->
                        <!--Use My Current Location-->
                        <!--</span>-->
                    </div>
                </div>

                <div class="search__header--providerSearchBar">
                    <div class="form-control__icon">
                        <i class="icon icon--medium">search</i>
                    </div>
                    <input
                        class="form-control"
                        [placeholder]="providerSearchBarText || 'Provider Last Name'"
                        (keyup.enter)="onSearchProviderName($event.target.value)"
                    />
                </div>
            </div>
        </div>
    </section>
</main>
