import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { accordion } from '@zipari/design-system';

import { NotificationModel } from '../notifications.constant';
import { NotificationsService } from '../notifications.service';

@Component({
    selector: 'notifications-allow-paging',
    templateUrl: './notifications-allow-paging.component.html',
    styleUrls: ['./notifications-allow-paging.component.scss', '../notifications.component.scss'],
    animations: [accordion],
})
export class NotificationsAllowPagingComponent implements OnInit {
    @Input() notifications: Array<NotificationModel>;
    @Input() icon: any;

    notificationStart: number = 0;

    constructor(private notificationsService: NotificationsService, public router: Router) {}

    ngOnInit() {}

    toggle(notification: NotificationModel) {
        notification.open = !notification.open;

        if (!notification.is_read && notification.open) {
            this.notificationsService.registerNotificationAsRead(notification.id);
        }
    }

    linkClicked(url) {
        if (url.includes('http')) {
            window.open(url, '_blank');
        } else {
            this.router.navigate([url]);
        }
    }
}
