import { displayCoverageType } from '@zipari/shared-sbp-constants';
import { CoverageFormConfig, defaultErrorMessages } from '@zipari/shared-sbp-enrollment';

// TODO: establish contract with BE to have a set place where these will be assigned. -hlopez
export const getCoverageTypes = (values): any => {
    const rootCoverageType = values.coverage_type;
    const rootCoverageTypes = values.coverage_types;
    const demographicsCoverageType = values.demographics?.coverage_type;
    const demographicsCoverageTypes = values.demographics?.coverage_types;
    let result;

    if (rootCoverageType || rootCoverageTypes) {
        result =
            typeof rootCoverageType === 'string' && !!rootCoverageType
                ? [rootCoverageType]
                : rootCoverageType && Array.isArray(rootCoverageType) && rootCoverageType.some((val) => val && val.length) // verify that it's not an array of empty strings
                ? rootCoverageType
                : rootCoverageTypes && Array.isArray(rootCoverageTypes)
                ? rootCoverageTypes
                : '';
    } else if (values.demographics && (demographicsCoverageType || (demographicsCoverageTypes && demographicsCoverageTypes.length))) {
        result =
            typeof demographicsCoverageType === 'string'
                ? [demographicsCoverageType]
                : demographicsCoverageTypes && demographicsCoverageTypes.length
                ? demographicsCoverageTypes
                : '';
    }

    return formatCoverageTypes(result);
};

export const formatCoverageTypes = (value: Array<string> | string): Array<string> | string => {
    // Sort to be in the following order: ['vision', 'dental', 'health']
    // Consistency in array order is important to backend step actions that check whether any keys have changed
    // Weights for sorting coverage types array: lower number = earlier in array
    const weights = {
        vision: 0,
        dental: 1,
        health: 2,
        medical: 2,
    };

    const coverageTypeSort = (a, b) => {
        const aWeight = weights[a];
        const bWeight = weights[b];

        if (aWeight !== undefined && bWeight !== undefined) {
            return aWeight - bWeight;
        } else if (aWeight !== undefined && bWeight === undefined) {
            return -1;
        } else if (aWeight === undefined && bWeight !== undefined) {
            return 1;
        }

        // If neither a or b is known, just sort alphabetically
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        }
        return 0;
    };

    const isString = (item) => typeof item === 'string';

    return Array.isArray(value) ? value.filter(isString).sort(coverageTypeSort) : value;
};

export const getDisplayCoverageType = (coverageTypes: Array<string> | String): string => {
    if (typeof coverageTypes === 'string') {
        return displayCoverageType[coverageTypes] || coverageTypes;
    } else if (Array.isArray(coverageTypes)) {
        const coverageTypesArr = coverageTypes as Array<string>;

        const formattedCoverageTypes = formatCoverageTypes(coverageTypesArr) as Array<string>;

        return formattedCoverageTypes
            .reduce((acc: Array<string>, coverageType: string) => {
                if (coverageType && typeof coverageType === 'string') {
                    const coverageTypeMapped = displayCoverageType[coverageType] || coverageType;
                    const coverageTypeUppercase = coverageTypeMapped[0].toUpperCase() + coverageTypeMapped.substr(1);
                    acc.push(coverageTypeUppercase);
                }
                return acc;
            }, [])
            .join(', ');
    }

    return 'N/A';
};

export const getIsMedicalApp = (coverageTypes: Array<string>): boolean =>
    !!coverageTypes.find((type) => ['health', 'medical'].includes(type));

export const updateErrorMessage = (coverageFormConfig: CoverageFormConfig): CoverageFormConfig => {
    coverageFormConfig.zipcode = {
        ...coverageFormConfig.zipcode,
        errors: {
            zipcode: defaultErrorMessages.invalidZipcode,
            customErrMessage: defaultErrorMessages.noCountyForSelectedZipcode,
        },
    };

    coverageFormConfig.county = {
        ...coverageFormConfig.county,
        errors: {
            customErrMessage: defaultErrorMessages.noPlansForSelectedCounty,
        },
    };

    return coverageFormConfig;
};
