import { ButtonModule } from '@zipari/design-system';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericErrorComponent } from './generic-error.component';

@NgModule({
    declarations: [GenericErrorComponent],
    imports: [CommonModule, ButtonModule],
    exports: [GenericErrorComponent],
})
export class GenericErrorModule {}
