export class QuoteEditPanelConfig {
    title: string = 'Edit Demographics';
    icon: string = '';
    instructionsHeader: string = '';
    instructionsBody: string = '';
    continue: string = 'Submit';
    cancel: string = 'Cancel';

    openSpecifiedAccordiansOnInit: boolean = false;

    coverageHeader: string = 'Coverage';
    coverageDisplay: boolean = true;
    coverageAllowEdits: boolean = true;
    hideCoverageTypes: boolean = true;

    householdMembersHeader: string = 'Household Members';
    householdMembersDisplay: boolean = true;
    householdMembersAllowEdits: boolean = true;
    allowAddOrRemoveDependents: boolean = true;
    allowAddOrRemoveSpouse: boolean = true;
    householdMembersForm: object = null;
    removeMemberIcon: string = 'trash';

    subsidyEstimateHeader: string = 'Subsidy Estimate';
    subsidyEstimateDisplay: boolean = true;
    subsidyEstimateAllowEdits: boolean = true;

    personalInfoForm: object = null;

    personalInformationHeader: string = 'Personal Information';
    personalInformationDisplay: boolean = true;
    personalInformationAllowEdits: boolean = true;

    addressHeader: string = 'Address';
    addressDisplay: boolean = true;
    addressAllowEdits: boolean = true;

    contactHeader: string = 'Contact';
    contactDisplay: boolean = true;
    contactAllowEdits: boolean = true;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export enum QuoteEditPanelSectionTypes {
    address = 'address',
    contact = 'contact',
    coverage = 'coverage',
    members = 'members',
    subscriber = 'subscriber',
    subsidy = 'subsidy',
}

export const EDIT_PANEL_SUBSIDY_CONTROLS = [
    {
        description: 'EX. 3',
        maxLength: 2,
        mask: 'number',
        label: 'Household Size',
        prop: 'household_size',
        type: 'text',
        validators: ['required'],
        errors: {
            max: 'Please provide a household size',
        },
    },
    {
        description: ' ',
        mask: 'number',
        options: [
            {
                label: 'YES',
                value: true,
            },
            {
                label: 'NO',
                value: false,
            },
        ],
        label: 'Are you an Alaskan or Native American? ',
        prop: 'native_indian_alaskan',
        type: 'toggle',
        validators: ['required'],
    },
    {
        prop: 'native_indian_alaskan_message',
        type: 'description',
        conditional: {
            when: [
                {
                    prop: 'native_indian_alaskan',
                    value: true,
                    comparator: 'EQUALS',
                },
            ],
            connective: 'AND',
        },
        label: 'You may be eligible for additional savings based on your status. Visit <a target="_blank" class="t-link" href="https://www.healthcare.gov/">HealthCare.gov</a> or call <a class="t-link" href="tel:555-555-4444">(555 )555-4444</a> for more information.',
    },
    {
        description: 'EX. 20,000',
        mask: 'currency',
        label: 'Household Income',
        prop: 'annual_income',
        type: 'text',
        validators: ['required'],
    },
];

export class QuoteEditPanelSectionsEdited {
    address?: boolean = false;
    contact?: boolean = false;
    coverage?: boolean = false;
    members?: boolean = false;
    personalInformation?: boolean = false;
    subsidyEstimate?: boolean = false;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export interface IWhoseCovered {
    children?: IndividualsCovered[];
    spouse?: IndividualsCovered;
    stepParents?: IndividualsCovered[];
    subscriber?: IndividualsCovered;
    dependents?: IndividualsCovered[];
    whose_covered?: string;
}

interface IndividualsCovered {
    address: [];
    coverage_type: [];
    date_of_birth: string;
    gender?: string;
    mailing_address?: [];
    home_phone?: string;
    medicare_enrolled: boolean;
    memberType: string;
    memberTypeUnique?: string;
    name: string;
    phone?: string;
    primary_phone?: string;
    tobacco_use?: boolean;
    tobacco_user: boolean;
    uuid: string;
}
