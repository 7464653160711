import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AddressValidationService {
    constructor(public http: HttpClient) {}

    validateAddress(addressData: any) {
        const body = {
            street_name_1: addressData.streetName1,
            city: addressData.cityName,
            state: addressData.stateCode,
            zip_code: addressData.zipCode,
        };

        if (addressData.streetName2) {
            body['street_name_2'] = addressData.streetName2;
            // body['address'] = `${addressData.streetName1} ${addressData.streetName2} ${addressData.cityName} ${addressData.stateCode} ${addressData.zipCode}`;
        } else {
            // body['address'] = `${addressData.streetName1} ${addressData.cityName} ${addressData.stateCode} ${addressData.zipCode}`;
        }

        return this.http.post('/api/locations/verify_us_address/?case=proper', body);
    }
}
