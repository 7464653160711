import { ApplicationMembers, Application__ContactInformation } from '../sharedModels';

export class CreateApplicationRequest__Application {
    coverageYear: number = null;
    coverageState: string = null;
    linkedSystemUserIdentifier: string = null;
    contactInformation: Application__ContactInformation = new Application__ContactInformation();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class CreateApplicationRequest {
    application: CreateApplicationRequest__Application = new CreateApplicationRequest__Application();
    applicationMembers: Array<ApplicationMembers> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
