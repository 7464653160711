<div
    (click)="handleClick()"
    class="table__row u-relative"
    [ngClass]="{
        'multiple-select': ztService.zipTableOptions.selection.multiple,
        'is-selected': selected && options?.selection?.border
    }"
>
    <div *ngIf="detailView" class="table__row__expand">
        <i class="icon icon--medium icon--pointer" (click)="data.expanded = !data.expanded">
            {{ data.expanded }}
            {{ data.expanded ? 'chevron_up' : 'chevron_down' }}
        </i>
    </div>

    <ng-container *ngIf="ztService.zipTableOptions.selection.multiple">
        <input
            [checked]="selected"
            class="form-control form-control--checkbox table--checkbox"
            type="checkbox"
            (change)="markRowAsSelected($event)"
        />
    </ng-container>

    <ng-container *ngIf="columns && data">
        <zip-table-generic-cell-2
            class="table__cell"
            *ngFor="let column of columns"
            [column]="column"
            [data]="data"
            [ngStyle]="{ 'justify-content': column.cell_alignment }"
            (cellClicked)="cellClicked.emit($event)"
        >
        </zip-table-generic-cell-2>
    </ng-container>

    <div
        class="table__row__indicator"
        [ngClass]="{ 'is-active': selected && options?.selection?.arrow }"
        *ngIf="options?.selection?.arrow"
        (click)="$event.preventDefault(); $event.stopPropagation()"
    >
        <i class="icon icon--medium">arrow_right</i>
    </div>
</div>
