import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneObject, formatConfig, isInEnum, stringBuilder } from '@zipari/web-utils';
import { SquidexContentNames, SquidexFeatureKeys, validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import {
    AnalyticsService,
    AuthService,
    BrokerAssistanceService,
    ConfigDispenserGetConfigOptions,
    ConfigDispenserService,
    ConfigService,
    LoggerService,
} from '@zipari/shared-sbp-services';
import { SquidexGetContentParams } from '@zipari/shared-sbp-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { NavigationConfig, NavItem, Toolbar } from './navigation.configuration';
import { contactUsModalConfig, TOP_NAV_HEIGHT } from './navigation.constants';

export enum validNavigationSegments {
    individual = 'individual',
    medicare = 'medicare',
    'small-group' = 'small-group',
    'member-360' = 'member-360',
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public buttonClicked = new Subject();
    public config: NavigationConfig;
    public mobileMenuOpen = false;
    public activeRoute: NavItem;
    public activeRouteIdx = 0;
    public contactUsModalConfig = contactUsModalConfig;
    public contactUsModalOpen: boolean = false;
    public contactUsConfig: any;
    public contactGroups: Array<any>;
    private toolbarConfig: Toolbar;
    private navigationConfig: any;
    private isNavHidden = false;
    private _toolbarHeight: number = 0; // height of <navigation>
    private _workflowNavHeight: number = 0; // height of <workflow-nav>
    private _stepperHeight: number = 0; // height of <stepper>
    private _height = new BehaviorSubject<number>(0);
    private _navigationConfig = new BehaviorSubject<NavigationConfig>(null);

    // total height of nav components, in pixels. Used as an offset for fixed position elements
    height$: Observable<number> = this._height.asObservable();
    navigationConfig$: Observable<NavigationConfig> = this._navigationConfig.asObservable();

    constructor(
        private configService: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public analyticsService: AnalyticsService,
        private configDispenserService: ConfigDispenserService,
        private brokerAssistanceService: BrokerAssistanceService,
        public loggerService: LoggerService
    ) {
        this.initConfig();
    }

    get isAuthenticated() {
        return !!this.authService.loggedInUser;
    }

    public getNavHidden() {
        return this.isNavHidden;
    }

    public setNavHidden(value: boolean) {
        this.isNavHidden = value;
    }

    setToolbarHeight(val: number) {
        this._toolbarHeight = val || 0;
        this._height.next(this.getFullNavHeight());
    }

    setWorkflowNavHeight(val: number) {
        this._workflowNavHeight = val || 0;
        this._height.next(this.getFullNavHeight());
    }

    setStepperHeight(val: number) {
        this._stepperHeight = val || 0;
        this._height.next(this.getFullNavHeight());
    }

    getFullNavHeight(): number {
        // On broker-portal, <workflow-nav> is the entire height. On shopping, it is <toolbar> + top nav (static 3.75rem) + <stepper>
        return this._workflowNavHeight || this._toolbarHeight + TOP_NAV_HEIGHT + this._stepperHeight;
    }

    private formatNavigationConfigVariables(config: NavigationConfig) {
        const strConfig = JSON.stringify(config);

        // takes config as 'string'
        const formatted = stringBuilder(strConfig, this.authService.user);

        // convert back to config object
        return JSON.parse(formatted);
    }

    initConfig() {
        const toolbarConfig = this.configService.configs['global']['toolbar'];
        const navigationConfig = this.configService.configs['global']['navigation'];
        this.toolbarConfig = toolbarConfig && (formatConfig(toolbarConfig) as unknown as Toolbar);
        this.navigationConfig = navigationConfig && formatConfig(navigationConfig);
        const routeSegment: string =
            this.configService.activeRoute || this.router.parseUrl(this.router.url).root.children.primary.segments[1]?.path;
        if (!!this.toolbarConfig) {
            if (isInEnum(validNavigationSegments, routeSegment)) {
                this.toolbarConfig.routes.forEach((route, index) => {
                    if (route.prop === routeSegment) {
                        this.selectRoute(route, index);
                    }
                });
                this.toolbarConfig.routes = this.toolbarConfig.routes.filter((route) => route.show);
            } else {
                const globalConfig: any = this.configService.getPageConfig('global') || {};
                this.config = globalConfig.navigation;
            }
        } else {
            // ensure that the config is formatted for `__is_array` on re-inits or when cohorts are applied
            const nonGlobalNavConfig = this.configService.configs['navigation'] && formatConfig(this.configService.configs['navigation']);
            this.config = cloneObject(nonGlobalNavConfig);

            // If the config has a toolbar in non-global property then set active route without selecting
            if (this.config.toolbar && this.config.toolbar.routes) {
                this.config.toolbar.routes.forEach((route, index) => {
                    if (route.prop === routeSegment) {
                        this.activeRoute = route;
                        this.activeRouteIdx = index;
                    }
                });
                this.config.toolbar.routes = this.config.toolbar.routes.filter((route) => route.show);
            }
        }

        if (!this.config.hasOwnProperty('showHamburger')) {
            this.config.showHamburger = true;
        }

        if (this.isAuthenticated) {
            const formattedConfig = this.formatNavigationConfigVariables({ ...this.config });
            const mergedConfig = this.config && formattedConfig;
            this.config = formatConfig(mergedConfig) as unknown as NavigationConfig;
        }

        const configs: any = this.configService.getPageConfig('navigation') || this.configService.configs.navigation;
        if (configs && configs.contact_us) {
            this.contactUsConfig = cloneObject(configs.contact_us);
        }

        this._navigationConfig.next(this.config);
    }

    setConfig() {
        if (!!this.toolbarConfig) {
            this.config = (this.configService.configs[this.configService.activeRoute] || {})['navigation'] || {};
            this.config.toolbar = this.toolbarConfig;
        } else {
            this.config = this.configService.configs['navigation'];
        }
    }

    selectNavLink(event, link) {
        event.stopPropagation();

        if (!!link.route) {
            this.mobileMenuOpen = false;

            if (link.queryParams) {
                this.router.navigate([link.route], { queryParams: { stayOnWorkbench: true } });
            } else {
                this.router.navigate([link.route]);
            }
        }

        if (!!link.items) {
            if (!link.open) {
                this.config.links.forEach((_link) => {
                    _link.open = false;
                });
            }
            link.open = !link.open;
        }
    }

    routeTo(route: string) {
        this.router.navigate([route]);
    }

    navigateSelected(route, index) {
        if (this.activeRouteIdx === index) return;

        let childRoute: string = this.router.parseUrl(this.router.url).root.children.primary.segments[2].path;
        if (route.externalLink) {
            window.open(route.externalLink + childRoute || '', '_self');
            return;
        }

        this.router.navigate([route.route]);
        this.selectRoute(route, index);
    }

    selectRoute(route, index) {
        this.activeRoute = route;
        this.activeRouteIdx = index;

        this.configService.activeRoute = route.prop;

        if (!!this.navigationConfig && !!this.navigationConfig.routeSegmentForLogo) {
            this.configService.activeRouteSegmentForLogo = this.navigationConfig.routeSegmentForLogo;
        }

        this.setConfig();
    }

    isActiveRoute(route) {
        return this.router.isActive(route, true);
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }

    closeContactModal() {
        this.contactUsModalOpen = false;
    }

    openContactModal(): void {
        const queryParams = cloneObject(this.route.snapshot.queryParams);

        if (this.configService.currentState) {
            const state: string = this.configService.currentState;
            Object.assign(queryParams || {}, { state });
        }

        this.contactUsModalConfig.header.title = this.contactUsConfig && this.contactUsConfig.header;

        // Get header icon style variable from config
        const themeConfig: any = this.configService.getPageConfig('theme');
        if (themeConfig && themeConfig.closeIconColor) {
            this.contactUsModalConfig.header.closeIcon = themeConfig.closeIconColor;
        }

        // If we have a broker that is assisting us we should pass the broker id to the BE
        // There is a special case for BSC where we need to pass the "broker_internal_id" which should only be used in
        // special cases like for BSC for example
        if (this.brokerAssistanceService.assistingBroker) {
            const broker_id: string =
                this.brokerAssistanceService.assistingBroker.broker_internal_id || this.brokerAssistanceService.assistingBroker.broker_id;
            Object.assign(queryParams || {}, { broker_id });
        }

        const squidexContentParams: SquidexGetContentParams = {
            contentName: SquidexContentNames.contactUs,
            featureKey: SquidexFeatureKeys.contactUsIndividual,
        };
        const dispenserOptions: ConfigDispenserGetConfigOptions = {
            httpEndpoint: '/api/common/contact-us-flyout/',
            httpOptions: { params: queryParams },
        };

        // get contact-us configs
        this.configDispenserService
            .dispenseConfig(null, squidexContentParams, dispenserOptions)
            .pipe(first())
            .subscribe(
                (data) => {
                    this.contactUsConfig = data;
                    this.contactGroups = this.contactUsConfig.contactGroups || this.contactUsConfig.contact_groups;
                    this.contactUsModalOpen = true;
                    this.analyticsService.dispatchAnalytics({
                        GAKey: validGAEvents['contact-us_viewed'],
                        CXKey: validCXEvents['contact-us_viewed'],
                        PosthogKey: validPosthogEvents['contact-us_viewed'],
                    });
                },
                (error) => this.loggerService.error(error)
            );
    }
}
