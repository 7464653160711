import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ede-button-footer',
    templateUrl: './ede-button-footer.component.html',
    styleUrls: ['./ede-button-footer.component.scss'],
})
export class EdeButtonFooterComponent implements OnInit {
    @Output() edit = new EventEmitter<any>();
    @Output() buttonClicked = new EventEmitter<any>();
    @Output() linkClicked = new EventEmitter<any>();

    @Input() helpText;
    @Input() valid;
    @Input() disabled;
    @Input() content;

    constructor() {}

    ngOnInit() {
        this.content = this.content || 'Save & Continue';
    }
}
