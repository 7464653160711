import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ClientData } from '@zipari/shared-sbp-constants';
import { ClientDetailLabel } from '@zipari/shared-sbp-models';
import { take } from 'rxjs';
import {
    clientLabelKey,
    defaultCancelButtonConfig,
    defaultExistingClientButtonConfig,
    defaultSaveButtonConfig,
} from './existing-client-list-modal.constants';

@Component({
    selector: 'existing-client-list-modal',
    templateUrl: './existing-client-list-modal.component.html',
    styleUrls: ['../add-row-modal/add-or-edit-modal.component.scss'],
})
export class ExistingClientListComponent implements OnInit, OnChanges {
    @Input() config;
    @Input() existingClientsData: ClientData[];
    @Input() isSaveButtonDisabled: boolean;

    @Output() formBuilt = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() continue = new EventEmitter();

    isModalOpen: boolean = false;
    saveButtonConfig: ButtonConfig = defaultSaveButtonConfig;
    cancelButtonConfig: ButtonConfig = defaultCancelButtonConfig;
    existingClientButtonConfig: ButtonConfig = defaultExistingClientButtonConfig;
    isCreateNewClientSelected: boolean = false;
    isExistingClientSelected: boolean = false;
    selectedClientId: number;
    existingClientForm: FormGroup = new FormGroup({});

    constructor(public formControlService: FormControlService) {}

    ngOnInit() {
        this.buildAddRowForm();
    }

    ngOnChanges() {
        this.updateClientConfig();
    }

    public buildAddRowForm(): void {
        this.formControlService.addControlToFormGroup(this.existingClientForm, this.config.existingClientListConfig);
        this.formBuilt.emit();
    }

    public onCancel(): void {
        this.config.existingClientListConfig.options = [this.config.existingClientListConfig.options[0]];
        this.cancel.emit();
    }

    public onSave(): void {
        this.isSaveButtonDisabled = true;
        this.save.emit(this.isCreateNewClientSelected);
    }

    public onContinue(): void {
        this.continue.emit(this.selectedClientId);
    }

    public updateClientConfig(): void {
        this.existingClientsData?.forEach((client: ClientData) => {
            const {
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                address_1,
                address_2,
                street_name_1,
                street_name_2,
                city,
                state,
                zipcode,
                zip_code,
            } = client;

            const address1: string = address_1 || street_name_1;
            const address2: string = address_2 || street_name_2;
            const zipCode: string = zipcode || zip_code;
            const addressValues: string[] = [address1, address2, `${city ?? ''} ${state ?? ''} ${zipCode ?? ''}`];
            // Filter out null,undefined or empty values
            const filteredAddressValues: string[] = addressValues.filter((addressValue: string) => addressValue);
            const clientDetail: ClientDetailLabel[] = [
                { key: clientLabelKey.name, value: `${firstName} ${lastName}` },
                { key: clientLabelKey.phone, value: phoneNumber },
                { key: clientLabelKey.address, value: filteredAddressValues },
            ];

            this.config.existingClientListConfig.options.push({ label: clientDetail, value: client });
        });

        this.saveButtonConfig.content =
            this.config.editClientButtonContent ||
            this.config.createNewClientButtonContent ||
            this.config.save ||
            defaultSaveButtonConfig.content;
        this.cancelButtonConfig.content = this.config.cancel || defaultCancelButtonConfig.content;
        this.existingClientButtonConfig.content = this.config.existingClientButtonContent || defaultExistingClientButtonConfig.content;
    }

    public handleClientSelected(): void {
        this.existingClientForm
            .get(this.config.existingClientListConfig.prop)
            .valueChanges.pipe(take(1))
            .subscribe((value: ClientData | string) => {
                const newClient: string = this.config.existingClientListConfig.options[0].value;
                const isNewClient: boolean = value === newClient;

                this.isCreateNewClientSelected = isNewClient;
                this.isExistingClientSelected = !isNewClient;
                this.isSaveButtonDisabled = !isNewClient;
                this.selectedClientId = (value as ClientData)?.id;
            });
    }
}
