import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfirmationModalComponent, ConfirmationModalConfig } from '@zipari/shared-sbp-components';
import { LoggerService } from '@zipari/shared-sbp-services';

@Directive({
    selector: '[triggerConfirmationModal]',
})
export class TriggerConfirmationModalDirective implements OnDestroy {
    @Input('triggerConfirmationModal') config: ConfirmationModalConfig;
    @Input('modalContext') context;
    @Output() modalConfirm = new EventEmitter();
    @Output() modalCancel = new EventEmitter();
    @Output() modalOpen = new EventEmitter();

    isModalOpen = false;
    overlayRef: OverlayRef;
    cancelSubscription: Subscription;
    confirmSubscription: Subscription;
    openSubscription: Subscription;
    constructor(public loggerService: LoggerService, private overlay: Overlay) {}

    @HostListener('click', ['$event'])
    onHostClick(event) {
        if (!this.config || this.isModalOpen) {
            this.loggerService.warn('config doesnt exist or modal is already open');
            return;
        }
        // Use CDK to attach dynamic modal component to overlay
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: ['modal__mask-modal'],
        });
        const confirmationModalComponent = new ComponentPortal(ConfirmationModalComponent);
        const containerRef = this.overlayRef.attach(confirmationModalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.config = this.config;
        containerRef.instance.context = this.context;

        this.openSubscription = containerRef.instance.open.subscribe((isOpen: boolean) => {
            this.isModalOpen = isOpen;
            this.modalOpen.emit(this.isModalOpen);
        });

        this.cancelSubscription = containerRef.instance.cancel.subscribe(() => {
            containerRef.instance.open.next(false);
            this.overlayRef.detach();
        });

        this.confirmSubscription = containerRef.instance.confirm.subscribe(() => {
            this.modalConfirm.emit();
            containerRef.instance.open.next(false);
            this.overlayRef.detach();
        });
    }

    ngOnDestroy() {
        if (this.openSubscription) {
            this.openSubscription.unsubscribe();
        }

        if (this.cancelSubscription) {
            this.overlayRef.detach();
            this.cancelSubscription.unsubscribe();
        }

        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    }
}
