<ng-content></ng-content>

<div class="paginator-content">
    <zip-page-count class="page-count" [firstShowing]="firstShowing" [lastShowing]="lastShowing" [totalCount]="totalResultCount">
        <ng-content select="[count-row]"></ng-content>
    </zip-page-count>

    <zip-page-buttons
        *ngIf="totalCount > 1"
        class="page-buttons"
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (pageButtonClick)="onPageButtonClick($event)"
    >
    </zip-page-buttons>
</div>
