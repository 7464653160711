import { signatureType } from '../sharedModels';

export class SubmitApplicationRequest {
    idProofingIndicator: boolean = true;
    signatureName: string = null;
    signatureDate: string = null;
    signatureType: signatureType = signatureType.APPLICANT;
    requestMedicaidDeterminationMembers?: Array<string>;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
