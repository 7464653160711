export class SearchByDemographic {
    firstName: string;
    lastName: string;
    birthDate: string;
    email: string;
    city: string;
    stateCode: string;
    zip5: string;
    coverageYearNumber: number;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class SearchBySSN {
    firstName: string;
    lastName: string;
    birthDate: string;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
