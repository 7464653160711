import { Injectable, isDevMode } from '@angular/core';
import { ZipBackendErrorResponse } from '@zipari/shared-sbp-services';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    log(message: string, isDev = true) {
        if (isDev && isDevMode()) {
            console.log(`${new Date().toISOString()}-${message}`);
        }
    }

    warn(message: string) {
        if (isDevMode()) {
            console.warn(`${new Date().toISOString()}-${message}`);
        }
    }

    /* console logs the array of log as collapsed group */
    logGroup(logs: any[], title?: string) {
        if (logs?.length) {
            console.groupCollapsed(title);
            logs.forEach((log) => {
                console.log(log);
            });
            console.groupEnd();
        }
    }

    error(error: Error | ZipBackendErrorResponse | string, extraMessage: string = '') {
        let formattedError = error;

        if (typeof error === 'string') {
            formattedError = new Error(error);
        }

        if (isDevMode()) {
            if (extraMessage) {
                console.error(extraMessage, formattedError);
            } else {
                console.error(formattedError);
            }
        }
    }
}
