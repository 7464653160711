import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessRulesService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { defaultplanOrdering } from '@zipari/shared-sbp-constants';
import { getCoverageTypes, getIsMedicalApp, handleOnExchange } from '@zipari/shared-sbp-enrollment';
import { PlanParams } from '@zipari/shared-sbp-models';
import { AnalyticsService, LoggerService, ModalService } from '@zipari/shared-sbp-services';
import { forkJoin, Observable } from 'rxjs';
import { WorkflowService } from '../../../../services/src/lib/workflow.service';

@Injectable({
    providedIn: 'root',
})
export class CreateQuoteService extends WorkflowService<any> {
    readonly planEndpoint: string = 'api/shopping/plans/';

    constructor(
        public loggerService: LoggerService,
        public locationStrategy: LocationStrategy,
        public analyticsService: AnalyticsService,
        public http: HttpClient,
        public modalService: ModalService,
        public businessRulesService: BusinessRulesService,
        public router: Router
    ) {
        super(loggerService, locationStrategy, analyticsService, http, modalService, router);
    }

    public get activeStepType() {
        return getValue(this.workflow || {}, 'current_step.schema.type');
    }

    public getPlansFilters(): PlanParams {
        const values = this.workflow.values;
        const coverageDate = values.coverage_effective_date;
        const year = coverageDate?.slice(0, 4) || new Date().getFullYear();
        const params: PlanParams = {
            coverage_effective_date: values.coverage_effective_date,
            dependants_count: values.dependents?.length ?? 0,
            ordering: defaultplanOrdering,
            year,
            zipcode: values.demographics.zipcode || values.getStarted.zipcode,
        };

        if (!!values.subscriber) {
            params['subscriber_date_of_birth'] = values.subscriber.date_of_birth;

            if (values.subscriber.hasOwnProperty('tobacco_user')) {
                params['subscriber_tobacco'] = !!values.subscriber.tobacco_user;
            }
        }

        if (!!values.spouse) {
            params['spouse_date_of_birth'] = values.spouse.date_of_birth;

            if (values.spouse.hasOwnProperty('tobacco_user')) {
                params['spouse_tobacco'] = !!values.spouse.tobacco_user;
            }
        }

        if (!!values.dependents) {
            values.dependents.forEach((dependent, index) => {
                params[`dependant_${index}_date_of_birth`] = dependent.date_of_birth;

                if (dependent.hasOwnProperty('tobacco_user')) {
                    params[`dependant_${index}_tobacco`] = !!dependent.tobacco_user;
                }
            });
        }
        return params;
    }

    // Go to the earliest plan selection step, which may be plan_selection or dental_vision, depending
    // on which coverate types are selected
    public goToPlanSelection() {
        const workflowValues = this.workflow.values;
        const planSelectionStep: number = this.workflow.helpers.getStepNumberByStepType('plan_selection');
        const specialtyPlanSelectionStep: number = this.workflow.helpers.getStepNumberByStepType('dental_vision');
        const coverageTypes = getCoverageTypes(workflowValues);
        const isMedicalApp = getIsMedicalApp(coverageTypes);

        if (isMedicalApp) {
            this.goToStep(planSelectionStep);
        } else {
            this.goToStep(specialtyPlanSelectionStep);
        }
    }

    public clearSelectedQuotes$(): Observable<any> {
        const patchValues: Array<Observable<any>> = [];
        const planSelectionStep: number = this.workflow.helpers.getStepNumberByStepType('plan_selection');
        const specialtyPlanSelectionStep: number = this.workflow.helpers.getStepNumberByStepType('dental_vision');
        const specialtyPlanSelectionData = this.workflow.steps?.find((step) => step.step_number === specialtyPlanSelectionStep);

        if (planSelectionStep) {
            patchValues.push(this.patchWorkflow({ quoted: null }, planSelectionStep));
        }

        if (specialtyPlanSelectionStep && specialtyPlanSelectionData?.values.quoted) {
            patchValues.push(this.patchWorkflow({ quoted: null }, specialtyPlanSelectionStep));
        }

        return forkJoin(patchValues);
    }

    handleOnExchange(workflow, router, configService, edeSes, callback, isDe: boolean, authService) {
        handleOnExchange(
            workflow,
            router,
            configService.getPageConfig('create-quote'),
            configService.appRoute,
            edeSes,
            callback,
            isDe,
            authService,
            this.activeStepType,
            this.http
        );
    }
}
