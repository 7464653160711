<div class="chat--button" *ngIf="config">
    <ng-container *ngIf="config?.type === chatTypes.liveagent">
        <!-- inline styles usually should never be done.... this is required for integration with salesforce liveagent -->
        <div style="display: none" [id]="liveAgentOnlineButtonText" (click)="handleStartingChat()">
            <ng-container *ngTemplateOutlet="onlineView"></ng-container>
        </div>
        <div style="display: none" [id]="liveAgentOfflineButtonText">
            <ng-container *ngTemplateOutlet="offlineView"></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #onlineView>
    <zip-button level="high" icon="chat_active"> </zip-button>
</ng-template>

<ng-template #offlineView>
    <zip-button [disabled]="true" level="low" icon="chat"> </zip-button>
</ng-template>
