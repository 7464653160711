const i1 = 3;
const i2 = 5;
const i3 = 9;

export const configConstants = {
    label: 'SSN',
    validators: ['numeric', 'social'],
    message: 'Enter a valid SSN. (EX. XXX-XX-XXXX)',
};

export const ssnControls = [
    {
        prop: 'ssn1',
        type: 'text',
        mask: 'splitSsn',
        placeholder: '---',
        maxLength: 3,
        slice: [0, i1],
        grid: {
            col: '1 / 3',
            row: '1',
        },
    },
    {
        prop: 'ssn2',
        type: 'text',
        mask: 'splitSsn',
        placeholder: '--',
        maxLength: 2,
        slice: [i1, i2],
        grid: {
            col: '2 / 3',
            row: '1',
        },
    },
    {
        prop: 'ssn3',
        type: 'text',
        placeholder: '----',
        maxLength: 4,
        slice: [i2, i3],
        grid: {
            col: '3 / 3',
            row: '1',
        },
    },
];
