<section>
    <header>
        <h4 class="t-bold">{{ config.title }}</h4>
    </header>

    <form-group [configs]="config.controls" [form]="form"> </form-group>

    <section *ngIf="isFormValid && employerReimbursement">
        <div class="message__container" *ngIf="displayPaperFormLink; else noFormMessage">
            <h6 class="t-bold">Thank you for your interest in Cigna!</h6>

            <p>
                Based on your responses to the 2020 Qualified Small Employer Health Reimbursement Arrangement Questions, you are required to
                have your employer provide an affidavit.

                <br />
                <br />

                <a class="t-link t-bold" target="_blank" [href]="config.pdfDownloadLink">Click Here</a> to download and print the Colorado
                Application which includes the Employer Affidavit. Please mail the completed and signed application to Cigna as instructed
                on the form.
            </p>
        </div>

        <ng-template #noFormMessage>
            <div class="message__container">
                <h6 class="t-bold">Thank you for your interest in Cigna!</h6>

                <p>
                    Based on your responses to the 2020 Qualified Small Employer Health Reimbursement Arrangement Questions, we are unable
                    to issue an individual policy with the premiums, or portion thereof, paid or reimbursed by your employer. Please contact
                    your broker or the Colorado Division of Insurance with any questions.
                </p>
            </div>
        </ng-template>
    </section>
</section>
