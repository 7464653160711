import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'generic-error',
    templateUrl: './generic-error.component.html',
    styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
    @Output() onAction = new EventEmitter<any>();
    @Input() config: any;

    constructor() {}

    ngOnInit(): void {}
}
