import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, FormControlModule } from '@zipari/design-system';

import { ConditionalToggleComponent } from './conditional-toggle/conditional-toggle.component';
import { FormGroupArrayComponent } from './form-group-array/form-group-array.component';
import { FormGroupComponent } from './form-group.component';
import { SharedModule } from '../shared.module';
import { CustomFormElementModule } from '../custom-form-element/custom-form-element.module';

@NgModule({
    declarations: [FormGroupComponent, FormGroupArrayComponent, ConditionalToggleComponent],
    imports: [CommonModule, CustomFormElementModule, FormControlModule, SharedModule, ButtonModule, ReactiveFormsModule],
    exports: [FormGroupComponent],
})
export class FormGroupModule {}
