export class SVIDispositionInformation {
    taskFromGroup?: string;
    taskToGroup?: string;
    sviReviewDateTime?: string;
    sviReason?: string;
    sviDisposition?: string;
}

export class SVIIssueEswSviInformationSVIInformation {
    applicationVersionNumber?: number;
    sviIdentifier?: number;
    associatedPersonTrackingNumbers?: Array<string>;
    sviType?: string;
    eswSviStatus?: string;
    calculatedClockEndDateTime?: string;
    sviDispositionInformation?: Array<SVIDispositionInformation>;
}

export class SVIIssueEswSviInformation {
    insuranceApplicationIdentifier?: number;
    sviInformation?: Array<SVIIssueEswSviInformationSVIInformation>;
}

export class SVIInformationGeneratedDocs {
    physicalDocumentRepositoryCreationDateTime?: string;
    physicalDocumentPublicationName?: string;
    physicalDocumentSubjectType?: string;
    physicalDocumentDsrsIdentifier?: string;
}

export class SVIInformationCarryingApplicants {
    insuranceApplicationIdentifier?: number;
    applicationVersionNumber?: number;
}

export class SVIInformationRecordingActions {
    actionDateTime?: string;
    actionType?: string;
}

export class SVIInformation {
    associatedMemberIdentifiers?: Array<string>;
    recordingActions?: Array<SVIInformationRecordingActions>;
    sviIdentifier?: number;
    consolidatedSviStatus?: string;
    originatingApplicationVersionNumber?: number;
    latestCarryingApplicationVersionNumber?: number;
    sviCreationDateTime?: string;
    timerType?: string;
    timerStatusType?: string;
    timerStatusReasonType?: string;
    startDate?: string;
    originalEndDate?: string;
    endDate?: string;
    sepEligibilityStartDate?: string;
    sepEligibilityEndDate?: string;
    secondarySepObsoletingSviIndicator?: boolean;
    secondarySepTypes?: Array<string>;
    planSelectedIndicator?: boolean;
    planSelectedDateTime?: string;
    verificationStatusReason?: string;
    generatedPhysicalDocuments?: Array<SVIInformationGeneratedDocs>;
    associatedPersonTrackingNumbers?: Array<string>;
    associatedMemberNames?: Array<string>;
    carryingApplications?: Array<SVIInformationCarryingApplicants>;
    secondarySepCanObsoleteSviIndicator?: boolean;
}

export class SVIModel {
    sviResponse?: {
        ffmSviInformationResponse?: {
            ffmSviInformation?: Array<{ insuranceApplicationIdentifier?: number; sviInformation?: Array<SVIInformation> }>;
            result?: string;
        };
        partialDataIndicator?: boolean;
        eswSviInformationResponse?: {
            eswSviInformation?: Array<SVIIssueEswSviInformation>;
            result?: string;
        };
    };
}

export class SEPffmAndEsw {
    ffm: Array<SVIInformation>;
    esw: Array<SVIIssueEswSviInformationSVIInformation>;
}

export class SEPVerificationReasonsForDisplay extends SVIInformation {
    /** Internal use */
    statusConfig?: any;

    label: string;
    date: string;
    members: Array<{ name: string }>;
    description: string;
}
