import License from './License.model';

export default class Agency {
    id: number;
    agency_id: number;
    licenses: Array<License>;
    has_user: true;
    agency_name: string;
    status: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    shipping_address1: string;
    shipping_address2: string;
    shipping_city: string;
    shipping_state: string;
    shipping_zip: string;
    phone_number: string;
    email_address: string;
    tin: string;
}
