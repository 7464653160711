import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getValue } from '@zipari/web-utils';
import { LoggerService } from '@zipari/shared-sbp-services';
import {
    IDProofingPayload,
    IDProofingResponse,
    IDProofingQuestionsPayload,
    FARSWithStoreResponse,
    RIDPFinalWithStoreResponse,
} from '@zipari/shared-sbp-enrollment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { SYSTEM_ERROR_CODES } from './id-proofing.constants';
import mockInitialResponse from './mock/initial-with-questions.json';
import mockFinalResponse from './mock/final-acc.json';

export interface IDProofingFormattedResponse {
    refNum: string;
    sessionID: string;
    decisionCode: string;
    responseCode: string;
    hasQuestions: boolean;
    identityProofingIdentifier?: string;
    questions?: {
        text: string;
        answers: string[];
    }[];
}

export interface FARSFormattedResponse {
    decisionCode: string;
    identityProofingIdentifier?: string;
    responseCode: string;
}

@Injectable()
export class IDProofingService {
    private _idProofingRequest: IDProofingPayload;
    idProofingResponse$: BehaviorSubject<IDProofingFormattedResponse> = new BehaviorSubject(undefined);
    farsResponse$: BehaviorSubject<FARSFormattedResponse> = new BehaviorSubject(undefined);
    rf3Count$: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(public loggerService: LoggerService, private readonly http: HttpClient) {}

    private formatInitPayload(data: any): IDProofingPayload {
        return {
            Person: {
                PersonBirthDate: {
                    Date: data.birthDate || null,
                },
                PersonName: {
                    PersonGivenName: data.firstName || null,
                    PersonMiddleName: data.middleName || null,
                    PersonSurName: data.lastName || null,
                    PersonNameSuffixText: data.suffix || null,
                },
                PersonSSNIdentification: data.ssn || null,
                PersonAugmentation: {
                    PersonPreferredLanguage: {
                        LanguageCode: data.preferredLanguage,
                    },
                },
            },
            CurrentAddress: {
                LocationAddress: {
                    StructuredAddress: {
                        LocationStreet: {
                            StreetName: data.streetName1 || null,
                        },
                        LocationCityName: data.cityName || null,
                        LocationStateUSPostalServiceCode: data.stateCode || null,
                        LocationPostalCode: data.zipCode || null,
                        LocationPostalExtensionCode: data.plus4Code || null,
                    },
                },
            },
            ContactInformation: {
                ContactTelephoneNumber: {
                    FullTelephoneNumber: data.number || null,
                },
            },
            LevelOfProofingCode: 'LevelTwo',
        };
    }

    private formatAnswerPayload(data: { [key: string]: string }, sessionId: string): IDProofingQuestionsPayload {
        return {
            VerificationAnswerSet: {
                VerificationAnswers: Object.entries(data).map(([questionNum, answerNum]) => {
                    return {
                        VerificationQuestionNumber: questionNum,
                        VerificatonAnswer: answerNum,
                    };
                }),
            },
            SessionIdentification: sessionId,
        };
    }

    private get systemErrorResponse(): IDProofingFormattedResponse {
        return {
            refNum: null,
            responseCode: 'SYSTEM_ERROR',
            sessionID: null,
            decisionCode: null,
            hasQuestions: false,
        };
    }

    // @TODO: Remove this, mock on the backend in environments that aren't used for testing ID proofing
    private getMockResponse(call: string): any {
        const mocks = {
            init: mockInitialResponse,
            final: mockFinalResponse,
        };

        return mocks[call];
    }

    resetResponse(): void {
        this.idProofingResponse$.next(undefined);
        this.farsResponse$.next(undefined);
    }

    init(formData: any, options?: { mockIdProofing?: boolean }): Observable<IDProofingFormattedResponse> {
        const mockResponse: IDProofingResponse = this.getMockResponse('init');
        const idProofingRequestBody = this.formatInitPayload(formData);
        this._idProofingRequest = idProofingRequestBody;
        const realApiCall = this.http.post<IDProofingResponse>('/api/ede/id_proofing/ridp/initial/', idProofingRequestBody);

        const apiCall = options.mockIdProofing ? of(mockResponse) : realApiCall;

        return apiCall.pipe(
            map((response) => {
                const { VerificationResponse, ResponseMetadata } = response;
                if (SYSTEM_ERROR_CODES.includes(ResponseMetadata.ResponseCode)) {
                    return this.systemErrorResponse;
                }
                const hasQuestions =
                    ResponseMetadata.ResponseCode === 'HS000000' &&
                    Array.isArray(VerificationResponse.VerificationQuestions) &&
                    VerificationResponse.VerificationQuestions.length > 0;
                return {
                    refNum: VerificationResponse.DSHReferenceNumber,
                    sessionID: VerificationResponse.SessionIdentification,
                    decisionCode: VerificationResponse.FinalDecisionCode,
                    responseCode: ResponseMetadata.ResponseCode,
                    hasQuestions,
                    questions: hasQuestions
                        ? VerificationResponse.VerificationQuestions.map((question) => ({
                              text: question.VerificationQuestionText,
                              answers: question.VerificationAnswerChoiceText,
                          }))
                        : [],
                };
            }),
            catchError((error) => {
                this.loggerService.error(error);
                return of(this.systemErrorResponse);
            }),
            tap((response) => {
                this.idProofingResponse$.next(response);
                if (response.decisionCode === 'RF3') this.rf3Count$.next(this.rf3Count$.value + 1);
            })
        );
    }

    final(formData: any, sessionID: string, options?: { mockIdProofing?: boolean }): Observable<IDProofingFormattedResponse> {
        // PersonGivenName, PersonSurName, and PersonBirthDate are added to this call for the backend to use in calling the EDE SES store id proofing endpoint

        const mockResponse: RIDPFinalWithStoreResponse = this.getMockResponse('final');
        const realApiCall = this.http.post<RIDPFinalWithStoreResponse>('/api/ede/id_proofing/ridp/final/', {
            ...this.formatAnswerPayload(formData, sessionID),
            PersonGivenName: this._idProofingRequest.Person.PersonName.PersonGivenName,
            PersonSurName: this._idProofingRequest.Person.PersonName.PersonSurName,
            PersonBirthDate: this._idProofingRequest.Person.PersonBirthDate.Date,
        });

        const apiCall = options.mockIdProofing ? of(mockResponse) : realApiCall;

        return apiCall.pipe(
            map((response) => {
                const { VerificationResponse, ResponseMetadata } = response.final;
                const identityProofingIdentifier = getValue(response, 'store.result.identityProofingIdentifier');
                if (SYSTEM_ERROR_CODES.includes(ResponseMetadata.ResponseCode._value_1)) {
                    return this.systemErrorResponse;
                }
                return {
                    refNum: VerificationResponse && VerificationResponse.DSHReferenceNumber,
                    decisionCode: VerificationResponse && VerificationResponse.FinalDecisionCode,
                    sessionID: VerificationResponse && VerificationResponse.SessionIdentification,
                    responseCode: ResponseMetadata.ResponseCode._value_1,
                    hasQuestions: false,
                    identityProofingIdentifier,
                };
            }),
            tap((response) => {
                this.idProofingResponse$.next(response);
                if (response.decisionCode === 'RF3') this.rf3Count$.next(this.rf3Count$.value + 1);
            })
        );
    }

    fars(data: { SubscriberNumber?: string; DSHReferenceNumber: string }): Observable<FARSFormattedResponse> {
        // PersonGivenName, PersonSurName, and PersonBirthDate are added to this call for the backend to use in calling the EDE SES store id proofing endpoint

        return this.http
            .post<FARSWithStoreResponse>('/api/ede/id_proofing/fars/', {
                ...data,
                PersonGivenName: this._idProofingRequest.Person.PersonName.PersonGivenName,
                PersonSurName: this._idProofingRequest.Person.PersonName.PersonSurName,
                PersonBirthDate: this._idProofingRequest.Person.PersonBirthDate.Date,
            })
            .pipe(
                map((response) => {
                    const { IDProofedResponse, ResponseMetadata } = response.fars;
                    const identityProofingIdentifier = getValue(response, 'store.result.identityProofingIdentifier');
                    if (SYSTEM_ERROR_CODES.includes(ResponseMetadata.ResponseCode._value_1)) {
                        return this.systemErrorResponse;
                    }
                    return {
                        decisionCode: IDProofedResponse && IDProofedResponse.FinalDecisionCode,
                        responseCode: ResponseMetadata.ResponseCode._value_1,
                        identityProofingIdentifier,
                    };
                }),
                tap((response) => {
                    this.farsResponse$.next(response);
                })
            );
    }
}
