import { Observable } from 'rxjs';

export interface AddressMaps {
    [id: string]: AddressMap;
}

export interface AddressMap {
    street_name_1: string;
    street_name_2: string;
    city: string;
    state: string;
    zipcode: string;
}

export interface AddressFormConfig {
    addressMaps: AddressMaps;
    controls: any;
}

export interface MappedAddressGroup {
    addressMap?: AddressMap;
    valueChanges$: Observable<any>;
}

export const altAddressFieldNames = {
    street_name_1: ['address1', 'address_1'],
    street_name_2: ['address2', 'address_2'],
    city: ['city_name'],
    state: ['state_name', 'state_code'],
    zipcode: ['zip_code', 'zip'],
};
