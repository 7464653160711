<ng-container *ngFor="let navRow of config?.navItems">
    <div
        (click)="handleSubnavItemClicked.emit(navRow)"
        *ngLet="navRow.prop === activeNavItem as isActiveItem"
        class="subnav__row u-flex"
        [ngClass]="{ 'is-active': isActiveItem, 'is-border': config?.border }"
    >
        <i class="icon icon--medium" *ngIf="navRow.icon" [innerHTML]="navRow.icon"> </i>
        <p class="t-data" [ngClass]="{ 't-bold': isActiveItem }" [innerHTML]="navRow.text"></p>
    </div>
</ng-container>
