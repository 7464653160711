import { Directive, TemplateRef } from '@angular/core';

/*
    Pulled from here: https://stackoverflow.com/a/50423299
    Gets us access to dynamic (e.g. ngFor'd) template refs using ViewChildren or ContentChildren
*/
@Directive({
    selector: '[templateRef]',
})
export class TemplateRefDirective {
    public template: TemplateRef<any>;

    /* istanbul ignore next */
    constructor(private templateRef: TemplateRef<any>) {
        this.template = this.templateRef;
    }
}
