import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableFilterComponent } from './table-filter.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule, FormControlModule } from '@zipari/design-system';

@NgModule({
    imports: [CommonModule, FormsModule, ButtonModule, FormControlModule],
    declarations: [TableFilterComponent],
    exports: [TableFilterComponent],
})
export class TableFilterModule {}
