export const saveMethodConfig = {
    label: 'Save Payment Method',
    prop: 'save_method',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
    grid: {
        col: '1 / 2',
        row: '5',
    },
};

export const autopayConfig = {
    label: 'Autopay',
    prop: 'autopay',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    grid: {
        col: '2 / 3',
        row: '5',
    },
};
