import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormControlValidatorsService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';

/**
 * @deprecated Use @zipari/design-system instead
 */
@Injectable({
    providedIn: 'root',
})
export class EdeFormControlService {
    constructor(public validatorService: FormControlValidatorsService) {}

    /**
     * @deprecated Use @zipari/design-system (FormControlValidatorsService) instead
     */
    getValidatorsForFormControl(control: any, form?) {
        return this.validatorService.getFormControlValidators(control, form);
    }

    /**
     * Create form controls based on configuration, and prepopulate from the master form group if available.
     * @param controlConfigs The form control config controls array, example: this.config.controls
     * @param from A FormGroup on the master form group that contains the values we want to use to prepopulate
     * @param mapping (optional) The subpath under 'from' form group for where to find the value to prepopulate.
     *        mapping can be one of:
     *          • a map from controlConfig.prop to subPath.prop, ex: [ birthDate:
     *     'controls.demographic.controls.birthDate' ]
     *          • a subPath to be used for all controls in the config: 'controls.demographic.controls'
     *          • omitted, and it will prepopulate if a FormControl matching the config is found in the 'from'
     *     FormGroup
     */
    initializeControls(
        controlConfigs: Array<{ prop: string; type: string }>,
        from: FormGroup,
        mapping?: { [fromProp: string]: string } | string
    ): Array<FormControl> {
        const formControls = [];

        controlConfigs.forEach((controlConfig) => {
            let fromPath, formEl;

            switch (typeof mapping) {
                case 'object':
                    fromPath = `${mapping[controlConfig.prop]}.value`;
                    break;
                case 'string':
                    fromPath = `${mapping}.${controlConfig.prop}.value`;
                    break;
                default:
                    fromPath = `controls.${controlConfig.prop}.value`;
            }

            const existingValue = getValue(from, fromPath);
            const formControlValue = existingValue || existingValue === false ? existingValue : null;
            const validators = this.getValidatorsForFormControl(controlConfig);

            if (controlConfig.type === 'checkbox') {
                let formControls;

                if (controlConfig['options'].length === 1 && !Array.isArray(formControlValue)) {
                    // Single checkbox
                    formControls = [new FormControl(formControlValue, { validators })];
                } else {
                    formControls = controlConfig['options'].map((option) => {
                        return new FormControl(null, { validators });
                    });
                }
                formEl = new FormArray(formControls);
            } else {
                formEl = new FormControl(formControlValue, { validators: validators });
            }

            formControls[controlConfig.prop] = formEl;
        });

        return formControls;
    }
}
