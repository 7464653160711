export class IndividualSimpleSubnavItem {
    icon?: string;
    text?: string;
    prop?: string;
    route?: string;
    conditional?: any;

    constructor(options) {
        Object.assign(this, options);
    }
}

/** Model for the simple subnav configuration
 * The simple subnav is a basic component that displays nav items in a list with icons and a border in between
 * */
export class SimpleSubnavConfig {
    title?: string;
    border?: boolean = true;
    navItems?: Array<IndividualSimpleSubnavItem>;

    constructor(options: SimpleSubnavConfig = new SimpleSubnavConfig({})) {
        Object.assign(this, options);
    }
}
