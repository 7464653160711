export const reimbursementCardConfig = {
    title: '2020 Qualified Small Employer Health Reimbursement Arrangement Questions',
    subtitle: null,
    pdfDownloadLink:
        'https://www.cigna.com/static/www-cigna-com/docs/individuals-families/2020/medical/co/907623-application-medical-co.pdf',
    controls: [
        {
            label: 'Will an employer of one hundred (100) or fewer eligible employees be paying for or reimbursing an employee through wage adjustment or a health reimbursement arrangement for any portion of the premium on the policy being applied for?',
            prop: 'employer_reimbursment',
            type: 'toggle',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false,
                },
            ],
            validators: ['required'],
            errors: {
                required: 'You must answer to proceed.',
            },
        },
        {
            label: "If the employer will be reimbursing an employee through a health reimbursement arrangement, does it qualify as a 'qualified small employer health reimbursement arrangement' or QSEHRA*?",
            prop: 'is_qsehra',
            type: 'toggle',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false,
                },
            ],
            validators: ['required'],
            errors: {
                required: 'You must answer to proceed.',
            },
            actions: [
                {
                    name: 'HIDE',
                },
            ],
            conditions: {
                all: [
                    {
                        operation: 'EQUALS',
                        value: true,
                        name: 'employer_reimbursment',
                    },
                ],
            },
        },
        {
            label: 'Did the employer have a small group health benefit plan providing coverage to any employee in the twelve (12) months prior to the date of this application?',
            prop: 'is_sg_coverage',
            type: 'toggle',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false,
                },
            ],
            validators: ['required'],
            errors: {
                required: 'You must answer to proceed.',
            },
            actions: [
                {
                    name: 'HIDE',
                },
            ],
            conditions: {
                all: [
                    {
                        operation: 'EQUALS',
                        value: true,
                        name: 'employer_reimbursment',
                    },
                ],
            },
        },
    ],
};
