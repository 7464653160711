import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { map, takeUntil } from 'rxjs/operators';

import { BaseCVAComponent } from './../base-cva.component';

/*
    Composite control to capture following behavior:
        - User can either enter a value in the configured input
            OR
        - User can check the "N/A" checkbox (hence the name of this component)
*/
@Component({
    selector: 'input-na',
    templateUrl: './input-na.component.html',
    styleUrls: ['./input-na.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputNaComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputNaComponent),
            multi: true,
        },
    ],
})
export class InputNaComponent extends BaseCVAComponent implements Validator, OnInit, OnDestroy {
    @Input() config: {
        inputValidators: Array<any>;
        naLabel: string;
        prop: string;
        [key: string]: any;
    };
    @Input() direction;

    inputConfig;
    checkboxConfig;
    naValue = '';
    naProp = 'not_applicable';
    showOtherTextInput = false;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.inputConfig = {
            ...this.config,
            validators: this.config.inputValidators,
            type: 'text',
        };

        this.checkboxConfig = {
            prop: this.naProp,
            type: 'singleCheckbox',
            checkboxLabel: this.config.naLabel,
        };

        this.formGroup = new FormGroup({});
        this.formControlService.addControlToFormGroup(this.formGroup, this.inputConfig);
        this.formControlService.addControlToFormGroup(this.formGroup, this.checkboxConfig);
    }

    public get inputControl() {
        return this.formGroup.get(this.config.prop);
    }

    public writeValue(value: any) {
        if (value || value === this.naValue) {
            if (value === this.naValue) {
                this.inputControl.disable({ emitEvent: false });
                this.formGroup.patchValue({
                    [this.config.prop]: null,
                    [this.naProp]: true,
                });
            } else {
                this.formGroup.patchValue({
                    [this.config.prop]: value,
                    [this.naProp]: false,
                });
            }
        }
    }

    public registerOnChange(fn: any) {
        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.destroy),
                map((changes) => {
                    // Did user check N/A checkbox
                    if (changes && changes[this.naProp]) {
                        this.inputControl.setValue(null, { emitEvent: false });
                        this.inputControl.reset(null, { emitEvent: false });
                        this.inputControl.disable({ emitEvent: false });
                        return this.naValue;
                    }

                    this.inputControl.enable({ emitEvent: false });
                    this.inputControl.markAsTouched({ onlySelf: true });

                    return this.inputControl.value;
                })
            )
            .subscribe(fn);
    }

    public validate(control: AbstractControl): ValidationErrors {
        return this.formGroup.valid ? null : this.inputControl.errors;
    }

    // Control is valid if input is valid or if the user checks the N/A checkbox
    public registerOnValidatorChange?(fn: () => void): void {
        this.formGroup.statusChanges
            .pipe(
                takeUntil(this.destroy),
                map((status) => {
                    return this.inputControl.valid || this.formGroup.get(this.naProp).value;
                })
            )
            .subscribe(fn);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
