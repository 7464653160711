import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneObject, deepCompare, isEmptyObj } from '@zipari/web-utils';
import { SpinnerService } from '@zipari/design-system';
import { AddressSelectModalComponent, ConfirmationModalComponent, ConfirmationModalConfig } from '@zipari/shared-sbp-components';
import { AddressFormConfig, AddressMap, AddressMaps, altAddressFieldNames, MappedAddressGroup } from '@zipari/shared-sbp-directives';
import { Address } from '@zipari/shared-sbp-models';
import { AddressService, ConfigService, VerifyAddressResponse } from '@zipari/shared-sbp-services';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

export interface AddressConfig {
    addressSelectionModalMessage: {
        errorModalNotVerified: ConfirmationModalConfig;
        errorModalDifferentZip: ConfirmationModalConfig;
    };
    streetName1Prop: string;
    poBoxValidatorName: string;
}

@Directive({
    selector: '[verifyAddress]',
})
export class VerifyAddressDirective implements OnInit, OnDestroy {
    @Input('verifyAddress') addressFormGroup: FormGroup;
    @Input() disableVerifyAddress: boolean;
    @Input() disableDisparateZip: boolean;
    @Input() disallowPOBox: boolean = false;
    @Input() addressConfig: AddressConfig;
    @Input() addressFormConfig: AddressFormConfig;
    @Input() debounceTime: number = 0;
    @Input() checkDisabledFields: boolean; // determine whether to use value or rawValue
    @Input() verifiedIfSelected: boolean; // verified if user selects USPS Record OR their own record; only invalid if incomplete or err
    @Input() validatePrepopulatedAddress: boolean;

    @Output() allowContinueForAddress = new EventEmitter();
    @Output() addressSelect = new EventEmitter();
    @Output() addressErrorStart = new EventEmitter(); // emit on opening of error modal
    @Output() errorConfirm = new EventEmitter(); // emit on confirm of error modal

    private destroy = new Subject();
    private errorModalDefault: ConfirmationModalConfig = {
        body: 'We could not verify the address you provided. Please update the address and try again.',
        confirm: 'Close',
    };
    private differentZipModalMessageDefault: ConfirmationModalConfig = {
        body: 'The address you have entered is located in a different zip code than the one you are shopping in. Please enter a different address, or shop again in a different zip code.',
        confirm: 'Close',
    };

    private errorModalMessage: ConfirmationModalConfig;
    private differentZipModalMessage: ConfirmationModalConfig;
    private verifiedAddress: Address = <Address>{};
    public configs: AddressConfig;
    overlays: Array<OverlayRef> = [];

    constructor(
        private addressService: AddressService,
        private overlay: Overlay,
        private spinnerService: SpinnerService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        this.configs = this.addressConfig || this.configService.getPageConfig('enrollment') || this.configService.configs.enrollment;

        if (this.configs && this.configs.addressSelectionModalMessage) {
            if (this.configs?.addressSelectionModalMessage?.errorModalNotVerified) {
                this.errorModalMessage = cloneObject(this.configs.addressSelectionModalMessage.errorModalNotVerified);
            } else {
                this.errorModalMessage = this.errorModalDefault;
            }

            if (this.configs && this.configs.addressSelectionModalMessage.errorModalDifferentZip) {
                this.differentZipModalMessage = cloneObject(this.configs.addressSelectionModalMessage.errorModalDifferentZip);
            } else {
                this.differentZipModalMessage = this.differentZipModalMessageDefault;
            }
        } else {
            this.errorModalMessage = this.errorModalDefault;
            this.differentZipModalMessage = this.differentZipModalMessageDefault;
        }
    }

    ngOnChanges(changes) {
        if (changes.addressFormGroup) {
            this.setAddressFormGroupChanges();
        }
    }

    public setAddressFormGroupChanges() {
        if (this.addressFormGroup && !this.disableVerifyAddress) {
            const addressMaps = this.addressFormConfig && this.addressFormConfig.addressMaps;
            const mappedAddressGroups: MappedAddressGroup[] = getMappedAddressGroups(addressMaps, this.addressFormGroup);
            const addressGroups: MappedAddressGroup[] = mappedAddressGroups || [{ valueChanges$: this.addressFormGroup.valueChanges }];

            addressGroups.forEach((addressGroup) => {
                const { addressMap } = addressGroup;

                addressGroup.valueChanges$
                    .pipe(
                        takeUntil(this.destroy),
                        distinctUntilChanged((a, b) => {
                            if (mappedAddressGroups) {
                                return deepCompare(a, b);
                            } else {
                                // Ignore changes to fields that won't end up in the address validation payload
                                return deepCompare(
                                    this.addressService.mapVerifyAddressPayload(a),
                                    this.addressService.mapVerifyAddressPayload(b)
                                );
                            }
                        }),
                        // debounce valuechanges to prevent address verification on every keystroke
                        debounceTime(this.debounceTime),
                        filter(() => {
                            const addressProps = addressMap && Object.values(addressMap);
                            const mappedAddressFieldsValid =
                                addressProps &&
                                addressProps.length > 0 &&
                                addressProps.reduce((valid: boolean, prop: string) => {
                                    return (
                                        valid &&
                                        this.addressFormGroup.controls[prop] &&
                                        this.addressFormGroup.controls[prop].valid &&
                                        this.addressFormConfig.controls.find(
                                            (controlConfig) => controlConfig.prop === prop && !controlConfig.hidden
                                        )
                                    );
                                }, true);
                            return (mappedAddressFieldsValid || this.addressFormGroup.valid) && !this.disableVerifyAddress;
                        }),
                        // Map address values for payload
                        // If address fields are disabled (eg: via pre-populating rules) then use rawValue
                        map((addressValues) => {
                            return this.mapFormToPayloadValues(addressValues, addressMap);
                        }),
                        // Filter out invalid payloads for API
                        filter((mappedAddressValues) => {
                            return this.validatePayload(mappedAddressValues);
                        }),
                        // Don't run on previously verified address
                        // This allows parent to patch form with verified address without retriggering verify
                        filter((mappedAddressValues) => {
                            return !this.hasAddressBeenValidated(mappedAddressValues);
                        }),
                        tap(() => {
                            this.spinnerService.addSpinner();
                        }),
                        concatMap((mappedAddressValues) => {
                            return forkJoin([of(mappedAddressValues), this.addressService.verifyAddress(mappedAddressValues)]);
                        })
                    )
                    .subscribe(
                        ([formAddress, verifiedResponse]) => {
                            this.spinnerService.removeSpinner();
                            if (!this.addressFormGroup.pristine) {
                                this.handleVerifyAPISuccess(formAddress, verifiedResponse, addressMap);
                            }
                        },
                        (error) => {
                            this.spinnerService.removeSpinner();
                            this.openErrorModal();
                        }
                    );
            });
        }

        // If validating prepopulated address on load then mark form group as dirty, controls as touched, and update form value/validity
        if (this.validatePrepopulatedAddress) {
            this.addressFormGroup.markAsDirty();
            const controls = Object.values(this.addressFormGroup.controls);
            controls.forEach((ctl) => {
                ctl.markAsTouched();
            });
            // trigger validation
            this.addressFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
    }

    ngOnDestroy() {
        this.overlays.forEach((overlay: OverlayRef) => {
            if (overlay && overlay.hasAttached()) {
                overlay.detach();
                overlay.dispose();
            }
        });
        this.destroy.next(null);
        this.destroy.complete();
    }

    public mapFormToPayloadValues(formValues: Partial<Address>, fieldMapping?: AddressMap): Address {
        let values = this.checkDisabledFields ? this.addressFormGroup.getRawValue() : formValues;
        return mapAddressValues(values, fieldMapping);
    }

    public validatePayload(mappedAddressValues: Address): boolean {
        return this.addressService.validateAddressPayload(mappedAddressValues);
    }

    public hasAddressBeenValidated(mappedAddressValues: Address): boolean {
        return compareAddresses(mappedAddressValues, this.verifiedAddress);
    }

    public handleVerifyAPISuccess(formAddress: any, verifiedResponse: VerifyAddressResponse, addressMap?: AddressMap) {
        if (verifiedResponse.errors && !this.addressFormGroup.pristine) {
            this.allowContinueForAddress.emit(true);
            this.openErrorModal();
        } else if (!verifiedResponse.errors) {
            // make a special check for whether or not the zip that was provided is still the same as the one that is
            // verified. this only applies for the home address because it is tied to rate calculation for plans
            const formAddressZip = formAddress.zipcode || formAddress.zip_code;
            if (!this.disableDisparateZip && formAddressZip !== verifiedResponse.address.zip_code) {
                this.openErrorModal(this.differentZipModalMessage);
                this.allowContinueForAddress.emit(false);
            } else if (this.disallowPOBox && this.checkVerifiedAddressForPOBox(verifiedResponse.address)) {
                this.setPOBoxError();
            } else {
                this.openModal(formAddress, verifiedResponse.address, addressMap);
            }
        }
    }

    public openErrorModal(errMessage: ConfirmationModalConfig = null) {
        // emit an error immediately (independent of errorConfirm to ensure backwards compatibility)
        // to block progress in scenarios where address needs to be verified to proceed
        this.addressErrorStart.emit();

        // Use CDK to attach dynamic modal component to overlay
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: ['modal__mask-modal'],
        });
        const confirmationModalComponent = new ComponentPortal(ConfirmationModalComponent);
        const containerRef = overlayRef.attach(confirmationModalComponent);

        this.overlays.push(overlayRef);

        // Handle modal component inputs and outputs
        containerRef.instance.config = errMessage ? errMessage : this.errorModalMessage;
        containerRef.instance.confirm.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.errorConfirm.emit();
            overlayRef.detach();
            overlayRef.dispose();
            this.overlays.pop();
        });
    }

    public openModal(formAddress, verifiedAddress, addressMap) {
        // Use CDK to attach dynamic modal component to overlay
        const overlayRef = this.overlay.create();
        const addressModalComponent = new ComponentPortal(AddressSelectModalComponent);
        const containerRef = overlayRef.attach(addressModalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.current = formAddress;
        containerRef.instance.verified = verifiedAddress;
        containerRef.instance.canCancel = false;

        this.overlays.push(overlayRef);

        containerRef.instance.cancel.pipe(takeUntil(this.destroy)).subscribe(() => {
            overlayRef.detach();
            overlayRef.dispose();
            this.overlays.pop();
        });

        containerRef.instance.resolve.pipe(takeUntil(this.destroy)).subscribe((val) => {
            this.allowContinueForAddress.emit(true);
            let formattedAddressValues = getAddressSelectValues(val, addressMap);

            /**
             * There is confusion over 'verified' versus 'different than USPS Record'
             * If user selects 'USPS Record' from Verify Address modal, then the address is considered verified
             * whereas if user selects 'Your Selection' it is NOT verified.
             * We're being asked to support scenarios that interpret 'Your Selection' as being verified
             */
            formattedAddressValues.verified = !!this.verifiedIfSelected ? true : formattedAddressValues.verified;

            this.addressSelect.next(formattedAddressValues);
            if (formattedAddressValues.verified) {
                this.verifiedAddress = formattedAddressValues.address as Address;
            } else {
                this.verifiedAddress = <Address>{};
            }
            overlayRef.detach();
            overlayRef.dispose();
            this.overlays.pop();
        });
    }

    public checkVerifiedAddressForPOBox(verifiedAddress): boolean {
        const streetName1Prop = verifiedAddress.street_name_1;
        return this.addressService.checkForPOBOX(streetName1Prop);
    }

    public setPOBoxError(): void {
        const streetName1Prop = this.configs.streetName1Prop || 'street_name_1';
        const poBoxValidatorName = this.configs.poBoxValidatorName || 'notPattern';
        this.addressFormGroup.get(streetName1Prop).setErrors({ [poBoxValidatorName]: { name: poBoxValidatorName } });
    }
}

/**
 * For each address, create a valueChanges Observable containing the address fields
 */
const getMappedAddressGroups = (addressMaps: AddressMaps, formGroup: FormGroup): MappedAddressGroup[] => {
    if (!addressMaps || !formGroup) return null;

    return Object.values(addressMaps).reduce((mappedAddressGroups: MappedAddressGroup[], addressMap: AddressMap) => {
        const addressProps = Object.values(addressMap).filter((prop: string) => formGroup.get(prop));

        // Combine the valueChanges of individual address fields into a single object resembling valueChanges on a form group
        const valueChanges$: Observable<any> = combineLatest(
            addressProps.map((prop) => formGroup.get(prop).valueChanges.pipe(startWith(formGroup.get(prop).value))),
            (...args) => addressProps.reduce((acc, prop, i) => ({ ...acc, [prop]: args[i] }), {})
        );

        if (addressProps.length)
            mappedAddressGroups.push({
                addressMap,
                valueChanges$,
            });
        return mappedAddressGroups;
    }, []);
};

/**
 * Generate a boolean dictionary of mapped address fields, useful when determining whether a field is an address field:
 *     const addressFieldsBoolDict = getAddressFieldsBoolDict(addressMaps);
 *     if (addressFieldsBoolDict[prop]) console.log('this is an address field');
 * @param addressMaps Address field mapping from config, e.g. { spouse: { state: 'spouse_state' }, pcp: { state: 'pcp_state' } }
 * @returns Flattened boolean dictionary of mapped address fields, e.g. { spouse_state: true, pcp_state: true }
 */
export const getAddressFieldsBoolDict = (addressMaps: AddressMaps): { [prop: string]: boolean } | {} | null => {
    if (!addressMaps || isEmptyObj(addressMaps)) return null;
    return Object.values(addressMaps).reduce((acc, addressMap) => {
        Object.values(addressMap).forEach((prop: string) => (acc[prop] = true));
        return acc;
    }, {});
};

/**
 * When an address is selected in the modal, translate the standard address field names to the mapped field names before emitting
 */
const getAddressSelectValues = (
    { address, verified }: { address: AddressMap; verified: boolean },
    addressMap: AddressMap
): { address: Object; verified: boolean } => {
    if (!addressMap) return { address, verified };

    const mappedAddressValues = Object.entries(addressMap).reduce((acc, [standardAddressProp, mappedAddressProp]: [string, string]) => {
        let val = address[standardAddressProp];

        if (altAddressFieldNames[standardAddressProp] && !val) {
            const altFieldName = altAddressFieldNames[standardAddressProp].find((prop) => address[prop]);
            if (altFieldName) val = address[altFieldName];
        }

        acc[mappedAddressProp] = val;
        return acc;
    }, {});

    return { address: mappedAddressValues, verified };
};

const mapAddressValues = (addressValues: Address, addressMap: AddressMap): Address => {
    return addressMap
        ? Object.entries(addressMap).reduce(
              (address: Address, [standardAddressProp, mappedAddressProp]: [string, string]) => ({
                  ...address,
                  [standardAddressProp]: addressValues[mappedAddressProp],
              }),
              {}
          )
        : addressValues;
};

// Compare two addreses to see if equivalent
const compareAddresses = (address1: Address, address2: Address): boolean => {
    if (address1 && address2) {
        return (
            address1.city === address2.city &&
            address1.state === address2.state &&
            address1.street_name_1 === address2.street_name_1 &&
            address1.street_name_2 === address2.street_name_2 &&
            address1.zip_code === address2.zip_code
        );
    }
};
