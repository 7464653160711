/**
 * Format config to be compatable for the DS Portal Footer component.
 * @param squidexResponse config gotten from the Squidex CMS
 */
export function formatPortalFooterSquidexResponse(squidexResponse: any): any {
    if (squidexResponse.usesDesignSystem && squidexResponse.rows) {
        // convert the columns within rows from an Array of Objects to Array of Arrays. see model change below
        // Array<{ portalFooterLine: Array<portalFooterLine> }> => Array<Array<portalFooterLine>>
        squidexResponse.rows = squidexResponse.rows.map((row) => ({ ...row, columns: row.columns.map((col) => col.portalFooterLines) }));
    }
    return squidexResponse;
}
