import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfigService } from '@zipari/shared-sbp-services';
import { ChatConfig, chatTypes } from './chat.constant';

@Component({
    selector: 'chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit {
    chatTypes = chatTypes;
    config: ChatConfig;

    constructor(public configService: ConfigService) {}

    ngOnInit() {
        const globalConfig: any = this.configService.getPageConfig('global');
        this.config = globalConfig.chat ? globalConfig.chat : null;
    }

    ngAfterViewInit() {
        if (!!this.config) {
            // allows for other integrations to be added as needed
            switch (this.config.type) {
                case chatTypes.liveagent:
                    this.handleInitiatingLiveAgent();
                    break;
            }
        }
    }

    handleInitiatingLiveAgent() {
        // this is some nastiness...
        // necessary for integrating with live agent specifically
        if (!window['_initLiveAgent']) {
            window['_initLiveAgent'] = true;

            liveagent.init(this.chatOptions.apiEndpoint, this.chatOptions.deploymentId, this.chatOptions.orgId);
        }

        delete window['_laq'];
        window['_laq'] = [];

        window['_laq'].push(() => {
            liveagent.showWhenOnline(this.chatOptions.buttonIdentifier, document.getElementById(this.liveAgentOnlineButtonText));
            liveagent.showWhenOffline(this.chatOptions.buttonIdentifier, document.getElementById(this.liveAgentOfflineButtonText));
        });
    }

    handleStartingChat() {
        switch (this.config.type) {
            case chatTypes.liveagent:
                liveagent.startChat(this.chatOptions.buttonIdentifier);
                break;
        }
    }

    public get chatOptions() {
        return this.config.options;
    }

    public get liveAgentOnlineButtonText() {
        return `liveagent_button_online_${this.chatOptions.buttonIdentifier}`;
    }

    public get liveAgentOfflineButtonText() {
        return `liveagent_button_offline_${this.chatOptions.buttonIdentifier}`;
    }
}
