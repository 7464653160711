import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { Column2, ZipTable2Service } from '@zipari/shared-sbp-modules';

@Component({
    selector: 'zip-table-generic-cell-2',
    templateUrl: './generic-cell.component.html',
})
export class GenericCellComponent {
    @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() column: Column2;
    _context;
    _cellValue = '';
    cellFormats = formatTypes;

    constructor(public ztService: ZipTable2Service) {}

    @Input('data')
    set data(data) {
        this._cellValue = this.ztService.getValueIncludingFormat(data, this.column);
        this._context = { col: this.column, val: this._cellValue, row: data };
    }

    ngOnInit() {}
}
