export class PortalUser {
    id?: number;
    first_name?: string;
    last_name?: string;
    user_name?: string;
    email_address?: string;
    email_verified?: boolean;
    phone_number?: string;
    is_active?: boolean;
    last_login?: string;
    data?: {
        title?: string;
        send_email_verification?: boolean;
        deactivation_date?: string;
        send_deactivation_email?: boolean;
        permissions?: Array<string>;
    };
    cx_id?: number;
    agency_id?: number;
    agency_name?: string;
    broker_id?: number;
    member_id?: number;
    tenant_name?: string;
    prospect_id?: number;
    license_number?: number;
    license_effective_date?: string;
    accepted_terms?: Array<string>;
    cohort_keys?: Array<string>;
    groups?: Array<Group>;
    roles?: Array<Role>;
    identities?: Identities;
    create_timestamp?: string;
    update_timestamp?: string;

    constructor() {
        this.data = {};
    }
}

class Group {
    id?: number;
    name?: string;
    category?: string;
}

class Role {
    name?: string;
    display_name?: string;
    active?: boolean;
    id?: number;
    group?: string;
    category?: string;
}

class Identities {
    is_admin?: boolean;
    members?: Array<any>;
    brokers?: Array<any>;
    employers?: Array<any>;
    providers?: Array<any>;
}
