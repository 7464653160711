import { Component, Input, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { getValue } from '@zipari/web-utils';

import { LangConfig } from './language-selector.constants';

@Component({
    selector: 'language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnDestroy {
    @Input() mode: string = 'default';
    @Input() config: LangConfig[];
    storageKey = 'zipLangISO';
    busy: Subscription;

    modalConfig;
    languages;

    constructor(public http: HttpClient) {}

    get selected(): LangConfig {
        let storedLang: LangConfig = JSON.parse(window.localStorage.getItem(this.storageKey));
        if (!storedLang) {
            storedLang = this.getBrowserLang();
            this.setLanguage(storedLang);
        }
        return storedLang;
    }

    getBrowserLang(): LangConfig {
        let preferred = this.config[0];
        const browserLang = getValue(window, 'navigator.language') || '';
        const browserLangs = getValue(window, 'navigator.languages') || [];

        [browserLang, ...browserLangs].some((iso) => {
            const match = this.config.find((lang) => {
                const a = lang.iso.toLowerCase();
                const b = iso.toLowerCase();
                return a.split('-')[0] === b.split('-')[0];
            });
            if (match) {
                preferred = match;
            }
            return !!match;
        });

        return preferred;
    }

    getClasses(root: string, iso: string): string[] {
        return [`${root}--${this.mode}`, iso === this.selected.iso && `${root}--selected`].filter((s) => !!s);
    }

    setLanguage(lang: LangConfig) {
        const oldLang: LangConfig = JSON.parse(window.localStorage.getItem(this.storageKey));
        const oldIso = oldLang ? oldLang.iso : 'en-us';

        window.localStorage.setItem(this.storageKey, JSON.stringify(lang));

        if (lang.iso !== oldIso) {
            this.busy = this.http.get(`languages/change/${lang.iso}/`).subscribe(() => {
                window.location.reload();
            });
        }
    }

    ngOnDestroy() {
        if (this.busy) {
            this.busy.unsubscribe();
        }
    }
}
