<div class="info--page container">
    <h3>{{ config?.header?.title }}</h3>
    <p>{{ config?.header?.subtitle }}</p>

    <div class="container workflow-step__cards">
        <section class="card workflow-step__card">
            <section *ngFor="let card of config.cards" class="workflow-step__card__body workflow-step__control-group">
                <div class="workflow-step__card__section" *ngFor="let section of card.sections">
                    <h6 *ngIf="section.type === 'header'" class="workflow-step__control-group__header t-bold">{{ section.text }}</h6>
                    <p *ngIf="section.type === 'text'" class="t-data" [innerHTML]="section.text"></p>
                    <ul *ngIf="section.type === 'list'">
                        <li
                            *ngFor="let item of section.items"
                            class="t-data workflow-step__control-group--list-item"
                            [innerHTML]="item"
                        ></li>
                    </ul>
                </div>
            </section>
        </section>
    </div>
</div>

<div class="info--page__footer" [ngClass]="config?.footer?.sticky ? 'sticky' : 'fixed'" *ngIf="config?.footer">
    <div class="container">
        <div class="u-flex u-justifyEnd">
            <zip-button
                *ngFor="let button of config?.footer.buttons"
                [level]="button.level"
                (zipButtonClicked)="close.emit()"
                [content]="button.content"
            >
            </zip-button>
        </div>
    </div>
</div>
