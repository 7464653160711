<div class="state-subsidy-results">
    <h6 class="t-bold">{{ config.title }}</h6>

    <div class="underline"></div>

    <div class="section" *ngFor="let section of stateSubsidyResultSections">
        <p class="t-data t-bold">{{ section?.title }}</p>

        <div class="section--text" *ngIf="section.type === 'text'">
            <p class="t-data" [innerHTML]="section?.text"></p>
        </div>
    </div>
</div>
