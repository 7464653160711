import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { Plan } from '@zipari/shared-sbp-models';
import { PlanDetailConfigs } from '../plan-detail-config.model';

@Component({
    selector: 'view-details-modal',
    templateUrl: './view-details-modal.component.html',
    styleUrls: ['./view-details-modal.component.scss'],
})
export class ViewDetailsModalComponent {
    @Input() plan: Plan;
    @Input() planDetailConfigs: PlanDetailConfigs;
    @Input() viewDetailsModalConfig: ModalConfig;
    @Input() documentList: Array<any>;
    @Output('toggleDetailsModal') toggleDetailsModal = new EventEmitter<void>();

    closeDetailsModal(): void {
        this.toggleDetailsModal.emit();
    }

    formatDocumentName(name: string): string {
        return name
            .split('_')
            .join(' ')
            .replace(/\b\w/g, function (l) {
                return l.toUpperCase();
            });
    }
}
