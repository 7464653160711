import { Address } from '@zipari/shared-sbp-models';

export class ProviderResultLocation {
    street_name_1: string;
    city_name: string;
    state: string;
    zip_code: string;
    coordinates: any;
}

export class ProviderResult {
    gender: string;
    network_code: string;
    provider_service_resrtictions: Array<any>;
    tax_id: string;
    address: Address;
    distance: string;
    provider_id: number;
    contacts: Array<any>;
    last_name: string;
    network_specific_id: string;
    languages: Array<any>;
    npi: string;
    facility: any;
    is_pcp: boolean;
    specialties: Array<any>;
    accepting_new_patients: any;
    id: number;
    first_name: string;
    picture: string;
    primary_specialty: string;
    phone: string;
    facility_locations?: Array<any>;
    location: ProviderResultLocation;
    selected: boolean;
    board_certifications: Array<any>;
    boardCertification: string;
    medical_groups?: Array<any>;
    medical_group: string;
    hospital_affiliations?: Array<any>;
    degree?: any;
    residency?: string;
    internship?: string;
}

export class AsideHeaderConfig {
    header: string;
    subHeader: string;
    showAsideHeader: boolean;
}
