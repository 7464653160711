import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { AttributeGridConfig, AttributeGridSection } from './attribute-grid.constants';

@Component({
    selector: 'attribute-grid',
    templateUrl: './attribute-grid.component.html',
    styleUrls: ['./attribute-grid.component.scss'],
})
export class AttributeGridComponent implements OnInit {
    @Input() config: AttributeGridConfig;
    @Input() context;
    @Output() edit = new EventEmitter();

    showEditModal: boolean;
    showCancelEditModal: boolean;

    sections: Array<AttributeGridSection>;
    sectionEditing: AttributeGridSection;
    form: FormGroup;

    constructor(private formControlService: FormControlService) {}

    ngOnInit() {
        this.sections = this.config.sections;

        this.form = new FormGroup({});

        this.sections.forEach((section) => {
            const key = section.key;
            this.form.addControl(key, new FormGroup({}));

            if (!!section?.edit?.controls) {
                section.edit.controls.forEach((control) => {
                    this.formControlService.addControlToFormGroup(this.form.get(key), control);
                });
            }
        });
    }

    toggleEditModal(section?: AttributeGridSection) {
        if (section) {
            this.sectionEditing = section;
            this.edit.emit(section.key);
        } else {
            this.sectionEditing = null;
            this.edit.emit(null);
        }
    }
}
