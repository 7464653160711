export const SYSTEM_ERROR_CODES = [
    'HE009999',
    'HE200041',
    'HX005000',
    'HX005001',
    'HX009000',
    'HX200004',
    'HX200005',
    'HX200009',
    'HX200010',
];
