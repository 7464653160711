import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule, FormattingService, ModelAttributeModule } from '@zipari/design-system';
import { SharedModule } from '../shared.module';
import { MemberCardComponent } from './member-card.component';

@NgModule({
    declarations: [MemberCardComponent],
    imports: [CommonModule, SharedModule, CardModule, ModelAttributeModule],
    providers: [FormattingService],
    exports: [MemberCardComponent],
})
export class MemberCardModule {}
