import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { RestructuredSignaturesConfig } from './signatures.constants';

@Component({
    selector: 'signature-card',
    templateUrl: './signature-card.component.html',
    styleUrls: ['./signature-card.component.scss'],
})
export class SignatureCardComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() signatures: Array<RestructuredSignaturesConfig>;
    @Input() signatureName;

    signatureForm: FormArray;

    constructor(private formControlService: FormControlService) {}

    ngOnInit() {
        this.initFormGroup();
    }

    private initFormGroup(): void {
        this.signatureForm = new FormArray([]);
        if (this.signatures && this.signatures.length) {
            this.signatures.forEach((signature: RestructuredSignaturesConfig, index: number) => {
                this.setControlDependencies(signature);
                this.formControlService.addFormGroupToFormGroup(this.signatureForm, signature, index);
            });

            this.formGroup.addControl('signatures', this.signatureForm);
        }
    }

    private setControlDependencies(signature): void {
        signature.controls.forEach(({ validators, prop }) => {
            if (validators) {
                if (prop === 'middle' && this.signatureName['middle']) {
                    validators.push('required');
                }
                validators.forEach((validator) => {
                    if (typeof validator === 'object' && validator.name === 'equals') {
                        // prop is going to be one of "first", "middle", or "last"
                        validator.dependency = this.signatureName[prop];
                    }
                });
            }
        });
    }
}
