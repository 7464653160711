<card>
    <div class="title--section" title>
        <div class="error--section" *ngIf="!success && errorMessage">
            <p class="t-bold t-error u-flex u-justifyCenter" *ngFor="let error of errorMessage">
                {{ error }}
            </p>
        </div>

        <div class="titles--section">
            <h4 class="t-bold" [innerHTML]="success && config?.successTitle ? config?.successTitle : config?.title"></h4>

            <p class="t-data" [innerHTML]="success && config?.successSubtitle ? config?.successSubtitle : config?.subtitle"></p>
        </div>
    </div>

    <div class="body--section" body>
        <div class="body--content">
            <form-group
                class="body-select-name"
                (linkClicked)="linkClicked.emit($event.config)"
                (formCreated)="patchDOB()"
                [configs]="config?.form?.controls"
                [form]="formGroup"
            ></form-group>
        </div>

        <ng-container *ngIf="browserSupportConfig?.showBanner && !isBrowserSupported">
            <message-banner [config]="browserSupportConfig.banner"> </message-banner>
        </ng-container>

        <div class="body--footer">
            <p
                *ngIf="config?.footer?.link"
                class="t-data t-link"
                (click)="linkClicked.emit(config?.footer?.link)"
                [innerHTML]="config?.footer?.link?.label"
            ></p>

            <zip-button
                data-test-id="logIn"
                (zipButtonClicked)="buttonClicked.emit()"
                [content]="config?.footer?.button?.content"
                [disabled]="disableButton || !formGroup.valid"
                [icon]="config?.footer?.button?.icon"
                [level]="config?.footer?.button?.level"
            >
            </zip-button>
        </div>
    </div>
</card>
