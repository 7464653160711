<header
    #stepperHeader
    *ngIf="authenticated"
    class="enrollment__header enrollment__header--desktop {{ displayType }}"
    [ngClass]="{ 'with--nav': withNav, 'with--toolbar': withToolbar }"
>
    <div (click)="handleScrollClicked(overflowDirections.left)" *ngIf="showLeftScrollIcon" class="steps--control steps--control--left">
        <i class="icon icon--medium">chevron_left</i>
    </div>

    <div class="container">
        <div (scroll)="checkForOverflow()" class="enrollment__header__steps" #headerStepsContainer>
            <div
                [id]="step?.uniqueId"
                class="enrollment__header__step"
                [ngClass]="{
                    'is-clickable': clickableSteps,
                    'is-visited': i + 1 < activeStepIndex,
                    'is-active': useStepType ? step.type === activeStepType : i + 1 === activeStepIndex
                }"
                *ngFor="let step of steps; let i = index"
            >
                <ng-container *ngIf="step.label">
                    <div class="status--indicator" *ngIf="displayType === validStepperTypes.check">
                        <i class="icon icon--small" *ngIf="i + 1 < activeStepIndex; else notVisited"> check </i>

                        <ng-template #notVisited>
                            <p class="t-data">
                                {{ step.visibleStepIndex }}
                            </p>
                        </ng-template>
                    </div>

                    <i class="icon icon--medium" *ngIf="displayType === validStepperTypes.iconDisplay && step.icon">
                        {{ step.icon }}
                    </i>

                    <p
                        [ngClass]="{ 't-bold': i + 1 < activeStepIndex }"
                        class="t-data t-alt"
                        (click)="
                            i + 1 < activeStepIndex ? stepIsClicked({ displayIndex: step.stepIndex, step: step }, clickableSteps) : null
                        "
                    >
                        {{ getStepLabel(step) }}
                    </p>

                    <div
                        class="divider"
                        *ngIf="
                            i + 1 !== steps.length &&
                            (displayType === validStepperTypes.check || displayType === validStepperTypes.iconDisplay)
                        "
                    ></div>

                    <i class="icon icon--medium" *ngIf="i + 1 !== steps.length && displayType === validStepperTypes.default">
                        carat_right
                    </i>
                </ng-container>
            </div>
        </div>

        <div
            (click)="handleScrollClicked(overflowDirections.right)"
            *ngIf="showRightScrollIcon"
            class="steps--control steps--control--right"
        >
            <i class="icon icon--medium">chevron_right</i>
        </div>
    </div>
</header>

<header class="enrollment__header enrollment__header--tablet-mobile" [ngClass]="{ 'with--toolbar': withToolbar }">
    <div class="container">
        <div [ngClass]="{ open: showMobileStepper }" class="stepper--label" *ngIf="steps">
            <div class="u-flex">
                <i
                    (click)="toggleShowMobileStepper()"
                    class="icon icon--medium icon--pointer"
                    [ngClass]="{ 'icon--rotate180': showMobileStepper }"
                >
                    chevron_down
                </i>

                <p class="t-data t-bold">
                    Step {{ activeStepIndex }} of {{ steps.length }}: {{ getStepLabel(steps[activeStepIndex - 1]) }}
                </p>
            </div>
            <p class="t-data t-alt" *ngIf="steps[activeStepIndex]">Next: {{ getStepLabel(steps[activeStepIndex]) }}</p>
        </div>
        <div class="other--steps" *ngIf="showMobileStepper" @accordion>
            <div
                [ngClass]="{
                    'is-visited': i + 1 < activeStepIndex,
                    'is-active': i + 1 === activeStepIndex
                }"
                class="step--display"
                *ngFor="let step of steps; let i = index"
            >
                <div class="indicator"></div>
                <p class="t-data">{{ getStepLabel(step) }}</p>
            </div>
        </div>
    </div>
</header>
