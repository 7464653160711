import { Component } from '@angular/core';
import { ModalConfig, ModalHeader, ModalTypes } from '@zipari/shared-ds-util-modal';
import { GlobalConfig } from '@zipari/shared-sbp-constants';
import { ConfigService, IdleService } from '@zipari/shared-sbp-services';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { TimeoutWarningModalConfig } from './timeout-modal.constants';

@Component({
    selector: 'timeout-modal',
    templateUrl: './timeout-modal.component.html',
    styleUrls: ['./timeout-modal.component.scss'],
})
export class TimeoutModalComponent {
    config: TimeoutWarningModalConfig;
    btn_continue: string;
    btn_logout: string;

    modalConfig = new ModalConfig({
        clickOutside: false,
        type: ModalTypes.popup,
        header: new ModalHeader({
            showClose: false,
        }),
    });

    constructor(private configService: ConfigService, public idleService: IdleService) {}

    ngOnInit() {
        this.setConfig();
    }

    ngOnDestroy() {
        this.idleService.stopTimeout();
    }

    setConfig() {
        const globalConfig = this.configService.getPageConfig<GlobalConfig>('global');
        const timeoutConfig = getValue(globalConfig, 'timeout.timeoutWarningModal');
        this.config = new TimeoutWarningModalConfig(timeoutConfig);
        this.config.body = globalConfig?.timeout?.idle?.body ? globalConfig?.timeout?.idle?.body : this.config.body;
        this.modalConfig.header.title = globalConfig?.timeout?.idle?.title ? globalConfig?.timeout?.idle?.title : this.config?.title;
        this.btn_continue = globalConfig?.timeout?.idle?.btn_continue ? globalConfig?.timeout?.idle?.btn_continue : 'Extend Session';
        this.btn_logout = globalConfig?.timeout?.idle?.btn_logout ? globalConfig?.timeout?.idle?.btn_logout : 'Logout';
    }

    handleCancel() {
        this.idleService.resetTimeout();
    }

    handleLogout() {
        this.idleService.handleLogout();
    }

    formatCountdown(seconds: number): string {
        if (!seconds) return '0';

        const dateStr = new Date(seconds * 1000).toISOString();
        let timeStr = dateStr.substr(11, 8); // HH:mm:ss
        return timeStr.replace(/^0(?:0:0?)?/, '');
    }

    public get modalBody(): string {
        const formattedCountdown = this.formatCountdown(this.countdown);
        return stringBuilder(this.config.body, { countdown: `<strong>${formattedCountdown}</strong>` });
    }

    public get countdown(): number {
        return this.idleService.timeoutCountdown;
    }

    public get isIdling(): boolean {
        return this.idleService.isIdling;
    }
}
