import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@zipari/shared-sbp-services';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { PersonSearchResults } from '../workflow-ede/apiModels/person-search/workflow-ede-person-search-response.constants';
import { SearchByDemographic, SearchBySSN } from '../workflow-ede/apiModels/person-search/workflow-ede-person-search-request.constants';

@Injectable()
export class PersonSearchService {
    constructor(private loggingService: LoggerService, private http: HttpClient) {}

    personSearch(body: SearchByDemographic | SearchBySSN | {}, options: { mock?: boolean } = {}): Observable<PersonSearchResults> {
        const emptyResults = { data: [] };

        if (options.mock) {
            return of(emptyResults);
        }

        return this.http.post<PersonSearchResults>('/api/ede/person_search/', body).pipe(
            catchError((error) => {
                this.loggingService.error(error);

                // @TODO: (tech debt) revisit person search error handling, we don't necessarily want to proceed on every error type https://jira.zipari.net/browse/CE-3617
                return of(emptyResults);
            })
        );
    }
}
