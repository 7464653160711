export default class Appeal {
    id: number;
    date_created: string;
    appeals_description: string;
    service_appealed: string;
    service_sub_type: string;
    service_date: string;
    supporting_documents: string;
    approval_status: 'pending' | 'approved' | 'denied';
    appeal_level: string;
    amount_disputed: number;
    appeal_age: number;
    appeal_source: string;
    reason_for_appeal: string;
    claim_number: number;
    date_of_service: string;
}
