import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';
import { isArray } from 'util';
import { MARKET_SEGMENTS } from '@zipari/shared-sbp-constants';
import { ApiListResponse, GetStartedFormValue, MultiPlanTypes, Plan } from '@zipari/shared-sbp-models';
import { ConfigService, PlanUtilService } from '@zipari/shared-sbp-services';
import { coverageType, pagination } from '@zipari/shared-sbp-templates';

@Injectable()
export class PlansAPIService {
    private plansEndpoint = 'api/enrollment/plans/?ordering=display_name';
    private endpoint = 'api/shopping/plans/';

    private plansCache = new Map<string, Observable<ApiListResponse<Plan>>>();

    constructor(private httpClient: HttpClient, private configService: ConfigService, private planUtilService: PlanUtilService) {}

    public fetchPlans(params = {}, endpoint = this.plansEndpoint): Observable<ApiListResponse<Plan>> {
        const httpParams = new HttpParams({ fromObject: params });
        const cacheKey = endpoint + httpParams.toString();

        const fetchPlansCall = this.httpClient.get<ApiListResponse<Plan>>(endpoint, { params: httpParams }).pipe(
            shareReplay(),
            map((response) => {
                // need to map the results so that none of the BE plan data comes in as selected
                response.results = response.results.map((plan, index) => ({
                    ...plan,
                    selected: false,
                    comparing: false,
                    index: index,
                }));

                return response;
            })
        );

        const cacheHit = !!this.plansCache.get(cacheKey);

        if (!cacheHit) {
            this.plansCache.set(cacheKey, fetchPlansCall);
        }

        // pipe the delay to simulate busy for the user
        const responseDelay = cacheHit ? 200 : 0;

        return this.plansCache.get(cacheKey).pipe(delay(responseDelay));
    }

    public getShoppingPlans(
        effectiveDate: string,
        getStartedFormValue: GetStartedFormValue,
        endpoint = this.endpoint
    ): Observable<ApiListResponse<Plan>> {
        const year = effectiveDate?.slice(0, 4) || new Date().getFullYear();
        const isMedicare = this.configService.app.indexOf(MARKET_SEGMENTS.MEDICARE) > -1;

        const { coverage_type } = getStartedFormValue;
        const coverageType = Array.isArray(coverage_type) ? coverage_type : [coverage_type];
        const param = {
            county_code: getStartedFormValue.county,
            zipcode: getStartedFormValue.zipcode,
            coverage_effective_date: effectiveDate,
            year: year,
            market_segment: isMedicare ? MARKET_SEGMENTS.MEDICARE : MARKET_SEGMENTS.INDIVIDUAL,
            plan_type: !isMedicare ? this.getFilteredPlanType(coverageType) : '',
            page_size: pagination.page_size,
            page: pagination.page,
        };

        const httpParams = new HttpParams({ fromObject: param });

        return this.httpClient.get<ApiListResponse<Plan>>(endpoint, { params: httpParams }).pipe(
            shareReplay(),
            map((response: ApiListResponse<Plan>) => ({
                ...response,
                results: response.results.map((plan: Plan, index: number) => ({
                    ...plan,
                    index,
                })),
            }))
        );
    }

    private getFilteredPlanType(coverageTypes: string[]): string {
        const filteredCoverageTypes = coverageTypes.filter(Boolean);
        const filteredIndex = filteredCoverageTypes.findIndex((coveragType) => coveragType === coverageType.health);

        if (filteredIndex !== -1) {
            filteredCoverageTypes[filteredIndex] = coverageType.medical;
            return filteredCoverageTypes.join(',');
        } else {
            return this.getMultiPlanTypes(coverageTypes);
        }
    }

    private getMultiPlanTypes(coverageTypes: string[]): string {
        let planTypes: string[];

        const multiplanTypes = this.planUtilService.multiPlanTypes;
        const filteredCoverageTypes = coverageTypes.filter(Boolean);

        if (multiplanTypes?.length > 0) {
            planTypes = multiplanTypes
                .filter((planType) => !planType.conditional || planType.conditional)
                .map((planType: MultiPlanTypes) =>
                    planType.conditional ? this.getConditionalPlanType(filteredCoverageTypes, planType) : planType.prop
                );
        } else {
            return filteredCoverageTypes.join(',');
        }

        return planTypes?.filter(Boolean).join(',');
    }

    private getConditionalPlanType(filteredCoverageTypes: string[], planType: MultiPlanTypes) {
        const filteredPlanType = filteredCoverageTypes.find((coverageType: string) => planType.conditional[coverageType]);

        return !!filteredPlanType ? planType.prop : '';
    }
}
