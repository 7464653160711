<div class="auth--card">
    <ng-content select="[authCard]"></ng-content>
</div>

<div class="auth-banner__hero">
    <img [src]="layoutOptions.heroImg" alt="Authentication Banner Hero" />
</div>

<div class="MoreInfo">
    <div class="MoreInfo__Header">
        @if(layoutOptions?.header?.icon) {
        <img [src]="layoutOptions?.header?.icon" alt="Header Icon" />
        } @else {
        <icon
            data-test-id="moreInfoHeaderIcon"
            class="MoreInfo__HeaderIcon"
            [config]="{ size: 'large', fill: 'primary' }"
            [name]="layoutOptions?.header?.iconName || 'cover_who'"
        ></icon>
        }
        <h4 class="t-bold" [innerHTML]="layoutOptions?.header?.text"></h4>
    </div>

    <div class="sections">
        <div class="section" *ngFor="let section of layoutOptions?.sections">
            <h4 class="t-bold">{{ section?.title }}</h4>

            <div class="underline"></div>

            <div class="section--text" *ngIf="section.type === 'text'">
                <p class="t-data" [innerHTML]="section?.text"></p>
            </div>

            <div class="section--list" *ngIf="section?.type === 'list'">
                <div class="list--item" *ngFor="let item of section.list">
                    <i class="icon icon--medium">{{ item.icon || 'check' }}</i>
                    <p class="t-data" [innerHTML]="item?.text"></p>
                </div>
            </div>

            <div class="section--links" *ngIf="section.type === 'links'">
                <p *ngIf="section?.description" class="t-data" [innerHTML]="section?.description"></p>

                <a target="_blank" class="t-data t-link" [href]="link.url" *ngFor="let link of section?.links">
                    {{ link.label }} <i class="icon icon--small icon--link">{{ link.icon || 'chevron_right' }}</i>
                </a>
            </div>

            <zip-button [config]="section?.button"></zip-button>
        </div>
    </div>
</div>
