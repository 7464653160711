import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AgencyService {
    private agencyEndpoint = 'api/enrollment/agencies/';
    constructor(private http: HttpClient) {}

    getAgency(agencyId) {
        return this.http.get(`${this.agencyEndpoint}${agencyId}/`);
    }

    getAgencies() {
        return this.http.get(`${this.agencyEndpoint}`);
    }
}
