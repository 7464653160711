export const unauthorizedFileTypes = [
    '_exe',
    'a6p',
    'ac',
    'acr',
    'action',
    'air',
    'apk',
    'app',
    'applescript',
    'awk',
    'bas',
    'bat',
    'bin',
    'cgi',
    'chm',
    'cmd',
    'com',
    'cpl',
    'crt',
    'csh',
    'dek',
    'dld',
    'dll',
    'dmg',
    'drv',
    'ds',
    'ebm',
    'elf',
    'emf',
    'esh',
    'exe',
    'ezs',
    'fky',
    'frs',
    'fxp',
    'gadget',
    'gpe',
    'gpu',
    'hlp',
    'hms',
    'hta',
    'icd',
    'iim',
    'inf',
    'ins',
    'inx',
    'ipa',
    'ipf',
    'isp',
    'isu',
    'jar',
    'js',
    'jse',
    'jsp',
    'jsx',
    'kix',
    'ksh',
    'lib',
    'lnk',
    'mcr',
    'mel',
    'mem',
    'mpkg',
    'mpx',
    'mrc',
    'ms',
    'msc',
    'msi',
    'msp',
    'mst',
    'mxe',
    'obs',
    'ocx',
    'pas',
    'pcd',
    'pex',
    'pif',
    'pkg',
    'pl',
    'plsc',
    'pm',
    'prc',
    'prg',
    'pvd',
    'pwc',
    'py',
    'pyc',
    'pyo',
    'qpx',
    'rbx',
    'reg',
    'rgs',
    'rox',
    'rpj',
    'scar',
    'scpt',
    'scr',
    'script',
    'sct',
    'seed',
    'sh',
    'shb',
    'shs',
    'spr',
    'sys',
    'thm',
    'tlb',
    'tms',
    'u3p',
    'udf',
    'url',
    'vb',
    'vbe',
    'vbs',
    'vbscript',
    'vdo',
    'vxd',
    'wcm',
    'widget',
    'wmf',
    'workflow',
    'wpk',
    'ws',
    'wsc',
    'wsf',
    'wsh',
    'xap',
    'xqt',
    'zlq',
    'application/x-msdownload',
    'application/x-authorware-bin',
    'application/vnd.acucobol',
    'application/vnd.acucorp',
    'application/x-action',
    'application/vnd.adobe.air-application-installer-package+zip',
    'application/vnd.android.package-archive',
    'application/applescript',
    'application/x-awk',
    'application/octet-stream',
    'application/cgi',
    'application/vnd.ms-htmlhelp',
    'application/x-csh',
    'application/x-debian-package',
    'application/x-dms',
    'application/x-dld',
    'application/x-dmg',
    'application/x-ezscript',
    'application/x-kix',
    'application/x-pkcs10',
    'application/x-sh',
    'application/x-shar',
    'application/x-ksh',
    'application/x-elf',
    'application/emf',
    'application/x-esh',
    'application/x-gzip',
    'application/javascript',
    'application/x-javascript',
    'application/vnd.ms-powerpoint',
    'application/x-matroska',
    'application/x-msexcel',
    'application/x-msmediaview',
    'application/x-msmetafile',
    'application/x-msmoney',
    'application/x-perl',
    'application/x-pc-dos',
    'application/vnd.ms-publisher',
    'application/vnd.ms-excel',
    'application/x-sharedlib',
    'application/vnd.micrografx.flo',
    'application/x-pcl',
    'application/vnd.mophun.certificate',
    'application/x-python-code',
    'application/x-python-code',
    'application/x-python-code',
];
