import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { cloneObject, getValue, stringBuilder } from '@zipari/web-utils';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import { ConfigService } from '@zipari/shared-sbp-services';
import { noCoverageConfig, noPlanConfig, healthcareButton } from './de-state-error-banner.constants';

@Component({
    selector: 'de-state-error-banner',
    templateUrl: './de-state-error-banner.component.html',
    styleUrls: ['./de-state-error-banner.component.scss'],
})
export class DeStateErrorBannerComponent implements OnChanges {
    @Input() application;
    @Input() fetchEligibility;
    @Input() showNoPlanBanner: boolean = true;
    @Output() shopAgain = new EventEmitter();

    noCoverageConfig: MessageBannerConfig = noCoverageConfig;
    noPlanConfig: MessageBannerConfig = noPlanConfig;

    config: MessageBannerConfig;

    showError: boolean = false;

    constructor(private configService: ConfigService, public http: HttpClient) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.application != undefined && this.fetchEligibility != undefined) {
            this.showError = false;
            this.config = undefined;
            const contactInfo = this.fetchEligibility.ContactInformation.find((obj) => obj.hasOwnProperty('ContactMailingAddress'));
            const { LocationCountyName, LocationCountyCode, LocationPostalCode, LocationStateUSPostalServiceCode } =
                contactInfo['ContactMailingAddress'];
            const pageConfig = this.configService.getPageConfig('ede');
            const states = getValue(pageConfig, 'getStarted.confirm.coverageState.options');
            if (this.application.permanent_address.state !== LocationStateUSPostalServiceCode) {
                if (states.find((state) => state.value === LocationStateUSPostalServiceCode)) {
                    if (this.showNoPlanBanner) {
                        this.config = noPlanConfig;
                        this.shopAgain.emit();
                    }
                } else {
                    this.config = noCoverageConfig;
                }
                this.config.message = stringBuilder(this.config.message, {
                    state: LocationStateUSPostalServiceCode,
                });
                this.showError = true;
            } else {
                const zip = LocationPostalCode.split('-')[0];
                if (this.application.permanent_address.zipcode !== zip) {
                    this.http.get(`api/enrollment/locations/zipcodes/${zip}/`).subscribe((response) => {
                        const county = response['results'].find(
                            (county) => county.county_name.toLowerCase() === LocationCountyName.toLowerCase()
                        );
                        if (!county || !county.has_plans_in_service) {
                            this.config = noCoverageConfig;
                            this.config.message = stringBuilder(this.config.message, {
                                state: county.county_name,
                            });
                            this.showError = true;
                        }
                    });
                }
            }
        }
    }

    healthCareDotGovRedirect() {
        window.open('https://www.healthcare.gov/login', '_blank');
    }
}
