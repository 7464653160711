import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@zipari/shared-sbp-services';

import { documentEndpoint, tableConfig } from './document-center.constant';

@Component({
    selector: 'document-center',
    templateUrl: './document-center.component.html',
    styleUrls: ['./document-center.component.scss'],
})
export class DocumentCenterComponent implements OnInit {
    endpoint;
    tableConfig;
    tabConfig: any;
    documentTabConfig = [];
    tabLabels;

    constructor(private configService: ConfigService) {}

    ngOnInit() {
        this.tableConfig = tableConfig;
        this.tabConfig = this.configService.getPageConfig('document-center');
        this.documentTabConfig = this.tabConfig.tabLabels;
        this.tabLabels = this.documentTabConfig.map((l) => l.label);
        this.onDocTabSelected(0);
    }

    cellClicked(data) {
        const docID = data.context.row.id;
        switch (data.action) {
            case 'print':
                console.log(data.action);
                break;
            case 'download':
                window.location.assign(this.tableConfig.endpoint + docID);
                break;
            case 'claims':
                console.log(data.action);
                break;
            default:
                console.log('No Actions Provided', data);
        }
    }

    onDocTabSelected(event?: any) {
        const api = `${documentEndpoint}folders/`;
        const folder = this.documentTabConfig[event].value;
        this.tableConfig.endpoint = api + folder;
    }
}
