// used in order to run cleanup on the EDE workflow questions...
// I (newman) created a system to quickly attempt to generate the structure for all of the options that may be necessary for controls
// IMPORTANT: THIS SHOULD NOT BE TREATED AS A SOURCE OF TRUTH... IT WAS JUST A QUICK WAY TO CLEANUP AN OVERWHELMING NUMBER OF QUESTIONS

export const incomeDifferenceReason = {
    'Hours at ${employerName} were reduced': 'Decrease_Hours',
    'Cut wages or salary at ${employerName}': 'Wage_Cut',
    'Stopped working at ${employerName}': 'Stopped_Working',
    'A reason not listed above': 'SES',
};
export const renewEligibility = {
    '1 year': '1 year',
    '2 year': '2 year',
    '3 year': '3 year',
    '4 year': '4 year',
    '5 year': '5 year',
    "Don't renew eligibility": "Don't renew eligibility",
};

export const coverageTypes = {
    Medicare: 'Medicare',
    TRICARE: 'TRICARE',
    'Veterans Affairs (VA) Health Care Program': 'Veterans Affairs (VA) Health Care Program',
    'Marketplace coverage': 'Marketplace coverage',
    '${stateMedicaidProgramName}': '${stateMedicaidProgramName}',
    'Peace Corps': 'Peace Corps',
    'Other full benefit coverage': 'Other full benefit coverage',
    'Other limited benefit coverage': 'Other limited benefit coverage',
};
export const lcsopPremiumFrequencyTypes = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly',
    Biweekly: 'Biweekly',
    'Twice a month': 'Twice a month',
};
export const familyRelationshipsBetweenApplicants = {
    Parent: 'Parent',
    Stepparent: 'Stepparent',
    'Son/Daughter': 'Son/Daughter',
    'Stepson/Stepdaughter': 'Stepson/Stepdaughter',
};

export const incomeFrequency = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly',
    'Every 2 weeks': 'Every 2 weeks',
    'Twice a month': 'Twice a month',
    'One time only': 'One time only',
};

export const maritalStatusList = {
    Single: 'UNMARRIED',
    Married: 'MARRIED',
};
export const reasonsForCoverageEnding = {
    Other: 'Other',
    "Coverage wasn't affordable": "Coverage wasn't affordable",
    "${ParentFNLNS}'s employer no longer provided coverage to ${ParentFNLNS} and ${ParentFNLNS}'s dependents":
        "${ParentFNLNS}'s employer no longer provided coverage to ${ParentFNLNS} and ${ParentFNLNS}'s dependents",
    "${ParentFNLNS}'s employment status changed, and now ${ParentFNLNS}'s children aren't eligible for coverage through ${ParentFNLNS}'s employer":
        "${ParentFNLNS}'s employment status changed, and now ${ParentFNLNS}'s children aren't eligible for coverage through ${ParentFNLNS}'s employer",
    "${ParentFNLNS}'s child has special health care needs that are not being met by employer coverage":
        "${ParentFNLNS}'s child has special health care needs that are not being met by employer coverage",
    "${ParentFNLNS}'s child lost coverage because of divorce or death of a parent":
        "${ParentFNLNS}'s child lost coverage because of divorce or death of a parent",
};
export const incarcerationTypes = {
    Yes: 'Pending Disposition',
    No: 'Incarcerated',
};

export const citizenshipNumberTypes = {
    'Alien number': 'Alien number',
    'I-94 number': 'I-94 number',
};

export const passportIssuingCountries = [
    {
        value: 'ABW',
        display: 'Aruba',
    },
    {
        value: 'AFG',
        display: 'Afghanistan',
    },
    {
        value: 'AGO',
        display: 'Angola',
    },
    {
        value: 'AIA',
        display: 'Anguilla',
    },
    {
        value: 'ALA',
        display: 'Eland Islands',
    },
    {
        value: 'ALB',
        display: 'Albania',
    },
    {
        value: 'AND',
        display: 'Andorra',
    },
    {
        value: 'ANT',
        display: 'Netherlands Antilles',
    },
    {
        value: 'ARE',
        display: 'United Arab Emirates',
    },
    {
        value: 'ARG',
        display: 'Argentina',
    },
    {
        value: 'ARM',
        display: 'Armenia',
    },
    {
        value: 'ASM',
        display: 'American Samoa',
    },
    {
        value: 'ATA',
        display: 'Antarctica',
    },
    {
        value: 'ATF',
        display: 'French Southern Territories',
    },
    {
        value: 'ATG',
        display: 'Antigua And Barbuda',
    },
    {
        value: 'AUS',
        display: 'Australia',
    },
    {
        value: 'AUT',
        display: 'Austria',
    },
    {
        value: 'AZE',
        display: 'Azerbaijan',
    },
    {
        value: 'BDI',
        display: 'Burundi',
    },
    {
        value: 'BEL',
        display: 'Belgium',
    },
    {
        value: 'BEN',
        display: 'Benin',
    },
    {
        value: 'BFA',
        display: 'Burkina Faso',
    },
    {
        value: 'BGD',
        display: 'Bangladesh',
    },
    {
        value: 'BGR',
        display: 'Bulgaria',
    },
    {
        value: 'BHR',
        display: 'Bahrain',
    },
    {
        value: 'BHS',
        display: 'Bahamas',
    },
    {
        value: 'BIH',
        display: 'Bosnia And Herzegovina',
    },
    {
        value: 'BLM',
        display: 'Saint Barthelemy',
    },
    {
        value: 'BLR',
        display: 'Belarus',
    },
    {
        value: 'BLZ',
        display: 'Belize',
    },
    {
        value: 'BMU',
        display: 'Bermuda',
    },
    {
        value: 'BOL',
        display: 'Bolivia, Plurinational State Of',
    },
    {
        value: 'BRA',
        display: 'Brazil',
    },
    {
        value: 'BRB',
        display: 'Barbados',
    },
    {
        value: 'BRN',
        display: 'Brunei Darussalam',
    },
    {
        value: 'BTN',
        display: 'Bhutan',
    },
    {
        value: 'BVT',
        display: 'Bouvet Island',
    },
    {
        value: 'BWA',
        display: 'Botswana',
    },
    {
        value: 'CAF',
        display: 'Central African Republic',
    },
    {
        value: 'CAN',
        display: 'Canada',
    },
    {
        value: 'CCK',
        display: 'Cocos (Keeling) Islands',
    },
    {
        value: 'CHE',
        display: 'Switzerland',
    },
    {
        value: 'CHL',
        display: 'Chile',
    },
    {
        value: 'CHN',
        display: 'China',
    },
    {
        value: 'CIV',
        display: "CÔTE D'IVOIRE",
    },
    {
        value: 'CMR',
        display: 'Cameroon',
    },
    {
        value: 'COD',
        display: 'Congo, Democratic Republic Of The',
    },
    {
        value: 'COG',
        display: 'Congo',
    },
    {
        value: 'COK',
        display: 'Cook Islands',
    },
    {
        value: 'COL',
        display: 'Colombia',
    },
    {
        value: 'COM',
        display: 'Comoros',
    },
    {
        value: 'CPV',
        display: 'Cape Verde',
    },
    {
        value: 'CRI',
        display: 'Costa Rica',
    },
    {
        value: 'CUB',
        display: 'Cuba',
    },
    {
        value: 'CXR',
        display: 'Christmas Island',
    },
    {
        value: 'CYM',
        display: 'Cayman Islands',
    },
    {
        value: 'CYP',
        display: 'Cyprus',
    },
    {
        value: 'CZE',
        display: 'Czech Republic',
    },
    {
        value: 'DEU',
        display: 'Germany',
    },
    {
        value: 'DJI',
        display: 'Djibouti',
    },
    {
        value: 'DMA',
        display: 'Dominica',
    },
    {
        value: 'DNK',
        display: 'Denmark',
    },
    {
        value: 'DOM',
        display: 'Dominican Republic',
    },
    {
        value: 'DZA',
        display: 'Algeria',
    },
    {
        value: 'ECU',
        display: 'Ecuador',
    },
    {
        value: 'EGY',
        display: 'Egypt',
    },
    {
        value: 'ERI',
        display: 'Eritrea',
    },
    {
        value: 'ESH',
        display: 'Western Sahara',
    },
    {
        value: 'ESP',
        display: 'Spain',
    },
    {
        value: 'EST',
        display: 'Estonia',
    },
    {
        value: 'ETH',
        display: 'Ethiopia',
    },
    {
        value: 'FIN',
        display: 'Finland',
    },
    {
        value: 'FJI',
        display: 'Fiji',
    },
    {
        value: 'FLK',
        display: 'Falkland Islands (Malvinas)',
    },
    {
        value: 'FRA',
        display: 'France',
    },
    {
        value: 'FRO',
        display: 'Faroe Islands',
    },
    {
        value: 'FSM',
        display: 'Micronesia, Federated States Of',
    },
    {
        value: 'GAB',
        display: 'Gabon',
    },
    {
        value: 'GBR',
        display: 'United Kingdom',
    },
    {
        value: 'GEO',
        display: 'Georgia',
    },
    {
        value: 'GGY',
        display: 'Guernsey',
    },
    {
        value: 'GHA',
        display: 'Ghana',
    },
    {
        value: 'GIB',
        display: 'Gibraltar',
    },
    {
        value: 'GIN',
        display: 'Guinea',
    },
    {
        value: 'GLP',
        display: 'Guadeloupe',
    },
    {
        value: 'GMB',
        display: 'Gambia',
    },
    {
        value: 'GNB',
        display: 'Guinea-Bissau',
    },
    {
        value: 'GNQ',
        display: 'Equatorial Guinea',
    },
    {
        value: 'GRC',
        display: 'Greece',
    },
    {
        value: 'GRD',
        display: 'Grenada',
    },
    {
        value: 'GRL',
        display: 'Greenland',
    },
    {
        value: 'GTM',
        display: 'Guatemala',
    },
    {
        value: 'GUF',
        display: 'French Guiana',
    },
    {
        value: 'GUM',
        display: 'Guam',
    },
    {
        value: 'GUY',
        display: 'Guyana',
    },
    {
        value: 'HKG',
        display: 'Hong Kong',
    },
    {
        value: 'HMD',
        display: 'Heard Island And McDonald Islands',
    },
    {
        value: 'HND',
        display: 'Honduras',
    },
    {
        value: 'HRV',
        display: 'Croatia',
    },
    {
        value: 'HTI',
        display: 'Haiti',
    },
    {
        value: 'HUN',
        display: 'Hungary',
    },
    {
        value: 'IDN',
        display: 'Indonesia',
    },
    {
        value: 'IMN',
        display: 'Isle Of Man',
    },
    {
        value: 'IND',
        display: 'India',
    },
    {
        value: 'IOT',
        display: 'British Indian Ocean Territory',
    },
    {
        value: 'IRL',
        display: 'Ireland',
    },
    {
        value: 'IRN',
        display: 'Iran, Islamic Republic Of',
    },
    {
        value: 'IRQ',
        display: 'Iraq',
    },
    {
        value: 'ISL',
        display: 'Iceland',
    },
    {
        value: 'ISR',
        display: 'Israel',
    },
    {
        value: 'ITA',
        display: 'Italy',
    },
    {
        value: 'JAM',
        display: 'Jamaica',
    },
    {
        value: 'JEY',
        display: 'Jersey',
    },
    {
        value: 'JOR',
        display: 'Jordan',
    },
    {
        value: 'JPN',
        display: 'Japan',
    },
    {
        value: 'KAZ',
        display: 'Kazakhstan',
    },
    {
        value: 'KEN',
        display: 'Kenya',
    },
    {
        value: 'KGZ',
        display: 'Kyrgyzstan',
    },
    {
        value: 'KHM',
        display: 'Cambodia',
    },
    {
        value: 'KIR',
        display: 'Kiribati',
    },
    {
        value: 'KNA',
        display: 'Saint Kitts And Nevis',
    },
    {
        value: 'KOR',
        display: 'Korea, Republic Of',
    },
    {
        value: 'KVO',
        display: 'Kosovo',
    },
    {
        value: 'KWT',
        display: 'Kuwait',
    },
    {
        value: 'LAO',
        display: "Lao People's Democratic Republic",
    },
    {
        value: 'LBN',
        display: 'Lebanon',
    },
    {
        value: 'LBR',
        display: 'Liberia',
    },
    {
        value: 'LBY',
        display: 'Libyan Arab Jamahiriya',
    },
    {
        value: 'LCA',
        display: 'Saint Lucia',
    },
    {
        value: 'LIE',
        display: 'Liechtenstein',
    },
    {
        value: 'LKA',
        display: 'Sri Lanka',
    },
    {
        value: 'LSO',
        display: 'Lesotho',
    },
    {
        value: 'LTU',
        display: 'Lithuania',
    },
    {
        value: 'LUX',
        display: 'Luxembourg',
    },
    {
        value: 'LVA',
        display: 'Latvia',
    },
    {
        value: 'MAC',
        display: 'Macao',
    },
    {
        value: 'MAF',
        display: 'Saint Martin (French Part)',
    },
    {
        value: 'MAR',
        display: 'Morocco',
    },
    {
        value: 'MCO',
        display: 'Monaco',
    },
    {
        value: 'MDA',
        display: 'Moldova, Republic Of',
    },
    {
        value: 'MDG',
        display: 'Madagascar',
    },
    {
        value: 'MDV',
        display: 'Maldives',
    },
    {
        value: 'MEX',
        display: 'Mexico',
    },
    {
        value: 'MHL',
        display: 'Marshall Islands',
    },
    {
        value: 'MKD',
        display: 'Macedonia, The Former Yugoslav Republic Of',
    },
    {
        value: 'MLI',
        display: 'Mali',
    },
    {
        value: 'MLT',
        display: 'Malta',
    },
    {
        value: 'MMR',
        display: 'Myanmar',
    },
    {
        value: 'MNE',
        display: 'Montenegro',
    },
    {
        value: 'MNG',
        display: 'Mongolia',
    },
    {
        value: 'MNP',
        display: 'Northern Mariana Islands',
    },
    {
        value: 'MOZ',
        display: 'Mozambique',
    },
    {
        value: 'MRT',
        display: 'Mauritania',
    },
    {
        value: 'MSR',
        display: 'Montserrat',
    },
    {
        value: 'MTQ',
        display: 'Martinique',
    },
    {
        value: 'MUS',
        display: 'Mauritius',
    },
    {
        value: 'MWI',
        display: 'Malawi',
    },
    {
        value: 'MYS',
        display: 'Malaysia',
    },
    {
        value: 'MYT',
        display: 'Mayotte',
    },
    {
        value: 'NAM',
        display: 'Namibia',
    },
    {
        value: 'NCL',
        display: 'New Caledonia',
    },
    {
        value: 'NER',
        display: 'Niger',
    },
    {
        value: 'NFK',
        display: 'Norfolk Island',
    },
    {
        value: 'NGA',
        display: 'Nigeria',
    },
    {
        value: 'NIC',
        display: 'Nicaragua',
    },
    {
        value: 'NIU',
        display: 'Niue',
    },
    {
        value: 'NLD',
        display: 'Netherlands',
    },
    {
        value: 'NOR',
        display: 'Norway',
    },
    {
        value: 'NPL',
        display: 'Nepal',
    },
    {
        value: 'NRU',
        display: 'Nauru',
    },
    {
        value: 'NZL',
        display: 'New Zealand',
    },
    {
        value: 'OMN',
        display: 'Oman',
    },
    {
        value: 'PAK',
        display: 'Pakistan',
    },
    {
        value: 'PAN',
        display: 'Panama',
    },
    {
        value: 'PCN',
        display: 'Pitcairn',
    },
    {
        value: 'PER',
        display: 'Peru',
    },
    {
        value: 'PHL',
        display: 'Philippines',
    },
    {
        value: 'PLW',
        display: 'Palau',
    },
    {
        value: 'PNG',
        display: 'Papua New Guinea',
    },
    {
        value: 'POL',
        display: 'Poland',
    },
    {
        value: 'PRI',
        display: 'Puerto Rico',
    },
    {
        value: 'PRK',
        display: "Korea, Democratic People's Republic Of",
    },
    {
        value: 'PRT',
        display: 'Portugal',
    },
    {
        value: 'PRY',
        display: 'Paraguay',
    },
    {
        value: 'PSE',
        display: 'Palestinian Territory, Occupied',
    },
    {
        value: 'PYF',
        display: 'French Polynesia',
    },
    {
        value: 'QAT',
        display: 'Qatar',
    },
    {
        value: 'REU',
        display: 'Reunion',
    },
    {
        value: 'ROU',
        display: 'Romania',
    },
    {
        value: 'RUS',
        display: 'Russian Federation',
    },
    {
        value: 'RWA',
        display: 'Rwanda',
    },
    {
        value: 'SAU',
        display: 'Saudi Arabia',
    },
    {
        value: 'SDN',
        display: 'Sudan',
    },
    {
        value: 'SEN',
        display: 'Senegal',
    },
    {
        value: 'SGP',
        display: 'Singapore',
    },
    {
        value: 'SGS',
        display: 'South Georgia And The South Sandwich Islands',
    },
    {
        value: 'SHN',
        display: 'Saint Helena, Ascension And Tristan Da Cunha',
    },
    {
        value: 'SJM',
        display: 'Svalbard And Jan Mayen',
    },
    {
        value: 'SLB',
        display: 'Solomon Islands',
    },
    {
        value: 'SLE',
        display: 'Sierra Leone',
    },
    {
        value: 'SLV',
        display: 'El Salvador',
    },
    {
        value: 'SMR',
        display: 'San Marino',
    },
    {
        value: 'SOM',
        display: 'Somalia',
    },
    {
        value: 'SPM',
        display: 'Saint Pierre And Miquelon',
    },
    {
        value: 'SRB',
        display: 'Serbia',
    },
    {
        value: 'SSD',
        display: 'South Sudan',
    },
    {
        value: 'STL',
        display: 'Stateless',
    },
    {
        value: 'STP',
        display: 'Sao Tome And Principe',
    },
    {
        value: 'SUR',
        display: 'Suriname',
    },
    {
        value: 'SVK',
        display: 'Slovakia',
    },
    {
        value: 'SVN',
        display: 'Slovenia',
    },
    {
        value: 'SWE',
        display: 'Sweden',
    },
    {
        value: 'SWZ',
        display: 'Swaziland',
    },
    {
        value: 'SYC',
        display: 'Seychelles',
    },
    {
        value: 'SYR',
        display: 'Syrian Arab Republic',
    },
    {
        value: 'TCA',
        display: 'Turks And Caicos Islands',
    },
    {
        value: 'TCD',
        display: 'Chad',
    },
    {
        value: 'TGO',
        display: 'Togo',
    },
    {
        value: 'THA',
        display: 'Thailand',
    },
    {
        value: 'TJK',
        display: 'Tajikistan',
    },
    {
        value: 'TKL',
        display: 'Tokelau',
    },
    {
        value: 'TKM',
        display: 'Turkmenistan',
    },
    {
        value: 'TLS',
        display: 'Timor-Leste',
    },
    {
        value: 'TON',
        display: 'Tonga',
    },
    {
        value: 'TTO',
        display: 'Trinidad And Tobago',
    },
    {
        value: 'TUN',
        display: 'Tunisia',
    },
    {
        value: 'TUR',
        display: 'Turkey',
    },
    {
        value: 'TUV',
        display: 'Tuvalu',
    },
    {
        value: 'TWN',
        display: 'TAIWAN',
    },
    {
        value: 'TZA',
        display: 'Tanzania, United Republic Of',
    },
    {
        value: 'UGA',
        display: 'Uganda',
    },
    {
        value: 'UKR',
        display: 'Ukraine',
    },
    {
        value: 'UMI',
        display: 'United States Minor Outlying Islands',
    },
    {
        value: 'URY',
        display: 'Uruguay',
    },
    {
        value: 'USA',
        display: 'United States',
    },
    {
        value: 'UZB',
        display: 'Uzbekistan',
    },
    {
        value: 'VAT',
        display: 'Holy See (Vatican City State)',
    },
    {
        value: 'VCT',
        display: 'Saint Vincent And The Grenadines',
    },
    {
        value: 'VEN',
        display: 'Venezuela, Bolivarian Republic Of',
    },
    {
        value: 'VGB',
        display: 'Virgin Islands (British)',
    },
    {
        value: 'VIR',
        display: 'Virgin Islands (U.S.)',
    },
    {
        value: 'VNM',
        display: 'Viet Nam',
    },
    {
        value: 'VUT',
        display: 'Vanuatu',
    },
    {
        value: 'WLF',
        display: 'Wallis And Futuna',
    },
    {
        value: 'WSM',
        display: 'Samoa',
    },
    {
        value: 'YEM',
        display: 'Yemen',
    },
    {
        value: 'ZAF',
        display: 'South Africa',
    },
    {
        value: 'ZMB',
        display: 'Zambia',
    },
    {
        value: 'ZWE',
        display: 'Zimbabwe',
    },
];

export const lawfulPresenceDocumentTypes = {
    'Naturalization Certificate': 'Naturalization Certificate',
    'Certificate of Citizenship': 'Certificate of Citizenship',
    'None of these': 'None of these',
};
export const moreFamilyRelationships = {
    Spouse: 'Spouse',
    Parent: 'Parent',
    Stepparent: 'Stepparent',
    Grandparent: 'Grandparent',
    Grandchild: 'Grandchild',
    'Other unrelated': 'Other unrelated',
    'Other relative': 'Other relative',
    'father-in-law': 'father-in-law',
    'Mother-in-law': 'Mother-in-law',
    'son-in-law': 'son-in-law',
    'Daughter-in-law': 'Daughter-in-law',
    'sister-in-law': 'sister-in-law',
    'Brother-in-law': 'Brother-in-law',
    'First cousin': 'First cousin',
    'Nephew/niece': 'Nephew/niece',
    'Uncle/aunt': 'Uncle/aunt',
    'Brother/sister': 'Brother/sister',
    'Child of domestic partner': 'Child of domestic partner',
    'Stepson/stepdaughter': 'Stepson/stepdaughter',
    'Son/daughter': 'Son/daughter',
    "Parent's domestic partner": "Parent's domestic partner",
    'Domestic Partner': 'Domestic Partner',
};
export const evenMoreBasicFamilyRelationships = {
    Parent: 'Parent',
    Stepparent: 'Stepparent',
};
export const basicFamilyRelationships = {
    Spouse: 'Spouse',
    'Son/Daughter': 'Son/Daughter',
    'Stepson/Stepdaughter': 'Stepson/Stepdaughter',
};
export const communicationPreferencesWithPrefillsText = {
    'Text me (messaging rates apply)': 'Text me (messaging rates apply)',
};
export const communicationPreferencesWithPrefillsEmail = {
    'Email me at ${householdContact.email}': 'Email me at ${householdContact.email}',
};
export const communicationPreferences = {
    Email: 'email',
    "Text me when there's a new notice in my Marketplace account": 'text',
    'Send me paper notices in the mail': 'mail',
};

export const languageTypes = {
    French: 'French',
    Chinese: 'Chinese',
    Arabic: 'Arabic',
    Spanish: 'Spanish',
    English: 'English',
    German: 'German',
    Gujarati: 'Gujarati',
    Hindi: 'Hindi',
    Korean: 'Korean',
    Polish: 'Polish',
    Portuguese: 'Portuguese',
    Russian: 'Russian',
    Tagalog: 'Tagalog',
    Urdu: 'Urdu',
    Vietnamese: 'Vietnamese',
    Other: 'Other',
    'French Creole': 'French Creole',
};
export const phoneNumberTypes = {
    Mobile: 'Mobile',
    Home: 'Home',
    Work: 'Work',
};

export const iAgreeDisagreeResponses = {
    Disagree: 'I Disagree',
    Agree: 'I Agree',
};
export const agreeDisagreeResponses = {
    Disagree: 'Disagree',
    Agree: 'Agree',
};
export const APTCProgramQuestionsAndImmigrant = {
    Moved: 'Moved',
    'Gained eligible immigration status': 'Gained eligible immigration status',
    'None of these changes': 'None of these changes',
    'Was released from incarceration (detention or jail)': 'Was released from incarceration (detention or jail)',
    'Got married': 'Got married',
    'Gained a dependent (or became a dependent) due to an adoption, foster care placement, or court order':
        'Gained a dependent (or became a dependent) due to an adoption, foster care placement, or court order',
};
export const APTCProgramQuestions = {
    Moved: 'Moved',
    'None of these changes': 'None of these changes',
    'Was released from incarceration (detention or jail)': 'Was released from incarceration (detention or jail)',
    'Got married': 'Got married',
    'Gained a dependent (or became a dependent) due to an adoption, foster care placement, or court order':
        'Gained a dependent (or became a dependent) due to an adoption, foster care placement, or court order',
};
export const CurrentIncomeType = {
    Unemployment: 'Unemployment',
    Scholarship: 'Scholarship',
    'Job (like salary, wages, commissions, or tips)': 'Job (like salary, wages, commissions, or tips)',
    'Self-employment (like your own business, consulting, or freelance work)':
        'Self-employment (like your own business, consulting, or freelance work)',
    'Farming & Fishing': 'Farming & Fishing',
    'Social Security benefits': 'Social Security benefits',
    'Retirement (like IRA and 401(k) withdrawals)': 'Retirement (like IRA and 401(k) withdrawals)',
    Pension: 'Pension',
    Investment: 'Investment',
    'Capital gains': 'Capital Gains',
    'Rental or royalty income': 'Rental or royalty income',
    'Alimony Received': 'ALIMONY_PAYMENT',
    'Canceled debt': 'Canceled Debts',
};
export const YesNoNotSureResponses = {
    NO: 'NO',
    YES: 'YES',
    "I'm not sure": "I'm not sure",
};

export const YesNoResponsesInverse = {
    No: true,
    Yes: false,
};
export const YesNoResponses = {
    No: false,
    Yes: true,
};

export const Numbers8 = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
};

export const Ethnicity = {
    Cuban: 'Cuban',
    Mexican: 'Mexican',
    'Mexican American, or Chicano': 'Mexican American, or Chicano',
    'Puerto Rican': 'Puerto Rican',
};

// if we want to use months like this we can just wasnt sure and didnt want to waste time on it
// var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const Months = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
};
export const Years = {
    1920: 1920,
    1921: 1921,
    1922: 1922,
    1923: 1923,
    1924: 1924,
    1925: 1925,
    1926: 1926,
    1927: 1927,
    1928: 1928,
    1929: 1929,
    1930: 1930,
    1931: 1931,
    1932: 1932,
    1933: 1933,
    1934: 1934,
    1935: 1935,
    1936: 1936,
    1937: 1937,
    1938: 1938,
    1939: 1939,
    1940: 1940,
    1941: 1941,
    1942: 1942,
    1943: 1943,
    1944: 1944,
    1945: 1945,
    1946: 1946,
    1947: 1947,
    1948: 1948,
    1949: 1949,
    1950: 1950,
    1951: 1951,
    1952: 1952,
    1953: 1953,
    1954: 1954,
    1955: 1955,
    1956: 1956,
    1957: 1957,
    1958: 1958,
    1959: 1959,
    1960: 1960,
    1961: 1961,
    1962: 1962,
    1963: 1963,
    1964: 1964,
    1965: 1965,
    1966: 1966,
    1967: 1967,
    1968: 1968,
    1969: 1969,
    1970: 1970,
    1971: 1971,
    1972: 1972,
    1973: 1973,
    1974: 1974,
    1975: 1975,
    1976: 1976,
    1977: 1977,
    1978: 1978,
    1979: 1979,
    1980: 1980,
    1981: 1981,
    1982: 1982,
    1983: 1983,
    1984: 1984,
    1985: 1985,
    1986: 1986,
    1987: 1987,
    1988: 1988,
    1989: 1989,
    1990: 1990,
    1991: 1991,
    1992: 1992,
    1993: 1993,
    1994: 1994,
    1995: 1995,
    1996: 1996,
    1997: 1997,
    1998: 1998,
    1999: 1999,
    2000: 2000,
    2001: 2001,
    2002: 2002,
    2003: 2003,
    2004: 2004,
    2005: 2005,
    2006: 2006,
    2007: 2007,
    2008: 2008,
    2009: 2009,
    2010: 2010,
    2011: 2011,
    2012: 2012,
    2013: 2013,
    2014: 2014,
    2015: 2015,
    2016: 2016,
    2017: 2017,
    2018: 2018,
    2019: 2019,
};

export const incomeDiscrepencies = {
    'A reason not listed above': 'A reason not listed above',
    'My household members have changed': 'My household members have changed',
    'My income fluctuates due to self-employment': 'My income fluctuates due to self-employment',
    'My taxable income is increasing due to a change in work location': 'My taxable income is increasing due to a change in work location',
    'My income will be higher due to a change in my job, for reasons such as a new job, a promotion, overtime/bonuses, or an increase in hours':
        'My income will be higher due to a change in my job, for reasons such as a new job, a promotion, overtime/bonuses, or an increase in hours,',
};

export const incomeSourceType = {
    'Student loan interest': 'STUDENT_LOAN_INTEREST',
    'Alimony payments': 'ALIMONY_PAYMENT',
    "One of these expenses:\n-IRA contributions (if [FNLNS] doesn't have a retirement account through a job)\n-Educator expenses (if [FNLNS] is a teacher and pays for supplies out-of-pocket)\n-Penalty on early withdrawal of savings":
        'OTHER_DEDUCTION',
};

export const CitizenshipImmigrationStatusDoesSubscriberHaveDocumentTypes = {
    'Document indicating member of a federally recognized Indian tribe or American Indian born in Canada':
        'Document indicating member of a federally recognized Indian tribe or American Indian born in Canada',
    'Members Of A Federally Recognized Indian Tribe': 'Members Of A Federally Recognized Indian Tribe',
    'Certification from U.S. Department of Health and Human Services (HHS) Office of Refugee Resettlement (ORR)':
        'Certification from U.S. Department of Health and Human Services (HHS) Office of Refugee Resettlement (ORR)',
    'Office of Refugee Resettlement (ORR) eligibility letter (if under 18)':
        'Office of Refugee Resettlement (ORR) eligibility letter (if under 18)',
    'Cuban/Haitian Entrant': 'Cuban/Haitian Entrant',
    'Document indicating withholding of removal': 'Document indicating withholding of removal',
    'Non Citizen Who Is Lawfully Present In American Samoa': 'Non Citizen Who Is Lawfully Present In American Samoa',
    'Administrative order staying removal issued by the Department of Homeland Security (DHS)':
        'Administrative order staying removal issued by the Department of Homeland Security (DHS)',
    'Battered spouse, child, or parent under the Violence Against Women Act':
        'Battered spouse, child, or parent under the Violence Against Women Act',
    "Another document or [FNLNS]'s alien number/I-94 number": "Another document or [FNLNS]'s alien number/I-94 number",
    "None of these (Select this if this person doesn't have a listed document. You can continue the application without selecting a document or status type.)":
        "None of these (Select this if this person doesn't have a listed document. You can continue the application without selecting a document or status type.)",
};

export const citizenshipImmigrationStatusRandomEligibility = {
    'Certificate of Eligibility for Nonimmigrant (F-1) Student Status (I-20)':
        'Certificate of Eligibility for Nonimmigrant (F-1) Student Status (I-20)',
    'Certificate of Eligibility for Exchange Visitor (J-1) Status': 'Certificate of Eligibility for Exchange Visitor (J-1) Status',
};

export const citizenshipImmigrationStatusGreenCardDocumentType = {
    'Temporary I-551 Stamp (on passport or I-94/I-94A)': 'Temporary I-551 Stamp (on passport or I-94/I-94A)',
    'I-327 (Reentry Permit)': 'I-327 (Reentry Permit)',
    'I-551 (Permanent Resident Card, "Green Card")': 'I-551 (Permanent Resident Card, "Green Card")',
    'Machine Readable Immigrant Visa (with temporary I-551 language)': 'Machine Readable Immigrant Visa (with temporary I-551 language)',
};

export const citizenshipImmigrationStatusTypeDocument = {
    'Arrival/Departure Record (I-94/I-94A)': 'Arrival/Departure Record (I-94/I-94A)',
    'Arrival/Departure Record in unexpired foreign passport (I-94)': 'Arrival/Departure Record in unexpired foreign passport (I-94)',
    'Unexpired foreign passport': 'Unexpired foreign passport',
    'I-20 (Certificate of Eligibility for Nonimmigrant (F-1) Student Status)':
        'I-20 (Certificate of Eligibility for Nonimmigrant (F-1) Student Status)',
    'DS2019 (Certificate of Eligibility for Exchange Visitor (J-1) Status)':
        'DS2019 (Certificate of Eligibility for Exchange Visitor (J-1) Status)',
};
export const citizenshipImmigrationStatusNumber = {
    'Alien number': 'Alien number',
    'I-94 number': 'I-94 number',
};

export const legalRelationshipsDateCheck = {
    Guardian: 'Guardian',
    Unrelated: 'Unrelated',
    Ward: 'Ward',
    Trustee: 'Trustee',
    Annuitant: 'Annuitant',
    'Collateral dependent': 'Collateral dependent',
    'Adopted son/daughter': 'Adopted son/daughter',
    'Court appointed guardian': 'Court appointed guardian',
    'Former spouse': 'Former spouse',
    'Foster child': 'Foster child',
    'Foster parent': 'Foster parent',
    'None of the above': 'None of the above',
};
export const legalRelationshipsGrandparents = {
    Guardian: 'Guardian',
    Ward: 'Ward',
    Annuitant: 'Annuitant',
    Trustee: 'Trustee',
    'None of the above': 'None of the above',
    'Collateral dependent': 'Collateral dependent',
    'Court appointed guardian': 'Court appointed guardian',
    'Foster parent': 'Foster parent',
};
export const legalRelationshipsExtra = {
    Guardian: 'Guardian',
    Ward: 'Ward',
    'Foster child': 'Foster child',
    'Adopted son/daughter': 'Adopted son/daughter',
    'Collateral dependent': 'Collateral dependent',
    'Sponsored dependent': 'Sponsored dependent',
    'Dependent of a minor dependent': 'Dependent of a minor dependent',
    'Court-appointed guardian': 'Court-appointed guardian',
    'Former spouse': 'Former spouse',
    'None of these relationships': 'None of these relationships',
};

export const RelationshipToTaxFilerCaretaker = {
    Grandchild: 'Grandchild',
    Cousin: 'Cousin',
    'Aunt/uncle': 'Aunt/uncle',
    'Child (including adopted, foster & stepchild)': 'Child (including adopted, foster & stepchild)',
    'Nephew/niece': 'Nephew/niece',
    'Sibling (including step-sibling and half-sibling)': 'Sibling (including step-sibling and half-sibling)',
    'Other relative (including by marriage and adoption)': 'Other relative (including by marriage and adoption)',
    'Other unrelated': 'Other unrelated',
};
export const RelationshipToTaxFilerRelationship = {
    Parent: 'Parent',
    Son: 'Son',
    daughter: 'daughter',
    Stepson: 'Stepson',
    stepdaughter: 'stepdaughter',
    Stepparent: 'Stepparent',
    Grandchild: 'Grandchild',
    Grandparent: 'Grandparent',
    'Mother-in-law/father-in-law': 'Mother-in-law/father-in-law',
    "Parent's domestic partner": "Parent's domestic partner",
    'First cousin': 'First cousin',
    'Stepbrother/stepsister': 'Stepbrother/stepsister',
    'Brother/sister': 'Brother/sister',
    'Domestic partner': 'Domestic partner',
    'Uncle/aunt': 'Uncle/aunt',
    'Nephew/niece': 'Nephew/niece',
    'Former spouse': 'Former spouse',
    'Daughter-in-law/son-in-law': 'Daughter-in-law/son-in-law',
    'Brother-in-law/sister-in-law': 'Brother-in-law/sister-in-law',
};

export const RelationshipToTaxFiler = {
    Parent: 'Parent',
    Son: 'Son',
    daughter: 'daughter',
    Stepson: 'Stepson',
    stepdaughter: 'stepdaughter',
    Stepparent: 'Stepparent',
    Grandchild: 'Grandchild',
    Grandparent: 'Grandparent',
    'None of these': 'None of these',
    'Mother-in-law/father-in-law': 'Mother-in-law/father-in-law',
    'Parent of domestic partner': "Parent's domestic partner",
    'First cousin': 'First cousin',
    'Stepbrother/stepsister': 'Stepbrother/stepsister',
    'Brother/sister': 'Brother/sister',
    'Domestic partner': 'Domestic partner',
    'Uncle/aunt': 'Uncle/aunt',
    'Nephew/niece': 'Nephew/niece',
    'Former spouse': 'Former spouse',
    'Daughter-in-law/son-in-law': 'Daughter-in-law/son-in-law',
    'Brother-in-law/sister-in-law': 'Brother-in-law/sister-in-law',
    'Child of domestic partner': 'Child of domestic partner',
};

export const YesNoMarriageStatusTypes = {
    Yes: 'married filing jointly',
    No: 'married filing separately',
};

export const SuffixList = {
    III: 'III',
    IV: 'IV',
    'Jr.': 'Jr.',
    'Sr.': 'Sr.',
};
export const Citizenship = {
    'Permanent Resident Card (Green Card) or Reentry Permit (I-551, temporary I-551 stamp, I-327)':
        'Permanent Resident Card (Green Card) or Reentry Permit (I-551, temporary I-551 stamp, I-327)',
    'Machine Readable Immigrant Visa with temporary I-551 language': 'Machine Readable Immigrant Visa with temporary I-551 language',
    'Employment Authorization Card (I-766)': 'Employment Authorization Card (I-766)',
    'Arrival/Departure Record (I-94, I-94A)\nRefugee Travel Document (I-571)':
        'Arrival/Departure Record (I-94, I-94A)\\nRefugee Travel Document (I-571)',
    'Passport, Travel Document, or Student or Exchange Visitor Documents (I-94, unexpired foreign passport, I-20, DS2019)':
        "'Passport, Travel Document, or Student or Exchange Visitor Documents (I-94, unexpired foreign passport, I-20, DS2019)",
    'Nonimmigrant Student or Exchange Visitor Status (I-20, DS2019)': 'Nonimmigrant Student or Exchange Visitor Status (I-20, DS2019)',
    'Notice of Action (I-797)': 'Notice of Action (I-797)',
    'Other document or status with an Alien Number or I-94 Number': 'Other document or status with an Alien Number or I-94 Number',
    'None of these': 'None of these',
    'Unexpired foreign passport': 'Unexpired foreign passport',
    'Permanent Resident Card/Green Card (I-551, temporary I-551 stamp, temporary I-551 language, I-327)':
        'Permanent Resident Card/Green Card (I-551, temporary I-551 stamp, temporary I-551 language, I-327)',
};

export const RequestingCoverageIndicator = {
    subscriberAndFamily: '${FNLNS} & other family members',
    justSubscriber: '${FNLNS} only',
    justFamily: 'Other family members, not ${FNLNS}',
};

export const HelpApplyingForCoverage = {
    Navigator: 'Navigator',
    'Certified application counselor': 'Certified application counselor',
    'Agent or broker': 'Agent or broker',
    'Other Assister': 'Other Assister',
};

// in times of open enrollment this is supposed to be the current year and also the next upcoming year
// since we are in SEP we just put the current year
export const CoverageYears = {
    2019: 2019,
};

export const Races = {
    Chinese: 'Chinese',
    Filipino: 'Filipino',
    Japanese: 'Japanese',
    Korean: 'Korean',
    Samoan: 'Samoan',
    Vietnamese: 'Vietnamese',
    White: 'White',
    'Another race not listed above:': 'Another race not listed above:',
    'A Pacific Islander race not listed above': 'A Pacific Islander race not listed above',
    'An Asian race not listed above': 'An Asian race not listed above',
    'Native Hawaiian': 'Native Hawaiian',
    'Guamanian or Chamorro': 'Guamanian or Chamorro',
    'American Indian or Alaska Native': 'American Indian or Alaska Native',
    'Asian Indian': 'Asian Indian',
    'Black or African American': 'Black or African American',
};
export const allStates = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'American Samoa',
        value: 'AS',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'DC',
    },
    {
        label: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Marshall Islands',
        value: 'MH',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virgin Islands',
        value: 'VI',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
];

// ITEM #
// data point: coverageState
export const FFMStates = {
    Alaska: 'AK',
    Alabama: 'AL',
    Arkansas: 'AR',
    Arizona: 'AZ',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Iowa: 'IA',
    Illinois: 'IL',
    Indiana: 'IN',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Mississippi: 'MI',
    Michigan: 'MO',
    Missouri: 'MS',
    Montana: 'MT',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Nebraska: 'NE',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    Nevada: 'NV',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Virginia: 'VA',
    Wisconsin: 'WI',
    'West Virginia': 'WV',
    Wyoming: 'WY',
};

//get states from State code
export const STATECODES = {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MI: 'Mississippi',
    MO: 'Michigan',
    MS: 'Missouri',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
};

export const SexesList = {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
};
