import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'zip-page-buttons',
    templateUrl: './page-buttons.component.html',
})
export class PageButtonsComponent implements OnChanges {
    @Input() currentPage: number;
    @Input() totalCount: number;
    @Output() pageButtonClick = new EventEmitter<number>();

    disablePrevious = false;
    disableNext = false;

    ngOnChanges(changes) {
        this.disablePrevious = this.currentPage === 1;
        this.disableNext = this.currentPage === this.totalCount;
    }

    public goToFirstPage() {
        this.pageButtonClick.emit(1);
    }

    public goToPreviousPage() {
        this.pageButtonClick.emit(this.currentPage - 1);
    }

    public goToNextPage() {
        this.pageButtonClick.emit(this.currentPage + 1);
    }

    public goToLastPage() {
        this.pageButtonClick.emit(this.totalCount);
    }
}
