import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, CardModule, FormControlModule, IconModule, ZipBusyModule } from '@zipari/design-system';
import { CoverageEffectiveDateModule } from '@zipari/shared-sbp-templates';

import { AddressComponent } from './address/address.component';
import { AutopayComponent } from './autopay/autopay.component';
import { BasicAddressComponent } from './basic-address/basic-address.component';
import { BrokerAssistanceComponent } from './broker-assistance/broker-assistance.component';
import { CitiesComponent } from './cities/cities.component';
import { CompanyAddressComponent } from './company-address/company-address.component';
import { CountiesComponent } from './counties/counties.component';
import { CoverageEffectiveControlComponent } from './coverage-effective-control/coverage-effective-control.component';
import { CustomFormElementComponent } from './custom-form-element.component';
import { DropdownOtherComponent } from './dropdown-other/dropdown-other.component';
import { FormArrayComponent } from './form-array/form-array.component';
import { HiddenComponent } from './hidden/hidden.component';
import { IndustriesComponent } from './industries/industries.component';
import { InputNaComponent } from './input-na/input-na.component';
import { OfficeHoursComponent } from './office-hours/office-hours.component';
import { OptionalDateComponent } from './optional-date/optional-date.component';
import { PasswordsComponent } from './passwords/passwords.component';
import { SsnMultiFieldComponent } from './ssn-multi-field/ssn-multi-field.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WhoseCoveredComponent } from './whose-covered/whose-covered.component';
import { WhoseCoveredFormComponent } from './whose-covered-form/whose-covered-form.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [
        CustomFormElementComponent,
        AddressComponent,
        OfficeHoursComponent,
        FormArrayComponent,
        BasicAddressComponent,
        IndustriesComponent,
        DropdownOtherComponent,
        OptionalDateComponent,
        CompanyAddressComponent,
        CountiesComponent,
        InputNaComponent,
        WhoseCoveredComponent,
        WhoseCoveredFormComponent,
        PasswordsComponent,
        BrokerAssistanceComponent,
        AutopayComponent,
        TermsAndConditionsComponent,
        CoverageEffectiveControlComponent,
        HiddenComponent,
        SsnMultiFieldComponent,
        CitiesComponent,
    ],
    imports: [
        CommonModule,
        FormControlModule,
        CardModule,
        IconModule,
        ButtonModule,
        ZipBusyModule,
        SharedModule,
        ReactiveFormsModule,
        CoverageEffectiveDateModule,
    ],
    exports: [CustomFormElementComponent, OptionalDateComponent],
})
export class CustomFormElementModule {}
