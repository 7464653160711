import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, ModelAttributeModule, FormControlModule } from '@zipari/design-system';
import { ExistingClientListComponent } from './existing-client-list-modal.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [ExistingClientListComponent],
    imports: [CommonModule, ButtonModule, FormControlModule, SharedModule, ModelAttributeModule],
    exports: [ExistingClientListComponent],
})
export class ExistingClientListModalModule {}
