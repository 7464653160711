<div
    class="badge"
    [ngClass]="{
        'badge--3': config.type === BadgeTypes.priority,
        'badge--flat': config.type === BadgeTypes.flat,
        'badge--large': config.size === BadgeSizes.large,
        'badge--small': config.size === BadgeSizes.small
    }"
>
    <ng-container *ngIf="config.size === BadgeSizes.small; else defaultHeader">
        <p class="t-data t-bold">{{ config.value }}</p>
    </ng-container>
    <ng-template #defaultHeader>
        <h4 class="t-bold">
            {{ config.value }}
        </h4>
    </ng-template>

    <p *ngIf="config.link; else defaultLabel" class="t-data t-link" (click)="linkClicked.emit($event)">
        {{ config.label }}
    </p>
    <ng-template #defaultLabel>
        <p class="t-data t-alt">{{ config.label }}</p>
    </ng-template>
</div>
