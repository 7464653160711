export const SHOW_SUBSIDY_CONFIG = {
    label: ' ',
    type: 'toggle',
    prop: 'showSubsidy',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
};

export interface SubsidyDemographicParams {
    county_code: string;
    coverage_effective_date?: string;
    dependents_count: number;
    ordering: string;
    zipcode: string;
    plan_type: string[];
    state_code?: string;
    subscriber_date_of_birth: string;
    subscriber_tobacco: string;
    spouse_date_of_birth?: string;
    spouse_tobacco?: string;
    dependent_0_date_of_birth?: string;
    dependent_0_tobacco?: string;
}

export interface SubsidyFormValues {
    annual_income: string;
    coverage_effective_date: string;
    household_size: string;
    native_indian_alaskan: boolean;
}

export interface SubsidyResponse {
    amount: number;
    aptc_applicable_amt: number;
    aptc_pct: number;
    benchmark_plan_price: number;
    correlation_id: string;
    eligibility_type: string;
    eligible: boolean;
    federal_subsidy_amount?: number;
    fpl_pct: number;
    plan_variations: Array<string>;
    state_subsidy_amount: number;
}

export class StateSubsidyConfig {
    title: string;
    sections: Array<StateSubsidyConfigSection>;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export interface StateSubsidyConfigSection {
    title: string;
    type: string;
    text: string;
}

export class SubsidyData {
    household_size: string = null;
    native_indian_alaskan: boolean = null;
    annual_income: string = null;
    subsidy_amount: number = null;
    plan_variation: Array<string> = null;
    federal_subsidy_amount: number = null;
    state_subsidy_amount: number = null;
    showSubsidy: boolean = false;
    constructor(options = {}) {
        Object.assign(this, options);
    }
}
