export const documentEndpoint = 'api/documents/';
export const tableConfig = {
    title: 'Document Center',
    endpoint: 'api/documents/',
    columns: [
        {
            name: 'Document',
            prop: 'filename',
            format: 'text',
        },
        {
            name: 'Document Type',
            prop: 'file_type',
            format: 'text',
        },
        {
            name: 'Year',
            prop: 'document_date',
            format: 'DATE',
        },
        {
            name: 'Language',
            prop: 'language',
            format: 'text',
        },
        {
            name: '',
            prop: '',
            format: 'ACTIONS',
            actions: ['print', 'download'],
            cell_alignment: 'flex-end',
        },
    ],
    filters: [
        {
            type: 'input',
            icon: 'search',
            size: 'large',
            prop: 'search',
            placeholder: 'Search Documents',
        },
    ],
    messages: {
        noData: 'No data found.',
    },
    paging: {
        enabled: true,
        pageSize: 20,
    },
    detail: {
        enabled: false,
    },
};
