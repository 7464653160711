import { AttributeHighlightByPlanTypeModel } from '@zipari/shared-sbp-modules';

export class PersonalInfoText {
    card_header?: string;
    coverage_eff_date?: string;
    purchasing?: string;
}

export class PlanConfig {
    attributeHighlights?: AttributeHighlightByPlanTypeModel;
}

export class PersonalInfoConfig {
    type?: string;
    disableVerifyAddress?: boolean;
    disableDisparateZip?: boolean;
    disablePutPriorCoverageOnWorkflowValues?: boolean;
    label?: string;
    title?: string;
    subtitle?: string;
    brokerByState?: boolean;
    checkSSN?: boolean;
    useSaveExit?: boolean;
    validatePrepopulatedAddress?: boolean;

    // todo: model out this structure
    forms?: any;
    text?: PersonalInfoText;
    planConfig?: PlanConfig;
    planTypeOrder?: Array<string>;
    addressFormOrder?: AddressType[];

    constructor(config: PersonalInfoConfig = new PersonalInfoConfig({})) {
        Object.assign(this, config);
    }
}

export enum AddressType {
    mailing_address = 'mailing_address',
    billing_address = 'billing_address',
}

export enum MemberType {
    subcriber = 'Subscriber',
    spouse = 'Spouse',
    domestic_partner = 'Domestic Partner',
    child_dependent = 'Dependent',
    step_parent_dependent = 'Parent/StepParent',
    domesticPartner = 'Domestic Partner',
}

export const priorCoverageConfig = {
    priorCoverage: 'prior_coverage',
};
