import Diagnosis from './Diagnosis.model';
import Procedure from './Procedure.model';
import Provider from './Provider.model';

export default class Preauthorization {
    readonly id?: number;
    readonly updated_at?: string;
    created_at?: string;
    authorization_number: string;
    status: string;
    servicing_provider?: Provider;
    limit?: string;
    authorization_status?: string;
    authorization_status_description?: string;
    approval_date: string;
    denial_date: string;
    procedures: Array<Procedure>;
    diagnosis: Array<Diagnosis>;
}
