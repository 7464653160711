import { Injectable } from '@angular/core';
import { getValue, isEmptyObj, isObj } from '@zipari/web-utils';
import { CoveredMemberKey, MemberCoverageOption } from '@zipari/shared-sbp-constants';
import { IWhoseCovered, SubsidyData } from '@zipari/shared-sbp-modules';
import { EnrollmentFormattingService, FirstStepFormat } from '@zipari/shared-sbp-enrollment';
import { MemberType } from '@zipari/shared-sbp-templates';

@Injectable({
    providedIn: 'root',
})
export class QuoteEditPanelService {
    constructor(private enrollmentFormattingService: EnrollmentFormattingService) {}

    public saveValuesForIndividualShopping(submitValues, workflowValues, memberOptions?: any) {
        const firstStepFormat = this.formatValuesForIndividualFirstStep(submitValues, workflowValues, memberOptions);
        const data = this.enrollmentFormattingService.formatFirstStep(workflowValues, firstStepFormat);

        data['total_members'] = data['demographics']['total_members'];
        // null out members if they dont exist anymore (user has removed them). We want these to get updated within the new workflow values
        if ((!data.dependents || !data.dependents.length) && workflowValues.dependents) {
            data.dependents = null;
            data.demographics.dependents = null;
        }

        if (!data.spouse && workflowValues.spouse) {
            data.spouse = null;
            data.demographics.spouse = null;
        }

        // update subsidy data within workflow values
        const subsidyData: SubsidyData = submitValues.subsidyData || {};

        return { ...data, ...subsidyData, subsidy: subsidyData };
    }

    public saveValuesForIndividualBP(submitValues, workflowValues) {
        const firstStepFormat = this.formatValuesForIndividualFirstStep(submitValues, workflowValues);
        const data = this.enrollmentFormattingService.formatFirstStep(workflowValues, firstStepFormat);

        // Update values on getStarted (used on broker portal)
        if (workflowValues['getStarted']) {
            data['getStarted'] = {
                county: data.county_location.county_code,
                zipcode: data.county_location.zipcode || data.demographics.zipcode || data.contact.zip_code,
                county_location: data.county_location,
                coverage_types: data.demographics.coverage_types || data.demographics.coverage_type,
            };
        }

        // Update values on whoseCovered (used on broker portal)
        const whoseCovered = this.getWhoseCoveredData(data, workflowValues);

        if (Object.keys(whoseCovered).length > 0) {
            data.coverage = { whoseCovered };
            // update the value of whose_covered within the root of data and within the demographics object.
            data['whose_covered'] = whoseCovered['whose_covered'];
            data.demographics['whose_covered'] = whoseCovered['whose_covered'];
        }
        data['total_members'] = data['demographics']['total_members'];
        // null out members if they dont exist anymore (user has removed them). We want these to get updated within the new workflow values
        if ((!data.dependents || !data.dependents.length) && workflowValues.dependents) {
            data.dependents = null;
            data.demographics.dependents = null;
        } else {
            data.demographics.dependents = [...(data.demographics.dependents || []), ...(data.demographics.stepParents || [])];
            data.coverage.whoseCovered.dependents = [
                ...(data.coverage.whoseCovered.dependents || []),
                ...(data.coverage.whoseCovered.stepParents || []),
            ];

            delete data.demographics.stepParents;
            delete data.coverage.whoseCovered.stepParents;
        }

        if (!data.spouse && workflowValues.spouse) {
            data.spouse = null;
            data.demographics.spouse = null;
        }
        // update subsidy data within workflow values
        const subsidyData: SubsidyData = submitValues.subsidyData || {};

        return { ...data, ...subsidyData, subsidy: subsidyData };
    }

    // Format the submit values to update the first workflow step in the individual workflow
    public formatValuesForIndividualFirstStep(submitValues, workflowValues, memberCoverageOptionConfigs?: any): FirstStepFormat {
        const { county_location, coverage_type, coverage_types, zipcode, permanent_address } = this.getCoverageValues(
            submitValues,
            workflowValues
        );
        const members = this.getHouseholdMembersValues(submitValues, workflowValues);
        const county = county_location.county_code;

        const contact = {
            county,
            zipcode,
            zip_code: zipcode,
            coverage_types: coverage_types || coverage_type,
        };

        if (!isEmptyObj(members)) {
            const primarySubscriber = members.subscriber ? members.subscriber : members.children[0];
            if (submitValues.contact) {
                Object.keys(submitValues.contact).forEach((prop: string) => {
                    primarySubscriber[prop] = submitValues.contact[prop];
                });
            } else {
                if (primarySubscriber.email_address) {
                    contact['email_address'] = primarySubscriber.email_address;
                }
                if (primarySubscriber.home_phone) {
                    contact['home_phone'] = primarySubscriber.home_phone;
                }
            }
        }

        if (submitValues.contact) {
            Object.keys(submitValues.contact).forEach((prop: string) => {
                contact[prop] = submitValues.contact[prop];
            });
        }

        // Values to update on each member
        const updatedMemberValues = {
            address: {
                county,
                zipcode,
                zip_code: zipcode,
            },
            coverage_type: coverage_types || coverage_type,
        };

        const singleMembers = ['subscriber', 'spouse'].reduce((acc, key) => {
            const memberObj = isEmptyObj(members) ? workflowValues[key] : members[key];
            if (!!memberObj) {
                acc[key] = {
                    ...members[key],
                    ...updatedMemberValues,
                };
            }
            return acc;
        }, {});

        const arrayMembers = ['dependents', 'children'].reduce((acc, key) => {
            const depsArr = isEmptyObj(members) ? workflowValues[key] : members[key];
            if (!!depsArr) {
                acc[key] = depsArr.map((dep) => ({
                    ...dep,
                    ...updatedMemberValues,
                }));
            }
            return acc;
        }, {});

        const memberCoverageOption = this.getMemberCoverageOption(members, memberCoverageOptionConfigs, workflowValues);

        const firstStepFormat: FirstStepFormat = {
            contact: contact,
            members: { ...singleMembers, ...arrayMembers },
            coverage_effective_date: workflowValues.coverage_effective_date,
            memberCoverageOption: memberCoverageOption,
            county: county_location,
            enrollmentPeriod: workflowValues.enrollment_period,
            permanent_address,
        };

        return firstStepFormat;
    }

    // getMemberCoverageOption() is used for Shopping Portal
    private getMemberCoverageOption(
        members: { [key: string]: any },
        memberCoverageOptionConfigs: any,
        workflowValues: any
    ): MemberCoverageOption {
        let memberCoverageOption = workflowValues.memberCoverageOption;
        if (members.children) {
            members[CoveredMemberKey.stepParents] = this.getDependentsWithMemberType(members.children, CoveredMemberKey.stepParents);
            members[CoveredMemberKey.children] = this.getDependentsWithMemberType(members.children, CoveredMemberKey.dependents);
        }

        if (memberCoverageOptionConfigs) {
            if (this.isFamily(members) && !this.doesMembersContainStepParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['family'];
            } else if (this.subscriberOnly(members) && !this.doesMembersContainStepParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['subscriber'];
            } else if (this.subscriberAndSpouseOnly(members) && !this.doesMembersContainStepParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['spouse'];
            } else if (this.subscriberAndDependentsOnly(members) && !this.doesMembersContainStepParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['parent'];
            } else if (this.childOnly(members) && !this.doesMembersContainStepParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['children'];
            } else if (this.subscriberSpouseChildrenParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['subscriber_spouse_children_parent'];
            } else if (this.subscriberSpouseParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['subscriber_spouse_parent'];
            } else if (this.subscriberChildrenParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['subscriber_children_parent'];
            } else if (this.subscriberParent(members)) {
                memberCoverageOption = memberCoverageOptionConfigs['subscriber_parent'];
            }
        }
        return memberCoverageOption;
    }

    // getWhoseCoveredData() is used to update the 'whoseCovered' object within the workflow values for Broker Portal
    getWhoseCoveredData(formattedFirstStepData: any, workflowValues) {
        let whoseCovered = getValue(workflowValues, 'coverage.whoseCovered');

        for (const key of ['subscriber', 'spouse', 'dependents', 'stepParents']) {
            let member = formattedFirstStepData.demographics[key];
            if (key === 'dependents' || key === 'stepParents') {
                const dependentMemberData = formattedFirstStepData.demographics?.dependents;
                whoseCovered[key] = dependentMemberData ? this.getDependentsWithMemberType(dependentMemberData, key) : null;
            } else if (member) {
                // We only add the member if the member exists within the demographics object. Otherwise null it out.
                whoseCovered[key] = member;
            } else {
                whoseCovered[key] = null;
            }
        }
        // update the value of 'whose_covered' within the whoseCovered object.
        const whoseCoveredKey = 'whose_covered';
        whoseCovered[whoseCoveredKey] = this.getWhoseCoveredMembers(whoseCovered);
        return whoseCovered;
    }

    private getWhoseCoveredMembers(whoseCovered: IWhoseCovered): string {
        if (this.isFamily(whoseCovered) && !this.doesMembersContainStepParent(whoseCovered)) {
            return 'family';
        } else if (this.subscriberOnly(whoseCovered) && !this.doesMembersContainStepParent(whoseCovered)) {
            return 'subscriber';
        } else if (this.subscriberAndSpouseOnly(whoseCovered) && !this.doesMembersContainStepParent(whoseCovered)) {
            return 'spouse';
        } else if (this.subscriberAndDependentsOnly(whoseCovered) && !this.doesMembersContainStepParent(whoseCovered)) {
            return 'parent';
        } else if (this.childOnly(whoseCovered) && !this.doesMembersContainStepParent(whoseCovered)) {
            return 'child_only';
        } else if (this.subscriberSpouseChildrenParent(whoseCovered)) {
            return 'subscriber_spouse_children_parent';
        } else if (this.subscriberSpouseParent(whoseCovered)) {
            return 'subscriber_spouse_parent';
        } else if (this.subscriberChildrenParent(whoseCovered)) {
            return 'subscriber_children_parent';
        } else if (this.subscriberParent(whoseCovered)) {
            return 'subscriber_parent';
        }
    }

    public childOnly(members: IWhoseCovered): boolean {
        return !members.subscriber && !members.spouse && this.isDependents(members);
    }

    // Used to add the stepParent and children key based on MemberType on the workflow

    private getDependentsWithMemberType(members: { [key: string]: any }, memberKey: string): IWhoseCovered {
        const dependentMemberType = memberKey === 'dependents' ? MemberType.child_dependent : MemberType.step_parent_dependent;
        return members.filter((member) => member.memberType === dependentMemberType);
    }

    private subscriberAndDependentsOnly(members: IWhoseCovered): boolean {
        return !!members.subscriber && this.isDependents(members);
    }

    private subscriberAndSpouseOnly(members: IWhoseCovered): boolean {
        return !!members.subscriber && !!members.spouse && !this.isDependents(members);
    }

    private subscriberOnly(members: IWhoseCovered): boolean {
        return !!members.subscriber && !members.spouse && !this.isDependents(members);
    }

    private isDependents(members: IWhoseCovered): boolean {
        const hasChildren: boolean =
            Boolean(!!members.children && members.children.length) || Boolean(!!members.dependents && members.dependents.length);
        return hasChildren;
    }

    private doesMembersContainStepParent(members: IWhoseCovered): boolean {
        return Boolean(!!members.stepParents && members.stepParents.length) || Boolean(!!members.stepParents && members.stepParents.length);
    }

    private isFamily(members: IWhoseCovered): boolean {
        // dont really need to check for subscriber since we can never remove a subscriber, but doesnt hurt.
        return this.enrollmentFormattingService.isSpouse(members) && this.isDependents(members) && !!members.subscriber;
    }

    private subscriberSpouseChildrenParent(members: IWhoseCovered): boolean {
        // dont really need to check for subscriber since we can never remove a subscriber, but doesnt hurt.
        return (
            this.enrollmentFormattingService.isSpouse(members) && this.isDependents(members) && this.doesMembersContainStepParent(members)
        );
    }

    private subscriberSpouseParent(members: IWhoseCovered): boolean {
        return (
            this.enrollmentFormattingService.isSpouse(members) && !this.isDependents(members) && this.doesMembersContainStepParent(members)
        );
    }

    private subscriberChildrenParent(members: IWhoseCovered): boolean {
        return (
            !this.enrollmentFormattingService.isSpouse(members) && this.isDependents(members) && this.doesMembersContainStepParent(members)
        );
    }

    private subscriberParent(members: IWhoseCovered): boolean {
        return (
            !this.enrollmentFormattingService.isSpouse(members) && !this.isDependents(members) && this.doesMembersContainStepParent(members)
        );
    }

    // If values are not defined in submitValues, fall back to the the workflow values
    getCoverageValues(submitValues, workflowValues) {
        const countyLocationFromPermanentAddress = {
            county_code: workflowValues.permanent_address.county,
            county_name: workflowValues.permanent_address.county_name,
            state_code: workflowValues.permanent_address.state,
            state_name: workflowValues.permanent_address.state_name,
        };
        const workflowCountyLocation = workflowValues.county_location || countyLocationFromPermanentAddress || null;
        const workflowCoverageType = getValue(workflowValues, 'demographics.coverage_type') || null;
        const workflowCoverageTypes = getValue(workflowValues, 'demographics.coverage_types') || null;
        const workflowZipCode = getValue(workflowValues, 'permanent_address.zipcode') || null;
        const workflowPermanentAddress = getValue(workflowValues, 'permanent_address') || null;

        const coverageTypes = submitValues.coverage_types && submitValues.coverage_types.length > 0 ? submitValues.coverage_types : null;

        return {
            county_location: submitValues.county_location || workflowCountyLocation,
            coverage_type: submitValues.coverage_type || workflowCoverageType,
            coverage_types: coverageTypes || workflowCoverageTypes,
            zipcode: submitValues.zipcode || workflowZipCode,
            permanent_address: submitValues.permanent_address || workflowPermanentAddress,
        };
    }

    getHouseholdMembersValues(
        submitValues,
        workflowValues
    ): { subscriber?: object; spouse?: object; dependents?: object; children?: object; child_only?: object } {
        return ['subscriber', 'spouse', 'dependents', 'children', 'child_only'].reduce((acc, key: string) => {
            if (submitValues[key]) {
                if (isObj(submitValues[key])) {
                    acc[key] = {
                        ...workflowValues[key],
                        ...submitValues[key],
                    };
                } else {
                    acc[key] = submitValues[key].map((dep) => {
                        // Find existing dep in workflow values by uuid
                        const existingDep =
                            workflowValues.dependents &&
                            workflowValues.dependents.find((existingDepVals) => existingDepVals.uuid === dep.uuid);
                        if (existingDep) return { ...existingDep, ...dep };
                        return dep;
                    });
                }
            }
            return acc;
        }, {});
    }
}
