export default class License {
    id: number;
    created_at: string;
    updated_at: string;
    data: string;
    effective_date: string;
    termination_date: string;
    related_id: number;
    related_id_type: string;
    license_number: string;
    license_effective_date: string;
    license_termination_date: string;
    state: string;
}
