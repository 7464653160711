import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { DropdownConfiguration, controlTypes } from '@zipari/shared-ds-util-form';

export interface QLEDocumentUploadStepConfig {
    header: {
        title: string;
        subtitle: string;
    };
    fileRestrictions: any;
    attributes: Array<ModelAttributeConfig>;
    documents: {
        uploadInstructions: string;
        isDocumentTypeRequired: boolean;
    };
    footer?: {
        skipButton?: {
            hide: boolean;
            label: string;
        };
        backButton?: {
            hide: boolean;
            label: string;
        };
        nextButton?: {
            hide: boolean;
            label: string;
        };
    };
}

export interface SelectedQLE {
    reason: string;
    updated_coverage_effective_date: string;
    qle: string;
    date: string;
    id?: string | number;
    state?: string;
    code?: any;
}

export const qleDocumentTypeDropdown: DropdownConfiguration = {
    type: controlTypes.dropdown,
    label: 'Document Type',
    prop: 'docType',
    placeholder: 'Select One',
    options: [],
    validators: ['required'],
};

export interface QLEDocumentUploadEvent {
    id?: number;
    url?: string;
    file_type?: string;
}

export interface UploadedQLEDocument extends QLEDocumentUploadEvent {
    document_type?: string;
}

export interface QLEDocumentUploadStepValue {
    sep_documents?: Array<UploadedQLEDocument>;
}
