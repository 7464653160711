import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@zipari/design-system';
import { AddressSelectModalComponent } from './address-select-modal.component';

@NgModule({
    declarations: [AddressSelectModalComponent],
    imports: [CommonModule, FormsModule, ButtonModule],
    exports: [AddressSelectModalComponent],
})
export class AddressSelectModalModule {}
