import { ButtonLevels, ButtonSizes } from '@zipari/shared-ds-util-button';
import { ModalConfig, ModalHeader, ModalTypes } from '@zipari/shared-ds-util-modal';

export enum providerSearchCoveredTypes {
    dependents = 'dependents',
    spouse = 'spouse',
    stepParents = 'stepParents',
    subscriber = 'subscriber',
    dependent = 'dependent',
}

export enum providerSearchTypes {
    facility = 'facilitySearch',
    doctor = 'doctorSearch',
}

export enum DependentMemberType {
    dependents = 'Dependent',
    stepParents = 'Parent/StepParent',
}

export enum FacilityFiltersKey {
    medicalGroup = 'medical_group',
    facilityType = 'facility_type',
    specialtyGroup = 'specialty_group',
    language = 'language',
}

export const providerSearchFooterButtons = {
    save: {
        content: 'Save & Exit',
        level: ButtonLevels.medium,
        size: ButtonSizes.fullWidth,
    },
    back: {
        content: 'Back',
        level: ButtonLevels.medium,
        size: ButtonSizes.fullWidth,
    },
    continue: {
        content: 'Next',
        level: ButtonLevels.high,
        size: ButtonSizes.fullWidth,
    },
    skip: {
        content: 'Skip',
        level: ButtonLevels.medium,
        size: ButtonSizes.fullWidth,
    },
};

export enum facilityFilterControlProp {
    specialties = 'specialties',
    medicalGroups = 'medical_groups',
    facilityType = 'facility_type',
    language = 'language',
    hospitalAffiliations = 'hospital_affiliations',
}

export const defaultFiltersModalConfig: ModalConfig = new ModalConfig({
    type: ModalTypes.flyout,
    direction: 'right',
    header: new ModalHeader({
        title: 'Filters',
        showClose: true,
    }),
});

export const defaultPCPModalConfig: ModalConfig = new ModalConfig({
    type: ModalTypes.flyout,
    direction: 'right',
    header: new ModalHeader({
        title: 'PCP',
        showClose: true,
    }),
});
