export const mockStepperConfig = [
    {
        id: 2739141,
        state: 'in_progress',
        locked: false,
        values: {},
        schema: {
            id: 1116,
            workflow: 'bp_individual_enrollment',
            step_number: 1,
            type: 'eligibility',
            label: 'Demographics',
            key: 'eligibility__information',
            description:
                'Before we can enroll the subscriber, we need to know when they will need their coverage to start, and what their relationship is to your client.',
            config: {},
        },
        step_number: 1,
    },
    {
        id: 2739142,
        state: 'not_started',
        locked: false,
        values: {},
        schema: {
            id: 1117,
            workflow: 'bp_individual_enrollment',
            step_number: 2,
            type: 'plan_selection',
            label: 'Plan Selection',
            key: 'plan_selection',
            description: '',
            config: {},
        },
        step_number: 2,
    },
    {
        id: 2739143,
        state: 'skipped',
        locked: false,
        values: {},
        schema: {
            id: 1118,
            workflow: 'bp_individual_enrollment',
            step_number: 3,
            type: 'dental_vision',
            label: 'Specialty Plan',
            key: 'dental_vision',
            description: '',
            config: {},
        },
        step_number: 3,
    },
];

export const globalConfig = {
    toolbar: {
        label: 'Market Segment',
        icons: [
            {
                count: 'notifications',
                label: 'Notifications',
                route: '/broker-portal/notifications',
                icon: 'notifications',
                auth: 'loggedIn',
            },
            {
                label: 'Messages',
                route: '/broker-portal/messages',
                icon: 'email',
                auth: 'loggedIn',
            },
            {
                label: 'Profile & Settings',
                route: '/broker-portal/profile',
                icon: 'profile',
                auth: 'loggedIn',
            },
            {
                logout: true,
                label: 'Logout',
                route: '/logout',
                icon: 'exit',
                auth: 'loggedIn',
            },
        ],
        routes: {
            __is_array: true,
            individual: {
                label: 'Individual',
                icon: 'person',
                prop: 'individual',
                show: false,
                route: '/broker-portal/individual/dashboard',
            },
            medicare: {
                label: 'Medicare',
                icon: 'library',
                prop: 'medicare',
                show: false,
                link: '{{medicare_broker_portal_url | safe}}',
            },
            group: {
                label: 'Small Group',
                icon: 'payments',
                prop: 'small-group',
                show: false,
                link: '{{group_broker_portal_url | safe}}',
            },
        },
    },
    analytics: {
        cx_capture: {
            base_url: 'https://democare.dev.zipari.io/events/',
            source_system_name: 'bp_democare',
        },
        posthog: {
            initialize: false,
            posthog_api_key: 'phc_CNdt9DfmaeToYeq8ThZBzvUpQ7rIxVIDPvV8wdjDN0s',
            api_host: 'https://posthog.sb1.zipari.cloud',
            debug_enabled: false,
            autocapture_on: false,
            customEvent_on: false,
        },
    },
    baseUrl: '',
    handleAuth: true,
    tenantName: 'Brand Health',
    title: 'Brand Health Broker Portal',
    navigation: {
        menu: {
            sections: [],
            footer: {},
        },
        links: [],
        icons: {},
        buttons: [
            {
                type: 'auth',
            },
        ],
        toolbar: {},
    },
    preventFamilyHighlightAttributes: true,
};

export const mockStepperSteps = [
    {
        id: 2761657,
        workflow: 'bp_individual_quote',
        step_number: 1,
        type: 'eligibility',
        label: 'Eligibility Information',
        key: 'quote_eligibility_information',
        description:
            'To see what policies your subscriber will be eligible for, we need to know when they need their coverage to start, and where they live.',
        state: 'in_progress',
        locked: false,
        stepIndex: 1,
        uniqueId: 'stepper-step--eligibility',
    },
    {
        id: 2761658,
        workflow: 'bp_individual_quote',
        step_number: 2,
        type: 'plan_selection',
        label: 'Select Plans',
        key: 'quote_plan_selection',
        description:
            'Check out the simple Friday plans to find one that covers you best. Having trouble choosing? We’ve got you covered! Call us at 844-451-4444 and our Colorado-based sales folks will help you select a great fit.',
        state: 'not_started',
        locked: false,
        values: {},
        stepIndex: 2,
        uniqueId: 'stepper-step--plan_selection',
    },
];

export const simpleChanges = {
    activeStepType: {
        currentValue: 'eligibility',
        firstChange: false,
        previousValue: null,
    },
    activeStepNumber: {
        previousValue: null,
        currentValue: 'eligibility',
        firstChange: false,
    },
    step: [],
} as any;
