import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { cloneObject, deepMergeIncludingNulls, getValue, isInEnum, formatConfig } from '@zipari/web-utils';
import { GlobalConfig, USER_ROLES } from '@zipari/shared-sbp-constants';
import { ApiListResponse, BrokerShoppingEndpointURL, RolePermissions } from '@zipari/shared-sbp-models';
import { from, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

import * as brokerPortalConfig from '../../../configs/broker-portal.config.json';
import * as brokerPortalMedicareConfig from '../../../configs/medicare-broker-portal.config.json';
import * as zshopConfig from '../../../configs/shopping.config.json';
import * as zshopMedicareConfig from '../../../configs/medicare-shopping.config.json';

export class ConfigApiResponse {
    ANGULAR_PARAMS: any;
    APP: any;
    APP_NAME: string;
    CSRF_TOKEN: string;
    SESSION_COOKIE_AGE: number;
    TENANT_NAME: string;
}

export enum nonMarketSegmentConfigs {
    workbench = 'workbench',
    landing = 'landing',
    sitemap = 'sitemap',
    de = 'de',
    ede = 'ede',
    footer = 'footer',
    individual = 'individual',
    messages = 'messages',
    notifications = 'notifications',
    profile = 'profile',
    global = 'global',
    login = 'login',
    register = 'register',
    forgot_password = 'forgot-password',
    reset_password = 'reset-password',
    'session-expired' = 'session-expired',
}

export enum validBrokerPortalMarketSegments {
    'large-group' = 'large-group',
    'small-group' = 'small-group',
    individual = 'individual',
    medicare = 'medicare',
}

export enum validShoppingMarketSegments {
    individual = 'individual',
    medicare = 'medicare',
    'small-group' = 'small-group',
    'large-group' = 'large-group',
}

@Injectable()
export class ConfigService {
    public configUpdated = new BehaviorSubject<any>(null);
    public initialConfigResponse;
    public appRoute: string;
    public app: string;
    public tenant: string;
    public configs: any;
    public permissions: RolePermissions = {};
    public activeRoute;
    public activeRouteSegmentForLogo;
    newMarketSegment;
    busy: Subscription;
    _currentState = '';
    private readonly configEndpoint = 'api/admin/frontend/';

    constructor(private http: HttpClient, @Inject('environment') private environment) {}

    get currentState(): string {
        return this._currentState;
    }

    public getMarketSegment(route: ActivatedRouteSnapshot) {
        return getValue(route, 'root.children.0.children.0.children.0.data.marketSegment');
    }

    public getConfig(): Observable<ConfigApiResponse> {
        return this.http.get<ConfigApiResponse>('config/');
    }

    /*
        CONFIG here is a global variable provided by django-frontend
        This config comes from config-services for a given app and tenant
    */
    public getPreloadedConfig(): ConfigApiResponse {
        return CONFIG;
    }

    // Get config object as promise needed for Angular's APP_INITIALIZER
    public getPreloadedConfigPromise(): Promise<ConfigApiResponse> {
        return new Promise((resolve, reject) => {
            resolve(this.getPreloadedConfig());
        });
    }

    public async initializeDBConfigWithLocalOverride(
        appName: string,
        { setConfig = true, makeAPICall = false, returnFullConfig = false }
    ): Promise<ConfigApiResponse> {
        const configToUse = makeAPICall ? await this.getConfig().toPromise() : await this.getPreloadedConfigPromise();
        const localConfig = this.getConfigJSON();
        const mockedResponse: ConfigApiResponse = new ConfigApiResponse();
        let finalAppConfig = cloneObject(configToUse.APP);

        finalAppConfig = deepMergeIncludingNulls(finalAppConfig, localConfig);
        configToUse.APP = finalAppConfig;

        if (setConfig) {
            if (returnFullConfig) {
                return configToUse;
            } else {
                return await this.setConfig(configToUse);
            }
        } else return mockedResponse;
    }

    public initializeConfig(): Promise<any> {
        const configPromise = this.getPreloadedConfigPromise();

        return configPromise.then((response) => this.setConfig(response).then(() => response));
    }

    public initializeLocalConfig(appName: string, setConfig = true): Promise<ConfigApiResponse> {
        const localConfig = this.getConfigJSON();
        const mockedResponse = new ConfigApiResponse();

        mockedResponse.APP = localConfig[appName];
        mockedResponse.APP_NAME = appName;

        // set unless overridden
        if (setConfig) {
            return this.setConfig(mockedResponse);
        }

        return Promise.resolve(mockedResponse);
    }

    public handleOverrideOfDefaultConfig(overrideConfig: any, appName: string): Promise<any> {
        return this.importAppConfigJson(appName).then((module) => {
            const configJson = module?.default;
            let finalAppConfig = cloneObject(configJson);
            finalAppConfig = deepMergeIncludingNulls(finalAppConfig, overrideConfig);
            return finalAppConfig;
        });
    }

    /*
        Handles dynamic import of app-specific config file
    */
    public importAppConfigJson(appName: string): Promise<any> {
        const configMapping = {
            'broker-portal': brokerPortalConfig,
            zshop: zshopConfig,
            'zshop-medicare': zshopMedicareConfig,
            'broker-portal-medicare': brokerPortalMedicareConfig,
        };

        return new Promise((resolve) => resolve(configMapping[appName] || null));
    }

    determineAppRoute(app) {
        switch (app) {
            case 'json-manager':
                return 'configuration-manager';
            case 'docs':
                return 'styleguide';
            case 'zshop':
                return 'shopping';
            case 'zshop-medicare':
                return 'shopping';
            case 'broker-portal-medicare':
                return 'broker-portal';
            default:
                return app;
        }
    }

    public setActiveRouteFromUrl(route: ActivatedRoute) {
        const marketSegmentCheck: string = getValue(route, 'snapshot.parent.url.0.path');
        // Only set as the active route if the config has a matching top-level value
        if (marketSegmentCheck && this.configs.hasOwnProperty(marketSegmentCheck)) {
            this.activeRoute = marketSegmentCheck;
        }
    }

    public getPageConfig<T>(page: string): T {
        // nonMarketSegmentConfigs contains items in the configuration that should be pulled from the root of the
        // configuration
        if (isInEnum(nonMarketSegmentConfigs, page) || !this.activeRoute) {
            return cloneObject(this.configs[page]);
        } else if (this.activeRoute && this.configs?.[this.activeRoute]) {
            return cloneObject(this.configs[this.activeRoute][page]);
        } else {
            return <T>{};
        }
    }

    checkIfMarketSegmentIsProvidedInConfig(marketSegment) {
        let inEnum, keys;
        const relevantConfig = this.configs[marketSegment];
        switch (this.app) {
            case 'zshop':
                inEnum = isInEnum(validBrokerPortalMarketSegments, marketSegment);
                keys = Object.keys(validBrokerPortalMarketSegments);

                if (relevantConfig && inEnum) {
                    return true;
                } else if (!relevantConfig && inEnum) {
                    for (let i = 0; i < keys.length; i++) {
                        if (!!this.configs[keys[i]] && keys[i] === marketSegment) {
                            this.newMarketSegment = keys[i];
                        }
                    }

                    return false;
                } else {
                    return true;
                }

            case 'broker-portal':
                inEnum = isInEnum(validBrokerPortalMarketSegments, marketSegment);
                keys = Object.keys(validShoppingMarketSegments);

                if (relevantConfig && inEnum) {
                    return true;
                } else if (!relevantConfig && inEnum) {
                    for (let i = 0; i < keys.length; i++) {
                        if (!!this.configs[keys[i]] && keys[i] === marketSegment) {
                            this.newMarketSegment = keys[i];
                        }
                    }

                    return false;
                } else {
                    return true;
                }
        }
    }

    validateMarketSegment(route: ActivatedRouteSnapshot) {
        const marketSegment = this.getMarketSegment(route);

        if (marketSegment) {
            const valid = this.marketSegmentIsValidInApp(route) && this.checkIfMarketSegmentIsProvidedInConfig(marketSegment);

            if (!valid && !!this.newMarketSegment) {
                return false;
            }
        }

        return true;
    }

    marketSegmentIsValidInApp(route: ActivatedRouteSnapshot) {
        switch (this.app) {
            case 'zshop':
            case 'zshop-medicare':
                return isInEnum(validShoppingMarketSegments, this.getMarketSegment(route));
            case 'broker-portal':
            case 'broker-portal-medicare':
                return isInEnum(validBrokerPortalMarketSegments, this.getMarketSegment(route));
        }
    }

    public getAppConfigs(tenant: string, application: string): Observable<ApiListResponse<any>> {
        let params = new HttpParams().set('app_name', application).set('tenant_name', tenant);

        return this.http.get<ApiListResponse<any>>(this.configEndpoint, { params: params });
    }

    public updateConfig(configuration: any): Observable<any> {
        delete configuration['version_number'];
        return this.http.put<any>(`${this.configEndpoint}/${configuration.id}/`, configuration);
    }

    public patchConfig(configuration: any): Observable<any> {
        delete configuration['version_number'];
        return this.http.patch<any>(`${this.configEndpoint}${configuration.id}/`, configuration);
    }

    public postConfig(configuration: any): Observable<any> {
        return this.http.post<any>(this.configEndpoint, configuration);
    }

    public applyStateCohortConfigs(fetchNewConfigProps: { setConfig: boolean; makeAPICall: boolean }) {
        return from(
            this.initializeDBConfigWithLocalOverride(this.environment['app'], fetchNewConfigProps).then((config) => {
                this.configs = config;
            })
        );
    }

    private getConfigJSON(): any {
        try {
            return require('../../../configs/config.json');
        } catch {
            return {};
        }
    }

    private setConfig(data: ConfigApiResponse): Promise<any> {
        this.initialConfigResponse = data;
        this.app = data.APP_NAME;
        this.appRoute = this.determineAppRoute(this.app);
        this.tenant = data.TENANT_NAME;
        this._currentState = data?.APP?.state;
        const configPromise = this.handleOverrideOfDefaultConfig(data.APP, this.app);

        configPromise.then((config) => {
            this.configs = formatConfig(config);
            window['mergedConfig'] = this.configs;

            // make sure anything subscribing to this subject knows to refresh it's configs
            this.configUpdated.next(this.configs);

            const globalConfig = this.configs?.global;
            if (globalConfig.formControlOverride) {
                localStorage.setItem('formControlOverride', JSON.stringify(globalConfig.formControlOverride));
            }

            this.permissions = this.configs['role-permissions'];
        });
        return configPromise;
    }

    public getParentDependentEnabled(): boolean {
        const globalConfig: GlobalConfig = this.getPageConfig('global');
        return globalConfig?.featuresFlag?.whoseCovered?.hasParentDependents || false;
    }

    public getEndpoint(endpointURLS: BrokerShoppingEndpointURL): string {
        const { brokerPortalEndpoint, shoppingEndpoint } = endpointURLS;

        return this.app.includes(USER_ROLES.BROKER) ? brokerPortalEndpoint : shoppingEndpoint;
    }
}
