import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatComponent } from './chat.component';
import { ButtonModule } from '@zipari/design-system';

@NgModule({
    declarations: [ChatComponent],
    imports: [CommonModule, ButtonModule],
    exports: [ChatComponent],
})
export class ChatModule {}
