import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

export interface PosthogConfiguration {
    posthog_api_key: string;
    api_host: string;
    debug_enabled: boolean;
    autocapture_on?: boolean;
    customEvent_on?: boolean;
    initialize?: boolean;
}

export interface MtmEventInput {
    navEventText: string;
    transactionCode: string;
    rest: object;
}

@Injectable({
    providedIn: 'root',
})
export class MtmService {
    config: PosthogConfiguration;
    dataToSendAsPartOfEveryCall: object;

    constructor() {}

    initialize(config: PosthogConfiguration, data?: object) {
        if (!config?.initialize) return;

        this.config = config;

        const { posthog_api_key, api_host, debug_enabled, autocapture_on } = this.config;
        // Autocapture is off as too much of the data we need to capture is not already on the html which
        // is where autocapture pulls from
        posthog.init(posthog_api_key, {
            api_host,
            autocapture: !!autocapture_on,
            capture_pageview: false,
            advanced_disable_decide: true,
            disable_session_recording: true,
        });

        if (debug_enabled) posthog.debug();
    }

    sendEvent(eventKey: string, eventData?: object) {
        if (this.config?.customEvent_on) {
            posthog.capture(eventKey, { ...eventData });
        }
    }

    identifyUserOnLogin(username: string) {
        if (username) {
            posthog.identify(username);
        }
    }

    resetUserOnLogout() {
        posthog.reset();
    }

    // sendNavEvent(eventData: { navEventText: string; transactionCode?: string; rest?: object }) {
    //     if (this.config) {
    //         const dataToSendUp = this.createObjectToSendUp(eventData.rest);
    //         const dataWithOptionalTransactionCode = eventData.transactionCode
    //             ? // eslint-disable-next-line
    //               { AUD_TRANS_TYP_CD: eventData.transactionCode, ...dataToSendUp }
    //             : dataToSendUp;
    //         this.dataToSendAsPartOfEveryCall
    //             ? posthog.capture(eventData.navEventText, { ...dataWithOptionalTransactionCode })
    //             : this.queueOfEventsCalledPreDataRetrieval.push({
    //                   eventKey: eventData.navEventText,
    //                   data: { ...dataWithOptionalTransactionCode },
    //                   isNavEvent: true,
    //               });
    //     }
    // }

    createObjectToSendUp(eventData?: object) {
        return {
            ...eventData,
            // eslint-disable-next-line
            USER_DATA: this.dataToSendAsPartOfEveryCall,
            // eslint-disable-next-line
            AUD_TRANS_CTGY_TYP: 'Site Activity',
        };
    }
}
