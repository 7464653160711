<message-banner *ngIf="displayMessageBanner" [config]="messageBannerConfig" (actionClicked)="state.showBanner = false"> </message-banner>

<card
    [config]="{
        hideSubHeader: true
    }"
    class="calculate-premium-card card"
    [ngClass]="{ 'is-open': state.open }"
    *ngIf="state && formGroup"
>
    <header title>
        <h6 class="t-bold">{{ config?.header?.title }}</h6>
        <div class="calculate-premium-card__toggle toggle" (click)="state.open = !state.open" *ngIf="state.started">
            <a class="t-link" *ngIf="state.open">
                <i class="icon icon--small">{{ config.header.toggle.close.icon }}</i></a
            >
            <a class="t-link" *ngIf="!state.open"
                >{{ config.header.toggle.edit.copy }} <i class="icon icon--small">{{ config.header.toggle.edit.icon }}</i></a
            >
        </div>
    </header>
    <div body *ngIf="state.open" [ngClass]="{ isOpen: state.open }">
        <p>{{ config?.body }}</p>
        <div class="t-link learn-more" *ngIf="config.showLearnMore" (click)="toggleModal()">
            <i class="icon icon--small">{{ config.learnMoreConfig?.icon }}</i> {{ config.learnMoreConfig.label }}
        </div>

        <div class="btn-container u-flex u-alignCenter" *ngIf="!state.started">
            <zip-button [level]="config.button.level" [content]="config.button.content" (zipButtonClicked)="state.started = true">
            </zip-button>
        </div>

        <div class="form-container" *ngIf="state.started && state.open">
            <form-group [form]="formGroup" [configs]="config.controls"> </form-group>
        </div>
    </div>

    <div footer *ngIf="state.started && state.open">
        <div class="footer-content u-flex u-justifyEnd">
            <zip-button
                [disabled]="!formGroup.valid"
                [level]="config.footer.button.level"
                [content]="config.footer.button.content"
                (zipButtonClicked)="onSubmit.emit(); state.open = false"
            >
            </zip-button>
        </div>
    </div>
</card>

<modal [config]="learnMoreModalConfig" [condition]="showModal">
    <div body>
        <div class="modal__body__primary-text" *ngFor="let paragraph of config.learnMoreConfig?.modalParagraphs">
            <p>{{ paragraph }}</p>
        </div>
    </div>
    <div footer>
        <zip-button class="close-button" [config]="{ level: 'high', content: 'Close' }" (zipButtonClicked)="toggleModal()"> </zip-button>
    </div>
</modal>
