<article class="ProviderSearch">
    <article class="ProviderSearchContent u-flex" *ngIf="!selectedResult; else profile">
        <aside class="ProviderSearchAside ProviderSearchAside--Desktop" *ngIf="showFiltersMembersAside; else filters">
            <header class="ProviderSearchAside__Header" *ngIf="asideHeaderConfig.showAsideHeader">
                <h4 class="ProviderSearchAside__HeaderTitle t-bold" [innerHTML]="asideHeaderConfig.header"></h4>
                <span class="ProviderSearchAside__HeaderSubTitle t-data" [innerHTML]="asideHeaderConfig.subHeader"></span>
            </header>

            <section
                class="ProviderSearchAside__Section u-flex"
                (click)="selectConsumer(members, i)"
                [ngClass]="{ SelectPCP: member.isMemberSelected, PCPSelected: member.isPCPSelected }"
                *ngFor="let member of members; let i = index"
            >
                <div class="ConsumerInfo u-flex">
                    <div class="u-flex">
                        <div class="BulletContainer">
                            <span
                                *ngIf="member.isMemberSelected && !member.isPCPSelected"
                                class="NumberedBullet--Active"
                                [innerHTML]="i + 1"
                            ></span>
                            <span
                                *ngIf="!member.isPCPSelected && !member.isMemberSelected"
                                class="NumberedBullet--Inactive"
                                [innerHTML]="i + 1"
                            ></span>
                            <i *ngIf="member.isPCPSelected" class="icon icon--medium">check_circle</i>
                        </div>
                        <div class="ConsumerContainer">
                            <div class="u-flex PersonalDetails">
                                <h6 class="SubscriberName t-bold" [innerHTML]="getMemberName(member)"></h6>
                                <span class="SubscriberDOB t-data" [innerHTML]="member.date_of_birth | date : 'MM/dd/yyyy'"></span>
                            </div>
                        </div>
                    </div>
                    <div class="ProviderDetails">
                        <p class="ProviderDetails__Name" *ngIf="!member.isPCPSelected" [innerHTML]="getProviderDetail(member)"></p>
                        <div *ngIf="member.isPCPSelected" class="SelectedProviderContainer">
                            <div class="SelectedProviderDetails">
                                <span class="SelectedProviderDetails__Name t-bold" [innerHTML]="selectedProviderName(member)"></span>
                                <span
                                    class="SelectedProviderDetails__PrimarySpecialty t-data"
                                    [innerHTML]="member.selectedProvider.primary_specialty"
                                ></span>
                            </div>
                            <icon name="delete" (click)="removePCP(member)" class="SelectedProviderDetails__DeleteIcon"></icon>
                        </div>
                    </div>
                </div>
                <i class="icon icon--medium icon--pointer" *ngIf="!member.isPCPSelected"> chevron_right </i>
            </section>
        </aside>
        <aside class="ProviderSearchFilters ProviderSearchFilters--Desktop" *ngIf="showFiltersAside" #filters>
            <header class="ProviderSearchFilters__Header">
                <h6 class="ProviderSearchFilters__HeaderText t-bold" [innerHTML]="'Filter Results'"></h6>
                <zip-button
                    *ngIf="showFiltersClose"
                    [config]="{ level: 'text', icon: 'close' }"
                    (zipButtonClicked)="toggleFilters()"
                ></zip-button>
            </header>

            <main class="ProviderSearchFilters__Body">
                <section class="ProviderSearchFilters__Section" *ngFor="let group of config.filters.groups">
                    <header *ngIf="group.header" class="ProviderSearchFilters__SectionHeader">
                        <p class="t-data t-bold t-caps" [innerHTML]="group.header"></p>
                    </header>

                    <form-control
                        *ngFor="let control of group.controls"
                        [config]="control"
                        [group]="filtersForm"
                        [control]="filtersForm.get(control.prop)"
                    >
                    </form-control>
                </section>
            </main>

            <div class="ProviderSearchFilters__Footer u-flex u-justifyBetween">
                <zip-button level="medium" content="Clear Filters" (zipButtonClicked)="handleFilters('clear')"> </zip-button>
                <zip-button level="high" content="Filter" (zipButtonClicked)="handleFilters('apply')"> </zip-button>
            </div>
        </aside>
        <!-- Main body -->
        <main class="ProviderSearch__Main layout--detail__main">
            <zip-button
                *ngIf="showFiltersMembersAside"
                class="ProviderSearch__MainAside--Mobile"
                [config]="{ level: 'text', content: 'Show PCP', icon: 'tune' }"
                (zipButtonClicked)="showPCPModal = true"
            >
            </zip-button>

            <zip-button
                *ngIf="showFiltersAside || !showFiltersMembersAside"
                class="ProviderSearch__MainFilters--Mobile"
                [config]="{ level: 'text', content: 'Filter Results', icon: 'tune' }"
                (zipButtonClicked)="showFilters = true; showFiltersModal = true"
            >
            </zip-button>

            <section class="ProviderSearch__Container">
                <div class="ProviderSearch__MainBanner">
                    <search-bar
                        [initialZipcode]="zipcode"
                        [config]="searchBarConfig"
                        [initialPCCDistance]="distance"
                        (initialDistance)="distance = $event"
                        (distanceChanged)="updateDistance($event)"
                        (search)="updateZipcodeSearch($event)"
                        (nameSearch)="searchByName($event)"
                    >
                    </search-bar>
                </div>
                <div class="u-flex u-relative">
                    <main #scrollToSearchResults class="ProviderSearch__MainContent" [zipBusy]="busy">
                        <header class="ProviderSearch__MainHeader">
                            <zip-button
                                *ngIf="showFiltersMembersAside"
                                [config]="{ level: 'high', content: 'Filter Results' }"
                                (zipButtonClicked)="toggleFilters()"
                            ></zip-button>
                        </header>
                        <ng-container *ngIf="backendResults.length > 0; else noResults">
                            <p
                                *ngIf="showFiltersMembersAside && this.whichWorkflow === 'doctorSearch'"
                                [innerHTML]="doctorsFoundMessage()"
                            ></p>
                            <section class="SearchResults">
                                <provider-card
                                    *ngFor="let thisResult of backendResults; let i = index"
                                    [result]="thisResult"
                                    [index]="i"
                                    [resultIsSelected]="resultIsSelected"
                                    [selectedProviders]="selectedProviders"
                                    [currentMember]="currentMember"
                                    [removePCPavatar]="removePCPavatar"
                                    [config]="config.providerCardConfig"
                                    [whichWorkflow]="whichWorkflow"
                                    (viewProfile)="!config.disableViewProfile ? viewSelectedProfile($event) : null"
                                    (addResultChange)="addResult($event)"
                                    (removeResultChange)="removeResult($event)"
                                >
                                </provider-card>
                            </section>
                            <paginator
                                class="ProviderCardPaginator"
                                [config]="paginatorConfig"
                                [data]="backendResults"
                                (pageSelected)="goToPage($event)"
                            >
                            </paginator>
                        </ng-container>

                        <ng-template #noResults>
                            <div *ngIf="!isAPILoading && backendResults.length === 0" class="ProviderSearchError">
                                <error-empty
                                    [config]="{
                                        title: config?.noResultsConfig?.header
                                            ? config.noResultsConfig.header
                                            : 'No Physicians Identified with Current Search Criteria.',
                                        image_url:
                                            config?.noResultsConfig?.img ||
                                            'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-4.svg'
                                    }"
                                >
                                </error-empty>
                            </div>
                        </ng-template>
                    </main>
                </div>
            </section>
        </main>
        <footer class="ProviderSearch__Footer">
            <div class="ProviderSearch__Footer--Desktop">
                <zip-button [config]="buttonConfig?.back || defaultFooterButtons.back" (zipButtonClicked)="goToPrevious()"> </zip-button>

                <div class="ProviderSearch__FooterButtons">
                    <zip-button
                        [config]="buttonConfig?.skip || defaultFooterButtons.skip"
                        [disabled]="!isPcpNotSelected"
                        (zipButtonClicked)="onSkip()"
                    >
                    </zip-button>
                    <zip-button
                        *ngIf="buttonConfig?.save"
                        [config]="buttonConfig?.save || defaultFooterButtons.save"
                        (zipButtonClicked)="saveAndExitStep()"
                    >
                    </zip-button>
                    <zip-button
                        [config]="buttonConfig?.continue || defaultFooterButtons.continue"
                        [disabled]="isPcpNotSelected"
                        (zipButtonClicked)="submit()"
                    >
                    </zip-button>
                </div>
            </div>
            <div class="ProviderSearch__Footer--Mobile">
                <zip-button
                    [level]="buttonConfig?.back?.level || 'medium'"
                    [icon]="buttonConfig?.back?.icon || 'chevron_left'"
                    (zipButtonClicked)="goToPrevious()"
                >
                </zip-button>

                <zip-button
                    [config]="buttonConfig?.skip || defaultFooterButtons.skip"
                    [disabled]="!isPcpNotSelected"
                    (zipButtonClicked)="onSkip()"
                >
                </zip-button>

                <zip-button
                    *ngIf="buttonConfig?.save"
                    [level]="buttonConfig?.save?.level || 'medium'"
                    [content]="buttonConfig?.save?.content || 'Save & Exit'"
                    (zipButtonClicked)="saveAndExitStep()"
                >
                </zip-button>

                <zip-button
                    [level]="buttonConfig?.continue?.level || 'high'"
                    [icon]="buttonConfig?.continue?.icon || 'chevron_right'"
                    [disabled]="isPcpNotSelected"
                    (zipButtonClicked)="submit()"
                >
                </zip-button>
            </div>
        </footer>
    </article>

    <ng-template #profile>
        <provider-profile
            [resultIsSelected]="resultIsSelected"
            [selectedResult]="selectedResult"
            [selectedResultIdx]="selectedResultIdx"
            [backendResults]="backendResults"
            [showDetails]="showDetails"
            [contactConfigs]="contactConfigs"
            [profileConfigs]="profileConfigs"
            [educationAndTrainingConfigs]="educationAndTrainingConfigs"
            [pins]="pins"
            [busy]="busy"
            [removePCPavatar]="removePCPavatar"
            (viewAllResultsChange)="viewAllResults()"
            (previousNextChange)="selectResult($event)"
            (addProviderChange)="addResult($event)"
            (removeProviderChange)="removeResult($event)"
            (pinSelect)="selectPin($event)"
        >
        </provider-profile>
    </ng-template>
</article>

<provider-search-modals
    [showPCPModal]="showPCPModal"
    [showFiltersModal]="showFiltersModal"
    [whichWorkflow]="whichWorkflow"
    [pcpModalConfig]="pcpModalConfig"
    [filtersModalConfig]="filtersModalConfig"
    [asideHeaderConfig]="asideHeaderConfig"
    [filterGroupConfig]="config.filters.groups"
    [members]="members"
    [filtersForm]="filtersForm"
    (togglePCPModal)="showPCPModal = false"
    (toggleFiltersModal)="toggleFilters()"
    (filter)="handleFilters('apply')"
    (reset)="handleFilters('clear')"
    (select)="selectedConsumer($event)"
    (removeMemberPCP)="removePCP($event)"
>
</provider-search-modals>
