import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EdeScrollingService {
    scrollingSummaryQueue = [];
    scrollingSectionQueue = [];

    constructor() {}

    handleScrolling() {
        // magic number to take into account the navigation bar that is affecting the user's screen
        const magicNumberForNavigation = 124;
        const scrollContainer = document.getElementsByClassName('shopping__main__content__wrapper')[0];
        if (this.scrollingSectionQueue.length > 0) {
            // manage the queue by handling the current scroll
            this.scrollingSectionQueue.pop();
            const formSectionElements = document.getElementsByClassName('form__section');

            let currentFormSection;
            let scrollTop;
            if (formSectionElements.length === 1) {
                currentFormSection = formSectionElements[0];
                scrollTop = currentFormSection['offsetTop'];
            } else {
                currentFormSection = formSectionElements[formSectionElements.length - 2];

                if (currentFormSection) {
                    const topOfEl = currentFormSection['offsetTop'];

                    // height of the element so we make sure we go to the right one
                    const heightOfEl = currentFormSection['offsetHeight'];

                    // size of the card footer plus a little padding for design purposes
                    const cardFooterSize = 68;

                    scrollTop = topOfEl - magicNumberForNavigation + heightOfEl - cardFooterSize;
                }
            }

            if (scrollTop) {
                // the addition of the 100 is a magic number for a reason
                // this is so that the user can see the top of the previous card
                if (scrollContainer.scrollTo) {
                    scrollContainer.scrollTo({
                        top: scrollTop,
                        behavior: 'smooth',
                    });
                } else {
                    scrollContainer.scrollTop = scrollTop;
                }
            }
        }

        if (this.scrollingSummaryQueue.length > 0) {
            this.scrollingSummaryQueue.pop();

            const formSummaryElement = document.getElementsByClassName('summary__title')[0];
            if (!!formSummaryElement) {
                const topOfEl = formSummaryElement['offsetTop'] - magicNumberForNavigation;

                if (topOfEl) {
                    // the addition of the 100 is a magic number for a reason
                    // this is so that the user can see the top of the previous card
                    scrollContainer.scrollTo({
                        top: topOfEl,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }
}
