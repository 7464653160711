import { FormControl } from '@angular/forms';

/**
 * Default a form control's value to null if it is an empty string.
 * The EDE APIs expect empty keys to have a null value, and will error if there is an empty string
 */
export const formControlDefaultToNull = (control: FormControl) => {
    if (control && (!!control.value || control.value === false)) {
        return control;
    }

    return new FormControl(null);
};
