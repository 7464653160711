import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CardModule } from '@zipari/design-system';
import { DeEnrollmentInfoCardComponent } from './de-enrollment-info-card.component';

@NgModule({
    declarations: [DeEnrollmentInfoCardComponent],
    imports: [CommonModule, ButtonModule, CardModule],
    exports: [DeEnrollmentInfoCardComponent],
})
export class DeEnrollmentInfoCardModule {}
