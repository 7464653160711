import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CardModule, MessageBannerModule } from '@zipari/design-system';
import { FormGroupModule } from '../../form-group/form-group.module';
import { AuthCardComponent } from './auth-card.component';

@NgModule({
    declarations: [AuthCardComponent],
    providers: [DatePipe, CurrencyPipe],
    imports: [CommonModule, CardModule, FormGroupModule, ButtonModule, MessageBannerModule],
    exports: [AuthCardComponent],
})
export class AuthCardModule {}
