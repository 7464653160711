import { ButtonConfig, ButtonLevels } from '@zipari/shared-ds-util-button';

export const defaultSaveButtonConfig: ButtonConfig = {
    content: 'Save',
    level: ButtonLevels.high,
};

export const defaultCancelButtonConfig: ButtonConfig = {
    content: 'Cancel',
    level: ButtonLevels.low,
};

export const defaultExistingClientButtonConfig: ButtonConfig = {
    content: 'View Profile',
    level: ButtonLevels.high,
};

export const clientLabelKey = {
    name: 'Name',
    phone: 'Phone Number',
    address: 'Address',
};
