export class ResponseLinks {
    rel: LinksRel;
    href: string;
}

// todo: need to get marital status
export enum MaritalStatus {
    UNMARRIED = 'UNMARRIED',
    MARRIED = 'MARRIED',
}

// todo: need to get actual sexes
export enum Sexes {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
}

// todo: need to get proper income source jobs
export enum IncomeSourceJob {
    JOB = 'JOB',
}

// todo: need to get proper income frequencies
export enum IncomeFrequencies {
    ANNUALLY = 'ANNUALLY',
}

// todo: need to get tax return filing state type
export enum TaxReturnFilingStateType {
    HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
    MARRIED_FILING_JOINTLY = 'MARRIED_FILING_JOINTLY',
}

// todo: need to get offerred employee coverage
export enum OfferedEmployeeCoverage {
    OFF = 'OFF',
}

// todo: need to get insurance market type
export enum InsuranceMarketType {
    NONE = 'NONE',
}

// todo: need to get insurance market type
export enum Suffixes {
    JR = 'Jr.',
    SR = 'Sr.',
    II = 'II',
    III = 'III',
    IV = 'IV',
    V = 'V',
}

// todo: need to get reconcile PTC indicator type
export enum ReconcilePTCIndicatorType {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    TRUE = 'TRUE',
}

// todo: need to get incarceration types
export enum IncarcertationType {
    NOT_INCARCERATED = 'NOT_INCARCERATED',
}

export enum signatureType {
    APPLICANT = 'APPLICANT',
}

export enum ResultType {
    SUCCESS = 'SUCCESS',
    SUCCESS_WITH_ERROR = 'SUCCESS_WITH_ERROR',
}

export enum PhoneNumberType {
    HOME = 'HOME',
    MOBILE = 'MOBILE',
    WORK = 'WORK',
}

export enum ErrorTypes {
    CLIENT_ERROR = 'CLIENT_ERROR',
}

export enum UseCaseTypes {
    INITIAL_APP = 'INITIAL_APP',
}

export enum InsuranceApplicationType {
    LEGACY_ONLINE = 'LEGACY_ONLINE',
}

export enum LinksRel {
    self = 'self',
}

export enum CreatingSystemId {
    LEGACY_ONLINE = 'LEGACY_ONLINE',
}

export enum CreatingSystemRole {
    FFM_ONLINE = 'FFM_ONLINE',
}

export enum YesNoResponse {
    NO = 'NO',
    YES = 'YES',
}

export enum ParentCaretakerCategoryStatus {
    NO = 'NO',
    YES = 'YES',
    TEMPORARY = 'TEMPORARY',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum IndianStatusReason {
    '178_ATSTD_NOT_INDIAN' = '178_ATSTD_NOT_INDIAN',
}

export enum NonEscMecStatusReason {
    '999_N_A' = '999_N_A',
}

export enum MedicaidLawfulPresenceStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum medicaidLawfulPresenceStatusReason {
    '569_APPLICANT_ATSTD_CITIZEN' = '569_APPLICANT_ATSTD_CITIZEN',
}

export enum dependentChildCoveredStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum chipWaitingPeriodStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum chipStateHealthBenefitStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum qhpLawfulPresenceStatus {
    YES = 'YES',
    NO = 'NO',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    DELAY = 'DELAY',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
}

export enum fiveYearBarStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum citizenshipStatusReason {
    '999_N_A' = '999_N_A',
}

export enum preliminaryAptcStatusReason {
    '999_N_A' = '999_N_A',
}

export enum escMecStatusReason {
    '999_N_A' = '999_N_A',
}

export enum medicaidResidencyStatusReason {
    '999_N_A' = '999_N_A',
}

export enum citizenshipDataEventStatus {
    HUB_CALL_COMPLETED = 'HUB_CALL_COMPLETED',
}

export enum preliminaryMedicaidStatusReason {
    '106_NOT_ALL_MDCAID_ELGBLT_CRITERIA_MET' = '106_NOT_ALL_MDCAID_ELGBLT_CRITERIA_MET',
}

export enum preliminaryChipStatusReason {
    '107_NOT_CHIP_ELGBL' = '107_NOT_CHIP_ELGBL',
}

export enum preliminaryEmergencyMedicaidStatusReason {
    '109_NOT_MEET_REQUIREMENTS_FOR_EMERGENCY_MDCAID' = '109_NOT_MEET_REQUIREMENTS_FOR_EMERGENCY_MDCAID',
}

export enum dependentChildCoveredStatusReason {
    '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC' = '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC',
    '551_APPLICATION_NOT_REQUESTING_FA' = '551_APPLICATION_NOT_REQUESTING_FA',
    '555_N_A_RULE_DOES_NOT_APPLY' = '555_N_A_RULE_DOES_NOT_APPLY',
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE' = '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE',
    '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE' = '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE',
    '655_NOT_PRELIM_MEDICAID_ELIGIBLE' = '655_NOT_PRELIM_MEDICAID_ELIGIBLE',
    '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS' = '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS',
    '999_N_A_RULE_INDICATOR_IS_Y' = '999_N_A_RULE_INDICATOR_IS_Y',
}

export enum chipStateHealthBenefitStatusReason {
    '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE' = '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE',
}

export enum chipWaitingPeriodStatusReason {
    '139_CHIP_WAITING_PERIOD_IN_EFFECT' = '139_CHIP_WAITING_PERIOD_IN_EFFECT',
    '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE' = '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE',
    '551_APPLICATION_NOT_REQUESTING_FA' = '551_APPLICATION_NOT_REQUESTING_FA',
    '555_N_A_RULE_DOES_NOT_APPLY' = '555_N_A_RULE_DOES_NOT_APPLY',
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE' = '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE',
    '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE' = '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE',
    '656_ATTESTED_TO_CHIP_WAITING_PERIOD' = '656_ATTESTED_TO_CHIP_WAITING_PERIOD',
    '657_APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE' = '657_APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE',
    '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS' = '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS',
    '999_N_A_RULE_INDICATOR_IS_Y' = '999_N_A_RULE_INDICATOR_IS_Y',
}

export enum emergencyMedicaidStatusReason {
    '109_NOT_MEET_REQUIREMENTS_FOR_EMERGENCY_MDCAID' = '109_NOT_MEET_REQUIREMENTS_FOR_EMERGENCY_MDCAID',
}

export enum qhpLawfulPresenceStatusReason {
    '569_APPLICANT_ATSTD_CITIZEN' = '569_APPLICANT_ATSTD_CITIZEN',
}

export enum incarcerationStatusReason {
    '100_ONLY_SSA_DATA_AVAILABLE_BUT_NOT_COMPATIBLE' = '100_ONLY_SSA_DATA_AVAILABLE_BUT_NOT_COMPATIBLE',
}

export enum medicaidNonMagiReferralStatusReason {
    '598_NON_MAGI_REFERRAL_CRITERIA_MET' = '598_NON_MAGI_REFERRAL_CRITERIA_MET',
}

export enum fiveYearBarStatusReason {
    '569_APPLICANT_ATSTD_CITIZEN' = '569_APPLICANT_ATSTD_CITIZEN',
}

export enum childCaretakerDeprivedStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum medicaidStandardBasisType {
    PARENT_CARETAKER_RELATIVE_CATEGORY = 'PARENT_CARETAKER_RELATIVE_CATEGORY',
}

export enum irsDataStatus {
    'HUB_CALL_COMPLETED' = 'HUB_CALL_COMPLETED',
}

export enum medicaidChipIncomeStatusReason {
    '533_ATSTD_ABOVE_MDCAID_STANDARD_CHIP_STANDARD_IS_0_INELGBLE_FOR_MDCAID_CHIP' = '533_ATSTD_ABOVE_MDCAID_STANDARD_CHIP_STANDARD_IS_0_INELGBLE_FOR_MDCAID_CHIP',
}

export enum medicaidTaxRoleType {
    INDIVIDUAL_TAX_FILER = 'INDIVIDUAL_TAX_FILER',
}

export enum qhpResidencyStatusReason {
    '999_N_A' = '999_N_A',
}

export enum residencyAddressSourceType {
    HOME = 'HOME',
}

export enum taxHouseholdStatusReason {
    '999_N_A' = '999_N_A',
}

export enum annualIncomeStatusReason {
    '564_VERIFIED_BASED_ON_EXTERNAL_INCOME_DATA' = '564_VERIFIED_BASED_ON_EXTERNAL_INCOME_DATA',
}

export enum applicationStatus {
    SUBMITTED = 'SUBMITTED',
}

export enum creationOriginType {
    FFM_ONLINE = 'FFM_ONLINE',
}

export enum submissionOriginType {
    FFM_ONLINE = 'FFM_ONLINE',
}

export enum ApplicationStatus {
    NO = 'NO',
    YES = 'YES',
}

export enum ApplicationStatusReason {
    '252_NOT_GRANTED_NOT_SEP_ELGBL' = '252_NOT_GRANTED_NOT_SEP_ELGBL',
}

export enum insuranceProductDivisionType {
    HEALTHCARE = 'HEALTHCARE',
}

export enum catastrophicEligibilityReasonType {
    AGE = 'AGE',
}

export enum IdentityProofingChannelType {
    ONLINE = 'ONLINE',
    IN_PERSON = 'IN_PERSON',
    TELEPHONE = 'TELEPHONE',
}

export enum ApplicationAssistorType {
    NAVIGATOR = 'NAVIGATOR',
    CERTIFIED_APPLICATION_COUNSELOR = 'CERTIFIED_APPLICATION_COUNSELOR',
    IN_PERSON_ASSISTANT_PERSONNEL = 'IN_PERSON_ASSISTANT_PERSONNEL',
    AGENT_BROKER = 'AGENT_BROKER',
    NO_ASSISTANCE = 'NO_ASSISTANCE',
}

export enum IncomeExplanationRequiredReasonType {
    INCOME_HIGHER_THAN_SOURCE = 'INCOME_HIGHER_THAN_SOURCE',
    INCOME_LOWER_THAN_SOURCE = 'INCOME_LOWER_THAN_SOURCE',
}

export enum ApplicationSignatureType {
    APPLICANT = 'APPLICANT',
    AUTHORIZED_REPRESENTATIVE = 'AUTHORIZED_REPRESENTATIVE',
    AGENT_BROKER = 'AGENT_BROKER',
}

export class UpdateApplicationResponse__Result__Computed__Member__Income__Employer {
    employer: string = null;
    jobIncomeExplanationRequiredIndicator: boolean = null;
    jobIncomeExplanationAcceptance: YesNoResponse = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse__Result__Computed__Member__Income {
    annualIncomeExplanationRequired: boolean = null;
    employer: Array<UpdateApplicationResponse__Result__Computed__Member__Income__Employer> = null;
    medicaidIncomeStatus: YesNoResponse = null;
    chipIncomeStatus: YesNoResponse = null;
    medicaidChipIncomeStatusReason: medicaidChipIncomeStatusReason = null;
    attestedMonthlyIndividualIncomeAmt: number = null;
    attestedAnnualizedAptcIndividualIncomeAmt: number = null;
    irsDataStatus: irsDataStatus = null;
    irsDataStatusReason: any = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse__Result__Computed__Member__MedicaidHouseholdComposition {
    medicaidTaxRoleType: medicaidTaxRoleType = null;
    medicaidHouseHoldStatus: YesNoResponse = null;
    medicaidHouseHoldStatusReason: any = null;
    medicaidHouseholdSize: number = null;
    medicaidHouseholdMemberIdentifiers: Array<number> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse__Result__Computed__Member__ResidencyAddress {
    residencyAddressSourceType: residencyAddressSourceType = null;
    streetName1: string = null;
    cityName: string = null;
    stateCode: string = null;
    zipCode: string = null;
    countyName: string = null;
    countyFipsCode: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Applications__BestSEP {
    status: ApplicationStatus = null;
    statusReason: ApplicationStatusReason = null;
    subjectToSviIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class UpdateApplicationResponse__Result__Computed__Member__qhpResidency {
    qhpResidencyStatus: YesNoResponse = null;
    qhpResidencyStatusReason: qhpResidencyStatusReason = null;
    taxHouseholdResidencyAppliesIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class MedicaidChipStandard {
    medicaidStandardPercent: number = null;
    medicaidStandardBasisType: medicaidStandardBasisType = null;
    chipStandardPercent: number = null;
    adultGroupCategoryStatus: YesNoResponse = null;
    childCaretakerDeprivedStatus: childCaretakerDeprivedStatus = null;
    parentCaretakerCategoryStatus: ParentCaretakerCategoryStatus = null;
    parentCaretakerChildList: Array<string> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Members__Structure {
    personTrackingNumber: string = null;
    indianStatus: YesNoResponse = null;
    indianStatusReason: IndianStatusReason = null;
    nonEscMecStatus: YesNoResponse = null;
    nonEscMecStatusReason: NonEscMecStatusReason = null;
    ssnStatus: YesNoResponse = null;
    ssnStatusReason: NonEscMecStatusReason = null;
    medicaidLawfulPresenceStatus: MedicaidLawfulPresenceStatus = null;
    medicaidLawfulPresenceStatusReason: medicaidLawfulPresenceStatusReason = null;
    citizenshipStatus: YesNoResponse = null;
    citizenshipStatusReason: citizenshipStatusReason = null;
    citizenshipDataEventStatus: citizenshipDataEventStatus = null;
    citizenshipDataFoundIndicator: boolean = null;
    preliminaryMedicaidStatus: YesNoResponse = null;
    preliminaryMedicaidStatusReason: preliminaryMedicaidStatusReason = null;
    preliminaryChipStatus: YesNoResponse = null;
    preliminaryChipStatusReason: preliminaryChipStatusReason = null;
    preliminaryAptcStatus: YesNoResponse = null;
    preliminaryAptcStatusReason: preliminaryAptcStatusReason = null;
    preliminaryEmergencyMedicaidStatus: YesNoResponse = null;
    preliminaryEmergencyMedicaidStatusReason: preliminaryEmergencyMedicaidStatusReason = null;
    dependentChildCoveredStatus: dependentChildCoveredStatus = null;
    dependentChildCoveredStatusReason: dependentChildCoveredStatusReason = null;
    chipStateHealthBenefitStatus: chipStateHealthBenefitStatus = null;
    chipStateHealthBenefitStatusReason: chipStateHealthBenefitStatusReason = null;
    chipWaitingPeriodStatus: chipWaitingPeriodStatus = null;
    chipWaitingPeriodStatusReason: chipWaitingPeriodStatusReason = null;
    emergencyMedicaidStatus: YesNoResponse = null;
    emergencyMedicaidStatusReason: emergencyMedicaidStatusReason = null;
    qhpLawfulPresenceStatus: qhpLawfulPresenceStatus = null;
    qhpLawfulPresenceStatusReason: qhpLawfulPresenceStatusReason = null;
    under100FplWithLawfulPresenceDmiIndicator: boolean = null;
    medicaidChipStandard: MedicaidChipStandard = new MedicaidChipStandard();
    escMecStatus: YesNoResponse = null;
    escMecStatusReason: escMecStatusReason = null;
    incarcerationStatus: YesNoResponse = null;
    incarcerationStatusReason: incarcerationStatusReason = null;
    income: UpdateApplicationResponse__Result__Computed__Member__Income = new UpdateApplicationResponse__Result__Computed__Member__Income();
    medicaidHouseholdComposition: UpdateApplicationResponse__Result__Computed__Member__MedicaidHouseholdComposition =
        new UpdateApplicationResponse__Result__Computed__Member__MedicaidHouseholdComposition();
    medicaidNonMagiReferralStatus: medicaidNonMagiReferralStatusReason = null;
    medicaidNonMagiReferralStatusReason: medicaidNonMagiReferralStatusReason = null;
    qhpResidency: UpdateApplicationResponse__Result__Computed__Member__qhpResidency =
        new UpdateApplicationResponse__Result__Computed__Member__qhpResidency();
    medicaidResidencyStatus: medicaidResidencyStatusReason = null;
    medicaidResidencyStatusReason: medicaidResidencyStatusReason = null;
    fiveYearBarStatus: fiveYearBarStatusReason = null;
    fiveYearBarStatusReason: fiveYearBarStatusReason = null;
    residencyAddress: UpdateApplicationResponse__Result__Computed__Member__ResidencyAddress =
        new UpdateApplicationResponse__Result__Computed__Member__ResidencyAddress();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Members {
    [memberID: string]: Computed__Members__Structure;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__TaxHouseholds__TaxhouseholdComposition {
    taxHouseholdStatus: YesNoResponse = null;
    taxHouseholdStatusReason: taxHouseholdStatusReason = null;
    taxHouseholdMemberIdentifiers: Array<string> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__TaxHouseholds__MaxAPTC {
    ptcFplPercentage: number = null;
    taxHouseholdAnnualIncomeAmount: number = null;
    maxAPTCAmount: number = null;
    slcspPremiumAmount: number = null;
    slcspIdentifier: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__TaxHouseholds__AnnualIncome {
    attestedAnnualTaxHouseholdIncomeAmount: number = null;
    attestedAnnualTaxHouseholdIncomePercent: number = null;
    annualIncomeStatus: YesNoResponse = null;
    annualIncomeStatusReason: annualIncomeStatusReason = null;
    incomeExplanationRequiredIndicator: boolean = null;
    usedDocumentAnnualTaxIncomeAmountExplanationRequiredAmount: boolean = null;
    incomeExplanationRequiredReasonType: IncomeExplanationRequiredReasonType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__TaxHouseholds__Structure {
    maxAPTC?: Computed__TaxHouseholds__MaxAPTC = new Computed__TaxHouseholds__MaxAPTC();
    taxHouseHoldComposition: Computed__TaxHouseholds__TaxhouseholdComposition = new Computed__TaxHouseholds__TaxhouseholdComposition();
    annualIncome: Computed__TaxHouseholds__AnnualIncome = new Computed__TaxHouseholds__AnnualIncome();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__TaxHouseholds {
    [houseHoldKey: string]: Computed__TaxHouseholds__Structure;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Applications__EnrollmentGroup__Enrollees__Structure {
    personTrackingNumber: string = null;
    subscriberIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Applications__EnrollmentGroup__Enrollees {
    [enrolleeKey: string]: Computed__Applications__EnrollmentGroup__Enrollees__Structure;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Applications__EnrollmentGroup {
    enrollmentGroupIdentifier: string = null;
    enrollmentGroupName: string = null;
    insuranceProductDivisionType: insuranceProductDivisionType = null;
    enrollees: Computed__Applications__EnrollmentGroup__Enrollees = new Computed__Applications__EnrollmentGroup__Enrollees();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Computed__Applications {
    bestSEP: Computed__Applications__BestSEP = new Computed__Applications__BestSEP();
    finalQhpEffectiveStartDate: string = null;
    finalQhpEffectiveEndDate: string = null;
    enrollmentGroups: Array<Computed__Applications__EnrollmentGroup> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class ApplicationMembers__MailingAddress {
    streetName1: string = null;
    cityName: string = null;
    stateCode: string = null;
    zipCode: string = null;
    countyName: string = null;
    countyFipsCode: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class ApplicationMembers {
    householdContactIndicator: boolean = null;
    firstName: string = null;
    lastName: string = null;
    string: string = null;
    birthDate: string = null;
    mailingAddress: ApplicationMembers__MailingAddress = new ApplicationMembers__MailingAddress();

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Application__ContactInformation__PhoneNumber {
    ext: string = null;
    number: string = null;
    type: PhoneNumberType = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class Application__ContactInformation {
    email: string = null;
    primaryPhoneNumber: Application__ContactInformation__PhoneNumber = new Application__ContactInformation__PhoneNumber();
    secondaryPhoneNumber: Application__ContactInformation__PhoneNumber = new Application__ContactInformation__PhoneNumber();

    constructor(options = null) {
        Object.assign(this, options);
    }
}
