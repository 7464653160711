<article class="modal custom--modal modal--small" @fade>
    <header *ngIf="config.header" class="modal__header t-data">
        {{ config.header }}
    </header>

    <section class="modal__body t-data">
        <ng-container *ngIf="config.HTMLBody; else body">
            <div [innerHtml]="config.HTMLBody"></div>
        </ng-container>
        <ng-template #body>
            {{ config.body | stringBuilder : context }}
        </ng-template>
    </section>

    <footer class="modal__footer">
        <p *ngIf="config.confirm" class="t-link t-data t-bold u-inline" (click)="confirm.emit()">
            {{ config.confirm }}
        </p>
        <p *ngIf="config.cancel" class="t-link t-data t-bold u-inline u-float-right" (click)="cancel.emit()">Cancel</p>
    </footer>
</article>
