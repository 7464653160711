<modal [config]="viewDetailsModalConfig" [condition]="true" (cancel)="closeDetailsModal()">
    <div body>
        <div class="ModalCard__RowDetail" *ngFor="let benefit of plan?.formattedBenefits">
            <p class="t-data t-bold" [innerHTML]="benefit.label" *ngIf="benefit.label"></p>
            <p class="t-data" [innerHTML]="benefit.value" *ngIf="benefit.value"></p>
        </div>

        <div *ngIf="planDetailConfigs?.displayAllDocuments || planDetailConfigs?.displayDocuments; else showSinglePdf">
            <div class="ModalCard__Pdf">
                <div *ngFor="let document of documentList">
                    <a class="ModalCard__DocumentLink" [href]="document.document_id" target="_blank">
                        <icon name="pdf" [config]="{ type: 'link' }"></icon>
                        <span class="t-data t-bold" [innerHTML]="document.label || formatDocumentName(document.type)"></span>
                    </a>
                </div>
            </div>
        </div>
        <ng-template #showSinglePdf>
            <div class="ModalCard__Pdf" *ngIf="!!pdfUrl">
                <a class="ModalCard__DocumentLink" [href]="pdfUrl" target="_blank">
                    <icon name="pdf" [config]="{ type: 'link' }"></icon>
                    <span class="t-data t-bold" [innerHTML]="'All ' + plan?.display_name + ' Benefits'"></span>
                </a>
            </div>
        </ng-template>
    </div>
</modal>
