<section class="form-group" *ngFor="let section of config.sections; let i = index">
    <header class="form-group__header">
        <div class="u-flex u-alignCenter u-justifyBetween u-maxX">
            <h6 class="t-bold">{{ section.label }}</h6>

            <i *ngIf="section.edit" class="icon icon--medium icon--link" (click)="toggleEditModal(section)"> edit </i>
        </div>
    </header>

    <section class="employee__profile__attributes">
        <model-attribute *ngFor="let attribute of section.attributes" [config]="attribute" [context]="context"> </model-attribute>
    </section>
</section>
