export const defaultOptionsConfig = {
    family: {
        value: 'family',
        cards: ['subscriber', 'spouse', 'children'],
    },
    children: {
        value: 'children',
        cards: ['child_only'],
        one_per_policy: false,
    },
    child_only: {
        value: 'children',
        cards: ['child_only'],
        one_per_policy: true,
    },
    subscriber: {
        value: 'subscriber',
        cards: ['subscriber'],
    },
    spouse: {
        value: 'spouse',
        cards: ['subscriber', 'spouse'],
    },
    parent: {
        value: 'parent',
        cards: ['subscriber', 'children'],
    },
    subscriber_spouse_children_parent: {
        value: 'subscriber_spouse_children_parent',
        cards: ['subscriber', 'spouse', 'children', 'parent'],
    },
    subscriber_spouse_parent: {
        value: 'subscriber_spouse_parent',
        cards: ['subscriber', 'spouse', 'parent'],
    },
    subscriber_children_parent: {
        value: 'subscriber_children_parent',
        cards: ['subscriber', 'children', 'parent'],
    },
    subscriber_parent: {
        value: 'subscriber_parent',
        cards: ['subscriber', 'parent'],
    },
};

export enum CoveredMemberKey {
    childonly = 'child_only',
    stepParents = 'stepParents',
    children = 'children',
    subscriber = 'subscriber',
    spouse = 'spouse',
    family = 'family',
    parent = 'parent',
    dependents = 'dependents',
}

export const CoveredMemberCards = ['subscriber', 'children'];
