export interface PaymentSuccessfulResponse {
    status_message?: string;
    response_code?: string;
    reference_id?: string;
    reference_code?: string;
    payment_success?: boolean;
    transaction_reference_number?: string;
    result?: FiservResult;
    autopay_enrollment_status?: string;
    referenceId?: string;
    enrollmentTypeStatus?: string;
}

export interface FiservResult {
    statusCode: string;
    statusMessages: FiservStatusMessage[];
}

export interface FiservStatusMessage {
    code: number;
    type: string;
    description: string;
}
