import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getValue } from '@zipari/web-utils';

import { SearchService } from '../../search.service';

import { ProviderResult } from '../provider-result.model';

@Component({
    selector: 'provider-card',
    templateUrl: './provider-card.component.html',
    styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent {
    @Input() index: number;
    @Input() result: ProviderResult = null;
    @Input() resultIsSelected: boolean;
    @Input() removePCPavatar: boolean;
    @Input() config: any;
    @Input() whichWorkflow: string;
    @Input() selectedProviders: Array<any>;
    @Input() currentMember: any;

    @Output() viewProfile: EventEmitter<number> = new EventEmitter<number>();
    @Output() addResultChange: EventEmitter<ProviderResult> = new EventEmitter<ProviderResult>();
    @Output() removeResultChange: EventEmitter<ProviderResult> = new EventEmitter<ProviderResult>();

    showDetails = false;

    constructor(private searchService: SearchService) {}

    get isSelectDisabled(): boolean {
        const providerSelectedForMember = this.selectedProviders[this.currentMember.type];
        if (providerSelectedForMember && !!Object.entries(providerSelectedForMember).length) {
            return true;
        }

        return this.resultIsSelected;
    }

    getDirections(address: string): string {
        return this.searchService.getDirections(address);
    }

    getValue(prop) {
        const facilityAttributeValue = getValue(this.result, prop);
        return prop.toLowerCase() === 'name' ? facilityAttributeValue?.first_name : facilityAttributeValue;
    }

    onAddResultClick(): void {
        this.addResultChange.emit(this.result);
    }

    onViewProfileClick(): void {
        this.viewProfile.emit(this.index);
    }

    onRemoveResultClick(): void {
        this.removeResultChange.emit(this.result);
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    getFormattedAttributeValue(attribute) {
        const prefix = attribute?.prefix || '';
        const attributeValue = this.getValue(attribute.prop) || '';
        const suffix = attribute?.suffix || '';

        return `<p class="t-data t-bold">${prefix}</p>${attributeValue} ${suffix}`;
    }
}
