<ng-container *ngIf="!isNavHidden">
    <div #toolbarContainerRef>
        <toolbar
            *ngIf="!!navigationService.config.toolbar; else tabletToolbar"
            [isLoggedIn]="isLoggedIn"
            [notifications]="notifications"
            (logoClicked)="logoClicked()"
            (buttonClicked)="onNavButtonClick($event)"
        >
        </toolbar>

        <ng-template #tabletToolbar>
            <tablet-toolbar class="nav__tablet-toolbar"></tablet-toolbar>
        </ng-template>
    </div>

    <mobile-menu
        [isLoggedIn]="isLoggedIn"
        (openContactModal)="navigationService.openContactModal()"
        (handleLogout)="handleLogout()"
    ></mobile-menu>

    <nav
        class="nav"
        [ngClass]="{
            'nav--alt': !!navigationService.config.navAlt || !!navigationService.config.toolbar,
            'display-none': !!navigationService.config.hideNavWhenLoggedOut && !isLoggedIn
        }"
    >
        <div class="container">
            <div class="u-flex">
                <div *ngIf="navigationService.config.showHamburger" class="Nav__Toggle">
                    <icon
                        class="HamburgerIcon__Tablet"
                        name="hamburger"
                        [config]="{ fill: navigationService.config.icons?.fill || 'btn-medium__icon--resting', type: 'pointer' }"
                        (click)="navigationService.toggleMobileMenu()"
                    ></icon>

                    <icon
                        class="HamburgerIcon__Mobile"
                        name="hamburger"
                        [config]="{ fill: 'menu__icon-color--mobile' || 'btn-medium__icon--resting', type: 'pointer' }"
                        (click)="navigationService.toggleMobileMenu()"
                    ></icon>
                </div>

                <span
                    (click)="logoClicked()"
                    class="nav__logo"
                    tabindex="0"
                    [attr.aria-label]="navigationService.config.alt"
                    *ngIf="!navigationService.config.showLogoInToolbar"
                >
                </span>

                <img
                    class="nav__image"
                    *ngIf="navigationService.config.showImage && !navigationService.config.showLogoInToolbar"
                    [src]="navigationService.config.imageLink"
                    alt="Navigation"
                />

                <section
                    class="nav__links"
                    [ngClass]="{ links__padding: navigationService.config.showImage && navigationService.config.showLogoInToolbar }"
                    *ngIf="isLoggedIn"
                >
                    <div
                        class="nav__link"
                        [ngClass]="{
                            'is-open': !!link.items && !!link.open,
                            'is-active': navigationService.isActiveRoute(link.route)
                        }"
                        *ngFor="let link of navigationService.config.links"
                    >
                        <ng-container *ngIf="!!link.route">
                            <a
                                class="t-data"
                                [attr.aria-label]="'navigate to ' + link.route"
                                (click)="navigationService.selectNavLink($event, link)"
                                [routerLink]="link.route"
                                [queryParams]="queryParams"
                            >
                                {{ link.label }}
                            </a>
                        </ng-container>

                        <ng-container *ngIf="!!link.link">
                            <a
                                class="t-data"
                                [href]="link.link"
                                [attr.aria-label]="'navigate to ' + link.link"
                                [target]="link.target || '_blank'"
                            >
                                {{ link.label }}
                            </a>
                        </ng-container>

                        <ng-container *ngIf="!!link.items">
                            <div class="u-flex" (click)="navigationService.selectNavLink($event, link)">
                                <p class="t-data">{{ link.label }}</p>

                                <i class="icon icon--medium" [ngClass]="{ 'icon--rotate180': !!link.open }"> chevron_down </i>
                            </div>
                        </ng-container>

                        <div class="nav__link__menu" (libOutsideClick)="closeLink(link)" *ngIf="!!link.items && !!link.open">
                            <div *ngFor="let item of link.items">
                                <div *ngIf="!!item.route">
                                    <a
                                        class="nav__link__menu__item"
                                        [routerLink]="item.route"
                                        (click)="closeLink(link)"
                                        [queryParams]="queryParams"
                                        [attr.aria-label]="'navigate to ' + item.route"
                                    >
                                        <i class="icon icon--medium">{{ item.icon }}</i>

                                        <div>
                                            <p class="t-data">{{ item.label }}</p>
                                            <p class="t-data t-alt">{{ item.description }}</p>
                                        </div>
                                    </a>
                                </div>
                                <div *ngIf="!!item.externalLink">
                                    <a
                                        class="nav__link__menu__item"
                                        href="{{ item.externalLink }}{{ queryParams.member_id }}/cxengine/"
                                        target="_blank"
                                        [attr.aria-label]="'navigate to ' + link.externalLink"
                                    >
                                        <i class="icon icon--medium">{{ item.icon }}</i>

                                        <div>
                                            <p class="t-data">{{ item.label }}</p>
                                            <p class="t-data t-alt">{{ item.description }}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    class="nav__icons nav__icons--mobile"
                    *ngIf="
                        navigationService.config.icons &&
                        !!navigationService.config.icons.mobile &&
                        !navigationService.config.hideUtilityLinksInNavBar
                    "
                >
                    <ng-container *ngFor="let tool of navigationService.config.icons.mobile">
                        <a
                            class="nav__link"
                            [ngClass]="{ 'is-active': navigationService.isActiveRoute(tool.route) }"
                            [routerLink]="tool.route"
                            [attr.aria-label]="'navigate to ' + tool.route"
                            (click)="navigationService.selectNavLink($event, tool)"
                            *ngIf="IsNavIconDisplay(tool)"
                        >
                            <i class="icon icon--medium">{{
                                !!navigationService.isActiveRoute(tool.route) ? tool.icon + '_active' : tool.icon
                            }}</i>
                            <ng-container [ngSwitch]="tool.count">
                                <ng-container *ngSwitchCase="'notifications'">
                                    <span
                                        class="nav__link-count t-caption u-flex u-alignCenter u-justifyCenter"
                                        *ngIf="notifications > 0"
                                        >{{ notifications }}</span
                                    >
                                </ng-container>
                            </ng-container>
                        </a>
                    </ng-container>
                </section>

                <section
                    class="nav__icons nav__icons--desktop"
                    *ngIf="
                        navigationService.config.icons &&
                        !!navigationService.config.icons.desktop &&
                        !navigationService.config.hideUtilityLinksInNavBar
                    "
                >
                    <a
                        data-tooltip="Notifications"
                        class="nav__link tooltip"
                        [ngClass]="{ 'is-active': navigationService.isActiveRoute(tool.route) }"
                        [routerLink]="tool.route"
                        [attr.aria-label]="'navigate to ' + tool.route"
                        (click)="navigationService.selectNavLink($event, tool)"
                        *ngFor="let tool of navigationService.config.icons.desktop"
                    >
                        <i class="icon icon--medium">{{
                            !!navigationService.isActiveRoute(tool.route) ? tool.icon + '_active' : tool.icon
                        }}</i>
                    </a>
                </section>

                <section
                    class="nav__utility-links u-flex u-alignCenter u-justifyEnd u-fillRemaining"
                    *ngIf="!!navigationService.config.buttons && !navigationService.config.hideUtilityLinksInNavBar"
                >
                    <div class="button__group">
                        <h6 *ngIf="navigationService.config.showBrokerName && !!authService.loggedInUser">
                            {{
                                !!authService.loggedInUser?.app_user_data?.first_name
                                    ? authService.loggedInUser.app_user_data.first_name
                                    : ''
                            }}
                        </h6>
                        <ng-container *ngFor="let button of navigationService.config.buttons">
                            <ng-container *ngIf="button.type === 'auth'; else defaultButton">
                                <ng-container *ngIf="isButtonVisible(button, 'billing')">
                                    <zip-button
                                        class="nav__icons--desktop"
                                        (zipButtonClicked)="handleLogout()"
                                        *ngIf="authService.loggedInUser && authService.loggedInUser.app_user_data; else logIn"
                                        [level]="button.level || 'medium'"
                                        [content]="button.logout || 'Log Out'"
                                    >
                                    </zip-button>

                                    <ng-template #logIn>
                                        <zip-button
                                            *ngIf="!button.hideRegisterbutton && isButtonVisible(button, 'register')"
                                            class="nav__icons--desktop"
                                            (zipButtonClicked)="handleRegister()"
                                            [level]="button.level || 'medium'"
                                            [content]="button.register || 'Register'"
                                        >
                                        </zip-button>

                                        <zip-button
                                            *ngIf="isButtonVisible(button, 'login')"
                                            class="nav__icons--desktop"
                                            (zipButtonClicked)="handleLogin()"
                                            [level]="button.level || 'medium'"
                                            [content]="button.login || 'Log In'"
                                        >
                                        </zip-button>
                                    </ng-template>
                                </ng-container>
                            </ng-container>

                            <ng-template #defaultButton>
                                <ng-container *ngIf="button.onClick">
                                    <zip-button
                                        class="nav__icons--desktop"
                                        (zipButtonClicked)="onNavButtonClick(button)"
                                        [level]="button.level"
                                        [content]="button.content"
                                    >
                                    </zip-button>
                                </ng-container>

                                <a
                                    *ngIf="button.link"
                                    [href]="button.link"
                                    [attr.aria-label]="'navigate to ' + button.link"
                                    target="_blank"
                                >
                                    <zip-button [level]="button.level" [content]="button.content"></zip-button>
                                </a>
                                <a
                                    *ngIf="button.route"
                                    [routerLink]="button.route"
                                    [attr.aria-label]="'navigate to ' + button.route"
                                    [queryParams]="button.queryParams"
                                >
                                    <zip-button
                                        *ngIf="isButtonVisible(button)"
                                        [level]="button.level"
                                        [content]="button.content"
                                        (zipButtonClicked)="onNavButtonClick(button)"
                                    ></zip-button>
                                </a>
                            </ng-template>
                        </ng-container>
                    </div>
                </section>
            </div>
        </div>
    </nav>
</ng-container>

<modal
    [config]="navigationService.contactUsModalConfig"
    [condition]="navigationService.contactUsModalOpen"
    (cancel)="navigationService.closeContactModal()"
>
    <div [zipBusy]="brokerSearchSub" class="contact--modal" body>
        <h4 class="t-bold" *ngIf="navigationService.contactUsConfig?.title">{{ navigationService.contactUsConfig?.title }}</h4>

        <div *ngFor="let contactGroup of navigationService.contactGroups">
            <div class="section--title">
                <i class="icon icon--medium t-bold" *ngIf="contactGroup.icon">{{ contactGroup.icon }}</i>
                <h6 class="t-bold">{{ contactGroup.header }}</h6>
            </div>
            <div class="SectionContent">
                <div *ngFor="let contact of contactGroup.contacts" class="SectionContacts">
                    <p class="SectionContacts__Label t-data t-bold" [innerHTML]="contact.header"></p>
                    <div class="SectionContacts__Value">
                        <p class="SectionContacts__Data t-data" *ngFor="let detail of contact.details">
                            <a
                                *ngIf="['phone', 'email'].includes(detail.type); else noLink"
                                [href]="detail.type === 'phone' ? 'tel:' + (detail.data | phoneNumber) : 'mailto:' + detail.data"
                                (click)="sendDataAnalytics(detail)"
                                [innerHTML]="detail.type === 'phone' ? (detail.data | phoneNumber) : detail.data"
                            >
                            </a>
                            <ng-template #noLink>
                                <p [innerHTML]="detail.data"></p>
                            </ng-template>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
