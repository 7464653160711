import { formatTypes } from '@zipari/shared-ds-util-format';

export enum CtaActionsEnum {
    download = 'download',
    print = 'print',
    email = 'email',
}
export const PRIMARY_BENEFIT_KEYS = ['family deductible', 'family max out of pocket', 'urgent care visit', 'primary care visit'];

export const defaultPrevPlanTitleTemplate = '${prevYear} Plan Summary';

export const defaultCurrPlanTitleTemplate = '${currYear} Plan Summary';

export interface Plan {
    add_ons: any;
    benefits: [];
    county: any;
    deductible: any;
    display_name: any;
    documents: any;
    external_id: any;
    hsa: any;
    id: any;
    is_active: any;
    is_benchmark: any;
    market_segment: any;
    max_out_of_pocket: any;
    metal_tier: any;
    name: any;
    networks: [];
    plan_benefits: [];
    plan_type: any;
    plan_variation: any;
    price: any;
    primary_benefits: [];
    rating_region: any;
    static_price: any;
    waiting_period: any;
    year: any;
    zipcodes: any;
    subsidyText?: any;
}

export interface AdditionalPlanDetailConfig {
    prop: string;
    label: string;
    format: formatTypes;
}

export interface Benefit {
    type: string;
    label: string;
    value: string;
    document_id?: string;
}
