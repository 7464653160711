import { Workflow, WorkflowStates } from '@zipari/shared-sbp-models';

export interface WorkflowStepSchema {
    type?: string;
    key?: string;
}

export interface WorkflowStep {
    locked: boolean;
    schema: WorkflowStepSchema;
    state: WorkflowStates;
    values: any;
    workflow: number;
    step_number: number;
}

export interface WorkflowStepDetails<T> {
    locked: boolean;
    next_step: WorkflowStep;
    previous_step: WorkflowStep;
    schema: any;
    state: WorkflowStates;
    step_number: number;
    values: T;
    workflow: Workflow<T>;
}
