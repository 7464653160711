import { Component, Input } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { stringBuilder } from '@zipari/web-utils';
import { StateSubsidyConfig, StateSubsidyConfigSection } from '../subsidy.constants';

@Component({
    selector: 'state-subsidy-results',
    templateUrl: './state-subsidy-results.component.html',
    styleUrls: ['./state-subsidy-results.component.scss'],
})
export class StateSubsidyResultsComponent {
    @Input() config: StateSubsidyConfig;
    @Input() amount: string;
    @Input() federal_subsidy_amount: number;
    @Input() state_subsidy_amount: number;

    constructor(public formattingService: FormattingService) {}

    get stateSubsidyResultSections(): Array<StateSubsidyConfigSection> {
        if (!this.amount) {
            const totalSubsidy = this.federal_subsidy_amount + this.state_subsidy_amount;
            this.amount = totalSubsidy.toString();
        }
        const context = {
            amount: this.formatCurrency(this.amount),
            federal_subsidy_amount: this.formatCurrency(this.federal_subsidy_amount),
            state_subsidy_amount: this.formatCurrency(this.state_subsidy_amount),
        };

        const stateSubsidySectionsConfig: Array<StateSubsidyConfigSection> = (this.config && this.config.sections) || [];

        return stateSubsidySectionsConfig.map((section: StateSubsidyConfigSection) => {
            return {
                ...section,
                text: stringBuilder(section.text, context),
            };
        });
    }

    formatCurrency(amount: string | number): string {
        return this.formattingService.restructureValueBasedOnFormat(amount, {
            format: formatTypes.CURRENCY,
        });
    }
}
