export const industryConfigs = {
    sicCode: {
        type: 'typeahead',
        label: 'SIC Code',
        prop: 'sic_code',
        key: 'sic_code',
        selectionRequired: true,
        options: [],
        validators: [],
        messages: {
            error: 'No Matching SIC Code Found',
            loading: 'Fetching SIC Codes',
        },
    },
    industryTitle: {
        type: 'typeahead',
        label: 'Nature of Business',
        prop: 'industry_title',
        selectionRequired: true,
        options: [],
        validators: [],
        messages: {
            error: 'No Matching SIC Code Found',
            loading: 'Fetching SIC Codes',
        },
    },
};
