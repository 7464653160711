export const address1Config = {
    label: 'Address',
    prop: 'street_name_1',
    type: 'text',
    maxLength: 50,
    validators: ['required'],
    grid: {
        col: '1 / 3',
        row: '1',
    },
};

export const address2Config = {
    label: 'Address 2 (optional)',
    prop: 'street_name_2',
    type: 'text',
    maxLength: 50,
    validators: [],
    grid: {
        col: '3 / 5',
        row: '1',
    },
};

export const cityConfig = {
    label: 'City',
    prop: 'city',
    type: 'text',
    maxLength: 25,
    validators: ['required'],
    grid: {
        row: '2',
    },
};

export const cityDropdownConfig = {
    label: 'City',
    prop: 'city',
    type: 'dropdown',
    options: [],
    validators: ['required'],
    grid: {
        row: '2',
    },
};

export const zipCodeConfig = {
    label: 'ZIP Code',
    type: 'text',
    prop: 'zip_code',
    maxLength: 5,
    validators: ['required', 'zipcode'],
    grid: {
        row: '2',
    },
};

export const countyConfig = {
    prop: 'county',
    options: [],
    validators: ['required'],
    grid: {
        row: '2',
    },
};

export const countyCodeConfig = {
    label: 'County',
    prop: 'county_code',
    type: 'dropdown',
    placeholder: 'Please select a county',
    validators: ['required'],
    options: [],
};

export const STATE_OPTIONS = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'American Samoa',
        value: 'AS',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'DC',
    },
    {
        label: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Marshall Islands',
        value: 'MH',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virgin Islands',
        value: 'VI',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
];

export const ABBREVIATED_STATE_OPTIONS = [
    {
        label: 'AL',
        value: 'AL',
    },
    {
        label: 'AK',
        value: 'AK',
    },
    {
        label: 'AS',
        value: 'AS',
    },
    {
        label: 'AZ',
        value: 'AZ',
    },
    {
        label: 'AR',
        value: 'AR',
    },
    {
        label: 'CA',
        value: 'CA',
    },
    {
        label: 'CO',
        value: 'CO',
    },
    {
        label: 'CT',
        value: 'CT',
    },
    {
        label: 'DE',
        value: 'DE',
    },
    {
        label: 'DC',
        value: 'DC',
    },
    {
        label: 'FM',
        value: 'FM',
    },
    {
        label: 'FL',
        value: 'FL',
    },
    {
        label: 'GA',
        value: 'GA',
    },
    {
        label: 'GU',
        value: 'GU',
    },
    {
        label: 'HI',
        value: 'HI',
    },
    {
        label: 'ID',
        value: 'ID',
    },
    {
        label: 'IL',
        value: 'IL',
    },
    {
        label: 'IN',
        value: 'IN',
    },
    {
        label: 'IA',
        value: 'IA',
    },
    {
        label: 'KS',
        value: 'KS',
    },
    {
        label: 'KY',
        value: 'KY',
    },
    {
        label: 'LA',
        value: 'LA',
    },
    {
        label: 'ME',
        value: 'ME',
    },
    {
        label: 'MH',
        value: 'MH',
    },
    {
        label: 'MD',
        value: 'MD',
    },
    {
        label: 'MA',
        value: 'MA',
    },
    {
        label: 'MI',
        value: 'MI',
    },
    {
        label: 'MN',
        value: 'MN',
    },
    {
        label: 'MS',
        value: 'MS',
    },
    {
        label: 'MO',
        value: 'MO',
    },
    {
        label: 'MT',
        value: 'MT',
    },
    {
        label: 'NE',
        value: 'NE',
    },
    {
        label: 'NV',
        value: 'NV',
    },
    {
        label: 'NH',
        value: 'NH',
    },
    {
        label: 'NJ',
        value: 'NJ',
    },
    {
        label: 'NM',
        value: 'NM',
    },
    {
        label: 'NY',
        value: 'NY',
    },
    {
        label: 'NC',
        value: 'NC',
    },
    {
        label: 'ND',
        value: 'ND',
    },
    {
        label: 'MP',
        value: 'MP',
    },
    {
        label: 'OH',
        value: 'OH',
    },
    {
        label: 'OK',
        value: 'OK',
    },
    {
        label: 'OR',
        value: 'OR',
    },
    {
        label: 'PW',
        value: 'PW',
    },
    {
        label: 'PA',
        value: 'PA',
    },
    {
        label: 'PR',
        value: 'PR',
    },
    {
        label: 'RI',
        value: 'RI',
    },
    {
        label: 'SC',
        value: 'SC',
    },
    {
        label: 'SD',
        value: 'SD',
    },
    {
        label: 'TN',
        value: 'TN',
    },
    {
        label: 'TX',
        value: 'TX',
    },
    {
        label: 'UT',
        value: 'UT',
    },
    {
        label: 'VT',
        value: 'VT',
    },
    {
        label: 'VI',
        value: 'VI',
    },
    {
        label: 'VA',
        value: 'VA',
    },
    {
        label: 'WA',
        value: 'WA',
    },
    {
        label: 'WV',
        value: 'WV',
    },
    {
        label: 'WI',
        value: 'WI',
    },
    {
        label: 'WY',
        value: 'WY',
    },
];

export const stateConfig = {
    label: 'State',
    prop: 'state',
    placeholder: 'Please select a state',
    type: 'dropdown',
    options: STATE_OPTIONS,
    validators: ['required'],
    grid: {
        row: '2',
    },
};

export const addressConfigs = [address1Config, address2Config, cityConfig, zipCodeConfig, stateConfig];

export const zipcodeLocationEndpoint = 'api/enrollment/locations/zipcodes/';
