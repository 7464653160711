import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BuildQueryPipe, isEmptyObj, getValue, deepCompare } from '@zipari/web-utils';
import { County } from '@zipari/shared-sbp-models';
import { LoggerService } from '@zipari/shared-sbp-services';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { SubsidyDemographicParams, SubsidyResponse, SubsidyFormValues } from './subsidy.constants';
import { FormGroup, FormControl } from '@angular/forms';
import { startWith, debounceTime, map, filter, distinctUntilChanged, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SubsidyService {
    isBusy = false;
    constructor(private http: HttpClient, private buildQuery: BuildQueryPipe, private loggerService: LoggerService) {}

    getSubsidyResult(
        demographicsInfo: SubsidyDemographicParams = null,
        useAdvancedSubsidy: boolean,
        coverageEffectiveDate: string,
        subsidyFormValues: SubsidyFormValues
    ): Observable<SubsidyResponse> {
        let endpoint: string = 'api/enrollment/plans/subsidies/';
        const params = [];
        if (demographicsInfo && Object.entries(demographicsInfo).length > 0 && useAdvancedSubsidy) {
            Object.keys(demographicsInfo).forEach((key) => {
                const addParam = {};
                addParam[key] = demographicsInfo[key];
                params.push(addParam);
            });
        } else {
            this.loggerService.error('demographics info needed for subsidy API call. It may fail (424) without it');
        }

        const coverage_effective_date: string = coverageEffectiveDate;
        const formValues = Object.assign(subsidyFormValues, { coverage_effective_date });
        params.push(formValues);
        endpoint = this.buildQuery.transform(endpoint, params);
        return this.http.get<SubsidyResponse>(endpoint);
    }

    getDemographicsFormInfoFromValues(values): SubsidyDemographicParams {
        // retrieve the data from form values and build out demographics info object.
        if (values && !isEmptyObj(values)) {
            const subscriberValues = values.subscriber;
            const dependentsValues: Array<any> = values.children || values.dependents;

            const demographicsParams: SubsidyDemographicParams = {
                county_code: values.county,
                dependents_count: dependentsValues ? dependentsValues.length : 0,
                ordering: 'price',
                subscriber_date_of_birth: subscriberValues?.date_of_birth || '',
                subscriber_tobacco: '',
                plan_type:
                    values.coverage_types ||
                    getValue(values, 'demographics.coverage_type') ||
                    getValue(values, 'demographics.coverage_types'),
                zipcode: values.zipcode,
            };

            if (subscriberValues?.tobacco_user && typeof subscriberValues.tobacco_user === 'object') {
                demographicsParams['subscriber_tobacco'] = subscriberValues.tobacco_user.tobacco_user;
            } else if (subscriberValues?.tobacco_user && typeof subscriberValues.tobacco_user === 'boolean') {
                demographicsParams['subscriber_tobacco'] = subscriberValues.tobacco_user;
            }

            if (values.county_location?.state_code) {
                demographicsParams.state_code = values.county_location?.state_code;
            }

            if (values.spouse) {
                const spouseData = values.spouse;
                demographicsParams['spouse_date_of_birth'] = spouseData.date_of_birth;
                demographicsParams['spouse_tobacco'] = '';

                if (spouseData.tobacco_user && typeof spouseData.tobacco_user === 'object') {
                    demographicsParams['spouse_tobacco'] = spouseData.tobacco_user.tobacco_user;
                } else if (spouseData.tobacco_user && typeof spouseData.tobacco_user === 'boolean') {
                    demographicsParams['spouse_tobacco'] = spouseData.tobacco_user;
                }
            }
            if (dependentsValues) {
                for (let i = 0; i < dependentsValues.length; i++) {
                    const dependent = dependentsValues[i];
                    demographicsParams[`dependent_${i}_date_of_birth`] = dependent.date_of_birth;
                    demographicsParams[`dependent_${i}_tobacco`] = '';

                    if (dependent.tobacco_user && typeof dependent.tobacco_user === 'object') {
                        demographicsParams[`dependent_${i}_tobacco`] = dependent.tobacco_user.tobacco_user;
                    } else if (dependent.tobacco_user && typeof dependent.tobacco_user === 'boolean') {
                        demographicsParams[`dependent_${i}_tobacco`] = dependent.tobacco_user;
                    }
                }
            }

            // don't need to add coverage_effective_date in demographicsParams
            // we already passing it's value separately
            //if (values.coverage_effective_date) demographicsParams.coverage_effective_date = values.coverage_effective_date;

            return demographicsParams;
        } else {
            return null;
        }
    }

    /**
     * On changes to the coverage or members form fields that affect subsidy, update subsidy
     * Used by shopping portal and the quote edit panel (BP & SP)
     */
    createSubsidyDemographicParamsObs(
        coverageFormGroup: FormGroup,
        isCoverageFormValid: BehaviorSubject<boolean>,
        countyLocation: BehaviorSubject<County>,
        membersFormGroup: FormGroup,
        isCoveredMembersFormValid: BehaviorSubject<boolean>,
        subscriberFormGroup: FormGroup = new FormGroup({}),
        isSubscriberFormValid: BehaviorSubject<boolean> = new BehaviorSubject(true)
    ): Observable<SubsidyDemographicParams> {
        const SUBSIDY_DEMO_PARAMS_DEBOUNCE_TIME = 500;

        return combineLatest([
            coverageFormGroup.valueChanges.pipe(startWith(coverageFormGroup.value)),
            isCoverageFormValid,
            countyLocation,
            membersFormGroup.valueChanges.pipe(startWith(membersFormGroup.value)),
            isCoveredMembersFormValid,
            subscriberFormGroup.valueChanges.pipe(startWith(subscriberFormGroup.value)),
            isSubscriberFormValid,
        ]).pipe(
            debounceTime(SUBSIDY_DEMO_PARAMS_DEBOUNCE_TIME),
            tap(() => {
                this.isBusy = true;
            }),
            distinctUntilChanged((prev, next) => deepCompare(prev, next)),
            map(
                ([
                    _coverageValues,
                    isCoverageFormValidValue,
                    county_location,
                    _memberValues,
                    isCoveredMembersFormValidValue,
                    _subscriberValues,
                    isSubscriberFormValidValue,
                ]): SubsidyDemographicParams => {
                    const coverageValues = _coverageValues;
                    const memberValues = _memberValues;
                    const subscriberValues = _subscriberValues;
                    const formValues: any = { ...coverageValues, ...memberValues, ...subscriberValues, county_location };
                    const coverageFilledOut: boolean = isCoverageFormValidValue && !isEmptyObj(coverageValues);
                    const membersFilledOut: boolean = isCoveredMembersFormValidValue && !isEmptyObj(memberValues);

                    // subscriber is optional since it exists on the slideout but not demographics
                    const subscriberFilledOut: boolean =
                        (isSubscriberFormValidValue && !isEmptyObj(subscriberValues)) || isEmptyObj(subscriberValues);
                    const shouldReturnValues: boolean = coverageFilledOut && membersFilledOut && subscriberFilledOut && !!county_location;
                    const newVals = this.getDemographicsFormInfoFromValues(formValues);

                    this.isBusy = false;

                    return shouldReturnValues ? newVals : null;
                }
            ),
            filter((result: SubsidyDemographicParams) => result !== null)
        );
    }

    /**
     * On changes to the coverage or members form fields that affect subsidy, update subsidy
     * Used by BP
     */
    createSubsidyWhoseCoveredParamsObs(
        coverageFormGroup: FormGroup,
        isCoverageFormValid: BehaviorSubject<boolean>,
        countyLocation: BehaviorSubject<County>,
        membersFormGroup: FormGroup,
        coverageEffectiveDateControl: FormControl
    ): Observable<SubsidyDemographicParams> {
        const SUBSIDY_DEMO_PARAMS_DEBOUNCE_TIME = 500;

        return combineLatest([
            coverageFormGroup.valueChanges.pipe(startWith(coverageFormGroup.value)),
            isCoverageFormValid,
            countyLocation,
            membersFormGroup.valueChanges,
            coverageEffectiveDateControl.valueChanges.pipe(startWith(coverageEffectiveDateControl.value)),
        ]).pipe(
            debounceTime(SUBSIDY_DEMO_PARAMS_DEBOUNCE_TIME),
            distinctUntilChanged((prev, next) => deepCompare(prev, next)),
            map(
                ([
                    _coverageValues,
                    isCoverageFormValidValue,
                    county_location,
                    _membersFormValues,
                    _coverageEffectiveDateValue,
                ]): SubsidyDemographicParams => {
                    const coverageValues = coverageFormGroup.value;
                    const membersValues = membersFormGroup.value.whoseCovered;
                    const coverage_effective_date = coverageEffectiveDateControl.value;
                    const formValues: any = { ...coverageValues, ...membersValues, county_location, coverage_effective_date };
                    const coverageFilledOut: boolean = isCoverageFormValidValue && !isEmptyObj(coverageValues);
                    const membersFilledOut: boolean = membersFormGroup.valid && !isEmptyObj(membersValues);

                    if (coverageFilledOut && membersFilledOut && county_location && coverage_effective_date)
                        return this.getDemographicsFormInfoFromValues(formValues);
                    return null;
                }
            ),
            filter((result: SubsidyDemographicParams) => result !== null)
        );
    }
}
