import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule, IconModule, StarRatingModule, StatusModule } from '@zipari/design-system';

import { ComparePlansComponent } from './compare-plans.component';
import { ComparePlanHeaderComponent } from './compare-plan-header/compare-plan-header.component';
import { FooterModule } from '../../../../../modules/src/lib/footer/footer.module';
import { NavigationModule } from '../../../../../modules/src/lib/navigation/navigation.module';

@NgModule({
    declarations: [ComparePlansComponent, ComparePlanHeaderComponent],
    imports: [CommonModule, IconModule, RouterModule, FooterModule, ButtonModule, NavigationModule, StarRatingModule, StatusModule],
    exports: [ComparePlansComponent, ComparePlanHeaderComponent],
})
export class ComparePlansModule {}
