import ClaimDetail from './ClaimDetail.model';
import PayeeInformation from './PayeeInformation.model';
import PrincipalDiagnosis from './PrincipalDiagnosis.model';

type PatientRelationshipCode = 'S' | 'SP' | 'D';
type ClaimType = 'claim' | 'rx' | 'medical' | 'dental';
type ClaimSubtype = 'physician' | 'hospital' | 'formulary';

/** Claim model usable in member portal and provider portal. */
export default interface Claim {
    readonly id?: number;

    claim_adjustment_version?: string;
    claim_detail?: ClaimDetail;
    claim_number?: string;
    claim_type?: ClaimType;
    claim_status?: string;
    claim_subtype?: ClaimSubtype;
    created_at?: string;
    data?: string;
    diagnoses?: Array<PrincipalDiagnosis>;
    effective_date?: string;
    eob_document_id?: string;
    liability_level?: string;
    member?: string;
    npi_number?: string;
    notes?: string;
    office_zip?: string;
    patient_id?: string;
    patient_name?: string;
    patient_relationship_code?: PatientRelationshipCode;
    payee_information?: PayeeInformation;
    principal_diagnosis?: PrincipalDiagnosis;
    process_date?: string;
    provider_liability_level_name?: string;
    provider_name?: string;
    provider_number?: string;
    provider_specialty_name?: string;
    receive_date?: string;
    subscriber_id?: string;
    tenant?: string;
    termination_date?: string;
    readonly updated_at?: string;
}
