import { ButtonLevels } from '@zipari/shared-ds-util-button';
import { clientsURLS } from '../app-urls.constant';

export const reviewStepperHeader = {
    label: 'Please Review Your Quote',
    description:
        'Review the quote and edit, if desired. You can also select “Download Quick Quote” to get the document without saving the quote, or select “Complete Quote” which will require you to assign the quote to a Client and Save. All saved quotes will be available in your dashboard.',
};

export const reviewErrorModal = {
    reviewErrorModalHeader: 'New Client Could Not Be Created',
    reviewErrorMessage: 'There was an error processing your request.',
};

export const clientTypeConfig = {
    type: 'toggle',
    prop: 'clientType',
    label: 'Select Client Type',
    options: [
        {
            label: 'New',
            value: 'new',
        },
        {
            label: 'Existing',
            value: 'existing',
        },
    ],
};

export const newClientConfig = [
    {
        type: 'text',
        prop: 'first_name',
        label: 'First Name',
        validators: ['required'],
    },
    {
        type: 'text',
        prop: 'last_name',
        label: 'Last Name',
        validators: ['required'],
    },
    {
        type: 'text',
        prop: 'email',
        label: 'Email Address',
        validators: ['required', 'email'],
    },
    {
        type: 'text',
        prop: 'phone_number',
        mask: 'phone',
        maxLength: 12,
        label: 'Phone Number',
        validators: ['required', 'phone'],
    },
];

export const existingClientConfig = {
    type: 'typeahead',
    prop: 'search',
    label: 'Search by client name or id',
    endpointDebounce: 100,
    options: [],
    endpoint: `${clientsURLS.brokerPortalEndpoint}?search=`,
    endpointTemplate: {
        label: '${first_name} ${last_name}: ${email}',
        value: '${id}',
    },
};

export const displayCoverageType = {
    health: 'Medical',
    medical: 'Medical',
    dental: 'Dental',
    vision: 'Vision',
    bundled: 'Bundled',
    'medical/dental': 'Medical and Dental',
    'health/dental': 'Medical and Dental',
    'dental/vision': 'Dental and Vision',
    'medical/vision': 'Medical and Vision',
    'health/vision': 'Medical and Vision',
    'medical/dental/vision': 'Medical, Dental and Vision',
    'health/dental/vision': 'Medical, Dental and Vision',
};

export class CreateQuotePayload {
    quoted?: any;
    plans?: any;
    data: {
        workflow_id: number;
    };
    notes?: string;
    client?: any;
    coverage_effective_date?: string;
    zipcode?: string;
    county?: string;
    coverage_type: string;
}

export const defaultButtons = [
    {
        content: 'Download Quote',
        level: ButtonLevels.medium,
        action: 'downloadQuote',
    },
    {
        content: 'Assign Quote to Client',
        level: ButtonLevels.high,
        action: 'assignQuote',
    },
];

export enum CoveredTypes {
    dependents = 'dependents',
    spouse = 'spouse',
    stepParents = 'stepParents',
    subscriber = 'subscriber',
}
