export class AddMemberRequest__ApplicationMembers__Demographic__Name {
    firstName: string = null;
    middleName: string = null;
    lastName: string = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberRequest__ApplicationMembers__Demographic {
    birthDate: string = null;
    name: AddMemberRequest__ApplicationMembers__Demographic__Name = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberRequest__ApplicationMembers {
    requestingCoverageIndicator: true = null;
    demographic: AddMemberRequest__ApplicationMembers__Demographic = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberRequest__Application {
    accountHolderIdentityProofedIndicator: boolean = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}

export class AddMemberRequest {
    application: AddMemberRequest__Application = null;
    applicationMembers: Array<AddMemberRequest__ApplicationMembers> = null;

    constructor(options = null) {
        Object.assign(this, options);
    }
}
