<section class="card workflow-step__card">
    <form class="workflow-step__card__body" [formGroup]="formGroup">
        <div *ngFor="let signature of signatures; let i = index" class="signature__section">
            <header class="workflow-step__card__header">
                <div class="u-fillRemaining">
                    <h6 class="t-bold">{{ signature.label }}</h6>
                </div>
            </header>

            <section>
                <form-group [configs]="signature.controls" [form]="signatureForm.at(i)"> </form-group>

                <input
                    *ngIf="signature.attestation"
                    type="checkbox"
                    required
                    [formControlName]="'signature' + i"
                    class="form-control form-control--checkbox"
                />
                <p class="t-data">{{ signature.attestation }}</p>
            </section>
        </div>
    </form>
</section>
