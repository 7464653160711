import { CountyConfig } from '@zipari/shared-sbp-constants';

export interface PlanParams {
    county_code?: string;
    workflow_id?: number;
    zipcode: string | number;
    coverage_effective_date: string;
    dependants_count: number;
    ordering: string;
    year: number | string;
    subscriber_date_of_birth?: string | Date;
    subscriber_tobacco?: boolean;
    subscriber_gender?: string;
    spouse_date_of_birth?: string | Date;
    spouse_tobacco?: boolean;
    dependant_date_of_birth?: string | Date;
    dependant_tobacco?: boolean;
    part_b_effective_date?: string;
}

export interface CoverageFormControl {
    options?: CoverageOption[];
    mask?: string;
    errors: Object;
    prop: string;
    type: string;
    validators: string[];
    enterAnother?: string;
    label: string;
    maxLength?: number;
    default?: string;
    conditional?: Object;
}

export interface CoverageOption {
    label?: string;
    value?: string;
}

export interface CountyOptionsModal {
    label?: string;
    value?: string;
    data?: CountyConfig;
}
