import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormattingService } from '@zipari/design-system';
import { formatTypes } from '@zipari/shared-ds-util-format';
import { cloneObject, isEmptyObj } from '@zipari/web-utils';
import { AddressData, MailingAddressData, MemberTypeValue } from '@zipari/shared-sbp-constants';
import { EnrollmentFormattingMemberExtraFields } from '@zipari/shared-sbp-enrollment';
import { Address } from '@zipari/shared-sbp-models';
import { v4 as uuidv4 } from 'uuid';

export const addDependentAddresses = (
    subscriberPermanentAddress: AddressData,
    extra: EnrollmentFormattingMemberExtraFields,
    dependents: MemberTypeValue[],
    memberType: string,
    subscriberMailingAddress?: MailingAddressData,
    isChildOnly?: boolean
): MemberTypeValue[] =>
    dependents.map((dependent: MemberTypeValue, depIndex: number) => {
        // dependent.address implies that a different mailing address has been selected given
        // how PersonalInfoMember stores mailing address.
        const dependentMailingAddress = cloneObject(dependent?.address || {});

        /* As of 05/18/2021 dependent.address should equal subscriber.permanent_address*/
        dependent.address = cloneObject(subscriberPermanentAddress);
        // Horizon has custom dependent address props, so we need to map those.
        // if dependent.child_street_address is truthy, then its safe to assume this dependent
        // has a different address, so overwrite address values.
        const isDifferentDependentAddress: boolean = !!dependent.child_street_address;
        const mailingAddressIsDifferent: boolean = isMailingAddressDifferent(
            dependent,
            dependentMailingAddress,
            subscriberMailingAddress,
            isChildOnly
        );

        if (isDifferentDependentAddress) {
            const {
                child_street_address: address_1,
                child_apartment_number: address_2,
                child_city: city,
                child_state: state,
                child_street_address: street_name_1,
                child_apartment_number: street_name_2,
                child_zipcode: zip_code,
                child_zipcode: zipcode,
            } = dependent;

            // Form values missing for Horizon, we need to clear these to prevent using the subscriber's primary
            // address values for these fields when we patch to the wf's values on personal info step completion.
            const county = dependents[depIndex]?.county || null;
            const county_display = dependents[depIndex]?.county_display || null;
            const county_name = dependents[depIndex]?.county_name || null;
            const state_name = dependents[depIndex]?.state_name || null;

            const dependentAddress: Address = {
                address_1,
                address_2,
                city,
                state,
                street_name_1,
                street_name_2,
                zip_code,
                zipcode,
                county,
                county_display,
                county_name,
                state_name,
            };

            dependent.mailing_address = cloneObject(dependentAddress);
        } else if (!isDifferentDependentAddress && !mailingAddressIsDifferent) {
            // When there's no different mailing address, assign subscriber permanent address to this member.
            dependent.address = cloneObject(subscriberPermanentAddress);
            dependent.mailing_address = cloneObject(subscriberPermanentAddress);
        } else if (mailingAddressIsDifferent) {
            // When there is different mailing address, assign that address to this member.
            const mailingAddress = !isEmptyObj(dependentMailingAddress) ? dependentMailingAddress : subscriberMailingAddress;
            const { address_1, address_2, street_name_1, street_name_2, zipcode, zip_code } = mailingAddress;

            dependent.mailing_address = {
                ...mailingAddress,
                street_name_1: address_1 || street_name_1,
                street_name_2: address_2 || street_name_2,
                zip_code: zipcode || zip_code,
            };
        }

        const dependentMemberType = dependents[depIndex]?.memberType ? dependents[depIndex].memberType : memberType;

        const dependentObj = {
            ...dependent,
            ...generateMemberOptions(dependentMemberType, extra ? extra : { uuid: dependents[depIndex].uuid }, depIndex),
        };

        dependents[depIndex].uuid = dependentObj.uuid;

        // set up the full name of the applicant
        const formattingService = new FormattingService(new CurrencyPipe('en'), new DatePipe('en'));

        dependentObj.name = formattingService.restructureValueBasedOnFormat(dependentObj, {
            format: formatTypes.FULL_NAME,
        });
        dependents[depIndex].name = dependentObj.name;

        const tobaccoVal: boolean = determineTobaccoUse(dependentObj);

        dependentObj.tobacco_user = tobaccoVal;
        dependentObj.tobacco_use = tobaccoVal;
        dependents[depIndex].memberType = dependentObj.memberType;

        return dependentObj;
    });

export const generateMemberOptions = (memberType, extraProperties: EnrollmentFormattingMemberExtraFields, ind: number = 0) => {
    // todo legacy code ported from V2... CLEANUP
    let finalObject: Record<string, unknown> = {
        memberType,
        memberTypeUnique: `${memberType}${ind}`,
    };

    if (extraProperties) {
        finalObject = { ...finalObject, ...extraProperties };
    }

    if ((extraProperties && !extraProperties.uuid) || !finalObject.uuid) {
        finalObject.uuid = uuidv4();
    }

    return finalObject;
};

export const determineTobaccoUse = (memberObj): boolean => {
    let tobaccoVal;
    if (memberObj.hasOwnProperty('tobacco_user')) {
        tobaccoVal = memberObj.tobacco_user;
    }

    if (!tobaccoVal && memberObj.hasOwnProperty('tobacco_use')) {
        tobaccoVal = memberObj.tobacco_use;
    }

    if (tobaccoVal && typeof tobaccoVal === 'object') {
        tobaccoVal = ['tobacco_user', 'tobacco_use'].filter((key) => tobaccoVal.hasOwnProperty(key)).map((key) => tobaccoVal[key])[0];
    }

    return tobaccoVal;
};

export const IsTobaccoUse = (memberObj): boolean => {
    return memberObj.hasOwnProperty('tobacco_user') || memberObj.hasOwnProperty('tobacco_use');
};

export const capFirstLetter = (stringVal: string): string => stringVal.charAt(0).toUpperCase() + stringVal.toLowerCase().slice(1);

const isMailingAddressDifferent = (
    dependent: MemberTypeValue,
    dependentMailingAddress: AddressData,
    subscriberMailingAddress: MailingAddressData,
    isChildOnly: boolean
): boolean => {
    return isChildOnly ? !!subscriberMailingAddress : dependent.different_mailing_address && !isEmptyObj(dependentMailingAddress);
};
