<input aria-label="file-uploader" class="u-hidden" type="file" #fileInput (change)="onFileSelected($event)" />

<zip-button
    [disabled]="disabled"
    (zipButtonClicked)="onUploadDocumentClick()"
    [content]="config?.buttonContent || 'Upload Document'"
    level="medium"
    icon="upload"
>
</zip-button>
