import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigService } from '@zipari/shared-sbp-services';

@Injectable()
export class StateCohortInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    const DIRTY_CONFIG_HEADER: string = event.headers.get('x-zpr-dirty-config');
                    if (DIRTY_CONFIG_HEADER) {
                        this.configService.busy = this.configService
                            .applyStateCohortConfigs({
                                setConfig: true,
                                makeAPICall: true,
                            })
                            .subscribe(
                                () => {},
                                () => {},
                                () => {
                                    this.configService.busy.unsubscribe();
                                    this.configService.configUpdated.next(this.configService.configs);
                                }
                            );
                    }
                }
            })
        );
    }
}

export const STATE_COHORT_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: StateCohortInterceptor,
    multi: true,
};
